.TAB-5-form {
    .btn-bg button {
        top: 20px;
        transform: rotate(8deg);
    }
    input {
        background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 5px;
    }

    .first-field {
        .bgArrow:before {
            top: 20px !important;
            right: 16px;
        }
    }

    .px_2 {
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }

    .your_domain {
        box-sizing: border-box;
        width: 60%;
        height: 50px;
        display: flex;
        align-items: center;
        background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
        box-shadow: -6px -6px 20px rgba(255, 255, 255, 0.8), 3px 3px 12px rgba(166, 180, 200, 0.4);
        border-radius: 3px;

        // white-space: nowrap;
        // background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
        // box-shadow: -12px -12px 20px rgb(255 255 255 / 80%), 6px 6px 24px rgb(166 180 200 / 40%);
        // border-radius: 3px;
        // font-weight: 500;
        // font-size: 12px;
        // line-height: 16px;
        // padding: 15px 10px;
        // color: #5C70AD;
        // z-index: 1;
        .icon {
            font-size: 20px;
            text-align: center;
        }

        .global_icon {
            color: #11A9CB;
        }

        h6 {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #474E74;
            margin-left: 8px;
            margin-bottom: 0;
        }

        .check_icon {
            color: #149C32;
        }

        .span {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #697094;
        }
    }

    .lock_icon {
        color: #A074FF;
    }

    .domain_name {
        background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 5px;

        .icon {
            width: 60px;
            height: 60px;
            font-size: 60px;
        }

        .vr {
            width: 6.36px;
            height: 80%;
            background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
            box-shadow: inset -2px -2px 2px #FFFFFF, inset 1px 1px 3px rgba(77, 89, 107, 0.1);
            border-radius: 5px;
            margin-top: auto;
            margin-bottom: auto;
        }

        h6 {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #606683;
            margin-bottom: 0;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #697094;
            margin-bottom: 0;
        }

        hr {
            margin: 0.5rem 0;
        }
    }

    .pl_0 {
        padding-left: 0 !important;
    }

    .own_domain_name {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
        box-shadow: -6px -6px 20px rgba(255, 255, 255, 0.8), 3px 3px 12px rgba(166, 180, 200, 0.4);
        border-radius: 3px;
        padding: 0 12px;
    }

    .TAB-5-form .select-field .bgArrow:before {
        top: 11px !important;
        right: 6px;
    }

    // .css-qbdosj-Input{
    // 	padding: 0px!important;
    // }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1660px) {
        .your_domain {
            width: 80%;
        }
    }
}