@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');

.featured-on-neumorphic-desktop-4{

    .Feature-on-desktop-4{
        
        .grey-box-4 {
            background: var(--grey-8, #F0F4F8);

            padding: 140px 0px 140px 0px;
    }
  
  .image-circle-4 {
    background: var(--grey-8, #F0F4F8);

  
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    
    align-items: center;
    justify-content: center;
    margin: 10px 20px 0px 10px;
    filter: drop-shadow(2px 2px 8px #B2BDCB) drop-shadow(-2px -2px 8px #FFF);
    z-index: 90;
}

.image-usa {
    background-color: #1EA3FF;
    
}


.green-box-3 {
    background-color: #7CBAA7;
    padding: 50px 40px 50px 40px;
    width: 100%;
}

  // .box-container-3{
  //   margin-top: -53px;
  // }
}
}