// .design-screen-desktop-2 {
//     background-color: #CBD5E0;

//     .toggle_button {
//         background: #F0F4F8;
//         border-radius: 30px;
//         width: 183px;
//         height: 35px;
//         display: flex;

//         .active_btn {
//             width: 97px;
//             height: 35px;
//             background: #7CBAA7 !important;
//             border-radius: 30px;
//             color: #000000;
//             text-align: center;
//         }

//         .btn1 {
//             background: transparent;
//             color: #000000;
//             text-align: center;
//             border: none;

//         }

//     }


//     .mt_20{
//         margin-top: 20px;
//     }
//     .green_btn1 {
//         width: 125px;
//         height: 35px;
//         background: #7CBAA7;
//         border-radius: 30px;
//         font-size: 12px;
//         color: #000000;
//         text-align: center;
//         border: none;
//     }
//     .plan_outer {
//         width: 300px;
//         height: 710px;
//         background: #F0F4F8;
//         border-radius: 20px;
//         position: relative;
//         .plan_listing{
//             ul {
//                 margin-top: 4rem;
//                 li {
//                     color: #000000;
//                     font-size: 14px;
//                     font-weight: 400;
//                     text-align: left;
//                     line-height: 17px;
//                     padding: .75rem 1rem;
//                     &:nth-of-type(odd){
//                         background: #FFFFFF;
//                     }

//                     .icon {
//                         width: 1.25rem;
//                         height: 1.25rem;
//                         margin-right: 8px;
//                     }

//                     .text_yellow {
//                         color: #FF7714;
//                     }
//                 }
//             }
//         }
//     }
//     .price {
//         position: absolute;
//         width: 140px;
//         height: 140px;
//         top: -75px;
//         border-radius: 50%;
//         background: #F0F4F8;
//         box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         flex-direction: column;
//         h2 {
//             font-weight: 700;
//             font-size: 48px;
//             line-height: 40px;
//             text-align: center;
//             color: #304053;
//         }
//         p {
//             font-weight: 400;
//             font-size: 16px;
//             line-height: 19px;
//             text-align: center;
//             color: #304053;
//         }
//     }
//     .plan_heading {
//         flex-direction: column;
//         h1 {
//             font-weight: 700;
//             font-size: 36px;
//             line-height: 44px;
//             letter-spacing: 0.08em;
//             color: #FFFFFF;
//         }
//         p {
//             font-weight: 400;
//             font-size: 14px;
//             line-height: 17px;
//             color: #FFFFFF;
//         }
//     }
//     #base {
//         background: #304053;
//         display: flex;
//         justify-content: center;
//         margin-left: -5px;
//         position: relative;
//         width: 310px;
//         height: 250px;
//         position: relative;
//         border-top-left-radius: 20px;
//         border-top-right-radius: 20px;
//         &:before {
//             border-top: 50px solid #304053;
//             border-left: 155px solid transparent;
//             border-right: 155px solid transparent;
//             content: "";
//             height: 0;
//             left: 0;
//             position: absolute;
//             bottom: -50px;
//             width: 0;
//         }
//     }
//     .btn {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         width: 124px;
//         height: 46px;
//         background: #304053;
//         border-radius: 30px;
//         font-weight: 500;
//         font-size: 13px;
//         line-height: 16px;
//         text-align: center;
//         letter-spacing: 0.04em;
//         text-transform: uppercase;
//         color: #7CBAA7;
//         position: absolute;
//         bottom: -22px;
//     }

// }

.blogpost-screen-desktop-2 {
    background-color: #F0F4F8;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

    .bg-whoweare {
        background-color: #CBD5E0;
        padding-left: 185px;
        padding-right: 185px;

        .whoweare-content-1 {
            .whoweare {
                font-size: 30px;
                font-weight: 600;
                line-height: 36px;
                color: #202020;
                font-family: 'inter';

                &::after {
                    content: "";
                    display: block;
                    position: relative;
                    width: 85px;
                    margin-top: 20px;
                    border-bottom: 6px solid #7CBAA7;

                }
            }
        }

        .whoweare-paragraph-1 {
            .paragraph-1 {
                font-size: 13px;
                font-weight: 400;
                line-height: 23px;
                font-family: 'inter';
            }
        }
    }
    .bg-whoweare2 {
        background-color: #CBD5E0;
        padding-left: 50px;
        padding-right: 50px;

        .whoweare-content-2 {
            .whoweare2 {
                font-size: 30px;
                font-weight: 600;
                line-height: 36px;
                color: #202020;
                font-family: 'inter';

                &::after {
                    content: "";
                    display: block;
                    position: relative;
                    width: 85px;
                    margin-top: 20px;
                    border-bottom: 6px solid #7CBAA7;

                }
            }
        }

        .whoweare-paragraph-2 {
            .paragraph-2 {
                font-size: 13px;
                font-weight: 400;
                line-height: 23px;
                font-family: 'inter';
            }
        }
    }
    .whoweare-paragraph-list{
        .list-items{
            font-size: 13px;
            font-weight: 400;
            line-height: 24px;
            font-family: 'inter';
            .checkicon{
                font-size: 18px;
            }
        }
    }
}