.market-researc-validation {
    .marketscroll {
        overflow-y: scroll;
        height: 700px;
    }

    .research-modals {
        img {
            cursor: pointer;
        }
    }

    .market-research-screen-container {
        width: 100%;
        margin: 0 auto;
        padding: 40px 15px;
        border-radius: 40px;
        // overflow-y: scroll;
        // box-shadow: inset -10px -10px 34px 4px rgba(68, 68, 68, 0.8);


    }
}

.graph_modal.modal {
    .modal-header {
        border-bottom: none;
        margin-bottom: 10px;
    }

    .modal-dialog {
        justify-content: center;

        .modal-content {
            width: 800px;
        }
    }
}