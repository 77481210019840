@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");

.Feature-on-mobile-3{

  .features-on-design-mobile-3{
    background-color: #cbd5e0;
    width: 400px;
    margin: auto;
    position: relative;
  height: 650px;

  }
  
  .grey-box-mobile-3 {
    background-color: #cbd5e0;
    
  }
  
  .image-circle-mobile-3 {
    background-color: #ffffff;
    
    border-radius: 50%;
    display: flex;
    
    align-items: center;
    justify-content: center;
    margin: 0px 2px ;
    box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.125);
    z-index: 90;
  }
  
  .image-usa {
  background-color: #1EA3FF;

}

.text-center-mobile-3 p {
  font-size: 35px;
  font-weight: bolder;
}

.green-box-mobile-3 {
  background-color: #7CBAA7;
  padding: 50px 20px;
  width: 100%;
}

}