.design-screen-desktop-3 {
    background-color: #cbd5e0;
    .bookme-oterr .book-me-mue {
        left: -33% !important;
    }
    @media screen and (min-width:1700px) {
            .main-content .hero-sidebar .profile-img{
                width: 66%!important;
                margin-left: 3% !important;
                margin-top: -98% !important;
    }
    }
    @media screen and (max-width:1700px) {
        .main-content .hero-sidebar .profile-img{
    width: 90px !important;
    margin-left: 5px !important;
    margin-top: -135px !important;
}
}
@media screen and (max-width:1676px) {
    .main-content .hero-sidebar .profile-img{
width: 90px !important;
margin-left: 5px !important;
margin-top: -135px !important;
}
}
@media screen and (max-width:1644px) {
    .main-content .hero-sidebar .profile-img{
width: 80px !important;
margin-left: -12px !important;
margin-top: -135px !important;
}
}
@media screen and (max-width:1540px) {
    .main-content .hero-sidebar .profile-img{
        width: 55% !important;
        margin-left: -2% !important;
        margin-top: -90% !important;
}
}
@media screen and (max-width:1380px) {
    .main-content .hero-sidebar .profile-img{
width: 65px !important;
margin-left: -9px !important;
    margin-top: -112px !important;
}
}
    .zoom-section {

        transition: transform 0.3s;
      }
      
      .zoom-section:hover {
        transform: scale(1.1);
      }
   .testimonial{
        transition: transform 0.3s;
      }
       .testimonial:hover {
        transform: scale(1.1);
      }
    .video-img{
        position: absolute!important;
        width: 220px!important;
        height: 145px!important;
        top: 35px!important;
        right: 8%!important;
    }
    .arrow-button {

        transition: transform 300ms;
      }
      .plalist-arrow{
        .arrow-buttonn {

            transition: transform 300ms;
          }
      }
      
   
    .dropdown-content {
position: relative;
z-index: 3;
  animation-fill-mode: both;
}



.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }
  
  .fadeInDown {
    animation-name: fadeInDown;
    animation-duration: 0.3s;
    animation-timing-function: ease-in;
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
      
      @keyframes slideDown {
        100% {
          opacity: 0;
          transform: translateY(-10px);
        }
        
        0% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    .meu-social{
        display: none;
    }
    #parent-circle {
        position: relative;
        width: 6.8vw;
        height: 6.8vw;
        border: 1px solid rgb(124 186 167);
        border-radius: 50%;
        transform: rotate(0deg);
        transition: transform 0.7s linear;
        animation: rotate 7s infinite linear;
    }
      .circle {
          display: block;
          position: absolute;
          width: 15%;
          height: 15%;
          margin: -8%;
          border-radius: 50%;
          font-size: 9px;
    top: 50%;
    left: 51%;
    padding-left: 3px;
    padding-top: 1px;
    }
          #parent-circle  .printerest {
            background-color: white;
            transform: rotate(5deg) translate(3.3vw) rotate(-5deg);
    
          }
          #parent-circle  .facebookk {
        background-color: white;
        transform: rotate(30deg) translate(3.3vw) rotate(-46deg);
          }
          #parent-circle  .instaa {
            background-color: white;
            transform: rotate(60deg) translate(3.3vw) rotate(-91deg);
          }
          #parent-circle  .twitter {
            background-color: white;
            transform: rotate(90deg) translate(3.3vw) rotate(-92deg);
          }
  
          #parent-circle  .innn {
            background-color: white;
            transform: rotate(115deg) translate(3.3vw) rotate(-95deg);
          }   #parent-circle  .tiktok {
            background-color: white;
            transform: rotate(143deg) translate(3.3vw) rotate(-166deg);
          }   #parent-circle  .videoo {
            background-color: white;
            color: rgb(124 186 167);
            transform: rotate(194deg) translate(3.3vw) rotate(-193deg);
          }   #parent-circle  .snapchat {
            background-color: white;
            color: rgb(124 186 167);
            transform: rotate(220deg) translate(3.3vw) rotate(-215deg);
          }   #parent-circle  .logo-f{
            background-color: white;
            color: rgb(124 186 167);
            border-radius: 50px;
            padding: 0px 2px 3px;
    font-size: 11px;
            transform: rotate(249deg) translate(3.3vw) rotate(-251deg);
          }   #parent-circle  .logo2 {
            background-color: white;
            transform: rotate(279deg) translate(3.3vw) rotate(84deg);
          }   #parent-circle  .logo-3 {
            background-color: white;
            transform: rotate(-53deg) translate(3.3vw) rotate(-278deg);
          }   #parent-circle  .logo-4 {
            background-color: white;
            transform: rotate(-22deg) translate(3.3vw) rotate(13deg);
          } 
          #parent-circle  .videooo {
            background-color: white;
            color: rgb(124 186 167);
            transform: rotate(169deg) translate(3.3vw) rotate(-171deg);
          }
    .vector-shape{
        display: none;
    }
    .meu-icon{
        display: none;
    }
    .glass-show {
        display: none;
    }
    .timeline-glass {
        display: none;
    }
    .book-me-geo {
        display: none;
    }
    .reg-sond {
        display: block;
    }
    .glass-sond {
        display: none;
    }
    .hero-section-reg {
        display: block;
    }
    .meu-img {
        display: none;
    }
    .timeline-gold {
        display: none;
    }
    .meu-timeline {
        display: none;
    }
    .test-reg {
        display: block;
    }
    .book-me-btn-sidebar {
        display: block;
    }
    .book-me-mue {
        display: none;
    }
    .reg-timeline-desktop {
        display: block;
    }
    .reg-timeline {
        display: block;
    }
    meu-timeline {
        display: none;
    }
    .hero-section-mue {
        display: none;
    }
    .bookme-oter {
        position: relative;

        .bookme-inner {
            background: #cbd5e0;
            z-index: 3;
            padding: 20px;
            width: 400px;
            position: absolute;
            box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
            right: 136px;
            top: 0px;

            .close-btn {
                border: none;
                background-color: transparent !important;
                top: 0px;
                right: 14px;
                font-size: 34px;
                color: grey;
                position: absolute;
            }
            .forms-inpt {
                background-color: white;
                border-radius: 10px;
                padding: 40px 30px;
                margin-top: 40px;
                .form-control {
                    width: 100%;
                    margin: 0px 3px;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: transparent;
                    border: 1px solid #7cbaa7;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;

                    color: #808492;
                }
            }

            .mr-0 {
                margin-right: 0px !important;
            }
            .meu-timeline {
                display: none;
            }
            .timelinee {
                counter-reset: year 2016;
                position: relative;
                margin-top: 73px;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    font-weight: 400;
                    font-size: 8px;
                    line-height: 12px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -3px;
                }
            }

            .timelinee li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-topp {
                width: 30px;
                height: 30px;
                font-weight: 500;
                font-size: 8px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #f0f4f8;
                background: #5f6168;
                top: -22px;
                left: 24px;
            }
            .timelinee li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 46px;
                margin: 0 auto 10px auto;
                width: 75px;
                height: 75px;
                background: #f0f4f8;
                color: #5f6168;
                font-size: 15px;
                margin-right: 15px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                img {
                    width: 20px;
                }
                .message {
                    width: 25px !important;
                }
                .load {
                    width: 28px !important;
                }
                .answer {
                    width: 32px;
                }
            }

            .timelinee:after {
                content: "";
                position: absolute;

                width: 70%;
                height: 12px;
                border: 3px solid #7cbaa7;
                background-color: #304053;
                top: 34px;
                left: 72px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timelinee li.active {
                color: #555555;
            }

            .timelinee li.active + li:after {
                background: #4caf50 !important;
            }
            .forms-inpt {
                .form-control {
                    width: 100%;
                    margin: 0px 3px;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: transparent;
                    border: 1px solid #7cbaa7;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;

                    color: #808492;
                }
            }
            .book-me-btnn {
                width: 150px;
                margin: auto;
                height: 100% !important;
                padding: 13px 30px;
                background: #7cbaa7;
                border: 1px solid #7cbaa7;
                border-radius: 5px;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: #202020;
                font-weight: 500;
                text-align: center;
                font-size: 13px;
                line-height: 16px;
            }
        }
    }
    .bookme-oterr {
        .bookme-inner {
            background: #cbd5e0;
            z-index: 3;
            padding: 20px;
            width: 400px;
            position: absolute;
            box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
            top: -190px;
            left: 0px;

            .close-btn {
                border: none;
                background-color: transparent !important;
                top: 0px;
                right: 14px;
                font-size: 34px;
                color: grey;
                position: absolute;
            }
            .forms-inpt {
                background-color: white;
                border-radius: 10px;
                padding: 40px 30px;
                margin-top: 40px;
                .form-control {
                    width: 100%;
                    margin: 0px 3px;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: transparent;
                    border: 1px solid #7cbaa7;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;

                    color: #808492;
                }
            }

            .mr-0 {
                margin-right: 0px !important;
            }
            .meu-timeline {
                display: none;
            }
            .timelinee {
                counter-reset: year 2016;
                position: relative;
                margin-top: 73px;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    font-weight: 400;
                    font-size: 8px;
                    line-height: 12px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -3px;
                }
            }

            .timelinee li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-topp {
                width: 30px;
                height: 30px;
                font-weight: 500;
                font-size: 8px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #f0f4f8;
                background: #5f6168;
                top: -22px;
                left: 24px;
            }
            .timelinee li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 46px;
                margin: 0 auto 10px auto;
                width: 75px;
                height: 75px;
                background: #f0f4f8;
                color: #5f6168;
                font-size: 15px;
                margin-right: 15px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                img {
                    width: 20px;
                }
                .message {
                    width: 25px !important;
                }
                .load {
                    width: 28px !important;
                }
                .answer {
                    width: 32px;
                }
            }

            .timelinee:after {
                content: "";
                position: absolute;

                width: 70%;
                height: 12px;
                border: 3px solid #7cbaa7;
                background-color: #304053;
                top: 34px;
                left: 72px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timelinee li.active {
                color: #555555;
            }

            .timelinee li.active + li:after {
                background: #4caf50 !important;
            }
            .forms-inpt {
                .form-control {
                    width: 100%;
                    margin: 0px 3px;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: transparent;
                    border: 1px solid #7cbaa7;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;

                    color: #808492;
                }
            }
            .book-me-btnn {
                width: 150px;
                margin: auto;
                height: 100% !important;
                padding: 13px 30px;
                background: #7cbaa7;
                border: 1px solid #7cbaa7;
                border-radius: 5px;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: #202020;
                font-weight: 500;
                text-align: center;
                font-size: 13px;
                line-height: 16px;
            }
        }
    }
    .search-position {
        position: relative;
    }
    .searchbox {
        top: -53px;
        left: 112%;
        position: absolute;
        background: #f0f4f8 !important;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
        border-radius: 10px !important;
        border: none;
        z-index: 3;
        padding: 20px 30px;
        width: 380px;
        .search-area {
            margin: 0px;
            .close-btn {
                border: none;
                background-color: transparent !important;
                top: -11px;
                right: -155px;
                font-size: 34px;
                color: grey;
                position: relative;
            }
            .search-field {
                width: 100%;
                margin: 0px 10px 0px;
                height: 44px !important;
                box-shadow: none;
                padding: 2px 11px;

                background: transparent;
                border: 1px solid #394649;
                border-radius: 50px;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #394649;
                .icon-mgni {
                    font-size: 35px !important;
                }
                input {
                    font-size: 15px;
                }
                svg {
                    cursor: pointer;
                }
            }
        }
        .search-me-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 17px 15px;
            margin-bottom: 10px !important;
            gap: 10px;
            margin: auto;
            margin-top: 20px;
            width: 110px !important;
            height: 50px;
            background: #7cbaa7;
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            border: none;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }
    }
    .bars-hambrger {
        position: relative;
        .react-multi-carousel-track {
            .react-multi-carousel-item {
                button {
                    width: 150px !important;
                    background-color: transparent !important;
                }
            }
        }
        .hamburger-outer {
            position: absolute;
            z-index: 4;
            padding: 40px 20px 40px;
            width: 400px;
            right: -17px;
            top: 0;
            background-color: #f0f4f8 !important;

            .sidebar-li {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                border-bottom: 1px solid rgb(177, 176, 176);
                padding: 20px;
                color: #3c3d40 !important;
            }

            .testimonial-p {
                color: #3c3d40;
                font-size: 15px;
            }
            .bottom-tabs {
                color: #3c3d40;
                margin-top: 5px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                h6 {
                    margin-left: 10px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 120%;
                    color: #3c3d40;
                }
            }

            .btn-close {
                background-color: #7cbaa7;
            }
            .close-btn {
                border: none;
                color: white;
                background: #7cbaa7;
                width: 45px;
                height: 40px;
                font-size: 23px;
                position: absolute;
                right: 17px;
                top: 30px;
            }
            .switch-outer {
                width: 62.06px;
                height: 33px;
                background-color: white;
                border-radius: 49.5px;
                z-index: 1;
                margin: 0px 15px;
                .switch {
                    display: inline-block;
                    position: relative;
                    width: 39.58px;
                    height: 22.71px;
                    box-shadow: none !important;
                    left: 6px;
                    top: 6px;
                    box-shadow: none;
                    border-radius: 49.5px;
                    z-index: 2;
                    background: #7cbaa7;
                    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    vertical-align: middle;
                    cursor: pointer;
                }
                .switch::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 20px;
                    width: 15.03px;
                    height: 15.03px;
                    background: white;
                    border-radius: 50%;
                    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                        box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                }
                .switch:active::before {
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                }
                input:checked + .switch {
                    background: #7cbaa7;
                }
                input:checked + .switch::before {
                    left: 9px;
                    background: #fff;
                }
                input:checked + .switch:active::before {
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                }
            }
            .mode-set {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #3c3d40;

                z-index: 1;
            }
            .social-set {
                font-style: normal;
                font-weight: 500;
                font-size: 10px !important;
                line-height: 12px;
                color: #3c3d40;
                z-index: 1;
            }
            .btn-close {
                background-color: white;
                opacity: 1;
                border-radius: 0px !important;
                width: 30px;
                height: 30px;
            }

            .bottom-tabs {
                color: #cbd5e0;
                margin-top: 5px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                h6 {
                    margin-left: 10px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 120%;
                    color: #cbd5e0;
                }
                svg {
                    font-size: 25px;
                }
            }
            .testimonial-p {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .top-padding {
                display: flex;
                justify-content: center;
                align-items: center;
                li {
                    padding: 0px 8px;
                }
            }
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: white;
                    overflow: hidden;
                    width: 87px;
                    padding: 8px;
                    height: 87px;
                    top: 97px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #7cbaa7;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 130px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }
    .navbar-top {
        background: #cbd5e0;
        height: 140px;
        padding: 24px !important;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
        .bottom-tabs {
            color: #394649;
            margin-top: 5px;
            text-align: center;
            svg {
                font-size: 28px;
                margin-bottom: 5px;
            }
            h6 {
                font-weight: 600;
                font-size: 14px;
                line-height: 120%;
                color: #192529;
            }
        }
        .social-icon-navbar {
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                width: 36px;
                height: 36px;
                background: #3c3d40;
                margin: 0px 8px;
                svg {
                    color: #7cbaa7;
                    font-size: 20px;
                }
            }
        }
        .mi-info {
            font-size: 25px;
            margin-left: -15px;
            margin-top: 0px;
        }
        .search-area {
            margin: 0px;
            .search-field {
                width: 350px;
                margin: 0px 10px 0px;
                height: 60px !important;
                box-shadow: none;
                padding: 2px 11px;

                background: transparent;
                border: 1px solid #3c3d40;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                border-radius: 0px;
                text-align: center;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #808492;
                .icon-mgni {
                    font-size: 35px !important;
                    color: #3c3d40;
                }
                input {
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    color: #808492;
                    font-size: 15px;
                }
                svg {
                    cursor: pointer;
                }
            }
        }
    }
    @media screen and (max-width: 1550px) {
        .navbar-top {
            background: #cbd5e0;
            height: 140px;
            padding: 24px !important;
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: white;
                    width: 71px !important;
                    padding: 6px !important;
                    height: 71px !important;
                    top: 97px !important;
                    left: 9px !important;

                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #7cbaa7;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 130px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                    @media screen and (min-width: 1800px) {
                        width: 109px;
                        margin: 0px 10px;
                        position: relative;
                        z-index: 2;
                        top: 28px !important;
                        left: -3px !important;
                    }
                }
            }
            .profile-img {
                width: 65px;
                margin-left: -15px;
                margin-top: -127px;
            }
            .icons-circle {
                width: 125px;
            }
            .img-logo {
                margin-left: 11px;
                width: 88px;
            }
            .timeline {
                counter-reset: year 2016;
                position: relative;
                margin-top: 27px;
                right: -11px;
                p {
                    font-weight: 400;
                    font-size: 8px;
                    padding: 0px 15px 0px;
                    line-height: 11px;
                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -7px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-top {
                width: 35px;
                height: 35px;
                font-weight: 500;
                font-size: 10px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #f0f4f8;
                background: #5f6168;
                top: 28px;
                left: -14px;
            }
            @media screen and (min-width: 1850px) {
                .timeline-top {
                    width: 88px;
                    padding: 6px;
                    height: 89px;
                    top: 116px;
                    left: 13px;
                }
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 22px auto;
                width: 78px;
                height: 78px;

                background: #f0f4f8;
                color: #5f6168;
                font-size: 15px;
                margin-right: 30px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                img {
                    width: 20px;
                }
                .message {
                    width: 25px !important;
                }
                .load {
                    width: 28px !important;
                }
                .answer {
                    width: 32px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;
                width: 67%;
                height: 12px;
                border: 3px solid #7cbaa7;
                background-color: #304053;
                top: 34px;
                left: 54px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
        }
    }
    .top-padding {
        margin-top: -124px;
    }
    .switch-outer {
        width: 110px;
        left: 22px;
        height: 60px;
        background-color: white;
        border-radius: 49.5px;
        z-index: 1;
        margin: 0px 15px;
        .switch {
            display: inline-block;
            position: relative;
            width: 74px;
            height: 45.64px;
            left: 8px;
            top: 6px;
            box-shadow: none !important;
            box-shadow: none;
            border-radius: 49.5px;
            z-index: 2;
            background: #7cbaa7;
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            vertical-align: middle;
            cursor: pointer;
        }
        .switch::before {
            content: "";
            position: absolute;
            top: 7px;
            left: 33px;
            width: 30.6px;
            height: 30.6px;
            background: white;
            border-radius: 50%;
            transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        }
        .switch:active::before {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
        }
        input:checked + .switch {
            background: #7cbaa7;
        }
        input:checked + .switch::before {
            left: 11px;
            background: #fff;
        }
        input:checked + .switch:active::before {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
        }
    }
    .mode-set {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #3c3d40;

        z-index: 1;
    }
    .social-set {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #3c3d40;
        z-index: 1;
    }
    .navbar-bars {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: 30px;

        width: 56px;
        height: 56px;
        color: black;
        background: #7cbaa7;

        margin: auto;
        margin-top: 35px;
    }

    .text-hide {
        color: transparent;
    }
    .main-content {
        position: relative;
        .intro-card {
            position: absolute;
            z-index: 1;
            background: #f0f4f8;
            width: 300px;
            height: 340px;
            left: 25px;
            padding: 15px;
            .icon-arrow {
                transform: rotate(90deg);
                color: #7cbaa7;
                font-size: 90px;
                position: absolute;
                left: 35%;
                top: -38px;
            }
            h1 {
                font-weight: 700;
                font-size: 48px;
                line-height: 58px;
                color: #ac8d98;
            }
            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                color: #000000;
            }
            h2 {
                font-weight: 500;
                font-size: 36px;
                line-height: 44px;
                text-transform: uppercase;
                color: #202020;
            }
            .border-card {
                width: 100px;
                height: 3px;
                margin: 20px 0px;
                background: #885769;
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #3c3d40;
            }
            h4 {
                font-weight: 500;
                font-size: 36px;
                line-height: 44px;
                text-transform: uppercase;
                color: #202020;
            }
        }
        .hero-section{
            .arrow-button{
                border: none!important;
                background-color: transparent!important;
            }
            .arrow-button   .icon-arrow {
                transform: rotate(90deg);
                color: #7cbaa7;
                font-size: 90px;
                position: absolute;
                left: 11%;
                z-index: 2;
                top: -38px;
            }
            .plalist-arrow{
                .arrow-buttonn{
                    border: none!important;
                    background-color: transparent!important;
                }
                .arrow-buttonn   .icon-arrow {
                    transform: rotate(90deg);
                    color: #7cbaa7;
                    font-size: 90px;
                    position: absolute;
                   right: 11%;
                    top: -38px;
                    z-index: 2;
                }
                .playlist-card{
                    animation-name: slideDown;
                    animation-duration: 5s;
                    animation-timing-function: ease-in-out;
                    animation-fill-mode: forwards;
                    opacity: 0;
                    transform: translateY(-10px);
                }
                @keyframes slideDown {
                    100% {
                      opacity: 0;
                      transform: translateY(-10px);
                    }
                    
                    0% {
                      opacity: 1;
                      transform: translateY(0);
                    }
                  }
            }
        }

        .playlist-card {
            position: absolute;
            z-index: 1;
            background: #f0f4f8;
            width: 300px;
            height: 340px;
            right: 25px;
            padding: 15px;
        
            .slidecontainer {
                width: revert;
                margin-left: -38px;
                margin-right: 11px;
            }
            .playlist-section-text {
                margin-top: 40px;
                margin-bottom: 20px !important;
                width: 270px;
             
                padding: 20px;
                border-radius: 20px 20px 20px 20px !important;

                // @media screen and (max-width:1580px) {
                //     right: -193px;
                //     width: 350px;

                // }
                input[type="range"].range-slider {
                    width: 48px;
                    margin-right: 7px;
                }
                .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                    height: 20px !important;
                    width: 26px !important;
                    bottom: -24px !important;
                    border-radius: 50% !important;
                    display: flex !important;
                    color: #202020;
                    justify-content: center !important;
                    align-items: center !important;
                    background-color: #3860ee !important;
                    font-size: 8px;
                }
                .range-slider__wrap
                    .range-slider__tooltip.range-slider__tooltip--bottom
                    .range-slider__tooltip__caret::before {
                    border-bottom-color: #3860ee !important;
                    border-width: 0 0.3rem 0.3rem;
                    bottom: 25px;
                    left: 2px;
                }
                .range-slider__wrap {
                    position: relative;
                    display: block;
                    height: 16px !important;

                    .range-slider__tooltip {
                        z-index: 0 !important;

                        &.range-slider__tooltip--bottom {
                            bottom: calc(0.75em + 0.375rem + 1px + 0.4rem + 10px + 3px) !important;
                            top: auto !important;
                        }

                        .range-slider__tooltip__caret {
                            transform: translateX(50%) !important;
                        }
                    }
                }
                input[type="range"].range-slider {
                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        box-sizing: border-box;
                        border: none;
                        border-radius: 50%;
                        height: 8px !important;
                        background-color: #3860ee !important;
                        border: 1px solid black;
                        width: 8px !important;
                        cursor: pointer;
                        transition: box-shadow 0.5s ease;
                        margin-top: -3px !important;
                    }
                }
                input[type="range"].range-slider {
                    box-shadow: none;
                    height: 8px;
                    padding: 0px;
                }
                input[type="range"].range-slider {
                    &::-webkit-slider-runnable-track {
                        -webkit-appearance: none !important;
                        width: 100% !important;
                        margin-top: 8px;
                        height: 1px !important;
                        background-color: #202020 !important;
                        border: 2px solid #202020;
                        outline: none !important;

                        -webkit-transition: 0.2s !important;

                        border-radius: 30px !important;
                    }
                }
                .next-icon {
                    background: #202020;
                    border-radius: 23.0737px;
                    width: 20px;
                    padding: 2px;
                    text-align: center;
                    color: white;
                    font-size: 17px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 20px;
                }
                .setting-icons {
                    background: #202020;
                    margin-left: 3px;
                    border-radius: 24.0118px;
                    padding: 0px 5px;
                    color: white;
                    font-size: 12px;
                    display: flex;
                    margin-top: -5px;
                    margin-right: -55px;
                    margin-left: 22px;
                    .icons-set {
                        margin: 4px 4px;
                    }
                }
                .side-text {
                    /* Grey/9 */

                    background: #ffffff;
                    border-radius: 2.38694px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 95px;
                    padding: 7.139px 0.36517px 3.97823px;
                    font-weight: 500;
                    font-size: 5.40381px;
                    line-height: 100%;
                    margin: 0px 10px;
                    /* or 5px */
                    border: 2px solid #dda01a;

                    text-align: center;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                    color: #1c1c1c;
                }
                .text-gree {
                    display: flex;
                    position: relative;
                    top: 4px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 3.27619px 5.2419px;
                    width: 60.48px;
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    font-size: 8px;
                    height: 10.55px;

                    background: #e5e9ed;
                    border-radius: 1.96571px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 4.44588px;
                    line-height: 100%;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #101010;
                }
                .next-icon-center {
                    background: #202020;
                    border-radius: 50.7836px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: -4px 2px 0px;
                    .iconss {
                        font-size: 12px;
                        color: white;
                        margin: 4px;
                    }
                }

                .video-icon {
                    font-size: 25px;
                    color: #202020;
                    margin: 0px 2px;
                    svg {
                        margin-top: -11px;
                    }
                }
                .mic-img {
                    padding: 0px 5px;
                    img {
                        width: 75px;
                    }
                }
            }
        }
        .hero-sidebar {
            background: #cbd5e0;
            width: 9%;
            height: 100%;
            padding: 15px;
            border-right: 1px solid #b2bdcb;
            @media screen and (max-width: 1650px) {
                width: 11%;
            }
            .testimonial {
                margin-top: -80px;
                @media screen and (min-width: 1850px) {
                    .timeline-top {
                        width: 88px !important;
                        padding: 6px !important;
                        height: 89px !important;
                        top: 116px !important;
                        left: 13px !important;
                    }
                }
                @media screen and (min-width: 1400px) and (max-width: 1850px) {
                    .timeline-top {
                        width: 79px !important;
                        padding: 6px !important;
                        height: 79px !important;
                        top: 102px !important;
                        left: 9px !important;
                    }
                }
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: white;
                    width: 68px;
                    padding: 6px;
                    height: 68px;
                    top: 92px;
                    left: 9px;

                    p {
                        margin-top: 3px;
                        line-height: 8px;
                    }
                    .icon-green {
                        font-size: 7px;
                        color: #7cbaa7;
                        padding-top: 0px;
                        margin-top: 14px;
                    }
                    p {
                        font-size: 6px;
                    }
                }

                img {
                    width: 108px;
                    margin: 0px 10px;
                    position: relative;
                    z-index: 2;
                    top: 18px;
                    left: -7px;

                    @media screen and (min-width: 1800px) {
                        width: 130px;
                        margin: 0px 10px;
                        position: relative;
                        z-index: 2;
                        top: 18px !important;
                        left: -8px !important;
                    }
                    @media screen and (max-width: 1500px) {
                        width: 96px;
                        margin: 6px 10px;
                        position: relative;
                        z-index: 2;
                        top: 12px;
                        left: -7px;
                    }
                }
            }

            .img-logo {
                margin-left: 20px;
                width: 110px;
            }
            .timeline {
                counter-reset: year 2016;
                position: relative;
                margin-top: 27px;
                right: -20px;
                p {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 11px;
                    /* identical to box height */

                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -3px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-top {
                width: 35px;
                height: 35px;
                font-weight: 500;
                font-size: 10px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #f0f4f8;
                background: #5f6168;
                top: 28px;
                left: -14px;
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 22px auto;
                width: 85px;
                height: 85px;

                background: #f0f4f8;
                color: #5f6168;
                font-size: 15px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                img {
                    width: 20px;
                }
                .message {
                    width: 25px !important;
                }
                .load {
                    width: 28px !important;
                }
                .answer {
                    width: 32px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;
                width: 370px;
                height: 12px;
                border: 3px solid #7cbaa7;
                background-color: #304053;
                top: 181px;
                left: -142px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
                transform: rotate(90deg);
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
            .profile-img {
                width: 65px;
                margin-left: -15px;
                margin-top: -127px;
            }
            @media screen and (min-width:1900px) {
                .profile-img {
                    width: 60% !important;
                    margin-left: -14% !important;
                    margin-top: -81% !important;
                }
                
            }
            .icons-circle {
                width: 106px;
            }

            .mode-set {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #3c3d40;

                z-index: 1;
            }
            .social-set {
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                color: #3c3d40;
                z-index: 1;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 9px;
                line-height: 11px;
                text-align: center;
                color: #202020;
            }

            .name-btn {
                background: #7cbaa7;
                border-radius: 4px;
                font-weight: 600;
                font-size: 9px;
                line-height: 100%;
                padding: 8px 10px;
                margin-bottom: 10px;
                text-align: center;
                letter-spacing: 0.01em;
                color: #202020;
            }
            .info-icon {
                border: 3px solid #394649;
                padding: 5px 11px 8px 14px;
                font-size: 19px;
                margin: 20px 0px;
                color: #394649;

                border-radius: 50px;
            }
            .fa-magnify {
                font-size: 44px;
                margin: 0px 0px 14px;
                color: #394649;
            }
        }
        .content-hero {
            width: 92%;
            .content-text {
                background: #cbd5e0;
            }
        }
    }
    .content-text {
        position: relative;
        .dots {
            position: relative;
            top: -88px;
            left: 44px;
        }
        .tab-right-section {
            width: 15%;
        }
        .nav-item .active {
            background: #202020!important;
            color: white;
            box-shadow: none!important;
        }

        .tab-section {
            width: 55%;
            margin: auto;
            margin-top: -63px !important;
        }
        .tab-himself-text {
            margin-top: -75px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 70px 100px 20px;

            position: relative;

            height: 364px;
            background: #7cbaa7;
            border-radius: 8px;
            p {
                overflow: auto;
                padding-right: 10px;
                ::-webkit-scrollbar {
                    width: 10px;
                }

                /* Track */
                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: #888;
                }

                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }

        .btn-video {
            position: absolute;
            right: 19%;
            top: 36px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 17px 25px;
            width: 114px;
            height: 45px;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #ffffff;

            /* Others/1 */

            background: #304053;
            border-radius: 4px;
        }
        .video-section {
            width: 23%;
            position: absolute;
            background: #f0f4f8;
            box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
            border-radius: 8px;
            right: 80px;
            height: 50%;
            bottom: 27%;
            display: flex;
            justify-content: center;
            align-items: center;
            .video-img {
                background-image: url(../../../../../../images/video-img.png);
                background-repeat: no-repeat;
                background-position: center;
                margin: 28px;
                width: 300px;
                height: 193.33px;
                border-radius: 10.5263px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .design-about {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px 18px;
        width: 240px;
        padding-left: 16px !important;
        margin: 3px 0px !important;
        height: 43px;
        position: relative;
        z-index: 1;
        background: #7cbaa7;
        color: #202020;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        top: 104px;
    }
    .nav-item .active {
        background: #202020!important;
        color: white;
        box-shadow: none!important;
    }
    .content-img {
        position: relative;
        width: 100%;
        top: -37px;
        @media screen and (max-width: 1500px) {
            height: 1014px;
        }

        height: 1050px;
        @media screen and (min-width: 1900px) {
            height: 1143px;
        }
    }
    .hero-section {
        position: relative;
    }
    .hero-part {
        position: absolute;
        top: 80px;
        left: 80px;
        h1 {
            font-weight: 700;
            font-size: 68px;
            line-height: 90px;
            color: #ffffff;

            text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        }
        p {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;

            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        }
    }
    @media screen and (min-width: 1500px) {
        .hero-part {
            position: absolute;
            top: 80px;
            left: 80px;
            h1 {
                font-weight: 700;
                font-size: 68px;
                line-height: 90px;
                color: #ffffff;

                text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            }
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;

                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
            }
        }
    }
    .content-hero {
        width: 92%;
    }
    .book-me-btn-sidebar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 16px;
        gap: 10px;
        margin: 20px 0px 7px;
        width: 100%;
        height: 50px !important;
        background: #7cbaa7;
        border-radius: 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        border: none;
        letter-spacing: 0.04em;
        text-transform: uppercase;
    }
    .logo-intro {
        width: 100%;
        text-align: right;
        position: absolute !important;
        top: 403px;
        z-index: 2;
        left: 176px;
        width: 153px;
    }
    .img-top {
        width: 150px;
        .img-logo {
            border: 8px solid #7cbaa7;
            border-radius: 15px;
            width: 150px;
        }
    }
    .book-me-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 17px 18px;
        gap: 10px;
        margin: 0px 30px 0px 20px;
        width: 151px;
        height: 50px !important;
        background: #7cbaa7;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        /* identical to box height */
        border: none;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        position: relative;
        top: -190px;
        left: 14px;
    }
    .forms-inpt {
        .form-control {
            width: 260px;
            margin: 0px 10px;
            height: 100% !important;
            padding: 13px 30px;
            background: transparent;
            border: 1px solid #7cbaa7;
            border-radius: 50px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            color: #808492;
        }
    }
    .timeline-desktop {
        display: flex;
        justify-content: flex-end;
    }
    .intro-left-section {
        position: absolute;
        z-index: 2;

        .book-me-btn {
            margin-top: 20px;
            width: 114px;
            margin-bottom: 10px;
            height: 50px;
        }
        .intro-banner {
            background-image: url(../../../../../../images/intro-img.png);
            width: 420px;
            height: 160px;

            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;
            padding: 20px;
            h5 {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
            }
            h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 36px;

                color: #ffffff;
            }

            .intro-btn {
                font-weight: 400;

                font-size: 14px;
                line-height: 17px;
                color: #202020;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 20px;
                background: rgba(255, 255, 255, 0.8);
                border-radius: 20px;
                width: 250px;
                height: 37px;
                margin: 15px auto auto;
            }
        }
    }
    .hero-conent {
        height: 800px;

        .himself-section {
            position: relative;
            border-radius: 0px;
            width: 75%;
            top: -475px;
            left: 0%;
            height: 430px;
            background: rgb(255 255 255 / 67%);
            @media screen and (min-width: 1643px) {
                top: -495px !important;
            }
            p {
                font-size: 13px;
                font-weight: 500;
            }
            .modal-tabs {
                position: relative;
                top: -85px;
            }
            .slidecontainer {
                width: revert;
                margin-left: -38px;
                margin-right: 11px;
            }
            .playlist-section-text {
                background: #ffffff;
                border-radius: 20px;
                padding: 20px;
                margin-bottom: 20px !important;
                left: -52px;
                width: 270px;

                // @media screen and (max-width:1580px) {
                //     right: -193px;
                //     width: 350px;

                // }
                input[type="range"].range-slider {
                    width: 48px;
                    margin-right: 7px;
                }
                .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                    height: 20px !important;
                    width: 26px !important;
                    bottom: -24px !important;
                    border-radius: 50% !important;
                    display: flex !important;
                    color: #202020;
                    justify-content: center !important;
                    align-items: center !important;
                    background-color: #3860ee !important;
                    font-size: 8px;
                }
                .range-slider__wrap
                    .range-slider__tooltip.range-slider__tooltip--bottom
                    .range-slider__tooltip__caret::before {
                    border-bottom-color: #3860ee !important;
                    border-width: 0 0.3rem 0.3rem;
                    bottom: 25px;
                    left: 2px;
                }
                .range-slider__wrap {
                    position: relative;
                    display: block;
                    height: 16px !important;

                    .range-slider__tooltip {
                        z-index: 0 !important;

                        &.range-slider__tooltip--bottom {
                            bottom: calc(0.75em + 0.375rem + 1px + 0.4rem + 10px + 3px) !important;
                            top: auto !important;
                        }

                        .range-slider__tooltip__caret {
                            transform: translateX(50%) !important;
                        }
                    }
                }
                input[type="range"].range-slider {
                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        box-sizing: border-box;
                        border: none;
                        border-radius: 50%;
                        height: 8px !important;
                        background-color: #3860ee !important;
                        border: 1px solid black;
                        width: 8px !important;
                        cursor: pointer;
                        transition: box-shadow 0.5s ease;
                        margin-top: -3px !important;
                    }
                }
                input[type="range"].range-slider {
                    box-shadow: none;
                    height: 8px;
                    padding: 0px;
                }
                input[type="range"].range-slider {
                    &::-webkit-slider-runnable-track {
                        -webkit-appearance: none !important;
                        width: 100% !important;
                        margin-top: 8px;
                        height: 1px !important;
                        background-color: #202020 !important;
                        border: 2px solid #202020;
                        outline: none !important;

                        -webkit-transition: 0.2s !important;

                        border-radius: 30px !important;
                    }
                }
                .next-icon {
                    background: #202020;
                    border-radius: 23.0737px;
                    width: 20px;
                    padding: 2px;
                    text-align: center;
                    color: white;
                    font-size: 17px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 20px;
                }
                .setting-icons {
                    background: #202020;
                    margin-left: 3px;
                    border-radius: 24.0118px;
                    padding: 0px 5px;
                    color: white;
                    font-size: 12px;
                    display: flex;
                    margin-top: -5px;
                    margin-right: -55px;
                    margin-left: 22px;
                    .icons-set {
                        margin: 4px 4px;
                    }
                }
                .side-text {
                    /* Grey/9 */

                    background: #ffffff;
                    border-radius: 2.38694px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 95px;
                    padding: 7.139px 0.36517px 3.97823px;
                    font-weight: 500;
                    font-size: 5.40381px;
                    line-height: 100%;
                    margin: 0px 10px;
                    /* or 5px */
                    border: 2px solid #dda01a;

                    text-align: center;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                    color: #1c1c1c;
                }
                .text-gree {
                    display: flex;
                    position: relative;
                    top: 4px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 3.27619px 5.2419px;
                    width: 60.48px;
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    font-size: 8px;
                    height: 10.55px;

                    background: #e5e9ed;
                    border-radius: 1.96571px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 4.44588px;
                    line-height: 100%;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #101010;
                }
                .next-icon-center {
                    background: #202020;
                    border-radius: 50.7836px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: -4px 2px 0px;
                    .iconss {
                        font-size: 12px;
                        color: white;
                        margin: 4px;
                    }
                }

                .video-icon {
                    font-size: 25px;
                    color: #202020;
                    margin: 0px 2px;
                    svg {
                        margin-top: -11px;
                    }
                }
                .mic-img {
                    padding: 0px 5px;
                    img {
                        width: 75px;
                    }
                }
            }
            .tab-himself-text {
                height: 529.4px;
                padding: 40px;
            }
        }
        .video-img {
            background-image: url(../../../../../../images/video-img.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            height: 193.33px;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -40px;
            right: -40px;
            margin-bottom: 23px;
        }
        .video-img img {
            width: 100px;
            height: 100px;
        }
    }
    .himself-left {
        width: 50%;
        margin-right: 15px;
        p {
            overflow-y: scroll;
            height: 280px;
        }
    }
    .himself-right {
        width: 50%;
        height: 120px;
        overflow-y: scroll !important;
        padding-right: 10px;
        margin-top: 162px;
    }
}

.Neumorphic-design {
    .design-screen-desktop-3 {
        background: #f0f4f8;
    //     #parent-circle{
    //         width: 87%;
    // height: 133px;
    //     }
    #parent-circle {
        position: relative;
        width: 100%!important;
        height: 99px!important;
    }
    .hero-sidebar .profile-img{
        width: 60%!important;
       
        margin-right: 10% !important;
        margin-top: -99% !important;
        @media screen and (max-width:1450px) {
        
                width: 60% !important;
                margin-right: -9% !important;
                margin-top: -110% !important;
          
        }
    }

    #parent-circle  .printerest {
        background-color: white;
        transform: rotate(5deg) translate(313%) rotate(-5deg);

      }
      #parent-circle  .facebookk {
    background-color: white;
    transform: rotate(30deg) translate(313%) rotate(-46deg);
      }
      #parent-circle  .instaa {
        background-color: white;
        transform: rotate(60deg) translate(313%) rotate(-91deg);
      }
      #parent-circle  .twitter {
        background-color: white;
        transform: rotate(90deg) translate(313%) rotate(-92deg);
      }
      #parent-circle  .innn {
        background-color: white;
        transform: rotate(115deg) translate(313%) rotate(-95deg);
      }
    #parent-circle  .tiktok {
        background-color: white;
        transform: rotate(143deg) translate(313%) rotate(-166deg);
      }
         #parent-circle  .videoo {
        background-color: white;
        color: rgb(124 186 167);
        transform: rotate(194deg) translate(313%) rotate(-193deg);
      } 
  
        #parent-circle  .snapchat {
        background-color: white;
        color: rgb(124 186 167);
        transform: rotate(220deg) translate(313%) rotate(-215deg);
      }   #parent-circle  .logo-f{
        background-color: white;
        color: rgb(124 186 167);
        border-radius: 50px;
        padding: 0px 2px 3px;
font-size: 11px;
        transform: rotate(249deg) translate(313%) rotate(-251deg);
      }   #parent-circle  .logo2 {
        background-color: white;
        transform: rotate(279deg) translate(313%) rotate(84deg);
      }   #parent-circle  .logo-3 {
        background-color: white;
        transform: rotate(-53deg) translate(313%) rotate(-278deg);
      }   #parent-circle  .logo-4 {
        background-color: white;
        transform: rotate(-22deg) translate(313%) rotate(13deg);
      } 
      #parent-circle  .videooo {
        background-color: white;
        color: rgb(124 186 167);
        transform: rotate(169deg) translate(313%) rotate(-171deg);
      } 
    .bookme-oterr .book-me-mue {
        left: -33% !important;
    }
    @media screen and (max-width:1770px) and (min-width:1690px) {
        .hero-sidebar .profile-img{
            margin-right: 2% !important;
            margin-top: -104% !important;
        }
       
    }
        .arrow-buttonn   .icon-arrow {
            transform: rotate(180deg);
             color: #e0c67a!important;
            font-size: 90px;
            position: absolute;
            right: -37px;
            top: 581px;
            z-index: 3;

        }
        .arrow-button   .icon-arrow {
            transform: rotate(180deg);
             color: #e0c67a!important;
            font-size: 90px;
            position: absolute;
            right: -37px;
            top: 581px;
            z-index: 3;

        }
        .himself-section .book-me-mue {
            display: block;
            left: -274px;
            position: relative;
            top: -121px!important;
        }
        .timeline-glass {
            display: none;
        }
        .reg-sond {
            display: block;
        }
        .glass-sond {
            display: none;
        }
        .meu-playlist-section {
            background: #f0f4f8;
            box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
            border-radius: 6.41451px;
            padding: 15px !important;
            .next-icon-center .iconss {
                font-size: 10px;
                color: #c49530;
                margin: 8px;
            }
        }
        .playlist-card .icon-arrow {
            color: #e0c67a;
        }
        .testimonial img {
            width: 115px !important;
        }
        @media screen and (min-width: 1800px) {
            .main-content .hero-sidebar .testimonial img {
                width: 150px !important;
            }
        }

        .meu-timeline {
            display: block;
        }

        .timeline-gold {
            display: block;
        }
        .test-reg {
            display: none;
        }
        .himself-section {
            top: -395px;
            z-index: 2;
        }
        .logo-intro {
            width: 100%;
            text-align: right;
            position: absolute !important;
            top: 364px;
            .img-logo {
                margin-right: 14px;
                width: 110px;
                border-radius: 10px;
                background: #f0f4f8;
                box-shadow: inset -1px -1px 2px #ffffff, inset 1px 1px 2px rgba(178, 189, 203, 0.9);
                border-radius: 15px;
                padding: 10px;
            }
        }
        .book-me-mue {
            display: block;
        }
        .book-me-btn-sidebar {
            display: none;
        }
        .timeline p {
            font-weight: 400;
            font-size: 7px !important;
            line-height: 8px;
        }
        .img-logo {
            margin-right: 14px;
            width: 90px;
            border-radius: 10px;
            background: #f0f4f8;
            box-shadow: inset -1px -1px 2px #ffffff, inset 1px 1px 2px rgba(178, 189, 203, 0.9);
            border-radius: 5px;
            padding: 5px;
            border: none !important;
        }
        .reg-timeline-desktop {
            display: none;
        }
        reg-timeline {
            display: none;
        }
        .meu-timeline {
            display: block;
            .timeline {
                display: flex;
                flex-direction: column;
                left: 0;
                li {
                    width: 75px;
                    height: 75px;
                    background: #f0f4f8;
                    box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                }
            }
            .timeline:after {
                border: 3px solid #f0f4f8;
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9);
            }
            .timeline-top {
                background: #f0f4f8 !important;
                box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
                color: black !important;
                top: 19px;
                left: -21px;
            }
            .active-oter {
                width: 90px;
                height: 90px;
                margin-bottom: 30px;
                padding: 7px;
                z-index: 2;
                border-radius: 50px;

                background: #f0f4f8;
                box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
            }
        }
        .reg-timeline {
            display: none;
        }
        .hero-sidebar {
            box-shadow: -2px -2px 8px #ffffff, 2px 2px 5px #f0f4f8;
            border: none !important;
            .book-me-mue {
                display: block;
                width: 146px;
                margin-left: -10px;
            }
        }
        .hero-section-mue {
            display: block;
        }
        .hero-section-mue .content-img {
            width: 67% !important;
            right: -26%;
            height: 75% !important;
            box-shadow: inset -4px -4px 10px #ffffff, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
            border-radius: 20px;
        }
        .hero-section-reg {
            display: none;
        }
        .hero-section-mue {
            display: block;
        }
        .navbar-bars {
            background: #f0f4f8;
            box-shadow: -4px -4px 10px #ffffff, 5px 5px 10px rgba(178, 189, 203, 0.8);
            border-radius: 5px;
        }
        .navbar-top {
            .switch {
                background: linear-gradient(
                    180deg,
                    #facb30 -18%,
                    #fef9c8 22.5%,
                    #c49530 67.84%,
                    #fff8c4 116%
                ) !important;
                height: 34.24px !important;
            }
        }
        .switch-outer {
            width: 110px;
            left: 22px;
            height: 60px;

            border-radius: 49.5px;
            height: 45.88px;
            background-color: #f0f4f8;
            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 35.2941px;
            .switch {
                background: linear-gradient(
                    180deg,
                    #facb30 -18%,
                    #fef9c8 22.5%,
                    #c49530 67.84%,
                    #fff8c4 116%
                ) !important;

                box-shadow: 1.0084px 1.0084px 1.0084px #b6c8da, -1.0084px -1.0084px 1.0084px #ffffff !important;
                border-radius: 29.2437px !important;
            }
            .switch::before {
                background: #eaeff6 !important;
                box-shadow: inset 0px -4.03361px 8.06723px #ffffff, inset 0px 4.03361px 6.05042px #a0b9d0;
                top: 2px;
                left: 40px;
            }
            input:checked + .switch::before {
                left: 5px;
            }
        }
        .hero-conent {
            .video-img {
                height: 137.33px;
                border-radius: 15px;
            }
            .himself-section {
                background: #f0f4f8 !important;
                box-shadow: inset -4px -4px 10px #ffffff, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
                border-radius: 0px 25px 25px 0px !important;
                padding: 23px 23px 23px 0px;
                .tab-section {
                    background: #f0f4f8;
                    box-shadow: -4px -4px 10px #ffffff, 5px 5px 10px rgba(178, 189, 203, 0.8);
                    border-radius: 0px 20px 20px 0px;

                    height: 385px;
                    .himself-left p {
                        overflow-y: scroll!important;
                        height: 235px;
                    }
             .himself-right {
                        height: 128px!important;
                    }
                }
            }
            .design-about {
                background: #f0f4f8 !important;
                box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
                border-radius: 0px !important;
                margin: 10px 0px !important;
            }
            .nav-item .active {
                background: #f0f4f8 !important;
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb !important;

                color: #202020 !important;
            }
        }
        .main-content {
            .playlist-card {
                background: #f0f4f8;
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 0px 0px 20px 20px;
            }
            .intro-card {
                background: #f0f4f8;
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 0px 0px 20px 20px;
                z-index: 2;
                .icon-arrow {
                    color: #e0c67a;
                }
            }
            .hero-sidebar {
                .name-btn {
                    background: #f0f4f8;
                    border: 1.14031px solid #f0f4f8;
                    box-shadow: inset -4.80637px -4.80637px 7.69018px #ffffff,
                        inset 3.84509px 3.84509px 7.69018px #889fb6;
                    border-radius: 4px;
                }
            }
        }
        .navbar-top {
            .mi-info {
                font-size: 24px;

                background: #f0f4f8;
                box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                border-radius: 30px;
                padding: 3px 7px 7px 7px;
            }
            .bottom-tabs svg {
                font-size: 31px;

                padding: 6px;
                background: #f0f4f8;
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 20px;
            }
            .search-area {
                .search-field {
                    width: 320px;
                    margin: 0px 29px 0px;

                    background: #f0f4f8;
                    border: 1px solid transparent;
                    box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                    border-radius: 4px;

                    color: #808492;
                    .icon-mgni {
                        color: #808492;
                    }
                }
            }
        }
        .bookme-inner {
            background: #f0f4f8 !important;
        }
        .navbar-top {
            background: #f0f4f8 !important;
        }
        .hero-sidebar {
            background: #f0f4f8 !important;
        }
        .content-text {
            background: #f0f4f8 !important;
        }
        .social-icon-navbar li {
            background: #f0f4f8;
            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 30px;
            svg {
                color: #202020;
            }
        }
        .range-slider__tooltip__caret::before {
            border-bottom-color: #facb30 !important;
            left: 13px;
        }
        .social-icon-navbar li {
            background: #f0f4f8;
            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 30px;
            svg {
                color: #202020;
            }
        }
        .meu-playlist-section {
            background: #f0f4f8;
            box-shadow: 1.75824px 1.75824px 3.51648px #b7c7d7, -1.75824px -1.75824px 3.51648px #ffffff;
            border-radius: 3.42107px;
            padding: 8px;
            margin-bottom: 10px;
        }
        .gold-btn {
            background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border-radius: 30px;
            padding: 20px;
            width: 70%;
            display: block;
            text-align: center;
            margin-bottom: 63px;
            margin-top: -27px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #202020;
        }
        .reg-plist {
            display: none;
        }
        .mue-video-section {
            display: block;
            width: 50%;
            padding: 10px !important;
            margin: auto;
            position: relative;
            margin-top: 20px;
            background: #f0f4f8;
            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 8px;
            right: -61px;
            height: 100%;
            top: -53px;
            justify-content: center;
            align-items: center;

            .video-img {
                background-repeat: no-repeat;
                background-position: center;

                width: 177px;
                height: 118.63px;
                border-radius: 10.5263px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .meu-show {
            display: block;
            text-align: right;
            width: 100%;
            z-index: 3;
            position: relative;
        }
        .margin-left-btn {
            margin-left: 9px;
        }
        .playlist-section-text {
            width: 230px;
            z-index: 3;
            position: relative;
            right: revert;

            border-radius: 8px;

            margin: revert;

            background: #f0f4f8;
            /* Neo/Light/Inner/4 */

            border-radius: 8px;
            .slidecontainer {
                width: revert;
                margin-right: -8px;
            }

            input[type="range"].range-slider {
                width: 66px;
                margin-right: 17px;
            }
            .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                height: 20px !important;
                width: 30px !important;
                bottom: -24px !important;
                border-radius: 50% !important;

                left: -10px;

                display: flex !important;
                color: #202020 !important;
                justify-content: center !important;
                align-items: center !important;
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            }
            .range-slider__wrap
                .range-slider__tooltip.range-slider__tooltip--bottom
                .range-slider__tooltip__caret::before {
                border-bottom-color: #facb30 !important;
                left: 13px;
            }
            .next-icon {
                background: #f0f4f8 !important;
                box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                border-radius: 23.0737px;
                width: 30px;
                text-align: center;
                color: #202020 !important;
                font-size: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
            }
            .next-icon-center {
                color: #202020 !important;
            }

            .setting-icons {
                background: #f0f4f8 !important;
                box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                margin-left: 13px;
                border-radius: 24.0118px;
                padding: 1px;
                margin-right: -42px !important;
                margin-left: 12px !important;
                color: #202020 !important;
                font-size: 11px;
                .icons-set {
                    margin: 4px 5px;
                }
            }
            .side-text {
                /* Grey/9 */

                background: #f0f4f8 !important;
                box-shadow: 1.75824px 1.75824px 3.51648px #b7c7d7, -1.75824px -1.75824px 3.51648px #ffffff;
                border-radius: 2.38694px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11.139px 6.36517px 3.97823px;
                font-weight: 500;
                font-size: 6.40381px;
                line-height: 100%;
                margin: 0px 10px;
                /* or 5px */
                border: 2px solid #dda01a;

                text-align: center;
                letter-spacing: 0.01em;
                text-transform: uppercase;
                color: #202020 !important;
            }
            .text-gree {
                display: flex;
                position: relative;
                top: 4px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 3.27619px 5.2419px;
                width: 105.48px;
                display: flex;
                justify-content: center;
                margin: auto;
                font-size: 8px;
                height: 10.55px;

                background: #f0f4f8 !important;
                box-shadow: inset 0px -1.75824px 3.51648px #ffffff, inset 0px 1.75824px 2.63736px #a0b9d0;
                border-radius: 1.08597px;
                font-style: normal;
                font-weight: 700;
                font-size: 4.44588px;
                line-height: 100%;
                text-align: center;
                letter-spacing: 0.01em;
                color: #202020 !important;
            }
            .next-icon-center {
                background: #f0f4f8 !important;
                box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                border-radius: 50.7836px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: -4px 2px 0px;
                .iconss {
                    font-size: 10px;
                    color: #c49530 !important;
                    margin: 3px;
                }
            }

            .video-icon {
                font-size: 16px;
                background: #f0f4f8 !important;
                box-shadow: 1.17633px 1.17633px 1.7645px #7b858f, -1.17633px -1.17633px 1.56844px #ffffff;
                border-radius: 50%;
                color: #c49530 !important;
                width: 18.64px;
                height: 18.64px;
                margin: -5px 4px 0px 3px !important;
                padding: 5px 1px !important;
                svg {
                    margin-top: -26px;
                    font-size: 18px;
                    /* margin: 0px; */
                    margin-left: 0px;
                }
            }
            .mic-img {
                padding: 0px 20px;
                img {
                    width: 90px;
                }
            }
        }
        .main-content
            .playlist-card
            .playlist-section-text
            .range-slider__wrap
            .range-slider__tooltip.range-slider__tooltip--bottom
            .range-slider__tooltip__caret::before {
            border-bottom-color: #facb30 !important;
            left: 13px !important;
        }
        .main-content .playlist-card .playlist-section-text .video-icon svg {
            margin-top: -26px !important;
        }
        .testimonial .timeline-top {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.3) 3.01%,
                rgba(255, 255, 255, 0) 103.3%
            ) !important;
            box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
        }

        .close-btn {
            border: none;
            background-image: url(../../../../../../images/close-batch.png) !important;
            background-repeat: no-repeat;
            top: 2px !important;
            width: 52px !important;
            height: 68px !important;
            margin-top: -11px !important;
            top: 3px !important;
            right: 9px !important;
            font-size: 34px !important;
            color: grey !important;
            position: absolute;
            background-color: transparent !important;
            svg {
                margin-top: -34px;
                margin-left: 10px;
                font-size: 28px;
            }
        }
        .hamburger-outer .sidebar-li {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            background: #f0f4f8;
            background: #f0f4f8;
            box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
            text-align: center;
            border-bottom: 1px solid transparent;
            padding: 20px;
            color: #f0f4f8;
            color: #202020;
            margin: 16px;
        }
        .hamburger-outer {
            .bottom-tabs {
                color: #202020 !important;
                padding: 6px;

                border-radius: 20px;
                svg {
                    padding: 6px;
                    background: #f0f4f8;
                    box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                    border-radius: 20px;
                }
                h6 {
                    color: #202020 !important;
                }
            }
        }
        .forms-inpt {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.3) 3.01%,
                rgba(255, 255, 255, 0) 103.3%
            ) !important;

            .form-control {
                border: 1px solid transparent !important;
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 4px !important;
            }
        }
        .bookme-oterr .bookme-inner {
            background: #cbd5e0;
            z-index: 3;
            padding: 20px;
            width: 400px;
            position: absolute;
            box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
            top: 147px;
            left: 193px;
        }
        .mue-shape {
            position: absolute;
            z-index: 1;
            top: 319px;
            width: 141px;
            left: 28px !important;
        }
        .icons-circle {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.3) 3.01%,
                rgba(255, 255, 255, 0) 103.3%
            ) !important;

            box-shadow: inset -2.01397px -3.02095px 4.02793px #ffffff, inset 2.01397px 2.01397px 4.02793px #a0b9d0;
            border-radius: 50%;

        }
        .profile-img {
            margin-left: 15px !important;
            margin-top: -149px !important;
        }
        @media screen and (min-width: 1850px) {
            .profile-img {
                margin-left: -4px !important;
                margin-top: -153px !important;
            }
        }
        @media screen and (max-width: 1850px) {
            .profile-img {
                margin-left: 3px !important;
                margin-top: -153px !important;
            }
        }
        @media screen and (max-width: 1724px) {
            .profile-img {
                margin-left: 13px !important;
                margin-top: -153px !important;
            }
        }
        @media screen and (max-width: 1344px) {
            .profile-img {
                margin-left: 21px !important;
                margin-top: -153px !important;
            }
        }
        @media screen and (max-width: 1500px) {
            .himself-section {
                top: -282px !important;
                z-index: 2;
            }
        }
        .book-me-btn {
            background: transparent !important;
        }
    }
}
.Glassmorphic-design {
    .design-screen-desktop-3{
        #parent-circle .circle {
            width: 20px;
            height: 20px;
            }
            
    }

          .hero-conent .nav-item .active {
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
 
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%)!important;
    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        }
    .hamburger-outer .bookme-oter .bookme-inner .forms-inpt .form-control {
        background: linear-gradient(
            158.72deg,
            rgba(255, 255, 255, 0.5) 3.01%,
            rgba(255, 255, 255, 0.05) 103.3%
        ) !important;
        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        box-shadow: none !important;
    }

    .design-screen-desktop-3 {
        #parent-circle{
            width: 58%;
            height: 48px;
        }
   
    
        .arrow-button{
            .icon-arrow{
                left: 141px!important;
                z-index: 2;
                top: -52px!important;
            }
        }
        .arrow-buttonn {
            z-index: 2;
         
        }
        .arrow-buttonn   .icon-arrow {
            transform: rotate(180deg);
             color: #e0c67a!important;
            font-size: 90px;
            position: absolute;
            right: -37px;
            top: 581px;
            z-index: 3;

        }
        .arrow-button {
            z-index: 2;
            position: relative;
        }
        .arrow-button   .icon-arrow {
            transform: rotate(180deg);
             color: #e0c67a!important;
            font-size: 90px;
            position: absolute;
            right: -37px;
            top: 581px;
            z-index: 3;

        }
        .hamburger-outer .forms-inpt {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.5) 3.01%,
                rgba(255, 255, 255, 0.05) 103.3%
            ) !important;
            border: 1px solid rgba(255, 255, 255, 0.4901960784);
        }
        .hamburger-outer .testimonial .timeline-top {
            width: 78px !important;
            padding: 6px !important;
            height: 78px !important;
            top: 109px !important;
            left: 14px !important;
        }
        .hero-sidebar {
            .meu-timeline .timeline {
                margin-left: 14px !important;
            }
            .testimonial {
                .timeline-top {
                    width: 76px !important;
                    padding: 6px !important;
                    height: 76px !important;
                    top: 100px !important;
                    left: 9px !important;
                }
            }
        }
        .hamburger-outer {
            left: -277px !important;
        }
        .timeline-glass {
            display: block;
        }
        .vector-shape {
            display: block;
            width: 25%;
            position: absolute;
            bottom: 0px;
            left: 0px;
        }
        background: linear-gradient(315.3deg, #9595b8 3.28%, #ecedf5 45.05%, #c8c9d2 67.11%, #97999d 93.39%);
        .reg-sond {
            display: none;
        }
        .glass-sond {
            display: block !important;
        }
        .book-me-geo {
            display: block;
        }
        .meu-icon {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.5) 3.01%,
                rgba(255, 255, 255, 0.05) 103.3%
            ) !important;
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            padding: 9px 1px 0px;
            width: 27px;
            height: 27px;
        }
        .bookme-oterr {
            .book-me-geo {
                width: 145px;
                text-align: center;
                margin-left: 35px;
                position: relative;
                top: -105px;
            }
        }
        .book-me-mue {
            display: none !important;
        }
        @media screen and (min-width: 1850px) {
            .hero-sidebar .testimonial .timeline-top {
                width: 90px !important;
                padding: 6px !important;
                height: 90px !important;
                top: 118px !important;
                left: 10px !important;
            }
        }
        @media screen and (min-width: 1400px) {
            .hero-sidebar .testimonial .timeline-top {
                width: 85px !important;
                padding: 6px !important;
                height: 85px !important;
                top: 114px !important;
                left: 10px !important;
            }
        }

        .main-content .hero-sidebar .timeline li {
            margin: 2px 9px 10px 2px;
        }
        .meu-playlist-section {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.3) 3.01%,
                rgba(255, 255, 255, 0) 103.3%
            ) !important;

            box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
            border-radius: 6.41451px;
            padding: 15px !important;
            .next-icon-center .iconss {
                font-size: 10px;
                color: black !important;
                margin: 8px;
            }
        }
        .playlist-card .icon-arrow {
            color: #e0c67a;
        }
        .testimonial img {
            width: 115px !important;
        }
        @media screen and (min-width: 1800px) {
            .main-content .hero-sidebar .testimonial img {
                width: 150px !important;
            }
        }

        .meu-timeline {
            display: block;
        }

        .timeline-gold {
            display: none;
        }
        .test-reg {
            display: none;
        }
        .himself-section {
            top: -395px;
            z-index: 2;
        }
        .logo-intro {
            width: 100%;
            text-align: right;
            position: absolute !important;
            top: 364px;
            .img-logo {
                margin-right: 14px;
                width: 110px;
                border-radius: 10px;
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.7) 3.01%,
                    rgba(255, 255, 255, 0.02) 103.3%
                );
                /* Background blur/Light/3 */

                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(5px);
                border-radius: 15px;
                padding: 10px;
            }
        }
        .book-me-mue {
            display: block;
        }
        .book-me-btn-sidebar {
            display: none;
        }
        .timeline p {
            font-weight: 400;
            font-size: 7px !important;
            line-height: 8px;
            color: white !important;
        }
        .img-logo {
            margin-right: 14px;
            width: 90px;
            border-radius: 10px;
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.3) 3.01%,
                rgba(255, 255, 255, 0) 103.3%
            ) !important;

            box-shadow: inset -1px -1px 2px #ffffff, inset 1px 1px 2px rgba(178, 189, 203, 0.9);
            border-radius: 5px;
            padding: 5px;
            border: none !important;
        }
        .reg-timeline-desktop {
            display: none;
        }
        reg-timeline {
            display: none;
        }
        .meu-timeline {
            display: block;
            .timeline {
                display: flex;
                flex-direction: column;
                left: 0;
                li {
                    width: 75px;
                    height: 75px;
                    background: linear-gradient(
                        158.72deg,
                        rgba(0, 0, 0, 0.5) 3.01%,
                        rgba(0, 0, 0, 0.3) 103.3%
                    ) !important;
                    box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                }
            }
            .timeline:after {
                border: 4px solid #c6c6c638;
                background: linear-gradient(158.72deg, rgb(255 255 255 / 90%) 3.01%, rgb(255 255 255 / 48%) 103.3%);

                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(5px);

                height: 12px;
            }
            .timeline-top {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.9) 3.01%,
                    rgba(255, 255, 255, 0.1) 103.3%
                ) !important;

                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25) !important;
                backdrop-filter: blur(1.5px) !important;

                color: black !important;
                top: 19px;
                left: -26px;
                border: 1px solid #fff;
            }
            .active-oter {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.07) 3.01%,
                    rgba(255, 255, 255, 0.02) 103.3%
                );
                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(5px);
                width: 95px;
                height: 95px;
                margin-bottom: 25px;
                padding: 7px;
                z-index: 2;
                border: 1px solid #fff;
                border-radius: 50px;

                -webkit-backdrop-filter: blur(5px);
                backdrop-filter: blur(5px);
            }
        }
        .reg-timeline {
            display: none;
        }
        .hero-sidebar {
            box-shadow: -2px -2px 8px #ffffff, 2px 2px 5px #f0f4f8;
            border: none !important;
            .book-me-geo {
                width: 125px;
                margin-left: -10px;
            }
        }
        .hero-section-mue {
            display: block;
        }
        .hero-section-mue .content-img {
            width: 67% !important;
            right: -26%;
            height: 75% !important;
            box-shadow: inset -4px -4px 10px #ffffff, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
            border-radius: 20px;
        }
        .hero-section-reg {
            display: none;
        }
        .hero-section-mue {
            display: block;
        }
        .navbar-bars {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
            border: 1px solid #ffffff7d;
            border-radius: 5px;
        }
        .navbar-top {
            .switch {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%);
                height: 34.24px !important;
            }
        }
        .switch-outer input:checked + .switch {
            background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%);

            box-shadow: 1.0084px 1.0084px 1.0084px #b6c8da, -1.0084px -1.0084px 1.0084px #ffffff !important;
            border-radius: 29.2437px !important;
        }
        .switch-outer {
            width: 110px;
            left: 22px;
            height: 60px;

            border-radius: 49.5px;
            height: 45.88px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
            border: 1px solid #ffffff7d;
            border-radius: 35.2941px;
            .switch {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%);

                box-shadow: 1.0084px 1.0084px 1.0084px #b6c8da, -1.0084px -1.0084px 1.0084px #ffffff !important;
                border-radius: 29.2437px !important;
            }
            .switch::before {
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                top: 2px;
                left: 40px;
            }
            input:checked + .switch::before {
                left: 5px;
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            }
        }
        .hero-conent {
            .video-img {
                height: 137.33px;
                border-radius: 15px;
            }
            .himself-section {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.3) 3.01%,
                    rgba(255, 255, 255, 0) 103.3%
                ) !important;

                backdrop-filter: blur(21px);
                box-shadow: none !important;
                border-radius: 0px 25px 25px 0px !important;
                padding: 23px 23px 23px 0px;
                .tab-section {
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.8) 3.01%,
                        rgba(255, 255, 255, 0.05) 103.3%
                    ) !important;
                    box-shadow: none !important;
                    border-radius: 0px 20px 20px 0px;

                    height: 385px;
                    .himself-left p {
                        overflow-y: scroll;
                        height: 235px;
                    }
                    .himself-right {
                        height: 130px;
                    }
                }
            }
            .design-about {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                );
                border: 1px solid rgb(255 255 255) !important;
                border-radius: 0px !important;
                margin: 10px 0px !important;
            }
            .nav-item .active {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                );
                border: 1px solid rgb(255 255 255) !important;

                color: #202020 !important;
            }
        }
        .main-content {
            .content-hero {
                background: linear-gradient(315.3deg, #9595b8 3.28%, #ecedf5 45.05%, #c8c9d2 67.11%, #97999d 93.39%);
            }
            .playlist-card {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.3) 3.01%,
                    rgba(255, 255, 255, 0) 103.3%
                ) !important;
                /* Background blur/Light/3 */

                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(5px);
                /* Note: backdrop-filter has minimal browser support */
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 0px 0px 20px 20px;
            }
            .intro-card {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.7) 3.01%,
                    rgba(255, 255, 255, 0.02) 103.3%
                ) !important;
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 0px 0px 20px 20px;
                z-index: 2;
                backdrop-filter: blur(5px);
                .icon-arrow {
                    color: #e0c67a!important;
                }
                h1 {
                    font-weight: 700;
                    font-size: 45px;
                    line-height: 47px;
                }
                h2 {
                    font-size: 34px;
                }
                h4 {
                    font-size: 34px;
                }
                p {
                    font-size: 17px;
                    line-height: 16px;
                }
            }
            .intro-inner {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                );
                border: 1px solid rgba(255, 255, 255, 0.4901960784);
                margin: -10px 5px 9px;
                padding: 20px;
                border-radius: 0px 0px 20px 20px;
            }
            .hero-sidebar {
                .name-btn {
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.5) 3.01%,
                        rgba(255, 255, 255, 0.05) 103.3%
                    );

                    border: 1px solid #ffffff7d;
                    border-radius: 4px;
                }
            }
        }
        .navbar-top {
            .mi-info {
                font-size: 24px;

                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                );

                border: 1px solid #ffffff7d;
                border-radius: 30px;
                padding: 3px 7px 7px 7px;
            }
            .bottom-tabs svg {
                font-size: 31px;

                padding: 6px;
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                );

                border: 1px solid #ffffff7d;
                border-radius: 20px;
            }
            .search-area {
                form {
                    width: 320px;
                    margin-right: 15px;
                    background: linear-gradient(#d6d7db, #d4d4da) padding-box,
                        linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                    border: 3px solid transparent !important;
                }
                .search-field {
                    width: 320px;
                    border: 1px solid transparent !important;
                    outline: 1px solid transparent !important;
                    margin: 0px 29px 0px;

                    border-radius: 4px;

                    color: #808492;
                    .icon-mgni {
                        color: #808492;
                    }
                }
            }
        }
        .bookme-inner {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.7) 3.01%,
                rgba(255, 255, 255, 0.02) 103.3%
            ) !important;
        }
        .navbar-top {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.7) 3.01%,
                rgba(255, 255, 255, 0.02) 103.3%
            ) !important;
        }
        .hero-sidebar {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.5) 3.01%,
                rgba(255, 255, 255, 0.05) 103.3%
            ) !important;
        }
        .content-text {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.7) 3.01%,
                rgba(255, 255, 255, 0.02) 103.3%
            ) !important;
        }
        .social-icon-navbar li {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);

            border: 1px solid #ffffff7d;
            border-radius: 30px;
            svg {
                color: #202020;
            }
        }
        .range-slider__tooltip__caret::before {
            border-bottom-color: #facb30 !important;
            left: 13px;
        }
        .social-icon-navbar li {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);

            border: 1px solid #ffffff7d;
            border-radius: 30px;
            svg {
                color: #202020;
            }
        }
        .meu-playlist-section {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.5) 3.01%,
                rgba(255, 255, 255, 0.05) 103.3%
            ) !important;
            border: 1px solid rgba(255, 255, 255, 0.4901960784);
            box-shadow: none !important;
            border-radius: 10px !important;
            border-radius: 3.42107px;
            padding: 8px;
            margin-bottom: 10px;
        }
        .gold-btn {
            background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border-radius: 30px;
            padding: 20px;
            width: 70%;
            display: block;
            text-align: center;
            margin-bottom: 63px;
            margin-top: -27px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #202020;
        }
        .reg-plist {
            display: none;
        }
        .mue-video-section {
            display: block;
            width: 50%;
            padding: 10px !important;
            margin: auto;
            position: relative;
            margin-top: 20px;
            background: #f0f4f8;
            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 8px;
            right: -61px;
            height: 100%;
            top: -53px;
            justify-content: center;
            align-items: center;

            .video-img {
                background-repeat: no-repeat;
                background-position: center;

                width: 177px;
                height: 118.63px;
                border-radius: 10.5263px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .glass-show {
            display: block;
            text-align: right;
            width: 100%;
            z-index: 3;
            position: relative;
        }
        .margin-left-btn {
            margin-left: 9px;
        }
        .playlist-section-text {
            width: 230px;
            z-index: 3;
            position: relative;
            right: revert;

            border-radius: 8px;

            margin: revert;

            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.3) 3.01%,
                rgba(255, 255, 255, 0) 103.3%
            ) !important;

            /* Neo/Light/Inner/4 */

            border-radius: 8px;
            .slidecontainer {
                width: revert;
                margin-right: -8px;
            }

            input[type="range"].range-slider {
                width: 66px;
                margin-right: 17px;
            }
            .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                height: 20px !important;
                width: 30px !important;
                bottom: -24px !important;
                border-radius: 50% !important;

                left: -10px;

                display: flex !important;
                color: #202020 !important;
                justify-content: center !important;
                align-items: center !important;
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            }
            .range-slider__wrap
                .range-slider__tooltip.range-slider__tooltip--bottom
                .range-slider__tooltip__caret::before {
                border-bottom-color: #facb30 !important;
                left: 13px;
            }
            .next-icon {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 23.0737px;
                width: 30px;
                text-align: center;
                color: #202020 !important;
                font-size: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
            }
            .next-icon-center {
                color: #202020 !important;
            }

            .setting-icons {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                margin-left: 13px;
                border-radius: 24.0118px;
                padding: 1px;
                margin-right: -42px !important;
                margin-left: 12px !important;
                color: #202020 !important;
                font-size: 11px;
                .icons-set {
                    margin: 4px 5px;
                }
            }
            .side-text {
                /* Grey/9 */

                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;

                border-radius: 2.38694px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11.139px 6.36517px 3.97823px;
                font-weight: 500;
                font-size: 6.40381px;
                line-height: 100%;
                margin: 0px 10px;
                /* or 5px */
                border: 2px solid #dda01a;

                text-align: center;
                letter-spacing: 0.01em;
                text-transform: uppercase;
                color: #202020 !important;
            }
            .text-gree {
                display: flex;
                position: relative;
                top: 4px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 3.27619px 5.2419px;
                width: 105.48px;
                display: flex;
                justify-content: center;
                margin: auto;
                font-size: 8px;
                height: 10.55px;

                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 1.08597px;
                font-style: normal;
                font-weight: 700;
                font-size: 4.44588px;
                line-height: 100%;
                text-align: center;
                letter-spacing: 0.01em;
                color: #202020 !important;
            }
            .next-icon-center {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 50.7836px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: -4px 2px 0px;
                .iconss {
                    font-size: 10px;
                    color: black !important;
                    margin: 3px;
                }
            }

            .video-icon {
                font-size: 16px;
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 50%;
                color: black !important;
                width: 18.64px;
                height: 18.64px;
                margin: -5px 4px 0px 3px !important;
                padding: 5px 1px !important;
                svg {
                    margin-top: -26px;
                    font-size: 18px;
                    /* margin: 0px; */
                    margin-left: 0px;
                }
            }
            .mic-img {
                padding: 0px 20px;
                img {
                    width: 90px;
                }
            }
        }
        .main-content
            .playlist-card
            .playlist-section-text
            .range-slider__wrap
            .range-slider__tooltip.range-slider__tooltip--bottom
            .range-slider__tooltip__caret::before {
            border-bottom-color: #facb30 !important;
            left: 13px !important;
        }
        .main-content .playlist-card .playlist-section-text .video-icon svg {
            margin-top: -26px !important;
        }
        .testimonial .timeline-top {
            background: #d9d9d9 !important;
            transform: matrix(-1, 0, 0, 1, 0, 0);
            border: 1px solid white;
        }

        .close-btn {
            border: none;
            background-image: url(../../../../../../images/close-batch.png) !important;
            background-repeat: no-repeat;
            top: 2px !important;
            width: 52px !important;
            height: 68px !important;
            margin-top: -11px !important;
            top: 3px !important;
            right: 9px !important;
            font-size: 34px !important;
            color: grey !important;
            position: absolute;
            background-color: transparent !important;
            svg {
                margin-top: -34px;
                margin-left: 10px;
                font-size: 28px;
            }
        }
        .hamburger-outer .sidebar-li {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.5) 3.01%,
                rgba(255, 255, 255, 0.05) 103.3%
            ) !important;
            border: 1px solid rgba(255, 255, 255, 0.4901960784);
            text-align: center;
            border-bottom: 1px solid transparent;
            padding: 20px;
            color: #f0f4f8;
            color: #202020;
            margin: 16px;
        }
        .hamburger-outer {
            .bottom-tabs {
                color: #202020 !important;
                padding: 6px;

                border-radius: 20px;
                svg {
                    padding: 6px;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.5) 3.01%,
                        rgba(255, 255, 255, 0.05) 103.3%
                    );

                    border: 1px solid #ffffff7d;
                    border-radius: 20px;
                }
                h6 {
                    color: #202020 !important;
                }
            }
        }
        .forms-inpt {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.8) 3.01%,
                rgba(255, 255, 255, 0.05) 103.3%
            ) !important;

            .form-control {
                border: 1px solid transparent !important;
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 4px !important;
            }
        }
        .bookme-oterr .bookme-inner {
            background: #cbd5e0;
            z-index: 3;
            padding: 20px;
            width: 400px;
            position: absolute;
            box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
            top: 147px;
            left: 193px;
        }
        .mue-shape {
            position: absolute;
            z-index: 1;
            top: 319px;
            width: 141px;
            opacity: 0.2;
            left: 28px !important;
        }
        .icons-circle {
            box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);

            border: 12.047px solid #f2f3f7;
            border-radius: 50%;
            width: 130px !important;
        }
        .profile-img {
            margin-left: 15px !important;
            margin-top: -149px !important;
        }
        @media screen and (min-width: 1850px) {
            .profile-img {
                margin-left: -4px !important;
                margin-top: -153px !important;
            }
        }
        @media screen and (max-width: 1850px) {
            .profile-img {
                margin-left: 3px !important;
                margin-top: -153px !important;
            }
        }
        @media screen and (max-width: 1724px) {
            .profile-img {
                margin-left: 13px !important;
                margin-top: -153px !important;
            }
        }
        @media screen and (max-width: 1344px) {
            .profile-img {
                margin-left: 21px !important;
                margin-top: -153px !important;
            }
        }
        @media screen and (max-width: 1500px) {
            .himself-section {
                top: -282px !important;
                z-index: 2;
            }
        }
        

@media screen and (max-width: 1380px){
 .main-content .hero-sidebar .profile-img {
        width: 60%!important;
        margin-left: 4% !important;
        margin-top: -115px !important;
    }
}

        .book-me-btn {
            background: transparent !important;
        }
        .bookme-oterr .hamburger-outer {
            left: -163px !important;
        }
        .hamburger-outer {
            .forms-inpt {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784);
            }
            .form-control {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784);
                box-shadow: none !important;
            }
        }
    }
}
