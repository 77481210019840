$color_1: #202020;
$color_2: #5F6168;
$color_3: #3C3D40;

.event-timeline2 {
  position: relative;
  justify-content: center;
  text-align: center;

  .event_detail {
    // width: 435px;
    height: 178px;
    margin-bottom: 20px;

    .event_day {
      background: #7CBAA7;
      width: 6%;
      padding: 5px 10px 5px 10px;
      writing-mode: vertical-lr;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .event_date {
      width: 19%;
      height: 178px;
      padding: 40px 0 40px 0;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        span {
          font-size: 56px;
          line-height: 50px;
          color: $color_1;
        }

        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        color: $color_2;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
      }
    }

    .event_description {
      width: 75%;
      text-align: left;
      height: 168px;
      margin-top: 5px;
      padding: 20px 10px;
      gap: 10px;
      background: #F0F4F8;

      h2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 19.36px;
        color: $color_1;
      }

      h6 {
        font-weight: 500;
        font-size: 12px;
        line-height: 14.52px;
        color: $color_1;
        width: 70%;
      }

      p {
        color: $color_3;
        font-size: 12px;
        line-height: 19.2px;
        font-weight: 400;
      }
    }

    .event_theme {
      font-size: 11px;
      line-height: 13.31px;
      font-weight: 400;
      color: $color_3;
      margin: 0;

      strong {
        font-weight: 500;
        color: $color_1;
      }
    }

    .event_card_btn {
      // width: 105px;
      height: 35px;
      padding: 12px 14px 12px 14px;
      background: #7CBAA7;
      font-size: 13px;
      border-radius: 0;
      line-height: 15.73px;
      font-weight: 500;
      color: $color_1;
      border: none;
    }
  }
}

.EventRegularTheme2Mob{
  .pl_0{
    padding-left: 0;
  }
  .px_0{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .main-timeline{
    margin: 0;
    .event_detail{
      height: 120px;
      .event_day{
        height: 124px;
      }
      .event_date{
        height: 124px;
        p{
          span{
            font-size: 40px;
            line-height: 42px;
          }
          font-size: 12px;
        }
      }
      .event_description{
        height: auto;
        padding: 10px 5px;
        h2{
          font-size: 12px;
          margin-bottom: 5px;
        }
        h6{
          font-size: 10px;
          width: 100%;
        }
        p{
          font-size: 12px;
          line-height: 12px;
          margin-bottom: 5px;
        }
        .event_theme{
          font-size: 10px;
          line-height: 10px;
        }
        .event_card_btn{
          height: auto;
          padding: 5px 8px;
          font-size: 12px;
          line-height: 10px;
          margin-bottom: 4px;
        }
      }
    }
  }
}