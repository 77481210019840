.subscription-screen-tab-4 {
  .subscription-tab-theme4 {
    background-color: #CBD5E0;
    padding: 130px 20px 130px;
    width: 768px;
    margin: 0 auto;
    &.regular-style {
      background-color: #CBD5E0;
    }
    &.neumorphic-style {
      background-color: #F0F4F8;
      box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
    }
    &.glassmorphic-style{
      background: linear-gradient(326deg, #9595B8 0%, #ECEDF5 46.35%, #C8C9D2 70.83%, #97999D 100%);
    }
    .theme-4-main-wrapper {
      &.neumorphic-style {
        background: #F0F4F8;
        margin: 0 auto;
      }
      &.glassmorphic-style {
        border-radius: 30px;
        max-width: 1030px;
        margin: 0 auto;
        backdrop-filter: blur(10px);
      }
      .subscription-tab-theme4-main-wrapper {
        background-position: center;
        background-size: cover;
        position: relative;
        width: 100%;
        max-width: 1000px;
        margin: auto;
        text-align: center;
        overflow: hidden;
        z-index: 2;
        padding: 200px 0 100px;
        &.regular-style {
          background: #3C3D40;
          .theme3TopRighttDotsImg, .theme3BottomLeftDotsImg, .theme3BottomRightDotsImg, {
            display: block;
          }
        }
        &.neumorphic-style {
          overflow: hidden;
          border-radius: 20px;
          background: #F0F4F8;
          box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.7), -6px -6px 15px 0px #FFF;
          .theme3NeumorphicTopRightDotsImg, .theme3NeumorphicBottomRightDotsImg, .theme3NeumorphicBottomLeftDotsImg {
            display: block;
          }
        }
        &.glassmorphic-style {
          overflow: hidden;
          box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
          border-radius: 30px;
          padding: 133px 0 427px;
          .theme3NeumorphicTopRightDotsImg, .theme3NeumorphicBottomRightDotsImg, .theme3NeumorphicBottomLeftDotsImg {
            display: block;
          }
        }
        .theme3TopRighttDotsImg, .theme3BottomLeftDotsImg, .theme3BottomRightDotsImg,
        .theme3NeumorphicTopRightDotsImg, .theme3NeumorphicBottomRightDotsImg, .theme3NeumorphicBottomLeftDotsImg,
        .theme2NeumorphicTopLeftDotsImg, .theme2NeumorphicTopRightDotsImg, .theme2NeumorphicBottomRightDotsImg {
          position: absolute;
          object-fit: cover;
          display: none;
          z-index: -1;
        }
        .theme3TopRighttDotsImg, .theme3NeumorphicTopRightDotsImg, .theme2NeumorphicTopLeftDotsImg {
          left: 0;
          top: 0;
          width: 550px;
          height: 240px;
        }
        .theme3BottomLeftDotsImg, .theme3NeumorphicBottomLeftDotsImg, .theme2NeumorphicTopRightDotsImg {
          width: 360px;
          height: 200px;
          bottom: 0;
          right: 0;
          z-index: -9;
        }
        .theme3BottomRightDotsImg, .theme3NeumorphicBottomRightDotsImg, .theme2NeumorphicBottomRightDotsImg {
          width: 100px;
          height: 160px;
          bottom: 0;
          left: 0;
        }
        .shadow-wrapper {
          width: 550px;
          margin: 0 auto;
          padding: 25px;
          border-radius: 10px;
          &.regular-style {
            border: 1px solid #7ABEAE;
            background: #7CBAA7;
            box-shadow: 4px 4px 2px 0px rgba(72, 152, 127, 0.25) inset, -4px -4px 2px 0px rgba(148, 240, 212, 0.25) inset;
          }
          &.neumorphic-style {
            box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
          }
          &.glassmorphic-style {
            background: linear-gradient(135deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.10) 100%);
          }
          .subscribe-weekly-drops {
            position: relative;
            width: 500px;
            margin: 0 auto 0;
            border-radius: 15px;
            padding: 214px 0 100px;
            &.regular-style {
              background: #7CBAA7;
            }
            &.neumorphic-style {
              background: #F0F4F8;
              box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.7), -6px -6px 15px 0px #FFF;
              .subscribe-weekly-drops-icon-wrapper {
                padding: 20px 0 0 0;
              }
            }
            &.glassmorphic-style {
              z-index: 1;
              background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
            }
            .subscribe-weekly-drops-icon-wrapper {
              .img-wrapper {
                border-radius: 50%;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                overflow: hidden;
                padding: 40px 0px 24px;
                width: 300px;
                height: 300px;
                position: absolute;
                top: -128px;
                left: 50%;
                transform: translateX(-50%);
                .subscribeAroundTextBlackImg, .subscribeAroundNeumorphicTextImg {
                  width: 250px;
                  height: 138px;
                  position: absolute;
                  top: 20px;
                  left: 50%;
                  transform: translateX(-50%);
                  display: none;
                }
                .icon-wrapper {
                  .subscribeUserIcon {
                    width: 160px;
                    height: 160px;
                    border-radius: 50%;
                  }
                }
                .pick-poison-text {
                  color: #3C3D40;
                  padding: 7px 10px 5px;
                  font-size: 13px;
                  border-radius: 30px;
                  position: absolute;
                  bottom: 30px;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 120px;
                }
                &.regular-style {
                  background: #7CBAA7;
                  box-shadow: 4px 4px 2px 0px rgba(52, 149, 119, 0.25), -4px -4px 2px 0px rgba(138, 226, 199, 0.25);
                  .img-wrapper {
                    background: #7CBAA7;
                  }
                  .pick-poison-text {
                    color: #202020;
                    background: #ffffff;

                  }
                  .subscribeAroundTextBlackImg {
                    display: block;
                    top: 35px;
                  }
                }

                &.neumorphic-style {
                  background: #F0F4F8;
                  box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
                  padding: 12px;
                  width: 300px;
                  height: 300px;
                  margin: 20px auto 0;

                  .icon-wrapper {
                    border-radius: 50%;
                    padding: 10px;
                    box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
                  }
                  .pick-poison-text {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  }
                  .subscribeAroundNeumorphicTextImg {
                    display: block;
                  }
                }
                &.glassmorphic-style {
                  background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
                  border: 1px solid #F0F4F8;
                  backdrop-filter: blur(10px);
                  .subscribeUserIcon {
                    box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
                    padding: 12px;
                    img {
                      width: 180px;
                      height: 180px;
                    }
                  }
                  .icon-wrapper {
                    border-radius: 50%;
                    background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.05) 100%);
                  }
                  .pick-poison-text {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    padding: 7px 13px 5px;
                  }
                  .subscribeAroundNeumorphicTextImg {
                    display: block;
                  }
                }
              }
            }
            .envelop-drop-wrapper {
              .img-div {
                text-align: center;
                position: relative;
                margin: 20px 0 0;
                .greenEnvelopDropImg, .whiteEnvelopDropImg, .goldenEnvelopDropImg {
                  height: 58px;
                  width: 246px;
                  margin: 0 auto;
                  display: none;
                }
                .whiteEnvelopImg {
                  width: 138px;
                  height: 70px;
                  object-fit: cover;
                  position: absolute;
                  top: -34px;
                  left: 50%;
                  transform: translateX(-50%);
                }
                &.regular-style{
                  .greenEnvelopDropImg{
                    display: block;
                  }
                }
                &.neumorphic-style{
                  .whiteEnvelopDropImg{
                    display: block;
                  }
                }
                &.glassmorphic-style{
                  .goldenEnvelopDropImg{
                    display: block;
                  }
                }
              }
            }
            .on-off-buttons-wrapper {
              padding: 0 15px 0 15px;
              margin: 30px 0;
              .on-off-btn-items {
                display: grid;
                grid-template-columns: auto auto auto;
                gap: 15px;

                li {
                  text-align: center;
                  .toggle-switch {
                    position: relative;
                    width: 36px;
                    display: inline-block;
                    text-align: left;
                    top: 8px;
                    .checkbox {
                      display: none;
                    }
                    .label {
                      display: block;
                      overflow: hidden;
                      cursor: pointer;
                      border: 0 solid #bbb;
                      border-radius: 10px;
                      width: 36px;
                      background: transparent;
                      box-shadow: none;
                      .inner {
                        display: block;
                        width: 200%;
                        margin-left: -100%;
                        transition: margin 0.3s ease-in 0s;
                        &::before {
                          float: left;
                          width: 36px;
                          height: 21px;
                          padding: 0;
                          line-height: 36px;
                          color: #fff;
                          font-weight: bold;
                          box-sizing: border-box;
                        }
                        &::after {
                          float: left;
                          width: 36px;
                          height: 21px;
                          padding: 0;
                          line-height: 36px;
                          color: #fff;
                          font-weight: bold;
                          box-sizing: border-box;
                        }
                        &::before {
                          content: "";
                          padding-left: 10px;
                          background-color: #7CBAA7;
                          color: #fff;
                        }
                        &::after {
                          content: "";
                          padding-right: 10px;
                          background-color: #B2BDCB;
                          color: #fff;
                          text-align: right;
                        }
                      }
                      .switch {
                        display: block;
                        width: 16px;
                        height: 16px;
                        margin: 5px;
                        background: #5F6168;
                        position: absolute;
                        top: -3px;
                        bottom: 0;
                        right: 12px;
                        border: 0 solid #bbb;
                        border-radius: 20px;
                        transition: all 0.3s ease-in 0s;
                      }
                    }
                    .checkbox:checked + .label .inner {
                      margin-left: 0;
                      &:after {
                        background: #FFFFFF;
                      }
                      &:before {
                        background: #FFFFFF;
                      }
                    }
                    .checkbox:checked + .label .switch {
                      right: -2px;
                      background: #7CBAA7;
                    }
                    &.regular-style {
                      .inner {
                        &:before {
                          color: #fff;
                          background-color: #7CBAA7;
                        }
                        &:after {
                          color: #fff;
                          background-color: #B2BDCB;
                        }
                      }
                      .switch {
                        background: #5F6168;
                      }
                    }
                    &.neumorphic-style {
                      .inner {
                        &:before {
                          color: #fff;
                          background-image: linear-gradient(#4E4E57, #E5E9ED);
                        }
                        &:after {
                          color: #fff;
                          background-image: linear-gradient(#4E4E57, #E5E9ED);
                        }
                      }
                      .checkbox:checked + .label .inner {
                        margin-left: 0;
                        &:after {
                          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                        }
                        &:before {
                          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                        }
                      }
                      .checkbox:checked + .label .switch {
                        right: -2px;
                        background: #EAEFF6;
                      }
                      .switch {
                        background: #5F6168;
                      }
                    }
                    &.glassmorphic-style {
                      .inner {
                        &:before {
                          color: #fff;
                          background: #bababa;
                        }
                        &:after {
                          color: #fff;
                          background: #bababa;
                        }
                      }
                      .checkbox:checked + .label .inner {
                        margin-left: 0;
                        &:after {
                          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                        }
                        &:before {
                          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                        }
                      }
                      .checkbox:checked + .label .switch {
                        right: -2px;
                        background: #202020;
                      }
                      .switch {
                        background: #202020;
                      }
                      strong {
                        color: #202020;
                      }
                    }
                  }
                  strong {
                    color: #202020;
                    font-size: 12px;
                    font-weight: 600;
                    margin: 12px 0 0px 10px;
                    display: inline-block;
                  }
                }
              }
            }
            .signup-form {
              margin: 15px 0 0 0;
              position: relative;
              z-index: 2;
              form {
                .form-group {
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                  gap: 30px;
                  padding: 0 0 14px;
                  input {
                    font-size: 13px;
                    color: #5F6168;
                    letter-spacing: 0.52px;
                    text-transform: uppercase;
                    margin: 0 auto;
                    font-weight: 500;
                    width: 380px;
                    height: 50px;

                  }
                  button {
                    width: 195px;
                    text-align: center;
                    margin: 0 auto;
                    font-size: 24px;
                    font-weight: 600;
                    letter-spacing: 0.52px;
                    text-transform: uppercase;
                    padding: 18px 40px;
                    height: 49px;
                    border-radius: 30px;
                  }
                }
              }
              &.regular-style {
                form {
                  .form-group {
                    padding: 0 0 14px;
                    input {
                      background: #F0F4F8;
                      border-radius: 0;
                      border: 1px solid #202020;
                      box-shadow: 4px 4px 2px 0px rgba(72, 123, 108, 0.25), -4px -4px 2px 0px rgba(129, 227, 197, 0.25);
                      &::placeholder {
                        color: #5F6168;
                      }
                    }
                    button {
                      background-color: black;
                      box-shadow: 4px 4px 2px 0px rgba(39, 96, 78, 0.25), -4px -4px 2px 0px rgba(147, 236, 209, 0.25);
                      color: #7CBAA7;
                      &:hover {
                        color: #7CBAA7;
                        background-color: black;
                      }
                    }
                  }
                }
              }
              &.neumorphic-style {
                background: transparent;
                &::after {
                  width: 530px;
                  background-image: url('../../../../../../images/subscription/envelop-theme2-neumorphic-bg-img.png');
                }
                form {
                  .form-group {
                    background: transparent;
                    input {
                      background: #F0F4F8;
                      box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
                      border-radius: 4px;
                    }
                    button {
                      background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    }
                  }
                }
              }
              &.glassmorphic-style {
                &::after {
                  background-image: url('../../../../../../images/subscription/envelop-theme2-bg-img.png');
                }
                form {
                  .form-group {
                    input {
                      background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.05) 100%);
                      border-radius: 4px;
                      color: #202020;
                      &::placeholder {
                        color: #202020;
                      }
                      &:focus {
                        box-shadow: none;
                      }
                    }
                    button {
                      font-size: 16px;
                      background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                      box-shadow: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
