
@import url( 'https://fonts.googleapis.com/css2?family=Orbitron:wght@600&display=swap');
.timer-card {
  background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
  box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8), 2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4);
  border-radius: 2.84939px;
  .top-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    color: #697094;
  }
  .timer-p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding-top: 10px;
    padding-bottom: 30px;
    color: #697094;
    border-bottom: 1px solid #CEDAE9;
  }
  .circle-big {
    position: relative;
    width: 128px;
    height: 114px;
    margin: 30px auto 7px auto;
  }
  .circle-big svg {
    width: 122px;
    padding: 8px;
    height: 118px;
    box-shadow: inset -3.4px -3.4px 3.4px rgb(255 255 255 / 70%), inset 3.4px 3.4px 5.66667px rgb(152 151 151 / 68%);
    border-radius: 50%;
  }
  .circle-big .bg {
    fill: none;
    stroke-width: 8px;
    stroke: #E0E4ED;
  }
  .circle-big .progress {
    fill: none;
    stroke-width: 8px;
    stroke: #Cbcccd;
    stroke-linecap: round;
    box-shadow: inset 4.4px -3.52px 8.8px #31C7D0;
    stroke-dasharray: 326.56;

    stroke-dashoffset: 178;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    animation: big 1.5s ease-in-out;
  }
  .circle-big .text {
    position: absolute;
    width: 100%;
    top: 30px;
    color: #657673;
    // font-size: 24px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
  }
  .progress-text {
    font-style: normal;
    font-weight: 600;
    font-size: 12.9651px;
    line-height: 15px;
    color: #697094;
    margin: 0px 0px 30px;
    text-align: center;
  }

  .circle-big .text .small {
    font-size: 14px;
    color: #fff;
  }






  @keyframes big {
    from {
      stroke-dashoffset: 326.56;
    }

    to {
      stroke-dashoffset: 180;
    }
  }

  @keyframes one {
    from {
      stroke-dashoffset: 232.36;
    }

    to {
      stroke-dashoffset: 80;
    }
  }

  @keyframes two {
    from {
      stroke-dashoffset: 232.36;
    }

    to {
      stroke-dashoffset: 140;
    }
  }

  @keyframes appear {

    0%,
    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .border-shadow {
    background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
    box-shadow: inset -2px -2px 2px #FFFFFF, inset 1px 1px 3px rgba(77, 89, 107, 0.1);
    border-radius: 5px;

    height: 6px;
    margin: 10px 30px;
  }

  .footer-icons {
    display: flex;
    justify-content: space-between;

    .icon-bg {
      background: #F0F4F8;
      box-shadow: -8.57143px -8.57143px 16.2857px #FFFFFF, 3.42857px 3.42857px 8.57143px rgba(166, 180, 200, 0.5);
      border-radius: 34.2857px;

      .footer-icon {
        font-size: 20px;
        text-align: center;
        margin: 13px 15px;

        color: #697094;
      }

      .footer-icon-pink {
        text-align: center;
        color: #EC7EDA;
        font-size: 25px;
        margin: 12px 17px;

      }
    }
  }
}
.clock {
  height: 8vh;
  color: #697094;
  font-size: 13px;
  line-height: 6.4vh;
  font-family: 'Orbitron', sans-serif;
  display: flex;
  position: relative;
  /*background: green;*/
  justify-content: center;
  overflow: hidden;
}

.clock::before, .clock::after {
  content: '';
  width: 7ch;
  height: 3vh;
  position: absolute;
  z-index: 2;
}
.hours, .minutes, .seconds{
  background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
  box-shadow: -4px 0px 4px rgb(255 255 255 / 67%), 4px 2px 9px rgb(81 89 106 / 15%);
  padding: 4px 0px;
  text-align: center;
  width: 33px;
  display: flex;
  justify-content: center;
}
.clock-text{
 
    color: #697094;
    font-size: 13px;
    line-height: 4.4vh;
    font-family: "Orbitron", sans-serif;
    display: flex;
    position: relative;
    /* background: green; */
    justify-content: center;
    overflow: hidden;
}
.clock-text div{
  padding: 4px 0px;
  text-align: center;
  width: 57px;
  display: flex;
  justify-content: center;
}
.clock::after {
  bottom: 0;
}

.clock > div {
  display: flex;
}

.tick {
  line-height: 8vh;
  padding: 0px 10px;
}

.tick-hidden {
  opacity: 0;
}

.move {
  animation: move linear 1s infinite;
}

@keyframes move {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(-20vh);
  }
}

.clock-timer{
  margin: auto;
  width:12em;
  height:12em;
  background: #F0F4F8;
  box-shadow: -32.5px -32.5px 61.75px #FFFFFF, 13px 13px 32.5px rgba(166, 180, 200, 0.5), inset 20.7717px 4.94565px 25.7174px rgba(0, 0, 0, 0.1);
  border-radius: 130px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
#watch { font-size:1em; position:relative;
  width:8.8em;
  height:8.8em;
  background: #F0F4F8;
  box-shadow: -26.325px -26.325px 50.0175px rgba(255, 255, 255, 0.6), 10.53px 10.53px 26.325px rgba(166, 180, 200, 0.5);

  border-radius: 105.3px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center; 
}
#watch .frame-face {
  position:relative;
  width:5em;
  height:5em;
  background: #F0F4F8;
  box-shadow: -4.55px -4.55px 30.2575px #FFFFFF, 6.37px 6.37px 15.925px rgba(166, 180, 200, 0.5);
  border-radius: 63.7px;
}
.watch1 { 
  width:11em!important;
  height:11em!important;
}
#watch .minute-marks li { 
  display:block;
  width:2px;
  height:.4em;
  background:#D9D9D9;
  position:absolute;
  top:50%; left:50%;
  margin:-.4em 0 0 -.1em;
}
#watch .minute-marks{
  position: relative;
  top: 3px;
  right: -39px;
}

#watch .minute-marks li:first-child {transform:rotate(6deg) translateY(-4em)}
#watch .minute-marks li:nth-child(2) {transform:rotate(12deg) translateY(-4em)}
#watch .minute-marks li:nth-child(3) {transform:rotate(18deg) translateY(-4em)}
#watch .minute-marks li:nth-child(4) {transform:rotate(24deg) translateY(-4em)}
#watch .minute-marks li:nth-child(5) {transform:rotate(30deg) translateY(-4em)}
#watch .minute-marks li:nth-child(49) {transform:rotate(36deg) translateY(-4em)}
#watch .minute-marks li:nth-child(6) {transform:rotate(42deg) translateY(-4em)}
#watch .minute-marks li:nth-child(7) {transform:rotate(48deg) translateY(-4em)}
#watch .minute-marks li:nth-child(8) {transform:rotate(54deg) translateY(-4em)}
#watch .minute-marks li:nth-child(50) {transform:rotate(60deg) translateY(-4em)}
#watch .minute-marks li:nth-child(9) {transform:rotate(66deg) translateY(-4em)}
#watch .minute-marks li:nth-child(10) {transform:rotate(72deg) translateY(-4em)}
#watch .minute-marks li:nth-child(11) {transform:rotate(78deg) translateY(-4em)}
#watch .minute-marks li:nth-child(12) {transform:rotate(84deg) translateY(-4em)}
#watch .minute-marks li:nth-child(51) {transform:rotate(90deg) translateY(-4em)}
#watch .minute-marks li:nth-child(13) {transform:rotate(96deg) translateY(-4em)}
#watch .minute-marks li:nth-child(14) {transform:rotate(102deg) translateY(-4em)}
#watch .minute-marks li:nth-child(15) {transform:rotate(108deg) translateY(-4em)}
#watch .minute-marks li:nth-child(16) {transform:rotate(114deg) translateY(-4em)}
#watch .minute-marks li:nth-child(52) {transform:rotate(120deg) translateY(-4em)}
#watch .minute-marks li:nth-child(17) {transform:rotate(126deg) translateY(-4em)}
#watch .minute-marks li:nth-child(18) {transform:rotate(132deg) translateY(-4em)}
#watch .minute-marks li:nth-child(19) {transform:rotate(138deg) translateY(-4em)}
#watch .minute-marks li:nth-child(20) {transform:rotate(144deg) translateY(-4em)}
#watch .minute-marks li:nth-child(53) {transform:rotate(150deg) translateY(-4em)}
#watch .minute-marks li:nth-child(21) {transform:rotate(156deg) translateY(-4em)}
#watch .minute-marks li:nth-child(22) {transform:rotate(162deg) translateY(-4em)}
#watch .minute-marks li:nth-child(23) {transform:rotate(168deg) translateY(-4em)}
#watch .minute-marks li:nth-child(24) {transform:rotate(174deg) translateY(-4em)}
#watch .minute-marks li:nth-child(54) {transform:rotate(180deg) translateY(-4em)}
#watch .minute-marks li:nth-child(25) {transform:rotate(186deg) translateY(-4em)}
#watch .minute-marks li:nth-child(26) {transform:rotate(192deg) translateY(-4em)}
#watch .minute-marks li:nth-child(27) {transform:rotate(198deg) translateY(-4em)}
#watch .minute-marks li:nth-child(28) {transform:rotate(204deg) translateY(-4em)}
#watch .minute-marks li:nth-child(60) {transform:rotate(210deg) translateY(-4em)}
#watch .minute-marks li:nth-child(29) {transform:rotate(216deg) translateY(-4em)}
#watch .minute-marks li:nth-child(30) {transform:rotate(222deg) translateY(-4em)}
#watch .minute-marks li:nth-child(31) {transform:rotate(228deg) translateY(-4em)}
#watch .minute-marks li:nth-child(32) {transform:rotate(234deg) translateY(-4em)}
#watch .minute-marks li:nth-child(55) {transform:rotate(240deg) translateY(-4em)}
#watch .minute-marks li:nth-child(33) {transform:rotate(246deg) translateY(-4em)}
#watch .minute-marks li:nth-child(34) {transform:rotate(252deg) translateY(-4em)}
#watch .minute-marks li:nth-child(35) {transform:rotate(258deg) translateY(-4em)}
#watch .minute-marks li:nth-child(36) {transform:rotate(264deg) translateY(-4em)}
#watch .minute-marks li:nth-child(57) {transform:rotate(270deg) translateY(-4em)}
#watch .minute-marks li:nth-child(37) {transform:rotate(276deg) translateY(-4em)}
#watch .minute-marks li:nth-child(38) {transform:rotate(282deg) translateY(-4em)}
#watch .minute-marks li:nth-child(39) {transform:rotate(288deg) translateY(-4em)}
#watch .minute-marks li:nth-child(40) {transform:rotate(294deg) translateY(-4em)}
#watch .minute-marks li:nth-child(56) {transform:rotate(300deg) translateY(-4em)}
#watch .minute-marks li:nth-child(41) {transform:rotate(306deg) translateY(-4em)}
#watch .minute-marks li:nth-child(42) {transform:rotate(312deg) translateY(-4em)}
#watch .minute-marks li:nth-child(43) {transform:rotate(318deg) translateY(-4em)}
#watch .minute-marks li:nth-child(44) {transform:rotate(324deg) translateY(-4em)}
#watch .minute-marks li:nth-child(58) {transform:rotate(330deg) translateY(-4em)}
#watch .minute-marks li:nth-child(45) {transform:rotate(336deg) translateY(-4em)}
#watch .minute-marks li:nth-child(46) {transform:rotate(342deg) translateY(-4em)}
#watch .minute-marks li:nth-child(47) {transform:rotate(348deg) translateY(-4em)}
#watch .minute-marks li:nth-child(48) {transform:rotate(354deg) translateY(-4em)}
#watch .minute-marks li:nth-child(59) {transform:rotate(360deg) translateY(-4em)}
#watch .digits li {
  font-size:1.6em;
  display:block;
  width:1.6em;
  height:1.6em;
  position:absolute;
  top:50%; left:50%;
  line-height:1.6em;
  text-align:center;
  margin:-.8em 0 0 -.8em;
  font-weight:bold;
}
#watch .digits li:nth-child(1) { transform:translate(3.9em, -6.9em) }
#watch .digits li:nth-child(2) { transform:translate(6.9em, -4em) }
#watch .digits li:nth-child(3) { transform:translate(8em, 0) }
#watch .digits li:nth-child(4) { transform:translate(6.8em, 4em) }
#watch .digits li:nth-child(5) { transform:translate(3.9em, 6.9em) }
#watch .digits li:nth-child(6) { transform:translate(0, 8em) }
#watch .digits li:nth-child(7) { transform:translate(-3.9em, 6.9em) }
#watch .digits li:nth-child(8) { transform:translate(-6.8em, 4em) }
#watch .digits li:nth-child(9) { transform:translate(-8em, 0) }
#watch .digits li:nth-child(10) { transform:translate(-6.9em, -4em) }
#watch .digits li:nth-child(11) { transform:translate(-3.9em, -6.9em) }
#watch .digits li:nth-child(12) { transform:translate(0, -8em) }
#watch .digits:before {
  content:'';
  width:1.6em;
  height:1.6em;
  border-radius:.8em;
  position:absolute;
  top:50%; left:50%;
  margin:-.8em 0 0 -.8em;
  background:#121314;
}
#watch .digits:after {
  content:'';
  width:2em;
  height:2em;
  border-radius:2.2em;
  position:absolute;
  top:50%; left:50%;
  margin:-2.1em 0 0 -2.1em;
  border:.1em solid #c6c6c6;
  background:-webkit-radial-gradient(center, ellipse cover, rgba(200,200,200,0), rgba(190,190,190,1) 90%, rgba(130,130,130,1) 100%);
  background:-moz-radial-gradient(center, ellipse cover, rgba(200,200,200,0), rgba(190,190,190,1) 90%, rgba(130,130,130,1) 100%);
  background:radial-gradient(ellipse at center, rgba(200,200,200,0), rgba(190,190,190,1) 90%, rgba(130,130,130,1) 100%);
}
@keyframes hours { to {transform:rotate(335deg)} }
#watch .hours-hand {
  width:.8em;
  height:7em;
  border-radius:0 0 .9em .9em;
  background:#232425;
  position:absolute;
  bottom:50%; left:50%;
  margin:0 0 -.8em -.4em;
  box-shadow:#232425 0 0 2px;
  transform-origin:0.4em 6.2em;
  transform:rotate(-25deg);
  animation:hours 43200s linear 0s infinite;
}
#watch .hours-hand:before {
  content:'';
  background:inherit;
  width:1.8em;
  height:.8em;
  border-radius:0 0 .8em .8em;
  box-shadow:#232425 0 0 1px;
  position:absolute;
  top:-.7em; left:-.5em;
}
#watch .hours-hand:after {
  content:'';
  width:0; height:0;
  border:.9em solid #232425;
  border-width:0 .9em 2.4em .9em;
  border-left-color:transparent;
  border-right-color:transparent;
  position:absolute;
  top:-3.1em; left:-.5em;
}
@keyframes minutes { to {transform:rotate(422deg)} }
#watch .minutes-hand {
  width:.8em;
  height:12.5em;
  border-radius:.5em;
  background:#343536;
  position:absolute;
  bottom:50%; left:50%;
  margin:0 0 -1.5em -.4em;
  box-shadow:#343536 0 0 2px;
  transform-origin:0.4em 11em;
  transform:rotate(62deg);
  animation:minutes 3600s linear 0s infinite;
}
@keyframes seconds { to {transform:rotate(480deg)} }
#watch .seconds-hand {
  width:.3em;
  height:7em;
  border-radius:0 0 .9em .9em;
  border-radius: 36.4px;
  position:absolute;
  bottom:50%; left:50%;
  margin:0 0 -.8em -.4em;
  background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
  box-shadow: 2.73px 5.46px 3.64px rgba(0, 0, 0, 0.25);
  transform-origin:0.4em 6.2em;
  transform:rotate(-25deg);
  animation:seconds 60s steps(60, end) 0s infinite;
}

#watch .seconds-hand1 {
  width:.3em;
  height:4.5em;
  border-radius:0 0 .9em .9em;
  border-radius: 36.4px;
  position:absolute;
  top:-12%; left:50%;
  margin:0 0 -.8em -.4em;
  background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
  box-shadow: 2.73px 5.46px 3.64px rgba(0, 0, 0, 0.25);
  // transform-origin:0.4em 6.2em;
  transform:rotate(358deg);
  z-index: 4;
  // animation:seconds 60s steps(60, end) 0s infinite;
}

#watch .digital-wrap {

  border-radius:.2em;
  position:absolute;
  overflow:hidden;
  display: flex;
align-items: center;

}
#watch .digital-wrap ul {
  float:left;  
  height:2em;

  font-family:Consolas, monaco, monospace;
}
#watch .digital-wrap ul:last-child { border:none }
#watch .digital-wrap li {
  font-size:1.5em;
  line-height:2;
  letter-spacing:2px;
  text-align:center;
  position:relative;
  left:1px;
  font-family: "Orbitron", sans-serif;
}
#watch .digit-minutes li {
  animation:dsm 3600s steps(60, end) 0s infinite;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
font-weight: 600;
font-size: 19.02px;

text-align: center;

color: #697094;
}
#watch .digit-seconds li {
  animation:dsm 60s steps(60, end) 0s infinite;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
font-weight: 600;
font-size: 19.02px;

text-align: center;

color: #697094;
}
@keyframes dsm {
  to { transform:translateY(-120em) }
}
}
#clock {
  width: 300px;
  height: 300px;
  position: relative;
  margin: 50px auto;
}

#analog-clock {
  position: relative;
  width: 100%;
  height: 100%;
}

.clock-face {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background-color: #ddd;
  box-shadow: 3px 3px 10px #777;
}

.hand {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0 0;
  width: 3px;
  height: 40%;
  background-color: black;
  box-shadow: 3px 3px 3px #777;
  transition: all 0.1s;
}

.hour-hand {
  transform: rotate(90deg);
  height: 35%;
}

.min-hand {
  transform: rotate(90deg);
}

.second-hand {
  width: 1px;
  height: 45%;
  background-color: red;
  transform: rotate(90deg);
}

#digital-clock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.7em;
  font-weight: bold;
  text-align: center;
  background: white;
  padding: 5px 5px 0px 10px;
  box-shadow: inset 3px 3px 3px #777;
  border-radius: 5px;
}

.togglebtn {
  position: absolute;
  top:-100px;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  &.left-side{
    left: 0px;
    width: 590px;
    height: 182px;
    top: -205px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
}
.left-side.newbarTab{
  img{
    width: 100px;
  }
  .active-tab {
    box-shadow: 0px 0px 0px 0px #000!important;
  }
}
.btnTop {
  margin-top:0px;
  position: relative;
  left: 0px;
}

.dark{
  .timer-card{
    box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
    background: #1c1c1c;
    .border-shadow{
      box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
      background: #1c1c1c;
    }
    .clock-timer{
      box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
      background: #1c1c1c;
      #watch{
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
        background: #1c1c1c;
        .frame-face{
          box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
          background: #1c1c1c;
        }
      }
    }
    .hours, .minutes, .seconds{
      box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
      background: #1c1c1c;
    }
    .footer-icons .icon-bg{
      box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
      background: #1c1c1c;
    }
    .timer-p{
      border-bottom: 1px solid #4a4a4a;
    }
  }

}




#watch .minute-marks1 li:first-child {transform:rotate(6deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(2) {transform:rotate(12deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(3) {transform:rotate(18deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(4) {transform:rotate(24deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(5) {transform:rotate(30deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(49) {transform:rotate(36deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(6) {transform:rotate(42deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(7) {transform:rotate(48deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(8) {transform:rotate(54deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(50) {transform:rotate(60deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(9) {transform:rotate(66deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(10) {transform:rotate(72deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(11) {transform:rotate(78deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(12) {transform:rotate(84deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(51) {transform:rotate(90deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(13) {transform:rotate(96deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(14) {transform:rotate(102deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(15) {transform:rotate(108deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(16) {transform:rotate(114deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(52) {transform:rotate(120deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(17) {transform:rotate(126deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(18) {transform:rotate(132deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(19) {transform:rotate(138deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(20) {transform:rotate(144deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(53) {transform:rotate(150deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(21) {transform:rotate(156deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(22) {transform:rotate(162deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(23) {transform:rotate(168deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(24) {transform:rotate(174deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(54) {transform:rotate(180deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(25) {transform:rotate(186deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(26) {transform:rotate(192deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(27) {transform:rotate(198deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(28) {transform:rotate(204deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(60) {transform:rotate(210deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(29) {transform:rotate(216deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(30) {transform:rotate(222deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(31) {transform:rotate(228deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(32) {transform:rotate(234deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(55) {transform:rotate(240deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(33) {transform:rotate(246deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(34) {transform:rotate(252deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(35) {transform:rotate(258deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(36) {transform:rotate(264deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(57) {transform:rotate(270deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(37) {transform:rotate(276deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(38) {transform:rotate(282deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(39) {transform:rotate(288deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(40) {transform:rotate(294deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(56) {transform:rotate(300deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(41) {transform:rotate(306deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(42) {transform:rotate(312deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(43) {transform:rotate(318deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(44) {transform:rotate(324deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(58) {transform:rotate(330deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(45) {transform:rotate(336deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(46) {transform:rotate(342deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(47) {transform:rotate(348deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(48) {transform:rotate(354deg) translateY(-5em)!important;}
#watch .minute-marks1 li:nth-child(59) {transform:rotate(360deg) translateY(-5em)!important;}


.clock-timer1{
  width:14em;
  height:14em;
  border: 2px solid #fff;
}