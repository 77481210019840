@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');


.Feature-on-tab-4{

    .grey-box-tab-4 {
        background-color: #cbd5e0;
    width: 768px;
    
    height: 700px;
    margin: auto;
    flex-direction: column;
    padding: 0px 80px 0px 80px;
    
}

.image-circle-tab-4 {
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    margin: 10px 5px 0px 5px;
    box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.125);
    z-index: 90;
}

.image-usa {
    background-color: #1EA3FF;
    
}



.green-box-tab-4 {
    background-color: #7CBAA7;
    padding: 50px 0px;
    width: 100%;
}
}