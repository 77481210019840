.subscription-screen-tab-3 {
  .subscription-tab-theme3 {
    background-color: #CBD5E0;
    padding: 100px 20px 120px;
    width: 768px;
    margin: 0 auto;
    &.regular-style {
      background-color: #CBD5E0;
    }
    &.neumorphic-style {
      background-color: #F0F4F8;
      box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
    }
    &.glassmorphic-style{
      background: linear-gradient(326deg, #9595B8 0%, #ECEDF5 46.35%, #C8C9D2 70.83%, #97999D 100%);
    }
    .theme-3-main-wrapper {
      &.neumorphic-style {
        background: #F0F4F8;
        margin: 0 auto;
      }
      &.glassmorphic-style {
        border-radius: 30px;
        max-width: 1030px;
        margin: 0 auto;
        backdrop-filter: blur(10px);
      }
      .subscription-tab-theme3-main-wrapper {
        background-position: center;
        background-size: cover;
        position: relative;
        width: 100%;
        max-width: 1000px;
        margin: auto;
        padding: 133px 0 320px;
        text-align: center;
        overflow: hidden;
        z-index: 2;
        &.regular-style {
          background: #3C3D40;
          .theme3TopRighttDotsImg, .theme3BottomLeftDotsImg, .theme3BottomRightDotsImg,{
            display: block;
          }
        }
        &.neumorphic-style {
          overflow: hidden;
          border-radius: 20px;
          background: #F0F4F8;
          box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.7), -6px -6px 15px 0px #FFF;
          .theme3NeumorphicTopRightDotsImg, .theme3NeumorphicBottomRightDotsImg, .theme3NeumorphicBottomLeftDotsImg{
            display: block;
          }
        }
        &.glassmorphic-style {
          overflow: hidden;
          box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
          border-radius: 30px;
          padding: 133px 0 427px;
          .theme3NeumorphicTopRightDotsImg, .theme3NeumorphicBottomRightDotsImg, .theme3NeumorphicBottomLeftDotsImg{
            display: block;
          }
        }
        .theme3TopRighttDotsImg, .theme3BottomLeftDotsImg, .theme3BottomRightDotsImg,
        .theme3NeumorphicTopRightDotsImg, .theme3NeumorphicBottomRightDotsImg, .theme3NeumorphicBottomLeftDotsImg,
        .theme2NeumorphicTopLeftDotsImg, .theme2NeumorphicTopRightDotsImg, .theme2NeumorphicBottomRightDotsImg{
          position: absolute;
          object-fit: cover;
          display: none;
          z-index: -1;
        }
        .theme3TopRighttDotsImg, .theme3NeumorphicTopRightDotsImg, .theme2NeumorphicTopLeftDotsImg {
          right: 0;
          top: 0;
          width: 550px;
          height: 240px;
        }
        .theme3BottomLeftDotsImg, .theme3NeumorphicBottomLeftDotsImg, .theme2NeumorphicTopRightDotsImg {
          width: 360px;
          height: 200px;
          bottom: 0;
          left: 0;
          z-index: -9;
        }
        .theme3BottomRightDotsImg, .theme3NeumorphicBottomRightDotsImg, .theme2NeumorphicBottomRightDotsImg {
          width: 100px;
          height: 160px;
          bottom: 0;
          right: 0;
        }
        .subscribe-weekly-drops {
          position: relative;
          width: 440px;
          margin: 0 auto 0;
          border-radius: 15px 15px 0px 0px;
          &:after {
            content: '';
            display: block;
            background-image: url('../../../../../../images/subscription/green-envelop-body-img.png');
            background-repeat: no-repeat;
            width: 500px;
            height: 340px;
            position: absolute;
            left: 50%;
            bottom: -195px;
            transform: translateX(-50%);
            background-position: center;
            background-size: cover;
            z-index: -1;
          }
          &:before{
            content: '';
            display: block;
            background-image: url('../../../../../../images/subscription/green-envelop-closing-cap.png');
            background-repeat: no-repeat;
            width: 493px;
            height: 404px;
            position: absolute;
            left: 50%;
            bottom: -80px;
            transform: translateX(-50%);
            background-position: center;
            background-size: cover;
            z-index: -1;
          }
          &.regular-style {
            background: #F0F4F8;
          }
          &.neumorphic-style {
            background: #F0F4F8;
            padding: 0 0 150px ;
            box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.7), -6px -6px 15px 0px #FFF;
            &:after{
              background-image: url('../../../../../../images/subscription/golden-envelop-body-img.png');
              bottom: -84px;
              z-index: 9;
            }
            &:before{
              background-image: url('../../../../../../images/subscription/golden-envelop-closing-cap.png');
              bottom: 20px;
            }
            .subscribe-weekly-drops-icon-wrapper{
              padding: 20px 0 0 0;
            }
          }
          &.glassmorphic-style {
            &:after{
              background-image: url('../../../../../../images/subscription/golden-envelop-body-img.png');
              bottom: -322px;
            }
            &:before{
              bottom: -220px;
              background-image: url('../../../../../../images/subscription/golden-envelop-closing-cap.png');
            }
            z-index: 1;
            padding: 10px 0 0;
            background:  linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
          }
          .subscribe-weekly-drops-icon-wrapper {
            .img-wrapper {
              border-radius: 50%;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
              overflow: hidden;
              padding: 40px 0px 24px;
              width: 300px;
              height: 300px;
              .subscribeAroundTextBlackImg, .subscribeAroundNeumorphicTextImg {
                width: 250px;
                height: 138px;
                position: absolute;
                top: 20px;
                left: 50%;
                transform: translateX(-50%);
                display: none;
              }
              .icon-wrapper{
                .subscribeUserIcon {
                  width: 160px;
                  height: 160px;
                  border-radius: 50%;
                }
              }
              .pick-poison-text {
                color: #3C3D40;
                padding: 7px 10px 5px;
                font-size: 13px;
                border-radius: 10px;
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translateX(-50%);
                width: 170px;
              }
              &.regular-style {
                background: transparent;
                .pick-poison-text {
                  color: #F0F4F8;
                  background: #202020;
                }
                .subscribeAroundTextBlackImg {
                  display: block;
                  top: 35px;
                }
              }
              &.neumorphic-style {
                background: #F0F4F8;
                box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
                padding: 12px;
                width: 300px;
                height: 300px;
                margin: 20px auto 0;
                .icon-wrapper{
                  border-radius: 50%;
                  padding: 10px;
                  box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
                }
                .pick-poison-text {
                  background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                }
                .subscribeAroundNeumorphicTextImg {
                  display: block;
                }
              }
              &.glassmorphic-style {
                background:  linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
                border: 1px solid #F0F4F8;
                backdrop-filter: blur(10px);
                .subscribeUserIcon {
                  box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
                  padding: 12px;
                  img{
                    width: 180px;
                    height: 180px;
                  }
                }
                .icon-wrapper{
                  border-radius: 50%;
                  background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.05) 100%);
                }
                .pick-poison-text {
                  background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  padding: 7px 13px 5px;
                }
                .subscribeAroundNeumorphicTextImg {
                  display: block;
                }
              }
            }
          }
          .on-off-buttons-wrapper {
            padding: 0 15px 0 15px;
            .on-off-btn-items {
              display: grid;
              grid-template-columns: auto auto auto;
              gap: 15px;
              li {

                text-align: center;
                .toggle-switch {
                  position: relative;
                  width: 36px;
                  display: inline-block;
                  text-align: left;
                  top: 8px;
                  .checkbox {
                    display: none;
                  }
                  .label {
                    display: block;
                    overflow: hidden;
                    cursor: pointer;
                    border: 0 solid #bbb;
                    border-radius: 10px;
                    width: 36px;
                    background: transparent;
                    box-shadow: none;
                    .inner {
                      display: block;
                      width: 200%;
                      margin-left: -100%;
                      transition: margin 0.3s ease-in 0s;
                      &::before {
                        float: left;
                        width: 36px;
                        height: 21px;
                        padding: 0;
                        line-height: 36px;
                        color: #fff;
                        font-weight: bold;
                        box-sizing: border-box;
                      }
                      &::after {
                        float: left;
                        width: 36px;
                        height: 21px;
                        padding: 0;
                        line-height: 36px;
                        color: #fff;
                        font-weight: bold;
                        box-sizing: border-box;
                      }
                      &::before {
                        content: "";
                        padding-left: 10px;
                        background-color: #7CBAA7;
                        color: #fff;
                      }
                      &::after {
                        content: "";
                        padding-right: 10px;
                        background-color: #B2BDCB;
                        color: #fff;
                        text-align: right;
                      }
                    }
                    .switch {
                      display: block;
                      width: 16px;
                      height: 16px;
                      margin: 5px;
                      background: #5F6168;
                      position: absolute;
                      top: -3px;
                      bottom: 0;
                      right: 12px;
                      border: 0 solid #bbb;
                      border-radius: 20px;
                      transition: all 0.3s ease-in 0s;
                    }
                  }
                  .checkbox:checked + .label .inner {
                    margin-left: 0;
                    &:after{
                      background: #FFFFFF;
                    }
                    &:before{
                      background: #FFFFFF;
                    }
                  }
                  .checkbox:checked + .label .switch {
                    right: -2px;
                    background: #7CBAA7;
                  }
                  &.regular-style {
                    .inner {
                      &:before {
                        color: #fff;
                        background-color: #7CBAA7;
                      }
                      &:after {
                        color: #fff;
                        background-color: #B2BDCB;
                      }
                    }
                    .switch {
                      background: #5F6168;
                    }
                  }
                  &.neumorphic-style {
                    .inner {
                      &:before {
                        color: #fff;
                        background-image: linear-gradient(#4E4E57, #E5E9ED);
                      }
                      &:after {
                        color: #fff;
                        background-image: linear-gradient(#4E4E57, #E5E9ED);
                      }
                    }
                    .checkbox:checked + .label .inner {
                      margin-left: 0;
                      &:after{
                        background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                      }
                      &:before{
                        background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                      }
                    }
                    .checkbox:checked + .label .switch {
                      right: -2px;
                      background: #EAEFF6;
                    }
                    .switch {
                      background: #5F6168;
                    }
                  }
                  &.glassmorphic-style {
                    .inner {
                      &:before {
                        color: #fff;
                        background: #bababa;
                      }
                      &:after {
                        color: #fff;
                        background: #bababa;
                      }
                    }
                    .checkbox:checked + .label .inner {
                      margin-left: 0;
                      &:after{
                        background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                      }
                      &:before{
                        background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                      }
                    }
                    .checkbox:checked + .label .switch {
                      right: -2px;
                      background: #202020;
                    }
                    .switch {
                      background: #202020;
                    }
                    strong{
                      color: #202020;
                    }
                  }
                }
                strong {
                  color: #202020;
                  font-size: 12px;
                  font-weight: 600;
                  margin: 15px 0 0;
                  display: block;
                }
              }
            }
          }
          .signup-form {
            margin: 15px 0 0 0;
            position: relative;
            z-index: 2;
            &:after{
              content: "";
              position: absolute;
              bottom: -19px;
              right: 147px;
              width: 0;
              height: 0;
              border-left: 30px solid transparent;
              border-right: 30px solid transparent;
            }
            &:before{
              content: "";
              position: absolute;
              bottom: -19px;
              left: 147px;
              width: 0;
              height: 0;
              border-left: 30px solid transparent;
              border-right: 30px solid transparent;
            }
            form {
              .form-group {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                padding: 0 0 14px;
                input {
                  width: 350px;
                  font-size: 13px;
                  color: #5F6168;
                  letter-spacing: 0.52px;
                  text-transform: uppercase;
                  margin: 0 auto;
                  font-weight: 500;
                  height: 45px;

                }
                button {
                  width: 102px;
                  text-align: center;
                  margin: 0 auto;
                  font-size: 13px;
                  font-weight: 600;
                  letter-spacing: 0.52px;
                  text-transform: uppercase;
                  padding: 18px 40px;
                  height: 46px;
                }
                .nothanks-btn{
                  color: #5F6168;
                  text-decoration: none;
                  margin: 5px 0 0 0;
                }
              }
            }
            &.regular-style {
              background: #F0F4F8;
              &:after {
                border-top: 20px solid #F0F4F8;
              }
              &:before{
                border-top: 20px solid #F0F4F8;              }
              form {
                .form-group {
                  background: #F0F4F8;
                  padding: 0 0 14px;
                  input {
                    background: #F0F4F8;
                    border-radius: 0;
                    border: 1px solid #202020;
                    &::placeholder{
                      color: #5F6168;
                    }
                  }
                  button {
                    background-color: #7CBAA7;
                    border-radius: 0;
                    color: #202020;
                    box-shadow: none;
                    &:hover {
                      background: #7CBAA7;
                    }
                  }
                }
              }
            }
            &.neumorphic-style {
              background: transparent;
              &::after {
                width: 530px;
                background-image: url('../../../../../../images/subscription/envelop-theme2-neumorphic-bg-img.png');
              }
              form {
                .form-group {
                  background: transparent;
                  input {
                    background: #F0F4F8;
                    box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
                    border-radius: 4px;
                  }
                  button {
                    border-radius: 5px;
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  }
                }
              }
            }
            &.glassmorphic-style {
              &::after {
                background-image: url('../../../../../../images/subscription/envelop-theme2-bg-img.png');              }
              form {
                .form-group {
                  input {
                    background:  linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
                    border-radius: 4px;
                    backdrop-filter: blur(10px);
                    color: #202020;
                    &::placeholder{
                      color: #202020;
                    }
                    &:focus{
                      box-shadow: none;
                    }
                  }
                  button {
                    border-radius: 5px;
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
