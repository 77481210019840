@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Norican&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Qwigley&display=swap');

.TAB-8-form {
    h2 {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        color: #697094;
        text-align: center;
    }

    .dancing_font {
        font-family: 'Dancing Script', cursive;
    }

    .great_vibes_font {
        font-family: 'Great Vibes', cursive;
    }

    .Norican_font {
        font-family: 'Norican', cursive;
    }

    .Madi_font {
        font-family: 'Ms Madi', cursive;
    }

    .Sacramento_font {
        font-family: 'Sacramento', cursive;
    }

    .country .css-13cymwt-control {
        background: #EFF4F8;
        box-shadow: inset -3px -3px 10px #FFFFFF, inset 4px 4px 8px #889FB6;
        border-radius: 30px !important;
    }

    .country .css-13cymwt-control,
    .country .css-t3ipsp-control,
    .country .css-1kdep9t-control,
    .country .css-7fr252-control {
        height: 50px;
        padding: 0;
        background: none;
        box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
        border-radius: 40px;
        border: 0px solid #000;
    }

    .css-13cymwt-control {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
        transition: all 100ms;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
    }

    .signature-button .btn-check+.btn {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 15px 30px;
        width: 100%;
        height: 85px;
        border: 0;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 10px;
        font-weight: 400;
        font-size: 32px !important;
        line-height: 120%;
        color: #1C1C1C;

        .icon {
            width: 1.25rem;
            height: 1.25rem;
            position: absolute;
            right: 8px;
            bottom: 8px;
            color: #7F838F;
        }
    }

    .signature-button .btn-check:checked+.btn {
        border: 2px solid #DDA01A;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 10px;

        .icon {
            color: #e59913;
        }
    }

    .final_signature {
        label {
            font-weight: 400;
            font-size: 15px;
            line-height: 100%;
            letter-spacing: 0.01em;
            color: #1C1C1C;

        }

        .signature_box {
            background: #EFF4F8;
            box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
            border-radius: 5px;
            height: 160px;

            span {
                font-family: 'Qwigley', cursive;
                font-weight: 400;
                font-size: 60px;
                line-height: 120%;
                color: #1C1C1C;
            }
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            color: #2B2B2E;
        }
    }

    .items-center {
        align-items: center;
    }

    .ip_input {
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 30px;
    }

    .signature_box {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 160px;
        background: #EFF4F8;
        box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
        border-radius: 5px;
        font-weight: 400;
        font-size: 60px;
        line-height: 120%;
        color: #1C1C1C;
    }

    .signature_label {
        font-weight: 400;
        font-size: 15px;
        line-height: 100%;
        /* identical to box height, or 15px */

        letter-spacing: 0.01em;

        color: #000000;
    }

    .white_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 30px;
        background: #EFF4F8;
        box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
        border-radius: 5px;
        width: auto;
        font-weight: 600;
        font-size: 12px;
        line-height: 190%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #1C1C1C;
        @media only screen and (max-width: 1220px) {
            padding: 12px;
          }
    }

    .green_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 30px;
        background: #77CB43;
        box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
        border-radius: 5px;
        width: auto;
        font-weight: 600;
        font-size: 12px;
        line-height: 190%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
        @media only screen and (max-width: 1220px) {
            padding: 12px;
          }
    }

    .yellow_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 30px;
        background: var(--goldenlinear);
        box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
        border-radius: 5px;
        width: auto;
        font-weight: 600;
        font-size: 12px;
        line-height: 190%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #000;
        @media only screen and (max-width: 1220px) {
            padding: 12px;
          }
    }

    #upload {
        label {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            color: #1C1C1C;
        }

        .custom-file-drop-area {
            background: #EFF4F8;
            box-shadow: inset -5px -5px 8px #FFFFFF, inset 4px 4px 8px #889FB6;
            border-radius: 20px;
            height: 250px;
        }

        .drop-area-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 30px;
            background: var(--goldenlinear);
            box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
            border-radius: 5px;
        }
    }

    .signature_font {
        font-family: 'Qwigley', cursive;
    }

    .signature_icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 10px;
        position: absolute;
        width: 38px;
        height: 26px;
        right: 0;
        top: 0;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 3px;

        .icon {
            color: #F0BF25;
        }
    }

    .relative {
        position: relative;
    }

    .yellow_download_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        width: 222px;
        height: 40px;
        background: var(--goldenlinear);
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 40px;

        .signature_icon {
            right: 12px;
            top: 8px;
        }
    }

    .gap-10 {
        gap: 10px;
    }

    .w_50 {
        width: 50%;
    }

    .download_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 3px;

        .icon {
            color: #F0BF25;
        }
    }

    .QR_outer_div {
        display: flex;
        padding: 10px;
        width: 80px;
        height: 80px;
        // background: #EFF4F8;ss
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 5px;

        .QR_box {
            background-image: url("/images/QR.png");
            background-repeat: no-repeat;
            height: auto;
            width: 80px;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .dark .ProgressBar-timeline ul li {
        background: #1c1c1c;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
    }

    .w-350 {
        width: 350px;
    }

    .text_light button {
        // color: #fff;
    }

    #confirm {
        h1 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            color: #1C1C1C;
        }
    }

    #draw {
        h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            color: #1C1C1C;
        }

        .draw_box {
            background-image: url("/images/draw-bg.png");
            background-repeat: no-repeat;
            height: 250px;
            background-size: 100% 100%;
            padding: 35px 75px;

            .draw_area {
                width: 100%;
                height: 180px;
                background: #EFF4F8;
                box-shadow: inset 0px -4px 8px #FFFFFF, inset 0px 4px 6px #A0B9D0;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    font-family: 'Sacramento', cursive;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 120%;
                    color: #1C1C1C;
                    opacity: 0.1;
                }
            }
        }

        .color_input {
            width: 151px;
            height: 36px;
            background: #EFF4F8;
            box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
            border-radius: 10px;
            display: flex;
            align-items: center;
            padding: 5px 15px;

            input {
                width: 30px;
                height: 24px;
                background: #4770DA;
                border-radius: 5px;
                margin-right: 5px;
            }
        }
    }

    .TabBtns ul li {
        position: relative;
        // margin-left: 2rem;
        margin-left: 5%;
    }

    .TabBtns ul li:first-child {
        margin-left: 0;
    }

    .TabBtns ul li button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        height: 40px;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 40px;
    }

    // .TabBtns ul li::before {
    //     content: "";
    //     position: absolute;
    //     background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%);
    //     box-shadow: inset -3px -3px 2px var(--shadow-light-50), inset 2px 2px 3px rgba(77, 89, 107, 0.15);
    //     border-radius: 5px;
    //     width: 161px;
    //     height: 9px;
    //     z-index: -1;
    //     top: 20px;
    // }
    // .TabBtns ul li::after {
    .TabBtns ul li::before {
        content: "";
        position: absolute;
        background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%);
        box-shadow: inset -3px -3px 2px var(--shadow-light-50), inset 2px 2px 3px rgba(77, 89, 107, 0.15);
        border-radius: 5px;
        width: 60px;
        height: 9px;
        z-index: -1;
        top: 20px;
        left: -50px;
        // right: -50px;
    }

    // .TabBtns ul li:last-child:after {
    //     display: none;
    // }
    .TabBtns ul li:first-child:before {
        display: none;
    }

    .TabBtns ul li button.active {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        height: 40px;
        background: var(--goldenlinear) !important;
        color: #1C1C1C !important;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 40px;
    }


    .user_type {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 15px;
        height: 250px;
        // background: #EFF4F8;
        // box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
        border-radius: 20px;
    }

    .user_icon {
        display: flex;
        align-items: flex-start;
        padding: 15px;
        width: 54px;
        height: 54px;
        background: #EFF4F8;
        box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
        border-radius: 40px;
        margin: 0 auto;
        margin-bottom: 14px;
    }

    .user_icon svg {
        color: #4770DA;
    }

    .user_type h6 {
        font-weight: 500;
        font-size: 15px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #1C1C1C;
    }

    .user_type p {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #4E4E57;
        height: 45px;
    }

    .user_type_button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 25px;
        background: var(--goldenlinear);
        box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
        border-radius: 30px;
        border: 0;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 2px;
    }

    .rotate_btn {
        .btn-bg button {
            top: 21px;
            transform: rotate(7deg);
        }
    }

}

.dark .TAB-8-form {
    input {
        color: hsl(0, 0%, 50%);
    }

    h2 {
        color: #fff;
    }

    .user_type {
        p {
            color: rgb(179, 176, 176);
        }

        h6 {
            color: #fff;
        }

        .user_icon svg {
            color: #fff
        }

    }

    .user_icon {
        background: #1c1c1c;
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }

    .TabBtns ul li button {
        color: #fff;
        background: #1c1c1c;
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
        // box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8), -4px -4px 8px rgba(46, 44, 44, 0.99);
    }

    .TabBtns ul li::before {
        box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99),
            inset 6px 9px 16px rgba(0, 0, 0, 0.8);
    }

    .user_type_button {
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }

    .signature-button .btn-check+.btn {
        color: #EFF4F8;
        background: #1c1c1c;
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }

    .signature_box {
        background: #1C1C1C;
        box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99), inset 6px 9px 16px rgba(0, 0, 0, 0.8);
        color: #EFF4F8
    }

    .signature_label {
        color: #fff;
    }

    #draw {
        .color_input {
            background: #1c1c1c;
            box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99), inset 6px 9px 16px rgba(0, 0, 0, 0.8);
        }
    }

    .white_btn {
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }

    .green_btn {
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }

    .yellow_btn {
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }

    #upload {
        .custom-file-drop-area {
            background: #1c1c1c;
            box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99), inset 6px 9px 16px rgba(0, 0, 0, 0.8);

            svg {
                color: #fff;
            }

            .drop-area-btn {
                box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
            }
        }
    }

    .ip_input {
        background: #1c1c1c;
        box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99), inset 6px 9px 16px rgba(0, 0, 0, 0.8);
    }

    .final_signature {
        label {
            color: #fff;
        }

        .signature_box span {
            color: #EFF4F8;
        }
    }

    .signature_icon {
        background: #1c1c1c;
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }

    .yellow_download_btn {
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }

    .QR_outer_div {
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;

        svg {
            color: #000;
        }
    }

    .download_icon {
        background: #1c1c1c;
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }
}