﻿@font-face {
  font-family: Lao MN;
  src: url(../fonts/lao-mn.ttf);
}
body {
	margin:0px auto;
	background:var(--page-background);
	line-height:normal;
	overflow-x:hidden;
	font-family:$site-font!important;
  color: var(--text-body);
  width: 100%;
  .dark{
    *::-webkit-scrollbar {
      width: 12px;
    }
  
    *::-webkit-scrollbar-track {
      box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000!important;
      border-radius: 28px;
      padding: 2px;
    }
  
    *::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
      border-radius: 10px;
  
      border: 3px solid #3c3d40!important;
    }
  }
}
html {
  overflow-x: hidden;
}
.container-xl {
  max-width: 1920px;
}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  // * {
  //   scrollbar-width: auto;
  //   scrollbar-color: #8f54a0 #ffffff;
  // }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
   
    box-shadow: inset -1px -1px 2px #FFFFFF, inset 1px 1px 2px rgba(178, 189, 203, 0.9);
    border-radius: 28px;
    padding: 2px;
  }
  *::-webkit-scrollbar-thumb {
    background-image: url("../images/img100.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
}
  // *::-webkit-scrollbar-thumb {
  //   background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
  //   border-radius: 10px;
  //   // position: relative;
  //   //   background-image: url("../images/img100.png");
  //   //   background-size: 28px 15px;
  //   //   background-repeat: no-repeat;
  //   //   background-position: bottom left;
  //   //   width: 32px;
  //   //   height: 35px;
  //   //   display: inline-block;
  //     // position: absolute;
  //     // bottom: 0;
  //     // left: 0;

  //   border: 3px solid #F0F4F8;
  // }
// ::-webkit-scrollbar {
//   width: 8px;
//   height:8px;
//   unicode-bidi:bidi-override;
//   direction: rtl;
// }
// ::-webkit-scrollbar-thumb {
//   border: 3px solid rgba(0, 0, 0, 0);
//   background-clip: padding-box;
//   background-color: var(--primary);
// }
img { 
	max-width:100%; 
}
textarea:focus, input:focus, select:focus { 
	outline: 0;
}
ul, ol {
	list-style:none;
	margin:0px;
	padding:0px;
}
a {
  color: var(--text-body);
  text-decoration: none;
}
/***** header ****/
h3 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
}
p{
  font-weight: 400;
  font-size: 16px;
  line-height: 190%;
}
.fw-semibold {
  font-weight: 600;
}
.p-40 {
  padding: 40px;
  @media only screen and (max-width: 767px) { 
    padding: 24px;
  }
}
.p-30 {
  padding: 30px!important;
}
.px-30 {
  padding-left: 30px!important;
  padding-right: 30px!important;
}
.px-40 {
  padding-left: 40px!important;
  padding-right: 40px!important;
}
.px-10 {
  padding-left: 10px!important;
  padding-right: 10px!important;
}
.p-20{
  padding: 20px;
}
.pb-30 {
  padding-bottom: 30px!important;
}
.pb-25 {
  padding-bottom: 25px!important;
}
.py-30 {
  padding-top: 30px!important;
  padding-bottom: 30px!important;
}
.py-25 {
  padding-top: 25px!important;
  padding-bottom: 25px!important;
}
.px-25 {
  padding-left: 25px!important;
  padding-right: 25px!important;
}
.py-17 {
  padding-top: 17px;
  padding-bottom: 17px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pe-30 {
  padding-right: 30px;
}
.pt-30 {
  padding-top: 30px;
}
.p-10{
  padding: 10px;
}
.mt-30{
  margin-top: 30px;
}
.mt-100 {
  @media (min-width:992px) {
    margin-top: 100px;
  }
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-20 {
  margin-bottom: 20px;
}
.shadow-4 {
  // box-shadow: 4px 4px 8px var(--shodow-drop-dark), -4px -4px 8px var(--shadow-light-50)!important;
  box-shadow: -2.880000114440918px -2.880000114440918px 5.760000228881836px 0px #FFF, 2.880000114440918px 2.880000114440918px 5.760000228881836px 0px #B7C7D7!important;
}
.shadow-3in{
  box-shadow: inset -3px -3px 10px rgba(255, 255, 255, 0.3), inset 4px 4px 8px #939291;
}
.shadow-4in {
  box-shadow: inset 0px -4px 8px var(--shadow-light-50), inset 0px 4px 6px var(--shadow-light);
}
.shadow-5in{
  box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
}
.shadow-6in{
  box-shadow: inset -5px -5px 8px #ffffffb3, inset 4px 4px 8px #a0b9d0;
}
.shadow-5in-dark{
  box-shadow: inset -5px -5px 8px rgba(54, 53, 53, 0.85), inset 4px 4px 8px rgba(0, 0, 0, 0.94);
}
.shadow-7in{
  box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
}
.br-100 {
  border-radius: 100px;
}
.br-100-r {
  border-radius: 0px 100px 0px 0px;
}
.br-100-l {
  border-radius: 100px 0px 0px 0px;
}
.br-40 {
  border-radius: 40px!important;
}
.br-50 {
  border-radius: 50%;
}
.br-50s {
  border-radius: 50px!important;
}
.br-e-30 {
  border-radius: 0px 30px 30px 0px
}
.br-20 {
  border-radius: 20px;
}
.br-8 {
  border-radius: 8px;
}
.br-30 {
  border-radius: 30px!important;
}
.br-15 {
  border-radius: 15px;
}
.br-top-15{
  border-radius: 15px 15px 0px 0px;
}
.br-bottom-15{
  border-radius: 0px 20px 20px 20px;
}
.br-10 {
  border-radius: 10px;
 
}
.brs-10 {
  border-radius: 10px;
  z-index: 99;
}
.br-5 {
  border-radius: 5px!important;
}
.br-3 {
  border-radius: 3px!important;
}
.w-180 {
  width: 180px;
  height: 180px;
}
.w-170 {
  width: 170px;
}
.lh-normal {
  line-height: normal!important;
}
.lh-85 {
  line-height: 85px!important;
}
.h-93 {
  height: 93px!important;
}
.shadow-3 {
  box-shadow: -2px -2px 5px #FFFFFF, 2px 2px 5px #818386;
}
.lh-44{
  line-height: 44px!important;
}
.lh-30{
  line-height: 30px!important;
}
.lh-20{
  line-height: 20px!important;
}
.w-185 {
  width: 185px;
}
.w-348 {
  width: 348px!important;
}
.w-375 {
  width: 375px!important;
}
.w-335 {
  width: 335px!important;
}
.w-400 {
  width: 400px!important;
}
.w-320 {
  width: 320px!important;
  height: 300px;
}
.w-360 {
  width: 260px!important;
  height: 46px;
}
.w-300 {
  width: 300px!important;
}
.w-250 {
  width: 250px;
}
.w-34 {
  width: 34px;
  height: 34px;
}
.w-30 {
  width: 30px;
  height: 30px;
}
.w-40 {
  width: 40px;
  height: 40px;
}
.w-50c {
  width: 50px;
  height: 50px;
}
.w-140 {
  width: 129px;
}
.w-140s {
  width: 140px;
}
.w-145 {
  width: 145px;
}
.w-168 {
  width: 168px;
}
.w-85 {
  width: 85px;
}
.w-60 {
  width: 60px;
}
.w-110 {
  width: 110px!important;
}
.h-110{
  height: 125px;
}
.bottom-50e{
  bottom: -70px;
}
.w-125 {
  width: 125px;
}
.w-113 {
  width: 108px;
}
.w-105 {
  width: 105px;
}
.w-100x {
  width: 100px;
}
.w-480 {
  width: 480px;
}
.py-15{
  padding-top: 10px;
  padding-bottom: 10px;
}
.w-90e {
  width: 90%;
}
.w-160{
  width: 160px;
}
.h-90 {
  height: 90px;
}
.h-26 {
  height: 26px;
}
.h-300 {
  height: 300px;
}
.h-48{
  height: 48px;
}
.h-286 {
  height: 286px!important;
}
.h-138 {
  height: 138px!important;
}
.h-110 {
  height: 120px!important;
}
.h-250 {
  height: 250px;
}
.h-30 {
  height: 30px;
}
.h-33 {
  height: 33px;
}
.h-370 {
  height: 370px!important;
}
.h-320 {
  height: 320px!important;
}
.h-36 {
  height: 36px;
}
.h-385 {
  height: 385px!important;
}
.h-293 {
  height: 293px!important;
}
.h-420 {
  height: 420px!important;
}
.w-90s {
  width: 90px!important;
}
.h-100s {
  height: 100px;
}
.h-125 {
  height: 125px;
}
.h-700 {
  height: 600px;
}
.h-780 {
  height: 780px;
  overflow-x: overlay;
}
.h-70 {
  height: 70px;
}
.w-65 {
  width: 65px;
  height: 65px;
}
.w-16 {
  width: 16px;
  height: 16px;
}
.w-19 {
  width: 19px;
  height: 19px;
}
.w-24 {
  width: 24px;
  height: 24px;
}
.w-26 {
  width: 26px;
  height: 26px;
}
.h15{
  font-size: 15px;
  line-height: 24px;
}
.w-28{
  width: 28px;
  height: 28px;
}
.border-7{
  border: 7px solid #303E6D;
  padding: 20px;
  border-radius: 7px;
}
.bgm-transparent {
  background-image: none!important;
}
.rounded-4s{
  border-radius: 0px 0px 30px 30px;
}
.h-160 {
  height: 160px;
}
.h-230 {
  height: 230px;
}
.h-210 {
  height: 211px;
}
.h-145 {
  height: 145px;
}
.h-120 {
  height: 120px;
}
.w-240 {
  width: 240px!important;
}
.top-10{
  top:10px;
}
.top-20{
  top:20px;
}
.end-15{
  right:15px;
}
.end-50s{
  right:50px;
}
.start-16{
  left:16px;
}
.w-220 {
  min-width: 220px!important;
}
.w-221 {
  width: 220px!important;
}
.w-450 {
  width: 450px!important;
}
.w-46 {
  width: 46px;
  height: 46px;
}
.w-200 {
  width: 200px!important;
}
.h-200 {
  height: 200px!important;
}
.w-270 {
  width: 270px;
}
.h-45 {
  height: 47px!important;
}
.h-42 {
  height: 42px!important;
}
.h-450 {
  height: 450px!important;
}
.h8 {
  font-size: 14px!important;
  line-height: normal;
}
.h9 {
  font-size: 18px!important;
}
.h10 {
  font-size: 10px!important;
  line-height: 10px!important;
}
.h13 {
  font-size: 13px!important;
}
.h11s {
  font-size: 11px!important;
  line-height: 11px!important;
}
.h10s {
  font-size: 10px!important;
  line-height: 12px!important;
}
.w-90{
  width: 87%;
}
.op-0 {
  opacity: 0;
}
.h11 {
  font-size: 6px!important;
}
.h-40 {
  height: 40px!important;
  line-height: 40px;
}
.h-40s {
  height: 40px!important;
}
.h-44 {
  height: 44px!important;
}
.h-50 {
  height: 50px;
  line-height: 50px;
}
.h-50s {
  height: 50px!important;
  line-height: 50px;
}
.h7, h7 {
  font-size: 12px!important;
}
.h22 {
  font-size: 22px!important;
}
.h30 {
  font-size: 30px!important;
}
.w-780 {
  width: 780px;
}
.w-720 {
  width: 720px;
}
.w-70 {
  width: 70px;
}
.w-600 {
  width: 100%;
  padding-right: 170px!important;
}
.w-690 {
  width: 690px;
}
.w-600s {
  width: 600px;
}
.w-560 {
  width: 563px;
}
.w-650 {
  width: 650px;
}
.w-970 {
  width: 970px;
}
.w-950 {
  width: 950px;
}
.w-1200 {
  width: 1000px!important;
}
.w-1200s {
  width: 1200px!important;
}
.w-1070 {
  width: 1140px!important;
}
.w-1300 {
  width: 1300px!important;
}
.w-1340 {
  width: 1300px!important;
}
.w-1440 {
  width: 1440px!important;
}
.w-1300x {
  width: 1066px!important;
}
.w-150 {
  width: 150px;
}
.w-890 {
  width: 890px!important;
}
.w-125s {
  width: 125px;
  height: 125px;
}
.border-dot {
  border: 1px dashed var(--shodow-drop-40);
}
.icon-sucess {
  background: var(--bs-success);
}
.img-class {
  border: 8px solid var(--page-background);
  box-shadow: inset -4px -4px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
  filter: drop-shadow(4px 4px 8px var(--shadow-light)) drop-shadow(-4px -4px 8px var(--shadow-light-50));
  border-radius: 150px;
}
.mb-30 {
  margin-bottom: 30px;
}
.border-2 {
position: relative;
// border: var(--goldenlinear) !important;
// &::after{
//   content: "";
//   position: absolute;
//   inset: 0;
//   border-radius: 10px; 
//   padding: 3px; 
//   background:var(--goldenlinear); 
//   -webkit-mask: 
//      linear-gradient(#fff 0 0) content-box, 
//      linear-gradient(#fff 0 0);
//   -webkit-mask-composite: xor;
//           mask-composite: exclude; 
// }
// border: 3px solid !important;
// border-image: var(--goldenlinear) !important;
  }
.form-sec{
  width: 430px;
  transition:all .4s ease-in-out 0s;
  position: relative;
  top: 0;
  z-index: 1;
  li {
      margin-bottom: 10px;
  }
  @media (max-width:991px) and (min-width: 768px){ 
    margin: 0 14px 0 auto!important;
  }
  @media only screen and (max-width: 767px) {
    width: 90%;
    margin: 0 auto!important;
  }
}
input[type="text"], input[type="email"], input[type="password"], input[type="phone"]{
  height: 50px;
  width: 100%;
  background: none;
  border: 0px solid #000;
  padding: 0 15px;
  font-size: 14px;
  color: #7F838F;
}
input[type="range"]{
  height: 30px;
  width: 100%;
  background: none;
  border: 0px solid #000;
  padding: 0 15px;
  font-size: 14px;
  color: #7F838F;
}
textarea{
  height: 150px;
  width: 100%;
  background: none;
  border: 0px solid #000;
  padding: 15px;
  color: #7F838F;
  resize: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  background-color: var(--page-background)!important;
}
.icon-frame {
  box-shadow: 4px 4px 8px var(--shodow-drop-dark), -4px -4px 8px var(--shadow-light-50);
}
small {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  display: block;
}
.text-sec {
  padding:100px 30px ;
  background-color: var(--page-background);
  position: relative;
}
.bg {
  background-color:var(--page-background)!important;
}
.bg2 {
  background:var(--page-background)!important;
}
.bg3{
  background-color:#57a527!important;
}
.bg1{
  background-color: var(--page-background);
}
.text-body {
  color: var(--text-body)!important;
}
.text-sec{
  &::before {
    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
    content: "";
    width: 102%;
    height: 102%;
    position: absolute;
    top:-5px;
    left: -5px;
    z-index: -1;
    border-radius: 20px;
  }
}
.mt-n9 {
  margin-top: -75px;
}
.mt-n6 {
  margin-top: -70px;
}
.mt-n7 {
  margin-top: -60px;
}
.z-index {
  z-index: 3;
}
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-4 {
  z-index: 4!important;
}
.form-check-input {
  cursor: pointer;
}
.text-rounded {
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  svg {
    transform: rotate(348deg);
    position: absolute;
    top: -70px;
    left: 0px;
    right: 0;
    margin: 0 auto; 
    fill: var(--text-body);   
  }
}
.top-sec {
  width: 350px;
  left: -300px;
  top: 0px;
  // z-index: 2;
  &::after {
    content: url("../images//triangle.png");
    position: absolute;
    bottom: -40px;
    right: 0;
    z-index: -999;
  }
  h4 {
    img {
      margin: -52px auto 10px;
    }
  }
  @media (max-width:991px) and (min-width: 320px){
    left: 0;
    top: -102px;
    h4 {
      font-size: 16px;
    }
  }

}
.close .top-sec {
  left: -50px;
  top: 368px;
  @media (max-width:991px) and (min-width: 768px){
    left: 50px;
  }
  @media only screen and (max-width: 767px) { 
    left: 20px;
  }
}
.btn-bg {
  box-shadow: 4px 4px 8px var(--shadow-light), -4px -4px 8px var(--shadow-light-50), inset -4px -4px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
  border-radius: 5px;
  height: 47px;
  line-height: 47px;
  text-align: center;
  color: var(--text-body);
}
.w-500 {
  width: 500px!important;
}
.w-520 {
  width: 520px!important;
}
.w-300 {
  width: 300px!important;
}
.w-50s {
  width: 50px!important;
}
.w-560 {
  width: 560px!important;
}
.w-540 {
  max-width: 540px!important;
  width: 100%;
}
.ms-6 {
  margin-left: 35px;
  @media only screen and (max-width: 767px) { 
    margin-left: 0;
  }
}
.btn-bg {
    button {
        background:var(--goldenlinear);
        box-shadow: 6px 8px 8px rgba(42, 46, 56, 0.12);
        border-radius: 5px;
        font-size: 12px;
        font-weight: 600;
        line-height: 190%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        line-height: 47px;
        transition:all .4s ease-in-out 0s;
        position: absolute;
        top: 9px;
        left: 2px;
        transform: rotate(10deg);
        // z-index: 1;
    }
}
.btn-bg.w-240 {
  button {
    top:20px;
  }
}
.btn-bg.lg {
  button {
    top: 21px;
    transform: rotate(6deg);
  }
}
// .btn-bg.text-primary {
//   button {
//     color: #fff!important;
//   }
// }
.text-primary{
  color:#FEC83A;
}
.btn-bg.w-200 {
  button {
    top:16px;
  }
}
.w-185{
  button{
    top:12px;
    transform: rotate(8deg);
    left: 4px;
  }
}
.anvalup {
  @media (max-width:991px) and (min-width: 768px){ 
    right: 0;
  }
  @media only screen and (max-width: 767px) { 
    width: 95%;
  }
}
.label-img {
  position: absolute;
  bottom: 120px;
  left: 200px;
  width: 100px;
  height: 100px;
  @media only screen and (max-width: 767px) {  
    left: 148px;
    bottom: 108px;
  }
}
.contact-area {
  height: 790px;
  overflow: hidden;
  @media only screen and (max-width: 767px) { 
    height: 715px;
  }
}
.contact-area::before {
  content: url("../images/img4.png");
  position: absolute;
  bottom: 133px;
  left: 49px;
  z-index: 1;
  width: 500px;
  @media (max-width:991px) and (min-width: 768px){ 
    left: inherit;
    right: 0;
  }
  @media only screen and (max-width: 767px) { 
    transform: scale(0.74);
    left: -52px;
    bottom: 43px;
  }
}
.label-2 {
    display: none;
  }
.close {
    .contact-area {
        &::after {
            content:"";
            background-color: #EBDDDE;
            height: 225px;
            left: 47px;
            position: absolute;
            bottom: 133px;
            z-index: -1;
            width: 500px;
            @media (max-width:991px) and (min-width: 768px){
              left: inherit;
              right: 8px;
            }
            @media only screen and (max-width: 767px) { 
              width: 96%;
              left: 10px;
              bottom:37px;
            }
        }
        &::before {
            content:"";
        }
    }
    .form-sec{
        top: 700px;
        transition:all .7s ease-in-out 0s;
    }
    .label-2 {
        display: block;
    }
    .label-1 {
        display: none;
    }
    .btn-bg {
      button {
          transition:all .3s ease-in-out 0s;
          top: 250px;
          opacity: 0;
    }
  }
  #hide_me1 {
    -moz-animation: cssAnimation 0s ease-in 5s forwards;
    -webkit-animation: cssAnimation 0s ease-in 5s forwards;
    -o-animation: cssAnimation 0s ease-in 5s forwards;
    animation: cssAnimation 0s ease-in 5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  @keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
  }
  @-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
  }
}
.form-switch .form-check-input:checked {
  background-color: #FDE992;
  border-color: var(--primary);
}



.login-tab {
  position: relative;
  border: 0px solid #000;
  &::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 96%;
    display: inline-block;
    background: #EBDDDE;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 12px;
  }
  li.nav-item{
    width: 25%;
    padding: 0 5px;
      button.nav-link {
        font-weight: 500;
        font-size: 11px;
        text-align: center;
        height: 50px;
        border-radius: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--page-background);
        color: var(--text-body);
        letter-spacing: 0.01em;
        box-shadow: 4px 4px 8px #C9D9E8, -4px -4px 8px #FFFFFF;
        position: relative;
        &::before {
          width: 100px;
          height: 30px;
          position: absolute;
          top: -66px;
          left: 0;
          right: 0;
          margin: 0 auto;
          border-radius: 20px;
        }
      }
      button.active {
        background: #EFF4F8;
        box-shadow: inset -3px -3px 10px #FFFFFF, inset 4px 4px 8px #889FB6;
        border-radius: 30px;
        &::before {
          content: url("../images/goldenbutton2.png");
        }
      }
  }
}
.profiletb {
  li{
    width: 128px!important;
      button.active {
        background: var(--goldenlinear)!important;
        color: #000!important;
      }
  }
}

.scroll-bar {
  height: 40px;
}
.scroll-bar1 {
  height: 20px;
  width: 85%;
  margin: 0 auto;
}
.tab-button {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 30px;
    width: 96%;
    display: inline-block;
    background: #EBDDDE;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 105px;
  }
  button {
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    text-align: center;
    background: var(--page-background);
    color: var(--text-body);
    letter-spacing: 0.01em;
    box-shadow: 4px 4px 8px var(--shodow-drop-dark), -4px -4px 8px var(--shadow-light-50);
    position: relative;
    text-transform: uppercase;
    &:focus {
      box-shadow: inset 0px -4px 8px var(--shadow-light-50), inset 0px 4px 6px var(--shadow-light);  
    }
    &::before {
      width: 100px;
      height: 30px;
      position: absolute;
      top: -64px;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-radius: 20px;
    }
  }
  button.active {
    box-shadow: inset 0px -4px 8px var(--shadow-light-50), inset 0px 4px 6px var(--shadow-light);
    &::before {
      content: url("../images/button-golden.png");
    }
  }
  @media (max-width:991px) and (min-width: 320px){
    &::before {
      background: none;
    } 
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        width: 20%;
        margin-bottom: 30px;
      }
    }
    button.active {
      &::before {
        content: "";
      }
    }
  }
  @media only screen and (max-width: 767px) { 
    ul {
      li {
        width: 50%;
        text-align: center;
        margin-right: 0px!important;
      }
    }
  }
}
.email-sec {
  width: 540px;
  height: 243px;
  background: #2B2B2E;
  box-shadow: inset 0px -4px 8px #1C1C1C, inset 0px 4px 8px #4E4E57;
  border-radius: 30px;
  position: relative;
  @media only screen and (max-width: 767px) { 
    width: 100%;
   }
   p{
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #AEAEBB;
   }
   input {
    width: 450px;
    height: 50px;
    background: #1C1C1C;
    box-shadow: inset 0px -2px 5px #4E4E57, inset 0px 4px 8px #101010;
    @media only screen and (max-width: 767px) {  
      width: 100%;
    }
   }
   &::after {
    background: #EBDDDE;
    border-radius: 30px;
    transform: rotate(-10deg);
    content: "";
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -42px;
    z-index: -1;
    @media only screen and (max-width: 767px) {
      width: 0px;
    }
   }
   &::before {
    background: #F6ECE5;
    border-radius: 30px;
    transform: rotate(10deg);
    content: "";
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -22px;
    z-index: -1;
    @media only screen and (max-width: 767px) {
      width: 0px;
    }
   }
}
.social-icon {
  ul {
    li {
      @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }
}
.bs-primary {
  background: var(--goldenlinear)!important;
}
.bt-primary {
  background: linear-gradient(169.44deg, #FACB30 -5.83%, #FEF9C8 35.12%, #C49530 89.43%, #FFF8C4 129.64%);
}
.border-linear {
  // border:2px solid #FEC83A;
  background: var(--goldenlinear);
  padding: 2px;
}
.texts-primary {
  background: -webkit-linear-gradient(#DDA01A, #FEC83A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mt-n8{
  margin-top: -45px;
}
.svg-primary {
  color: #FEC83A;
}
.top-15{
  top:15px;
}
.start-15{
  left:15px;
}


.sidebar-area {
    margin-top: -250px;
    width: 100%;
    padding-top: 130px;
    text-align: center;
}
.sidebar-area-top{
  padding-top: 155px!important;
  .drop-sec{
    margin-top: 125px;
  }
  
}
.leftSection{
  span {
    width: 50px;
    height: 50px;
    box-shadow: 5px 13px 15px var(--shadow-light), -4px -8px 10px var(--shadow-light-50);
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin:10px;
    position: relative;
    // &:before {
    //   background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
    //   content: "";
    //   width: 102%;
    //   height: 102%;
    //   position: absolute;
    //   top:-5px;
    //   left: -5px;
    //   z-index: -1;
    //   border-radius: 20px;
    // }
  }
  a {
      color: var(--social-gray);
      font-size: 18px;
      svg {
          margin: 0;
      }
  }
}
.overflow-overlay {
  overflow-y: overlay;
  overflow-x: hidden;
}
.fixed-large {
  @media only screen and (min-width: 1921px) {
  -webkit-transform: translateZ(0);
  }
}
.bg-primary {
  background:var(--goldenlinear);
}

/*** Sidebar ***/
.fs-11 {
  a{
    font-size: 10px!important;
  }
}
.SideBar {
  padding: 15px;
    a {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #525253;
      text-transform: uppercase;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      box-shadow: 5px 13px 15px var(--shadow-light), -4px -8px 10px var(--shadow-light-50);
      margin:0 auto 15px;
      padding:0 13px;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      &:focus {
        box-shadow: inset -3px -3px 12px rgba(255, 255, 255, 0.84), inset 5px 5px 7px rgba(72, 72, 72, 0.33);
      }
      span, .h8 {
        display: none;
        line-height: 19px;
      }
      img {
        margin-right: 8px;
      }
  }
  .largeHeading {
    margin: 63px auto!important;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 10px;
      padding: 2px; /* control the border thickness */
      background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
      -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude;
      pointer-events: none;
    }
  }
}

.w-300 {
  width: 300px;
  z-index: 4;
    a {
      width: 100%;
      box-shadow: -3px -4px 5px rgba(255, 255, 255, 0.55), 1px 3px 4px rgba(72, 72, 72, 0.27);
      span, .h8 {
        display: block;
      }
    }
    .largeHeading {
      height: 138px;
      padding: 16px 20px;
      margin: 30px auto!important;
    }
  }
.subBar {
  position: relative;
  display: none;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 3px; /* control the border thickness */
    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    pointer-events: none;
  }
  li{
    a {
      box-shadow: -3px -4px 5px rgba(255, 255, 255, 0.55), 1px 3px 4px rgba(72, 72, 72, 0.27);
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #525253;
      text-transform: uppercase;
      width: 100%;
      height: 50px;
      text-align: left;
      display: flex;
      align-items: center;
      margin:0 auto 15px;
      padding:0 0px 0 25px;
      &:focus {
        box-shadow: inset -3px -3px 12px rgba(255, 255, 255, 0.84), inset 5px 5px 7px rgba(72, 72, 72, 0.33);
      }
    }
  }
}

.darkImg {
  display: none;
}
.dividerSidebar {
  width: 98px;
  height: 5px;
  box-shadow: inset 1px -1px 2px var(--shadow-dark), inset 0px 0px 1px var(--shadow-dark-50);
  border-radius: 13px;
}
.ImgArea {
  margin-top: 50px;
  .user {
    width: 65px;
  }
  .userImg img {
    width: 54px;
    height: 54px;
  }
  .tag {
    left: -2px;
    img {
      width: 9px;
      height: auto;
    }
  }
  .flag{
    left: -7px;
    bottom: -2px;
    img {
      width: 18px;
      height: auto;
    }
  }
  .status {
    img {
      width: 15px;
      height: 15px;
    }
  }
  h6{
    font-size: 12px;
    width: 110px;
    height: 24px;
    line-height: 24px;
    font-weight: 500;
    box-shadow: -3px -4px 5px rgba(255, 255, 255, 0.55), 1px 3px 4px rgba(72, 72, 72, 0.27);
    border-radius: 3px;
  }
}
.overlayer {
    position: fixed;
    content: "";
    width: 80px;
    height: 100%;
    background: rgba(0,0,0,0);
    left: 38px;
    top: 0;
    z-index: 7;
    @media (max-width:1366px) and (min-width: 992px){ 
      left: 31px;
    }
}
.overlayer1 {
  position: fixed;
  content: "";
  width: 80px;
  height: 100%;
  background: rgba(0,0,0,0);
  right: 38px;
  top: 0;
  z-index: 14;
  @media (max-width:1366px) and (min-width: 992px){ 
    right: 31px;
  }
}
.overlayer0, .overlayer2 {
    z-index: -1;
    height: 0;
    width: 0;
}
.dropdown-menu {
  a {
    color: var(--text-body);
    padding: 5px 10px;
    width: 100%;
    display: inline-block;
    &:focus {
      box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
    }
  }
}

.subBar.dropdown{
  a {
    position: relative;
    padding-left: 50px!important;
    &::before {
      content: "";
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIzNCIgY2xhc3NOYW1lPSJtZS0zIiB2aWV3Qm94PSIwIDAgOCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLjY2MTA1IDkuOTk5NzNDMS44MTA0NCA5Ljk5OTIyIDEuOTU4MDUgMTAuMDMyMiAyLjA5MzAzIDEwLjA5NjJDMi4yMjgwMSAxMC4xNjAyIDIuMzQ2OTMgMTAuMjUzNyAyLjQ0MTA1IDEwLjM2OTdMNy4yNzEwNSAxNi4zNjk3QzcuNDE4MTMgMTYuNTQ4NyA3LjQ5ODU0IDE2Ljc3MzEgNy40OTg1NCAxNy4wMDQ3QzcuNDk4NTQgMTcuMjM2MyA3LjQxODEzIDE3LjQ2MDggNy4yNzEwNSAxNy42Mzk3TDIuMjcxMDUgMjMuNjM5N0MyLjEwMTMxIDIzLjg0MzkgMS44NTc0IDIzLjk3MjQgMS41OTI5NyAyMy45OTY3QzEuMzI4NTUgMjQuMDIxMSAxLjA2NTI2IDIzLjkzOTUgMC44NjEwNDggMjMuNzY5N0MwLjY1NjgzMSAyMy42IDAuNTI4NDA3IDIzLjM1NjEgMC41MDQwMjcgMjMuMDkxNkMwLjQ3OTY0OCAyMi44MjcyIDAuNTYxMzA5IDIyLjU2MzkgMC43MzEwNDggMjIuMzU5N0w1LjIwMTA1IDE2Ljk5OTdMMC44ODEwNDggMTEuNjM5N0MwLjc1ODc2NSAxMS40OTI5IDAuNjgxMDg4IDExLjMxNDIgMC42NTcyMDggMTEuMTI0N0MwLjYzMzMyOSAxMC45MzUxIDAuNjY0MjQ2IDEwLjc0MjcgMC43NDYzMDIgMTAuNTcwMkMwLjgyODM1OCAxMC4zOTc2IDAuOTU4MTE3IDEwLjI1MjIgMS4xMjAyMyAxMC4xNTExQzEuMjgyMzQgMTAuMDUgMS40NzAwMSA5Ljk5NzQ5IDEuNjYxMDUgOS45OTk3M1oiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl83MF80NzEyKSIvPg0KICAgICAgICAgICAgICAgICAgPGRlZnM+DQogICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfNzBfNDcxMiIgeDE9IjMuOTk5MTgiIHkxPSIyNi41MjEyIiB4Mj0iMy45OTkxOCIgeTI9IjcuNzU5NDIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4NCiAgICAgICAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiNGQUNCMzAiLz4NCiAgICAgICAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMC4zMDIyNjkiIHN0b3AtY29sb3I9IiNGRUY5QzgiLz4NCiAgICAgICAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMC42NDA2MjUiIHN0b3AtY29sb3I9IiNDNDk1MzAiLz4NCiAgICAgICAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZGRjhDNCIvPg0KICAgICAgICAgICAgICAgICAgPC9saW5lYXJHcmFkaWVudD4NCiAgICAgICAgICAgICAgICAgIDwvZGVmcz4NCiAgICAgICAgICAgICAgICA8L3N2Zz4=);
      position: absolute;
      left: 25px;
      top:8px;
      height: 34px;
      width: 8px;
      background-repeat: no-repeat;
    }
  }
}
.dropdown {
  .dropArrow.show {
    a {
      box-shadow: inset 0px -4px 8px var(--shadow-light-50), inset 0px 4px 6px var(--shadow-light)!important;
    }
  }
}


.w-300  {
  #dropSection {
    position: relative;
    &::after {
      content: "";
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIzNCIgY2xhc3NOYW1lPSJtZS0zIiB2aWV3Qm94PSIwIDAgOCAzNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xLjY2MTA1IDkuOTk5NzNDMS44MTA0NCA5Ljk5OTIyIDEuOTU4MDUgMTAuMDMyMiAyLjA5MzAzIDEwLjA5NjJDMi4yMjgwMSAxMC4xNjAyIDIuMzQ2OTMgMTAuMjUzNyAyLjQ0MTA1IDEwLjM2OTdMNy4yNzEwNSAxNi4zNjk3QzcuNDE4MTMgMTYuNTQ4NyA3LjQ5ODU0IDE2Ljc3MzEgNy40OTg1NCAxNy4wMDQ3QzcuNDk4NTQgMTcuMjM2MyA3LjQxODEzIDE3LjQ2MDggNy4yNzEwNSAxNy42Mzk3TDIuMjcxMDUgMjMuNjM5N0MyLjEwMTMxIDIzLjg0MzkgMS44NTc0IDIzLjk3MjQgMS41OTI5NyAyMy45OTY3QzEuMzI4NTUgMjQuMDIxMSAxLjA2NTI2IDIzLjkzOTUgMC44NjEwNDggMjMuNzY5N0MwLjY1NjgzMSAyMy42IDAuNTI4NDA3IDIzLjM1NjEgMC41MDQwMjcgMjMuMDkxNkMwLjQ3OTY0OCAyMi44MjcyIDAuNTYxMzA5IDIyLjU2MzkgMC43MzEwNDggMjIuMzU5N0w1LjIwMTA1IDE2Ljk5OTdMMC44ODEwNDggMTEuNjM5N0MwLjc1ODc2NSAxMS40OTI5IDAuNjgxMDg4IDExLjMxNDIgMC42NTcyMDggMTEuMTI0N0MwLjYzMzMyOSAxMC45MzUxIDAuNjY0MjQ2IDEwLjc0MjcgMC43NDYzMDIgMTAuNTcwMkMwLjgyODM1OCAxMC4zOTc2IDAuOTU4MTE3IDEwLjI1MjIgMS4xMjAyMyAxMC4xNTExQzEuMjgyMzQgMTAuMDUgMS40NzAwMSA5Ljk5NzQ5IDEuNjYxMDUgOS45OTk3M1oiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl83MF80NzEyKSIvPg0KICAgICAgICAgICAgICAgICAgPGRlZnM+DQogICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfNzBfNDcxMiIgeDE9IjMuOTk5MTgiIHkxPSIyNi41MjEyIiB4Mj0iMy45OTkxOCIgeTI9IjcuNzU5NDIiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4NCiAgICAgICAgICAgICAgICAgIDxzdG9wIHN0b3AtY29sb3I9IiNGQUNCMzAiLz4NCiAgICAgICAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMC4zMDIyNjkiIHN0b3AtY29sb3I9IiNGRUY5QzgiLz4NCiAgICAgICAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMC42NDA2MjUiIHN0b3AtY29sb3I9IiNDNDk1MzAiLz4NCiAgICAgICAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZGRjhDNCIvPg0KICAgICAgICAgICAgICAgICAgPC9saW5lYXJHcmFkaWVudD4NCiAgICAgICAgICAgICAgICAgIDwvZGVmcz4NCiAgICAgICAgICAgICAgICA8L3N2Zz4=);
      position: absolute;
      right: 15px;
      top:50;
      height: 34px;
      width: 8px;
      background-repeat: no-repeat;
    }
  }
  .down {
    &::after {
      transform: rotate(90deg);
    }
  }
}
.drop-sec {
  z-index: 10;
  .subBar {
    height: 0;
    overflow: hidden;
    padding: 0!important;
  }
}
.drop-sec.w-300 {
  .subBar {
    height:auto;
    overflow: visible;
    padding: 16px 24px!important;
  }
}

.w-10 {
  width: 10%;
  margin-right: 1.2%;
  @media (max-width:1600px) and (min-width: 1300px){
    button {
      font-size: 12px;
    }
  }
}
.bg-btn {
  &:hover {
    background: var(--goldenlinear);
  }
}
.bg-primary{
  background: var(--goldenlinear)!important;
}
//Slider 

@media (max-width:1921px) and (min-width: 320px){
  .cssmarquee {
      padding: 15px 0;
      overflow: hidden;
      .moveSection {
          animation: cssmarquee 8s linear infinite;
      }
  }
  @keyframes cssmarquee {
      0% {
          transform: translateX(100%);
      }
      100% {
          transform: translateX(-100%);
      }
  }
}
  @media (max-width:1300px) and (min-width: 768px){
  .tab-button{
    button {
      font-size: 12px;
      width: 95px!important;
    }
  }
  .cssmarquee1 {
    padding: 15px 0;
    overflow: hidden;
    .moveSection1 {
        animation: cssmarquee1 38s linear infinite;
    }
  }
  @keyframes cssmarquee1 {
      0% {
          transform: translateX(100%);
      }
      100% {
          transform: translateX(-100%);
      }
  }
}

.react-multi-carousel-list {
  overflow: visible!important;
}
.slidetab {
  padding-top: 60px;
  position: relative;
  margin-top: -69px;
  overflow: hidden;
}

.TabsHeight {
  height: 1020px;
  overflow-y: overlay;
  overflow-x: hidden;
  position: relative;
  z-index: 10;
}
.carousel-inner {
  height: 1020px;
}

.newclass {
  border-radius: 8px;
  overflow: hidden;
  .Toastify__toast-theme--dark{
    background-color:var(--page-background);
    color: var(--text-body);
    box-shadow: 4px 4px 8px var(--shodow-drop-dark), -4px -4px 8px var(--shadow-light-50);
  }
  .Toastify__progress-bar-theme--dark {
    background: #FACB30;
  }
  .Toastify__close-button {
    color: var(--text-body);
  }
}

.submited {
  display: none;
}
.submit {
  display: flex;
}
.close {
  .submited {
    display: flex;
  }
  .submit {
    display: none;
  }
}
.bs-success {
  // background-color: var(--bs-success);
  background: var(--goldenlinear)!important;
  text-transform: capitalize;
}


.bs-gray {
  button {
    background: #4E4E57!important;
    color:#fff;
  }
}
.bs-gray1 {
    background: #4E4E57!important;
    color:#fff;
}
.bs-danger {
  button {
    background: #E03F3F!important;
    color:#fff;
  }
}

// dropdown 


.country {
  .css-13cymwt-control, .css-t3ipsp-control, .css-1kdep9t-control, .css-7fr252-control{
    height: 50px;
    padding: 0;
    background: none;
    box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
    border-radius: 10px;
    border: 0px solid #000;
    .css-1fdsijx-ValueContainer {
      height: 50px;
      padding: 0;
    }
    .css-qbdosj-Input {
      height: 50px;
      padding: 0 15px;
      position: relative;
    }
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  .css-1jqq78o-placeholder, .css-1dimb5e-singleValue {
    text-align: left;
    padding: 0 15px;
  }
  // .css-1jqq78o-placeholder {
  //   position: relative;
  //   &::after {
  //     position: absolute;
  //     content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB4PSIwIiB5PSIwIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGQ9Ik01LDlIMTlsLTYuMjkzLDYuMjkzYTEsMSwwLDAsMS0xLjQxNCwwWiIgZmlsbD0iI2YxYjgyZSIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCI+PC9wYXRoPjwvZz48L3N2Zz4=);
  //     width: 40px;
  //     height:auto ;
  //     display: inline-block;
  //     right:25px;
  //     top:-8px;
  //   }
  // }
  .css-13cymwt-control {
    position: relative;
  }
  .css-1nmdiq5-menu {
    // box-shadow: -4px -4px 8px #fff, inset 4px 4px 8px var(--shadow-light);
    box-shadow: 4px 4px 8px #C9D9E8, -4px -4px 8px var(--shadow-light-50);
    border-radius: 10px;
    background-color: var(--page-background);
    z-index: 20;
    color:var(--text-body);
    position: absolute;
    right: 0;
    width: 340px;
    overflow-x: hidden;
    &::before {
      position: absolute;
      content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAQCAYAAAABOs/SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgBpZS9jtNAEIBnNg5H7o4izUkRPycaCoR04q+IrsF0iAYLOigIBQKlSQpEAQIHUVDyCL4XgA4JGqgQpakoaOw3MNIlShzvDvvrOHC5S2CieHd2x/PNzswap+nzkNU3XoC3CcA2EeonCGtylDovCpqO95FPhjAdD6HIR1ovpF6M90GPk6FcG6JcBz4ZyXcm4K01yDu2jt7ahpyvg5yb0f6Zd3zA6tuvQ0HsFRCg/EkhOSKBEb2mFUQgIlSDmmnV2ehtuaAtibQvvSHt9N7MVAgxaO08CZlS6yefhoAssiQiY+/IiqZAiq3cosKqEQ3GPk0w2sqClGJm1h2J6PTVl6GaMgeobXU70iDSp4KZkD0g2aCxXLeBoXuaYEwMJiYdNpZ+9rZ333ScX1ZhAGPUl8axNSxz7SBlwrCSkoroMlhztKm2Z/9eA9abY8292OxkjGq+9BBjeTZXYqykorrnwnELcwlT8Hg4Kvyz/ttsIdjAgwxzEUhwYo+JaItbde96Ciogck1lEo6CIAVvfOtiEGV/cv4CG/iNBIj5JCgxHWXdmUH3lU03QVlu11xlgImo5dfO33yXHsQ4EKz9N9oJ5+ArB7beNPOviqdPZEo5f2JV2DSf5v6l2x/SRf4XgpU0mn4CeV6Fw+zuomxGDSmvkW2sVOTcb3e+JIf5PhSs4a0g4VQEEpmZC2LKjfq6w/w1AvyVCxG0H39LjvJ7JFhJ89TduCjG1xXc3RXXSKy8PphxAb7fjeNlfC4FVrJ1rhtzwfvuk1nhmw8tw97uw69LQVcCK2ld6EcF5w/I1tN8EqXG8P6Ve5/2VvG1EljJmcvPIsFp4HSJHezceb8S9L/k5+de+OPjoxD+UX4DXJpPQT3IMC4AAAAASUVORK5CYII=);
      width: 46px;
      height:auto ;
      display: inline-block;
      right:25px;
      top:0px;
    }
  }
  .css-166bipr-Input{
    padding: 0 15px;
    color: var(--text-body);
  }
  .css-1dimb5e-singleValue {
    color: var(--text-body);
  }
  .react-select__menu {
    background: #fff!important;
  }
}
.customWidth {
  .css-1nmdiq5-menu {
    width: 290px;
    &::before { 
      content: none;
    }
  }
}
.customWidth1 {
  .css-1nmdiq5-menu {
    width: 190px!important;
    &::before { 
      content: none;
    }
  }
}
.customWidth2 {
  .css-1nmdiq5-menu {
    width: 100px!important;
    &::before { 
      content: none;
    }
  }
}

.currencySec {
  .css-1nmdiq5-menu {
    &::before { 
      right: 0;
      left: 0;
      margin: 0 auto;
    }
  }
}

.addMore {
  color: var(--text-body);
  &:focus {
    box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
  }
}

.check-button {
  position: relative;
  display: flex;
  align-items: center;
  .btn-check{
    left: 0;
  }
  .btn {
    background: var(--page-background);
    box-shadow: inset -3px -3px 10px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
    border: 0px solid #000;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 5px;
    position: relative;
    margin-right: 10px;
    svg {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 8px;
      opacity: 0;
    }
  }
  .btn-check:checked + .btn{
    color:var(--shadow-light-50);
    background-color: inherit;
    background: var(--goldenlinear);
    box-shadow: 2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shadow-light-50);
    svg{
      opacity: 1;
    }
  }
}

.link {
  color:#4770DA;
}
.countryList {
  position: relative;
  &::before {
    position: absolute;
    content: url('../images/triangle1.png');
    width: 10px;
    height: auto;
    display: inline-block;
    right: 35px;
    top: 16px;

  }
  .css-13cymwt-control, .css-t3ipsp-control, .css-1kdep9t-control, .css-7fr252-control{
    height: 50px;
    padding: 0;
    background: none;
    box-shadow: 4px 4px 8px var(--shodow-drop-dark), -4px -4px 8px var(--shadow-light-50);
    border-radius: 5px;
    border: 0px solid #000;
    .css-1fdsijx-ValueContainer {
      height: 50px;
      padding: 0;
    }
    .css-qbdosj-Input {
      height: 50px;
      padding: 0 15px;
    }
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  .css-1jqq78o-placeholder, .css-1dimb5e-singleValue {
    text-align: left;
    padding: 0 5px;
  }
  .css-13cymwt-control {
    position: relative;
  }
  .css-1nmdiq5-menu {
    // box-shadow: -4px -4px 8px #fff, inset 4px 4px 8px var(--shadow-light);
    box-shadow: 4px 4px 8px #C9D9E8, -4px -4px 8px var(--shadow-light-50);
    border-radius: 10px;
    background-color: var(--page-background);
    z-index: 20;
    color:var(--text-body);
    position: absolute;
    right: 0;
    width: 240px;
    overflow-x: hidden;
    // &::before {
    //   position: absolute;
    //   content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB4PSIwIiB5PSIwIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGQ9Ik01LDlIMTlsLTYuMjkzLDYuMjkzYTEsMSwwLDAsMS0xLjQxNCwwWiIgZmlsbD0iI2YxYjgyZSIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCI+PC9wYXRoPjwvZz48L3N2Zz4=);
    //   width: 46px;
    //   height:auto ;
    //   display: inline-block;
    //   right:25px;
    //   top:-20px;
    // }
  }
  .css-166bipr-Input{
    padding: 0 15px;
    color: var(--text-body);
  }
  .css-1dimb5e-singleValue {
    color: var(--text-body);
  }
  .react-select__menu {
    background: #fff!important;
  }
}
.topsub{
  padding-top: 150px;
}
.countryList3 {
  position: relative;
  &::before {
    position: absolute;
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAQCAYAAAABOs/SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgBpZS9jtNAEIBnNg5H7o4izUkRPycaCoR04q+IrsF0iAYLOigIBQKlSQpEAQIHUVDyCL4XgA4JGqgQpakoaOw3MNIlShzvDvvrOHC5S2CieHd2x/PNzswap+nzkNU3XoC3CcA2EeonCGtylDovCpqO95FPhjAdD6HIR1ovpF6M90GPk6FcG6JcBz4ZyXcm4K01yDu2jt7ahpyvg5yb0f6Zd3zA6tuvQ0HsFRCg/EkhOSKBEb2mFUQgIlSDmmnV2ehtuaAtibQvvSHt9N7MVAgxaO08CZlS6yefhoAssiQiY+/IiqZAiq3cosKqEQ3GPk0w2sqClGJm1h2J6PTVl6GaMgeobXU70iDSp4KZkD0g2aCxXLeBoXuaYEwMJiYdNpZ+9rZ333ScX1ZhAGPUl8axNSxz7SBlwrCSkoroMlhztKm2Z/9eA9abY8292OxkjGq+9BBjeTZXYqykorrnwnELcwlT8Hg4Kvyz/ttsIdjAgwxzEUhwYo+JaItbde96Ciogck1lEo6CIAVvfOtiEGV/cv4CG/iNBIj5JCgxHWXdmUH3lU03QVlu11xlgImo5dfO33yXHsQ4EKz9N9oJ5+ArB7beNPOviqdPZEo5f2JV2DSf5v6l2x/SRf4XgpU0mn4CeV6Fw+zuomxGDSmvkW2sVOTcb3e+JIf5PhSs4a0g4VQEEpmZC2LKjfq6w/w1AvyVCxG0H39LjvJ7JFhJ89TduCjG1xXc3RXXSKy8PphxAb7fjeNlfC4FVrJ1rhtzwfvuk1nhmw8tw97uw69LQVcCK2ld6EcF5w/I1tN8EqXG8P6Ve5/2VvG1EljJmcvPIsFp4HSJHezceb8S9L/k5+de+OPjoxD+UX4DXJpPQT3IMC4AAAAASUVORK5CYII=);
    width: 28px;
    height:auto ;
    display: inline-block;
    right:6px;
    top:10px;
  }
  .css-13cymwt-control, .css-t3ipsp-control, .css-1kdep9t-control, .css-7fr252-control{
    height: 34px;
    padding: 0;
    background: none;
    box-shadow: inset -3px -3px 10px var(--shadow-light-50), inset 4px 4px 8px var(--shodow-drop-dark);
    box-shadow: inset -3px -3px 10px #FFFFFF, inset 4px 4px 8px #889FB6;
    border-radius: 5px;
    border: 0px solid #000;
    .css-1fdsijx-ValueContainer {
      height: 34px;
      padding: 0;
    }
    .css-qbdosj-Input {
      height: 34px;
      padding: 0 15px;
    }
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  .css-1jqq78o-placeholder, .css-1dimb5e-singleValue {
    text-align: left;
    padding: 0 15px;
  }
  .css-13cymwt-control {
    position: relative;
  }
  .css-1nmdiq5-menu {
    // box-shadow: -4px -4px 8px #fff, inset 4px 4px 8px var(--shadow-light);
    box-shadow: 4px 4px 8px #C9D9E8, -4px -4px 8px var(--shadow-light-50);
    border-radius: 10px;
    background-color: var(--page-background);
    z-index: 20;
    color:var(--text-body);
    position: absolute;
    right: 0;
    width: 240px;
    overflow-x: hidden;
    // &::before {
    //   position: absolute;
    //   content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB4PSIwIiB5PSIwIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGQ9Ik01LDlIMTlsLTYuMjkzLDYuMjkzYTEsMSwwLDAsMS0xLjQxNCwwWiIgZmlsbD0iI2YxYjgyZSIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCI+PC9wYXRoPjwvZz48L3N2Zz4=);
    //   width: 46px;
    //   height:auto ;
    //   display: inline-block;
    //   right:25px;
    //   top:-20px;
    // }
  }
  .css-166bipr-Input{
    padding: 0 15px;
    color: var(--text-body);
  }
  .css-1dimb5e-singleValue {
    color: var(--text-body);
  }
  .react-select__menu {
    background: #fff!important;
  }
}

.br-30 {
  .css-13cymwt-control, .css-t3ipsp-control, .css-1kdep9t-control, .css-7fr252-control{
    border-radius: 30px!important;
  }
}

.bgArrow {
  position: relative;
	&::before {
		position: absolute;
		content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAQCAYAAAABOs/SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgBpZS9jtNAEIBnNg5H7o4izUkRPycaCoR04q+IrsF0iAYLOigIBQKlSQpEAQIHUVDyCL4XgA4JGqgQpakoaOw3MNIlShzvDvvrOHC5S2CieHd2x/PNzswap+nzkNU3XoC3CcA2EeonCGtylDovCpqO95FPhjAdD6HIR1ovpF6M90GPk6FcG6JcBz4ZyXcm4K01yDu2jt7ahpyvg5yb0f6Zd3zA6tuvQ0HsFRCg/EkhOSKBEb2mFUQgIlSDmmnV2ehtuaAtibQvvSHt9N7MVAgxaO08CZlS6yefhoAssiQiY+/IiqZAiq3cosKqEQ3GPk0w2sqClGJm1h2J6PTVl6GaMgeobXU70iDSp4KZkD0g2aCxXLeBoXuaYEwMJiYdNpZ+9rZ333ScX1ZhAGPUl8axNSxz7SBlwrCSkoroMlhztKm2Z/9eA9abY8292OxkjGq+9BBjeTZXYqykorrnwnELcwlT8Hg4Kvyz/ttsIdjAgwxzEUhwYo+JaItbde96Ciogck1lEo6CIAVvfOtiEGV/cv4CG/iNBIj5JCgxHWXdmUH3lU03QVlu11xlgImo5dfO33yXHsQ4EKz9N9oJ5+ArB7beNPOviqdPZEo5f2JV2DSf5v6l2x/SRf4XgpU0mn4CeV6Fw+zuomxGDSmvkW2sVOTcb3e+JIf5PhSs4a0g4VQEEpmZC2LKjfq6w/w1AvyVCxG0H39LjvJ7JFhJ89TduCjG1xXc3RXXSKy8PphxAb7fjeNlfC4FVrJ1rhtzwfvuk1nhmw8tw97uw69LQVcCK2ld6EcF5w/I1tN8EqXG8P6Ve5/2VvG1EljJmcvPIsFp4HSJHezceb8S9L/k5+de+OPjoxD+UX4DXJpPQT3IMC4AAAAASUVORK5CYII=);
		width: 20px;
		height: 10px;
		display: inline-block;
		right: 25px;
		top: 42px;
	}
}
.bgArrow1 {
  .country{
    position: relative;
    &::before {
      position: absolute;
      content: url('../images/triangle1.png');
      width: 20px;
      height: 10px;
      display: inline-block;
      right: 20px;
      top: 18px;
    }
  }
}

.radioButton {
  .active {
    box-shadow: inset -3.4px -3.4px 3.4px var(--shadow-light-50), inset 3.4px 3.4px 5.66667px var(--shadow-light)!important;
  }
}

.noOne, .yesOne {
  display: none;
}

//radio button

.btnRadio {
  width: 24px;
  height: 24px;
  border: 0px solid #000!important;
  box-shadow: inset -3.4px -3.4px 3.4px var(--shadow-light-50), inset 3.4px 3.4px 5.66667px var(--shadow-light);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  padding: 0px;
  .radioInner {
    background: var(--shodow-drop-40);
    box-shadow: 2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shadow-light-50);
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    span {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 50%;
      background: #E5E9ED;
    }
  }
}
.large.btnRadio {
  width: 28px;
  min-width: 28px;
  height: 28px;
  .radioInner {
    width: 16px;
    height: 16px;
  }
}
.large.btnRadio.me-3 {
  margin-right: 13px!important;
}

.btn-check:checked + .btnRadio {
  box-shadow: inset 0px 0px 0px var(--shadow-light-50), inset 0px 0px 0px var(--shadow-light);
  background: var(--goldenlinear)!important;
  filter: drop-shadow(2.26667px 2.26667px 3.4px var(--shadow-light)) drop-shadow(-2.26667px -2.26667px 3.4px var(--shadow-light-50));
  .radioInner {
    background: var(--goldenlinear);
    box-shadow: inset 2.26667px 2.26667px 3.4px #C18034, inset -2.26667px -2.26667px 3.4px #FFD15B;
    span {
      background: var(--goldenlinear);
    }
  }
}

.btnShadow {
  box-shadow: 4px 4px 8px var(--shodow-drop-dark), -4px -4px 8px var(--shadow-light-50);
  border-radius: 25px;
  height: 48px;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border: 0px solid #000;
}
.btn-check:checked + .btnShadow{
  box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
  .btnRadio {
    box-shadow: inset 0px 0px 0px var(--shadow-light-50), inset 0px 0px 0px var(--shadow-light);
    background: var(--goldenlinear)!important;
    filter: drop-shadow(2.26667px 2.26667px 3.4px var(--shodow-drop-dark)) drop-shadow(-2.26667px -2.26667px 3.4px var(--shadow-light-50));
    .radioInner {
      background: var(--goldenlinear);
      box-shadow: inset 2.26667px 2.26667px 3.4px #C18034, inset -2.26667px -2.26667px 3.4px #FFD15B;
      span {
        background: var(--goldenlinear);
      }
    }
  }
}
.text-blue {
  color: #fff;
  rect {
    color:#4770DA;
  }
}
.text-darks {
  color:#C49530;
  rect {
    color:#000;
    fill: #000;
  }
  path{
    fill: #C49530;
  }
}

// Button 

.btnDefult  {
  background: var(--goldenlinear);
  box-shadow: 4px 4px 8px var(--shadow-light), -4px -4px 8px var(--shadow-light-50);
  border-radius: 5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 35px;
  color: #1C1C1C;
  height: 47px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.btnPrimary {
  background: var(--goldenlinear);
  &:focus {
    background: #EFF4F8;
  }
}
.btnSm  {
  box-shadow: 4px 4px 8px var(--shadow-light), -4px -4px 8px var(--shadow-light-50);
  border-radius: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
  height: 50px;
  color: #1C1C1C;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border: 0px solid #000;
  &:focus {
    box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
  }
}


// upload file 

.fileTab {
  img {
    object-fit: contain;
  }
  p {
    font-size: 14px;
    line-height: normal;
  }
}

.object-cover{
  object-fit: cover;
}
.dropLink .DropdownRange {
  width: 100% !important;
}
.dropLink.small{
  .z-10 {
    width: 55px;
    .neu_range_select__number {
      font-size: 10px;
    }
  }
  .mx-2 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .neu_range_select__bar_container {
    width: 100%;
    height: 23px!important;
    .circle {
      // display: none;
      width: 15px;
      height: 15px;
    }
  }
  .neu_range_select__bg, .neu_range_select__bar_container{
    height: 20px;
  }
}
.dropLink.large{
  .z-10 {
    .neu_range_select__number {
      font-size: 10px;
    }
  }
  .mx-2 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .neu_range_select__bar_container {
    width: 100%;
    height: 23px!important;
    .circle {
      // display: none;
      width: 15px;
      height: 15px;
    }
  }
  .neu_range_select__bg, .neu_range_select__bar_container{
    height: 20px;
    margin-bottom: 5px!important;
  }
}
.range-slider__wrap .range-slider__tooltip {
  opacity: 1!important;
}

.bioSec {
	.bioNav {
    .active {
      box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
    }
    button{
      font-weight: 500;
    }
  }
}
.dropFormat {
  .dropdown-menu {
    li {
      margin-right: 16px;
      // margin-bottom: 20px;
      a {
        width: 44px;
        height: 44px;
        box-shadow: 2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shadow-light-50);
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.large {
          width: 97px!important;
          font-size: 14px;
        }
        &:focus {
          box-shadow: inset -2px -2px 3px var(--shadow-light-50), inset 2px 2px 3px var(--shadow-light);
        }
      }
      &:nth-child(10){
        margin-right: 0px;
      }
      &:nth-child(21){
        margin-right: 0px;
      }
      &:nth-child(32){
        margin-right: 0px;
      }
    }
  }
}

.btnArrow {
  .dropArrow {
    a {
      position: relative;
      &::after {
        position: absolute;
        content: url('../images//triangle1.png');
        width: 20px;
        height: 10px;
        display: inline-block;
        right: 20px;
        top: 18px;
      }
    }
  }
}

.brakectSec {
  div {
    position: relative;
    padding: 0 2px;
    &::after {
      content: '}';
    }
    &::before {
      content: '{';
    }
  }
}
.bg-none {
  background: none;
  background-color: inherit;
}
.w-color-wheel {
  border-radius: 50%;
  border: 0px solid #d9e2eb;
  width: 150px!important;
  height:150px!important;
  position: relative;
  margin: 0 auto!important;
//   background: #F0F4F8;
// box-shadow: 13px 13px 32.500003814697266px 0px rgba(166, 180, 200, 0.50), 20.771738052368164px 4.945652008056641px 25.717390060424805px 0px rgba(0, 0, 0, 0.10) inset;
  // &::before{
  //   content: "";
  //   width: 150px;
  //   height: 150px;
  //   margin: auto;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   border-radius: 50%;
  //   background-color: var(--page-background);
  //   top:0px;
  //   bottom:0px;
  //   pointer-events: none;
  //   z-index: 2;
  //   box-shadow: 6.370000839233398px 6.370000839233398px 15.925000190734863px 0px rgba(166, 180, 200, 0.50), -4.550000190734863px -4.550000190734863px 30.25750160217285px 0px #FFF;
  // }
  &::after{
    content: "Select \A a \A Color";
    white-space: pre;
    width: 110px;
    text-align: center;
    height: 110px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top:0px;
    bottom:0px;
    pointer-events: none;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    background: var(--page-background);
    border-radius: 50%;
  }
}
.wheel-gear{
  position: relative;
  // &::after{
  //   content: "";
  //   background: url(../images/wheel-gear.png);
  //   width: 240px;
  //   height: 240px;
  //   display: inline-block;
  //   top: 20px;
  //   left: 32px;
  //   position: absolute;
  //   background-repeat: no-repeat;
  //   background-size:contain;
  //   border-radius: 50%;
  //   box-shadow: 6.370000839233398px 6.370000839233398px 15.925000190734863px 0px rgba(166, 180, 200, 0.50), -4.550000190734863px -4.550000190734863px 30.25750160217285px 0px #FFF;
  // }
  // &::before{
  //   content:"";
  // }
  width: 100%;
  height: 100%;
}
.w-color-circle{
  box-shadow: 2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shadow-light-50);
  border-radius: 50%;
  width: 55px;
  height: 55px;
  justify-content: center;
  align-items: center;
  div{
    margin: 0!important;
    transform: scale(1)!important;
    padding: 0!important;
    display: inline-block!important;
    width: 36px!important;
    height: 36px!important;
    div{
      background-image: url(../images/icongear1.png)!important;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: inherit!important;
    }
  }
}
.w-color-alpha{
  // border: 1px solid var(--shodow-drop-40);
  border-radius: 20px!important;
  width: 97%;
}
.w-color-alpha div {
  border-radius: 20px!important;
}
.hexBox {
  box-shadow: inset -3px -3px 5px var(--shadow-light-50), inset 3px 3px 3px var(--shadow-light);
  width: 75px;
  height: 34px;
  span {
    width: 17px;
    overflow: hidden;
  }
}
.boxClrCircle {
  div{
    margin: 0!important;
    padding: 0px!important;
  }
  .w-color-swatch, .w-color-alpha, .w-color-interactive, .w-color-editable-input {
    display: none!important;
  }
  .w-color-editable-input-rgba {
    width: 165px;
    height: 34px;
    background: var(--page-background);
    box-shadow: inset -3px -3px 5px var(--shadow-light-50), inset 3px 3px 3px var(--shadow-light);
    border-radius: 5px;
    .w-color-editable-input {
      display: flex!important;
      flex-direction: row-reverse!important;
      width: 54px;
      justify-content: center;
      align-items: center;
      border-left: 1px solid#B6C8DA;
      &:first-child{
        border: 0px solid #000;
      }
      &:last-child {
        display: none!important;
      }
      input {
        background: none;
        box-shadow: none!important;
        width: 28px!important;
      }
    }
  }
}

.sociallabel {
  label {
    font-size: 14px;
  }
}

.br-primary{
  border: 3px solid #FEC83A;
}



.select-box {
  position: relative;
  display: block;
  font-size: 16px;
  color: #60666d;
  width: 75px;
  height: 34px;
}

.select-box__current {
  position: relative;
  cursor: pointer;
  outline: none;
}
.select-box__current:focus + .select-box__list {
  opacity: 1;
  -webkit-animation-name: none;
          animation-name: none;
}
.select-box__current:focus + .select-box__list .select-box__option {
  cursor: pointer;
}
.select-box__current:focus .select-box__icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
.select-box__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 12px;
  opacity: 0.3;
  transition: 0.2s ease;
}
.select-box__value {
  display: flex;
}
.select-box__input {
  display: none;
}
.select-box__input:checked + .select-box__input-text {
  display: flex;
  align-items: center;
}
.select-box__input-text {
  display: none;
  width: 100%;
  margin: 0;
  background: #EFF4F8;
  box-shadow: 2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shadow-light-50);
  border-radius: 5px;
  font-size: 16px;
  height: 34px;
  padding-left: 10px;
}
.select-box__list {
  position: absolute;
  width: 100%;
  padding: 0;
  list-style: none;
  opacity: 0;
  z-index: 4;
  -webkit-animation-name: HideList;
          animation-name: HideList;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: step-start;
          animation-timing-function: step-start;
  li {
    margin-bottom: 10px!important;
    text-align: center;
    label {
      width: 100%;
      line-height: 30px;
    }
  }
}
.select-box__option {
  display: block;
}

@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}

@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  to {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
  }
}
.h-57{
  height: 57px;
}

.text-capitalize {
  text-transform: capitalize!important;
}

label {
  font-size: 14px;
}

@media (max-width:1298px) and (min-width: 320px){ 
  .react-multi-carousel-track  {
    flex-wrap: nowrap!important;
  }
  .col-lg-1 {
	  flex: 0 0 auto;
	  width: 0;
  }
  .overlayer, .overlayer1 {
    display: none!important;
  }
}
.h-90{
  height: 90px;
}
.h-860{
  height: 860px;
}
.h-845{
  height: 845px;
}
.h-85{
  height: 140px;
}
.mt-7 {
  margin-top:-30px;
}
.mt-8 {
  margin-top:-50px;
}
.mt-9 {
  margin-top:-42px;
}
.mt-10 {
  margin-top:-100px;
}
.h-1250{
  height: 1420px;
  @media (max-width:1440px) and (min-width: 1299px){
    height: 1130px;
  }
}

.h-525{
  height: 525px;
}

.collapse.show{
  transition:all .4s ease-in-out 0s;
}


button:focus-visible {
  outline: -webkit-focus-ring-color auto 0px!important;
}

.leftbtn {
  width: 120px;
  height: 85px;
  top: 42%;
  img {
    width: 100%;
    height: 100%;
  }
}
.left-50{
  left: -60px;
}
.right-50{
  right: -60px;
}
.shadow-0 {
  box-shadow: 0px 0px 0px 0px #000!important;
}

.topTag {
  width: 80px;
  height: 80px;
  padding: 4px;
  top:-50px;
  right: -20px;
  .p-9 {
    padding: 4px;
  }
}
.topTag1 {
  width: 80px;
  height: 80px;
  padding: 4px;
  top:30px!important;
  right: 0px;
  left: -33px!important;
  z-index: 5;
  .p-9 {
    padding: 4px;
  }
}
.overflow-s{
  overflow-y: scroll;
  height: 230px;
}
.roundedimg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-contain{
  object-fit: contain!important;
}
.bottom-30{
  bottom: -30px;
}
.bottom-20{
  bottom: -20px;
}
.bottom-50s{
  bottom: -50px;
}
.bottom-4{
  bottom: -4px;
}
.bottom-5{
  bottom: 40px;
}
.w-96{
  width: 96%!important;
}

.iconspace {
  .mb-4 {
    margin-bottom: 35px!important;
  }
}
.customCol{
  .col-md-5 {
    width: 37%;
  }
}

.mt-87 {
  margin-top: -87px;
}

.sliderupload{
  background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 100%);
  box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
  border-radius: 5px;
  height: auto;
  padding: 6px 7px;
}
.countryList1 {
  .css-166bipr-Input{
    display: none;
  }
}
.p-20{
  padding: 20px;
}

.inputaudio {
  width: 900px!important;
  height: 48px!important;
  border-radius: 10px;
}
.mt-52{
  margin-top: -52px;
}
.w-800{
  width: 800px;
}
.w-815{
  width: 815px;
}
.w-396{
  width: 396px;
}
.w-80s{
  width: 80%;
}
.w-80c{
  width: 80px!important;
  height: 80px!important;
}
.p-5s{
  padding: 5px;
}
.ps-3s{
  padding-left: 3px;
}
// Dark Mode 

.dark {
  .shadow-4 {
    box-shadow: 4px 4px 8px rgb(0 0 0 / 80%), -4px -4px 8px rgb(46 44 44 / 99%);
  }
  .shadow-4in {
    box-shadow: inset -7px -6px 11px rgb(46 44 44 / 99%), inset 6px 9px 16px rgb(0 0 0 / 80%);
  }
  .shadow-5in{
    box-shadow: inset -7px -6px 11px rgb(46 44 44 / 99%), inset 6px 9px 16px rgb(0 0 0 / 80%);
  }
  .shadow-7in{
    box-shadow: inset 0px 0px 0px var(--shadow-light-50), inset 0px 0px 0px var(--shadow-light);
  }
  .leftSection{
    span {
      box-shadow: 5px 13px 15px var(--shadow-light), -10px -5px 15px var(--shadow-light-50);
    }
  }
  .dividerSidebar {
    box-shadow: inset 1px -1px 2px rgba(68, 68, 68, 0.31), inset 0px -1px 1px rgba(154, 153, 153, 0.71);
    background-color: #000000;
  }
  .btn-bg {
    box-shadow: 4px 4px 8px var(--shadow-light), -4px -4px 8px var(--shadow-light-50), inset -4px -4px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
  }
  .sidebar-area {
    border-color: #000000!important;
  }
  .w-300 {
    // .btnSection {
    //   box-shadow: 5px 13px 15px rgba(0, 0, 0, 0.68), -10px -5px 15px #2E2C2C;
      a {
        box-shadow: 5px 13px 15px rgba(0, 0, 0, 0.68), -10px -5px 15px #2E2C2C;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    // }
  }
  .social-sec {
    img{
      filter: brightness(0) invert(1);
    }
  }
    a {
      &:focus {
        box-shadow: inset -5px -5px 7px rgba(54, 53, 53, 0.85), inset 6px 6px 19px rgba(0, 0, 0, 0.94);
      }
    }
  .subBar {
    li{
      a {
        box-shadow: 5px 13px 15px rgba(0, 0, 0, 0.68), -10px -5px 15px #2E2C2C;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        &:focus {
          box-shadow: inset -3px -3px 12px #2E2C2C, inset 5px 5px 7px rgba(0, 0, 0, 0.68);
        }
      }
    }
  }
  .darkImg {
    display: block;
  }
  .lightImg {
    display: none;
  }
  .ImgArea {
    h6{
      box-shadow: inset -0.8px -2px 1px #383838, inset 0.5px 1.65px 2px #000000;
    }
  }
  .dropdown-menu {
    a {
      &:focus {
        box-shadow: inset 4px 4px 8px rgb(0 0 0 / 80%), -4px -4px 8px rgb(46 44 44 / 99%);
      }
    }
  }
  .tab-button {
    &::before {
      background: #444444;
    }
    button {
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.68), -4px -4px 8px #2E2C2C;
      &:focus {
        box-shadow: inset 0px -4px 8px #2E2C2C, inset 0px 4px 6px rgba(0, 0, 0, 0.68);  
      }
    }
    button.active {
      box-shadow: inset 0px -4px 8px #2E2C2C, inset 0px 4px 6px rgba(0, 0, 0, 0.68);
    }
  }
  .newclass {
    .Toastify__toast-theme--dark{
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.68), -4px -4px 8px #2E2C2C;
    }
  }
  .dropdown {
    .dropArrow.show {
      a {
        box-shadow: inset -5px -5px 7px rgb(54 53 53 / 85%), inset 6px 6px 19px rgb(0 0 0 / 94%)!important;
      }
    }
  }
}


// .border-2{
//   border-width: 3px !important;
//   border-style: solid !important;
//   border-image: var(--goldenlinear) !important;
// }
.css-1xc3v61-indicatorContainer, .css-1u9des2-indicatorSeparator{
  display: none!important;
}
.upload {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 22px!important;
    height: 22px!important;
  }
}
.dark{
  #root{
    *::-webkit-scrollbar {
      width: 12px;
    }
  
    *::-webkit-scrollbar-track {
      box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000!important;
      border-radius: 28px;
      padding: 2px;
    }
  
    *::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
      border-radius: 10px;
  
      border: 3px solid #3c3d40!important;
    }
  }
}
// .Tab_settings{
//   width: 68%;
// }
.togglebtn.top-0{
  top: -170px!important;
}
.togglebtn3.top-0{
  top: 100px!important;
  transform: rotate(90deg);
}
.flex.header-text{
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.countryList svg{
  background: -webkit-linear-gradient(-86deg, #EEF85B 5%, #7AEC8D 53%, #09E5C3 91%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
}
.w-140{
  width: 140px;
}
.p-cutom {
  padding: 10px 10px;
}
// .custom-clr{
//   .w-color-wheel{

//   }
// }

.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
  color: #202020!important;
}

.clock-timer4{
  width: 18em!important;
  height: 11em!important;
  position: relative;
  &::after{
    background-image: url('../images/img72.png');
    background-repeat: no-repeat;
    content:"";
    width: 38px;
    height: 33px;
    position: absolute;
    bottom: -18px;
    left: 0px;
    right: 0;
    margin: 0 auto;
    background-size: contain;
  }
}
.arrow5{
  .card-tab {
    margin-bottom: 0px!important;
    background: none!important;
    margin-left: 50px;
  }
}

.css-8mmkcg {
  display: none!important;
}

.probtm{
  position: relative;
  width: 100%;
  top: -20px;
  img{
  width: 180px;
  display: inline-block;
  position: relative;
  left: 16px;
  right: 0;
  margin: 0 auto;
  top:-2px;
  z-index: -1;
  height: 18px;
  }
  h6{
    color: #1F1D1D;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 13.3px */
    letter-spacing: 0.35px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top:5px
  }
}

.carousel-control-prev {
  left: -42px;
  height: 20px;
  top: 30px;
  span {
    opacity: 0;
  }
}
.carousel-control-next {
  right: -42px;
  height: 20px;
  top: 30px;
  span {
    opacity: 0;
  }
}
.text-dark {
  color:#000!important
}
.custom-sr{
  .range-slider__wrap{
    width: 100%;
  }
}

.close-btn {
  width: 48px !important;
  border: none;
  background-color: transparent !important;
  background-image: url(../images/close-batch.png) !important;
  background-repeat: no-repeat;

  height: 70px !important;
  font-size: 23px;
  position: absolute;
  right: 17px !important;
  top: -18px !important;
  font-size: 34px;
  color: grey !important;
  position: absolute;
  svg {
      margin-top: -10px;
      margin-left: 10px;
      font-size: 28px;
      color:#000;
  }
}
.close-btn1 {
  width: 48px !important;
  border: none;
  background-color: transparent !important;
  background-image: url(../images/close-batch.png) !important;
  background-repeat: no-repeat;

  height: 70px !important;
  font-size: 23px;
  position: absolute;
  right: 17px !important;
  top: -28px !important;
  font-size: 34px;
  color: grey !important;
  position: absolute;
  svg {
      margin-top: -10px;
      margin-left: 10px;
      font-size: 28px;
      color: #000;
  }
}
.ncheck {
  .btn-check{
    top:8px;
    left:8px;
    pointer-events:auto!important;
    clip: unset!important;
    z-index: 1;
    transform: scale(1.9);
    opacity: 0;
    cursor: pointer;
  }
}
.dropdown {
  a{
    &:focus{
      box-shadow: inset -3.4px -3.4px 3.4px var(--shadow-light-50), inset 3.4px 3.4px 5.66667px var(--shadow-light) !important;
    }
  }
}
.w-650{
  width: 700px;
  left:-55px!important;
  right:inherit!important;
  top: 66px!important;
}
.dropdown-menu.show{
  display: inline-block!important;
}
.gradient-box {
  position: relative;
  box-sizing: border-box;
  $border: 3px;
  background: var(--page-background) !important;
  background-clip: padding-box!important; /* !importanté */
  border: solid $border transparent !important; /* !importanté */
  border-radius: 1em;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: var(--goldenlinear);
    // background: var(--page-background);
  }
}
.gradient-box-dark {
  position: relative;
  box-sizing: border-box;
  $border: 3px;
  background: #000 !important;
  background-clip: padding-box!important; /* !importanté */
  border: solid $border transparent !important; /* !importanté */
  border-radius: 1em;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: var(--goldenlinear);
    // background: var(--page-background);
  }
}
.br-8{
  border-radius: 14px;
  overflow: hidden;
}
.bioSec .tabbtn.active {
  position: relative;
  box-sizing: border-box;
  $border: 3px;
  background: var(--page-background) !important;
  background-clip: padding-box!important; /* !importanté */
  // border: solid $border transparent !important; /* !importanté */
  border-radius: 5px;
  z-index: 4;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: 5px; /* !importanté */
    // background: var(--goldenlinear);
  }
}

.brd-bg {
  background: var(--goldenlinear);
  border-radius: 10px;
  padding: 2px;
  width: 100%;
  height: 50px;
  input{
    background: var(--page-background);
    width: 100%!important;
    height: 100%!important;
  }
}
.br-30s{
  border-radius: 30px!important;
  button{
    border-radius: 30px!important;
  }
}

.banner-one{
  height: 355px!important;
  overflow: hidden;
  background: #fff;
  ul {
    display: flex;
    justify-content: center;
    margin-top: -41px;
    li{
      width:158px;
      padding: 0!important;
      display: inline-block;
      border-bottom: 0px solid #000!important;
      img{
        height: 120px!important;
        width: 140px!important;
        clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
        margin: 0px 0;
      }
      &:nth-child(odd){
        padding-top: 61px!important;
        margin: 0 -48px;
      }
    }
    .li-padding{
      padding-top: 125px!important;
    }
  }
  .bnrtext{
    margin-top: -50px;
    text-align: center;
    height: auto!important;
    h4{
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    h5{
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .heading-4{
      position: relative;
      padding-bottom: 15px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        width: 200px;
        height: 1px;
        background: #999;
        margin: 0 auto;
      }
    }
    .heading-5{
      position: relative;
      padding-bottom: 15px;
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      letter-spacing: 6px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 200px;
        height: 1px;
        background: #999;
      }
    }
    h6{
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 3.6px;
      text-transform: uppercase;
    }
  }
  .banner-2{
    margin-top: -100px;
    text-align: left;
    padding: 0 50px;
    width: 50%;
  }
}

.banner-two{
  height: 355px!important;
  overflow: hidden;
  background: #fff;
  ul {
    display: flex;
    justify-content: center;
    margin-top: -67px;
    li{
      padding: 0!important;
      display: inline-block;
      border-bottom: 0px solid #000!important;
      height: auto!important;
      img{
        height: 120px!important;
        width: 160px!important;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        margin: 0px 0;
      }
      &:nth-child(odd){
        padding-top:0px!important;
        margin: 0 -68px;
        img{
          transform: rotate(180deg);
        }
      }
    }
    .li-padding{
      padding-top: 125px!important;
    }
    &:nth-child(2){
      li{
        img{
          transform: rotate(180deg);
        }
        &:nth-child(odd){
          img{
            transform: rotate(0deg);
          }
        }
      }
    }
  }
  .bnrtext{
    margin-top:0px;
    text-align: center;
    height: auto!important;
    h6{
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      height: auto!important;
    }
    .heading-4{
      position: relative;
      padding-bottom: 15px;
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        width: 200px;
        height: 1px;
        background: #999;
        margin: 0 auto;
      }
    }
  }
}

.button_wrapper_new{
  margin-top: 12px;
  span{
    width: 100%;
    text-transform: capitalize;
  }
  .inner_btn {
    width: 26.08px;
    height: 25.43px;
    background: #F1F4F9;
    box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.2), 5px 5px 10px rgba(136, 159, 182, 0.2);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn_1 {
        width: 13.39px;
        height: 13.06px;
        background: #F1F4F9;
        box-shadow: inset -5px -5px 10px #FFFFFF, inset 5px 5px 10px #889FB6;
        border-radius: 100px;
    }
}
  .button_7 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 15px 13px 20px;
      width: 155px;
      height: 50px;
      background: #EFF4F8;
      border: 2px solid #57a527;
      box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
      border-radius: 30px;
      position: relative;

      .btn_border {
          position: absolute;
          top: -8px;
          margin-left: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
      }

      .btn_border_reverse {
          rotate: 180deg;
          position: absolute;
          bottom: -8px;
          margin-left: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
      }
  }
}
.input-group{
  .button_wrapper_new{
    margin-top: 0px!important;
  }
}
.inputspan{
  background: var(--BEST-WHITE, #EFF4F8);
  box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF inset, 7.31801px 7.31801px 14.63601px 0px #B7C7D7 inset;
  height: 50px;
  display: flex;
}
input.announcment {
  // border: 3px dashed #ddd!important;
  // background: url("../images/input-bg2.png") no-repeat;
  // background-size: 100% 50px;
  // background-position:0px 0px;
  // box-shadow: 0px 0px 0px 0px #000!important;
  border-radius: 3.659px;
  border: 3px dashed var(--main-grey, #EFF4F8);
  position: relative;
  // background: var(--BEST-WHITE, #EFF4F8);
  width: 100%;
  height:100% ;
  z-index: 1;
}
.inputspan1{
  background: var(--BEST-WHITE, #EFF4F8);
  box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF inset, 7.31801px 7.31801px 14.63601px 0px #B7C7D7 inset;
  height: 140px;
  display: flex;
  z-index: 2;
}
textarea.announcment {
  // border: 3px dashed #ddd!important;
  // background: url("../images/input-bg2.png") no-repeat;
  // background-size: 100% 50px;
  // background-position:0px 0px;
  // box-shadow: 0px 0px 0px 0px #000!important;
  border-radius: 3.659px;
  border: 3px dashed var(--main-grey, #EFF4F8);
  // background: var(--BEST-WHITE, #EFF4F8);
  position: relative;
  width: 100%;
  height:100% ;
  z-index: 1;
  padding: 10px 150px 10px 20px;
}
// .nav-item1{
//   position: relative;
//   &::after{
//     content: "";
//     background: url("../images/li-after.png") no-repeat;
//     position: absolute;
//     display: inline-block;
//     right: 30px;
//     top: -12px;
//     width: 45px;
//     height: 70px;
//     background-size: cover;
//   }
// }
// .nav-item2{
//   position: relative;
//   &::after{
//     content: "";
//     background: url("../images/li-after.png") no-repeat;
//     position: absolute;
//     display: inline-block;
//     left: 20px;
//     top: -12px;
//     width: 45px;
//     height: 70px;
//     background-size: cover;
//   }
// }
// .overflow3 {
//   overflow: hidden;
//   padding: 34px 18px 11.087px 19px;
// }
.customDrop{
  width: 300px;
  max-height: 415px;
  padding: 5px 0px 5px;
  flex-direction: column;
  border-radius: 13.061px;
  background: #EFF4F8;
  box-shadow: -2.9579286575317383px -2.9579286575317383px 5.915857315063477px 0px #FFF, 2.9579286575317383px 2.9579286575317383px 5.915857315063477px 0px #B7C7D7;
  border: 0px solid #000;
  z-index: 1;
  .dropheight{
    max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  }
  // overflow: hidden;
  // position: relative;
  // &::before {
  //   content: "";
  //   background: url("../images/ul-before.png") no-repeat;
  //   position: absolute;
  //   left: 30px;
  //   top: 50px;
  //   width: 15px;
  //   height: 62%;
  //   display: inline-block;
  // }
  li{
    margin-bottom: -8px;
    width: 100%;
    height:auto;
    position: relative;
    .dropdiv{
      cursor: pointer;
    }
    .spansec{
      width: 263px;
      height: fit-content;
      display: inline-block;
      background: url(../images/li-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      position: relative;
      z-index: 1;
      right: 12px;
      padding: 18px 15px;
    }
    .dropdiv {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Lao MN;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 2px 10px 5px;
      text-transform: capitalize;
      // background: linear-gradient(180deg, #6B6666 0%, #585454 100%);
      // background-clip: text;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      text-align: center;
      // &:focus{
      //   box-shadow: 0px 0px 0px 0px #000!important;
      // }
    }
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      right: 6px;
      top: 6px;
      bottom: 6px;
      width: 37px;
      height: -webkit-fill-available;
      display: inline-block;
      background-size: 100% 100%;
    }
    &::before{
      content: "";
      background: url("../images/li-before1.png") no-repeat;
      position: absolute;
      left: 0;
      top: 20px;
      width: 163px;
      height: 82px;
      display: inline-block;
    }
    &:last-child{
      &::before{
        content: "";
        background: url("../images/li-before.png") no-repeat;
      }
    }
  }
}
.zoom-banner {
  transform: scale(1.3);
}
.zoom-banner1 {
  transform: scale(1.2);
  margin-top: 19px!important;
}
.zoom-banner3 {
  transform: scale(1.9);
}
.zoom-banner4 {
  transform: scale(1.6);
  height: auto!important;
}
.zoomul{
  margin-top: 40px!important;
  margin-left: 0px!important;
  height: auto!important;
}
.banner4{
  // background: url("../images/banner/banner4.png") no-repeat;
  background-size:cover;
  padding: 15px 0;
  width: 100%;
  height: 355px!important;
  display: inline-block;
  .borderdiv{
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    padding:5px 15px;
  }
  .banner4-iner {
    background: url("../images/banner/banner4sss.png") no-repeat;
    background-size:cover;
    padding: 0px;
    width: 100%;
    height: 100%;
    display: inline-block;
    h5{
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 9.6px;
      padding: 10px 0 0;
      border-top: 1px solid #000;
      height: auto!important;
    }
    h6{
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 4.8px;
      text-transform: uppercase;
      padding: 10px 0;
      border-bottom: 1px solid #000;
      height: auto!important;
    }
  }
}
.banner5{
  width: 100%;
  height: 355px!important;
  display: inline-block;
  background: #fff;
  padding: 10px 0;
  position: relative;
  ul{
    border-top: 19px solid #BD9488;
    border-bottom: 19px solid #BD9488;
    padding: 15px 0;
    li{
      border: 0px solid #000!important;
      padding: 0px!important;
      width: 20%;
    }
  }
  .banner5inr{
    width: 340px;
    height: 355px;
    border-radius: 522px;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    img{
      width: 102px!important;
      height: 69px!important;
      margin-bottom: 50px;
    }
    h5{
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 6px;
      border-bottom: 1px solid #000;
      height: auto!important;
    }
    h6{
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      height: auto!important;
    }
  }
}
.banner5s{
  width: 100%;
  height: 600px!important;
  display: inline-block;
  background: #fff;
  padding: 10px 0;
  position: relative;
  ul{
    border-top: 19px solid #BD9488;
    border-bottom: 19px solid #BD9488;
    padding: 15px 0;
    li{
      border: 0px solid #000!important;
      padding: 0px!important;
      width: 50%;
      height: 50%!important;
    }
  }
  .banner5sinr{
    width: 340px;
    height: 355px;
    border-radius: 50%;
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin:0 auto;
    img{
      width: 102px!important;
      height: 69px!important;
      margin-bottom: 50px;
    }
    h5{
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 6px;
      border-bottom: 1px solid #000;
      height: auto!important;
    }
    h6{
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      height: auto!important;
    }
  }
}
.banner6{
  width: 100%;
  height: 355px!important;
  display: inline-block;
  background: #fff;
  position: relative;
  ul{
    display: inline-block;
    height: auto!important;
    position: relative;
    left: 0px;
    margin: 30px 0; 
    li{
      border: 0px solid #000!important;
      float: left;
      margin:0 -15px;
      position: relative;
      padding: 0!important;
      img {
        // box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.10), -5px -5px 10px 0px rgba(0, 0, 0, 0.10);
        object-fit: cover!important;
        width: auto!important;
        border: 8px solid #fff;
      }
      &:nth-child(1){
        width: 63px!important;
        height: 63px!important;
        display: flex;
        img{
          transform: rotate(352deg);
          width: 63px!important;
        }
      }
      &:nth-child(2){
        width: 120px!important;
        height: 120px!important;
        display: flex;
        img{
          transform: rotate(354deg);
          width: 120px!important;
        }
      }
      &:nth-child(3){
        width: 172px!important;
        height: 172px!important;
        display: flex;
        img{
          transform: rotate(356deg);
          width: 172px!important;
        }
      }
      &:nth-child(4){
        width: 219px!important;
        height: 219px!important;
        display: flex;
        z-index: 3;
        img{
          width: 219px!important;
        }
      }
      &:nth-child(5){
        width: 168px!important;
        height: 168px!important;
        display: flex;
        z-index: 2;
        img{
          transform: rotate(4deg);
          width: 168px!important;
        }
      }
      &:nth-child(6){
        width: 120px!important;
        height: 120px!important;
        display: flex;
        z-index: 1;
        img{
          transform: rotate(6deg);
          width: 120px!important;
        }
      }
      &:nth-child(7){
        width: 70px!important;
        height: 70px!important;
        display: flex;
        img{
          transform: rotate(8deg);
          width: 70px!important;
        }
      }
    }
  }
  .banner6inr{
    height: auto!important;
    margin-top: -10px;
    h5{
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 6px;
      border-bottom: 1px solid #000;
      height: auto!important;
      padding-bottom: 8px;
    }
    h6{
      color: #000;
      font-family:'Times New Roman', Times, serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      height: auto!important;
    }
  }
}

.banner-six {
  background: #fff;
  height: 555px !important;
  overflow: hidden;
}

.banner-six ul {
  display: flex;
  justify-content: center;
  margin-top: -66px;
  margin-left:30px;
}

.banner-six ul li {
  border-bottom: 0 solid #000 !important;
  display: inline-block;
  padding: 0 !important;
  width: 158px;
}

.banner-six ul li:nth-child(even) {
  margin: 0 -48px;
  padding-top: 61px !important;
}

.banner-six ul li img {
  -webkit-clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  height: 120px !important;
  margin: 5px 0;
  width: 140px !important;
}

.banner-six .bnrtext {
  height: auto !important;
  margin-top: 70px;
  text-align: center;
}
.banner-six .bnrtext1 {
  margin-top: 90px!important;
}

.banner-six .bnrtext .heading-4 {
  padding-bottom: 15px;
  position: relative;
}

.banner-six .bnrtext .h4,
.banner-six .bnrtext h4 {
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.banner-six .bnrtext .h4,
.banner-six .bnrtext h4 {
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 10px 0;
}

.banner-six .bnrtext .heading-4:after {
  background: #999;
  bottom: 0;
  content: "";
  height: 1px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 200px;
}
.banner7 {
  // background: url("../images/banner/banner4.png") no-repeat;
  background-size: cover;
  display: inline-block;
  height: 600px!important;
  padding: 15px 0;
  width: 100%;
  img{
    height: 166px!important;
  }
  .borderdiv{
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    padding:5px 15px;
  }
}
.banner7 .banner7-iner {
  background: url("../images/banner/banner4sss.png") no-repeat;
  background-size: cover;
  display: inline-block;
  height: 100%;
  padding: 0;
  width: 100%;
  h5, h6{
    height: auto!important;
  }
}
.h-auto{
  height: auto!important;
}
.h-255{
  height: 300px!important;
}
.h-233{
  height: 302px!important;
}
.h-170{
  height: 170px!important;
}
.h-218{
  height: 220px!important;
}
.zoom-banner2 {
  transform: scale(1);
  margin-top: -10px !important;
  margin-right: -68px;
}
.zoomebanner4{
  transform: scale(0.8);
}
.zoomebanner5
{
  transform: scale(0.6);
}
.banner9{
  transform: scale(0.9);
}
.banner-small{
  .bnrtext {
    margin-top: -16px;
    h4{
      font-size: 14px;
    }
    .heading-4{
      padding-bottom: 0px;
    }
  }
}


.banner9 {
  width: 100%;
  height: 555px !important;
  display: inline-block;
  background: #fff;
  position: relative;
}

.banner9 ul {
  display: inline-block;
  height: auto !important;
  position: relative;
}

.banner9 ul li {
  border: 0px solid #000 !important;
  float: left;
  margin: 0 -45px;
  position: relative;
  padding: 0px!important;
}

.banner9 ul li img {
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.10), -5px -5px 10px 0px rgba(0, 0, 0, 0.10);
  object-fit: cover !important;
  width: auto !important;
  border: 4px solid #fff;
}
.banner61 li:nth-child(1) {
  width: 250px !important;
  height: 250px !important;
  display: flex;
}

.banner61 li:nth-child(1) img {
  transform: rotate(356deg);
}

.banner61 li:nth-child(2) {
  width: 300px !important;
  height: 300px !important;
  display: flex;
  z-index: 3;
}
.banner61 li:nth-child(3) {
  width: 250px !important;
  height: 250px !important;
  display: flex;
  z-index: 2;
}

.banner61 li:nth-child(3) img {
  transform: rotate(4deg);
}

.banner62 li:nth-child(1) {
  width: 150px !important;
  height: 150px !important;
  display: flex;
}

.banner62 li:nth-child(1) img {
  transform: rotate(352deg);
}

.banner62 li:nth-child(2) {
  width: 200px !important;
  height: 200px !important;
  display: flex;
}

.banner62 li:nth-child(2) img {
  transform: rotate(354deg);
}
.banner62 li:nth-child(3) {
  width: 190px !important;
  height: 190px !important;
  display: flex;
}

.banner62 li:nth-child(4) {
  width: 200px !important;
  height: 200px !important;
  display: flex;
  z-index: 1;
}

.banner62 li:nth-child(4) img {
  transform: rotate(6deg);
}

.banner62 li:nth-child(5) {
  width: 150px !important;
  height: 150px !important;
  display: flex;
}

.banner62 li:nth-child(5) img {
  transform: rotate(8deg);
}

.banner6inr {
  height: auto !important;
  margin-top: -10px;
}

.banner6inr h5 {
  color: #000;
  font-family: 'Times New Roman', Times, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6px;
  border-bottom: 1px solid #000;
  height: auto !important;
  padding-bottom: 8px;
}

.banner6inr h6 {
  color: #000;
  font-family: 'Times New Roman', Times, serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
  height: auto !important;
}
.banner-10{
  height:600px!important;
  ul{
    height: auto!important;
  }
  .h-200 {
    height: 200px!important;
  }
}
.zoombanner11 {
  transform: scale(2.3);
  padding-top: 150px;
}
.ng-btn1{
  border-radius: 30px;
  background:var(--goldenlinear);
  box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
  display: inline-flex;
  height: 53px;
  padding: 10px;
}
.ng-btn{
  border-radius: 20px;
background:var(--goldenlinear);
height: 33px;
box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
padding: 0px 20px;
line-height: 33px;
}
.tabbtn{
  border-radius: 5px;
  background: #F0F4F8;
  box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
  display: flex;
  padding: 5px;
  margin: 0 3px;
  height: 100px;
  span{
    display: flex;
    width: 80px;
    height: 100px;
    padding: 15px 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background:#F0F4F8;
    box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
    color:#202020;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.tabbtn.active span{
  position: relative;
  box-sizing: border-box;
  $border: 3px;
  background: var(--page-background) !important;
  background-clip: padding-box!important; /* !importanté */
  border: solid $border transparent !important; /* !importanté */
  border-radius: 5px;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: var(--goldenlinear);
  }
}
.bioNav{
  position: relative;
  z-index: 5;
  margin-bottom: -25px;
}
.bioSec .tab-pane{
  background: var(--page-background);
}
.z-index-4s{
  z-index: 4;
}
.bnrbtm {
  justify-content: space-between;
}
.img-cover{
  object-fit: cover;
}
.jobdiv{
  width: 100%;
  height: 221px;
  padding: 40px;
  display: inline-block;
  border-radius: 6.988px;
  background: url('../images/img103.png')no-repeat;
  background-size: 100% 100%;
  position: relative;
  // box-shadow: 0px 2.795124053955078px 4.192686080932617px 0px #A0B9D0 inset, 0px -2.795124053955078px 5.590248107910156px 0px #FFF inset;
  &::before{
    content: "";
    position: absolute;
    right: -16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    background: url('../images/img102.png');
    width: 131px;
    height: 56px;
    display: inline-block;
  }
  &::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: url('../images/img101.png');
    width: 36px;
    height: 165px;
    display: inline-block;
  }
  .innerdiv-job {
    background: url('../images/img104.png') no-repeat;
    border-radius: 4.87px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-size: 100% 100%;
    // box-shadow: -2.782949447631836px -2.782949447631836px 5.565898895263672px 0px #FFF, 2.782949447631836px 2.782949447631836px 5.565898895263672px 0px #B7C7D7;
    h5{
      margin: 0;
      text-shadow: 9px -11px 8px #FFF, 6px 7px 8px #B7C7D7;
      font-size: 42px;
      font-weight: 400;
      line-height: 90.5%; /* 43.44px */
      letter-spacing: 0.48px;
      background:  #000000 50% / cover no-repeat;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.z1 {
  position: relative;
  z-index: 1;
}
.profileview {
  width: 100px!important;
  height: 100px;
  .userImg{
    height: 100%;
  }
  .userImg img {
    width: 85px;
    height: 85px;
  }
}
.text-new{
  border-radius: 10px;
}
.bgborder {
  width:-webkit-fill-available;
  height:55px;
  background-image: url('../../src/images/rectangle-center.png');
  background-repeat: repeat-x;
  background-size: 100% 55px;
  display: inline-block;
  position: absolute;
  top: -26px;
  left: -10px;
  right: -10px;
  &::before{
    content: "";
    position: absolute;
    z-index:0;
    top: 0px;
    left: -8px;
    width:8px;
    height:55px;
    background-image: url('../../src/images/rectangle-left.png');
    background-repeat: no-repeat;
  }
  &::after{
    content: "";
    position: absolute;
    z-index:0;
    top: 0px;
    right: -8px;
    width:8px;
    height:55px;
    background-image: url('../../src/images/rectangle-right.png');
    background-repeat: no-repeat;
  }
}
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio:0) {
  input[type='range'] {
    overflow: hidden;
    width: 80px;
    -webkit-appearance: none;
    background-color: #9a905d;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #13bba4;
    margin-top: -1px;
  }
  
  input[type='range']::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    cursor: ew-resize;
    background: #434343;
    box-shadow: -80px 0 0 80px #43e5f7;
  }

}
/** FF*/
input[type="range"]::-moz-range-progress {
background-color: #43e5f7; 
}
input[type="range"]::-moz-range-track {  
background-color: #9a905d;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
background-color: #43e5f7; 
}
input[type="range"]::-ms-fill-upper {  
background-color: #9a905d;
}
.h-645{
  height: 645px;
}
/*Eisha Sajid*/

.dropdown-btn{
  background-repeat: no-repeat;
  background-size: cover;
  color:black;
  position: relative;
  background-color: #eff4f8;
  border:3px dashed #cdcbcb61;
  &:hover, &:focus, &:focus-visible, &:active, &.show{
    color:black!important;
    background-color: #eff4f8!important;
    border:3px dashed #cdcbcb61!important;
  }
  // &::before{
  //   content: "";
  //   position: absolute;
  //   z-index: -1;
  //   left: -25px;
  //   top: -25px;
  //   right: -25px;
  //   width:-webkit-fill-available;
  //   height:55px;
  //   background-image: url('../../src/images/Rectangle-golden.png');
  //   background-repeat: no-repeat;
  //   background-size: 100% 55px;
  // }
  &::after{
    content: "";
    position: absolute;
    z-index:0;
    top: 15px;
    right: 10px;
    width:25px;
    height:12px;
    background-image: url('../../src/images/triangle1.png');
    background-repeat: no-repeat;
    background-size:contain;
  }
}
.customDrop{
  left: 0px!important;
  right: 0px!important;
  margin: 0 auto!important;
  top:66px!important;
  transform:none!important;
  &::before{
    content: "";
    position: absolute;
    left: 0;
    margin: 0 auto;
    right: 0;
    top: -14px;
    width:25px;
    height: 14px;
    background-image: url('../../src/images/triangle1.png');
    background-size: cover;
  }
}

.clinetimg{
  width: 75px;
  height: 75px;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  bottom: 15px;
}

.headingnew{
  display: flex;
  width: 167.389px;
  height: 40.339px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
  box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
  h5{
    display: flex;
    width: 158.818px;
    height: 28.931px;
    padding: 0px 10px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--BEST-WHITE, #EFF4F8);
    box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
    color:#3C3D40;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}
.headingnew1{
  display: flex;
  width: 203.526px;
  height: 40.339px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
  box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
  h5{
    display: inline-flex;
    width: 193.105px;
    height: 28.931px;
    padding: 0px 10px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--BEST-WHITE, #EFF4F8);
    box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
    color:#3C3D40;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}
.col-446{
  width: 515px;
}
.col-488{
  width: 488px;
}
.p-5s{
  padding: 32px 15px 0px;
}
.p-2s{
  padding:15px;
}
.w-1494{
  width: 1494px;
}
.innerHeight{
  .carousel-inner{
    height: auto!important;
  }
}
.carousel-indicators{
  bottom:20px;
  button {
    width: 8px!important;
    height: 8px!important;
    display: inline-block;
    border-radius: 50%;
    background: var(--goldenlinear);
    filter: drop-shadow(5.25184px 5.25184px 10.5037px #B7C7D7) drop-shadow(-5.25184px -5.25184px 10.5037px #FFFFFF);
  }
}
.h-460{
  height: 460px;
}

.centerimg {
  height: 82%!important;
  margin-top: 44px;
}
.scroll-bar2 {
  position: relative;
  &::after{
    content: "";
    position: absolute;
    top: -6px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 98%;
    height: 6px;
    background-image: url('../images/linese.png');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.newimg1s img{
  height: 270px!important;
  object-fit:contain;
}

.d-block{
  display: block!important;
}
.mt0 {
  .button_wrapper_new{
    margin-top: 0px!important;
  }
}

.newdrop{
  .customDrop{
    left: inherit!important;
  }
}
.newdrop1{
  .customDrop{
    left: inherit!important;
    &::before{
      left: inherit!important;
      right: 30px!important;
    }
  }
}
.new{
  .range-slider__wrap{
    padding-left: 0!important;
    padding-right: 0!important;
  }
}

.bgArrow5 { 
  height: 50px;
  &::before {
    position: absolute;
    content: "";
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAQCAYAAAABOs/SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgBpZS9jtNAEIBnNg5H7o4izUkRPycaCoR04q+IrsF0iAYLOigIBQKlSQpEAQIHUVDyCL4XgA4JGqgQpakoaOw3MNIlShzvDvvrOHC5S2CieHd2x/PNzswap+nzkNU3XoC3CcA2EeonCGtylDovCpqO95FPhjAdD6HIR1ovpF6M90GPk6FcG6JcBz4ZyXcm4K01yDu2jt7ahpyvg5yb0f6Zd3zA6tuvQ0HsFRCg/EkhOSKBEb2mFUQgIlSDmmnV2ehtuaAtibQvvSHt9N7MVAgxaO08CZlS6yefhoAssiQiY+/IiqZAiq3cosKqEQ3GPk0w2sqClGJm1h2J6PTVl6GaMgeobXU70iDSp4KZkD0g2aCxXLeBoXuaYEwMJiYdNpZ+9rZ333ScX1ZhAGPUl8axNSxz7SBlwrCSkoroMlhztKm2Z/9eA9abY8292OxkjGq+9BBjeTZXYqykorrnwnELcwlT8Hg4Kvyz/ttsIdjAgwxzEUhwYo+JaItbde96Ciogck1lEo6CIAVvfOtiEGV/cv4CG/iNBIj5JCgxHWXdmUH3lU03QVlu11xlgImo5dfO33yXHsQ4EKz9N9oJ5+ArB7beNPOviqdPZEo5f2JV2DSf5v6l2x/SRf4XgpU0mn4CeV6Fw+zuomxGDSmvkW2sVOTcb3e+JIf5PhSs4a0g4VQEEpmZC2LKjfq6w/w1AvyVCxG0H39LjvJ7JFhJ89TduCjG1xXc3RXXSKy8PphxAb7fjeNlfC4FVrJ1rhtzwfvuk1nhmw8tw97uw69LQVcCK2ld6EcF5w/I1tN8EqXG8P6Ve5/2VvG1EljJmcvPIsFp4HSJHezceb8S9L/k5+de+OPjoxD+UX4DXJpPQT3IMC4AAAAASUVORK5CYII=);
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    display: inline-block;
    right: 15px;
    top: 20px;
  }
}
.bgArrow7 { 
  height: 50px;
  &::before {
    position: absolute;
    content: "";
    background: url("../images/StepZero/arrow.png");
    width: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 25px;
    display: inline-block;
    right: 11px;
    top: 12px;
  }
}
.bgArrow6 { 
  height: 22px;
  font-size: 14px;
  &::before {
    position: absolute;
    content: "";
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAQCAYAAAABOs/SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgBpZS9jtNAEIBnNg5H7o4izUkRPycaCoR04q+IrsF0iAYLOigIBQKlSQpEAQIHUVDyCL4XgA4JGqgQpakoaOw3MNIlShzvDvvrOHC5S2CieHd2x/PNzswap+nzkNU3XoC3CcA2EeonCGtylDovCpqO95FPhjAdD6HIR1ovpF6M90GPk6FcG6JcBz4ZyXcm4K01yDu2jt7ahpyvg5yb0f6Zd3zA6tuvQ0HsFRCg/EkhOSKBEb2mFUQgIlSDmmnV2ehtuaAtibQvvSHt9N7MVAgxaO08CZlS6yefhoAssiQiY+/IiqZAiq3cosKqEQ3GPk0w2sqClGJm1h2J6PTVl6GaMgeobXU70iDSp4KZkD0g2aCxXLeBoXuaYEwMJiYdNpZ+9rZ333ScX1ZhAGPUl8axNSxz7SBlwrCSkoroMlhztKm2Z/9eA9abY8292OxkjGq+9BBjeTZXYqykorrnwnELcwlT8Hg4Kvyz/ttsIdjAgwxzEUhwYo+JaItbde96Ciogck1lEo6CIAVvfOtiEGV/cv4CG/iNBIj5JCgxHWXdmUH3lU03QVlu11xlgImo5dfO33yXHsQ4EKz9N9oJ5+ArB7beNPOviqdPZEo5f2JV2DSf5v6l2x/SRf4XgpU0mn4CeV6Fw+zuomxGDSmvkW2sVOTcb3e+JIf5PhSs4a0g4VQEEpmZC2LKjfq6w/w1AvyVCxG0H39LjvJ7JFhJ89TduCjG1xXc3RXXSKy8PphxAb7fjeNlfC4FVrJ1rhtzwfvuk1nhmw8tw97uw69LQVcCK2ld6EcF5w/I1tN8EqXG8P6Ve5/2VvG1EljJmcvPIsFp4HSJHezceb8S9L/k5+de+OPjoxD+UX4DXJpPQT3IMC4AAAAASUVORK5CYII=);
    width: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    height: 20px;
    display: inline-block;
    right: 26px;
    top: 22px;
  }
}
.imgsec{
  width: 140px;
  height: 120px!important;
  margin:5px 0;
}
.btn4{
  color:#1C1C1C;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: url('../images/btnbg2.png') no-repeat;
  padding: 20px 40px;
  background-size: 100% 100%;
}
.announcment{
  .newform{
    display:flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 20px;
    // align-items: center;
    // background: url('../images/bgform.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 3.659px;
    border: 3.659px dashed var(--main-grey, #EFF4F8);
    background: var(--BEST-WHITE, #EFF4F8);
    box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF inset, 7.31801px 7.31801px 14.63601px 0px #B7C7D7;
  }
  label{
    background: var(--goldenlinear);
    border-radius: 1.665px;
    border: 1.665px dashed var(--main-grey, #EFF4F8);
    box-shadow: -1.66477px -1.66477px 3.32954px 0px #FFF, 4.16192px 4.16192px 8.32384px 0px #B7C7D7;
    min-width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
    color: var(--dark, #1C1C1C);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    position: relative;
    z-index: 2;
    width: 160px;
  }
  input{
    color: #877E7E;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 15px;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border: 3.659px dashed var(--main-grey, #EFF4F8);
  }
  .nav-item2{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: 0px;
      top: -15px;
      width: 50px;
      height: 77px;
      background-size: cover;
    }
  }
  .nav-item4{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: 22px;
      top: 17px;
      width: 50px;
      height: 77px;
      background-size: cover;
    }
  }
  .nav-item3{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after1.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: 0px;
      top: 36px;
      width: 72px;
      height: 170px;
      background-size: cover;
    }
  }
  .newannous {
    background: url("../images/bg-new4.png") no-repeat;
    background-size: 100% 100%;
    height: 115px;
    padding: 15px;
    h6{
      color: #48494D;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      width: 108px;
      height: 67.438px;
    }
  }

}
.mb-28{
  margin-bottom: 28px;
}
.spacedrop {
  top: 5px;
  right: 10px;
  .dropdown-menu{
    a {
      img{
        height: auto;
      }
    }
  }
}
.dropdownload{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  label{
    background: none!important;
    border-radius:0;
    border: 1.665px dashed var(--main-grey, #EFF4F8);
    box-shadow: 0px 0px 0px 0px #FFF, 0px 0px 0px 0px #B7C7D7;
    min-width:inherit;
    height:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 00px;
    color: var(--dark, #1C1C1C);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    z-index: 2;
  }
  span{
    display: inline-flex;
    height: 32px;
    padding:8px 15px;
    justify-content: center;
    align-items: center;
    border: 0px solid #000;
    border-radius: 4.018px;
    cursor: pointer;
    background: var(--gradient-gold, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    box-shadow: -1.60735px -1.60735px 3.2147px 0px #FFF, 1.60735px 1.60735px 3.2147px 0px #B7C7D7;
  }
}
.nametext{
  color:#1C1C1C;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}
/*tab slider */

.tabComponent{
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 96%;
    display: inline-block;
    background: #EBDDDE;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 12px;
  }
  .tabsul {
    .tabli {
      width: 128px;
      .btn-layer {
        box-shadow: -1px -1px 0px #FFFFFF, -2px -2px 2px #B8CCE0, inset -1px -1px 0px #FFFFFF;
        background: var(--page-background);
        height: 42px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 5px;
        span {
          font-weight: 500;
          font-size: 11px;
          text-align: center;
          height: 34px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--page-background);
          color: var(--text-body);
          letter-spacing: 0.01em;
          box-shadow: 4px 4px 8px #C9D9E8, -4px -4px 8px #FFFFFF;
          position: relative;
          cursor: pointer;
          &::before {
            width: 100px;
            height: 30px;
            position: absolute;
            top: -68px;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 20px;
          }
          svg {
            margin-bottom: 0;
          }
        }
      }
      &.active{
        .btn-layer span {
          background: var(--goldenlinear);
          color: #000;
          box-shadow: 4px 4px 8px #C9D9E8, -4px -4px 8px #FFFFFF;
          &::before {
            content: url("../images//goldenbutton2.png")
          }
        }
      }
    }
  }
}

.tabComponent1{
  .tabsul {
    .tabli {
      width: 94px;
    }
  }
}

/*tab status */

.login-tab1 {
  position: relative;
  .tabli {
    width: 20%;
    .btn-layer {
      box-shadow: -0px -0px 0px #FFFFFF, -0px -0px 0px #B8CCE0, inset -0px -0px 0px #FFFFFF;
      background: var(--page-background);
      height: 42px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 5px;
      span {
        font-weight: 500;
        font-size: 11px;
        text-align: center;
        height: 34px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--page-background);
        color: var(--text-body);
        letter-spacing: 0.01em;
        box-shadow: 0px 0px 0px #C9D9E8, -0px -0px 0px #FFFFFF;
        position: relative;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        &::before {
          width: 35px;
          height: 45px;
          position: absolute;
          top: -42px;
          left: 0;
          right: 0;
          margin: 0 auto;
          border-radius: 20px;
        }
        svg {
          margin-bottom: 0;
        }
      }
    }
    &.active{
      .btn-layer span {
        box-shadow: 0px 0px 0px #C9D9E8, -0px -0px 0px #FFFFFF;
        &::before {
          content: url("../images/unionas.png")
        }
      }
    }
  }
}

.radioTab{
  .btn-check:checked + .btn{
      position: relative;
      box-sizing: border-box;
      background: var(--page-background) !important;
      background-clip: padding-box !important;
      border: solid 3px transparent !important;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -3px;
      border-radius: inherit;
      background: var(--goldenlinear);
    }
  }
}


.playerinternal{
  span{
    display: flex;
    width: 357px;
    height: 83px;
    padding: 10px;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin: 0 auto 18px;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
    h6{
      display: flex;
      width: px;
      height: 59.527px;
      padding: 0px 15px;
      align-items: flex-start;
      flex-shrink: 0;
      border-radius: 20px;
      background: var(--BEST-WHITE, #EFF4F8);
      box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
      color: var(--grey-3, #3C3D40);
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: 59px;
      margin: 0;
    }
  }
}
.three-hour-launch .col-lg-9.nav-item4{
  padding-left: 15px!important;
}
.gander-button .btn-check:checked + .btn, .gander-button .btn-check + .btn:hover{
  background: var(--goldenlinear)!important;
  color: #000!important;
}
.multi-range-slider{
  border: 0px solid #000!important;
  box-shadow: 0px 0px 0px 0px #000!important;
  padding: 0!important;
}
.multi-range-slider .bar-inner{
  border: solid 0px black!important;
  background: var(--goldenlinear)!important;
  box-shadow: 0px 0px 0px 0px #000!important;
  border-radius: 10px;
}
.multi-range-slider .bar{
  height: 16px;
  background: var(--page-background)!important;
  border-radius: 15px;
  box-shadow: inset -5px -5px 8px #fff, inset 4px 4px 8px #889fb6!important;
}
.multi-range-slider .bar-right, .multi-range-slider .bar-left{
  background: var(--page-background)!important;
  border-radius: 15px;
  box-shadow: inset -5px -5px 8px #fff, inset 4px 4px 8px #889fb6!important;
}
.multi-range-slider .ruler .ruler-rule {
  border:0px solid #000!important;
  background: url("../images/img200.png") no-repeat;
  background-size:contain;
  padding: 5px 10px;
}
.multi-range-slider .ruler{
  justify-content: space-between;
}
.multi-range-slider .thumb::before{
  background-image: url("../images/Unionasd.png");
  background-repeat: no-repeat;
  height: 70px!important;
  width: 70px!important;
  background-size: contain;
  border: 0px solid #000!important;
  background-color: initial!important;
  box-shadow: 0px 0px 0px 0px #000!important;
  margin: -25px -31px!important;
}
.thumb-left{
  display: none;
}
.bar-inner-left{
  border-radius: 10px 10px 0px 0px!important;
}
.multi-range-slider .labels, .multi-range-slider .ruler, .multi-range-slider .thumb .caption *{
  display: none!important;
}
.text-flex{
  display: flex;
  justify-content: space-between;
  h6{
    img{
      width: 88%;
    }
    width: 20%;
    display: inline-block;
    text-align: center;
    color: #4E4E57;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.pointer{
  pointer-events: none;
}
.righttool{
  width: 54px;
  height: 315px;
  border-radius: 0px 0px 30px 30px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 80px;
  right: 40px;
  z-index: 1;
  a{
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    letter-spacing: 0.12px;
  }
}
.playerinternal{
  height: 1200px;
}
.playerinterna2{
  height: 650px;
}
.audioplay {
  width: 790px;
  top:240px;
  left: 0;
  right: 0;
  margin: 0 auto;
  .audiotool {
    z-index: 8;
  }
  .audioBtm2{
    padding: 50px 40px 47px;
    text-align: center;
    border-radius:0 0 150px 150px;
    border: 2px solid var(--GOLD, #FACB30);
    box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70) inset, -6px -6px 15px 0px #FFF inset;
    width: 96%;
    margin: -189px auto 0;
    position: relative;
    top:0;
    z-index: 7;
    h5{
      display: flex;
      width: 224px;
      height: 42px;
      padding: 4.118px 6.588px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto -15px;
      border-radius: 2.471px;
      color: #101010;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.14px;
      position: relative;
      z-index: 7;
    }
    h6{
      display: flex;
      width: 100%;
      height: 59px;
      padding: 14px 20px 5px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 3px;
      border: 1px solid var(--colors-2, #FACB30);
      color: #1C1C1C;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .audioBtm{
    padding: 200px 40px 47px;
    text-align: center;
    border-radius:0 0 150px 150px;
    border: 2px solid var(--GOLD, #FACB30);
    box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70) inset, -6px -6px 15px 0px #FFF inset;
    width: 96%;
    margin: -189px auto 0;
    position: relative;
    top:0;
    z-index: 7;
    h5{
      display: flex;
      width: 224px;
      height: 42px;
      padding: 4.118px 6.588px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto -15px;
      border-radius: 2.471px;
      color: #101010;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.14px;
      position: relative;
      z-index: 7;
    }
    h6{
      display: flex;
      width: 100%;
      height: 59px;
      padding: 14px 20px 5px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 3px;
      border: 1px solid var(--colors-2, #FACB30);
      color: #1C1C1C;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .customSec{
    padding: 200px 40px 47px;
    text-align: center;
    border-radius:0 0 130px 130px;
    border: 2px solid var(--colors-2, #FACB30);
    background: var(--grey-8, #F0F4F8);
    box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
    width: 96%;
    margin: -189px auto 0;
    position: relative;
    top:0;
    // margin-top: -189px;
    z-index: 6;
  }
}
.wcircle{
  position: absolute;
  right: -33px;
  top: -39px;
  z-index: 0;
  img{
    width: 150px;
  }
}
.iconaudio{
  position: absolute;
  right: 0px;
  top: -145px;
  left:-20px;
  z-index: 0;
  img{
    width: 70px;
  }
}
.iconaudio2{
  position: absolute;
  right: -36px;
  top: -30px;
  z-index: 0;
  img{
    width: 150px;
  }
}
.livebtn2{
  position: absolute;
  top: 52px;
  right: -15px;
  padding: 5px 0px 5px 0px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 15px;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 90px;
  height: 24px;
  div{
    width: 50%;
  }
  .active{
    position: relative;
    &::after{
      display: flex;
      padding: 8px 12px;
      width: 45px;
      top:-10px;
      align-items: flex-start;
      border-radius: 20px;
      background:#202020;
      content: "Live";
      position: absolute;
      left: 0;
      box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
      height: 30px;
      color: #FFF;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &::before{
      display: flex;
      width: 16px;
      top:-13px;
      right: -10px;
      content: "";
      position: absolute;
      height:16px;
      background:url("../images/audio/iconplay.png") no-repeat;
      background-size: contain;
      z-index: 1;
    }
  }
}
.middleimg{
  border-top: 1px solid var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
  z-index: 1;
  position: relative;
}
.btnarea {
  button{
    background:var(--goldenlinear);
    border-radius: 30px;
    height: 36px;
    padding: 0 10px;
    color:#202020;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    border: 0px solid #000;
    margin: 0 3px;
    font-family: "Inter";
  }
}
.imgtop{
  margin-bottom: -108px;
  position: relative;
  z-index: 1;
  width: 80%;
}
.listenLive{
  display:flex;
  width: 260px;
  .icontb{
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -40px;
    position: relative;
    z-index: 6;
  }
  span{
    display: flex;
    width: 200px;
    height: 70px;
    padding: 10px 20px 10px 60px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex-shrink: 0;
    border-radius: 0px 60px 0px 0px;
    background: var(--colors-2, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
    h3{
      color: #000;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
    h4{
      color: #000;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }
}
.listbtn{
  button{
    display: flex;
    height: 40px;
    padding: 10px 20px;
    align-items: center;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
    color:#202020;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
  }
}
.listfriest {
  padding-right: 10px;
  img{
    width: 50px;
    height: 50px;
    display: inline-block;
  }
  h3{
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
  h4{
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;

  }
}
.d-inline-table{
  display: inline-table;
}
.btmaudio{
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h3{
    display: flex;
    padding: 7px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 30px;
    background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
    margin: 0;
    span{
      display: flex;
      padding: 6px 20px;
      align-items: flex-start;
      border-radius: 20px;
      background: var(--page-background);
      box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  h4{
    display: flex;
    padding: 7px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    border-radius: 30px;
    background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
    span{
      display: inline-flex;
      padding: 10px 20px;
      align-items: flex-start;
      border-radius: 20px;
      background: var(--page-background);
      box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
      font-family: Inter;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.videoplayer{
  padding-top: 50px;
  .topbtn{
    height: 100px;
    width: 100%;
    color: #000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%; /* 91.2px */
    text-transform: capitalize;
    transform: rotate(-4.084deg);
  }
}
.videobox{
  width: 100%;
  padding: 77px 40px;
  display: inline-block;
  height: 1142px;
  border-radius: 12px;
  border: 3px dashed var(--colors-2, #FACB30);
  background: var(--main-grey, #EFF4F8);
  box-shadow: -4px -4px 8px 0px #FFF, 4px 4px 8px 0px #B7C7D7;
  .playersec{
    width: 1100px;
  }
}
.vidplay {
  width: 1060px;
  top:0px;
  left: 0;
  right: 0;
  margin: 0;
  .audiotool {
    z-index: 8;
  }
  .audioBtm{
    padding: 227px 52px 28px;
    text-align: center;
    border-radius:0 0 150px 150px;
    border: 2px solid var(--GOLD, #FACB30);
    box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70) inset, -6px -6px 15px 0px #FFF inset;
    width: 100%;
    margin: -189px auto 0;
    position: relative;
    top:0;
    z-index: 7;
    h5{
      display: flex;
      width: 224px;
      height: 42px;
      padding: 4.118px 6.588px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto -15px;
      border-radius: 2.471px;
      color: #101010;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.14px;
      position: relative;
      z-index: 1;
    }
    h6{
      display: flex;
      width: 100%;
      height: 59px;
      padding: 14px 20px 5px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 3px;
      border: 1px solid var(--colors-2, #FACB30);
      color: #1C1C1C;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .customSec{
    padding: 230px 70px 50px;
    text-align: center;
    border-radius:0 0 130px 130px;
    border: 2px solid var(--colors-2, #FACB30);
    background: var(--grey-8, #F0F4F8);
    box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
    width: 100%;
    margin: -189px auto 0;
    position: relative;
    top:0;
    // margin-top: -189px;
    z-index: 6;
  }
}
.listfriest1 {
  padding-right: 10px;
  align-items: center;
  img{
    width: 50px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;
  }
  h3{
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
  h4{
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;

  }
}
.listenLive1{
  display:flex;
  width: 360px;
  position: relative;
  z-index: 1;
  .icontb{
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -40px;
    position: relative;
    z-index: 1;
  }
  span{
    display: flex;
    width: 280px;
    padding: 10px 20px 10px 60px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0px 60px 0px 0px;
    background: var(--colors-2, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
    h3{
      color: #000;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    h4{
      color: #000;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }
}
.vidsidebr{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 110px;
  margin-top: 60px;
  height: 828px;
  border-radius: 60px;
  padding-bottom: 34px;
  background: var(--grey-8, #F0F4F8);
  box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF; 
  .vidlayer{
    width: 90px;
    height: 70px;
    margin-bottom: 15px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 2px solid var(--colors-2, #FACB30);
    box-shadow: 1px 1px 3px 0px rgba(178, 189, 203, 0.70), -1px -1px 3px 0px #FFF;
  }
  .videolay{
    height: 100%;
    width: 100%;
    display: inline-block;
    padding: 30px 10px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
.liststyle{
  display: flex;
  width: 800px;
  height: 44px;
  padding: 10px 15px 10px 40px;
  align-items: center;
  border-radius: 0px 60px 60px 0px;
  background: var(--page-background);
  box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: -80px;
  justify-content: center;
  }
.dropnew{
  ul{
    width: 200px;
    padding:0px;
    right: 0px !important;
    top: 45px !important;
    transform: none !important;
    &::before{
      content: none!important;
    }
    .newdrop6{
      padding: 5px;
      border: 4px dashed #F0F4F8!important;
      border-radius: 0px;
    }
    li {
      width: 100%!important;
      height: auto!important;
      border-radius: 0%!important;
      padding: 0px!important;
      margin-right: 0px!important;
      margin-bottom: 5px;
      box-shadow: inset 0px 0px 0px var(--shadow-light-50), inset 0px 0px 0px var(--shadow-light)!important;
      a{
        background: var(--grey-8, #F0F4F8);
        box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
        display: flex;
        width: 100%;
        padding: 5px;
        height: 44px;
        align-items: center;
      }
    }
  }
}
.custompopup{
  max-width: 550px;
  .modal-content{
    padding: 40px 20px 20px 20px!important;
    border-radius: 0!important;
    border: 4px dashed #F0F4F8!important;
  }
  .modal-body{
    padding: 0px;
    h6{
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 15px;
    }
    ul {
      position: relative;
      &::after{
        content: "";
        background: url("../images/audio/bgline.png");
        width: 6px;
        height: 225px;
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 10px;
      }
      li {
        padding-left: 30px;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 15px;
        position: relative;
        display: flex;
        align-items: center;
        &::after{
          content: "";
          background: url("../images/audio/lidot.png") no-repeat;
          width: 32px;
          height: 33px;
          display: inline-block;
          position: absolute;
          top: -2px;
          left: -2px;
          z-index: 1;
        }
        svg{
          margin-left: auto;
        }
      }
    }
    button{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 218px;
      height: 50px;
      border-radius: 30px;
      background: var(--goldenlinear);
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.52px;
      text-transform: uppercase;
      border: 0px solid #000;
    }
  }
  .btn-close{
    position: absolute;
    top: -15px;
    opacity: 1;
    right: 15px;
    height: 60px;
    width: 50px;
  }
}
.custompopup0{
  align-items: start!important;
  .modal-content{
    padding: 60px 20px 20px 20px!important;
    border-radius: 0!important;
    border: 4px dashed #F0F4F8!important;
  }
  .modal-body{
    button.top{
      display: flex;
      padding: 12px 15px;
      align-items: center;
      gap: 10px;
      border: 3px dashed var(--grey-9, #FFF);
      background: var(--colors-2, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 auto;
      position: absolute;
      top:-90px;
      left: 0;
      right: 0;
      width: fit-content;
    }
    padding: 0px;
  }
  .btn-close{
    position: absolute;
    top: -15px;
    opacity: 1;
    right: 15px;
    height: 60px;
    width: 50px;
  }
}
.custompopup1{

  .modal-body{
    padding: 0px;
    ul {
      li {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: relative;
        display: flex;
        align-items: center;
        height: 46px;
        width: 100%;
        &::after{
          content: "";
          background: var(--grey-8, #F0F4F8);
          box-shadow: 1px 1px 1px 0px rgba(178, 189, 203, 0.70), -1px -1px 1px 0px #FFF;
          height: 2px;
          width: 100%;
          display: inline-block;
          position: absolute;
          left: 0;
          bottom: 0px;
        }
        &:last-child{
          &::after{
            content: none;
          }
        }
        svg{
          margin-left: auto;
        }
      }
    }
    p{
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;
      margin-bottom: 20px;
      a{
        color: #DDA01A;
      }
    }
    button.bottom{
      border-radius: 30px;
      background: var(--grey-8, #F0F4F8);
      box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
      display: flex;
      padding: 17px 30px;
      align-items: center;
      width: 161px;
      height: 50px;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.52px;
      text-transform: uppercase;
      margin: 0 auto;
    }
  }
}
.dotbtn{
  position: absolute;
  top:20px;
  left: 20px;
}
.custompopup2{
  max-width: 410px;
  .modal-body{
    padding: 0px;
    ul {
      position: relative;
      margin-bottom: 20px;
      &::after{
        content: "";
        background: url("../images/audio/bgline.png");
        width: 6px;
        height: 405px;
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 16px;
      }
      li {
        padding-left: 30px;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left:0px;
        span{
          background: var(--goldenlinear);
          display: flex;
          width: 35px;
          height: 16px;
          padding: 2px 5px;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
          z-index: 1;
          margin-right: 10px;
        }
        svg{
          margin-left: auto;
        }
        a {
          width: 320px;
          padding: 10px;
          font-family: Inter;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          &:hover{
            border-radius: 30px;
            background: var(--grey-8, #F0F4F8);
            box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
          }
        }
      }
    }
    p{
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    }
  }
}
.custompopup3{
  max-width: 480px;
  .modal-body{
    padding: 20px;
    text-align:center;
    ul{
      justify-content: space-between;
    
      li{
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
      }
    }
    h3{
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    h4{
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    h5{
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      a{
        color: #DDA01A;
      }
    }
    button.btn-bottom{
      display: flex;
      padding: 17px 30px;
      align-items: center;
      border-radius: 30px;
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.52px;
      text-transform: uppercase;
      border:0px solid #000;
      margin-top: 20px;
    }
  }
}
.custompopup4{
  max-width: 700px;
  .modal-content{
    padding-top: 20px!important;
  }
  .modal-body{
    button.top{
      top:-50px;
    }
    padding: 0px;
    ul {
      position: relative;
      margin-bottom: 20px;
      &::after{
        content: "";
        background: url("../images/audio/bgline2.png") no-repeat;
        width: 95%;
        height: 10px;
        display: inline-block;
        background-size: cover;
        position: absolute;
        top: 40px;
        left: 16px;
      }
      li {
        position: relative;
        z-index: 1;
        text-align: center;
        img{
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 10px;
          box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
        }
        a {
          text-align: center;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    h3{
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    button.btn-dark{
      display: flex;
      width: 110px;
      height: 40px;
      padding: 10px 10px;
      align-items: center;
      border-radius: 20px;
      background: #202020;
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      color: #FFF;
      text-align: center;
      justify-content: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    h4{
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    h5{
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    button.btn-bottom{
      display: flex;
      padding: 17px 30px;
      width: 100px;
      height: 50px;
      align-items: center;
      border-radius: 30px;
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.52px;
      text-transform: uppercase;
      margin-left: 10px;
    }
    h6{
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    small{
      color: #808492;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;}
  }
}
.custompopup5{
  max-width: 650px;
  .modal-content{
    padding-top: 20px!important;
  }
  .modal-body{
    button.top{
      top:-50px;
    }
    padding: 0px;
    h3{
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p{
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    button.btn-bottom{
      display: flex;
      padding: 15px 20px;
      height: 50px;
      align-items: center;
      box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.52px;
      text-transform: uppercase;
      border:0px solid #000;
    }
  }
}
.custompopup6{
  max-width: 600px;
  .modal-content{
    padding: 70px 20px 20px 20px;
  }
  .modal-body{
    padding: 0px;
    .picarea{
      width: 425px;
      display: inline-flex;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      .inerpic{
        width: 100px;
        height: 100px;
        padding: 10px;
        position: absolute;
        bottom: -40px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    p{
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 41px;
    }
    .boxmodal{
      display: flex;
      width: 470px;
      height: 100px;
      margin: 0 auto 30px;
      padding: 0px 20px;
      justify-content: space-between;
      align-items: center;
      border: 4px dashed var(--colors-2, #FACB30);
      background: var(--grey-8, #F0F4F8);
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      .topbtn{
        border-radius: 30px;
        border: 2px solid var(--grey-2, #202020);
        display: flex;
        padding: 10px 15px;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: -30px;
        width: 100px;
        button{
          display: flex;
          height: 26px;
          padding: 0px 15px;
          align-items: center;
          border-radius: 20px;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
      .spandiv{
        width: 160px;
        img{
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 5px;
        }
        h3{
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin: 0 0 5px;
        }
        h4{
          display: flex;
          padding: 5px 10px;
          align-items: flex-start;
          font-family: Inter;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
        }
      }
    }
    h5{
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .bottmdrop{
      padding: 10px 20px;
      background: url("../images/audio/dropbg.png") no-repeat;
      border-radius: 40px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      background-size: 100% 100%;
      cursor: pointer;
      button{
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.52px;
        text-transform: uppercase;
        height: 50px;
        width: 160px;
        padding: 17px 25px;
      }
    }
    .bottmsec{
      .hidesection{
        position: relative;
        width: 424px;
        height: 160px;
        margin: 40px auto 0;
        display: inline-block;
        padding: 30px 0px 0;
        background: url("../images/audio/hidbox.png") no-repeat;
        border-radius: 8px;
        background-size: 100% 100%;
        h6{
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .spantop{
          display: flex;
          width: 100%;
          height: 40.5px;
          padding: 9px 0px 9.5px 0px;
          justify-content: center;
          align-items: center;
          border-radius: 8.212px 8.212px 0px 0px;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 136%; /* 21.76px */
        }
        .spanbtm{
          display: flex;
          width: 100%;
          height: 40.5px;
          padding: 6.5px 0px 7px 0px;
          justify-content: center;
          align-items: center;
          border-radius: 0px 0px 8.212px 8.212px;
          text-align: center;
          font-family: Inter;
          font-size: 19.709px;
          font-style: normal;
          font-weight: 600;
          line-height: 136%; /* 26.804px */
        }
        ul{
          display: flex;
          li{
            width: 33.33%;
            padding: 0 7px;
            .overflow-h{
              overflow: hidden;
            }
          }
        }
        .firstbtn{
          display: flex;
          justify-content: end;
          position: absolute;
          right: 0;
          top:-20px;
          button.left{
            display: flex;
            width: 150px;
            padding: 12px 15px;
            align-items: flex-start;
            border-radius: 50px;
            border: 1px solid #202020;
            box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
            justify-content: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 14px */
            letter-spacing: 0.14px;
            margin-right: 14px;
            position: relative;
            z-index: 10;
          }
          button.right{
            width: 105px;
            height: 40px;
            fill: var(--colors-2, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
            filter: drop-shadow(2px 2px 8px #B2BDCB) drop-shadow(-2px -2px 8px #FFF);
            position: relative;
            z-index: 1;
            &::after{
              content: "";
              width: 42px;
              height: 10px;
              background: var(--grey-2, #202020);
              box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
              display: flex;
              left: -42px;
              position: absolute;
              top: 13px;
              z-index: 0;
            }
          }
        }
      }
      .editsec{
        position: relative;
        display: flex;
        width: 100%;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        background: url("../images/audio/img13.png") no-repeat;
        border-radius: 0px;
        background-size: 100% 100%;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      h6{
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        a{
          color: #DDA01A;
        }
      }
    }
    .newpopup{
      .topnewbtn{
        width: 250px;
        height: 80px;
        background: url(../images/audio/img20.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h3{
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      h4{
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      h6{
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
      .mb-22{
        margin-bottom: 22px;
      }
      button.join{
        display: flex;
        padding: 17px 30px;
        height: 50px;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.52px;
        text-transform: uppercase;
      }
      button.member{
        display: flex;
        width: 152px;
        padding: 12px 15px;
        align-items: center;
        justify-content:center;
        margin: 0 auto;
        height: 38px;
        border-radius: 50px;
        box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 14px */
        letter-spacing: 0.14px;
      }
      .checksec{
        width: 350px;
        display: flex;
        align-items: center;
        text-align: left;
        justify-content: flex-start;
        margin: 0 auto;
        margin-bottom: 10px;
        span{
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
          margin-right: 10px;
        }
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}


.tabpayment {
  .tab-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 400px;
    margin: 0 auto;
    &::after{
      content: "";
      background: url("../images/audio/bgline3.png") no-repeat;
      width: 100%;
      height: 12px;
      display: inline-block;
      position: absolute;
      top: 20px;
      left: 0px;
    }
  }

  .tab-buttons button {
    width: 70px;
    height: 40px;
    background: var(--goldenlinear);
    cursor: pointer;
    border: 0px solid #000;
    border-radius: 30px;
    position: relative;
    z-index: 1;
  }

  // .tab-buttons button.active {
  //   background-color: #007bff;
  //   color: #fff;
  // }

  .tab-content {
    .greentb{
      background: linear-gradient(130deg, #4C6D5C 0.65%, #6F9F86 47.74%, #4C6D5C 100%);
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
      border-radius: 10px;
      display: flex;
      width: 500px;
      padding: 20px 0;
      margin: 0 auto;
    }
    .graytb{
      border-radius: 18px;
      background: #243747 url(../images/audio/img16.png);
      box-shadow: 8px 8px 20px 0px rgba(178, 189, 203, 0.70), -8px -8px 20px 0px #FFF;
      width: 500px;
      flex-direction: column;
      align-items: center;
      display: flex;
      padding:0 0 20px;
      margin: 0 auto;
    }
    .graytb1{
      background: #808080 url(../images/audio/img17.png);
      border-radius: 9.632px;
      // background: var(--shiny-silver, linear-gradient(180deg, #808080 -18%, #EBEBEB 22.5%, #A7A7A7 67.84%, #E8E8E8 116%));
      width: 500px;
      flex-direction: column;
      align-items: center;
      display: flex;
      padding:0 0 20px;
      margin: 0 auto;
    }
    label{
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 10px;
    }
    input{
      display: flex;
      height: 50px;
      padding: 15px 5px;
      width: 100%;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      background: var(--grey-8, #F0F4F8);
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
      color: #5F6168;
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border:0px solid #000
    }
  }
  .tab-panel {
    display: none;
  }

  .tab-panel.active {
    display: block;
  }
}
.col-lg-7s {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-lg-5s {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.three-hour-launch .col-lg-3, .three-hour-launch .col-lg-9, .three-hour-launch .col-lg-5, .three-hour-launch .col-lg-7 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.pe-2 {
    padding-right: 0.5rem !important;
}
.col-lg-3s {
    flex: 0 0 auto;
    width: 25%;
}
.col-lg-9s {
  flex: 0 0 auto;
  width: 75%;
}
.modal button:focus:not(:focus-visible){
  box-shadow: 0px 0px 0px 0px #000;
}
.dropdown a.dropBtn:focus{
  box-shadow: 0px 0px 0px 0px #000!important;
}
.newdrop{
  border-radius: 15px;
  padding: 30px;
  width: 360px;
  top:-50px;
  // transform:none!important;
  // inset: inherit!important;
  h4{
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 190%; /* 38px */
  }
}
.dropn .show .dropBtn::before {
  position: absolute;
  content:"";
  background:url("../images/audio/arrow1.png") no-repeat;
  width: 40px;
  height: 45px;
  display: inline-block;
  background-size: contain;
  right: -51px;
  top: -12px;
}
.custompopup7{
  max-width: 650px;
  .modal-content{
    padding-top: 70px!important;
  }
  .modal-body{
    button.top{
      top:-90px;
    }
    padding: 0px;
    h4{
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    p{
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    a{
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color:#DDA01A;
    }
    button.btn-bottom{
      display: flex;
      padding: 15px 30px;
      height: 50px;
      align-items: center;
      box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.52px;
      text-transform: uppercase;
      border:0px solid #000;
    }
  }
}
.mb-7{
  margin-bottom: 30px;
}
.dropnew2{
  ul{
    width: 540px;
    padding:0px;
    right: 0px !important;
    top: 53px !important;
    transform: none !important;
    &::before{
      content: none!important;
    }
    .newdrop7{
      padding: 20px;
      border: 4px dashed #F0F4F8!important;
      border-radius: 0px;
      h6{
        color: #808492;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
      }
      h4{
        color: #808492;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 40px;
      }
    }
    .labeldiv{
      display: flex;
      width: 90px;
      height: 50px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .clipdiv{
      margin: 30px 0;
      h5{
        height: 30px;
        width: 25%;
        border-left: 1px solid #000;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
      }
    }
    .clipinternal{
      padding: 10px;
    }
    button{
      display: flex;
      padding: 17px 20px;
      height: 50px;
      align-items: center;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.52px;
      text-transform: uppercase;
      border-radius: 0px!important;
    }
    button.bg{
      background: var(--page-background)!important;
    }
  }
  .dropdown-menu{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top:-17px;
      right: 20px;
      background: url("../images/triangle1.png") no-repeat;
      width: 30px;
      height: 16px;
      background-size: contain;
    }
  }
}
.custompopup8{
  max-width: 300px;
  .modal-content{
    padding: 15px 12px!important;
  }
  .modal-body{
    padding: 0px;
    h4{
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 0;
    }
    h5{
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    button.btn-bottom{
      border-radius: 20px;
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      display: flex;
      height: 40px;
      padding: 10px 20px;
      align-items: center;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: 0px solid #000;
      margin-right: 5px;
    }
  }
}
.dropnew3{
  ul{
    width: 100px;
    right: 0px !important;
    top: 53px !important;
    transform: none !important;
    li{
      a{
        justify-content: center;
      }
    }
  }
  .dropdown-menu{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top:-17px;
      right: -5px;
      background: url("../images/triangle1.png") no-repeat;
      width: 30px;
      height: 16px;
      background-size: contain;
    }
  }
}
.playable{
  align-items: center;
  a{
    margin: 0 14px;
  }
  .btnplaytime{
    display: flex;
    width: 167px;
    height: 36.34px;
    padding: 0px 10px;
    align-items: center;
    border-radius: 20px;
    background: var(--goldenlinear);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.playable1{
  align-items: center;
  a, .dropnew {
    margin: 0 5px;
  }
  .inerplayable{
    a {
      margin: 0 2px;
    }
  }
  .btnplaytime{
    display: flex;
    width: 132px;
    height: 36px;
    padding: 0px 10px;
    align-items: center;
    border-radius: 20px;
    background: var(--goldenlinear);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
}
.dropnew4{
  ul{
    width: 180px;
    right: 0px !important;
    top: 53px !important;
    transform: none !important;
    li{
      margin: 5px 0;
      a{
        justify-content: center;
      }
    }
  }
  h6{
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .dropdown-menu{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top:-17px;
      right: -5px;
      background: url("../images/triangle1.png") no-repeat;
      width: 30px;
      height: 16px;
      background-size: contain;
    }
  }
}

.dropnew5{
  ul{
    width: 315px;
    right: 0px !important;
    top: 53px !important;
    transform: none !important;
    li{
      margin: 5px 0;
      a{
        justify-content: center;
      }
    }
  }
  h6{
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .dropdown-menu{
    position: relative;
    .newdrop6{
      padding: 20px;
    }
    &::after{
      content: "";
      position: absolute;
      top:-17px;
      right: -5px;
      background: url("../images/triangle1.png") no-repeat;
      width: 30px;
      height: 16px;
      background-size: contain;
    }
  }
  .newdrop6{
    position: relative;
    &::after{
      content: "";
      width: 6px;
      height: 140px;
      display: inline-block;
      background: url("../images/audio/bgline.png") no-repeat;
      position: absolute;
      top: 56px;
      left: 19px;
    }
    .lidiv{
      img{
        margin-right: 8px;
        width: 24px;
        height: 24px;
        display: inline-block;
        object-fit: none;
      }
      font-family: Inter;
      padding-left: 25px;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      height: 34px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      position: relative;
      &::before{
        content: "";
        background: url("../images/audio/lidot.png") no-repeat;
        width: 32px;
        height: 33px;
        display: inline-block;
        position: absolute;
        top: 3px;
        left: -13px;
        z-index: 1;
      }
      &::after{
        width: 250px;
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        height: 2px;
        background:#F0F4F8;
        box-shadow: 1px 1px 1px 0px rgba(178, 189, 203, 0.70), -1px -1px 1px 0px #FFF;
      }
      h6{
        font-family: Inter;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0;
        margin-left: auto;
      }
    }
    .lastdiv{
      &::after{
        content: none;
      }
    }
    button.top{
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      padding: 12px 15px;
      align-items: center;
      border: 3px dashed var(--grey-9, #FFF);
      background: var(--colors-2, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 auto;
      position: absolute;
      top:-60px;
      left: 0;
      right: 0;
      width: fit-content;
    }
  }
}
.soundediv{
  position: relative;
  height: 20px;
  width: 200px;
  transform: rotate(270deg);
  background-color: #202020;
  border-radius: 10px;
}
.soundediv22{
  position: relative;
  height: 20px;
  width: 200px;
  transform: rotate(270deg);
  background-color: #202020;
  border-radius: 10px;
}
.soundediv2{
  position: relative;
  height: 20px;
  width: 200px;
  transform: rotate(270deg);
  background-color: #202020;
  border-radius: 10px;
}
.sound-control{
  position: absolute;
  left: 20px;
  top: 180px;
  display: flex;
  flex-direction: column;
}
.sound-control22{
  position: absolute;
  left: 20px;
  top: 5px;
  display: flex;
  flex-direction: column;
}
.sound-control-mini{
  position: absolute;
  left: 49px;
  top: -18px;
  display: flex;
  flex-direction: column;
}
.sound-control-mini2{
  position: absolute;
  left: 50px;
  top: -18px;
  display: flex;
  flex-direction: column;
}
.video-container-mini{
  border: 3px dashed var(--colors-2, #FACB30);
}
.sound-control2{
  position: absolute;
  left: 40px;
  
  top: 80px;
  display: flex;
  flex-direction: column;
}
.sound-control3{
  position: absolute;
  left: 280px;
  
  top: 350px;
  display: flex;
  flex-direction: column;
}
.sound-control4{
  position: absolute;
  left: -10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  img{
    margin-left: 13px;
}
}
.soundediv1{
  position: absolute;
  left: 40px;
  top: 100px;
  img{
    height: 250px;
  }
}
.newcircle{
  display: flex;
  width: 140.366px!important;
  height: 28px !important;
  padding: 7.018px 10.527px !important;
  align-items: center;
  color: #393A3C;
text-align: center;
font-size: 12px !important;

}
.btns1{
  display: flex;
  width: 100px !important;
  padding: 5.615px 11.931px!important;
  justify-content: center;
  align-items: center;
  font-size: 7.72px;
  letter-spacing: 0.386px;
  text-transform: uppercase;
  border: 0px solid #000;
  border-radius: 21.055px;
}
.inputnew3{
  display: flex;
  width: 277.866px;
  height: 50.33px;
  padding: 7.018px 10.527px;
  align-items: center;
  border-radius: 21.055px;
  box-shadow: 2.80732px 2.80732px 5.61464px 0px #889FB6 inset, -2.10549px -2.10549px 7.0183px 0px #FFF inset;
  color: #7F838F;
  text-align: center;
  font-size: 15.83px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 18.996px */
}

.newform{
  h3{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0!important;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  h4{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  h5{
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Lao MN;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0px 0;
  }
}
.starrateing{
  span{
    // transform: rotate(45deg);
    text-shadow: 0px 0px 3px #B7C7D7;
  }
  div{
    justify-content: center;
  }
}
.z-1{
  z-index: 1;
}
.z-3{
  z-index: 3;
}
.livebtn{
  position: absolute;
  top: 25px;
  right: -40px;
  padding: 5px 0px 5px 0px;
  justify-content: flex-end;
  align-items: center;
  border-radius: 15px;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 90px;
  height: 24px;
  div{
    width: 50%;
  }
  .active{
    position: relative;
    &::after{
      display: flex;
      padding: 8px 12px;
      width: 45px;
      top:-10px;
      align-items: flex-start;
      border-radius: 20px;
      background:#202020;
      content: "Live";
      position: absolute;
      left: 0;
      box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
      height: 30px;
      color: #FFF;
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &::before{
      display: flex;
      width: 16px;
      top:-13px;
      right: -10px;
      content: "";
      position: absolute;
      height:16px;
      background:url("../images/audio/iconplay.png") no-repeat;
      background-size: contain;
      z-index: 1;
    }
  }
}
.h-fit{
  height: fit-content;
}
.start-6{
  left: 10px;
}

.gifimg{
  position: absolute;
  left: -12px;
  bottom: 14px;
}
.loghover{
  opacity: 0;
  position: absolute;
  width: 300px;
  height: 400px;
  top: 0;
  left: 0;
  background: var(--page-background);
  z-index: 16;
  display: none;
  transition:.4s ease-in-out;
  img.logtop{
    height: 350px;
  }
  .gifimg{
    left: 25px;
    bottom: 110px;
  }
}
.logosite{
  &:hover{
    .loghover{
      opacity: 1;
      display: inline-block;
      transition:.4s ease-in-out;
    }
  }
}

/* social */

.dropopen{
  position: absolute;
  inset: auto auto 0px 0px!important;;
  margin: 0px!important;;
  transform: translate(55px, -44px)!important;
  display:inline-block;
}
.dropopen1{
  position: absolute;
  inset: auto auto 0px 0px!important;;
  margin: 0px!important;;
  transform: translate(0px, -46px)!important;
  display:inline-block;
}
.newsocial{
  position: relative;
  &::after{
    content: "";
    width: 66px;
    height: -webkit-fill-available;
    background: url("../images/social/img2.png") no-repeat;
    background-size: 100% 100%;
    position:absolute;
    top: 45px;
    right:-12px;
    bottom: -60px;
  }
}
.tophead{
  display: flex;
  width: 80%;
  height: 63.2px;
  justify-content: center;
  align-items: center;
  gap: 5.866px;
  flex-shrink: 0;
  position: relative;
  &::after{
    content: "";
    width: 100%;
    height: 63px;
    background: url("../images/social/img1.png") no-repeat;
    background-size: 100% 100%;
    position:absolute;
    top: -40px;
    left: 0;
    z-index: 0;
  }
  .inrhead{
    width: 100%;
    height: 63.2px;
    border-radius: 4.228px 4.228px 0px 0px;
    border: 2.579px dashed var(--main-grey, #EFF4F8);
    background:var(--goldenlinear);
    box-shadow: -1.17315px -1.17315px 2.34631px 0px #FFF, 2.93288px 2.93288px 5.86576px 0px #B7C7D7;
    color: #1C1C1C;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 5.866px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    line-height: 63px;
    z-index: 2;
    @media (max-width:1366px) and (min-width: 992px){ 
      font-size: 20px;
    }
  }
}
.downarrow{
  border: 2.579px dashed #ddd;
  &::after{
    content: "";
    position: absolute;
    background: url('../images/social/down.png') no-repeat;
    width: 79px;
    height: 44px;
    display: inline-block;
    bottom: -44px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.socialdown{
  display: flex;
  width: 100%;
  padding: 10px 5px 20px 5px;
  align-items: center;
  border-radius: 3.659px;
  border: 3.659px dashed #ddd;
  background: var(--page-background);
  box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF inset, 7.31801px 7.31801px 14.63601px 0px #B7C7D7;
}
.newannous1 {
  background: url("../images/social/img3.png") no-repeat;
  background-size: 100% 100%;
  height: 196px;
  padding: 15px;
  width: 250px;
  h6{
    color: #48494D;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    width: 125px;
  }
}
.announcment1{
  position: relative;
  &::after{
    content: "";
    position: absolute;
    left: 34px;
    top: 55px;
    background: url("../images/social/img6.png");
    width: 11px;
    height: 270px;
  }
  .newform{
    display:flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 20px;
    // align-items: center;
    // background: url('../images/bgform.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 3.659px;
    border: 3.659px dashed var(--main-grey, #EFF4F8);
    background: var(--BEST-WHITE, #EFF4F8);
    box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF inset, 7.31801px 7.31801px 14.63601px 0px #B7C7D7;
  }
  label{
    background: var(--goldenlinear);
    border-radius: 1.665px;
    border: 1.665px dashed var(--main-grey, #EFF4F8);
    box-shadow: -1.66477px -1.66477px 3.32954px 0px #FFF, 4.16192px 4.16192px 8.32384px 0px #B7C7D7;
    min-width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
    color: var(--dark, #1C1C1C);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    position: relative;
    z-index: 2;
    width: 160px;
  }
  input{
    color: #877E7E;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 15px;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border: 3.659px dashed var(--main-grey, #EFF4F8);
  }
  .nav-item2{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: -19px;
      top: -9px;
      width: 50px;
      height: 67px;
      background-size: contain;
    }
  }
  .nav-item4{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: 22px;
      top: 17px;
      width: 50px;
      height: 77px;
      background-size: cover;
    }
  }
  .nav-item3{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after1.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: 0px;
      top: 36px;
      width: 72px;
      height: 170px;
      background-size: cover;
    }
  }
  .newannous {
    background: url("../images/bg-new4.png") no-repeat;
    background-size: 100% 100%;
    height: 115px;
    padding: 15px;
    h6{
      color: #48494D;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      width: 108px;
      height: 67.438px;
    }
  }

}
.announce3{
  color:#000;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  display: flex;
  width: 100%;
  height: 57px;
  padding: 0px 8.324px;
  justify-content: center;
  align-items: center;
  border-radius: 1.665px;
  // border: 2px dashed #ddd;
  position: relative;
  background: url('../images/social/img5.png') no-repeat;
  background-size: 100% 107%;
  &::after{
    background: url('../images/li-after.png') no-repeat;
    background-size: contain;
    content: "";
    position: absolute;
    display: inline-block;
    right: -19px;
    top: -6px;
    width: 50px;
    height: 67px;
    background-size: contain;
    z-index: -1;
  }
}
.icon67{
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: var(--BEST-WHITE, #EFF4F8);
  box-shadow: -4px -4px 8px 0px #FFF, 4px 4px 8px 0px #C9D9E8;
  display:flex;
  justify-content: center;
  align-items: center;
}
.newdrop9{
  width: 300px;
  height: 60px;
  display: flex;
  background: var(--goldenlinear);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
  &::after{
    content: "";
    position: absolute;
    left: 25px;
    bottom: -25px;
    background: url("../images/social/img4.png") no-repeat;
    background-size: contain;
    width:37px;
    height:25px;
    display: inline-block;
  }
  .dropbtn9{
    display: flex;
    width: 280px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background: var(--page-background);
    border-radius: 30px;
    button{
      display: flex;
      width: 265px;
      height: 30px;
      padding: 0px 19px;
      align-items: center;
      background: var(--goldenlinear);
      border: 0px solid #000;
      border-radius: 30px;
      color: #000;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: -0.32px;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
.pr-5{
  margin-left: -24px!important;
}
.announment6{
  position: relative;
  &::after{
    content: "";
    background: url("../images/audio/lidot.png") no-repeat;
    width: 32px;
    height: 33px;
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 12px;
    z-index: 1;
  }
  &::before{
    content: "";
    background: url("../images/social/img7.png") no-repeat;
    width: 50px;
    height: 18px;
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 18px;
    z-index: 0;
  }
}
.announmentLin{
  position: relative;
  &::before{
    content: "";
    background: url("../images/social/img7.png") no-repeat;
    background-size:100% 100%;
    width: 120px;
    height: 18px;
    display: inline-block;
    position: absolute;
    top: 20px;
    left: -39px;
    z-index: 0;
  }
}
.downbnt{
  .dropdown-menu{
    padding-bottom: 15px;
  }
  .newdrop10{
    width: 78px;
    height: 78px;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 13px;
    img{
      width: 100%;
    }
    &::after{
      content: "";
      position: absolute;
      left: 20px;
      bottom: -19px;
      background: url("../images/social/img4.png") no-repeat;
      background-size: contain;
      width:37px;
      height:25px;
      display: inline-block;
    }
  }
}
.videoverlay{
  border-radius: 50px;
background: var(--grey-8, #F0F4F8);
padding: 20px;
box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70) inset, -6px -6px 15px 0px #FFF inset;
}
.videoplayer2{

  // padding-top: 50px;
  .topbtn{
    height: 60px;
    width: 100%;
    color: #000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    border-radius:8px ;
    line-height: 166%; /* 76px */
    text-transform: capitalize;
    transform: rotate(-4.084deg);
    position: relative;
    z-index: 1;
    top:-9px;
  }
  .iconaudio img{
    width: 112px;
  }
  .livebtn{
    top:43px;
    right: -31px;
  }
  .iconaudio {
    position: absolute;
    right: 0px;
    top: -63px;
    z-index: 2;
  }
  .videobox{
    // padding:20px 0px 20px;
    padding:10px 0px 10px;
  }
}
.VideoIcon{
  cursor: pointer;
}
.video-container{
  font-size: 10px;
  }
  .videop{
    width: 100%;  
    height: 100%; 
    
   border-radius: 150px;
  background-color:"black";
    z-index: 9999;
  }
  .theater-mode {
    width: 100%;  
    height: 100%; 
    margin: auto;
   border-radius: 150px;
   

    z-index: 9999;
    

  }
  #overlay{
    display: none !important;
  }
  .mini-player {
    position: fixed;
    
    right: 10px;
    width: 300px; /* Adjust the width as needed */
    height: 20vh;
    border: 1px solid #ccc;
  bottom: 5px;
    border-radius: 10px;
    z-index: 9999;
  }
  
  .mini-player video {
    width: 100%;
    border-radius: 10px;
    height: 100%;
  }
  .mini-player2 {
    width: 400px; 
    height: 10vh; 
    position: fixed;
    background-color: black;
    bottom: 5px; 
    right: 5px; 
    z-index: 9999; 
  }
  // .slider{
  //   width: 20px; 
  //   height: 200px; 
  //   position: relative;
   
    
  // }
  
  .progressbar {
    border: 1px solid var(--glass-stroke-1, #FFF);
    background: var(--glass-fill-4, linear-gradient(159deg, rgba(255, 255, 255, 0.70) 3.01%, rgba(255, 255, 255, 0.02) 103.3%));

    backdrop-filter: blur(10px);
    height: 10px;
    width: 100%;
    border-radius: 10px;

    .progressinner {
      background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
      width: 0%;
      height: 100%;
      border-radius: 10px;
      position: relative;

      &::before {
        content: '';
        width: 27px; /* Adjust the size of the circle */
        height: 30px; /* Adjust the size of the circle */
        // border-radius: 50%; /* Make it a circle */
        background-image: url('../images/progressunion.png'); /* Circle background color */
       position: absolute;
        top: -5px; /* Adjust the vertical position as needed */
       left:100%; 
        animation: moveCircle linear forwards;
        animation-play-state: paused;
      }
    }

    .progress-inner {
      animation: animate-bar linear forwards;
      animation-play-state: paused;
    }
  }

@keyframes animate-bar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes moveCircle {
  from {
    right: 0%;
  }
  to {
    right: 100%; /* Move the circle to the end of the progress bar */
  }
}

.volume-control22 {
  height: 140px;
  width: 20px;
position: relative;
border-radius: 10;
input[type="range"].win10-thumb {
  --thumb-height: 0.875em;
  --thumb-width: 0.5em;
  padding: 0;
}

input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-thumb {
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;
  -webkit-appearance: none;
  background: gray;
  box-shadow: var(--box-fill);
  color: gray;
}
  
  input[type="range"] {
    

    width: 129px; /* Adjust the width as needed */
    height: 12px;
    top:57px;
    right:48px; /* Adjust the height as needed */
    
    // background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    border: 10px  var(--glass-stroke-1, #FFF);
    background: var(--glass-fill-4, linear-gradient(159deg, rgba(255, 255, 255, 0.70) 3.01%, rgba(255, 255, 255, 0.02) 103.3%));
    
    backdrop-filter: blur(10px);    border-radius: 25px;
    appearance: none;
    cursor: pointer;
   

  
   

   

    &::-moz-range-thumb {
      width: 12px; /* Adjust the thumb size as needed */
      height: 12px; /* Adjust the thumb size as needed */
      background: #333;
      border: 1px solid #333;
      border-radius: 50%;
      appearance: none;
    }
  }
}
.volume-control {
  height: 195px;
  width: 20px;
position: relative;
border-radius: 10;

  
  input[type="range"] {
    

    width: 194px; /* Adjust the width as needed */
    height: 16px;
    top:87px;
    right:80px; /* Adjust the height as needed */
    color: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    outline:none;
    // background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    border: 10px  var(--glass-stroke-1, #FFF);
    background: var(--glass-fill-4, linear-gradient(159deg, rgba(255, 255, 255, 0.70) 3.01%, rgba(255, 255, 255, 0.02) 103.3%));
    
    backdrop-filter: blur(10px);    border-radius: 25px;
    appearance: none;
    cursor: pointer;
    &::-webkit-slider-runnable-track {
      width: 5px;
      height: 5px;
      background: var(--glass-fill-4, linear-gradient(159deg, rgba(255, 255, 255, 0.70) 3.01%, rgba(255, 255, 255, 0.02) 103.3%));
    }

    &::-webkit-slider-thumb {
      width: 27px; /* Adjust the thumb size as needed */
      height: 10px; /* Adjust the thumb size as needed */
      background-image: url('../images/volumeunions.png');
      background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
     
      appearance: none;
    }
    &::-moz-rang-track{
      background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));

    }
   

    /* For Firefox */
    &::-moz-range-thumb {
      background: yellow; /* Change the color here */
    }

    &::-moz-range-thumb {
      width: 12px; /* Adjust the thumb size as needed */
      height: 12px; /* Adjust the thumb size as needed */
      background: #333;
      border: 1px solid #333;
      border-radius: 50%;
      appearance: none;
    }
  }
}
.volume-control-mini {
  height: 195px;
  width: 20px;
position: relative;
border-radius: 10;

  
  input[type="range"] {
    

    width: 84px; /* Adjust the width as needed */
    height: 16px;
    top:87px;
    right:80px; /* Adjust the height as needed */
    color: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    outline:none;
    // background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    border: 10px  var(--glass-stroke-1, #FFF);
    background: var(--glass-fill-4, linear-gradient(159deg, rgba(255, 255, 255, 0.70) 3.01%, rgba(255, 255, 255, 0.02) 103.3%));
    
    backdrop-filter: blur(10px);    border-radius: 25px;
    appearance: none;
    cursor: pointer;
    &::-webkit-slider-runnable-track {
      width: 5px;
      height: 5px;
      background: var(--glass-fill-4, linear-gradient(159deg, rgba(255, 255, 255, 0.70) 3.01%, rgba(255, 255, 255, 0.02) 103.3%));
    }

    &::-webkit-slider-thumb {
      width: 27px; /* Adjust the thumb size as needed */
      height: 10px; /* Adjust the thumb size as needed */
      background-image: url('../images/volumeunions.png');
      background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
     
      appearance: none;
    }
    &::-moz-rang-track{
      background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));

    }
   

    /* For Firefox */
    &::-moz-range-thumb {
      background: yellow; /* Change the color here */
    }

    &::-moz-range-thumb {
      width: 12px; /* Adjust the thumb size as needed */
      height: 12px; /* Adjust the thumb size as needed */
      background: #333;
      border: 1px solid #333;
      border-radius: 50%;
      appearance: none;
    }
  }
}

.volume-control2{
  height: 195px;
  width: 20px;
position: relative;
border-radius: 10;
  input[type="range"].win10-thumb {
    --thumb-height: 0.875em;
    --thumb-width: 0.5em;
    padding: 0;
  }

  input[type="range"],
  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type="range"]::-webkit-slider-thumb {
    --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
      100vmax currentColor;
    -webkit-appearance: none;
    background: gray;
    box-shadow: var(--box-fill);
    color: gray;
  }
}
.volume-control-mini{
  height: 195px;
  width: 20px;
position: relative;
border-radius: 10;
  input[type="range"].win10-thumb {
    --thumb-height: 0.875em;
    --thumb-width: 0.5em;
    padding: 0;
  }

  input[type="range"],
  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type="range"]::-webkit-slider-thumb {
    --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
      100vmax currentColor;
    -webkit-appearance: none;
    background: gray;
    box-shadow: var(--box-fill);
    color: gray;
  }
}

.thumb-image {
  position: absolute;
  right: -70px;
  top: 80px;
  z-index: 0;
  cursor: pointer;
  left: 7px;
  height: 100px; 
  width: 20px;

  img {
    width: 100%; /* Adjust this as needed */
  }
}
/* VolumeSlider.css or VolumeSlider.scss */
.volume-control {
  input[type="range"].win10-thumb {
    --thumb-height: 0.875em;
    --thumb-width: 0.5em;
    padding: 0;
  }
  input[type="range"],
  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type="range"]::-webkit-slider-thumb {
    --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
      100vmax currentColor;
    -webkit-appearance: none;
    background:gray;
    box-shadow: var(--box-fill);
    color: gray;
  }
}

.thumb-image {
  position: absolute;
  right: -70px;
  top: 80px;
  z-index: 0;
  cursor: pointer;
  left: 7px;
  height: 100px; 
  width: 20px;

  img {
    width: 100%; /* Adjust this as needed */
  }
}
.inner2{
  &::before{
    content: none!important;
  }
}
.top-25{
  top:28px;
}
.closebtn2{
  position: absolute;
  right: 4px;
  top: -8px;
  z-index: 9;
  cursor: pointer;
}
.topvid41{
  box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
  padding: 9px;
  background: var(--page-background);
  border-radius: 50px;
  // margin: 70px auto;
  width: 326px;
  height: 190px;

}
.close-button {
  position: absolute;
  top: 104px;
  right: 35px;
  background-color: transparent;
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  z-index: 60;
img{
width: 25px;
cursor: pointer;
}
}
.close-button2 {
  position: absolute;
  top: 305px;
  left:255px;
  background-color: transparent;
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  z-index: 60;
img{
width: 25px;
cursor: pointer;
}
}
.vol-btn {
  position: absolute;
  top:104px;
  left: 35px;
  
  background-color: transparent;
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  z-index: 60;
img{
width: 20px;
cursor: pointer;
}
}
.vol-btn2 {
  position: absolute;
  top:307px;
  left: 40px;
  
  background-color: transparent;
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  z-index: 60;
img{
width: 20px;
cursor: pointer;
}
}
.miniplay{
  svg{
    position: relative;
    top:30px;
  height: 50px;
  width: 50px;
  }
}
.miniplay2{
  svg{
    position: relative;
  height: 50px;
  width: 50px;
  right: 65px;
    top: 128px;
  }
}


.minvid{
  height: 100%;
  width: 100%;
  border-radius: 50px;
}
.full-screen1 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  justify-content: center;
  align-items: center;
 /* Adjust as needed */
  .topvid41{
    box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
    padding: 21px;
    background: var(--page-background);
    border-radius: 100px;
    // margin: 70px auto;
    width: 100%;
    height: 100%;

  }
}
.full-screen1 video {
  // background: var(--page-background);
  // border-radius: 100px;
  // box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
  box-shadow: 4px 4px 8px var(--shodow-drop-dark), -4px -4px 8px var(--shadow-light-50)!important;
  margin-top: 0px;
  // padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
  .full-width {
    width: 100%;
  }
}
.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  justify-content: center;
  align-items: center;
 /* Adjust as needed */
  .topvid4{
    box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
    padding: 21px;
    background: var(--page-background);
    border-radius: 100px;
    // margin: 70px auto;
    width: 100%;
    height: 85%;

  }
}
/* Style the video element within the full-screen container */
.full-screen video {
  // background: var(--page-background);
  // border-radius: 100px;
  // box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
  box-shadow: 4px 4px 8px var(--shodow-drop-dark), -4px -4px 8px var(--shadow-light-50)!important;
  margin-top: 0px;
  // padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
  .full-width {
    width: 100%;
  }
}

.fullaudio{
  background-color: var(--BEST-WHITE, #EFF4F8);
  border-radius: 230px;
  box-shadow: inset 0px -4px 8px var(--shadow-light-50), inset 0px 4px 6px var(--shadow-light);
  margin-top: 70px;
  padding:-20px;
  width: 80%;
  height: 80%;
}
.videoControlls{
  width: 1600px;
  margin-bottom: 50px;
}
.videoControlls2{
  width: 1100px;
  margin-bottom: 150px;
}
.symble{
  h5, h6{
    letter-spacing: 0.01em;
    box-shadow: 0px 0px 0px #C9D9E8, -0px -0px 0px #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
.shadowTextCustom{
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Lao MN;
}
.positionVideo{
  position:relative;
  bottom:570px;
  // top:15px;
  right:0;
  left: 0;
  margin: 0 auto;
  z-index: 205;
 
  // position: relative;
  // right: 0;
  // margin-left: auto;
  // margin-top:-68px;
}

#gradient-bar{
  display: none;
  margin-top: 0!important;
}
#rbgcp-wrapper{
  div{
    margin: 0!important;
    height: 18px;
    canvas{
      width: 100%;
      height: 18px;
      border-radius: 5px!important;
    }
    &:nth-child(1){
      display: none!important;
    }
  }
}
// #gradient-bar ~ div{
//   display: none!important;
// }
.h-304{
  height: 302px;
}
.h-310{
  height: 306px;
}
.br-45{
  border-radius: 45px;
}
.br-8s{
  border-radius: 8px;
  width: max-content;
  margin: 0 auto;
  position:relative;
  &::after{
  position:absolute;
  left: 0;
  content:"";
  width:480px;
  right: 0;
  top:0;
  margin: auto;
  bottom:0;
  height:13px;
  background:url("../images/colorbg.png");
  display:inline-block;
}
}
.mini-videop{
  width: 100%;
  height:500px;
  background-color: black;
  border-radius: 150px;
}
.mini-controls{
  width: 100%;
  height: 200px;
  background-color: gray;
  border-radius: 20px;
}
// .scrollpage{
//   .width-form-set{
//     overflow-y: scroll;
//     height: 100vh;
//     overflow-x: hidden;
//   }
// }
.glob-track{
  
  border-radius: 5px;
  background: var(--BEST-WHITE, #EFF4F8);
  
  /* out shadow */
  box-shadow: -4px -4px 8px 0px #FFF, 4px 4px 8px 0px #B7C7D7;
  // height:800px;
  
}
.glob-img{
  position:relative;
  top:35px
}
.audio-head{
  position: relative;
  width: 460px;
  top: 171px;
    right: 65px;
}
.mini-pod{
  display: flex;
  position: absolute;
  bottom: 199px;
  left: 43px;
  // right:64 !important;
  // top:160 !important;
    width: 237px;
    height: 53px;
    padding: 10px;
    
    align-items: center;
    justify-content: center;
    margin: 0 auto 18px;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 30px;
    background: var(--gradient, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
    box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
    span{
      width: 227px;
      height: 40px;
      border-radius: 30px;
      justify-content: center;
      align-items: center;
      background: var(--page-background);
    }
}



.topheadGlob{
  display: flex;
  width: 80%;
  height: 63.2px;
  justify-content: center;
  align-items: center;
  gap: 5.866px;
  flex-shrink: 0;
  position: relative;
  margin-bottom:-20px;
  &::after{
    content: "";
    width: 100%;
    height: 63px;
    background: url("../images/social/img1.png") no-repeat;
    background-size: 100% 100%;
    position:absolute;
    top: -40px;
    left: 0;
    z-index: 0;
  }
  .inrhead{
    width: 100%;
    height: 63.2px;
    border-radius: 4.228px 4.228px 0px 0px;
    border: 2.579px dashed var(--main-grey, #EFF4F8);
    background:var(--goldenlinear);
    box-shadow: -1.17315px -1.17315px 2.34631px 0px #FFF, 2.93288px 2.93288px 5.86576px 0px #B7C7D7;
    color: #1C1C1C;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 5.866px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    line-height: 63px;
    z-index: 2;
    @media (max-width:1366px) and (min-width: 992px){ 
      font-size: 20px;
    }
  }
}
.customDrop2s{
  &::before{
    content: none;
  }
  .dropicn{
    min-width: 34px;
    height: 34px;
    box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 10px;
    background: var(--page-background);
    span{
      min-width: 25px;
      height: 25px;
      box-shadow: inset -4px 8px 13px var(--shadow-light), inset -1px -8px 13px var(--shadow-light-50);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--page-background);
      img{
        object-fit: contain;
      }
      svg{
        width: 14px;
        height: 14px;
      }
    }
  }
  .dropheight {
    overflow: visible;
    max-height: none;
    li{
      display: flex;
      padding-left: 50px;
      align-items: center;
      &::after{
        right: inherit;
        left: 265px;
      }
    }
  }
  .spansec {
    margin-left: 0!important;
    justify-content: flex-start!important;
    h6{
      font-size: 14px;
      text-transform: uppercase;
      text-align: left;
    }
    input{
      font-size: 14px;
      text-transform: uppercase;
      text-align: left;
      padding: 0;
    }
  }
  .spansec1 {
    background: url("../images/dropdown/img10.png") no-repeat!important;
    background-size: 233px 40px!important;
    background-position: center center!important;
  }
  .spansec2 {
    background: url("../images/dropdown/img10.png") no-repeat!important;
    background-size: 233px 53px!important;
    background-position: center center!important;
  }
  .sideBtn{
    button{
      width: 105px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin:0 3px;
      border: 0px solid #000;
      color: #1C1C1C;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Lao MN;
      font-size: 12px;
      font-style: normal;
      font-weight: 250;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
}
.droptop{
  .menu{
    right: -418px;
      .newdrop10{
          width: 885px;
          padding: 62px 5px 5px 10px;
          flex-direction: column;
          align-items: center;
          border-radius: 10px;
          background:var(--page-background);
          box-shadow: 4px 4px 8px 0px #889FB6, -5px -5px 8px 0px #FFF;
      }
  }
}
.top-50s{
  top:50px;
}
.bgLine6{
  position: relative;
  &::after{
    content: "";
    background: url("../images/dropdown/img8.png") no-repeat;
    width: -webkit-fill-available;
    height: 34px;
    display: inline-block;
    top: 5px;
    bottom: 0;
    left: 19px;
    margin: auto 0;
    position: absolute;
  }
}
.bglinearrow{
   &::before{
    content: "";
    background: url("../images/dropdown/img11.png") no-repeat;
    background-size: contain;
    width: 90px;
    height: 40px;
    display: inline-block;
    top: -32px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
  }
}
.dropNew{
  padding-top: 35px;
  input{
    display: flex;
    width: 650px;
    height: 55px;
    padding: 10px;
    align-items: center;
    border-radius: 3.659px;
    border-top: 3.659px solid var(--main-grey, #EFF4F8);
    background: var(--page-background);
    box-shadow: 0px 4px 6px 0px #A0B9D0 inset, 0px -4px 8px 0px #FFF inset;
    position: relative;
    z-index: 1;
    margin:10px 40px 10px 0px;
  }
  img.dot{
    position: relative;
    z-index: 1;
    height: 30px;
    width: 30px;
  }
  ul{
    position: relative;
    &::after{
      position: absolute;
      left: 28px;
      top: 0;
      height: 100%;
      width: 15px;
      content: "";
      background: url("../images/dropdown/img7.png") no-repeat;
    }
    li{
      position: relative;
      margin: 30px 0;
      z-index: 1;
      &::after{
        content: "";
        background: url("../images/li-after.png") no-repeat;
        position: absolute;
        right: 6px;
        top: -14px;
        bottom: -8px;
        width: 63px;
        height: -webkit-fill-available;
        display: inline-block;
        background-size: 100% 100%;
      }
      .icon{
        width: 60px;
        position: relative; 
        z-index: 1;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 183.333px;
        background: var(--page-background);
        box-shadow: -7.33333px -7.33333px 14.66667px 0px #FFF, 7.33333px 7.33333px 14.66667px 0px #B7C7D7;
        span{
          display: flex;
          width: 33px;
          height: 33px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 9.458px;
          flex-shrink: 0;
          border-radius: 945.767px;
          background: var(--goldenlinear);
          box-shadow: -3.78307px -3.78307px 7.56614px 0px #FFF, 3.78307px 3.78307px 7.56614px 0px #B7C7D7;
        }
      }
      h6{
        color: #848484;
        font-family: Lao MN;
        font-size: 18px;
        font-style: normal;
        font-weight: 250;
        line-height: normal;
        text-transform: capitalize;
        display: flex;
        width: 600px;
        height: 55px;
        padding: 10px;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 3.659px;
        border: 3.659px dashed var(--main-grey, #EFF4F8);
        background: var(--main-grey, #EFF4F8);
        box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF, 7.31801px 7.31801px 14.63601px 0px #B7C7D7;
        position: relative;
        z-index: 2;
        margin-right: 40px;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
}
.btmBtn9{
  button{
    display: flex;
    width: 161px;
    height: 49px;
    padding: 13.513px 5px;
    align-items: center;
    border-radius: 40.539px 0px 0px 40.539px;
    background: var(--page-background);
    box-shadow: -4px -4px 8px 0px #FFF, 4px 4px 8px 0px #B7C7D7;
    color: #48494D;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Lao MN;
    font-size: 21.621px;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    line-height: 120%; /* 25.945px */
  }
  span{
    display: inline-flex;
    height: 49px;
    padding: 13px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 40.54px 40.54px 0px;
background: var(--page-background);
box-shadow: 5.40522px 5.40522px 10.81044px 0px #889FB6 inset, -4.05391px -4.05391px 13.51304px 0px #FFF inset;
  }
}
.max-h-auto{
  max-height: fit-content!important;
}
.bg-theme {
  button{
    background: var(--page-background)!important;
  }
}

.announcment1s{
  .newform{
    display:flex;
    flex-direction: column;
    width: 100%;
    padding: 30px 20px;
    // align-items: center;
    // background: url('../images/bgform.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 3.659px;
    border: 3.659px dashed var(--main-grey, #EFF4F8);
    background: var(--BEST-WHITE, #EFF4F8);
    box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF inset, 7.31801px 7.31801px 14.63601px 0px #B7C7D7;
  }
  label{
    background: var(--goldenlinear);
    border-radius: 1.665px;
    border: 1.665px dashed var(--main-grey, #EFF4F8);
    box-shadow: -1.66477px -1.66477px 3.32954px 0px #FFF, 4.16192px 4.16192px 8.32384px 0px #B7C7D7;
    min-width: 140px;
    height: 45px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
    color: var(--dark, #1C1C1C);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    position: relative;
    z-index: 2;
    width: 140px;
  }
  .w-custom{
    width: 62%;
  }
  .w-72{
    width: 72%;
  }
  input{
    color: #877E7E;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 12px;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border: 3.659px dashed var(--main-grey, #EFF4F8);
  }
  .nav-item2{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: 19px;
      top: -10px;
      bottom: -6px;
      width: 50px;
      height: -webkit-fill-available;
      background-size: 100% 100%;
    }
  }
  .nav-item6{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: 19px;
      top: -10px;
      bottom: -10px;
      width: 50px;
      height: -webkit-fill-available;
      background-size: 100% 100%;
    }
  }
  .inputspan {
    height: 45px;
}
  .nav-item4{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: 22px;
      top: 17px;
      width: 50px;
      height: 77px;
      background-size: cover;
    }
  }
  .nav-item3{
    position: relative;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after1.png") no-repeat;
      position: absolute;
      display: inline-block;
      right: 0px;
      top: 36px;
      width: 72px;
      height: 170px;
      background-size: cover;
    }
  }
  .newannous {
    background: url("../images/bg-new4.png") no-repeat;
    background-size: 100% 100%;
    height: 115px;
    padding: 15px;
    h6{
      color: #48494D;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      width: 108px;
      height: 67.438px;
    }
  }
}
.custom-end{
  right: -10px;
  height: 38px;
  border-radius: 50%!important;
  fill: #777;
}
.custom-end1{
  right: -20px;
  height: 38px;
  border-radius: 50%!important;
  fill: #777;
}
.custom-end2{
  height: 38px;
  border-radius: 50%!important;
  fill: #777;
}
.customDrop6{
  width: 300px;
  max-height: 415px;
  padding: 5px 0px 5px;
  flex-direction: column;
  border-radius: 13.061px;
  background: #EFF4F8;
  box-shadow: -2.9579286575317383px -2.9579286575317383px 5.915857315063477px 0px #FFF, 2.9579286575317383px 2.9579286575317383px 5.915857315063477px 0px #B7C7D7;
  border: 0px solid #000;
  z-index: 1;
  .dropheight{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      left: 12px;
      top:25px;
      height:-webkit-fill-available;
      width: 5px;
      display: inline-block;
      background: url("../images/dropdown/img7.png") no-repeat;
      bottom: 220px;
      z-index: -1;
    }
  }
  li{
    margin-bottom: -8px;
    width: 100%;
    height:auto;
    position: relative;
    margin: 15px 0;
    .dropdiv{
      cursor: pointer;
    }
    .spansec{
      width: 263px;
      min-width: 263px;
      height: fit-content;
      display: inline-block;
      background: url(../images/li-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      position: relative;
      z-index: 1;
      right: 12px;
      padding: 18px 15px;
    }
    .dropdiv {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Lao MN;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 2px 10px 5px;
      text-transform: capitalize;
      // background: linear-gradient(180deg, #6B6666 0%, #585454 100%);
      // background-clip: text;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      text-align: center;
      // &:focus{
      //   box-shadow: 0px 0px 0px 0px #000!important;
      // }
    }
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      right: 6px;
      top: 6px;
      bottom: 6px;
      width: 37px;
      height: -webkit-fill-available;
      display: inline-block;
      background-size: 100% 100%;
    }
    &::before{
      content: "";
      background: url("../images/li-before.png") no-repeat;
      position: absolute;
      left: 0;
      top: 20px;
      width: 163px;
      height: 82px;
      display: inline-block;
    }
    &:last-child{
      &::before{
        content: "";
        background: url("../images/li-before.png") no-repeat;
      }
    }
  }
}
.btmSeo{
  &::after{
    bottom:50px!important;
  }
}

.integTab4 {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 90%;
    display: inline-block;
    background:url("../images/ul-after.png") no-repeat;
    background-size: 100% 100%;
    z-index: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 25px;
  }
}
.integTab5 {
  position: relative;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 500px;
    display: inline-block;
    background:url("../images/ul-after1.png") no-repeat;
    background-size: 100% 100%;
    z-index: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 25px;
  }
}
.pt-100{
  margin-top: 105px;
}
.pt-150{
  padding-top: 150px;
}
.h-177{
  height: 179px;
}
.h-183{
  height: 183px;
}
.w-170{
  width: 170px;
}
.videoplayer3{
  width: 325px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  .topbtn{
    font-size: 24px;
    height: 45px;
  }
}

.dropNew21{
  .bgLine7{
    position: relative;
    display: flex;
    padding-right: 20px;
    &::after{
      content: "";
      background: url("../images/li-after.png") no-repeat;
      position: absolute;
      right: 0px;
      top: -14px;
      bottom: -12px;
      width: 63px;
      height: -webkit-fill-available;
      display: inline-block;
      background-size: 100% 100%;
    }
    &::before{
      content: "";
      background: url("../images/dropdown/img12.png") no-repeat;
      position: absolute;
      left: -100px;
      top: 16px;
      width: 100%;
      height: 23px;
      display: inline-block;
      background-size: 100% 100%;
    }
    h6{
      color: #848484;
      font-family: Lao MN;
      font-size: 18px;
      font-style: normal;
      font-weight: 250;
      line-height: normal;
      text-transform: capitalize;
      display: flex;
      min-width: 100%;
      height: 50px;
      padding: 10px;
      align-items: center;
      justify-content: space-between;
      border-radius: 3.659px;
      border: 3.659px dashed var(--main-grey, #EFF4F8);
      background: var(--main-grey, #EFF4F8);
      box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF, 7.31801px 7.31801px 14.63601px 0px #B7C7D7;
      position: relative;
      z-index: 2;
      margin-right: 20px;
      margin-left: auto;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
  .bgLine8{
    input{
      display: flex;
      width: 522px;
      height: 55px;
      padding: 10px;
      align-items: center;
      border-radius: 3.659px;
      border-top: 3.659px solid var(--main-grey, #EFF4F8);
      background: var(--page-background);
      box-shadow: 0px 4px 6px 0px #A0B9D0 inset, 0px -4px 8px 0px #FFF inset;
      position: relative;
      z-index: 1;
      margin:10px 21px 0px 0px;
    }
  }
  img.dot{
    position: relative;
    z-index: 1;
    height: 22px;
    width: 22px;
    top: 3px;
  }
}
.bgLine8{
  position: relative;
  &::after{
    content: "";
    background: url("../images/dropdown/img12.png") no-repeat;
    background-size: 100% 100%;
    width: 60%;
    height: 23px;
    display: inline-block;
    top: 18px;
    bottom: 0;
    left: -11px;
    margin: auto 0;
    position: absolute;
  }
}
.bglinearrow1{
  &::before{
   content: "";
   background: url("../images/dropdown/img11.png") no-repeat;
   background-size: contain;
   width: 60px;
   height: 30px;
   display: inline-block;
   top: -25px;
   left: 0;
   right: 155px;
   margin: 0 auto;
   position: absolute;
 }
}

.top-65{
  top:65px;
}
.custmToggle {
  .switch {
    position: relative;
    display: inline-block;
    width: 84px;
    height: 35px;
  }  
  .slider1 {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    -webkit-transition: .3s;
    transition: .2s;
    box-shadow: inset 0px -6.85714px 13.7143px var(--shadow-light-50), inset 0px 6.85714px 10.2857px var(--shadow-light);
  }
  .slider1:before {
    position: absolute;
    content: "";
    height: 27px;
    width: 27px;
    left: 4px;
    bottom: 4px;
    background: #666;
    -webkit-transition: .3s;
    transition: .3s;
  }
  .slider1:active {
    background: var(--goldenlinear);
    box-shadow: 1.71429px 1.71429px 1.71429px var(--shadow-light), -1.71429px -1.71429px 1.71429px var(--shadow-light-50);
  }  
  .slider1:active:before {
    -webkit-transform: translateX(46px);
    -ms-transform: translateX(46px);
    transform: translateX(46px);
    background: #EAEFF6;
    box-shadow: 0px 5.32331px 7.98496px 0px #A0B9D0 inset, 0px -5.32331px 10.64662px 0px #FFF inset;
  }
  // .slider1:active:before{
  //   background: #666;
  // }
  /* Rounded sliders */
  .slider1.round {
    border-radius: 34px;
  }
  .slider1.round:before {
    border-radius: 50%;
  }
}
.w-470{
  width: 470px;
}
.colorRange{
  .multi-range-slider{
    border: 0px solid #000!important;
    box-shadow: 0px 0px 0px 0px #000!important;
    padding: 0!important;
  }
  .multi-range-slider .bar-inner{
    border: solid 0px black!important;
    background: linear-gradient(90deg, #68FFDB 0%, #41FF54 16.67%, #FFE91F 32.29%, #FF7D1F 52.6%, #D637E4 73.44%, #FF0E0E 100%)!important;
    box-shadow: 0px 0px 0px 0px #000!important;
    border-radius: 10px;
  }
  .multi-range-slider .bar{
    height: 20px;
    background: var(--page-background)!important;
    border-radius: 5px;
    box-shadow: inset -5px -5px 8px #fff, inset 4px 4px 8px #889fb6!important;
  }
  .multi-range-slider .bar-right, .multi-range-slider .bar-left{
    background: var(--page-background)!important;
    border-radius: 15px;
    box-shadow: inset -5px -5px 8px #fff, inset 4px 4px 8px #889fb6!important;
  }
  .multi-range-slider .bar-left{
    position:absolute
  }

  .multi-range-slider .ruler .ruler-rule {
    border:0px solid #000!important;
    background: url("../images/img200.png") no-repeat;
    background-size:contain;
    padding: 5px 10px;
  }
  .multi-range-slider .ruler{
    justify-content: space-between;
  }
  .multi-range-slider .thumb::before{
    background-image: url("../images/audio/img23.png");
    background-repeat: no-repeat;
    height: 30px!important;
    width: 30px!important;
    background-size: contain;
    border: 0px solid #000!important;
    background-color: initial!important;
    box-shadow: 0px 0px 0px 0px #000!important;
    margin:-6px -26px !important;
  }
}
.mini-players {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 200px; /* Adjust the size as needed */
  height: 150px; /* Adjust the size as needed */
  background-color: #000; /* Adjust background color as needed */
  /* Add styling for mini player controls */
}
.design-one-reg.nav-link, .design-one-reg.nav-link.acitve{
  background-color: #7CBAA7 !important;
  box-shadow: none !important;
}
.social-reguler{
  .banner-one, .slick-list, .banner-two, .banner5, .banner6, .banner-six, .banner7, .banner5s, .banner9{
    background: #CBD5E0!important;
  }
  .slick-slide{
    padding: 0!important;
  }
  .banner5 .banner5inr, .banner5s .banner5sinr{
    background: #CBD5E0!important;
  }
  .banner5 ul, .banner5s ul{
    border-color: #7CBAA7!important;
  }
  .banner6 ul li img, .banner9 ul li img{
    border-color:#7CBAA7!important ;
  }
  .borderdiv{
    border-color: #7CBAA7!important;
    .col-lg-3s, .col-lg-6{
      padding: 0!important;
    }
  }
}
.social-neumorphic{
  .banner-one, .banner-two, .banner5, .banner6, .slick-list, .banner-six, .banner7, .banner5s, .banner9{
    background: #F0F4F8!important;
  }
  .slick-slide{
    padding: 0!important;
  }
  .socialSlider{
    background: #F0F4F8!important;
    box-shadow: -2.880000114440918px -2.880000114440918px 5.760000228881836px 0px #FFF, 2.880000114440918px 2.880000114440918px 5.760000228881836px 0px #B7C7D7!important;
  }
  .banner5 .banner5inr, .banner5s .banner5sinr{
    background: #F0F4F8!important;
  }
  .banner5 ul, .banner5s ul{
    border-color: transparent!important;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      background: url("../images/border-line.png") no-repeat;
      display: block;
      height: 20px;
      background-size: 100% 100%;
    }
    &::before{
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      background: url("../images/border-line.png") no-repeat;
      display: block;
      height: 20px;
      background-size: 100% 100%;
    }
  }
  .borderdiv{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      background: url("../images/border-line.png") no-repeat;
      display: block;
      height: 20px;
      background-size: 100% 100%;
    }
    &::before{
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      background: url("../images/border-line.png") no-repeat;
      display: block;
      height: 20px;
      background-size: 100% 100%;
    }
    .col-lg-3s, .col-lg-6{
      padding:0 3px;
      .boxiner{
      padding: 10px!important;
      background: var(--page-background);
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      }
    }
    .col-lg-12{
      padding:3px 0;
      .boxiner{
      padding: 10px!important;
      background: var(--page-background);
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      }
    }
  }
  .banner6 ul li img, .banner9 ul li img{
    border-color:#F0F4F8!important ;
    box-shadow: -2.880000114440918px -2.880000114440918px 5.760000228881836px 0px #FFF, 2.880000114440918px 2.880000114440918px 5.760000228881836px 0px #B7C7D7!important;
  }
  .banner7{
    img{
      height: 140px!important;
    }
  }
  .h-170{
    height: 140px!important;
  }
}
.social-glassmorphic{
  .banner-one, .banner-two, .banner5, .banner6, .slick-list, .banner-six, .banner7, .banner5s, .banner9{
    background: var(--glass-gradient-1, linear-gradient(315deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%))!important;
  }
  .slick-slide{
    padding: 0!important;
    background: var(--glass-gradient-1, linear-gradient(315deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%))!important;
  }
  .socialSlider{
    background: var(--glass-gradient-1, linear-gradient(315deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%))!important;
    box-shadow: -2.880000114440918px -2.880000114440918px 5.760000228881836px 0px #FFF, 2.880000114440918px 2.880000114440918px 5.760000228881836px 0px #B7C7D7!important;
  }
  .banner5 .banner5inr, .banner5s .banner5sinr{
    background: var(--glass-gradient-1, linear-gradient(315deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%))!important;
  }
  .banner5 ul, .banner5s ul{
    border-color: transparent!important;
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      background: url("../images/border-line.png") no-repeat;
      display: block;
      height: 20px;
      background-size: 100% 100%;
    }
    &::before{
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      background: url("../images/border-line.png") no-repeat;
      display: block;
      height: 20px;
      background-size: 100% 100%;
    }
  }
  .borderdiv{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      background: url("../images/border-line.png") no-repeat;
      display: block;
      height: 20px;
      background-size: 100% 100%;
    }
    &::before{
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      background: url("../images/border-line.png") no-repeat;
      display: block;
      height: 20px;
      background-size: 100% 100%;
    }
    .col-lg-3s, .col-lg-6{
      padding:0 4px;
      .boxiner{
      padding: 10px!important;
      border: 1px solid var(--glass-stroke-2, rgba(255, 255, 255, 0.80));
      background: var(--glass-fill-2, linear-gradient(159deg, rgba(255, 255, 255, 0.30) 3.01%, rgba(255, 255, 255, 0.00) 103.3%));
      backdrop-filter: blur(10px);
      }
    }
    .col-lg-12{
      padding:4px 0;
      .boxiner{
      padding: 10px!important;
      border: 1px solid var(--glass-stroke-2, rgba(255, 255, 255, 0.80));
      background: var(--glass-fill-2, linear-gradient(159deg, rgba(255, 255, 255, 0.30) 3.01%, rgba(255, 255, 255, 0.00) 103.3%));
      backdrop-filter: blur(10px);
      }
    }
  }
  .banner6 ul li img, .banner9 ul li img{
    border: 8px solid var(--glass-stroke-2, rgba(255, 255, 255, 0.4))!important;
    background: var(--glass-fill-3, linear-gradient(159deg, rgba(255, 255, 255, 0.50) 3.01%, rgba(255, 255, 255, 0.05) 103.3%));
    backdrop-filter: blur(10px);
  }
  .banner7{
    img{
      height: 140px!important;
    }
  }
  .h-170{
    height: 140px!important;
  }
}
.imgsec{
  .cursor-pointer{
    fill: var(--page-background);
    filter: drop-shadow(6px 6px 15px rgba(178, 189, 203, 0.70)) drop-shadow(-6px -6px 15px #FFF);
    // filter: drop-shadow(-2.880000114440918px -2.880000114440918px 5.760000228881836px 0px #FFF, 2.880000114440918px 2.880000114440918px 5.760000228881836px 0px #B7C7D7);
  }
}
.cursor-pointer{
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.gradient-box1 {
  position: relative;
  box-sizing: border-box;
  $border: 3px;
  background: var(--page-background) !important;
  background-clip: padding-box!important; /* !importanté */
  border: solid $border transparent !important; /* !importanté */
  border-radius: 1em;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: var(--goldenlinear);
    // background: var(--page-background);
  }
  &:after {
    content: '';
    position: absolute;
    top: 1px; right: 1px; bottom: 1px; left: 1px;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: var(--page-background);
  }
}
.gradient-box2 {
  position: relative;
  box-sizing: border-box;
  $border: 3px;
  background: var(--page-background) !important;
  background-clip: padding-box!important; /* !importanté */
  border: solid $border transparent !important; /* !importanté */
  border-radius: 1em;
  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -5px; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: var(--goldenlinear);
    // background: var(--page-background);
  }
  &:after {
    content: '';
    position: absolute;
    top: 1px; right: 1px; bottom: 1px; left: 1px;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: var(--page-background);
  }
}
.w-1120{
  width: 1120px!important;
}