.design-screen-tab-5 {
    background-color: #CBD5E0;
    width: 768px;
    margin: auto;
    // height: 1075px;

    .h_1010 {
        height: 1010px;
    }

    .toggle_button {
        background: #F0F4F8;
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: #7CBAA7 !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            background: transparent;
            color: #000000;
            text-align: center;
            border: none;
        }

    }

    .content_wrapper {
        background: #CBD5E0;
        border-radius: 25px;
        padding: 20px;
    }
.fs_8{
    font-size: 8px;
}

.basic_sign_up_btn{
    background: #5AA3AD;
}
.standard_sign_up_btn{
    background: #F4B343;
}
.premium_sign_up_btn{
    background: #847F67;
}
.read_more_btn{
    border: 1px solid #F0F4F8;
    color: #F0F4F8;
}
    .main_wrapper {
        background: #7CBAA7;
        border-radius: 40px;
        padding: 10px;

        .plan_outer_wrapper {
            background: #7CBAA7;
            border: 1px dashed #000000;
            border-radius: 35px;
            padding: 20px;


            .plan_wrapper {
                padding: 20px;
                // width: 920px;
                width: 630px;
                height: 772px;
                background: #F0F4F8;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                .plan_outer {
                    width: 190px;
                    height: 610px;
                    background: #304053;
                    border-radius: 20px;
                    position: relative;


                    .plan_content {
                        padding: 80px 15px;
                    }

                    .badge_wrapper {
                        position: absolute;
                        width: 120px;
                        height: 125px;
                        top: -60px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 7px solid #000000;

                        h2 {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 29px;
                            text-align: center;
                            color: #FFFFFF;
                            margin: 0;
                        }

                        p {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: #FFFFFF;
                            margin-bottom: 0;
                        }
                    }

                    .bg_green {
                        background: #5AA3AD;
                    }

                    .bg_amber {
                        background: #F4B343;
                    }

                    .bg_gray {
                        background: #847F67;
                    }

                    .text_green {
                        color: #5AA3AD;
                    }

                    .text_amber {
                        color: #F4B343;
                    }

                    .text_gray {
                        color: #847F67;
                    }

                    .price {
                        h1 {
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 29px;
                            text-align: center;
                            /* color: #5AA3AD; */
                        }

                        p {
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 17px;
                            text-align: center;
                            color: #F0F4F8;
                        }
                    }

                    li {
                        list-style: none;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #F0F4F8;
                        text-align: left;
                        padding: 20px 0;
                        border-bottom: 1px solid #F0F4F8;
                        display: flex;
                        align-items: center;

                        &:last-child {
                            border-bottom: none;
                        }

                        .icon {
                            width: 1.25rem;
                            height: 1.25rem;
                            margin-right: 8px;
                            color: #F0F4F8;
                        }
                    }

                    .footer_wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-top: 4px solid #F0F4F8;
                        width: 100%;
                        height: 46px;
                        background: #304053;
                        border-radius: 0 0 20px 20px;
                        position: absolute;
                        bottom: 0;

                        .btn_wrapper {
                            width: 115px;
                            height: 43px;
                            background: #F0F4F8;
                            position: relative;

                            .btn1 {
                                padding: 10px 25px;
                                width: 115px;
                                height: 63px;
                                // background: #5AA3AD;
                                position: absolute;
                                top: -40px;
                                border: 0;
                                color: #F0F4F8;
                                font-size: 14px;
                                border-bottom: 20px solid #000000 !important;
                            }
                        }
                    }

                }

            }
        }
    }

    .relative {
        position: relative;
    }
}