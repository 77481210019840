@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.main-container {
  height: 100%;
  width: 100%;
  border-radius: 0px;

  margin: 0px auto;
  &.Neumorphic-style {
    background-color: var(--bg-white-secondary-light);
    box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
    border-radius: 20px;
    border: none;
    .after-picturebox {
      color: #202020;
    }
    .sec-p {
      color: #202020;
    }
    .third-p {
      color: #202020;
    }
    .box-image {
      width: 100%;
      height: 100%;
      background-size: contain;
      box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
      border-radius: 15px;
    }
  }
  &.Regular-style {
    background: var(--bg-color-dark);
    .sec-p {
      color: #7cbaa7;
    }

    .box-image {
      width: 100%;
      height: 100%;
      background-size: contain;
    }
  }
  .picture-box {
    width: 43%;
    height: 100%;
    background-color: var(--bg-white-secondary-light);
    align-items: center;
  }

  .after-picturebox {
    width: 45%;
    color: var(--bg-white-secondary-light);
    padding-top: 95px;
    .sec-p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }
    .first-p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
    }
    .third-p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
    }
  }
}
