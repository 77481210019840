.dark {
    .design-screen-desktop-1 .content-text .tab-himself-text {
        overflow: auto;
        padding: 71px 100px ;
    }
    *::-webkit-scrollbar {
        width: 12px;
      }
      .bottom-tabs{
        color: #f0f4f8 !important;
        h6{
            color: #f0f4f8 !important;
        }
      }
      .design-screen-desktop-1 , .design-screen-tab-1 , .design-screen-mobile-1{
        .dark-meu-show{
            display: none!important;
        }
        .navbar-top .timeline:after {
            content: "";
            position: absolute;
            width: 58%;
            height: 12px;
            border: 3px solid #3c3d40!important;
            background-color: #202020!important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        }
      }

      .reg-show , .book-me-reg , .book-me-btn{
        background: #202020!important;
        color: white!important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
      }
      .name-btn{
        background: #202020!important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
      }

      .design-screen-desktop-1 , .design-screen-tab-1 , .design-screen-mobile-1 , .design-screen-mobile , .design-screen-desktop-3 , .design-screen-mobile-3 , .design-screen-tab-3 , .design-screen-tab-1 , .design-screen-tab , .design-screen-desktop{
        .navbar-top .timeline:after , .hero-sidebar   .timeline:after{
            content: "";
        
            border: 3px solid #3c3d40!important;
            background-color: #202020!important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        }
        .navbar-bars{
            color: #f0f4f8 !important;
            background: #202020!important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
          }
        .design-about{
            color: white!important;
            background: #3c3d40 !important;
        box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
          } 
      }  
      .content-text .nav-item .active {
        background: #202020!important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        color: white;
        box-shadow: none;
    }
      *::-webkit-scrollbar-track {
        box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
        border-radius: 28px;
        padding: 2px;
      }
    
      *::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        border-radius: 10px;
    
        border: 3px solid #3c3d40;
      }
    .dark-meu-show{
        display: block!important;
    }
    #parent-circle{
        border: 9.5166px solid #202020!important;

box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000!important;
    }
  
    .circle {
        background: #202020!important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        border: 0.276673px solid #202020!important;
        color: #C49530;
    }
    .meu-show{
display: none!important;
    }
    .Neumorphic-design .design-screen-tab-1 .dark-meu-show {
        display: block;
        position: absolute;
        right: 38px;
        top: 51px;
    }
    

.Neumorphic-design .design-screen-desktop-1 .dark-meu-show img {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    right: 7%;
    top: -4px;
}
.searchbox , .hamburger-outer {
    background: #202020 !important;
    box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
    .Neumorphic-design .design-screen-tab-1 .dark-meu-show {
      position: revert!important;
    }
    

.Neumorphic-design .design-screen-desktop-1 .dark-meu-show img {
    position: revert!important;
}
}
    .design-two {
        background: #202020 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        span{
            color: #f8f8f0 !important;
        }
        .active-icon-color-second {
            background: #202020 !important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        }
    }
    .design-screen-desktop-1 .content-text .tab-content{
        background: #3c3d40;
    }
    .design-screen-tab-1 .content-text .tab-content{
        background: #3c3d40;
    }
    .design-screen-mobile-1 .content-text .tab-content{
        background: #3c3d40;
    }
    .navbar-top .testimonial .timeline-top p{
      
            color: #f0f4f8 !important; 
  
    }
    .design-three{
        color: #f8f8f0 !important;
    }
    .design-one {
        background-color: transparent!important;
        background: linear-gradient(158.72deg, rgb(184 184 184 / 50%) 3.01%, rgb(255 255 255 / 5%) 103.3%);
    border: 1px solid rgba(255, 255, 255, 0.4901960784);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        span{
            color: #f8f8f0 !important;
        }
    }
    .twelvestep-scss .nav-pills .design-two.nav-link.active, .twelvestep-scss .nav-pills .show > .design-two.nav-link{
        background: #202020 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        span{
            color: #f8f8f0 !important;
        }
    }
    .Neumorphic-design .design-screen-mobile .content-text .tab-content{
        background: #202020 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
    }
   
    .Neumorphic-design .design-screen-desktop .design-screen-desktop .hero-section-mue{
        box-shadow: none!important;
    }
   
.name-btn{
    color: #f8f8f0 !important;
}
.mi-info{
    background: #202020 !important;
    box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
}
    .twelvestep-scss .nav-pills .design-one.nav-link.active, .twelvestep-scss .nav-pills .show > .design-one.nav-link{
        background-color: transparent!important;
        background: linear-gradient(158.72deg, rgb(184 184 184 / 50%) 3.01%, rgb(255 255 255 / 5%) 103.3%);
    border: 1px solid rgba(255, 255, 255, 0.4901960784);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        span{
            color: #f8f8f0 !important;
        }  
    }
    .forms-inpt {
        input::placeholder {
            color: #f8f8f0 !important;
        }
    }

    .mode-set , .social-set{
        color: #f0f4f8 !important;
    }
    .mobile-btns h4 {
        color: #f0f4f8 !important;
    }
    .hamburger-outer .sidebar-li {
        color: #f0f4f8 !important;
    }
    .banner-btn .btn-bg button {
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
    }
    .bars-seeall .hamburger-outer .testimonial-p {
        color: #f0f4f8 !important;
    }
    .bars-hambrger .hamburger-outer .sidebar-li {
        color: #f0f4f8 !important;
    }
    .testimonial-seeall h5 {
        color: #f0f4f8 !important;
    }
    .hero-conent .nav-item .active {
        box-shadow: none !important;
    }
    .mi-info {
        padding: 0px 5px 5px !important;
    }
    .card-outo .modal-content {
        background: #202020 !important;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
        border: 1px solid transparent !important;
        .btn-close {
            background-color: #f5f5f5 !important;
        }
        .btn-bg {
            background: #202020 !important;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
            button {
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
            }
        }
    }
    .bars-hambrger .hamburger-outer {
        background-color: #202020 !important;
    }
    .hero-conent .himself-section {
        background: #3c3d40c2 !important;

        box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
    }
    .main-content {
        .intro-card {
            background: #3c3d40 !important;
            h2,
            h5,
            h4,
            p {
                color: #f0f4f8 !important;
            }
        }
        .playlist-card {
            background: #3c3d40 !important;
            .side-text {
                background: #202020 !important;
                color: #f0f4f8 !important;
            }
            .text-gree {
                background: #3c3d40 !important;
                color: #f0f4f8 !important;
            }
        }
    }
    .navbar-top {
        background: #202020 !important;
        .navbar-btn {
            p {
                color: #f0f4f8 !important;
            }
        }
        .timeline li {
            background: #3c3d40 !important;
        }
        .timeline p {
            color: #f0f4f8 !important;
        }
        .testimonial .timeline-top {
            background: #3c3d40 !important;
        }
        .mode-set,
        .social-set {
            color: #f0f4f8 !important;
        }
        .bottom-tabs {
            color: #f0f4f8 !important;
            h6 {
                color: #f0f4f8 !important;
            }
        }
    }
  #parent-circle .logo-f , #parent-circle .snapchat{
        color: rgb(180 138 47)!important;
    }
    .hero-sidebar {
        background: #202020 !important;
        .testimonial .timeline-top {
            background: #3c3d40 !important;
            p {
                color: #f0f4f8 !important;
            }
        }
        .timeline li {
            background: #3c3d40 !important;
        }
        .timeline p {
            color: #f0f4f8 !important;
        }

        .fa-magnify {
            color: #f0f4f8 !important;
        }
        .info-icon {
            border: 3px solid #f0f4f8 !important;

            color: #f0f4f8 !important;
        }
    }
    .modal-dialog.modal-90w .modal-content {
        background: #202020 !important;
        box-shadow: none !important;
        .btn-close {
            background-color: #f5f5f5 !important;
        }
        .btn-bg {
            background: #202020 !important;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1) !important;
        }
    }
    .content-text {
        background: #202020 !important;
        .accordion-body {
            background: #3c3d40 !important;
        }
        .side-text {
            background: #202020 !important;
            color: #f0f4f8 !important;
        }
    }
    .design-screen-mobile-3 .content-text .accordion-button {
        background: #3c3d40 !important;
        color: #f0f4f8 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        height: 52px;
      
    }
 
    .version-vido{
        background: transparent!important;
    }
    .meu-show{
        display: none;
    }
   .dark-meu-show{
display: block!important;
    }

    .version-video-2{
        background: #202020 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
        p{
           color: #f0f4f8 !important;
        }  
    }
    .border-bt{
        border: 2px solid #202020;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
    }
    .desktop-version-2 .Neumorphic-design .design-screen-desktop-1 .version-video-2 p{
        color: #f0f4f8 !important;
    }
    .gold-btn-version{
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        color: #202020 !important; 
    }
    .info-icon{
        border: 3px solid #f0f4f8 !important;
  
    color: #f0f4f8 !important;
  
    }
    .desktop-version-2 .Neumorphic-design .design-screen-desktop-1 .search-navbar-btn .fa-magnify , .desktop-version-2 .Neumorphic-design .design-screen-desktop-1 .search-navbar-btn .info-oter {
        background: #202020 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
        color: #f0f4f8 !important;
    }
  .design-screen-desktop-3 , .design-screen-tab-3 , .design-screen-mobile-3{
    .book-me-btn-sidebar{
        background: #202020 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
        color: #f0f4f8 !important;
    }
  }
    .design-screen-desktop-1,
    .design-screen-mobile-1,
    .design-screen-tab-1,
    .design-screen-tab-3,
    .design-screen-mobile-3,
    .design-screen-desktop-3,
    .design-screen-tab,
    .design-screen-mobile,
    .design-screen-desktop {
        .search-me-btn{
            border-bottom: 1px solid #363b40;
            background: #202020 !important;
            color: #f0f4f8 !important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
        }
        .timeline-top {
            background: #202020 !important; 
        }
        .timeline{
            li{
                background: #3c3d40 !important;
              p{
                color: #f0f4f8 !important;
              }
              
            }
        }
        #parent-circle .logo-f , #parent-circle .snapchat{
            color: rgb(180 138 47)!important;
        }
      .border-b {
            border-bottom: 1px solid #363b40;
        }
        .searchbox .search-area .search-field{
            background: #202020 !important;
            color: #f0f4f8 !important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
            svg{
                color: #f0f4f8 !important;
            }
        }
        background: #202020 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        .content-text .playlist-section-text .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
      
            background-color: #202020 !important;
     
        }
        .searchbox , .hamburger-outer {
            background: #202020 !important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        }
        .content-text .playlist-section-text .range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom .range-slider__tooltip__caret::before {
            border-bottom-color: #202020  !important;
            border-width: 0 0.3rem 0.3rem;
            bottom: 25px;
            left: 2px;
        }
        .testimonial .timeline-top .icon-green{
            color: #f0f4f8 !important;
        }
        .hero-conent .himself-section .playlist-section-text .text-gree{
            background: #3c3d40 !important;
            color: #f0f4f8 !important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        }
        .tab-himself-text {
            background: #3c3d40 !important;
            .text-gree {
                background: #3c3d40 !important;
                color: #f0f4f8 !important;
            }
        }
    
        .bookme-inner {
            background: #202020 !important;
            .timelinee li {
                background: #3c3d40 !important;
            }
            .timelinee p {
                color: #f0f4f8 !important;
            }
            .forms-inpt {
                background: #3c3d40 !important;
            }
        }
        .bars-hambrger .hamburger-outer {
            background-color: #202020 !important;
            .mode-set,
            .social-set,
            .sidebar-li {
                color: #f0f4f8 !important;
            }
            .testimonial .timeline-top p {
                color: #f0f4f8 !important;
            }
        }
        .content-text .accordion-body {
            background: #3c3d40 !important;
            h5,
            p {
                color: #f0f4f8 !important;
            }
        }
        .accordion-item {
            border: 1px solid #3c3d40 !important;
        }
    }
    .design-screen-desktop {
        .himself-section {
            background: transparent !important ;
            .tab-section .tab-content {
                background: #3c3d40 !important;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
            }
        }
    }
    .playlist-section-text,
    .video-section {
        background: #3c3d40 !important;
        box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;

    }
    .switch-border,
    .switch-outer {
        background-color: #3c3d40 !important;
        border: 1px solid #7cbaa7;
    }
    .switch-border input[type="checkbox"]::after {
        background: #202020 !important;
    }
    .switch-outer .switch::before {
        background: #202020 !important;
        box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
    }
    .design-one-size {
        background: #202020 !important;
        box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
        span {
            color: #f0f4f8 !important;
        }
        .icon-design {
            background: #202020 !important;
            box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
        }
    }
    .design-one-size.active {
        background: linear-gradient(#202020, #202020) padding-box,
            linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
        border: 2px solid transparent !important;
    }
    .design-screen-desktop {
        .meo-shape{
            display: block;
            opacity: 0.05;
            position: absolute;
            z-index: 0;
            left: 17px;
        }
        .hero-section-mue .content-img {
            display: block;
        }
        .hero-section-mue {
            display: block !important;
            width: 70%;
            text-align: right;
            right: -340px;
        }
        .hero-section-reg .content-img {
            display: none;
        }
    }
    .design-screen-tab{
        .himself-section{
            background: transparent!important;

        }
        .meo-shape{
            display: block;
            opacity: 0.05;
            position: absolute;
            z-index: 0;
    left: 364px;
        }
        .hero-section-mue .content-img {
            display: block;
        }
        .hero-section-mue {
            display: block !important;
            width: 80%;
            position: relative;
            text-align: right;
            right: -130px;
        }
        .hero-section-reg {
            display: none;
        }
    }
    .sidebar-li{
        background: #202020 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
    }
    .testimonial-p{
        background: #202020 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
        color: #f0f4f8 !important;
    }
    .Neumorphic-design {
     
        .sidebar-li{
            background: #202020 !important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
        }
        .testimonial-p{
            background: #202020 !important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            color: #f0f4f8 !important;
        }
        .timeline-top{
            color: #f0f4f8 !important;
        }
        .searchbox , .hamburger-outer {
            background: #202020 !important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
        }
        .intro-oter{
            background: #202020 !important;
    box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
        }
      .playlist-section-text{
        box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;

        }
        .hero-conent .himself-section .playlist-section-text{
            background: #202020 !important;
            box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
        }
    
        .img-top{
            background: #202020 !important;
            box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
        
        }
        .tab-section{
            background: #202020 !important;
            box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
        }
        .side-text{
            background: #202020 !important;
            box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
            color: #f0f4f8 !important; 
        }
        .design-screen-tab , 
        .design-screen-mobile , 
        .design-screen-desktop{
            .nav-item .active {
                background: linear-gradient(#202020, #202020) padding-box,
                linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;     
               }
            }
            .design-screen-tab{
                .himself-section{
                    background: transparent!important;
        
                }
              
                .hero-section-mue .content-img {
                    display: block;
                }
                .hero-section-mue {
                    display: block !important;
                    position: revert!important;
                    text-align: right;
                    right: revert!important;
                    width: revert!important;
                }
                .hero-section-reg {
                    display: none;
                }
            }
     
            .Neumorphic-design , .Glassmorphic-design{
                .design-screen-desktop-1,
                .design-screen-mobile-1,
                .design-screen-tab-1,
                .design-screen-tab-3,
                .design-screen-mobile-3,
                .design-screen-desktop-3,
                .design-screen-tab,
                .design-screen-mobile,
                .design-screen-desktop {
                    .dark-meu-show{
                        display: block!important;
                    }
                    .meu-show img {
                        display: none!important;
                    }
                    .bars-hambrger .hamburger-outer{
                        background: #202020 !important;
                        color: #f0f4f8 !important;
                        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
                    }
                    .searchbox{
                        background: #202020 !important;
                        color: #f0f4f8 !important;
                        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
                    }
                    .forms-inpt{
                        background: #202020 !important;
                        color: #f0f4f8 !important;
       .form-control{
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
       }
                    }
                }
            }
     
        .design-screen-desktop-1,
        .design-screen-mobile-1,
        .design-screen-tab-1,
        .design-screen-tab-3,
        .design-screen-mobile-3,
        .design-screen-desktop-3,
        .design-screen-tab,
        .design-screen-mobile,
        .design-screen-desktop {
            .bars-hambrger .hamburger-outer .bottom-tabs h6{
                color: #f0f4f8 !important;
            }
            .searchbox .search-area .search-field{
                background: #202020 !important;
                color: #f0f4f8 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
                svg{
                    color: #f0f4f8 !important;
                }
            }
            .searchbox{
                .dark-meu-show img{
                    position: revert!important;
                }
                .meu-show img {
                    display: none!important;
                }
            }
            .bars-hambrger .hamburger-outer .testimonial .timeline-top{
                background: #202020 !important;
                box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
            }
            .hero-conent .himself-section .playlist-section-text .side-text{
                background: #202020 !important;
                box-shadow: -3px -3px 6px -2px rgba(58, 58, 60, 0.8), 4px 4px 6px -2px #000000 !important;
                color: #f0f4f8 !important;
            }
            .playlist-section-text {
                .setting-icons, .meu-icon , .next-icon , .next-icon-center  , .video-icon{
                    background: #202020 !important;
                    box-shadow: -3px -3px 6px -2px rgba(58, 58, 60, 0.8), 4px 4px 6px -2px #000000 !important;
                    transform: matrix(-1, 0, 0, 1, 0, 0);
                }
            }
            .tab-seeall .testimonial .timeline-top{
                background: linear-gradient(#202020, #202020) padding-box,
                linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
            border: 2px solid transparent !important;
            box-shadow: -3px -3px 6px -2px rgba(58, 58, 60, 0.8), 4px 4px 6px -2px #000000 !important;

            }
            .tab-seeall .testimonial .timeline-top p {
                font-size: 5px;
                color: #f0f4f8 !important;
            }
            
            .forms-inpt{
                .form-control{
                    background: linear-gradient(#202020, #202020) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                }
            }
            .mode-set , .social-set{
                color: #f0f4f8 !important;
            }
            .design-screen-desktop {
               
                .hero-section-mue {
                    display: revert!important;
                    width: revert!important;
                    text-align: right;
                    right: revert!important;
                }
               
            }
            .next-icon,
            .next-icon,
            .next-icon-center,
            .meu-icon,
            .video-icon,
            .next-icon-center,
            .setting-icons {
                background: #202020 !important;

                box-shadow: -3px -3px 6px -2px rgba(58, 58, 60, 0.8), 4px 4px 6px -2px #000000 !important;


                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
            .next-icon,
            .next-icon,
            .setting-icons {
                svg {
                    color: #f0f4f8 !important;
                }
            }
            .hero-section-mue .content-img {
                background: #202020 !important;
                box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
            }

            .design-screen-desktop .intro-left-section .img-top {
                background: #202020 !important;
                box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
            }
            .intro-anner-oter,
            .video-btn-oter,
            .tab-section .tab-oter-content {
                background: #202020 !important;
                box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
            }
            .video-section {
                background: #202020 !important;
                box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
            }
            .content-text .nav-item,
            .design-about {
                color: #f0f4f8 !important;
                background: #202020 !important;
                box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
            }
            .content-text .nav-item .active {
                background: linear-gradient(#202020, #202020) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                box-shadow: -3px -3px 6px -2px rgba(58, 58, 60, 0.8), 4px 4px 6px -2px #000000 !important;

                color: #f0f4f8 !important;
                border: 3px solid transparent !important;
            }
            .tab-content {
                background: #202020 !important;
                box-shadow: -6px -6px 20px rgba(56, 56, 56, 0.8), 8px 8px 20px #000000 !important;
            }
            .tab-himself-text {
                background: #202020 !important;
            }
            .gold-btn {
                box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
            }
            .mue-shape,
            .mue-shape-right,
            .mue-shape-last,
            .mue-shape-end,
            .meo-shape {
                opacity: 0.05;
            }
            .meu-playlist-section {
                background: #202020 !important;

                box-shadow: -4px -4px 9px -2px rgba(58, 58, 60, 0.8), 5px 5px 12px -4px #000000 !important;
            }
            .mue-video-section {
                background: #202020 !important;

                box-shadow: -4px -4px 9px -2px rgba(58, 58, 60, 0.8), 5px 5px 12px -4px #000000 !important;
            }
            .hero-section {
                box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
            }
            .mue-border {
                background: #3c3d40 !important;

                box-shadow: -4px -4px 9px -2px rgba(58, 58, 60, 0.8), 5px 5px 12px -4px #000000 !important;
            }

            .content-text {
                .playlist-section-text {
                    background: #202020 !important;
                    box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
                }
            }
            .hero-sidebar {
                .bottom-tabs {
                    svg {
                        color: #f0f4f8 !important;
                        background: #202020 !important;
                        box-shadow: -3px -3px 6px -2px rgba(58, 58, 60, 0.8), 4px 4px 6px -2px #000000 !important;
                    }
                    h6 {
                        color: #f0f4f8 !important;
                    }
                }
            }
            .main-content .hero-sidebar .fa-magnify,
            .navbar-magni,
            .main-content .hero-sidebar .info-oter,
            .meu-social li {
                background: #202020 !important;
                box-shadow: -3px -3px 6px -2px rgba(58, 58, 60, 0.8), 4px 4px 6px -2px #000000 !important;
                svg {
                    color: #f0f4f8 !important;
                }
            }
            .switch-border,
            .switch-outer {
                background: #202020 !important;
                box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
            }
            .switch-border input[type="checkbox"]::after {
                background: #202020 !important;
                box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
            }
            .name-btn {
                border: 1.14031px solid #202020 !important;
                box-shadow: inset -2px -2px 5px rgba(56, 56, 56, 0.7), inset 2px 2px 5px #000000 !important;
                background: #202020 !important;
            }
            .m-section .hero-section-mue .content-img {
                background: #202020 !important;
                box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
            }
            .hero-sidebar {
                background: #202020 !important;
                box-shadow: -3px -3px 6px -2px rgba(58, 58, 60, 0.8), 4px 4px 6px -2px #000000 !important;
            }
            .img-logo-oter {
                background: #202020 !important;
                /* Neo/Dark/Inner/3 */

                box-shadow: inset -4px -4px 7px rgba(56, 56, 56, 0.7), inset 5px 5px 8px #000000 !important;
            }

            .navbar-top .testimonial {
                .timeline-top {
                    border: 1px solid #cdaf7f !important;
                    background-color: #202020 !important;
                    box-shadow: -4px -4px 9px -2px rgba(58, 58, 60, 0.8), 5px 5px 12px -4px #000000 !important;
                    p {
                        font-size: 6px;
                        color: #f0f4f8 !important;
                    }
                }
            }
            .timeline:after {
                border: 3px solid #202020 !important;
                box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9) !important;
            }
            .active-oter {
                background: #202020 !important;
                box-shadow: -4px -4px 9px -2px rgba(58, 58, 60, 0.8), 5px 5px 12px -4px #000000 !important;
                li {
                    background: #202020 !important;
                    box-shadow: -3px -3px 6px -2px rgba(58, 58, 60, 0.8), 4px 4px 6px -2px #000000 !important;
                }
                .timeline-top {
                    background: #202020 !important;
                    box-shadow: -4px -4px 9px -2px rgba(58, 58, 60, 0.8), 5px 5px 12px -4px #000000 !important;
                }
            }
        }
        .navbar-bars {
            background: #202020 !important;

            box-shadow: -4px -4px 9px -2px rgba(58, 58, 60, 0.8), 5px 5px 12px -4px #000000 !important;
            border-radius: 5px;
            color: #f0f4f8 !important;
        }
    }
    .Glassmorphic-design {
        .timeline-top{
            color: #f0f4f8 !important;
        }
        .searchbox , .hamburger-outer {
            background: #202020 !important;
            box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
            .sidebar-li{
                background: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            }
        }
        .design-screen-desktop-3,
        .design-screen-tab-3,
        .design-screen-mobile-3 {
            .searchbox , .hamburger-outer {
                background: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
            }
            .tab-himself-text {
                background: transparent !important;
            }
            .side-text,
            .text-gree {
                color: #f0f4f8 !important;
            }
            .next-icon-center svg {
                color: #f0f4f8 !important;
            }
            .hero-sidebar {
                z-index: 2;
            }
            .navbar-top {
                background: linear-gradient(
                    311.75deg,
                    #2e2d32 3.63%,
                    #494a55 53.34%,
                    #212125 88.18%,
                    #0f0f10 110.87%
                ) !important;
            }
            .main-content .content-hero {
                background: linear-gradient(
                    311.75deg,
                    #2e2d32 3.63%,
                    #494a55 53.34%,
                    #212125 88.18%,
                    #0f0f10 110.87%
                ) !important;
            }
            .himself-section {
                background: linear-gradient(
                    311.75deg,
                    #2e2d32 3.63%,
                    #494a55 53.34%,
                    #212125 88.18%,
                    #0f0f10 110.87%
                ) !important;
            }
            .social-icon-navbar li svg {
                color: #f0f4f8 !important;
            }
            .main-content .intro-card {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%) !important;
                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25) !important;
                .intro-inner {
                    background: linear-gradient(
                        158.72deg,
                        rgba(0, 0, 0, 0.4) 3.01%,
                        rgba(0, 0, 0, 0.2) 103.3%
                    ) !important;
                }
            }
            .hero-conent .himself-section .tab-section {
                background: linear-gradient(
                    311.75deg,
                    #2e2d32a6 3.63%,
                    #494a55ab 53.34%,
                    #212125b3 88.18%,
                    #0f0f1070 110.87%
                ) !important;
            }
            .main-content .playlist-card {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%) !important;
                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25) !important;
            }
            .switch-outer .switch::before {
                background: linear-gradient(
                    180deg,
                    #facb30 -18%,
                    #fef9c8 22.5%,
                    #c49530 67.84%,
                    #fff8c4 116%
                ) !important;
                box-shadow: none !important;
            }
            .search-field {
                background: linear-gradient(#212123, #2a2a2e) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                input::placeholder {
                    color: #f0f4f8 !important;
                }
                svg {
                    color: #f0f4f8 !important;
                }
            }
            .navbar-top .search-area .search-field {
                background: linear-gradient(#212123, #2a2a2e) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                input::placeholder {
                    color: #f0f4f8 !important;
                }
                svg {
                    color: #f0f4f8 !important;
                }
            }
            .forms-inpt .form-control {
                background: linear-gradient(#212123, #2a2a2e) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
            }
            .hero-conent .nav-item .active {
                border: 2px solid rgba(255, 255, 255, 0.4901960784) !important;
                color: #f0f4f8 !important;
                box-shadow: none !important;
            }
        }
        .design-screen-mobile-3 {
            .content-text {
                background: linear-gradient(
                    311.75deg,
                    rgba(46, 45, 50, 0.6509803922) 3.63%,
                    rgba(73, 74, 85, 0.6705882353) 53.34%,
                    rgba(33, 33, 37, 0.7019607843) 88.18%,
                    rgba(15, 15, 16, 0.4392156863) 110.87%
                ) !important;
            }
            .hero-section-mue .content-img {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.3) 3.01%,
                    rgba(255, 255, 255, 0) 103.3%
                ) !important;
                -webkit-backdrop-filter: blur(21px);
                backdrop-filter: blur(21px);
            }
        }
        .design-screen-mobile-1 {
            .content-text {
                background: transparent !important;
            }
        }
        .design-screen-desktop-1,
        .design-screen-mobile-1,
        .design-screen-tab-1,
        .design-screen-tab-3,
        .design-screen-mobile-3,
        .design-screen-desktop-3,
        .design-screen-tab,
        .design-screen-mobile,
        .design-screen-desktop {
            background: linear-gradient(
                311.75deg,
                #2e2d32 3.63%,
                #494a55 53.34%,
                #212125 88.18%,
                #0f0f10 110.87%
            ) !important;
            .icons-circle {
                border: 12.047px solid #202020 !important;
            }
            .bars-hambrger .hamburger-outer .sidebar-li {
                color: #f0f4f8 !important;
            }
            .main-content .hero-sidebar .bottom-tabs h6 {
                color: #f0f4f8 !important;
            }
            .main-content .bottom-tabs h6 {
                color: #f0f4f8 !important;
            }
            .bars-hambrger .hamburger-outer {
                background: linear-gradient(
                    311.75deg,
                    #2e2d32 3.63%,
                    #494a55 53.34%,
                    #212125 88.18%,
                    #0f0f10 110.87%
                ) !important;
            }
            .bars-hambrger .hamburger-outer .bottom-tabs h6 {
                color: #f0f4f8 !important;
            }
            .bars-hambrger .hamburger-outer .testimonial-p {
                color: #f0f4f8 !important;
            }
            .searchbox {
                background: linear-gradient(
                    311.75deg,
                    #2e2d32 3.63%,
                    #494a55 53.34%,
                    #212125 88.18%,
                    #0f0f10 110.87%
                ) !important;
            }
            .hero-sidebar .testimonial {
                .timeline-top {
                    border: 1px solid #ffffffb7 !important;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.5) 3.01%,
                        rgba(255, 255, 255, 0.05) 103.3%
                    ) !important;
                    box-shadow: -4px -4px 9px -2px rgba(58, 58, 60, 0.8), 5px 5px 12px -4px #000000 !important;
                    p {
                        font-size: 6px;
                        color: #f0f4f8 !important;
                    }
                }
            }
            .hamburger-outer {
                .testimonial {
                    .timeline-top {
                        border: 1px solid #ffffffd0 !important;
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.5) 3.01%,
                            rgba(255, 255, 255, 0.05) 103.3%
                        ) !important;
                        box-shadow: -4px -4px 9px -2px rgba(58, 58, 60, 0.8), 5px 5px 12px -4px #000000 !important;
                        p {
                            font-size: 6px;
                            color: #f0f4f8 !important;
                        }
                    }
                }
            }
            .hamburger-outer {
                background: linear-gradient(
                    311.75deg,
                    #2e2d32 3.63%,
                    #494a55 53.34%,
                    #212125 88.18%,
                    #0f0f10 110.87%
                ) !important;
            }
            .playlist-section-text {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%) !important;
            }
            .search-field {
                input::placeholder {
                    color: #f0f4f8 !important;
                }
                svg {
                    color: #f0f4f8 !important;
                }
            }
            .forms-inpt .form-control {
                background: linear-gradient(#212123, #2a2a2e) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
            }
            .main-content .hero-sidebar .switch-outer .switch::before {
                background: linear-gradient(
                    180deg,
                    #facb30 -18%,
                    #fef9c8 22.5%,
                    #c49530 67.84%,
                    #fff8c4 116%
                ) !important;
                box-shadow: none !important;
            }
            .nav-item .active {
                background: linear-gradient(#212123, #2a2a2e) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
            }
            .next-icon,
            .next-icon,
            .next-icon-center,
            .meu-icon,
            .video-icon,
            .next-icon-center,
            .setting-icons {
                svg {
                    color: #f0f4f8 !important;
                }
            }
            .meu-icon {
                background: transparent !important;
                border: none !important;
            }
            .next-icon-center {
                margin: 4px 0px 0px !important;
                .iconss {
                    margin: 4px !important;
                }
            }
            .video-icon {
                margin: 0px 0px 0px 1px !important;
                padding: 3px 1px !important;
            }
            .gold-btn {
                box-shadow: -5px -5px 13px -4px #3a3a3c, 5px 5px 16px #000000 !important;
                background: linear-gradient(#202020, #202020) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                border: 8px solid transparent !important;
                border-radius: 60px !important;
                color: #f0f4f8 !important;
            }
            .active-oter {
                .timeline-top {
                    color: #f0f4f8 !important;
                    background: linear-gradient(
                        158.72deg,
                        rgba(0, 0, 0, 0.3) 3.01%,
                        rgba(0, 0, 0, 0.1) 103.3%
                    ) !important;
                    box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25) !important;
                    backdrop-filter: blur(1.5px) !important;
                }
            }
            .navbar-bars {
                color: #f0f4f8 !important;
            }
            .navbar-magni {
                svg {
                    color: #f0f4f8 !important;
                }
            }
            .mi-info {
                padding: 0px 5px 5px !important;
            }
            .bottom-tabs {
                svg {
                    color: #f0f4f8 !important;
                }
                h6 {
                    color: #f0f4f8 !important;
                }
            }
            .testimonial {
                .timeline-top {
                    color: #f0f4f8 !important;
                }
            }
            .icon-green {
                color: #f0f4f8 !important;
            }
            .text-gree {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%) !important;
                box-shadow: inset 0px 10px 20px rgba(255, 255, 255, 0.07) !important;
                backdrop-filter: blur(10px) !important;
                color: #f0f4f8 !important;
            }
            .video-section {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.5) 3.01%, rgba(0, 0, 0, 0.3) 103.3%) !important;
                /* Background blur/Light/2 */

                backdrop-filter: blur(10px) !important;
            }
            .video-name {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%) !important;
            }
            .meu-playlist-section {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%) !important;
                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25) !important;
            }
            .main-content .hero-sidebar {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.5) 3.01%, rgba(0, 0, 0, 0.3) 103.3%) !important;
                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25) !important;
                backdrop-filter: blur(5px) !important;
                color: #f0f4f8 !important;
                p {
                    color: #f0f4f8 !important;
                }
            }
            .switch-border,
            .switch-outer {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%) !important;
                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25) !important;
                backdrop-filter: blur(5px) !important;
                .switch {
                    background: linear-gradient(
                        158.72deg,
                        rgba(0, 0, 0, 0.3) 3.01%,
                        rgba(0, 0, 0, 0.1) 103.3%
                    ) !important;
                }
                input[type="checkbox"]::after,
                input[type="checkbox"]::before {
                    background: linear-gradient(
                        180deg,
                        #facb30 -18%,
                        #fef9c8 22.5%,
                        #c49530 67.84%,
                        #fff8c4 116%
                    ) !important;
                }
            }
            .content-img {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.5) 3.01%, rgba(0, 0, 0, 0.3) 103.3%) !important;
                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25) !important;
                backdrop-filter: blur(5px) !important;
            }
            .name-btn {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.3) 3.01%, rgba(0, 0, 0, 0.1) 103.3%) !important;
                color: #f0f4f8 !important;
            }
            .mode-set,
            .social-set {
                color: #f0f4f8 !important;
            }
            .design-about {
                background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.5) 3.01%, rgba(0, 0, 0, 0.3) 103.3%) !important;
                color: #f0f4f8 !important;
            }
            .content-text {
                .tab-content {
                    background: linear-gradient(
                        158.72deg,
                        rgba(0, 0, 0, 0.5) 3.01%,
                        rgba(0, 0, 0, 0.3) 103.3%
                    ) !important;
                    backdrop-filter: blur(10px) !important;
                }
                .nav-item .active {
                    background: linear-gradient(
                        158.72deg,
                        rgba(0, 0, 0, 0.5) 3.01%,
                        rgba(0, 0, 0, 0.3) 103.3%
                    ) !important;
                    color: #f0f4f8 !important;
                }
                .tab-himself-text {
                    background: rgb(37 37 42) !important;
                }
            }
        }
        .design-screen-tab .himself-section {
            background: transparent !important;
        }
    }
    .design-screen-tab-3,
    .design-screen-desktop-3 {
        .tab-himself-text {
            background: transparent !important;
        }
        .modal-tabs .nav-item .active {
            background: rgb(37 37 42) !important;
        }
    }
    .design-screen-desktop-3,
    .design-screen-mobile-3 {
        .modal-tabs .nav-item .active {
            background: rgb(37 37 42) !important;
        }
    }
    .design-screen-desktop-3{
        .tab-content{
          background:transparant!important;
        }
    }
    .Neumorphic-design .design-screen-desktop .searchbox{
        background: #202020 !important;
        color: #f0f4f8 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
    }
    .Neumorphic-design .design-screen-tab .hero-sidebar .hamburger-outer{
        background: #202020 !important;
        color: #f0f4f8 !important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
    }
    .Neumorphic-design {
        .design-screen-desktop-1,
        .design-screen-mobile-1,
        .design-screen-tab-1,
        .design-screen-tab-3,
        .design-screen-mobile-3,
        .design-screen-desktop-3,
        .design-screen-tab,
        .design-screen-mobile,
        .design-screen-desktop {

            .hero-conent .himself-section{
                background-color: #202020 !important; 
                      
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            }
            .search-area .search-field {
      
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
                color: #f0f4f8 !important;  
            }
            .hamburger-outer{
                .glass-show {
                    position: revert!important;
                }
            }
            .bottom-tabs{

                background-color: #202020 !important;
                h6{
                    background-color: #202020 !important;
                }
            }
            .bars-hambrger .hamburger-outer .sidebar-li{
                color: #f0f4f8 !important;  
            }
            .bookme-oter .bookme-inner .forms-inpt .form-control{
                background-color: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            }
            .searchbox{
                background-color: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            }
            .searchbox{
                background-color: #202020 !important;
            }
            .bars-hambrger .hamburger-outer{
                background-color: #202020 !important;
            }
            .dark-meu-show{
                position: revert!important;
            }
            .form-control{
                background: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;  
            }
            .bookme-oter .bookme-inner .hamburger-outer .forms-inpt{
                background: #202020 !important;
       
            }
            .searchbox{
                .dark-meu-show img{
                    position: revert!important;
                }
            }
            .hamburger-outer{
                background: #202020 !important;
                  box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
                  .dark-meu-show img{
                    position: revert!important;
                }
                .forms-inpt{
                    .dark-meu-show img{
                        background: #202020 !important;
                     
                    }
                }
            }
            .search-field{
                background: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            }
            .tab-content{
                background: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            }
            .img-logo{
                background: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;  
            }
            .hero-section{
                background: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;      
            }
            .navbar-top{
                background: #202020 !important;     
            }
        }
        .design-screen-desktop-1,
        .design-screen-mobile-1,
        .design-screen-tab-1{
            .hamburger-outer{
                background: #202020 !important;
                  box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
                  .dark-meu-show img{
                    position: revert!important;
                }

            }
      .searchbox{
        .dark-meu-show img{
            position: revert!important;
        }
      }
            .meu-show{
                display: none!important;
            }
.dark-meu-show{
    display: block!important;
}
        }
    .design-screen-tab-3,
        .design-screen-mobile-3,
        .design-screen-desktop-3,
        .design-screen-tab,
        .design-screen-mobile,
        .design-screen-desktop {

            .meu-show{
                display: none!important;
            }
            .book-me-mue{
                display: none!important;
            }
           
        }
      .design-screen-desktop{
        .glass-show{
            display: block!important;
            width: 200px;
        }
   
      }
      .design-screen-tab{
        .glass-show{
            display: block!important;
        
        }

    }
    }
    .Neumorphic-design .design-screen-tab{
        .bookme-oter  .glass-show {
            display: block;
            width: 200px;
            position: relative;
            right: 0px;
            margin-top: -18px;
   
                right: -158px;
            
        }
    }
      .Glassmorphic-design{
        .design-screen-desktop-1,
        .design-screen-mobile-1,
        .design-screen-tab-1,
        .design-screen-tab-3,
        .design-screen-mobile-3,
        .design-screen-desktop-3,
        .design-screen-tab,
        .design-screen-mobile,
        .design-screen-desktop {
            .himself-section{
                background-color: #202020 !important; 
            }
            .bottom-tabs{
                background-color: #202020 !important;
                h6{
                    background-color: #202020 !important;
                }
            }
            .bars-hambrger .hamburger-outer .sidebar-li{
                color: #f0f4f8 !important;  
            }
            .bottom-tabs{
                background-color: #202020 !important;
            }
            .bookme-oter .bookme-inner .forms-inpt .form-control{
                background-color: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            }
            .searchbox{
                background-color: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            }
            .bars-hambrger .hamburger-outer{
                background-color: #202020 !important;
            }
            .circle {
                background: #202020 !important;
                color: #C49530!important;
            }
            .search-field{
                background: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important; 
            }
            .tab-content{
                background: transparent !important;
           
            }
            .img-logo{
                background: #202020 !important;
                box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;  
            }
          
            .navbar-top{
                background: #202020 !important;     
            }
            .hero-conent .himself-section .tab-section .himself-right {
                height: 156px;
            }
        }
        .Glassmorphic-design .hamburger-outer {
      
   
            left: -294px!important;
        }
     
    }
}
