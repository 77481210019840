.design-screen-mobile-6 {
    background-color: #cbd5e0;
    width: 400px;
    margin: auto;
    padding: 40px 20px 120px !important;

    .toggle_button {
        background: #F0F4F8;
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: #7CBAA7 !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            background: transparent;
            color: #000000;
            text-align: center;
            border: none;
        }

    }

    .bg_light {
        background: #F0F4F8;
    }

    .content_wrapper1 {
        z-index: 20;
        position: relative;

        .accordion-button:not(.collapsed) {
            background: #304053 !important;
            color: white;
            border-radius: 0px;
            height: 52px;
        }

        .accordion-button:not(.collapsed)::after {
            color: white !important;
        }

        .accordion-button {
            background-color: #7cbaa7;
            height: 52px;
            color: #202020;
            border-radius: 0px;
        }

        .accordion-body {
            padding: 0;
        }
    }

    .content_wrapper_outer {
        background: #CBD5E0;
        border-radius: 25px;
        padding: 10px;
        padding-bottom: 80px;
    }

    .pt_80 {
        padding-top: 80px;
    }

    .fs_10 {
        font-size: 10px;
    }

    .main_wrapper {
        background: #fff;
        border: 4px dashed #7CBAA7;
        padding: 10px;

        .plan_outer_wrapper {
            background: #7CBAA7;
            padding: 5px;



            .margin-left-btn {
                margin-left: 10px;
            }

            .tab_content {
                // width: 750px;
                height: 350px;
                padding: 10px;
                background: #F0F4F8;
                display: flex;
                justify-content: center;
                align-items: center;

                h1 {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 36px;
                    color: #202020;
                }

                h3 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #202020;
                    margin-bottom: 8px;
                }

                small {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #3C3D40;
                }

                h5 {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #202020;
                    margin-bottom: 15px;
                }

                ul {
                    list-style: disc;
                    padding-left: 2rem;

                    li {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        color: #202020;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .tab_btn {
            .btn_left {
                padding: 15px 20px;
                background: #F0F4F8;
                border: 2px solid #304053;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #202020;
            }

            .btn_right {
                padding: 15px 20px;
                background: #7CBAA7;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #202020;
                border: #7CBAA7;
            }
        }
    }
}
