.mobile-help-section{
    .steps-mob-timeline-wrapper{
        width: 100%;
        position: relative;
        margin-top: 40px;
        .steps-timeline-img-wrapper {
            width: 100%;
            max-width: 680px;
            margin: 0 auto;
            position: relative;
            text-align: left;
            .steps-timeline-content {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                .steps-timeline-mob-content-inner {
                    width: 100%;
                    max-width: fit-content;
                    position: absolute;
                    margin: 0 auto;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    text-align: left;
                    justify-content: flex-start;
                    width: 100%;
                    max-width: 270px;
                    position: absolute;
                    margin: 0 auto;

                    .mob-step-counter {
                        width: 100%;
                        max-width: 30px;
                        height: 30px;
                        margin: 0 auto;
                        background-color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 29px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                    span.step-heading {
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 19px;
                        letter-spacing: 0em;
                        color: #202020;
                        margin-top: 10px;
                        display: block;
                    }
                    .steps-timeline-mobile {
                        font-family: Inter;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: left;
                        margin-bottom: 0;
                        margin-top: 5px;
                        color: #3C3D40;
                    }
                    .step-counter {
                        width: 70px;
                        height: 70px;
                        margin: 0 auto;
                        background-color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        font-family: Inter;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 29px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                    span.step-heading {
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 19px;
                        letter-spacing: 0em;
                        color: #202020;
                        margin-top: 10px;
                        display: block;
                    }
                    .steps-timeline {
                        font-family: Inter;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: center;
                        margin-bottom: 0;
                        margin-top: 5px;
                    }
  
                }
            .steps-timeline-mob-content-inner:first-child{
                left: 90px;
                top: 75px;

            }
            .steps-timeline-mob-content-inner:nth-child(2){
                left: 90px;
                top: 175px;

            }
            .steps-timeline-mob-content-inner:nth-child(3){
                left: 90px;
                top: 278px;

            }
            .steps-timeline-mob-content-inner:nth-child(4){
                left: 90px;
                bottom: 190px;

            }
            .steps-timeline-mob-content-inner:nth-child(5){
                left: 90px;
                bottom: 88px;

            }
            .steps-timeline-mob-content-inner:nth-child(6){
                left: 90px;
                bottom: -8px;

            }
            }
          .neumorphic-timeline,.glassmorphic-timeline{
            display: none;
          }
        }

    }
    .timeline-section-content {
        display: block !important;
        align-items: center;
        margin-top: 110px;
       
     
    }
}