@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.diet-programs-main-desktop{
    .img-tranformation {
        display: block;
        margin-top: 50px;
        text-align: center;
        h4 {
          font-family: Inter;
          font-size: 24px;
          font-weight: 600;
          line-height: 29px;
          letter-spacing: 0em;
        }
        .transform-img {
          margin-top: 30px;
        }
        .transform-img-neumorphic,
        .transform-img-glassmorphic {
          display: none;
        }
      }
}
.compare-slider-desktop-parent
{
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 position: relative;
}
.compare-slider-desktop
{
 
}
.overlay-image-left
{
  position: absolute;
  left: 480px;
  z-index: 1;
}
.overlay-image-right
{
  position: absolute;
  right: 480px;
  z-index: 1;
}