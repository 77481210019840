.blogpost-screen-mobile-2 {
    width: 480px;
    margin: 0 auto;
    background-color: #F0F4F8;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

    .bg-whoweare {
        background-color: #CBD5E0;
        padding-left: 50px;
        padding-right: 50px;

        .whoweare-content-1 {
            .whoweare {
                font-size: 30px;
                font-weight: 600;
                line-height: 36px;
                color: #202020;
                font-family: 'inter';

                &::after {
                    content: "";
                    display: block;
                    position: relative;
                    width: 85px;
                    margin-top: 20px;
                    border-bottom: 6px solid #7CBAA7;

                }
            }
        }

        .whoweare-paragraph-1 {
            .paragraph-1 {
                font-size: 12px;
                font-weight: 400;
                line-height: 23px;
                font-family: 'inter';
            }
        }
    }

    .bg-whoweare2 {
        background-color: #CBD5E0;
        padding-left: 25px;
        padding-right: 25px;

        .whoweare-content-2 {
            .whoweare2 {
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                color: #202020;
                font-family: 'inter';

                &::after {
                    content: "";
                    display: block;
                    position: relative;
                    width: 85px;
                    margin-top: 20px;
                    border-bottom: 6px solid #7CBAA7;

                }
            }
        }

        .whoweare-paragraph-2 {
            .paragraph-2 {
                font-size: 12px;
                font-weight: 400;
                line-height: 23px;
                font-family: 'inter';
            }
        }
    }
    .whoweare-paragraph-list{
        .list-items{
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            font-family: 'inter';
            .checkicon{
                font-size: 18px;
            }
        }
    }
}