.subscription-screen-desktop-1 {
  .subscription-desktop-theme1 {
    padding: 250px 0 150px;
    .subscription-desktop-theme1-main-wrapper {
      padding: 250px 100px 50px;
      max-width: 1000px;
      width: 100%;
      margin: auto;
      position: relative;
      &.regular-style {
        background-color: #F0F4F8;
      }
      &.neumorphic-style {
        background-color: #F0F4F8;
        box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
      }
      &.glassmorphic-style {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
        border-radius: 20px;
      }
      .subscribe-img-wrapper {
        padding: 42px 20px 30px 20px;
        text-align: center;
        position: absolute;
        top: -125px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        &.regular-style {
          background-color: white;
        }
        &.neumorphic-style {
          border-radius: 10px;
          background-color: #F0F4F8;
          box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
        }
        &.glassmorphic-style {
          background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.05) 100%);
          backdrop-filter: blur(10px);
          border-radius: 10px;
        }
        .subscribeUserIcon {
          width: 140px;
          height: 140px;
        }
        .subscribeAroundTextImg {
          width: 181px;
          height: 100px;
          position: absolute;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
        .choose-position {
          font-size: 13px;
          line-height: 16px;
          display: block;
          padding: 10px 20px;
          margin: 0 0 0 0;
          // font-family: $inter;
          font-weight: 400;
          &.regular-style {
            color: #FFFFFF;
            background-color: #202020;
          }
          &.neumorphic-style {
            color: #202020;
            background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
          }
          &.glassmorphic-style {
            color: #202020;
            background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
          }
        }
      }
      .regular-main-wrapper {
        position: relative;
        width: 100%;
        padding: 130px 0 0;
        .subscription-images-wrapper {
          position: relative;
          .images-main-wrapper {
            position: absolute;
            bottom: 7px;
            min-height: 350px;
            width: 100%;
            .images-wrapper {
              position: relative;
              padding: 0;
              margin: 0;
              min-height: 350px;
              width: 750px;
              margin: 0 auto;
              z-index: 1;
              img {
                width: 241px !important;
                // height: 260px;
                object-fit: cover;
                position: absolute;
                bottom: 0;
                &:nth-child(1) {
                  // transform: rotate(-10deg);
                  left: 0;
                }
                &:nth-child(2) {
                  // transform: rotate(-7deg);
                  bottom: 68px;
                  left: 152px;
                  z-index: 2;
                }
                &:nth-child(3) {
                  // transform: rotate(7deg);
                  bottom: 75px;
                  right: 152px;
                  z-index: 4;
                }
                &:nth-child(4) {
                  // transform: rotate(10deg);
                  right: 0;
                }
              }
            }
          }
          .sign-up-form {
            padding: 50px 130px;
            z-index: 2;
            position: relative;
            &.regular-style {
              background-color: #202020;
            }
            &.neumorphic-style {
              background-color: #F0F4F8;
              margin: 0 0 20px 0;
              box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
            }
            &.glassmorphic-style {
              margin: 0 0 20px 0;
              backdrop-filter: blur(10px);
              background: linear-gradient(135deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.02) 100%);
            }
            form {
              width: 100%;
              .form-group {
                display: flex;
                justify-content: center;
                align-items: center;
                &.regular-style {
                  input {
                    background-color: white;
                  }
                  button {
                    color: #3C3D40;
                    background: #7CBAA7;
                    &:hover {
                      background: #7CBAA7 !important;
                      color: #3C3D40;
                      border: none;
                    }
                  }
                }
                &.neumorphic-style {
                  input {
                    background-color: #F0F4F8;
                    box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
                  }
                  button {
                    color: #3C3D40;
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  }
                }
                &.glassmorphic-style {
                  input {
                    border-radius: 4px;
                    backdrop-filter: blur(10px);
                    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
                  }
                  button {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    border-radius: 4px;
                  }
                }
              }
              input {
                border-radius: 0;
                height: 46px;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                // font-family: $inter;
                &:focus {
                  box-shadow: none;
                  border: none;
                }
                &::placeholder {
                  // font-family: $inter;
                  color: #5F6168;
                }
              }
              button {
                height: 46px;
                padding: 15px 30px;
                font-size: 13px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.52px;
                text-transform: uppercase;
                margin: 0 0 0 2px;
                box-shadow: none;
                border-radius: 0;
                border: none;
                // font-family: $inter;
              }
            }
          }
        }
        .on-off-buttons-wrapper {
          padding: 60px 20px;
          &.regular-style {
            background-color: white;
          }
          &.neumorphic-style {
            background-color: #F0F4F8;
            box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
          }
          &.glassmorphic-style {
            background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
          }
          .btns-list {
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            li {
              // font-family: $inter;
              text-align: center;
              .labelSwitch {
                position: relative;
                display: inline-block;
                font-size: inherit;
                input {
                  opacity: 0;
                  width: 0;
                  height: 0;
                }
                input:checked + .span {
                  // background-color: #7CBAA7;
                }
                input:checked + .span:before {
                  transform: translateX(14px);
                  // background-color: #D3FFB0;
                }
                .span {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  // background: #B2BDCB;
                  transition: 0.3s;
                  border-radius: 30px;
                  width: 36px;
                  margin: 0 auto;
                  height: 21px;
                  &:before {
                    position: absolute;
                    content: "";
                    height: 16px;
                    width: 16px;
                    left: 3px;
                    bottom: 2.4px;
                    // background-color: #F0F4F8;
                    border-radius: 50%;
                    transition: 0.3s;
                  }
                  &.neumorphic {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    &::before {
                      background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    }
                  }
                }
                strong {
                  display: block;
                  // font-family: $inter;
                  margin: 12px 0 0 0;
                  font-weight: 400;
                  color: #202020;
                  font-size: 14px;
                }
                &.regular-style {
                  .span {
                    background-color: #B2BDCB;
                    &:before {
                      background-color: #F0F4F8
                    }
                  }
                  input:checked + .span {
                    background-color: #7CBAA7;
                  }
                  input:checked + .span:before {
                    background-color: #D3FFB0;
                  }
                }
                &.neumorphic-style {
                  .span {
                    background: #E5E9ED;
                    background-image: linear-gradient(#4E4E57, #E5E9ED);
                    &:before {
                      background-color: #4E4E57;
                    }
                  }
                  input:checked + .span {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  }
                  input:checked + .span:before {
                    background-image: linear-gradient(#4E4E57, #E5E9ED);
                  }
                }
                &.glassmorphic-style {
                  .span {
                    background: #ffffff;
                    &:before {
                      background-color: #3C3D40;
                    }
                  }
                  input:checked + .span {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  }
                  input:checked + .span:before {
                    background-color: #3C3D40;
                  }
                }
              }

              .toggle-switch {
                position: relative;
                width: 75px;
                display: inline-block;
                text-align: left;
                top: 8px;
              }
              .checkbox {
                display: none;
              }
              .label {
                display: block;
                overflow: hidden;
                cursor: pointer;
                border: 0 solid #bbb;
                border-radius: 20px;
              }
              .inner {
                display: block;
                width: 200%;
                margin-left: -100%;
                transition: margin 0.3s ease-in 0s;
              }
              .inner:before,
              .inner:after {
                float: left;
                width: 50%;
                height: 36px;
                padding: 0;
                line-height: 36px;
                color: #fff;
                font-weight: bold;
                box-sizing: border-box;
              }
              .inner:before {
                content: "YES";
                padding-left: 10px;
                background-color: #060;
                color: #fff;
              }
              .inner:after {
                content: "NO";
                padding-right: 10px;
                background-color: #bbb;
                color: #fff;
                text-align: right;
              }
              .switch {
                display: block;
                width: 24px;
                margin: 5px;
                background: #fff;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 40px;
                border: 0 solid #bbb;
                border-radius: 20px;
                transition: all 0.3s ease-in 0s;
              }
              .checkbox:checked + .label .inner {
                margin-left: 0;
              }
              .checkbox:checked + .label .switch {
                right: 0px;
              }
            }
          }
        }
      }
    }
  }
}
