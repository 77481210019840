.tablet-regular-help-section{
    .steps-tab-timeline-wrapper{
        width: 100%;
        position: relative;
        margin-top: 40px;
        .steps-timeline-img-wrapper {
            width: 100%;
            max-width: 680px;
            margin: 0 auto;
            position: relative;
            .steps-timeline-content {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                .steps-timeline-content-inner {
                    width: 100%;
                    max-width: 180px;
                    position: absolute;
                    margin: 0 auto;

                    .step-counter {
                        width: 70px;
                        height: 70px;
                        margin: 0 auto;
                        background-color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 10px;
                        font-family: Inter;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 29px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                    span.step-heading {
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 19px;
                        letter-spacing: 0em;
                        color: #202020;
                        margin-top: 10px;
                        display: block;
                    }
                    .steps-timeline {
                        font-family: Inter;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0em;
                        text-align: center;
                        margin-bottom: 0;
                        margin-top: 5px;
                        color: #3C3D40;
                    }
  
                }
                  .steps-timeline-content-inner {
                width: 100%;
                max-width: 180px;
                position: absolute;
                margin: 0 auto;
                .step-counter {
                    width: 70px;
                    height: 70px;
                    margin: 0 auto;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    font-family: Inter;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 29px;
                    letter-spacing: 0em;
                    text-align: left;
                }
                span.step-heading {
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                    letter-spacing: 0em;
                    color: #202020;
                    margin-top: 10px;
                    display: block;
                }
                .steps-timeline {
                    font-family: Inter;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0em;
                    text-align: center;
                    margin-bottom: 0;
                    margin-top: 5px;
                }

            }
            .steps-timeline-content-inner:first-child{
                left: 0px;
                top: 25px;

            }
            .steps-timeline-content-inner:nth-child(2){
                right: 0px;
                top: 135px;

            }
            .steps-timeline-content-inner:nth-child(3){
                left: 0px;
                top: 230px;

            }
            .steps-timeline-content-inner:nth-child(4){
                right: 0px;
                bottom: 160px;

            }
            .steps-timeline-content-inner:nth-child(5){
                left: 0px;
                bottom: 60px;

            }
            .steps-timeline-content-inner:nth-child(6){
                right: 0px;
                bottom: -50px;

            }
            }
          .neumorphic-timeline,.glassmorphic-timeline{
            display: none;
          }
        }

    }
}