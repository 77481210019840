.service-package-plan-glassmorphic-desktop-2 {
    background: linear-gradient(315.3deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%);

    .toggle_button {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            width: 97px;
            height: 35px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.7) 3.01%, rgba(255, 255, 255, 0.02) 103.3%);
            border-radius: 30px;
            color: #000000;
            text-align: center;
            border: none ;
        }

    }


    .green_btn1 {
        width: 125px;
        height: 35px;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        border-radius: 30px;
        font-size: 12px;
        color: #000000;
        text-align: center;
        border: none;
    }
    .mt_20{
        margin-top: 20px;
    }
    .plan_outer {
        width: 300px;
        height: 710px;
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        border-radius: 20px;
        position: relative;
        .plan_listing{
            ul {
                margin-top: 4rem;
                li {
                    color: #000000;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    line-height: 17px;
                    padding: .75rem 1rem;
                    &:nth-of-type(odd){
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        // background: #FFFFFF;
                    }
    
                    .icon {
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-right: 8px;
                    }
    
                    .text_yellow {
                        color: #FF7714;
                    }
                }
            }
        }
    }
    .price {
        position: absolute;
        width: 140px;
        height: 140px;
        top: -75px;
        border-radius: 50%;
        backdrop-filter: blur(10px);
        border: 1px solid #F0F4F8;
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2 {
            font-weight: 700;
            font-size: 48px;
            line-height: 40px;
            text-align: center;
            color: #304053;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #304053;
        }
    }
    .plan_heading {
        flex-direction: column;
        h1 {
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;
            letter-spacing: 0.08em;
            color: #202020;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #202020;
        }
    }
    #base {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.7) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        display: flex;
        justify-content: center;
        margin-left: -5px;
        position: relative;
        width: 310px;
        height: 250px;
        position: relative;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        &:before {
            border-top: 50px solid rgba(255, 255, 255, 0.49);
            border-left: 155px solid transparent;
            border-right: 155px solid transparent;
            content: "";
            height: 0;
            left: 0;
            position: absolute;
            bottom: -50px;
            width: 0;
        }
    }
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 124px;
        height: 46px;
        background: #304053;
        border-radius: 30px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        position: absolute;
        bottom: -22px;
    }
    
}