@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');

.Feature-on-tab-2{

  
  .features-on-design-tab-2{
    background-color: #cbd5e0;
    width: 768px;
    
    height: 900px;
    margin: auto;
    flex-direction: column;
    padding: 40px 80px 40px 80px;
  }
    .grey-box-tab-2{
    background-color: #CBD5E0;
}
    .image-circle-tab-2{
      background-color: #ffffff;
        
        border-radius: 50%;
        display: flex;
        
        align-items: center;
        justify-content: center;
        margin: 2px -10px 0px 0px ;
        
        z-index: 90;
        img{
        margin: 0px;
      }  
    }
      
      .image-usa {
        background-color: #1EA3FF ;
      
        
      }
      
      .box-view-tab-2 {
        border-radius: 10px;
        background-color: black;
      }
      .box-view-tab-2 p{
        font-size: 35px;
        font-weight: bolder;
        color: white;
      }
      .text-center-tab-2{
        
      
        z-index: -2;
      }
      
      .box-container-tab-2{
        margin-top: -70px;
      }
      
    }
  