.service-package-plan-glassmorphic-tab-1 {
    background: linear-gradient(315.3deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%);
    width: 768px;
    margin: auto;
    // height: 1075px;

    .toggle_button {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            width: 97px;
            height: 35px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.7) 3.01%, rgba(255, 255, 255, 0.02) 103.3%);
            // box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
            border-radius: 30px;
            color: #000000;
            text-align: center;
            border: none;

        }

    }

    .relative {
        position: relative;
    }

    .main_row {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        border-radius: 20px;
        height: 930px;
        z-index: 0;
        position: absolute;
        width: 700px;
    }

    .plan_content {
        z-index: 20;
        position: relative;

        h2 {
            color: #202020;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            text-align: center;
        }

        ul {
            li {
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: 17px;
                margin-bottom: 20px;

            }
        }

        .signin_btn {
            width: 110px;
            height: 110px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: none;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn_inner {
                width: 100px;
                height: 97px;
                border-radius: 50%;
                color: #202020;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    display: block;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

           
            .basic_btn_inner {
                background: #E3BDB5;
            }

            .professional_btn_inner {
                background: #9DCDA4;
            }

            .luxury_btn_inner {
                background: #E1C285;
            }
        }
    }

    .services_list {
        ul {
            li {
                color: #000000;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                line-height: 17px;
                margin-bottom: 15px;

                .icon {
                    width: 1.25rem;
                    height: 1.25rem;
                    margin-right: 8px;
                }

                .text_yellow {
                    color: #FF7714;
                }
            }
        }

    }

    .plan_div {
        h1 {
            font-weight: 600;
            font-size: 22px;
            line-height: 36px;
            text-align: center;
            padding-top: 10px;
            color: #3C3D40;
        }

        .text_dark {
            color: #202020;
        }

        .text_pink {
            color: #E3BDB5;
        }

        .text_green {
            color: #9DCDA4;
        }

        .text_orange {
            color: #E1C285;
        }
    }

    .arrow_icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 8px;
    }

    .plan_outer {
        width: 200px;
        height: 710px;
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        backdrop-filter: blur(10px);
        border-radius: 10px 10px 200px 200px;
    }

    .price {
        h2 {
            font-weight: 700;
            font-size: 38px;
            line-height: 40px;
            text-align: center;
            color: #202020;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #202020;
        }
    }

    .ribbon1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 20px);
        height: 100px;
        line-height: 50px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #E3BDB5;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid #E5A091;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            bottom: -10px;
            border-top: 10px solid #E5A091;
            border-right: 10px solid transparent;
        }
    }

    .ribbon_1 {
        display: block;
        width: calc(100% + 20px);
        height: 10px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #E3BDB5;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -10px;
            left: 0;
            border-bottom: 10px solid #E5A091;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: -10px;
            border-bottom: 10px solid #E5A091;
            border-right: 10px solid transparent;
        }
    }



    .ribbon2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 20px);
        height: 100px;
        line-height: 50px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #9DCDA4;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid #73A178;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            bottom: -10px;
            border-top: 10px solid #73A178;
            border-right: 10px solid transparent;
        }
    }

    .ribbon_2 {
        display: block;
        width: calc(100% + 20px);
        height: 10px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #9DCDA4;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -10px;
            left: 0;
            border-bottom: 10px solid #73A178;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: -10px;
            border-bottom: 10px solid #73A178;
            border-right: 10px solid transparent;
        }
    }



    .ribbon3 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 20px);
        height: 100px;
        line-height: 50px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #E1C285;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid #D3B57D;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            bottom: -10px;
            border-top: 10px solid #D3B57D;
            border-right: 10px solid transparent;
        }
    }

    .ribbon_3 {
        display: block;
        width: calc(100% + 20px);
        height: 10px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #E1C285;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -10px;
            left: 0;
            border-bottom: 10px solid #D3B57D;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: -10px;
            border-bottom: 10px solid #D3B57D;
            border-right: 10px solid transparent;
        }
    }

}