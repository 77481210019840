@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

///Regular Version Theme 2 
.regular3-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbd5e0;
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular3_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 420px;
            height: 434px;
            background-color: white;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 54%;
                left: 40%;

            }



            .see-my-wall-div {
                margin: 0px;
                padding: 0px;

                p {
                    color: black;
                    margin: 0px;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(124, 186, 167, 1);
                    color: rgba(32, 32, 32, 1);
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .comma-div {

                display: flex;
                justify-content: flex-end;

                .comma-img-div {
                    background-image: url("../../../../../images/testimonial-case-study/regular3_desktop_commas.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 84px;
                    height: 84px;
                }
            }

            .profile-detail-div {
                color: #202020;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                .avatar-div {
                    width: 100px;
                    height: 100Px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

        }
    }
}

.regular3-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbd5e0;
    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular3_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 420px;
            height: 434px;
            background-color: white;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 54%;
                left: 40%;

            }



            .see-my-wall-div {
                margin: 0px;
                padding: 0px;

                p {
                    color: black;
                    margin: 0px;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(124, 186, 167, 1);
                    color: rgba(32, 32, 32, 1);
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .comma-div {

                display: flex;
                justify-content: flex-end;

                .comma-img-div {
                    background-image: url("../../../../../images/testimonial-case-study/regular3_desktop_commas.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 84px;
                    height: 84px;
                }
            }

            .profile-detail-div {
                color: #202020;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                .avatar-div {
                    width: 100px;
                    height: 100Px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

        }
    }
}

.regular3-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 1000px;
    margin-bottom: 50px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular3_mobile_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 300px;
            height: 388px;
            background-color: white;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 54%;
                left: 35%;

            }



            .see-my-wall-div {
                margin: 0px;
                padding: 0px;

                p {
                    color: black;
                    margin: 0px;

                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(124, 186, 167, 1);
                    color: rgba(32, 32, 32, 1);
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .comma-div {

                display: flex;
                justify-content: flex-end;

                .comma-img-div {
                    background-image: url("../../../../../images/testimonial-case-study/regular3_desktop_commas.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 64px;
                    height: 60px;
                }
            }

            .profile-detail-div {
                color: #202020;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                .avatar-div {
                    width: 100px;
                    height: 100Px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                h1 {
                    font-size: 14px;
                    font-weight: 600;

                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;

                }

                h3 {
                    font-size: 11px;
                    font-weight: 400;
                    width: 260px;
                    text-align: center;
                    line-height: 17.6px;
                }

            }

        }
    }
}

//////////////////////////////////////////////////////////
.neumorphic3-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F4F8;
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic3_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #F0F4F8;
        margin-top: 100px;

        .profile-div {
            width: 420px;
            height: 434px;
            background-color: #F0F4F8;
            position: relative;
            border-style: inset;
            border: 5px solid white;

            .rating-stars-div {
                position: absolute;
                top: 54%;
                left: 40%;

            }



            .see-my-wall-div {
                margin: 0px;
                padding: 0px;

                p {
                    color: black;
                    margin: 0px;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(124, 186, 167, 1);
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .comma-div {

                display: flex;
                justify-content: flex-end;

                .comma-img-div {
                    background-image: url("../../../../../images/testimonial-case-study/neumorphic3_desktop_commas.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 84px;
                    height: 84px;
                }
            }

            .profile-detail-div {
                color: #202020;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                .avatar-div {
                    width: 100px;
                    height: 100Px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

        }
    }
}


.neumorphic3-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F4F8;
    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic3_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #F0F4F8;
        margin-top: 100px;

        .profile-div {
            width: 420px;
            height: 434px;
            background-color: #F0F4F8;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 54%;
                left: 40%;

            }



            .see-my-wall-div {
                margin: 0px;
                padding: 0px;

                p {
                    color: black;
                    margin: 0px;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(124, 186, 167, 1);
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .comma-div {

                display: flex;
                justify-content: flex-end;

                .comma-img-div {
                    background-image: url("../../../../../images/testimonial-case-study/neumorphic3_desktop_commas.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 84px;
                    height: 84px;
                }
            }

            .profile-detail-div {
                color: #202020;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                .avatar-div {
                    width: 100px;
                    height: 100Px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

        }
    }
}

.neumorphic3-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 1000px;
    margin-bottom: 50px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic3_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #F0F4F8;
        margin-top: 100px;

        .profile-div {
            width: 280px;
            height: 388px;
            background-color: #F0F4F8;
            position: relative;
            transform: translate(10px, 10px);

            .rating-stars-div {
                position: absolute;
                top: 55%;
                left: 35%;

            }



            .see-my-wall-div {
                margin: 0px;
                padding: 0px;

                p {
                    color: black;
                    margin: 0px;

                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(124, 186, 167, 1);
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .comma-div {

                display: flex;
                justify-content: flex-end;

                .comma-img-div {
                    background-image: url("../../../../../images/testimonial-case-study/neumorphic3_desktop_commas.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 60px;
                    height: 64px;
                }
            }

            .profile-detail-div {
                color: #202020;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                .avatar-div {
                    width: 100px;
                    height: 100Px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                h1 {
                    font-size: 14px;
                    font-weight: 600;

                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;

                }

                h3 {
                    font-size: 11px;
                    font-weight: 400;
                    width: 260px;
                    text-align: center;
                    line-height: 17.6px;
                }

            }

        }
    }
}

///Glassmorphic Version Theme 2 
.glassmorphic3-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(47, 38, 38, 0.27);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(47, 38, 38, 0.3);
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/glassmorphic3_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 420px;
            height: 434px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 54%;
                left: 40%;

            }



            .see-my-wall-div {
                margin: 0px;
                padding: 0px;

                p {
                    color: black;
                    margin: 0px;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(124, 186, 167, 1);
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .comma-div {

                display: flex;
                justify-content: flex-end;

                .comma-img-div {
                    background-image: url("../../../../../images/testimonial-case-study/neumorphic3_desktop_commas.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 84px;
                    height: 84px;
                }
            }

            .profile-detail-div {
                color: #202020;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                .avatar-div {
                    width: 100px;
                    height: 100Px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

        }
    }
}

.glassmorphic3-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(47, 38, 38, 0.27);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(47, 38, 38, 0.3);
    width: 768px;
    
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/glassmorphic3_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: contain;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 420px;
            height: 434px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 54%;
                left: 40%;

            }



            .see-my-wall-div {
                margin: 0px;
                padding: 0px;

                p {
                    color: black;
                    margin: 0px;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(124, 186, 167, 1);
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .comma-div {

                display: flex;
                justify-content: flex-end;

                .comma-img-div {
                    background-image: url("../../../../../images/testimonial-case-study/neumorphic3_desktop_commas.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 84px;
                    height: 84px;
                }
            }

            .profile-detail-div {
                color: #202020;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                .avatar-div {
                    width: 100px;
                    height: 100Px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

        }
    }
}

.glassmorphic3-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 320px;
    height: 1000px;
    margin-bottom: 50px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/glassmorphic3_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 300px;
            height: 388px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 55%;
                left: 35%;

            }



            .see-my-wall-div {
                margin: 0px;
                padding: 0px;

                p {
                    color: black;
                    margin: 0px;

                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(124, 186, 167, 1);
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                    font-size: 13px;
                    font-weight: 500;
                }
            }

            .comma-div {

                display: flex;
                justify-content: flex-end;

                .comma-img-div {
                    background-image: url("../../../../../images/testimonial-case-study/neumorphic3_desktop_commas.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 60px;
                    height: 64px;
                }
            }

            .profile-detail-div {
                color: #202020;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;

                .avatar-div {
                    width: 100px;
                    height: 100Px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                h1 {
                    font-size: 14px;
                    font-weight: 600;

                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;

                }

                h3 {
                    font-size: 11px;
                    font-weight: 400;
                    width: 260px;
                    text-align: center;
                    line-height: 17.6px;
                }

            }

        }
    }
}