.subscription-screen-mobile-1 {
  .regular-theme-mobile-main-wrapper {
    max-width: 320px;
    padding: 74px 0 100px 0;
    margin: auto;
    .subscribe-img-wrapper {
      padding: 42px 20px 30px 20px;
      text-align: center;
      max-width: 228px;
      margin: 0 auto;
      position: relative;
      &.regular-style {
        background-color: white;
      }
      &.neumorphic-style {
        border-radius: 10px;
        background-color: #F0F4F8;
        box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      }
      &.glassmorphic-style {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.05) 100%);
        backdrop-filter: blur(10px);
        border-radius: 10px;
      }
      .subscribeUserIcon {
        width: 140px;
        height: 140px;
      }
      .subscribeAroundTextImg {
        width: 181px;
        height: 100px;
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
      }
      .choose-position {
        font-size: 13px;
        line-height: 16px;
        display: block;
        padding: 10px 14px;
        margin: 0 0 0 0;
        font-weight: 400;
        &.regular-style {
          color: #FFFFFF;
          background-color: #202020;
        }
        &.neumorphic-style {
          color: #202020;
          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
        }
        &.glassmorphic-style {
          color: #202020;
          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
        }
      }
    }
    .sign-up-form-main-wrapper {
      padding: 15px 10px;
      .sign-up-form {
        padding: 20px 10px;
        z-index: 2;
        position: relative;
        &.regular-style {
          background-color: #202020;
        }
        &.neumorphic-style {
          background-color: #F0F4F8;
          margin: 0 0 20px 0;
          box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
        }
        &.glassmorphic-style {
          margin: 0 0 20px 0;
          backdrop-filter: blur(10px);
          background: linear-gradient(135deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.02) 100%);
        }
        form {
          width: 100%;
          .form-group {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 2px;
            &.regular-style {
              input {
                background-color: white;
              }
              button {
                color: #3C3D40;
                background: #7CBAA7;
                &:hover {
                  background: #7CBAA7 !important;
                  color: #3C3D40;
                  border: none;
                }
              }
            }
            &.neumorphic-style {
              input {
                background-color: #F0F4F8;
                box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
              }
              button {
                color: #3C3D40;
                background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
              }
            }
            &.glassmorphic-style {
              input {
                border-radius: 4px;
                backdrop-filter: blur(10px);
                background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
                margin: 0 0 3px 0;
              }
              button {
                background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                border-radius: 4px;
              }
            }
          }
          input {
            border-radius: 0;
            height: 46px;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            text-align: center;
            &:focus {
              box-shadow: none;
              border: none;
            }
            &::placeholder {
              color: #5F6168;
            }
          }
          button {
            height: 46px;
            padding: 15px 30px;
            font-size: 13px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.52px;
            text-transform: uppercase;
            box-shadow: none;
            border-radius: 0;
            border: none;
            width: 100%;
            display: block;
            margin: 0;
          }
        }
      }
    }
    .regular-theme1-mobile-slider {
      padding: 60px 0 50px 0;
      &.neumorphic-style {
        .slick-slider {
          .slick-dots {
            li {
              button {
                &::before {
                  color: #CBD5E0;
                }
              }
              &.slick-active {
                button {
                  &::before {
                    font-size: 14px;
                    opacity: 1;
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
      &.glassmorphic-style {
        .slick-slider {
          .slick-dots {
            li {
              button {
                &::before {
                  color: #ffffff;
                }
              }
              &.slick-active {
                button {
                  &::before {
                    font-size: 14px;
                    opacity: 1;
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              position: relative;
              // display: none;
              .subscription-regular-theme1-mobile-sliderimg {
                height: 258px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
        .slick-prev {
          background: transparent;
          top: -13px;
          left: 34%;
          &::before {
            content: '';
            height: 24px;
            width: 24px;
            display: block;
            top: -15px;
            left: 34%;
            background-repeat: no-repeat;
            position: absolute;
            background-image: url('../../../../../../images/subscription/subscription-regular-theme1-mobile-slider-prev-icon.svg');
          }
        }
        .slick-next {
          background: transparent;
          top: -39px;
          right: 34%;
          &::before {
            content: '';
            height: 24px;
            width: 24px;
            display: block;
            background-repeat: no-repeat;
            position: absolute;
            background-image: url('../../../../../../images/subscription/subscription-regular-theme1-mobile-slider-next-icon.svg');
          }
        }
        .slick-dots {
          bottom: -35px;
          li {
            margin: 0;
            button {
              &:before {
                color: #304053;
                font-size: 9px;
                opacity: 1;
              }
            }
            &.slick-active {
              button {
                &::before {
                  color: #7CBAA7;
                  font-size: 14px;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
    .on-off-buttons-wrapper {
      padding: 0 10px;
      &.regular-style {
        background-color: transparent;
      }
      &.neumorphic-style {
        padding: 20px 10px 0;
        background-color: #F0F4F8;
        box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
      }
      &.glassmorphic-style {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
      }
      .btns-list {
        display: block;
        margin: 0;
        padding: 0;
        li {
          // font-family: $inter;
          display: inline-block;
          margin: 0 10px 20px 0;
          .labelSwitch {
            position: relative;
            display: inline-block;
            font-size: inherit;
            input {
              opacity: 0;
              width: 0;
              height: 0;
            }
            input:checked + .span {
            }
            input:checked + .span:before {
              transform: translateX(14px);
            }
            .span {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              transition: 0.3s;
              border-radius: 30px;
              width: 36px;
              margin: 0 auto;
              height: 21px;
              &:before {
                position: absolute;
                content: "";
                height: 16px;
                width: 16px;
                left: 3px;
                bottom: 2.4px;
                border-radius: 50%;
                transition: 0.3s;
              }
              &.neumorphic {
                background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                &::before {
                  background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                }
              }
            }
            &.regular-style {
              .span {
                background-color: #B2BDCB;
                &:before {
                  background-color: #F0F4F8
                }
              }
              input:checked + .span {
                background-color: #7CBAA7;
              }
              input:checked + .span:before {
                background-color: #D3FFB0;
              }
            }
            &.neumorphic-style {
              .span {
                background: #E5E9ED;
                background-image: linear-gradient(#4E4E57, #E5E9ED);
                &:before {
                  background-color: #4E4E57;
                }
              }
              input:checked + .span {
                background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
              }
              input:checked + .span:before {
                background-image: linear-gradient(#4E4E57, #E5E9ED);
              }
            }
            &.glassmorphic-style {
              .span {
                background: #ffffff;
                &:before {
                  background-color: #3C3D40;
                }
              }
              input:checked + .span {
                background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
              }
              input:checked + .span:before {
                background-color: #3C3D40;
              }
            }
          }
          strong {
            display: inline-block;
            font-weight: 400;
            color: #202020;
            font-size: 14px;
            padding: 0 0 0 40px;
          }
        }
      }
    }
  }
}
