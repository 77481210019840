.img_wrapper {
    position: relative;
    background-image: url('../../../images/screen-frame.png');
    background-size: cover;
    background-repeat: no-repeat;
    // min-height: 670px;
    height: 670px;
    // min-height: 770px;
    width: 690px;
    // width: 795px;
    // padding: 50px;
    padding: 25px;
    // padding-top: 75px;
    align-items: center;
    display: flex;
    padding-bottom: 145px;

    // @media (max-width: 1890px) and (min-width: 1630px) {
    //     height: 100%;
    //     padding: 22px;
    //     padding-bottom: 140px;
    // }

    // @media (max-width: 1630px) and (min-width: 1560px) {
    //     // height: 550px;
    //     height: 560px;
    //     padding: 19px;
    //     padding-bottom: 100px;
    // }

    // @media (max-width: 1559px) and (min-width: 1500px) {
    //     height: 500px;
    // }

    // @media (max-width: 1500px) and (min-width: 1400px) {
    //     height: 470px;
    //     padding: 20px;
    //     padding-bottom: 110px;
    //     // padding-bottom: 100px;
    // }

    // @media (max-width: 1399px) and (min-width: 1291px) {
    //     background-size: contain;
    //     height: 95%;
    //     padding: 15px;
    //     padding-bottom: 100px;
    // }

    // @media (max-width: 1290px) and (min-width: 1200px) {
    //     background-size: cover;
    //     height: 515px;
    //     padding: 15px;
    //     padding-bottom: 100px;
    // }

    .f_8 {
        @media (max-width: 1500px) and (min-width: 1400px) {
            font-size: 8px !important;
        }
    }

    .frame-inner {
        background-color: #EBF3FE !important;
        display: flex;
        align-items: center;
        height: 520px;
        // box-shadow: inset -10px -10px 34px 4px rgba(128, 128, 128, 0.2);
        box-shadow: inset -4px -4px 6px 2px rgba(128, 128, 128, 0.2);
        padding: 15px;

        // @media (max-width: 1890px) {
        //     // height: 100%;
        //     height: 460px;
        // }

        // @media (max-width: 1630px) and (min-width: 1440px) {
        //     height: 370px;
        //     // height: 400px;
        //     margin-top: 20px;
        // }

        .chart-view {
            img {
                width: 125px;
                height: 125px;
                display: block;
                margin: 0 auto;
                object-fit: contain;

                // @media (max-width: 1890px) {
                //     width: 80px;
                //     height: 80px;
                // }
            }
        }

        .frame-padding {
            // box-shadow: 2.244px 2.244px 8.4881px #999FB0;
            padding: 20px;
            // height: 100%;
            background: #EBF3FF;

            // @media (max-width: 1500px) {
            //     padding: 10px;
            // }
        }
    }

    .growth-content {
        padding: 10px;
        background: #EBF3FF;
        // box-shadow: -2px -2px 10px #FFFFFF, 5px 5px 10px #999FB0;
        box-shadow: -2px -2px 4px #FFFFFF, 2px 2px 4px #999FB0;
        border-radius: 5px;
        text-align: center;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        // @media (max-width: 1399px) and (min-width: 1200px) {
        //     padding: 5px;
        // }

        span {
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 0em;
        }

        span.year-color {
            color: #274AFF;
            font-weight: 800 !important;
            font-size: 18px !important;

            // @media (max-width: 1399px) and (min-width: 1200px) {
            //     font-size: 14px !important;
            // }
        }

        .positive-btn,
        .negative-btn {
            border-radius: 50px;
            text-align: center;
            padding: 12px;
            background: #EBF3FF;
            display: flex;
            align-items: center;
            justify-content: center;
            // box-shadow: inset -4.65517px -4.65517px 9.31034px #FFFFFF, inset 4.65517px 4.65517px 9.31034px #999FB0;
            text-transform: capitalize;
            position: relative;
            width: 100%;
            max-width: 120px;
            margin: 0 auto;

        }

        .positive-btn:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
            border-radius: 100%;
            background-color: #1FE31F;
        }

        .negative-btn:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            border-radius: 100%;
            background-color: #Ec313c;
        }

        .inner-content-shadow {
            // filter: drop-shadow(-5px -5px 10px #FFFFFF) drop-shadow(5px 5px 10px #999FB0);
            box-shadow: -2px -2px 4px #FFFFFF, 2px 2px 4px #999FB0;
            border-radius: 5px;
            padding: 8px;
            width: fit-content;

            .growth-percentage {
                font-weight: 800 !important;
                color: #274AFF;
                font-size: 18px !important;
            }
        }

        .growth-img {
            height: 40px;
            object-fit: cover;
        }

        .market-impact-text {
            display: block;
            margin: 10px auto 0;

            span {
                font-size: 18px;

                // @media (max-width: 1890px) {
                //     font-size: 14px;
                // }

                // @media (max-width: 1630px) and (min-width: 1300px) {
                //     font-size: 12px;
                // }
            }
        }

        .negative-btn {
            justify-content: flex-start !important;
            margin-top: 10px;
        }
    }

    .percentage-circle {
        position: relative;
        display: flex;
        justify-content: center;

        .inner-content-shadow {
            background: #EBF3FF;
            // filter: drop-shadow(-5px -5px 10px #FFFFFF) drop-shadow(5px 5px 10px #999FB0);
            box-shadow: -2px -2px 4px #FFFFFF, 2px 2px 4px #999FB0;
            border-radius: 5px;
            padding: 5px 20px;
            width: fit-content;
            position: absolute;
            top: 1rem;

            .growth-percentage {
                font-weight: 800 !important;
                color: #274AFF;
                font-size: 18px !important;
            }
        }

        img {
            width: 175px !important;
            height: 175px !important;
        }
    }

    .onject_fill {
        object-fit: fill;
    }

    .w-125 {
        width: 125px !important;
    }

    .frame-button {
        position: absolute;
        bottom: 6px;
        left: -5px;

        .btn-bg button {
            background: #00FFFF;
            color: #737373 !important;
            background-color: #00FFFF !important;
        }

        .btn-bg.lg button {
            top: 7px;
            transform: rotate(8deg);
        }
    }

    .frame-badge {
        position: absolute;
        top: -20px;
        right: -40px;
        width: 90px;
        height: 90px;
        // height: 110px;
        border-radius: 100%;
        text-align: center;
        padding: 10px;
        // background: #EBF3FF;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%), #EBF3FF;
        display: flex;
        align-items: center;
        justify-content: center;
        // box-shadow: inset -4.65517px -4.65517px 9.31034px #FFFFFF, inset 4.65517px 4.65517px 9.31034px #999FB0;

        // @media (max-width: 1890px) {
        //     width: 80px;
        //     height: 80px;
        // }

        .frame-badge-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70px;
            width: 70px;
            // width: 80px;
            border-radius: 100%;

            // @media (max-width: 1890px) {
            //     width: 60px;
            //     height: 60px;
            // }

            span {
                // font-size: 10px;
                // font-weight: 700;
                // line-height: 18px;
                // letter-spacing: 0em;
                // text-align: center;
                font-size: 13px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: center;
                text-transform: uppercase;

                // @media (max-width: 1890px) {
                //     font-size: 10px;
                //     line-height: 14px;
                // }

                // @media (max-width: 1559px) and (min-width: 1300px) {
                //     font-size: 8px;
                //     line-height: 10px;
                // }
            }
        }

        .frame-badge-pink {
            background-color: #FFBFCA;
        }

        .frame-badge-golden {
            background-color: #EBC939;
        }

        .frame-badge-red {
            background-color: #FE6161;
        }

        .frame-badge-purple {
            background-color: #B4A7D6;
        }

        .frame-badge-green {
            background-color: #93C47D;
        }

        .frame-badge-orange {
            background-color: #F6B26B;
        }

        .frame-badge-blue {
            background-color: #00FFFF;
        }

        .frame-badge-amber {
            background-color: #BF9000;
        }

        .frame-badge-light {
            background-color: #D0E0E3;
        }

        .frame-badge-lightpink {
            background-color: #E6B8AF;
        }

        .frame-badge-parrot {
            background-color: #00FF00;
        }

    }
}