@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
// ammar css start
.h_1550{
  height: 1550px;
}
.RegularTheme1Desktop {
  padding-top: 50px;
  // height: 1150px;
  width: 100%;
  .top_heading{
    font-size: 30px;
    line-height: 36.31px;
    font-weight: 600;
    color: #202020;
  }
}
.bgColorRegular {
  background-color: #cbd5e0 !important;
}
.bgColorNeumorphic {
  background-color: transparent !important;
}
.RegularTheme1Desktop .text-center-desk {
  margin: 10px auto;
  width: 50%;
  text-align: center;
  padding-left: 11px;
  margin-bottom: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #3c3d40;
  line-height: 24px;
}
.RegularTheme1Desktop .text-center-desk p {
  margin-left: 11% !important;
}
.RegularTheme1Desktop .position-nav-desk {
  position: absolute;
  right: 11%;
  top: 260px !important;
  width: 38%;
  background-color: white;
  height: 150px;
}

.RegularTheme1Desktop .main-container-desk {
  height: 645px;
  width: 51%;
  border-radius: 0px;
  // background: #202020;
  margin: 0px auto;
  @media screen and (min-width: 1500px) {
    .RegularTheme1Desktop .main-container-desk {
      width: 70% !important;
    }
  }
}
.RegularTheme1Desktop .position-box-desk {
  width: 20%;
  // background: #7cbaa7;
  position: absolute;
  top: 713px !important;
  right: 38%;
}
// form position
.RegularTheme1Desktop .form-position-desk {
  width: 27%;
  height: 502px !important;
  position: absolute;
  right: 9%;
  top: 800px;
}
.RegularTheme1Desktop .side-position-box-desk {
  position: absolute;
  box-sizing: border-box;
  position: absolute;
  width: 26%;
  height: 480px !important;
  left: 6%;
  top: 385px;
}

.RegularTheme1Desktop .pattern {
  position: absolute;
  top: 490px;
  right: 4%;

  @media screen and (min-width: 1500px) {
    right: 9.5% !important;
  }
}

.RegularTheme1Desktop .pattern-two {
  position: absolute;
  top: 420px;
  right: 25%;
}
