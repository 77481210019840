.blogpost-screen-mobile-1 {
    background-color: #CBD5E0;
    width: 400px;
    margin: auto;

    .blogpost-contentmobile1 {
        position: absolute;
        bottom: 45%;
        left: 25%;
        width: 235px;

        p {
            line-height: 20px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .branding-photoshooting-mobile {
        padding-top: 70px;
        padding-bottom: 40px;

        .brand-heading-mobile {
            font-size: 10px;
        }

        .brandingparagraph-mobile {
            font-size: 12px;
            width: 179px !important;
            margin-right: 50px;
            line-height: 23px;
            text-align: justify;
        }

        .blog-postimage2 {
            width: 100px;
            border-radius: 50%;
            margin-right: 9px;
            height: 100px;
        }
    }

    .blogpostmobile-circle {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #7CBAA7;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        bottom: -590px;

        span {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
        }
    }





    .blogpost-contentmobile2 {
        position: absolute;
        bottom: 45%;
        left: 25%;
        width: 235px;

        p {
            line-height: 20px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .brand-heading-mobile2 {
        font-size: 10px;
        margin-left: 50px;
    }

    .brandingparagraph-mobile2 {
        font-size: 12px;
        width: 179px !important;
        margin-left: 50px;
        line-height: 23px;
        text-align: justify;
    }



    .blogpostmobile-circle2 {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #7CBAA7;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        bottom: -412px;

        span {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
        }
    }

    .blogpost-contentmobile2 {
        position: absolute;
        bottom: 45%;
        left: 12%;
        width: 235px;
        p {
            line-height: 20px;
            font-size: 20px;
            font-weight: 500;
        }
    }
}