.service-package-plan-glassmorphic-desktop-5 {
    background: linear-gradient(315.3deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%);

    .toggle_button {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            width: 86px;
            height: 35px;
            border-radius: 30px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.7) 3.01%, rgba(255, 255, 255, 0.02) 103.3%);
            color: #000000;
            text-align: center;
            border: none;
        }

    }

    .content_wrapper {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        border-radius: 25px;
        padding: 30px;
        border: 1px solid #F0F4F8;

    }
    .fs_10 {
        font-size: 10px;
    }
    .read_more_btn {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        border: 1px solid #F0F4F8;
        border-radius: 5px;
        width: 68px;
        height: 24px;
        color: #202020;
        font-size: 8px;
        line-height: 10px;
    }

    .main_wrapper {
        background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.3) 3.01%, rgba(0, 0, 0, 0.1) 103.3%);
        border-radius: 40px;
        padding: 10px;


        .plan_outer_wrapper {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
            border: 3px dashed #5F6168;
            border-radius: 35px;
            padding: 30px;


            .plan_wrapper {
                padding: 40px;
                width: 920px;
                height: 772px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                .plan_outer {
                    width: 260px;
                    height: 630px;
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                    border-radius: 20px 20px 0px 0px;
                    border-radius: 20px;
                    position: relative;

                    .plan_content {
                        padding: 80px 30px;
                    }

                    .badge_wrapper {
                        position: absolute;
                        width: 140px;
                        height: 140px;
                        top: -75px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 150px;
                        height: 150px;

                        h2 {
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 29px;
                            text-align: center;
                            color: #202020;
                            margin: 0;
                        }

                        p {
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 16px;
                            text-align: center;
                            color: #202020;
                            margin-bottom: 0;
                        }
                    }

                    .bg_green {
                        background: #5AA3AD;
                    }

                    .bg_amber {
                        background: #F4B343;
                    }

                    .bg_gray {
                        background: #847F67;
                    }

                    .text_green {
                        color: #5AA3AD;
                    }

                    .text_amber {
                        color: #F4B343;
                    }

                    .text_gray {
                        color: #847F67;
                    }

                    .price {
                        h1 {
                            font-weight: 600;
                            font-size: 30px;
                            line-height: 36px;
                            text-align: center;
                        }

                        p {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            text-align: center;
                            color: #202020;
                        }
                    }

                    li {
                        list-style: none;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #202020;
                        text-align: left;
                        padding: 17px 0;
                        border-bottom: 1px solid #F0F4F8;
                        display: flex;
                        align-items: center;

                        &:last-child {
                            border-bottom: none;
                        }

                        .icon_outer {
                            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                            border-radius: 20px;
                            border: 1px solid #F0F4F8;
                            width: 26px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;

                            .icon {
                                width: 1.25rem;
                                height: 1.25rem;
                                color: #202020;
                            }
                        }
                    }

                    .list_divider {
                        width: 100%;
                        height: 2px;
                        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.7) 3.01%, rgba(255, 255, 255, 0.02) 103.3%);
                        display: block;
                    }

                    .footer_wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-top: 4px solid #f0f4f870;
                        width: 100%;
                        height: 46px;
                        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                        border-radius: 0 0 20px 20px;
                        position: absolute;
                        bottom: 0;

                        .btn_wrapper {
                            width: 115px;
                            height: 43px;
                            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                            position: relative;

                            .basic_sign_up_btn {
                                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                            }

                            .standard_sign_up_btn {
                                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                            }

                            .premium_sign_up_btn {
                                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                            }


                            .btn1 {
                                padding: 10px 25px;
                                width: 115px;
                                height: 63px;
                                position: absolute;
                                top: -40px;
                                border: 0;
                                color: #202020;
                                font-size: 16px;
                                border-bottom: 20px solid #f0f4f8b8 !important;
                            }
                        }
                    }

                }

            }
        }
    }



}