.subHeader {
    margin-top: 45px;
    overflow-x: hidden;
    padding: 15px 0;
    padding-top: 110px;
    ul {
        position: relative;
        &::before {
            content:"";
            background-image: url("/images/bg-animation.png");
            position: absolute;
            top:7px;
            left: 0px;
            width: 100%;
            height:73px;
            display: inline-block;
            background-repeat: repeat-x;
            z-index: 0;
        }
        li {
            width: 65px;
            height: 80px;
            margin: 0 50px;
            display: flex;
            flex-direction: column;
            box-shadow: inset -3px 3px 4px rgba(152, 151, 151, 0.68), inset 3px -3px 4px rgba(255, 255, 255, 0.9);
            align-items: center;
            justify-content:center;
            position: relative;
            border-radius: 10px;
            background: var(--page-background);
            &::before {
                content:url("/images/timeline.png");
                position: absolute;
                top: -13px;
                left: -49px;
                width: 164px;
                height:106px;
                display: inline-block;
                z-index: -1;
            }
            // clip-path: polygon(50% 0%, 100% 0, 91% 14%, 91% 100%, 0 100%, 9% 88%, 8% 0);
            h6 {
                font-weight: 500;
                font-size: 9px;
                line-height: 12px;
                display: block;
                text-align: center;
                text-transform: uppercase;
                margin: 5px 0 0;
            }
        }
    }
    ul.focusbar{
        li {
            &::before {
                content:url("/images/timeline1.png");
            }
        }
    }
    .focusbar {
        position: absolute;
        z-index: 1;
        left:8%;
        top: 15px;
        &::before{
            left:-109%;
            width:240px;
        }
    }
}

.dark {
    .subHeader {
        ul {
            li {
                box-shadow: inset 0px -4px 4px rgba(58, 58, 58, 0.25), inset 0px 4px 4px #000000;
                &::before {
                    content:url("/images/timelinedark.png");
                }
            }
        }
        ul.focusbar{
            li {
                &::before {
                    content:url("/images/timelinedark1.png");
                }
            }
        }
    }
}
.pl-240 {
    padding-left: 240px!important;
}