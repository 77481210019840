.service-package-plan-glassmorphic-desktop-6 {
    background: linear-gradient(315.3deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%);

    .toggle_button {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            width: 97px;
            height: 35px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.7) 3.01%, rgba(255, 255, 255, 0.02) 103.3%);
            border-radius: 30px;
            color: #000000;
            text-align: center;
            border: none;

        }

    }

    .content_wrapper_outer {
        padding: 0px 20px;
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);

        .content_wrapper {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            padding: 30px 50px;
        }
    }

    .fs_10 {
        font-size: 10px;
    }

    .main-content {
        background: none;
    }

    .main_wrapper {
        background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.3) 3.01%, rgba(0, 0, 0, 0.1) 103.3%);
        border: 4px dashed #FACB30;
        padding: 30px;

        .plan_outer_wrapper {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            padding: 20px;

            .modal-tabs1 {
                .nav-item {
                    .nav-link {
                        padding: 10px 30px !important;
                        border: 1px solid #F0F4F8;
                        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                        color: #202020;
                        font-size: 13px;
                        line-height: 16px;
                        border-radius: 0;
                        font-weight: 500;
                    }

                    .active {
                        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                        padding: 10px 30px;
                        position: relative;
                        color: #202020;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -15px;
                            left: 45%;
                            width: 0;
                            height: 0;
                            border-left: 9px solid transparent;
                            border-right: 9px solid transparent;
                            border-top: 15px solid #FACB30;
                        }

                    }
                }
            }

            .tab_content_wrapper {

                .tab_content {
                    width: 780px;
                    height: 500px;
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                    border-left: 30px solid #facc31d6;
                    border-right: 30px solid #facc31d6;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    h1 {
                        font-weight: 600;
                        font-size: 30px;
                        line-height: 36px;
                        color: #202020;
                    }

                    h3 {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #202020;
                        margin-bottom: 8px;
                    }

                    small {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #3C3D40;
                    }

                    h5 {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        color: #202020;
                        margin-bottom: 15px;
                    }

                    ul {
                        list-style: disc;
                        padding-left: 2rem;

                        li {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 17px;
                            color: #202020;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            .tab_btn {
                .btn_left {
                    padding: 15px 20px;
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                    border: 1px solid #F0F4F8;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    color: #202020;
                }

                .btn_right {
                    padding: 15px 20px;
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    color: #202020;
                    border: #FACB30;
                }
            }
        }
    }



}