@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');



.featured-on-neumorphic-mobile-1 {


    .Feature-on-mobile-1 {

           width: 460px;
           display: contents;
        .grey-box-mobile {
            background: #F0F4F8;
            box-shadow: 5px 4px 5px 1px rgba(67, 67, 67, 0.09);
            border-radius: 5px;
            padding: 30px 0px 30px 0px;
         
          }

          .image-circle-mobile {
            
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            
            align-items: center;
            justify-content: center;
            margin: 10px 5px 0px 0px ;

            background: #F0F4F8;
            // fill: #ffffff;
            box-shadow: 5px 5px 5px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
            width: 50px;
      height: 50px;
        }
        .image-circle-mobile span{
            background-color: #F0F4F8;
            padding: -5px -5px;
border-radius: 50%;
filter: drop-shadow(2px 2px 8px #B2BDCB) drop-shadow(-2px -2px 8px #FFF);
        }
        .image-circle-mobile::before {

            padding: -5px;
             }
          .image-usa{
            background-color: #ffffff ;
          }


          .line-update-mobile{
   
          background: #EAA91B;
          visibility: hidden;
        }
        .outer-circle-mobile-1 {
          width: 90%;
          // height: 50%;
          border-radius: 50%;
          background: var(--grey-8, #F0F4F8);

          /* Neo/Light/Outer/3 */
          box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
      }

    }

}