@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.diet-programs-main-desktop {
  padding: 55px 30px;
  display: block;
  .diet-programs-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    background-color: #202020;
    padding: 10px 60px 10px 10px;
  }
}
