@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");

.side-position-box {
  max-width: 430px;
  box-sizing: border-box;
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 530px;
  height: 100% !important;
  border: 1px solid teal;

  &.Regular-style {
    background-image: linear-gradient(
      to right,
      #7cbaa74d 0%,
      #7cbaa74d 20%,
      #f0f4f8 20%,
      #f0f4f8 100%
    ) !important;

    border: 1px solid teal;
    .s-p-pfirst {
      color: teal;
    }
    .line-child {
      background-color: teal;
      color: #7cbaa74d;
    }
    .pr-card-child .ch-one {
      background: #304053;
    }
    .pr-card-child .ch-one::after {
      border-top: 18px solid #304053 !important;
      z-index: 0;
    }
    .fa-circle::before {
      color: #304053 !important;
    }
  }

  &.Neumorphic-style {
    background-image: linear-gradient(
      to right,
      #facb30 0%,
      #e9c984 20.23%,
      #f0f4f8 20.23%,
      #f0f4f8 100%
    ) !important;
    box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
    border-radius: 20px;
    border: none !important;
    .s-p-pfirst {
      background: var(--goldenlinear);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .line-child {
      box-shadow: -1px -1px 1px #ffffff, 1px 1px 1px rgba(178, 189, 203, 0.9);
      background: var(--goldenlinear);
      color: goldenrod;
    }
    .pr-card-child .ch-one,
    .line-child {
      background: linear-gradient(
        180deg,
        #facb30 -18%,
        #fef9c8 22.5%,
        #c49530 67.84%,
        #fff8c4 116%
      );
    }
    .pr-card-child .ch-one::after {
      border-top: 18px solid goldenrod !important;
      z-index: 1;
    }
    .fa-circle::before {
      color: goldenrod !important;
    }
  }

  .s-p-pfirst {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
  }

  .s-p-hfirst {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: var(--bluish-black);
  }

  .s-p-psecond {
    width: 100%;
    height: 110px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 170%;
    color: var(--text-body-secondary);
  }

  // side-card
  .per-card {
    margin-top: 5px;
  }

  .pr-card-child {
    .ch {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #202020;
      margin-top: 5px;
    }

    .ch-one {
      position: relative;
      z-index: 1;
      font-family: "Inter";
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      // background-color: #202020;
      color: #f0f4f8;
      border-radius: 50%;
      padding: 8px 0px 8px 9px;
      z-index: 2;
    }

    .ch-one::after .ch-one {
      position: relative;
      z-index: 1;
      font-family: "Inter";
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      // background-color: #202020;
      color: #f0f4f8;
      border-radius: 50%;
      padding: 8px 0px 8px 9px;
    }

    .ch-one::after {
      content: " ";
      position: relative;
      right: 8px;
      top: 30px;
      border-left: 11px solid transparent;
      border-right: 0px solid transparent;
      // border-top: 18px solid #202020;
    }
  }

  .line-parent {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: -5px;

    .line-child {
      width: 94.6%;
      height: 5px;
      box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
    }

    .line-icon {
      width: 10px;
      height: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: #304053;
      border-radius: 50%;
    }
  }
}
