.elevator {
    .elevator_heading {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 30px;
        color: #697094;
    }

    .btn-bg.lg button {
        top: 15px;
        transform: rotate(12deg);
    }
}
.elevator_btn{
    .btn-bg.lg button {
        top: 15px;
        transform: rotate(12deg);
    }
}
.whitespace-sowrap{
    white-space: nowrap;
}
.h-300{
    height: 300px;
}