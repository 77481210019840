@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.main-container-mob-comp {
  height: 100% !important;
  padding-top: 71px !important;
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 0px;
  margin: 0px auto;
  padding-bottom: 30px;
  .box-image {
    width: 100%;
  }
  &.Neumorphic-style {
    background-color: var(--bg-secondary-light);
    box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
    border-radius: 20px;
    border: none;

    .after-picturebox {
      color: var(--black);
    }
    .sec-p {
      color: var(--black);
    }
    .third-p {
      color: var(--black);
    }
  }
  &.Regular-style {
    background: var(--bg-color-dark);
    .sec-p {
      color: #7cbaa7;
    }
  }
  .picture-box {
    width: 100%;
    height: 350px !important;
    overflow: hidden;

    background-color: var(--bg-secondary-light);
  }

  .after-picturebox {
    width: 95%;
    color: var(--bg-secondary-light);
    margin-bottom: 30px;

    .sec-p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
    }
    .first-p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
    }
    .third-p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
    }
  }
}
