@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.diet-programs-main-tab{
    .diet-programs-right-section{
        width: 60%;
            .training-guide-steps {
                filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
                padding: 0px 15px;
                display: flex;
                background-color: #fff;
                box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
                height: 95px;
                .consultaion-step {
                    height: 75px;
                    width: 130px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #7CBAA7;
                    clip-path: polygon(0 0, 70% 0, 100% 100%, 30% 100%);
                    span{
                        font-family: Inter;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 36px;
                        letter-spacing: 0em;
                    }
                }
                .consultaion-description{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 15px;
                    span {
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 19px;
                        letter-spacing: 0em;
                        color: #202020;
                    }
                    p{
                        font-family: Inter;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0em;
                        color: #3C3D40;
                        margin-bottom: 0;
                        margin-top: 5px;
                    }
                }
            }


        }
}



.main-row-tab {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 10px;
    height: fit-content;
  }
  .counter-div-tab {
    background-image: url("../../../../../../../../images/how-it-work/bar_two.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    z-index: 0;
    position: absolute;
    left: 8%;
    font-size: 30px;
    font-weight: bold;
    width: 90px;
    height: 74px;
  }
  .description-div-tab {
    background-image: url("../../../../../../../../images/how-it-work/bar_three.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 10px 0 10px 25px;
    z-index: 1;
    margin-left: 25px;
    width: 340px;
  }
  .counter-div-tab span {
    font-weight: bold;
    font-size: 30px;
    position: absolute;
    left: 44%;
    top: 24%;
  }
  .description-div-tab span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    color: #202020;
  }
  .description-div-tab p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    color: #3c3d40;
    margin-bottom: 0;
    margin-top: 5px;
  }
  