@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.diet-programs-main-desktop{
    .diet-consultaion{
        width: 30%;
        text-align: center;
        h3{
        
            font-family: Inter;
            font-size: 30px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0em;
            color: #fff;

        }
        p{
            font-size: 18px;
            font-weight: 400;
            line-height: 29px;
            letter-spacing: 0em;
            color: #FFFFFF;
            font-style: italic;
            font-family: 'Kalam', cursive;

        }
        .diet-consultaion-btn {
            display: flex;
            justify-content: center;
            gap: 20px;
            align-items: center;
            margin-top: 30px;
            .consultaion-btn {
                padding: 13px 20px;
                border-radius: 30px;
                font-family: Inter;
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                cursor: pointer;
            }
            .diet-buy-btn{
                background-color: #7CBAA7;
                border: 1.5px solid #7CBAA7;
                color: #000;
            }
            .program-btn{
                border: 1.5px solid #fff;
                background-color: transparent;
                color: #fff;
                            
            }
        }
    }
}
