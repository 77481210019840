.TAB-6-form {
    li div label {
        font-size: 12px !important;
        font-weight: 500;
        height: 23px;
    }

    .f_14 {
        font-size: 14px;
    }

    .btn-bg button {
        top: 18px;
        transform: rotate(15deg);
    }
    .at_sign {
        color: #000;
        font-weight: bold;
        font-size: 22px;
    }

    .items-center {
        align-items: center;
    }

    .new_email_address {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 15px;
        width: 100%;
        height: 55px;
        background: var(--goldenlinear);
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 3px;
        color: #1C1C1C;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }

    // .col-md-5{
    //     padding-left: 15px;
    //     padding-right: 15px;
    // }
    input {
        // background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 5px;
    }

    .select_shadow {
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4) !important;
    }

    .first-field {
        .bgArrow:before {
            top: 20px !important;
            right: 16px;
        }
    }

    .px_2 {
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }

    .lock_icon {
        color: #A074FF;
    }

    .domain_name {
        background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 5px;
        width: 80%;
        margin: 0 auto;

        .icon {
            width: 60px;
            height: 60px;
            font-size: 60px;
        }

        .vr {
            width: 6.36px;
            height: 80%;
            background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
            box-shadow: inset -2px -2px 2px #FFFFFF, inset 1px 1px 3px rgba(77, 89, 107, 0.1);
            border-radius: 5px;
            margin-top: auto;
            margin-bottom: auto;
        }

        h6 {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #606683;
            margin-bottom: 0;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #697094;
            margin-bottom: 0;
        }

        hr {
            margin: 0.5rem 0;
        }
    }

    .pl_0 {
        padding-left: 0 !important;
    }

    .w-150 {
        width: 150px !important;
    }

    .TAB-6-form .select-field .bgArrow:before {
        top: 11px !important;
        right: 6px;
    }
}


.dark .TAB-6-form {
    .contry .css-13cymwt-control {
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
    }

    .select_shadow {
        box-shadow: unset !important;
    }

    .new_email_address {
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }

    .domain_name {
        background: #1c1c1c;
        box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99), inset 6px 9px 16px rgba(0, 0, 0, 0.8);
    }
}