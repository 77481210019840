.subscription-screen-desktop-6 {
  .subscription-desktop-theme6 {
    background-color: #CBD5E0;
    padding: 100px 0;

    &.glassmorphic-style {
      background: linear-gradient(326deg, #9595B8 0%, #ECEDF5 46.35%, #C8C9D2 70.83%, #97999D 100%);
    }

    .theme-6-main-wrapper {
      &.neumorphic-style {
        background: #F0F4F8;
        margin: 0 auto;
      }

      &.glassmorphic-style {
        border-radius: 30px;
        max-width: 1030px;
        margin: 0 auto;
        backdrop-filter: blur(10px);
      }

      .subscription-desktop-theme6-main-wrapper {
        background-position: center;
        background-size: cover;
        position: relative;
        width: 100%;
        max-width: 1000px;
        margin: auto;
        text-align: center;
        overflow: hidden;
        z-index: 2;
        padding: 353px 0 200px;

        &.regular-style {
          background: #3C3D40;

          .theme5RegularTopLeftDotsImg, .theme5RegularTopRightDotsImg, .theme5RegularBottomRightDotsImg, {
            display: block;
          }
        }

        &.neumorphic-style {
          overflow: hidden;
          border-radius: 20px;
          background: #F0F4F8;
          box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.7), -6px -6px 15px 0px #FFF;

          .theme5NeumorphicTopLeftDotsImg, .theme5NeumorphicTopRightDotsImg, .theme5NeumorphicBottomRightDotsImg, {
            display: block;
          }

          // box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
        }

        &.glassmorphic-style {
          overflow: hidden;
          border-radius: 30px;
          background: linear-gradient(135deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.10) 100%);

          .theme5GlassmorphicTopLeftDotsImg, .theme5GlassmorphicTopRightDotsImg, .theme5GlassmorphicBottomRightDotsImg {
            display: block;
          }
        }

        .theme5RegularTopLeftDotsImg, .theme5RegularTopRightDotsImg, .theme5RegularBottomRightDotsImg,
        .theme5NeumorphicTopLeftDotsImg, .theme5NeumorphicTopRightDotsImg, .theme5NeumorphicBottomRightDotsImg,
        .theme5GlassmorphicTopLeftDotsImg, .theme5GlassmorphicTopRightDotsImg, .theme5GlassmorphicBottomRightDotsImg {
          position: absolute;
          object-fit: cover;
          display: none;
          z-index: -1;
        }

        .theme5RegularTopLeftDotsImg, .theme5NeumorphicTopLeftDotsImg, .theme5GlassmorphicTopLeftDotsImg {
          left: 0;
          height: 550px;
          width: 200px;
          transform: rotate(-90deg);
          bottom: -170px;
        }

        .theme5RegularTopRightDotsImg, .theme5NeumorphicTopRightDotsImg, .theme5GlassmorphicTopRightDotsImg {
          width: 100px;
          height: 300px;
          transform: rotate(-90deg);
          top: -89px;
          left: 93px;
          z-index: -9;
        }

        .theme5RegularBottomRightDotsImg, .theme5NeumorphicBottomRightDotsImg, .theme5GlassmorphicBottomRightDotsImg {
          width: 350px;
          height: 200px;
          transform: rotate(-90deg);
          top: 0;
          right: -72px;
        }

        .shadow-wrapper {
          width: 800px;
          margin: 0 auto;
          position: relative;

          &.regular-style {
          }

          &.neumorphic-style {
            box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
          }

          &.glassmorphic-style {
            //background: linear-gradient(135deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.10) 100%);
          }

          .subscribe-weekly-drops {
            position: relative;
            width: 800px;
            margin: 0 auto 0;
            padding: 100px 0 70px;
            background-repeat: no-repeat;
            background-size: cover;

            &.regular-style {
              background-image: url("../../../../../../images/subscription/theme6-green-main-body-bg-img.png");
            }

            &.neumorphic-style {
              background: #F0F4F8;
              box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.7), -6px -6px 15px 0px #FFF;
              background-image: url("../../../../../../images/subscription/theme6-pink-main-body-bg-img.png");

              .subscribe-weekly-drops-icon-wrapper {
                padding: 20px 0 0 0;
              }
            }

            &.glassmorphic-style {
              background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.05) 100%);
              z-index: 1;
              backdrop-filter: blur(10px);
              background-image: url("../../../../../../images/subscription/theme6-pink-bg-arrows-img.png");
              background-position: bottom;
            }

            .subscribe-weekly-drops-icon-wrapper {
              .img-wrapper {
                border-radius: 0;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                overflow: hidden;
                padding: 40px 0px 24px;
                width: 290px;
                height: 270px;
                position: absolute;
                top: -200px;
                left: 50%;
                transform: translateX(-50%);

                .subscribeAroundTextBlackImg, .subscribeAroundTextWhiteImg {
                  width: 250px;
                  height: 138px;
                  position: absolute;
                  top: 20px;
                  left: 50%;
                  transform: translateX(-50%);
                  display: none;
                }

                .icon-wrapper {
                  .subscribeUserIcon {
                    width: 160px;
                    height: 160px;
                    border-radius: 50%;
                  }
                }

                .pick-poison-text {
                  color: #3C3D40;
                  padding: 7px 10px 5px;
                  font-size: 13px;
                  position: absolute;
                  bottom: 16px;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 130px;
                }

                &.regular-style {
                  background: #7CBAA7;

                  .img-wrapper {
                    background: #7CBAA7;
                  }

                  .pick-poison-text {
                    background: #202020;
                    color: #ffffff;

                  }

                  .subscribeAroundTextBlackImg {
                    display: block;
                    top: 25px;
                  }
                }

                &.neumorphic-style {
                  background: #F0F4F8;
                  box-shadow: 2px 2px 8px 0px #B2BDCB, -2px -2px 8px 0px #FFF;
                  padding: 12px;
                  width: 300px;
                  height: 300px;
                  margin: 20px auto 0;
                  border-radius: 10px;

                  .icon-wrapper {
                    border-radius: 50%;
                    padding: 10px;
                    box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
                  }

                  .pick-poison-text {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  }

                  .subscribeAroundTextBlackImg {
                    display: block;
                  }
                }

                &.glassmorphic-style {
                  background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
                  border-radius: 10px;
                  backdrop-filter: blur(10px);

                  .subscribeUserIcon {
                    box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
                    padding: 12px;

                    img {
                      width: 180px;
                      height: 180px;
                    }
                  }

                  .icon-wrapper {
                    border-radius: 50%;
                    background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.05) 100%);
                  }

                  .pick-poison-text {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    padding: 7px 13px 5px;
                  }

                  .subscribeAroundTextBlackImg {
                    display: block;
                  }
                }
              }
            }

            .on-off-buttons-wrapper {
              padding: 0 90px 0;
              margin: 30px 0;

              .on-off-btn-items {
                display: flex;
                justify-content: space-between;
                gap: 15px;

                li {

                  text-align: center;

                  .toggle-switch {
                    position: relative;
                    width: 36px;
                    display: inline-block;
                    text-align: left;
                    top: 8px;

                    .checkbox {
                      display: none;
                    }

                    .label {
                      display: block;
                      overflow: hidden;
                      cursor: pointer;
                      border: 0 solid #bbb;
                      border-radius: 10px;
                      width: 36px;
                      background: transparent;
                      box-shadow: none;

                      .inner {
                        display: block;
                        width: 200%;
                        margin-left: -100%;
                        transition: margin 0.3s ease-in 0s;

                        &::before {
                          float: left;
                          width: 36px;
                          height: 21px;
                          padding: 0;
                          line-height: 36px;
                          color: #fff;
                          font-weight: bold;
                          box-sizing: border-box;
                        }

                        &::after {
                          float: left;
                          width: 36px;
                          height: 21px;
                          padding: 0;
                          line-height: 36px;
                          color: #fff;
                          font-weight: bold;
                          box-sizing: border-box;
                        }

                        &::before {
                          content: "";
                          padding-left: 10px;
                          background-color: #7CBAA7;
                          color: #fff;
                        }

                        &::after {
                          content: "";
                          padding-right: 10px;
                          background-color: #B2BDCB;
                          color: #fff;
                          text-align: right;
                        }
                      }

                      .switch {
                        display: block;
                        width: 16px;
                        height: 16px;
                        margin: 5px;
                        background: #5F6168;
                        position: absolute;
                        top: -3px;
                        bottom: 0;
                        right: 12px;
                        border: 0 solid #bbb;
                        border-radius: 20px;
                        transition: all 0.3s ease-in 0s;
                      }
                    }

                    .checkbox:checked + .label .inner {
                      margin-left: 0;

                      &:after {
                        background: #7CBAA7;
                      }

                      &:before {
                        background: #7CBAA7;
                      }
                    }

                    .checkbox:checked + .label .switch {
                      right: -2px;
                      background: #D3FFB0;
                    }

                    &.regular-style {
                      .inner {
                        &:before {
                          color: #fff;
                          background-color: #7CBAA7;
                        }

                        &:after {
                          color: #fff;
                          background-color: #B2BDCB;
                        }
                      }

                      .switch {
                        background: #5F6168;
                      }
                    }

                    &.neumorphic-style {
                      .inner {
                        &:before {
                          color: #fff;
                          background-image: linear-gradient(#4E4E57, #E5E9ED);
                        }

                        &:after {
                          color: #fff;
                          background-image: linear-gradient(#4E4E57, #E5E9ED);
                        }
                      }

                      .checkbox:checked + .label .inner {
                        margin-left: 0;

                        &:after {
                          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                        }

                        &:before {
                          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                        }
                      }

                      .checkbox:checked + .label .switch {
                        right: -2px;
                        background: #EAEFF6;
                      }

                      .switch {
                        background: #5F6168;
                      }
                    }

                    &.glassmorphic-style {
                      .inner {
                        &:before {
                          color: #fff;
                          background: #bababa;
                        }

                        &:after {
                          color: #fff;
                          background: #bababa;
                        }
                      }

                      .checkbox:checked + .label .inner {
                        margin-left: 0;

                        &:after {
                          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                        }

                        &:before {
                          background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                        }
                      }

                      .checkbox:checked + .label .switch {
                        right: -2px;
                        background: #202020;
                      }

                      .switch {
                        background: #202020;
                      }

                      strong {
                        color: #202020;
                      }
                    }
                  }

                  strong {
                    color: #202020;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 12px 0 0 0;
                    display: block;
                  }
                }
              }

            }

            .signup-form {
              position: relative;
              z-index: 2;
              margin: 70px 0 0;

              form {
                .form-group {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  gap: 20px;
                  //background: #F0F4F8;
                  padding: 0 0 14px;
                  margin: 25px -10px 0px 0px;

                  input {
                    font-size: 13px;
                    color: #5F6168;
                    letter-spacing: 0.52px;
                    text-transform: uppercase;
                    font-weight: 500;
                    width: 550px;
                    height: 50px;
                  }

                  button {
                    width: 150px;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0.52px;
                    text-transform: uppercase;
                    padding: 8px 10px;
                    height: 50px;
                    margin: 0;
                    border-radius: 40px 0px 0px 40px;
                    position: relative;

                    &:after {
                      content: "";
                      display: block;
                      position: absolute;
                      top: -9px;
                      right: 0;
                      width: 0;
                      height: 0;

                      border-right: 10px solid transparent;
                    }
                  }
                }
              }

              &.regular-style {
                p {
                  color: white;
                }

                form {
                  .form-group {
                    input {
                      background: #ffffff;
                      border-radius: 0;

                      &::placeholder {
                        color: #5F6168;
                        box-shadow: none;
                      }

                      &:focus {
                        box-shadow: none;
                      }
                    }

                    button {
                      background-color: #7CBAA7;
                      color: #202020;
                      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

                      &:after {
                        border-bottom: 10px solid #7CBAA7;
                      }

                      &:hover {
                        background-color: #7CBAA7;
                        background: #7CBAA7;
                        color: #202020;
                      }
                    }
                  }
                }
              }

              &.neumorphic-style {
                background: transparent;

                &::after {
                  width: 530px;
                  background-image: url('../../../../../../images/subscription/envelop-theme2-neumorphic-bg-img.png');
                }

                form {
                  .form-group {
                    background: transparent;

                    input {
                      background: #F0F4F8;
                      box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
                      border-radius: 4px;
                    }

                    button {
                      background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);

                      &:after {
                        border-bottom: 10px solid #FACB30;
                      }
                    }
                  }
                }
              }

              &.glassmorphic-style {
                &::after {
                  background-image: url('../../../../../../images/subscription/envelop-theme2-bg-img.png');
                }

                form {
                  .form-group {
                    input {
                      background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
                      border-radius: 4px;
                      color: #202020;

                      &::placeholder {
                        color: #202020;
                      }

                      &:focus {
                        box-shadow: none;
                      }
                    }

                    button {
                      font-size: 16px;
                      background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                      box-shadow: none;

                      &:after {
                        border-bottom: 10px solid #FACB30;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
