@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');
.tab-neumorphic-design-2{
    .diet-programs-main-tab{
        padding: 55px 30px;
        display: block;
        .diet-programs-flex {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
            padding: 20px;
            background: #F0F4F8;
            box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border-radius: 30px;
            .diet-programs-left-section {
                width: 40%;
                .diet-programs-left-section-inner {
                    border-radius: 20px;
                    background: #F0F4F8;
                    box-shadow: inset -4px -4px 10px #FFFFFF, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
                    padding: 15px;
                    img{
                        border-radius: 20px;
                    }
                }
            }
            .diet-consultaion{
                width: 100%;
                text-align: left;
                margin-top: 29px;
                h3{
                
                    font-family: Inter;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 29px;
                    letter-spacing: 0em;
                    color: #202020;
    
                }
                p{
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 29px;
                    letter-spacing: 0em;
                    color: #202020;
                    font-style: italic;
                    font-family: 'Kalam', cursive;
                    margin-bottom: 10px;
    
                }
                .diet-consultaion-btn {
                    display: flex;
                    justify-content: flex-start !important;
                    gap: 20px;
                    align-items: center;
                    margin-top: 30px;
                    .consultaion-btn {
                        padding: 11px 18px;
                        border-radius: 30px;
                        font-family: Inter;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.04em;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                    .diet-buy-btn{
                        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                        border: 0;
                        color: #202020;
                    }
                    .program-btn{
                        border: 0;
                        background: #F0F4F8;
                        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                        color: #202020;
                                    
                    }
                }
            }
            .diet-programs-right-section{
                width: 60%;
                    .training-guide-steps {
                        filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.25));
                        padding: 0px 15px;
                        display: flex;
                        background-color: #fff;
                        box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
                        height: 95px;
                        .consultaion-step {
                            height: 80px;
                            width: 120px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                            clip-path: polygon(0 0, 70% 0, 100% 100%, 30% 100%);
                            
                            span{
                                font-family: Inter;
                                font-size: 24px;
                                font-weight: 500;
                                line-height: 36px;
                                letter-spacing: 0em;
                            }
                        }
                        .consultaion-description{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding-left: 15px;
                            span {
                                font-family: Inter;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 19px;
                                letter-spacing: 0em;
                                color: #202020;
                            }
                            p{
                                font-family: Inter;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 16px;
                                letter-spacing: 0em;
                                color: #3C3D40;
                                margin-bottom: 0;
                                margin-top: 5px;
                            }
                        }
                    }
       
    
                }
              
        }
        .img-tranformation{
            display: block;
            margin-top: 50px;
            text-align: center;
            h4{
                font-family: Inter;
                font-size: 24px;
                font-weight: 600;
                line-height: 29px;
                letter-spacing: 0em;
    
            }
            .transform-img{
                margin-top: 30px;
                display: none;        
            }
            .transform-img-neumorphic{
                display: block;
                margin: 50px auto 0;
            }
        }
    }
}
