@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Norican&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Qwigley&display=swap');


.twelvestep-scss{
    .mobile{
        display: block;
    }
    .nav-pills .design-one.nav-link.active, .nav-pills .show > .design-one.nav-link {
        background-color: #7CBAA7!important;
        box-shadow: none!important;
    }
    .nav-pills .design-two.nav-link.active, .nav-pills .show > .design-two.nav-link {
        background-color: #F0F4F8!important;
    
box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7)!important;
    }
    .nav-pills .design-three.nav-link.active, .nav-pills .show > .design-three.nav-link {
     
        box-shadow: none!important;
    }
}
.desktop-3{
    position: relative;
    bottom: -37px;
    margin-bottom: 55px;
    margin-top: 40px;
}
.design-one{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 13px 40px!important;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 190%;
/* identical to box height, or 23px */

letter-spacing: 0.05em;
text-transform: uppercase;

color: #000000!important;
border-radius: 0px!important;
width: 200px;
height: 50px;

/* Others/2 */

background-color: #7CBAA7!important;

}

.design-two{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 13px 15px!important;
gap: 10px;
margin-left: 20px!important;
width: 200px;
height: 50px;
border-radius: 0px!important;
/* Grey/8

Base color for Light mode
*/
background-color: #F0F4F8!important;
/* Neo/Light/Outer/2 */

box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
font-weight: 600;
font-size: 12px;
line-height: 190%;
/* identical to box height, or 23px */

letter-spacing: 0.05em;
text-transform: uppercase;

color: #000000!important;
.lock{
    color:  #A074FF!important ;
    margin-left: 20px;
    font-size: 15px;
}
}
.design-three{
    margin-left: 20px!important;
    background-image: url(../../../../images/three-btn.png)!important;
    background-position: center!important;
   
    background-repeat: no-repeat;
    .lock{
        color:  #A074FF!important ;
        font-size: 15px;
        margin-left: 20px;
    }
}
.design-three{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000!important;
    border-radius: 0px!important;
    width: 200px!important;
    height: 50px!important;

}
items-center{
    align-items: center;

}
.ws-80{
    width:80%;
    margin: auto;
}
.justify-content-between{
    justify-content: space-between!important;
}
h4{
    margin: 10px 0px;
}
.screen-bg{
    background: #F0F4F8;
/* Neo/Light/Outer/2 */

box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
padding: 15px;
margin: 15px;
}
.card-outo{
margin: auto;
justify-content: center;

.modal-dialog{

    width: 99%;
    top: 130px;
    margin: auto;
    
}
@media screen and (min-width:1920px) {
    .modal-dialog{
        top: 130px;
       
        
    }
}
.modal-content{
    
    background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%)!important;
box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8), 2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4)!important;
border: 1px solid transparent!important;
border-radius: 2.84939px;
}
.modal-header{
    border-bottom: none;
}
.screen-btn{
    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
    /* Neo/Light/Outer/2 */
    
    box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
border-radius: 25px;
padding: 15px ;
text-align: center;
font-weight: 500;
font-size: 12px;
line-height: 15px;
color: #202020;
width: 143px;
margin: 30px auto;
display: flex;
justify-content: center;
border: none;
}
}
.design-one-size{
    display: flex;
    align-items: center;
    width: 130px;
height: 48px;
background: #F0F4F8!important;
box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
border-radius: 30px!important;
span{
    color: #202020;
}
.icon-design{
    margin-right:8px;
    display: flex;
justify-content: center;
align-items: center;
padding: 10px;

background: #F0F4F8;
box-shadow: -1px -1px 3px #FFFFFF, 1px 1px 3px rgba(178, 189, 203, 0.7);
color: #F0BF25;
font-size: 15px;
border-radius: 50px;
}
}

.margin-left-btn{
    margin-left: 15px;
}
.active-icon-color{
    background: #202020;
border-radius: 50px;
width: 34px;
height: 34px;
margin-right: 10px;
display: flex;
    align-items: center;
    justify-content: center;

.active-vector{
    width: 20px;
    
}
}
.active-icon-color-second {
    background: #F0F4F8;
    box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
border-radius: 50px;
width: 34px;
height: 34px;

display: flex;
    align-items: center;
    justify-content: center;

.active-vector{
    width: 20px;
    
}
}
.active-icon-color-third {
    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
border-radius: 50px;
width: 34px;
height: 34px;
margin-right: 10px;
display: flex;
    align-items: center;
    justify-content: center;

.active-vector{
    width: 20px;
    
}
}
.active-sign{
    display: none;
}
.services-work  .nav-item .nav-link.active .active-sign{
    display: block;
}
.modal-tabs{
    .nav-item{
        .active{
            background: #F0F4F8;
box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
        }
        .icon-design{
            margin-left: 15px;
        }
    }
}
.dark{
    .screen-bg{
        background: #1e1e1e!important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000!important;
    }
    .screen-btn ,.design-two{
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000!important;

    }

}





.modal-setting{
    .modal .modal-dialog{

        max-width: 99%!important;
        top: 130px;
        margin: auto;
        
    }
    @media screen and (min-width:1920px) {
        .modal .modal-dialog{
            top: 130px;
           
            
        }
    }
    @media screen and  (min-width: 576px){
   .modal  .modal-dialog {
            max-width: 99%!important;
        }
    }
    @media screen and  (min-width: 576px){
     .modal    .modal-dialog {
            max-width: 99%!important;
        }
    }
.modal-90w{
    width: 90%!important;
}

    .modal-content{
        
        background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%)!important;
    box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8), 2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4)!important;
    border: 1px solid transparent!important;
    border-radius: 2.84939px;
    }
    .modal-header{
        border-bottom: none;
    }
    .screen-btn{
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        /* Neo/Light/Outer/2 */
        
        box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
    border-radius: 25px;
    padding: 15px ;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #202020;
    width: 143px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    border: none;
    }
}

// @media screen and  (min-width: 576px){
//     .modal-dialog {
//         max-width: 100%!important;
//         margin-right: auto;
//         margin-left: auto;
//     }
// }
    @media (min-width: 576px){
        .modal-dialog.modal-90w {
            max-width: 100%!important;
       }
   }
   .banner-btn{
    .btn-left{
        button{
            top: 12px;
            transform: rotate(7deg);
        }
    }
    .btn-bg{
        background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%)!important;
        box-shadow: inset -4.55556px -4.55556px 7.59259px rgba(255, 255, 255, 0.75), inset 3.03704px 3.03704px 7.59259px rgba(166, 180, 200, 0.4)!important;
    border-radius: 3px;
    border: 1px solid #F2E6CF;
    button{
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
border-radius: 5px;
color: #202020!important;
    }
       }
       .ml-3{
        margin-left: 40px;
       }
   }
   .modal-dialog.modal-90w{
    width: 99%;
    top: 130px;
    margin: auto;
    .modal-content{
        background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%) !important;
        box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8), 2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4) !important;
        border: 1px solid transparent !important;
        border-radius: 2.84939px;
        .modal-header {
            border-bottom: none;
        }
    }
   }
   .screen-btn{
    cursor: pointer;
   }
    .page-no{
        font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 100%;
text-align: center;
letter-spacing: 0.01em;
color: #7F838F;
span{
    color: #202020;
}
    }
    .topic-css{
        justify-content: space-between;
    width: 83%;
    align-items: center;
    }
    .offcanvs.sidebar-dailog{

  
    
        background-color: #202020 !important;
        .offcanvas-header {
            .switch-outer {
                width: 62.06px;
                height: 33px;
                background-color: white;
                border-radius: 49.5px;
                z-index: 1;
                margin: 0px 15px;
                .switch {
                    display: inline-block;
                    position: relative;
                    width: 39.58px;
                    height: 22.71px;
                    box-shadow: none!important;
                    left: 6px;
                    top: 6px;
                    box-shadow: none;
                    border-radius: 49.5px;
                    z-index: 2;
                    background: #7cbaa7;
                    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    vertical-align: middle;
                    cursor: pointer;
                }
                .switch::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 20px;
                    width: 15.03px;
                    height: 15.03px;
                    background: white;
                    border-radius: 50%;
                    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                        box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                }
                .switch:active::before {
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                }
                input:checked + .switch {
                    background: #7cbaa7;
                }
                input:checked + .switch::before {
                    left: 9px;
                    background: #fff;
                }
                input:checked + .switch:active::before {
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                }
            }
            .mode-set {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #3c3d40;

                z-index: 1;
            }
            .social-set {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #f0f4f8;
                z-index: 1;
            }
            .btn-close {
                background-color: white;
                opacity: 1;
                border-radius: 0px !important;
                width: 30px;
                height: 30px;
            }
        }
        .offcanvas-body {
            padding: 20px 0px;
            .sidebar-li {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                border-bottom: 1px solid grey;
                padding: 20px;

                /* Grey/8 */

                color: #f0f4f8;
            }
            .bottom-tabs {
                color: #cbd5e0;
                margin-top: 5px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                h6 {
                    margin-left: 10px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 120%;
                    color: #cbd5e0;
                }
                svg {
                    font-size: 25px;
                }
            }
            .testimonial-p {
                color: #cbd5e0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .top-padding {
                display: flex;
                justify-content: center;
                align-items: center;
                li {
                    padding: 0px 8px;
                }
            }
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: white;
                    overflow: hidden;
                    width: 87px;
                    padding: 8px;
                    height: 87px;
                    top: 97px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #7cbaa7;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 130px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }
    .offcanvs{
        z-index: 99999;
        right: 20px;
    }
    .offcanvas {
        position: absolute!important;
        right: 40px!important;
        left: revert!important;
        top: 80px!important;
        overflow-y: revert!important;
    }
    .offcanvas-backdrop.show {
        opacity: 0!important;
    }
    .offcanvas-backdrop.fade.show{
        opacity: 0!important;
    }

    .subscription-regular-style{
        background-color: white;
        padding: 50px 100px;
        max-width: 1000px;
        width: 100%;
        .regular-main-wrapper{
            background-color: white;
            position: relative;
        width: 100%;
            .subscribe-img-wrapper{
                background-color: white;
                padding: 42px 20px 30px 20px;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                .subscribeUserIcon{
                    width: 140px;
                    height: 140px;
                }
                .subscribeAroundTextImg{
                    width: 181px;
                    height: 100px;
                    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
                }
                .choose-position{
                    font-size: 13px;
                    line-height: 16px;
                    color: #FFFFFF;
                    display: block;
                    background-color: #202020;
                    padding: 10px 20px;
                    margin: 0 0 0 0;
                }
            }
            .sign-up-form{
                background-color: #202020;
                form{
                    .form-group{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        input{
                            &:focus{
                                border: none;
                                width: 400px;
                            }
                        }
                    }
                }
            }
        }
    }
  
