@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

///Regular Version Theme 2 
.regular5-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbd5e0;
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular5_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        position: relative;

        .after-image {
            position: absolute;
            top: 40%;
            right: 10%;
        }

        .hr-break {
            border-top: 2px solid #CBD5E0;
            width: 630px;
            margin-bottom: 12%;
            margin-top: 3%;


        }

        .heading-client-div {
            text-transform: uppercase;

            span {
                display: block;
            }

            .span1 {
                font-weight: 300;
                color: #F0F4F8;
                line-height: 48.41px;
                text-align: center;
                font-size: 40px;
                font-family: Inter;
            }

            .span2 {
                font-weight: 700;
                font-size: 36px;
                color: #7CBAA7;
                line-height: 43.57px;
                text-align: center;
                font-family: Inter;

            }

            .span3 {
                font-size: 36px;
                font-weight: 700;
                line-height: 43.57px;
                text-align: center;
                color: #9BB6AF;
                font-family: Inter;
            }
        }


        .main-grid-container {

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            width: 620px;
            height: 300px;
            position: relative;

            .grid-container-left {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .left-grid1 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 25px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #CBD5E0;

                    img {
                        width: 150px;
                        height: 150px;

                    }
                }

                .left-grid2 {
                    padding: 25px 25px 0px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;

                    position: relative;

                    .profile-div {
                        margin-bottom: 10px;

                        h1 {
                            font-size: 24px;
                            font-weight: 600;
                            font-family: Inter;
                            margin: 0px;
                            color: #CBD5E0;


                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            text-align: center;
                            margin: 0px;
                            font-family: inter;
                            color: #B2BDCB;

                        }
                    }

                    .rating-stars-div {
                        position: absolute;
                        bottom: 0%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #CBD5E0;
                        border-top: 1px solid #CBD5E0;
                        width: 100%;
                    }


                }
            }

            .grid-container-right {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                box-sizing: border-box;

                .right-grid1 {
                    padding: 0px 25px 25px 25px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;
                    border-bottom: 1px solid #CBD5E0;
                    position: relative;

                    p {
                        font-size: 13px;
                        font-weight: lighter;
                        line-height: 20.8px;
                        color: #CBD5E0;
                        text-align: left;
                        font-family: Inter;
                        margin: 0px;

                    }

                }

                .right-grid1::after {
                    content: "";
                    position: absolute;
                    top: -16%;
                    left: -5%;
                    width: 30.72px;
                    height: 27.72px;
                    background-image: url('../../../../../images/testimonial-case-study/regular5_desktop_upper_coma.png');
                    background-size: contain;
                }

                .right-grid1::before {
                    content: "";
                    position: absolute;
                    bottom: -30%;
                    right: -28%;
                    width: 70px;
                    height: 63.16px;
                    background-image: url('../../../../../images/testimonial-case-study/regular5_desktop_lower_coma.png');
                    background-size: contain;
                }

                .right-grid2 {
                    padding: 25px 25px 0px 25px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;

                    p {
                        font-size: 13px;
                        font-weight: lighter;
                        line-height: 20.8px;
                        color: #CBD5E0;
                        text-align: left;
                        font-family: Inter;

                    }
                }
            }


        }

        .see-my-wall-div {
            position: absolute;
            top: 88%;

            p {
                color: #F0F4F8;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 1);
                color: #202020;
                font-size: 13px;
                font-weight: 500;
            }
        }

    }
}

.regular5-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbd5e0;
    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular5_tab_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        position: relative;

        .after-image {
            position: absolute;
            top: 40%;
            right: 3%;
        }

        .hr-break {
            border-top: 2px solid #CBD5E0;
            width: 588px;
            margin-bottom: 12%;
            margin-top: 3%;


        }

        .heading-client-div {
            text-transform: uppercase;

            span {
                display: block;
            }

            .span1 {
                font-weight: 300;
                color: #F0F4F8;
                line-height: 48.41px;
                text-align: center;
                font-size: 40px;
                font-family: Inter;
            }

            .span2 {
                font-weight: 700;
                font-size: 36px;
                color: #7CBAA7;
                line-height: 43.57px;
                text-align: center;
                font-family: Inter;

            }

            .span3 {
                font-size: 36px;
                font-weight: 700;
                line-height: 43.57px;
                text-align: center;
                color: #9BB6AF;
                font-family: Inter;
            }
        }


        .main-grid-container {

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            width: 620px;
            height: 300px;
            position: relative;
            transform: translateX(-20px);

            .grid-container-left {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .left-grid1 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 25px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #CBD5E0;

                    img {
                        width: 150px;
                        height: 150px;

                    }
                }

                .left-grid2 {
                    padding: 25px 25px 0px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;

                    position: relative;

                    .profile-div {
                        margin-bottom: 10px;

                        h1 {
                            font-size: 24px;
                            font-weight: 600;
                            font-family: Inter;
                            margin: 0px;
                            color: #CBD5E0;


                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            text-align: center;
                            margin: 0px;
                            font-family: inter;
                            color: #B2BDCB;

                        }
                    }

                    .rating-stars-div {
                        position: absolute;
                        bottom: 0%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #CBD5E0;
                        border-top: 1px solid #CBD5E0;
                        width: 100%;
                    }


                }
            }

            .grid-container-right {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                box-sizing: border-box;

                .right-grid1 {
                    padding: 0px 25px 25px 25px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;
                    border-bottom: 1px solid #CBD5E0;
                    position: relative;

                    p {
                        font-size: 13px;
                        font-weight: lighter;
                        line-height: 20.8px;
                        color: #CBD5E0;
                        text-align: left;
                        font-family: Inter;
                        margin: 0px;

                    }

                }

                .right-grid1::after {
                    content: "";
                    position: absolute;
                    top: -16%;
                    left: -5%;
                    width: 30.72px;
                    height: 27.72px;
                    background-image: url('../../../../../images/testimonial-case-study/regular5_desktop_upper_coma.png');
                    background-size: contain;
                }

                .right-grid1::before {
                    content: "";
                    position: absolute;
                    bottom: -30%;
                    right: -21%;
                    width: 56px;
                    height: 50px;
                    background-image: url('../../../../../images/testimonial-case-study/regular5_desktop_lower_coma.png');
                    background-size: contain;
                }

                .right-grid2 {
                    padding: 25px 25px 0px 25px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;

                    p {
                        font-size: 13px;
                        font-weight: lighter;
                        line-height: 20.8px;
                        color: #CBD5E0;
                        text-align: left;
                        font-family: Inter;

                    }
                }
            }


        }

        .see-my-wall-div {
            position: absolute;
            top: 88%;

            p {
                color: #F0F4F8;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 1);
                color: #202020;
                font-size: 13px;
                font-weight: 500;
            }
        }


    }
}

.regular5-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular5_mobile_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1050px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        position: relative;




        .after-image {
            position: absolute;
            top: 65%;
            left: 62%;
            transform: translateX(24px);
        }

        .hr-break {
            border-top: 2px solid #CBD5E0;
            width: 264px;
            margin-bottom: 12%;
            margin-top: 3%;


        }

        .heading-client-div {
            text-transform: uppercase;

            span {
                display: block;
            }

            .span1 {
                font-weight: 300;
                color: #F0F4F8;
                line-height: 48.41px;
                text-align: center;
                font-size: 30.79px;
                font-family: Inter;
            }

            .span2 {
                font-weight: 700;
                font-size: 27.71px;
                color: #7CBAA7;
                line-height: 43.57px;
                text-align: center;
                font-family: Inter;

            }

            .span3 {
                font-size: 27.71px;
                font-weight: 700;
                line-height: 43.57px;
                text-align: center;
                color: #9BB6AF;
                font-family: Inter;
            }
        }


        .main-grid-container {

            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            width: 260px;
            height: 600px;
            position: relative;

            .grid-container-left {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                margin-bottom: 33px;

                .left-grid1 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 25px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #CBD5E0;

                    img {
                        width: 150px;
                        height: 150px;

                    }
                }

                .left-grid2 {
                    padding: 25px 25px 0px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;

                    position: relative;

                    .profile-div {
                        margin-bottom: 10px;

                        h1 {
                            font-size: 20px;
                            font-weight: 600;
                            font-family: Inter;
                            margin: 0px;
                            color: #CBD5E0;


                        }

                        p {
                            font-size: 13px;
                            font-weight: 400;
                            text-align: center;
                            margin: 0px;
                            font-family: inter;
                            color: #B2BDCB;

                        }
                    }

                    .rating-stars-div {
                        position: absolute;
                        bottom: 0%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #CBD5E0;
                        border-top: 1px solid #CBD5E0;
                        width: 100%;
                    }


                }
            }

            .grid-container-right {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                box-sizing: border-box;

                .right-grid1 {
                    padding: 0px 15px 15px 15px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;
                    border-bottom: 1px solid #CBD5E0;
                    position: relative;

                    p {
                        font-size: 12px;
                        font-weight: lighter;
                        line-height: 20.8px;
                        color: #CBD5E0;
                        text-align: left;
                        font-family: Inter;
                        margin: 0px;

                    }

                }

                .right-grid1::after {
                    content: "";
                    position: absolute;
                    top: -20%;
                    left: -5%;
                    width: 30.72px;
                    height: 27.72px;
                    background-image: url('../../../../../images/testimonial-case-study/regular5_desktop_upper_coma.png');
                    background-size: contain;
                }

                .right-grid1::before {
                    content: "";
                    position: absolute;
                    bottom: -10%;
                    right: -12%;
                    width: 36px;
                    height: 32px;
                    background-image: url('../../../../../images/testimonial-case-study/regular5_desktop_lower_coma.png');
                    background-size: contain;
                }

                .right-grid2 {
                    padding: 15px 15px 0px 15px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;

                    p {
                        font-size: 12px;
                        font-weight: lighter;
                        line-height: 20.8px;
                        color: #CBD5E0;
                        text-align: left;
                        font-family: Inter;

                    }
                }
            }


        }

        .see-my-wall-div {
            position: absolute;
            top: 93%;

            p {
                color: #F0F4F8;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 1);
                color: #202020;
                font-size: 13px;
                font-weight: 500;
            }
        }

    }
}

///Neumoophic Version Theme 2 
/// //////////////////////////////////////////////////////////////////
.neumorphic5-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F4F8;
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic5_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        position: relative;

        .after-image {
            position: absolute;
            top: 40%;
            right: 10%;
        }

        .hr-break {
            border-top: 2px solid rgba(178, 189, 203, 0.7);
            width: 630px;
            margin-bottom: 12%;
            margin-top: 3%;


        }

        .heading-client-div {
            text-transform: uppercase;

            span {
                display: block;
            }

            .span1 {
                font-weight: 300;
                color: #202020;
                line-height: 48.41px;
                text-align: center;
                font-size: 40px;
                font-family: Inter;
            }

            .span2 {
                font-weight: 700;
                font-size: 36px;
                line-height: 43.57px;
                text-align: center;
                font-family: Inter;
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                -webkit-text-fill-color: transparent;
                background-clip: text;

            }

            .span3 {
                font-size: 36px;
                font-weight: 700;
                line-height: 43.57px;
                text-align: center;
                font-family: Inter;
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }


        .main-grid-container {

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            width: 620px;
            height: 300px;
            position: relative;

            .grid-container-left {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .left-grid1 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 25px;
                    box-sizing: border-box;
                    border-bottom: 1px solid rgba(178, 189, 203, 0.7);

                    img {
                        width: 150px;
                        height: 150px;

                    }
                }

                .left-grid2 {
                    padding: 25px 25px 0px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;

                    position: relative;

                    .profile-div {
                        margin-bottom: 10px;

                        h1 {
                            font-size: 24px;
                            font-weight: 600;
                            font-family: Inter;
                            margin: 0px;
                            color: #202020;


                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            text-align: center;
                            margin: 0px;
                            font-family: inter;
                            color: #202020;

                        }
                    }

                    .rating-stars-div {
                        position: absolute;
                        bottom: 0%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #CBD5E0;
                        border-top: 1px solid #CBD5E0;
                        width: 100%;
                    }


                }
            }

            .grid-container-right {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                box-sizing: border-box;

                .right-grid1 {
                    padding: 0px 25px 25px 25px;
                    box-sizing: border-box;
                    border-left: 1px solid rgba(178, 189, 203, 0.7);
                    border-bottom: 1px solid rgba(178, 189, 203, 0.7);
                    position: relative;

                    p {
                        font-size: 13px;
                        font-weight: 300;
                        line-height: 20.8px;
                        color: #202020;
                        text-align: left;
                        font-family: Inter;
                        margin: 0px;

                    }

                }

                .right-grid1::after {
                    content: "";
                    position: absolute;
                    top: -16%;
                    left: -5%;
                    width: 30.72px;
                    height: 27.72px;
                    background-image: url('../../../../../images/testimonial-case-study/neumorphic5_desktop_upper_coma.png');
                    background-size: contain;
                }

                .right-grid1::before {
                    content: "";
                    position: absolute;
                    bottom: -30%;
                    right: -28%;
                    width: 70px;
                    height: 63.16px;
                    background-image: url('../../../../../images/testimonial-case-study/neumorphic5_desktop_lower_coma.png');
                    background-size: contain;
                }

                .right-grid2 {
                    padding: 25px 25px 0px 25px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;

                    p {
                        font-size: 13px;
                        font-weight: 300;
                        line-height: 20.8px;
                        color: #202020;
                        text-align: left;
                        font-family: Inter;

                    }
                }
            }


        }

        .see-my-wall-div {
            position: absolute;
            top: 88%;

            p {
                color: #202020;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(124, 186, 167, 1);
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                color: #202020;
                font-size: 13px;
                font-weight: 500;
            }
        }

    }
}

.neumorphic5-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F4F8;
    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic5_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        position: relative;

        .after-image {
            position: absolute;
            top: 40%;
            right: 10%;
        }

        .hr-break {
            border-top: 2px solid rgba(178, 189, 203, 0.7);
            width: 630px;
            margin-bottom: 12%;
            margin-top: 3%;


        }

        .heading-client-div {
            text-transform: uppercase;

            span {
                display: block;
            }

            .span1 {
                font-weight: 300;
                color: #202020;
                line-height: 48.41px;
                text-align: center;
                font-size: 40px;
                font-family: Inter;
            }

            .span2 {
                font-weight: 700;
                font-size: 36px;
                line-height: 43.57px;
                text-align: center;
                font-family: Inter;
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                -webkit-text-fill-color: transparent;
                background-clip: text;

            }

            .span3 {
                font-size: 36px;
                font-weight: 700;
                line-height: 43.57px;
                text-align: center;
                font-family: Inter;
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }


        .main-grid-container {

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            width: 620px;
            height: 300px;
            position: relative;

            .grid-container-left {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;

                .left-grid1 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 25px;
                    box-sizing: border-box;
                    border-bottom: 1px solid rgba(178, 189, 203, 0.7);

                    img {
                        width: 150px;
                        height: 150px;

                    }
                }

                .left-grid2 {
                    padding: 25px 25px 0px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;

                    position: relative;

                    .profile-div {
                        margin-bottom: 10px;

                        h1 {
                            font-size: 24px;
                            font-weight: 600;
                            font-family: Inter;
                            margin: 0px;
                            color: #202020;


                        }

                        p {
                            font-size: 14px;
                            font-weight: 400;
                            text-align: center;
                            margin: 0px;
                            font-family: inter;
                            color: #202020;

                        }
                    }

                    .rating-stars-div {
                        position: absolute;
                        bottom: 0%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #CBD5E0;
                        border-top: 1px solid #CBD5E0;
                        width: 100%;
                    }


                }
            }

            .grid-container-right {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                box-sizing: border-box;

                .right-grid1 {
                    padding: 0px 25px 25px 25px;
                    box-sizing: border-box;
                    border-left: 1px solid rgba(178, 189, 203, 0.7);
                    border-bottom: 1px solid rgba(178, 189, 203, 0.7);
                    position: relative;

                    p {
                        font-size: 13px;
                        font-weight: 300;
                        line-height: 20.8px;
                        color: #202020;
                        text-align: left;
                        font-family: Inter;
                        margin: 0px;

                    }

                }

                .right-grid1::after {
                    content: "";
                    position: absolute;
                    top: -16%;
                    left: -5%;
                    width: 30.72px;
                    height: 27.72px;
                    background-image: url('../../../../../images/testimonial-case-study/neumorphic5_desktop_upper_coma.png');
                    background-size: contain;
                }

                .right-grid1::before {
                    content: "";
                    position: absolute;
                    bottom: -30%;
                    right: -28%;
                    width: 70px;
                    height: 63.16px;
                    background-image: url('../../../../../images/testimonial-case-study/neumorphic5_desktop_lower_coma.png');
                    background-size: contain;
                }

                .right-grid2 {
                    padding: 25px 25px 0px 25px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;

                    p {
                        font-size: 13px;
                        font-weight: 300;
                        line-height: 20.8px;
                        color: #202020;
                        text-align: left;
                        font-family: Inter;

                    }
                }
            }


        }

        .see-my-wall-div {
            position: absolute;
            top: 88%;

            p {
                color: #202020;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(124, 186, 167, 1);
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                color: #202020;
                font-size: 13px;
                font-weight: 500;
            }
        }

    }
}

.neumorphic5-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic5_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1050px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        position: relative;




        .after-image {
            position: absolute;
            top: 65%;
            left: 62%;
            transform: translateX(24px);
        }

        .hr-break {
            border-top: 2px solid #CBD5E0;
            width: 264px;
            margin-bottom: 12%;
            margin-top: 3%;


        }

        .heading-client-div {
            text-transform: uppercase;

            span {
                display: block;
            }

            .span1 {
                font-weight: 300;
                color: #202020;
                line-height: 48.41px;
                text-align: center;
                font-size: 30.79px;
                font-family: Inter;
            }

            .span2 {
                font-weight: 700;
                font-size: 27.71px;
                line-height: 43.57px;
                text-align: center;
                font-family: Inter;
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                -webkit-text-fill-color: transparent;
                background-clip: text;

            }

            .span3 {
                font-size: 27.71px;
                font-weight: 700;
                line-height: 43.57px;
                text-align: center;
                font-family: Inter;
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }


        .main-grid-container {

            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            width: 260px;
            height: 600px;
            position: relative;

            .grid-container-left {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                margin-bottom: 33px;

                .left-grid1 {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-bottom: 25px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #CBD5E0;

                    img {
                        width: 150px;
                        height: 150px;

                    }
                }

                .left-grid2 {
                    padding: 25px 25px 0px 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;

                    position: relative;

                    .profile-div {
                        margin-bottom: 10px;

                        h1 {
                            font-size: 20px;
                            font-weight: 600;
                            font-family: Inter;
                            margin: 0px;
                            color: #202020;


                        }

                        p {
                            font-size: 13px;
                            font-weight: 400;
                            text-align: center;
                            margin: 0px;
                            font-family: inter;
                            color: #202020;

                        }
                    }

                    .rating-stars-div {
                        position: absolute;
                        bottom: 0%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #CBD5E0;
                        border-top: 1px solid #CBD5E0;
                        width: 100%;
                    }


                }
            }

            .grid-container-right {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(2, 1fr);
                grid-column-gap: 0px;
                grid-row-gap: 0px;
                box-sizing: border-box;

                .right-grid1 {
                    padding: 0px 15px 15px 15px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;
                    border-bottom: 1px solid #CBD5E0;
                    position: relative;

                    p {
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 20.8px;
                        color: #202020;
                        text-align: left;
                        font-family: Inter;
                        margin: 0px;

                    }

                }

                .right-grid1::after {
                    content: "";
                    position: absolute;
                    top: -20%;
                    left: -5%;
                    width: 30.72px;
                    height: 27.72px;
                    background-image: url('../../../../../images/testimonial-case-study/neumorphic5_desktop_upper_coma.png');
                    background-size: contain;
                }

                .right-grid1::before {
                    content: "";
                    position: absolute;
                    bottom: -10%;
                    right: -12%;
                    width: 36px;
                    height: 32px;
                    background-image: url('../../../../../images/testimonial-case-study/neumorphic5_desktop_lower_coma.png');
                    background-size: contain;
                }

                .right-grid2 {
                    padding: 15px 15px 0px 15px;
                    box-sizing: border-box;
                    border-left: 1px solid #CBD5E0;

                    p {
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 20.8px;
                        color: #202020;
                        text-align: left;
                        font-family: Inter;

                    }
                }
            }


        }

        .see-my-wall-div {
            position: absolute;
            top: 93%;

            p {
                color: #202020;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(124, 186, 167, 1);
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);

                color: #202020;
                font-size: 13px;
                font-weight: 500;
            }
        }

    }
}