@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

.desktop-version-2{

    .Neumorphic-design {
        .design-screen-desktop-1 .main-content .hero-sidebar .mode-set {
            left: 103px !important;
        }
        .design-screen-desktop-1 .main-content .hero-sidebar .social-set {
            left: 103px !important;
        }
        .design-screen-desktop-1{
            .searchbox {
                top: 196px;
                left: -528%;
            }
            .navbar-top{
       .meu-show img {
                    position: revert!important;
                    margin: auto;
                    text-align: center;
                }
            }
            .main-content .hero-sidebar {
            
                width: 12%;
     
            }
            .gold-btn-version{
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
                border-radius: 30px;
                padding: 10px;
                font-size: 12px;
                font-weight: 500;
            }
   .sidebar-video{
    display: block;
   }
   .version-video-2{
    background: #EFF4F8;
    margin-top: 15px;
   

box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
border-radius: 10px!important;
.version-vido{
    border-bottom-left-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;
    border: none;
    width: 100%;
}
h1{
    font-size: 9px;
    font-weight: 800;
    @media screen and (min-width:1700px) {
        font-size: 14px!important;
    }
}
img{
    width: 100px;
    border-radius: 100%;
    background: linear-gradient(#f0f4f8, #f0f4f8) padding-box, linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
    border: 1px solid transparent;
    @media screen and (min-width:1600px) {
        width: 47px;
    }
 
}
h5{
    font-size: 8px;
 margin-bottom: 3px!important;
 @media screen and (min-width:1704px) {
    font-size: 12px;
}
}
  p{
font-size: 7.71642px!important;
line-height: 8px!important;
color: rgba(0, 0, 0, 0.5)!important;
text-align: left!important;
margin-bottom: 2px!important;
margin-left: 10px;
@media screen and (min-width:1704px) {
    font-size: 11px!important;
}
.logos_tiktok-icon{
    width: 20%;
    margin-left: 10px;
    border: none!important;
    border-radius: none!important;
}
  }
   }
            .version-2{
                display: block!important;
            }
            .hero-part{
                display: none;
            }
            .search-position{
                display: none;
            }
            .hero-sidebar{
                .info-oter{
                    display: none;
                }
            }
            .search-navbar-btn{
                display: block!important;
                margin-top: -30px;
                li{
                    list-style: none;
                }
                .info-oter {
                    width: 48px !important;
                    height: 48px !important;
                    margin: auto;
                    border-radius: 50px;
                    padding: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                    background: #f0f4f8;
                    box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                }
              .info-icon {
                    border: 3px solid #394649;
                    padding: 5px 9px 7px 12px;
                    font-size: 14px;
                    margin: 20px 0px;
                    color: #394649;
                    border-radius: 50px;
                    position: relative;
                    z-index: 2;
                }
                .fa-magnify {
                    background: #f0f4f8;
                    box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                    padding: 12px !important;
              font-size: 20px!important;
                    border-radius: 30px;
                    margin: 10px 0px 10px;
        color: #394649;
                }
                .search-position{
                    display: block!important;
                    position: relative;
                    z-index: 2;

        
                }
                .border-bt{
                    border: 2px solid #F0F4F8;

margin-bottom: 5px;
                    box-shadow: -1px -1px 1px #FFFFFF, 1px 1px 1px rgba(178, 189, 203, 0.7);
                }
            }
        }
        .main-content .hero-section .arrow-button {
            border: none !important;
            background-color: transparent !important;
        }
        .design-screen-desktop-1 .main-content .hero-section .arrow-button .icon-arrow {
            transform: rotate(90deg);
            color: #e0c67a !important;
            font-size: 90px;
            position: absolute;
            right: 15%;
            z-index: 2;
            top: -3px;
        }
        .hero-section {
            position: relative;
        }
        .arrow-button .icon-arrow {
            transform: rotate(180deg);
            color: #e0c67a !important;
            font-size: 90px;
            position: absolute;
            right: -37px;
            top: 581px;
            z-index: 3;
        }
        .intro-card {
            position: absolute;
            z-index: 1;
            background: #F0F4F8;
            /* Neo/Light/Inner/4 */
            
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
            border-radius: 0px 0px 20px 20px;
            width: 300px;
            height: 340px;
            right: 25px;
            padding: 15px;
            .intro-inner{
                background: #F0F4F8;
                /* Neo/Light/Outer/4 */
                
                box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                border-radius: 0px 0px 15px 15px;
                padding:10px;
            }
            .icon-arrow {
                transform: rotate(90deg);
                color: #7cbaa7;
                font-size: 90px;
                position: absolute;
                left: 35%;
                top: -38px;
            }
            h1 {
                font-weight: 700;
                font-size: 48px;
                line-height: 58px;
               color: #E4A122;;
            }
            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                color: #000000;
            }
            h2 {
                font-weight: 500;
                font-size: 36px;
                line-height: 44px;
                text-transform: uppercase;
                color: #202020;
            }
            .border-card {
                width: 100px;
                height: 3px;
                margin: 20px 0px;
                background: #885769;
            }
            p {
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #3c3d40;
            }
            h4 {
                font-weight: 500;
                font-size: 36px;
                line-height: 44px;
                text-transform: uppercase;
                color: #202020;
            }
        }
        .arrow-button {
    
            transition: transform 300ms;
          }
          .plalist-arrow{
            .arrow-buttonn {
    
                transition: transform 300ms;
              }
          }
          
       
        .dropdown-content {
    position: relative;
    z-index: 3;
      animation-fill-mode: both;
    }
    
    
    
    .fadeInUp {
        animation-name: fadeInUp;
        animation-duration: 0.3s;
        animation-timing-function: ease-in-out;
      }
      
      .fadeInDown {
        animation-name: fadeInDown;
        animation-duration: 0.3s;
        animation-timing-function: ease-in;
      }
      
      @keyframes fadeInUp {
        0% {
          opacity: 0;
          transform: translateY(10px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      
      @keyframes fadeInDown {
        0% {
          opacity: 0;
          transform: translateY(-10px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
          
          @keyframes slideDown {
            100% {
              opacity: 0;
              transform: translateY(-10px);
            }
            
            0% {
              opacity: 1;
              transform: translateY(0);
            }
          }
    }
    }

.Neumorphic-design {

    #parent-circle{
        border: 2px solid #F0F4F8;
        box-shadow: inset -1.59095px -2.38643px 3.1819px #FFFFFF, inset 1.59095px 1.59095px 3.1819px #A0B9D0;
        .circle{
            background: #F0F4F8;
border: 1.00362px solid #F0F4F8;
box-shadow: 4.01449px 4.01449px 8.02899px #C9D9E8, -4.01449px -4.01449px 8.02899px #FFFFFF;
color: #C49530;

        }
        .videoo {
      
            color: rgb(124, 186, 167)!important;
        }
        .snapchat {

            background: black!important;
        }
    }
    .sidebar-li{
        color: black!important;
    }
    .bars-hambrger .hamburger-outer{
        background-color: #f0f4f8!important;
    }
    .hamburger-outer{
        .book-me-mue{
            position: revert!important;
        }
        .meo-show{
            display: none;
        }
    }
    .reg-book{
        display: none;
    }
   
   .navbar-top .bottom-tabs , .hero-sidebar .bottom-tabs{
        svg{
            background: #f0f4f8;
            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 30px;
            width: 20px;
            height: 20px;
            padding: 6px;
        }
        }
    .hamburger-outer{
        .bottom-tabs{
            display: flex;
            justify-content: center;
            svg{
                width: 25px;
                height: 25px;
                padding: 2px;
                margin-right: 10px;
                color: #192529;
            }
            h6 {
                font-weight: 600;
                font-size: 15px;
                line-height: 120%;
                color: #192529!important;
            }
        }
    }
    .hero-section-reg {
        display: none;
    }
    .hero-section-mue {
        display: block!important;
    }
    .hero-section-mue .content-img {
        width: 100% !important;
        right: 0;
        height: 75% !important;
        box-shadow: inset -4px -4px 10px #ffffff, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
        border-radius: 20px;
    }
    .mue-shape-right {
        display: block;
        position: absolute;
        
    right: 0;
    top: 208px;
    }
    .fa-magnify{
        font-size: 26px;
        margin: 10px 0px 55px;
    color: #394649;
    background: #F0F4F8;
        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
        border-radius: 30px;
        padding: 10px;
    }
    .info-oter{
        background: #F0F4F8;
        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
        width: 50px;
        height: 50px;
        margin: auto;
        border-radius: 50px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .meu-social{
        display: block;
    }
@media screen and (max-width: 1450px){
    .design-screen-desktop-1 .content-text .playlist-section-text {
        right: -167px;
        width: 330px;
    }
}


@media screen and (max-width: 1662px){
.design-screen-desktop-1 .content-text .playlist-section-text {
    right: -193px;
    width: 350px;
}
}
    .meu-show{
        display: block;

    }
    .test-reg{
        display: none;
    }
    .timeline-gold{
        display: block!important;
    }
    .reg-social{
        display: none!important;
    }
    .switch-border {
        width: 182px;
        left: 22px;
        height: 93px;
        background-color: white;
        position: relative;
        border-radius: 49.5px;
        z-index: 31;
        .check-box {
            transform: scale(2);
        }

        input[type="checkbox"] {
            position: relative;
            appearance: none;
            width: 100px;
            height: 50px;
            background: #ccc;
            border-radius: 50px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            transition: 0.4s;
        }

        input:checked[type="checkbox"] {
            background: #7da6ff;
        }

        input[type="checkbox"]::after {
            position: absolute;
            content: "";
            width: 48px;
            height: 48px;
            left: 50%;
            top: 10px;
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transform: scale(1.1);
            transition: 0.4s;
        }

        input:checked[type="checkbox"]::after {
            left: 14px;
        }

        check-box {
            display: inline-block;
            position: relative;
            width: 130px;
            height: 69.64px;
            left: -16px;
            top: 10px;
            box-shadow: none !important;
            border-radius: 49.5px;
            z-index: 2;
            background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%)!important;
            box-shadow: 1.71429px 1.71429px 1.71429px #b6c8da, -1.71429px -1.71429px 1.71429px #ffffff!important;
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            vertical-align: middle;
            cursor: pointer;
        }
        input[type="checkbox"] {
            display: inline-block;
            position: relative;
            width: 130px;
            height: 69.64px;
            left: -16px;
            top: 10px;
            box-shadow: none !important;
            border-radius: 49.5px;
            z-index: 2;
            background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%)!important;
            box-shadow: 1.71429px 1.71429px 1.71429px #b6c8da, -1.71429px -1.71429px 1.71429px #ffffff!important;
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            vertical-align: middle;
            cursor: pointer;
        }

        input[type="checkbox"]::before {
            content: "";

            border-radius: 50%;

            position: absolute;
            top: 0;
            left: 0;
            transition: 0.5s;
        }

        input[type="checkbox"]:checked::before {
            transform: translateX(100%);
            background: #fff;
        }

        input[type="checkbox"]:checked {
            display: inline-block;
            position: relative;
            width: 130px;
            height: 69.64px;
            left: -16px;
            top: 10px;
            box-shadow: none !important;
            border-radius: 49.5px;
            z-index: 2;
            background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            box-shadow: 1.71429px 1.71429px 1.71429px #b6c8da, -1.71429px -1.71429px 1.71429px #ffffff;
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            vertical-align: middle;
            cursor: pointer;
        }
    }
    .btn-video {
        display: none !important;
    }

    .design-screen-desktop-1 {
        @media screen and (max-width:1369px) {
            .active-oter {
             
                margin-right: 7px !important;
            }
            .meu-show img {
     
                right: 2%!important;
       
            }
            .content-text .tab-himself-text{
                padding: 70px 115px 20px;
            }
            .main-content .hero-sidebar .mode-set {
         
                left: 85px!important;
            }
            .main-content .hero-sidebar .social-set{
                left: 85px!important;
            }
            .content-text .playlist-section h4{
                right: -58px !important;
            }
        }

      .hamburger-outer  .meu-show img{
            position: revert!important;
        }
       .bookme-oter .bookme-inner .hamburger-outer .forms-inpt {
            background-color: #F0F4F8!important;
            padding: 3px 15px;
        }
        .mue-shape {
            position: absolute;
            z-index: 0;
            bottom: 137px;
            width: 148px;
            left: 37px;
            display: block;
        }
        .m-section  .hero-section {
            position: relative;
            box-shadow: none!important;
            background: transparent!important;
padding: 0px!important ;

margin: 0px!important;
        }
      .m-section .hero-section-mue .content-img {
            width: 90% !important;
            right: -5% !important;
            height: 624px!important;
            padding: 15px!important;
        }

        .content-text .tab-section {
            width: 55%;
            margin: auto;
            margin-left: 21%!important;
        }
        .switch-border {
            background: #F0F4F8!important;
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
        }
        .switch-border input[type=checkbox]::after {
            background: #EAEFF6!important;
            box-shadow: inset 0px -6.85714px 13.7143px #FFFFFF, inset 0px 6.85714px 10.2857px #A0B9D0!important;
        }
        .bars-hambrger .hamburger-outer .testimonial .timeline-top {
            flex-direction: column;
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            color: black;
            background: #f0f4f8 !important;
            box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
            overflow: hidden;
            width: 81px;
            padding: 8px;
            height: 81px;
            top: 88px;
            left: 18px;
            p {
                font-size: 6px;
            }
        }
        .hamburger-outer {
    
            position: absolute;
            z-index: 3;
            padding: 40px 20px 40px;
            width: 400px;
            right: 0;
            top: 0;
            background: #F0F4F8!important;
            box-shadow: -4px -4px 10px #FFFFFF, 5px 5px 10px rgba(178, 189, 203, 0.8);
            border-radius: 10px;
            .close-btn {
             
                height: 67px;
                font-size: 23px;
                position: absolute;
                right: 14px;
                top: 1px;
            }
            .sidebar-li {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                background: #F0F4F8;
                background: #F0F4F8;
                box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
             
                text-align: center;
              border-bottom: 1px solid transparent; 
                padding: 20px;
                color: #f0f4f8;
                color: #202020;
                margin: 16px;
             
            }
            .switch-outer{
                display: none;
            }
        }
       .gold-btn {
            position: absolute;
            z-index: 5;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
            box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border-radius: 30px;
            padding: 20px;
            width: 270px;
            top: 39px;
            display: block;
            text-align: center;
            margin-bottom: 63px;
            margin-top: -27px;
            left: -66px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #202020;
        }
        .searchbox{
            .search-area{
                .search-field {
margin-top: 50px!important;
                    background: #F0F4F8!important;
                    box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB!important;
                    border-radius: 40px;
                    border: 1px solid transparent!important;
                    width: 100%;
                    margin: 0px 10px 0px;
                    height: 44px !important;
                    box-shadow: none;
                    padding: 2px 11px;
          
            
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #394649;
                }
            }
     
            .close-btn {
                border: none;
                background-image: url(../../../../../images/close-batch.png);
                background-repeat: no-repeat;
                top: 2px;
                height: 68px;
                margin-top: -11px;
                top: 3px;
                right: 9px;
                font-size: 34px;
                color: grey;
                position: absolute;
                svg{
                    margin-top: -34px;
                    margin-left: 10px;
                    font-size: 28px;
                }
            } 
        }
        .meu-social{
            display: block;
            li {
                background: #F0F4F8;
                box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
                border-radius: 30px;
                padding: 10px;
                width: 35px;
                height: 35px;
                text-align: center;
                padding: 9px 9px 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 15px auto;
    
            }
        }
      
        .close-btn {
            border: none;
            background-image: url(../../../../../images/close-batch.png);
            background-repeat: no-repeat;
            top: 2px;
            height: 68px;
            margin-top: -10px;
            top: 3px;
            right: 9px;
            font-size: 34px;
            color: grey;
            position: absolute;
            svg{
                margin-top: -34px;
                margin-left: 10px;
                font-size: 28px;
            }
        }
        .meu-icon {
            display: block;
            margin-right: 10px;
            margin-left: 5px;
        }
        .bookme-inner{
            background: #f0f4f8;
        }
        .meu-img {
            display: block;
        }
        .reg-timeline{
            display: none;
        }
        .meu-timeline {
            display: block !important;
        }
        .forms-inpt {
  
            border-radius: 10px;
            padding: 38px 15px;
          margin-top: 40px;
            .form-control {
                width:100%;
                margin: 0px 3px;
                height: 100% !important;
                padding: 13px 30px;
                background: #F0F4F8;
                border: 1px solid transparent!important;
                box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
                border-radius: 4px!important;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
        
                color: #808492;
            }
        }
        .bars-hambrger {
            display: flex;
            align-items: center;
        }
        .reg-timeline {
            display: none;
        }
        .reg-img {
            display: none;
        }
        .reg-icon {
            display: none;
        }
        .meu-icon {
            width: 21.57px;
            height: 21.57px;
            background: #f0f4f8;
            box-shadow: inset 0px -0.78422px 1.56844px #ffffff, inset 0px 0.78422px 1.17633px #a0b9d0;
            border-radius: 11.7633px;
        }
        .meu-playlist-section {
            background: #f0f4f8;
            box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
            border-radius: 6.41451px;
            padding: 15px !important;
            .next-icon-center .iconss {
                font-size: 10px;
                color: #c49530;
                margin: 8px;
            }
        }
        background: #f0f4f8;

        .meu-show img {
            position: absolute;

            display: flex;
            flex-direction: row;
            align-items: center;
            right: 7%;
            top: -4px;
        }

        .navbar-top {
            background: #f0f4f8;
            height: 160px;
            padding: 24px !important;
            .active-oter {
                width: 85px;
                height: 85px;
                margin-right: 48px;
                padding: 8px;
                z-index: 2;
                border-radius: 50px;

                background: #f0f4f8;
                box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
            }
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: #f0f4f8!important;
                    box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
                    width: 87px;
                    padding: 8px;
                    height: 87px;
                    top: 97px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #7cbaa7;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 140px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }

            .profile-img {
                width: 83px;
                margin-left: 30px;
                margin-top: -161px;
            }
            @media screen and (max-width:1800px) and (min-width:1600px) {
                .profile-img {
                width: 50%;
                margin-left: 18%;
                margin-top: -91%;
                }
            }
            .icons-circle {
                width: 140px;
                margin-top: 18px;
            }
            .img-logo {
                margin-left: 20px;
                width: 110px;
            }
            .timeline {
                display: flex;
                justify-content: revert!important;
                counter-reset: year 2016;
                position: relative;
                margin-top: 27px;
                p {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 11px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -13px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-top {
                width: 40px;
                height: 40px;
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #5f6168;
                background: #f0f4f8!important;
                box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
                top: -27px;
                left: 15px;
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;
                width: 70px;
                height: 70px;
                background: #eff4f8;
                /* Neo/Light/Outer/4 */
                box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                color: #5f6168;
                font-size: 15px;
                margin-right: 47px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                @media screen and (max-width: 1400px) {
                    margin-right: 15px !important;
                }
                .timeline-med {
                    background-color: #eff4f8;
                    box-shadow: 4px 4px 8px #b7c7d7, -2px -2px 8px #ffffff;
                    width: 75px;
                    margin: 10px;
                    height: 75px;
                    border-radius: 50%;
                }
                img {
                    width: 15px;
                    margin-top: -10px;
                }
                .message {
                    width: 20px !important;
                    margin-top: -10px;
                }
                .load {
                    width: 25px !important;
                    margin-top: -10px;
                }
                .answer {
                    width: 25px;
                    margin-top: -10px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;

                width: 60%;
                height: 12px;
                border: 3px solid #f0f4f8;
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                top: 34px;
                box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9);
                left: 85px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
        }

        @media screen and (max-width: 1550px) {
            .navbar-top {
                background: #f0f4f8;
                height: 160px;
                padding: 24px !important;
                .testimonial {
                    margin-top: -80px;
                    .timeline-top {
                        flex-direction: column;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        position: relative;
                        color: #5f6168;
                        background: #f0f4f8!important;
                        box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;

                        width: 87px;
                        padding: 8px;
                        height: 87px;
                        top: 97px;
                        left: 23px;
                        p {
                            margin-top: 4px;
                            line-height: 10px;
                        }
                        .icon-green {
                            font-size: 8px;
                            color: #7cbaa7;
                            padding-top: 3px;
                        }
                        p {
                            font-size: 8px;
                        }
                    }

                    img {
                        width: 140px;
                        margin: 0px 13px;
                        position: relative;
                        z-index: 2;
                    }
                }
                .profile-img {
                    width: 74px;
                    margin-left: 17px;
                    margin-top: -125px;
                }
                .icons-circle {
                    width: 125px;
                    margin-top: 18px;
                }
                .img-logo {
                    margin-left: 11px;
                    width: 88px;
                }
                .timeline {
                    display: flex;
                    justify-content: space-around;
                    counter-reset: year 2016;
                    position: relative;
                    margin-top: 27px;
                    p {
                        font-weight: 400;
                        font-size: 8px;
                        padding: 0px 15px 0px;
                        line-height: 11px;
                        display: flex;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #1c1c1c;
                        margin-top: -14px;
                    }
                }

                .timeline li {
                    list-style: none;
                    float: left;

                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Dosis", sans-serif;
                }

                //   ul:nth-child(1){
                //     color: #4caf50;
                //   }
                .timeline-icon {
                    margin-top: -30px;
                }
                .timeline-top {
                    width: 35px;
                    height: 35px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: #5f6168;
                    background: #f0f4f8!important;
                    box-shadow: inset -3px -3px 10px #ffffff, inset 2px 3px 5px #bfbfbf;
                    top: -27px;
                    left: 18px;
                }
                .timeline li {
                    z-index: 2;
                    position: relative;

                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 50px;
                    margin: 0 auto 10px auto;
                    width: 70px;
                    height: 70px;

                    background: #f0f4f8;
                    color: #5f6168;
                    font-size: 15px;
                    margin-right: 30px;

                    transition: all ease-in-out 0.3s;
                    cursor: pointer;
                    @media screen and (max-width: 1400px) {
                        margin-right: 15px !important;
                    }
                    img {
                        width: 15px;
                    }
                    .message {
                        width: 20px !important;
                    }
                    .load {
                        width: 21px !important;
                    }
                    .answer {
                        width: 28px;
                    }
                }

                .timeline:after {
                    content: "";
                    position: absolute;
                    width: 76%;
                    height: 12px;
                    /* Neo/Light/Inner/6 */

                    box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9);
                    background-color: #304053;
                    top: 34px;
                    left: 54px;
                    z-index: 1;
                    transition: all ease-in-out 0.3s;
                    margin: auto;
                }
                .timeline li.active {
                    color: #555555;
                }

                .timeline li.active + li:after {
                    background: #4caf50 !important;
                }
            }
        }
        .navbar-bars {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 30px;

            margin: 10px;
            width: 90px;
            height: 90px;
            background: #f0f4f8;
            /* Neo/Light/Outer/3 */

            box-shadow: -4px -4px 10px #ffffff, 5px 5px 10px rgba(178, 189, 203, 0.8);
            border-radius: 5px;
            color: #202020;
        }

        .text-hide {
            color: transparent;
        }
        .main-content {
            .hero-sidebar {
                background: #f0f4f8;
                /* Neo/Light/Outer/4 */
                z-index: 1;
                position: relative;
                box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                width: 9%;
                height: 100%;
                padding: 15px;
                .fa-magnify{
                    background: #f0f4f8;
                    box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                    padding: 12px!important;

                }
                .mode-set {
                    position: relative;

                    top: -37px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #202020;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                .social-set {
                    position: relative;

                    top: 39px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #202020;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 11px;
                    text-align: center;
                    color: #202020;
                }
                .bottom-tabs {
                    color: #394649;
                    margin-top: 50px;
                    h6 {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 120%;
                        color: #192529;
                    }
                }

                .name-btn {
                    background: #f0f4f8;

                    border: 1.14031px solid #f0f4f8;

                    box-shadow: inset -4.80637px -4.80637px 7.69018px #ffffff,
                        inset 3.84509px 3.84509px 7.69018px #889fb6;
                    border-radius: 4px;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 9px;
                    line-height: 100%;
                    padding: 8px 10px;
                    margin-bottom: 10px;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #202020;
                }
                .info-icon {
                    border: 3px solid #394649;
                    padding: 5px 11px 8px 14px;
                    font-size: 19px;
                    margin: 20px 0px;
                    color: #394649;

                    border-radius: 50px;
                }
                .fa-magnify {
                    font-size: 35px;
                    margin: 10px 0px 55px;
                    color: #394649;
                }
            }
            .content-hero {
                width: 92%;
                .content-text {
                    background: #f0f4f8;
                }
            }
        }
        .content-text {
            position: relative;
            .nav-item .active {
                background: linear-gradient(#f0f4f8, #f0f4f8) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                border: 3px solid transparent !important;
                box-shadow: none;
            }
            .nav-pills {
                justify-content: center;
            }
            .tab-content {
                background: #f0f4f8;
                box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
                border: 1px solid #facb30;
                height: 440px!important;
            }
            .nav-item {
                background: #f0f4f8;
                /* Neo/Light/Inner/4 */

                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 5px;
                padding: 15px;
                z-index: 2;
            }
            .mue-shape{
                display: block;
             
            }
        
            .dots{
                display: none;
            }
            .dots {
                position: relative;
                top: -88px;
                left: 44px;
            }
            .tab-right-section {
                width: 15%;
            }
            .nav-item .active {
                background: #202020;
                color: white;
                box-shadow: none;
            }

            .tab-section {
                width: 55%;
                margin: auto;
                margin-top: -105px !important;
            }
            .tab-himself-text {
                margin-top: 25px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                position: relative;

                border-radius: 8px;
                p {
                    overflow: auto;
                    padding-right: 10px;
                    height: 190px;
                    ::-webkit-scrollbar {
                        width: 10px;
                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                        background: #888;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                }
            }
            .playlist-section {
                position: absolute;
                left: 55px;
                z-index: 1;
                top: 5%;
                h4 {
                    margin-bottom: 40px;
                    width: 130px;
                    position: relative;
                    right: -69px !important;
                }

                /* Grey/8
    
    Base color for Light mode
    */
            }
            .playlist-section-text {
                position: absolute;
                background: #F0F4F8;
                box-shadow: inset -1.06667px -1.06667px 3.2px #FFFFFF, inset 1.06667px 1.06667px 3.2px #B2BDCB;
                border-radius: 8px;
                width: 100%;

                padding: 20px;
                margin-top: 23px;
                right: -159px;
                width: 336px;

                /* Grey/8
    
    Base color for Light mode
    */
                background: #f0f4f8;
                /* Neo/Light/Inner/4 */

                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 8px;
                .slidecontainer {
                    width: revert;
                    margin-right: -8px;
                }
                @media screen and (max-width: 1580px) {
                    right: -159px;
                    width: 336px;
                }
                input[type="range"].range-slider {
                    width: 68px;

                    margin-right: 20px;
                }
                .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                    height: 20px !important;
                    width: 30px !important;
                    bottom: -24px !important;
                    border-radius: 50% !important;

                    left: -10px;

                    display: flex !important;
                    color: #202020;
                    justify-content: center !important;
                    align-items: center !important;
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);

                }
                .range-slider__wrap
                    .range-slider__tooltip.range-slider__tooltip--bottom
                    .range-slider__tooltip__caret::before {
                    border-bottom-color: #FACB30 !important;
                    left: 13px;

                }
                .next-icon {
                    background: #f0f4f8;
                    box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                    border-radius: 23.0737px;
                    width: 35px;
                    text-align: center;
                    color: #202020!important;
                    font-size: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                }
                .setting-icons {
                    background: #f0f4f8;
                    box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                    margin-left: 20px;
                    border-radius: 24.0118px;
                    padding: 1px;
                    color: #202020;
                    font-size: 15px;
                    display: flex;
                    .icons-set {
                        margin: 5px 9px;
                    }
                }
                .side-text {
                    /* Grey/9 */
                    background: #F0F4F8;
                    box-shadow: 1.75824px 1.75824px 3.51648px #B7C7D7, -1.75824px -1.75824px 3.51648px #FFFFFF;
                    border-radius: 2.38694px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 11.139px 6.36517px 3.97823px;
                    font-weight: 500;
                    font-size: 6.40381px;
                    line-height: 100%;
                    margin: 0px 10px;
                    /* or 5px */
                    border: 2px solid #dda01a;

                    text-align: center;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                    color: #1c1c1c;
                }
                .text-gree {
                    display: flex;
                    position: relative;
                    top: 4px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 3.27619px 5.2419px;
                    width: 105.48px;
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    font-size: 8px;
                    height: 10.55px;

                    background: #e5e9ed;
                    border-radius: 1.96571px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 4.44588px;
                    line-height: 100%;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #101010;
                }
                .next-icon-center {
                    background: #f0f4f8;
                    box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                    border-radius: 50.7836px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: -4px 2px 0px;
                    .iconss {
                        font-size: 10px;
                        color: #c49530;
                        margin: 8px;
                    }
                }

                .video-icon {
                    font-size: 20px;
                    background: #f0f4f8;
                    box-shadow: 1.17633px 1.17633px 1.7645px #7b858f, -1.17633px -1.17633px 1.56844px #ffffff;
                    border-radius: 50%;
                    color: #c49530;
                    width: 22.64px;
                    height: 22.64px;
                    margin: 0px 4px 0px 3px;
                    padding: 4px 1px;
                    svg {
                        margin-top: -13px;
                    }
                }
                .mic-img {
                    padding: 0px 20px;
                    img {
                        width: 90px;
                    }
                }
            }
            .btn-video {
                position: absolute;
                right: 19%;
                top: 36px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 17px 25px;
                width: 114px;
                height: 45px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #202020;

                /* Others/1 */
                background: #f0f4f8;
                box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                border-radius: 4px;
            }
            .video-section {
                width: 23%;
                position: absolute;
                background: #f0f4f8;
                /* Neo/Light/Inner/4 */

                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 8px;
                right: 80px;
                height: 50%;
                bottom: 27%;
                display: flex;
                justify-content: center;
                align-items: center;
                .video-img {
                    background-image: url(../../../../../images/video-img.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 152.63px;
                    margin: 28px;
                    width: 230px;
                    height: 155.63px;
                    border-radius: 10.5263px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: revert;
                }
            }
        }
        .design-about {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 15px 18px;
            width: 90px;
            height: 110px;
            position: relative;
            z-index: 1;
            background: #f0f4f8 !important;
            /* Neo/Light/Outer/4 */

            border: 4px solid transparent;
            box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb !important;
            border-radius: 4px;

            border-radius: 4px;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            text-align: center;

            /* Grey/2 */

            color: #202020 !important;
        }
        .content-img {
            position: relative;
            width: 100%;
            height: 100% !important;
        }
        .hero-section {
            position: relative;
            background: #f0f4f8;
            padding: 20px;
            margin: 30px 80px;
            box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border-radius: 20px;
        }
        .hero-part {
            position: absolute;
            top: 41px;
    left: 107px;
            h1 {
                font-weight: 700;
                font-size: 68px;
                line-height: 90px;
                color: #ffffff;

                text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            }
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;

                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
            }
        }
        @media screen and (min-width: 1500px) {
            .hero-part {
                position: absolute;
                top: 41px;
    left: 107px;
                h1 {
                    font-weight: 700;
                    font-size: 68px;
                    line-height: 90px;
                    color: #ffffff;

                    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
                }
                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;

                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
                }
            }
        }
        .content-hero {
            width: 92%;
        }
    }

   
    .design-screen-mobile {
        background-color: #cbd5e0;
        width: 400px;
        margin: auto;
        .navbar-top {
            background: #cbd5e0;
            height: 160px;
            padding: 24px !important;
            .testimonial-seeall {
                display: flex;
                align-items: center;
                h5 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 159.5%;

                    text-align: center;
                    letter-spacing: 0.01em;
                    text-decoration-line: underline;
                    color: #202020;
                    padding-right: 10px;
                    margin-top: 5px;
                }
                img {
                    width: 20px;
                }
            }
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: #f0f4f8!important;
                    box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;

                    width: 87px;
                    padding: 8px;
                    height: 87px;
                    top: 97px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #7cbaa7;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 130px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }
            .profile-img {
                width: 50px;
                margin-left: 20px;
                margin-top: -113px;
            }
            .icons-circle {
                width: 90px;
                margin-top: 18px;
            }
            .img-logo {
                margin-left: 20px;
                width: 110px;
            }
            .timeline {
                counter-reset: year 2016;
                position: relative;
                margin-top: 27px;
                p {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 11px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -3px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-top {
                width: 35px;
                height: 35px;
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #f0f4f8;
                background: #5f6168;
                top: -23px;
                left: 24px;
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;
                width: 70px;
                height: 70px;

                background: #f0f4f8;
                color: #5f6168;
                font-size: 15px;
                margin-right: 47px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                img {
                    width: 15px;
                }
                .message {
                    width: 15px !important;
                }
                .load {
                    width: 18px !important;
                }
                .answer {
                    width: 20px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;

                width: 58%;
                height: 12px;
                border: 3px solid #7cbaa7;
                background-color: #304053;
                top: 34px;
                left: 72px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
        }
        @media screen and (max-width: 1550px) {
            .navbar-top {
                background: #cbd5e0;
                height: 160px;
                padding: 24px !important;
                .testimonial {
                    margin-top: -80px;
                    .timeline-top {
                        flex-direction: column;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        position: relative;
                        color: black;
                        background: #f0f4f8!important;
                        box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;

                        width: 87px;
                        padding: 8px;
                        height: 87px;
                        top: 97px;
                        left: 23px;
                        p {
                            margin-top: 4px;
                            line-height: 10px;
                        }
                        .icon-green {
                            font-size: 8px;
                            color: #7cbaa7;
                            padding-top: 3px;
                        }
                        p {
                            font-size: 8px;
                        }
                    }

                    img {
                        width: 130px;
                        margin: 0px 13px;
                        position: relative;
                        z-index: 2;
                    }
                }
                .profile-img {
                    width: 74px;
                    margin-left: 26px;
                    margin-top: -147px;
                }
                .icons-circle {
                    width: 125px;
                    margin-top: 18px;
                }
                .img-logo {
                    margin-left: 11px;
                    width: 88px;
                }
                .timeline {
                    counter-reset: year 2016;
                    position: relative;
                    margin-top: 27px;
                    p {
                        font-weight: 400;
                        font-size: 8px;
                        padding: 0px 15px 0px;
                        line-height: 11px;
                        display: flex;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #1c1c1c;
                        margin-top: -14px;
                    }
                }

                .timeline li {
                    list-style: none;
                    float: left;

                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Dosis", sans-serif;
                }

                //   ul:nth-child(1){
                //     color: #4caf50;
                //   }
                .timeline-icon {
                    margin-top: -30px;
                }
                .timeline-top {
                    width: 35px;
                    height: 35px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: #f0f4f8!important;
                    background: #5f6168;
                    top: -23px;
                    left: 24px;
                }
                .timeline li {
                    z-index: 2;
                    position: relative;

                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 50px;
                    margin: 0 auto 10px auto;
                    width: 70px;
                    height: 70px;

                    background: #f0f4f8;
                    color: #5f6168;
                    font-size: 15px;
                    margin-right: 30px;

                    transition: all ease-in-out 0.3s;
                    cursor: pointer;
                    img {
                        width: 20px;
                    }
                    .message {
                        width: 25px !important;
                    }
                    .load {
                        width: 28px !important;
                    }
                    .answer {
                        width: 32px;
                    }
                }

                .timeline:after {
                    content: "";
                    position: absolute;
                    width: 67%;
                    height: 12px;
                    border: 3px solid #7cbaa7;
                    background-color: #304053;
                    top: 34px;
                    left: 54px;
                    z-index: 1;
                    transition: all ease-in-out 0.3s;
                    margin: auto;
                }
                .timeline li.active {
                    color: #555555;
                }

                .timeline li.active + li:after {
                    background: #4caf50 !important;
                }
            }
        }
        .navbar-bars {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 30px;

            width: 90px;
            height: 160px;
            color: white;
            background: #202020;
        }

        .text-hide {
            color: transparent;
        }
        .main-content {
            .hero-sidebar {
                background: #cbd5e0;
                width: 8%;
                height: 100%;
                padding: 15px;

                .switch-outer {
                    width: 182px;
                    left: 22px;
                    height: 93px;
                    background-color: white;
                    position: relative;
                    border-radius: 49.5px;
                    z-index: 1;
                    .switch {
                        display: inline-block;
                        position: relative;
                        width: 130px;
                        height: 69.64px;
                        left: -16px;
                        top: 10px;
                        border-radius: 49.5px;
                        z-index: 2;
                        background: #7cbaa7;
                        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .switch::before {
                        content: "";
                        position: absolute;
                        top: 9px;
                        left: 68px;
                        width: 49.6px;
                        height: 49.6px;
                        background: white;
                        border-radius: 50%;
                        transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                            background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    }
                    .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                    }
                    input:checked + .switch {
                        background: #7cbaa7;
                    }
                    input:checked + .switch::before {
                        left: 11px;
                        background: #fff;
                    }
                    input:checked + .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                    }
                }
                .mode-set {
                    position: relative;

                    top: -37px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                .social-set {
                    position: relative;

                    top: 39px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 11px;
                    text-align: center;
                    color: #202020;
                }
                .bottom-tabs {
                    color: #394649;
                    margin-top: 50px;
                    h6 {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 120%;
                        color: #192529;
                    }
                }

                .name-btn {
                    background: #f0f4f8;

                    border: 1.14031px solid #f0f4f8;

                    box-shadow: inset -4.80637px -4.80637px 7.69018px #ffffff,
                        inset 3.84509px 3.84509px 7.69018px #889fb6;
                    border-radius: 4px;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 9px;
                    line-height: 100%;
                    padding: 8px 10px;
                    margin-bottom: 10px;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #202020;
                }
                .info-icon {
                    border: 3px solid #394649;
                    padding: 5px 11px 8px 14px;
                    font-size: 19px;
                    margin: 20px 0px;
                    color: #394649;

                    border-radius: 50px;
                }
                .fa-magnify {
                    font-size: 35px;
                    margin: 10px 0px 55px;
                    color: #394649;
                }
            }
            .content-hero {
                width: 92%;
                .content-text {
                    background: #cbd5e0;
                }
            }
        }
        .content-text {
            position: relative;
            .nav-item .active {
                background: linear-gradient(#f0f4f8, #f0f4f8) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                border: 3px solid transparent !important;
                box-shadow: none;
            }
            .dots {
                position: relative;
                top: -88px;
                left: 44px;
            }
            .tab-right-section {
                width: 15%;
            }
            .nav-item .active {
                background: #202020;
                color: white;
                box-shadow: none;
            }

            .tab-section {
                width: 55%;
                margin: auto;
                margin-top: -105px !important;
            }
            .tab-himself-text {
                margin-top: -75px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 70px 100px 20px;

                position: relative;

                height: 364px;
                background: #7cbaa7;
                border-radius: 8px;
                p {
                    overflow: auto;
                    margin-bottom: 20px;
                    padding-right: 10px;
                    height: 190px;
                    ::-webkit-scrollbar {
                        width: 10px;
                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                        background: #888;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                }
            }
            .playlist-section {
                position: absolute;
                left: 55px;
                z-index: 1;
                top: 5%;
                h4 {
                    margin-bottom: 40px;
                    width: 130px;
                    position: relative;
                    right: -99px !important;
                }

                /* Grey/8
    
    Base color for Light mode
    */
            }
            .playlist-section-text {
                position: absolute;
                background: #f0f4f8;
                box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
                border-radius: 8px;
                width: 100%;

                padding: 20px;
                margin-top: -20px;
                right: -218px;
                width: 355px;

                /* Grey/8
    
    Base color for Light mode
    */
                background: #f0f4f8;
                box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
                border-radius: 8px;
                @media screen and (max-width: 1580px) {
                    right: -193px;
                    width: 350px;
                }
                input[type="range"].range-slider {
                    width: 97px;
                    margin-right: 20px;
                }
                .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                    height: 30px !important;
                    width: 40px !important;
                    bottom: 1px !important;
                    border-radius: 50% !important;
                    display: flex !important;
                    color: #202020;
                    justify-content: center !important;
                    align-items: center !important;
                    background-color: #3860ee !important;
                }
                .range-slider__wrap
                    .range-slider__tooltip.range-slider__tooltip--bottom
                    .range-slider__tooltip__caret::before {
                    border-bottom-color: #3860ee !important;
                }
                .next-icon {
                    background: #202020;
                    border-radius: 23.0737px;
                    width: 35px;
                    text-align: center;
                    color: white;
                    font-size: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                }
                .setting-icons {
                    background: #202020;
                    margin-left: 20px;
                    border-radius: 24.0118px;
                    padding: 1px;
                    color: white;
                    font-size: 15px;
                    display: flex;
                    .icons-set {
                        margin: 5px 9px;
                    }
                }
                .side-text {
                    /* Grey/9 */

                    background: #ffffff;
                    border-radius: 2.38694px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 11.139px 6.36517px 3.97823px;
                    font-weight: 500;
                    font-size: 8.40381px;
                    line-height: 100%;
                    margin: 0px 10px;
                    /* or 5px */
                    border: 2px solid #dda01a;

                    text-align: center;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                    color: #1c1c1c;
                }
                .text-gree {
                    display: flex;
                    position: relative;
                    top: 4px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 3.27619px 5.2419px;
                    width: 105.48px;
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    font-size: 8px;
                    height: 10.55px;
                    background: #F0F4F8;
                    box-shadow: inset 0px -1.75824px 3.51648px #FFFFFF, inset 0px 1.75824px 2.63736px #A0B9D0;
                    border-radius: 1.08597px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 4.44588px;
                    line-height: 100%;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #101010;
                }
                .next-icon-center {
                    background: #202020;
                    border-radius: 50.7836px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: -4px 2px 0px;
                    .iconss {
                        font-size: 10px;

                        margin: 8px;
                        color: white;
                        margin: 8px;
                    }
                }

                .video-icon {
                    font-size: 45px;
                    color: #202020;
                    margin: 0px 3px;
                    svg {
                        margin-top: -13px;
                    }
                }
                .mic-img {
                    padding: 0px 20px;
                    img {
                        width: 90px;
                    }
                }
            }
            .btn-video {
                position: absolute;
                right: 19%;
                top: 36px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 17px 25px;
                width: 114px;
                height: 45px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #ffffff;

                /* Others/1 */

                background: #304053;
                border-radius: 4px;
            }
            .video-section {
                width: 23%;
                position: absolute;
                background: #f0f4f8;
                box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
                border-radius: 8px;
                right: 80px;
                height: 50%;
                bottom: 27%;
                display: flex;
                justify-content: center;
                align-items: center;
                .video-img {
                    background-image: url(../../../../../images/video-img.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 152.63px;
                    margin: 28px;
                    width: 230px;
                    height: 155.63px;
                    border-radius: 10.5263px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .design-about {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 15px 18px;
            width: 90px;
            height: 110px;
            position: relative;
            z-index: 1;
            background: #f0f4f8;
            /* Neo/Light/Outer/4 */

            box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
            border-radius: 4px;
            border-radius: 4px;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            text-align: center;

            color: #202020;
            text-align: center;
        }
        .content-img {
            position: relative;
            width: 100%;
        }
        .hero-section {
            position: relative;
        }
        .hero-part {
            position: absolute;
            top: 80px;
            left: 80px;
            h1 {
                font-weight: 700;
                font-size: 68px;
                line-height: 90px;
                color: #ffffff;

                text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            }
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;

                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
            }
        }
        @media screen and (min-width: 1500px) {
            .hero-part {
                position: absolute;
                top: 80px;
                left: 80px;
                h1 {
                    font-weight: 700;
                    font-size: 68px;
                    line-height: 90px;
                    color: #ffffff;

                    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
                }
                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;

                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
                }
            }
        }
        .content-hero {
            width: 92%;
        }
    }
    .img-logo-oter {
        background: #f0f4f8;
        /* Neo/Light/Inner/3 */

        box-shadow: inset -4px -4px 10px #ffffff, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
        border-radius: 15px;
        padding: 10px;
        margin-left: 10px;
        .img-logo {
            border-radius: 15px;
            margin-left: 0px !important;
        }
    }

}




.modal-setting {
    .modal .modal-dialog {
        max-width: 99% !important;
        top: 130px;
        margin: auto;
    }
    @media screen and (min-width: 1920px) {
        .modal .modal-dialog {
            top: 130px;
        }
    }
    @media screen and (min-width: 576px) {
        .modal .modal-dialog {
            max-width: 99% !important;
        }
    }
    @media screen and (min-width: 576px) {
        .modal .modal-dialog {
            max-width: 99% !important;
        }
    }
    .modal-90w {
        width: 90% !important;
    }

    .modal-content {
        background: linear-gradient(151.05deg, #ebf2f7 13.27%, #f2f6f9 83.27%) !important;
        box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8),
            2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4) !important;
        border: 1px solid transparent !important;
        border-radius: 2.84939px;
    }
    .modal-header {
        border-bottom: none;
    }
    .screen-btn {
        background: linear-gradient(49.05deg, #f0bf25 -0.22%, #e28d0e 101.07%);
        box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
        border-radius: 25px;
        padding: 15px;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #202020;
        width: 143px;
        margin: 30px auto;
        display: flex;
        justify-content: center;
        border: none;
    }
}

// @media screen and  (min-width: 576px){
//     .modal-dialog {
//         max-width: 100%!important;
//         margin-right: auto;
//         margin-left: auto;
//     }
// }
@media (min-width: 576px) {
    .modal-dialog.modal-90w {
        max-width: 100% !important;
    }
}
.banner-btn {
    .btn-left {
        button {
            top: 12px;
            transform: rotate(7deg);
        }
    }
    .btn-bg {
        background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%) !important;
        box-shadow: inset -4.55556px -4.55556px 7.59259px rgba(255, 255, 255, 0.75),
            inset 3.03704px 3.03704px 7.59259px rgba(166, 180, 200, 0.4) !important;
        border-radius: 3px;
        border: 1px solid #f2e6cf;
        button {
            background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
            border-radius: 5px;
            color: #202020 !important;
        }
    }
    .ml-3 {
        margin-left: 40px;
    }
}
.modal-dialog.modal-90w {
    width: 99%;
    top: 130px;
    margin: auto;
    .modal-content {
        background: linear-gradient(151.05deg, #ebf2f7 13.27%, #f2f6f9 83.27%) !important;
        box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8),
            2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4) !important;
        border: 1px solid transparent !important;
        border-radius: 2.84939px;
        .modal-header {
            border-bottom: none;
        }
    }
}
.screen-btn {
    cursor: pointer;
}
@media screen and (max-width: 1580px){
    .Neumorphic-design .design-screen-desktop-1 {
        .content-text .playlist-section-text {
            right: -132px;
            width: 314px;
        }
        .gold-btn{
            left: -104px;
        }
        .playlist-section h4{
            margin-bottom: 52px!important;
            width: 130px;
            font-size: 19px;
            position: relative;
            right: -39px!important;
        }
        .active-oter {
            width: 85px;
            height: 85px;
            margin-right: 20px!important;
        }

    }
   
    

}