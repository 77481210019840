@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');


.featured-on-neumorphic-mobile-3 {


    .Feature-on-mobile-3 {

        .features-on-design-mobile-3{
            background-color: #F0F4F8;
            width: 400px;
            margin: auto;
            position: relative;
          height: 650px;
        
          }

        .grey-box-mobile-3 {
            background-color: #F0F4F8;
        }

        .image-circle-mobile-3 {
            background-color: #F0F4F8;

            margin: 0px 2px ;

            border-radius: 50%;
            display: flex;

            align-items: center;
            justify-content: center;
            filter: drop-shadow(2px 2px 8px #B2BDCB) drop-shadow(-2px -2px 8px #FFF);
            z-index: 90;
        }

        .image-usa {
            background-color: #1EA3FF;

        }


        .green-box-mobile-3 {
            border-radius: 5px;
            background: var(--grey-8, #F0F4F8);
            /* Neo/Light/Outer/3 */
            box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
            padding: 50px 40px 50px 40px;
            width: 100%;
        }

        // .box-container-3{
        //   margin-top: -53px;
        // }
    }
}