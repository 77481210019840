@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.diet-programs-main-mobile {
  .diet-programs-right-section {
    width: 100%;
    margin-top: 10px;
    .training-guide-steps {
      padding: 0px;
      display: flex;
      background-color: #fff;
      height: 85px;
      margin-bottom: 2px;
      .consultaion-step {
        height: 85px;
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #7cbaa7;
        span {
          font-family: Inter;
          font-size: 20px;
          font-weight: 500;
          line-height: 36px;
          letter-spacing: 0em;
        }
      }
      .consultaion-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
        span {
          font-family: Inter;
          font-size: 13px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          color: #202020;
        }
        p {
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          color: #3c3d40;
          margin-bottom: 0;
          margin-top: 5px;
        }
      }
    }
  }
}




.main-row-mobile {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 10px;
  height: fit-content;
}
.counter-div-mobile {
  background-image: url("../../../../../../../../images/how-it-work/bar_two.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  z-index: 0;
  position: absolute;
  left: 8%;
  font-size: 30px;
  font-weight: bold;
  width: 90px;
  height: 74px;
}
.description-div-mobile {
  background-image: url("../../../../../../../../images/how-it-work/bar_three.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px 0 10px 25px;
  z-index: 1;
  margin-left: 25px;
  width: 340px;
}
.counter-div-mobile span {
  font-weight: bold;
  font-size: 30px;
  position: absolute;
  left: 44%;
  top: 24%;
}
.description-div-mobile span {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  color: #202020;
}
.description-div-mobile p {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #3c3d40;
  margin-bottom: 0;
  margin-top: 5px;
}
