@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

.Neumorphic-design {
    .hero-section-reg {
        display: none;
    }
    .glass-btn   {
        display: none;
    }
    .meu-timeline{
        display: block;
    }
    .fa-magnify{
        box-shadow: none;
        background: none;
        font-size: 30px!important;
     
        margin-top: 54px!important;
        padding: 0px!important;
    color: #394649;
  
        border-radius: 30px;
     
    }
    .meu-social{
        display: block;
    }
    .reg-social {
        display: none !important;
    }
    .bars-seeall .hamburger-outer .top-padding li {
        padding: 19px 8px;
    }
    .testimonial-p {
        background: #f0f4f8;
        box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #202020 !important;
        padding: 8px;
        margin-left: 18px;
        border-radius: 10px;
        width: 127px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn-video {
        display: none !important;
    }
    .switch-mobile {
        display: block;
        position: absolute;
        .mode-set {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            z-index: 1;
            bottom: -168px;
            position: absolute;
            transform: rotate(-90deg);
            z-index: 1;
        }
        .social-set {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 12px;
            color: #f0f4f8;
            z-index: 1;
            bottom: -289px;
            position: absolute;
            transform: rotate(-90deg);
        }
        .switch-border {

            width: 75px;
            height: 40.85px;
            background: #f0f4f8 !important;
            /* Neo/Light/Inner/5 */

            box-shadow: inset -1px -1px 2px #ffffff, inset 1px 1px 2px rgba(178, 189, 203, 0.9);
            left: -10px;
            top: 246px;
            position: relative;
            border-radius: 49.5px;
            z-index: 31;
            .check-box {
                transform: scale(2);
            }

            input[type="checkbox"] {
                position: relative;
                appearance: none;
                width: 100px;
                height: 50px;
                background: #ccc;
                border-radius: 50px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                transition: 0.4s;
            }

            input:checked[type="checkbox"] {
                background: #7da6ff;
            }

            input[type="checkbox"]::after {
                position: absolute;
                content: "";
                width: 22px;
                height: 22px;
                left: 50%;
                top: 4px;
                background: #fff;
                border-radius: 50%;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                transform: scale(1.1);
                transition: 0.4s;
            }

            input:checked[type="checkbox"]::after {
                left: 8px;
            }

            check-box {
                display: inline-block;
                position: relative;
                width: 60px;
                height: 30.64px;
                left: 7px;
                top: 5px;
                box-shadow: none !important;
                border-radius: 49.5px;
                z-index: 2;
                background: #7cbaa7;
                transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                vertical-align: middle;
                cursor: pointer;
            }
            input[type="checkbox"] {
                display: inline-block;
                position: relative;
                width: 60px;
                height: 30.64px;
                left: 7px;
                top: 5px;
                box-shadow: none !important;
                border-radius: 49.5px;
                z-index: 2;
                background: #7cbaa7;
                transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                vertical-align: middle;
                cursor: pointer;
            }

            input[type="checkbox"]::before {
                content: "";

                border-radius: 50%;

                position: absolute;
                top: 0;
                left: 0;
                transition: 0.5s;
            }

            input[type="checkbox"]:checked::before {
                transform: translateX(100%);
                background: #fff;
            }

            input[type="checkbox"]:checked {
                display: inline-block;
                position: relative;
                width: 60px;
                height: 30.64px;
                left: 7px;
                top: 5px;
                box-shadow: none !important;
                border-radius: 49.5px;
                z-index: 2;
                background: #7cbaa7;
                transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }
    .mue-plist {
        display: block;
    }
    .reg-timeline {
        display: none !important;
    }
    .border-b {
        border-bottom: 1px solid transparent !important;
    }
    .meu-timeline {
        display: block !important;
        .timeline {
            margin-left: 33px;
        }
    }
    .mue-border {
        width: 100%;
        height: 2px;
        background: #f0f4f8;
        box-shadow: -1px -1px 1px #ffffff, 1px 1px 1px rgba(178, 189, 203, 0.7);
        margin: 5px 3px;
    }

    .reg-img {
        display: none;
    }
    .meu-img {
        display: block;
    }
    .reg-social {
        display: none;
    }
    .meu-social {
        display: block;
        li {
            background: #f0f4f8;
            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 30px;
            padding: 10px;
            width: 35px;
            height: 35px;
            text-align: center;
            padding: 9px 9px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px auto;
        }
    }

    .reg-icon {
        display: none !important;
    }

    .mue-shape-last {
        display: block;
        img {
            position: absolute;
            z-index: 0;
            bottom: 91px;
            right: 0;
        }
    }

    .meu-icon {
        display: block !important;

        border-radius: 11.7633px;

        font-size: 14px !important;

        border-radius: 50%;
        color: #c49530 !important;

        svg {
            margin-top: -22px;
        }
    }

    .design-screen-mobile-1 {
        background: #f0f4f8;
        width: 400px;
        margin: auto;
        position: relative;
        .bookme-oter .bookme-inner .hamburger-outer .forms-inpt {
      
            padding: 3px 15px!important;
        }
    .search-me-btn{
        display: none!important;
    }
        .mue-shape img {
            position: absolute;
            left: 130px;
            z-index: 0;
            top: 105px;
        }
        .m-section .hero-section {
            position: relative;
            background: #f0f4f8;
padding: 0px!important ;

margin: 0px!important;
        }
       .m-section .hero-section-mue .content-img {
            width: 100% !important;
            right: 0%!important;
            height: 100%!important;
            padding: 15px!important;
        }
        .bars-hambrger .hamburger-outer .testimonial .timeline-top {
            flex-direction: column;
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            color: black;
            background: #f0f4f8 !important;
            box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
            overflow: hidden;
            width: 81px;
            padding: 8px;
            height: 81px;
            top: 88px;
            left: 18px;
            p {
                font-size: 6px;
            }
        }
        .hamburger-outer {
            position: absolute;
            z-index: 3;
            padding: 40px 20px 40px;
            width: 400px;
            right: 0;
            top: 0;
            background: #f0f4f8;
            box-shadow: -4px -4px 10px #ffffff, 5px 5px 10px rgba(178, 189, 203, 0.8);
            border-radius: 10px;
            .close-btn {
                height: 67px;
                font-size: 23px;
                position: absolute;
                right: 14px;
                top: 1px;
            }
            .sidebar-li {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                background: #f0f4f8;
                background: #f0f4f8;
                box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;

                text-align: center;
                border-bottom: 1px solid transparent;
                padding: 20px;
                color: #f0f4f8;
                color: #202020;
                margin: 16px;
            }
            .switch-outer {
                display: none;
            }
        }
        .searchbox {
            .search-area {
                .search-field {
                    margin-top: 50px !important;
                    background: #f0f4f8 !important;
                    box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb !important;
                    border-radius: 40px;
                    border: 1px solid transparent !important;
                    width: 100%;
                    margin: 0px 10px 0px;
                    height: 44px !important;
                    box-shadow: none;
                    padding: 2px 11px;

                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #394649;
                }
            }

            .close-btn {
                border: none;
                background-image: url(../../../../../images/close-batch.png);
                background-repeat: no-repeat;
                top: 2px;
                height: 68px;
                margin-top: -11px;
                top: 3px;
                right: 9px;
                font-size: 34px;
                color: grey;
                position: absolute;
                svg {
                    margin-top: -34px;
                    margin-left: 10px;
                    font-size: 28px;
                }
            }
        }
        .meu-playlist-section {
            background: #f0f4f8;
            box-shadow: 1.75824px 1.75824px 3.51648px #b7c7d7, -1.75824px -1.75824px 3.51648px #ffffff;
            border-radius: 3.42107px;
            padding: 8px;
            margin-bottom: 10px;
        }
        .gold-btn {
            background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border-radius: 30px;
            padding: 20px;
            width: 70%;
            display: block;
            text-align: center;
            margin-bottom: 63px;
            margin-top: -27px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #202020;
        }
        .reg-plist {
            display: none;
        }
        .mue-video-section {
            display: block;
            width: 50%;
            padding: 10px !important;
            margin: auto;
            position: relative;
            margin-top: 20px;
            background: #f0f4f8;
            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 8px;
            right: -61px;
            height: 100%;
            top: -53px;
            justify-content: center;
            align-items: center;

            .video-img {
                background-image: url(../../../../../images/video-img.png);
                background-repeat: no-repeat;
                background-position: center;

                width: 180px;
                height: 118.63px;
                border-radius: 10.5263px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .meu-show {
            display: block;
            text-align: right;
            width: 100%;
            z-index: 3;
            position: relative;
        }
        .margin-left-btn {
            margin-left: 9px;
        }
        .bookme-oter {
            .bookme-inner {
                background: #f0f4f8;
                z-index: 3;
                padding: 20px;
                width: 400px;
                position: absolute;
                box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
                right: 0;
                top: 974px;
                z-index: 4;

                .close-btn {
                    border: none;
                    background-image: url(../../../../../images/close-batch.png);
                    background-repeat: no-repeat;
                    top: 2px;
                    height: 68px;
                    margin-top: -10px;
                    top: 3px;
                    right: 9px;
                    font-size: 34px;
                    color: grey;
                    position: absolute;
                    svg {
                        margin-top: -34px;
                        margin-left: 10px;
                        font-size: 28px;
                    }
                }
                .forms-inpt {
                    background: #f0f4f8;
                    border-radius: 10px;
                    padding: 38px 15px;
                    margin-top: 40px;
                    .form-control {
                        width: 100%;
                        margin: 0px 3px;
                        height: 100% !important;
                        padding: 13px 30px;
                        background: #f0f4f8;
                        border: 1px solid transparent;
                        box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                        border-radius: 4px;

                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;

                        color: #808492;
                    }
                }

                .mr-0 {
                    margin-right: 0px !important;
                }
                .meu-timeline {
                    display: none;
                }
                .timeline {
                    counter-reset: year 2016;
                    position: relative;
                    margin-top: 73px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p {
                        font-weight: 400;
                        font-size: 8px;
                        line-height: 12px;
                        /* identical to box height */

                        display: flex;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #1c1c1c;
                        margin-top: -3px;
                    }
                }

                .timeline li {
                    list-style: none;
                    float: left;

                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Dosis", sans-serif;
                }

                //   ul:nth-child(1){
                //     color: #4caf50;
                //   }
                .timeline-icon {
                    margin-top: -30px;
                }
                .timeline-top {
                    width: 30px;
                    height: 30px;
                    font-weight: 500;
                    font-size: 8px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: #f0f4f8 !important;
                    box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
                    top: -22px;
                    left: 24px;
                }
                .timeline li {
                    z-index: 2;
                    position: relative;

                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 46px;
                    margin: 0 auto 10px auto;

                    background: #f0f4f8;
                    color: #5f6168;
                    font-size: 15px;
                    margin-right: 15px;

                    transition: all ease-in-out 0.3s;
                    cursor: pointer;
                    img {
                        width: 20px;
                    }
                    .message {
                        width: 25px !important;
                    }
                    .load {
                        width: 28px !important;
                    }
                    .answer {
                        width: 32px;
                    }
                }

                .timeline:after {
                    content: "";
                    position: absolute;

                    width: 70%;
                    height: 12px;
                    border: 3px solid #7cbaa7;
                    background-color: #304053;
                    top: 34px;
                    left: 72px;
                    z-index: 1;
                    transition: all ease-in-out 0.3s;
                    margin: auto;
                }
                .timeline li.active {
                    color: #555555;
                }

                .timeline li.active + li:after {
                    background: #4caf50 !important;
                }
                .forms-inpt {
                    .form-control {
                        width: 100%;
                        margin: 0px 3px;
                        height: 100% !important;
                        padding: 13px 30px;
                        background: #f0f4f8;
                        border: 1px solid transparent;
                        box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                        border-radius: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;

                        color: #808492;
                    }
                }
                .book-me-btn {
                    width: 150px;
                    margin: auto;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: #7cbaa7;
                    border: 1px solid #7cbaa7;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: #202020;
                    font-weight: 500;
                    text-align: center;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }
        .search-position {
            position: relative;
        }
        .searchbox {
            top: 53px;
            right: -8px;
            position: absolute;
            background: #f0f4f8 !important;
            box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
            border-radius: 10px !important;
            border: none;
            z-index: 3;
            padding: 20px 30px;
            width: 380px;
            .search-area {
                margin: 0px;
                margin-top: 40px;
                .close-btn {
                    border: none;
                    background-image: url(../../../../../images/close-batch.png);
                    background-repeat: no-repeat;
                    top: 2px;
                    height: 68px;
                    margin-top: -10px;
                    top: 3px;
                    right: 9px;
                    font-size: 34px;
                    color: grey;
                    position: absolute;
                    svg {
                        margin-top: -34px;
                        margin-left: 10px;
                        font-size: 28px;
                    }
                }
                .search-field {
                    width: 100%;

                    margin: 0px 10px 0px;
                    height: 44px !important;
                    box-shadow: none;
                    padding: 2px 11px;

                    background: transparent;
                    border: 1px solid #394649;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #394649;
                    .icon-mgni {
                        font-size: 35px !important;
                    }
                    input {
                        font-size: 15px;
                    }
                    svg {
                        cursor: pointer;
                    }
                }
            }
            .search-me-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 17px 15px;
                margin-bottom: 10px !important;
                gap: 10px;
                margin: auto;
                margin-top: 20px;
                width: 110px !important;
                height: 50px;
                background: #7cbaa7;
                border-radius: 8px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                border: none;
                letter-spacing: 0.04em;
                text-transform: uppercase;
            }
        }
        .bars-hambrger {
            position: relative;
            .react-multi-carousel-track {
                .react-multi-carousel-item {
                    button {
                        width: 150px !important;
                        background-color: transparent !important;
                    }
                }
            }
            .hamburger-outer {
                background: #f0f4f8 !important;
                position: absolute;
                z-index: 3;
                padding: 40px 20px 40px;
                width: 400px;
                right: -18px;
                top: -19px;
                .close-btn {
                    border: none;
                    background-image: url(../../../../../images/close-batch.png);
                    background-repeat: no-repeat;
                    top: 2px;
                    height: 68px;
                    margin-top: -10px;
                    top: 3px;
                    right: 9px;
                    font-size: 34px;
                    color: grey;
                    position: absolute;
                    svg {
                        margin-top: -34px;
                        margin-left: 10px;
                        font-size: 28px;
                    }
                }
                .switch-outer {
                    width: 62.06px;
                    height: 33px;
                    background-color: white;
                    border-radius: 49.5px;
                    z-index: 1;
                    margin: 0px 15px;
                    .switch {
                        display: inline-block;
                        position: relative;
                        width: 39.58px;
                        height: 22.71px;
                        box-shadow: none !important;
                        left: 6px;
                        top: 6px;
                        box-shadow: none;
                        border-radius: 49.5px;
                        z-index: 2;
                        background: #7cbaa7;
                        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .switch::before {
                        content: "";
                        position: absolute;
                        top: 4px;
                        left: 20px;
                        width: 15.03px;
                        height: 15.03px;
                        background: white;
                        border-radius: 50%;
                        transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                            background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    }
                    .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                    }
                    input:checked + .switch {
                        background: #7cbaa7;
                    }
                    input:checked + .switch::before {
                        left: 9px;
                        background: #fff;
                    }
                    input:checked + .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                    }
                }
                .mode-set {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #3c3d40;

                    z-index: 1;
                }
                .social-set {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    color: #f0f4f8;
                    z-index: 1;
                }
                .btn-close {
                    background-color: white;
                    opacity: 1;
                    border-radius: 0px !important;
                    width: 30px;
                    height: 30px;
                }
                .sidebar-li {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: center;

                    padding: 20px;
                }
                .bottom-tabs {
                    color: #cbd5e0;
                    margin-top: 5px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h6 {
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 120%;
                        color: #cbd5e0;
                    }
                    svg {
                        font-size: 25px;
                    }
                }
                .testimonial-p {
                    color: #cbd5e0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .top-padding {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    li {
                        padding: 0px 8px;
                    }
                }
                .testimonial {
                    margin-top: -80px;
                    .timeline-top {
                        p {
                            margin-top: 4px;
                            line-height: 10px;
                        }
                        .icon-green {
                            font-size: 8px;
                            color: #7cbaa7;
                            padding-top: 3px;
                        }
                    }

                    img {
                        width: 130px;
                        margin: 0px 13px;
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
        .border-b {
            border-bottom: 1px solid #b2bdcb;
        }
        .navbar-top {
            background: #f0f4f8;
            height: 135px;
            padding: 0px 12px !important;
            .active-oter {
                width: 60px;
                height: 60px;
                padding: 5px;
                margin-right: 8px;
                z-index: 2;
                border-radius: 50px;
                background: #f0f4f8;
                box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
            }
            .testimonial-seeall {
                display: flex;
                align-items: center;
                h5 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 159.5%;

                    text-align: center;
                    letter-spacing: 0.01em;
                    text-decoration-line: underline;
                    color: #202020;
                    padding-right: 10px;
                    margin-top: 5px;
                }
                img {
                    width: 20px;
                }
            }
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: #f0f4f8 !important;
                    box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                    width: 87px;
                    padding: 8px;
                    height: 87px;
                    top: 97px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #7cbaa7;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 130px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }
            .profile-img {
                width: 65px;
                margin-left: 10px;
                margin-top: -109px;
            }
            .icons-circle {
                width: 115px;
                margin-top: 5px;
            }
            .img-logo {
                margin-left: 20px;
                width: 78px;
            }
            .timeline {
                counter-reset: year 2016;
                position: relative;
                margin-top: 27px;
                p {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 11px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -3px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-top {
                width: 30px;
                height: 30px;
                font-weight: 500;
                font-size: 9px;
                line-height: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: black;
                background: #f0f4f8 !important;
                box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
                top: -20px;
                left: 14px;
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;
                width: 50px;
                height: 50px;

                background: #f0f4f8;
                background: #eff4f8;
                box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                color: #5f6168;
                font-size: 15px;
                margin-right: 6px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                img {
                    width: 15px;
                }
                .message {
                    width: 20px !important;
                }
                .load {
                    width: 20px !important;
                }
                .answer {
                    width: 25px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;
                width: 72%;
                height: 9px;
                box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9);
                border: 3px solid #f0f4f8;
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                top: 26px;
                left: 40px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
        }
        .mi-info {
            font-size: 25px;
            margin-left: 17px;
            margin-top: -20px;
            background: #f0f4f8;
            box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
            padding: 4px 5px 5px;
            border-radius: 50px;
        }

        .navbar-right {
            padding: 18px 18px 0px 18px;
        }
        .navbar-btn {
            margin-left: 20px;
            .name-btn {
                background: #f0f4f8;
                border: 1.14031px solid #f0f4f8;
                box-shadow: inset -4.80637px -4.80637px 7.69018px #ffffff, inset 3.84509px 3.84509px 7.69018px #889fb6;
                border-radius: 4px;
                font-weight: 600;
                font-size: 9px;
                line-height: 100%;
                padding: 8px 10px;
                margin-bottom: 10px;
                text-align: center;
                letter-spacing: 0.01em;
                width: 115px;
                color: #202020;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 11px;
                text-align: center;
                color: #202020;
            }
        }

        .navbar-bars {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 30px;

            width: 40px;
            height: 40px;
            margin-bottom: 10px;
            background: #f0f4f8;
            box-shadow: -4px -4px 10px #ffffff, 5px 5px 10px rgba(178, 189, 203, 0.8);
            color: #202020;
        }
        .navbar-magni {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            font-size: 40px;

            width: 40px;
            height: 40px;
            margin-bottom: 20px;
            background: #f0f4f8;
            box-shadow: -4px -4px 10px #ffffff, 5px 5px 10px rgba(178, 189, 203, 0.8);
            color: #202020;
            border-radius: 50px;
        }
        .text-hide {
            color: transparent;
        }
        .main-content {
            .hero-sidebar {
                background: #cbd5e0;
                width: 8%;
                height: 100%;
                padding: 15px;

                .switch-outer {
                    width: 182px;
                    left: 22px;
                    height: 93px;
                    background-color: white;
                    position: relative;
                    border-radius: 49.5px;
                    z-index: 1;
                    .switch {
                        box-shadow: none;
                        display: inline-block;
                        position: relative;
                        width: 130px;
                        height: 69.64px;
                        box-shadow: none !important;
                        left: -16px;
                        top: 10px;
                        border-radius: 49.5px;
                        z-index: 2;
                        background: #7cbaa7;
                        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .switch::before {
                        content: "";
                        position: absolute;
                        top: 9px;
                        left: 68px;
                        width: 49.6px;
                        height: 49.6px;
                        background: white;
                        border-radius: 50%;
                        transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                            background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    }
                    .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                    }
                    input:checked + .switch {
                        background: #7cbaa7;
                    }
                    input:checked + .switch::before {
                        left: 11px;
                        background: #fff;
                    }
                    input:checked + .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                    }
                }
                .mode-set {
                    position: relative;

                    top: -37px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                .social-set {
                    position: relative;

                    top: 39px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 11px;
                    text-align: center;
                    color: #202020;
                }
                .bottom-tabs {
                    color: #394649;
                    margin-top: 50px;
                    h6 {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 120%;
                        color: #192529;
                    }
                }

                .info-icon {
                    border: 3px solid #394649;
                    padding: 5px 11px 8px 14px;
                    font-size: 19px;
                    margin: 20px 0px;
                    color: #394649;

                    border-radius: 50px;
                }
                .fa-magnify {
                    font-size: 35px;
                    margin: 10px 0px 55px;
                    color: #394649;
                }
            }
            .content-hero {
                width: 92%;
                .content-text {
                    background: #cbd5e0;
                }
            }
        }
        .content-text {
            position: relative;
            .dots {
                position: relative;
                top: -88px;
                left: 44px;
            }
            .tab-right-section {
                width: 15%;
            }
            .nav-item {
                background: #f0f4f8;
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 5px;
                padding: 10px;
                z-index: 2;
            }
            .nav-item .active {
                background: linear-gradient(#f0f4f8, #f0f4f8) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                border: 2px solid transparent !important;
                box-shadow: none;
                color: #202020;
            }

            .tab-section {
                width: 93%;
                margin: auto;
                margin-top: -58px !important;
            }
            .tab-content {
                background: #f0f4f8;
                box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
                border: 1px solid #facb30;
                margin-top: -75px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                height: 364px;
                border-radius: 8px;
            }
            .tab-himself-text {
                padding: 70px 20px 20px;

                position: relative;

                overflow: auto;

                p {
                    overflow: auto;

                    height: 213px;
                    padding-right: 10px;
                    ::-webkit-scrollbar {
                        width: 10px;
                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                        background: #888;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                }
            }
            .playlist-section {
                left: 55px;
                z-index: 1;
                top: 5%;
                h4 {
                    margin-bottom: 40px;
                    width: 130px;
                    position: relative;
                    right: -99px !important;
                }

                /* Grey/8
    
    Base color for Light mode
    */
            }
            .playlist-section-text {
                z-index: 3;
                position: relative;
                right: revert;
                background: #f0f4f8;
                box-shadow: inset -1.06667px -1.06667px 3.2px #ffffff, inset 1.06667px 1.06667px 3.2px #b2bdcb;
                border-radius: 8px;
                width: 280px !important;
                margin: revert;
                padding: 20px;

                width: 355px;

                background: #f0f4f8;
                /* Neo/Light/Inner/4 */

                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                border-radius: 8px;
                .slidecontainer {
                    width: revert;
                    margin-right: -8px;
                }
                @media screen and (max-width: 1580px) {
                    width: 350px;
                }
                input[type="range"].range-slider {
                    width: 66px;
                    margin-right: 17px;
                }
                .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                    height: 20px !important;
                    width: 30px !important;
                    bottom: -24px !important;
                    border-radius: 50% !important;

                    left: -10px;

                    display: flex !important;
                    color: #202020;
                    justify-content: center !important;
                    align-items: center !important;
                    background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                }
                .range-slider__wrap
                    .range-slider__tooltip.range-slider__tooltip--bottom
                    .range-slider__tooltip__caret::before {
                    border-bottom-color: #facb30 !important;
                    left: 13px;
                }
                .next-icon {
                    background: #f0f4f8;
                    box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                    border-radius: 23.0737px;
                    width: 30px;
                    text-align: center;
                    color: #202020 !important;
                    font-size: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                }
                .setting-icons {
                    background: #f0f4f8;
                    box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                    margin-left: 13px;
                    border-radius: 24.0118px;
                    padding: 1px;
                    color: #202020;
                    font-size: 11px;
                    .icons-set {
                        margin: 4px 5px;
                    }
                }
                .side-text {
                    /* Grey/9 */

                    background: #f0f4f8;
                    box-shadow: 1.75824px 1.75824px 3.51648px #b7c7d7, -1.75824px -1.75824px 3.51648px #ffffff;
                    border-radius: 2.38694px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 11.139px 6.36517px 3.97823px;
                    font-weight: 500;
                    font-size: 6.40381px;
                    line-height: 100%;
                    margin: 0px 10px;
                    /* or 5px */
                    border: 2px solid #dda01a;

                    text-align: center;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                    color: #1c1c1c;
                }
                .text-gree {
                    display: flex;
                    position: relative;
                    top: 4px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 3.27619px 5.2419px;
                    width: 105.48px;
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    font-size: 8px;
                    height: 10.55px;

                    background: #f0f4f8;
                    box-shadow: inset 0px -1.75824px 3.51648px #ffffff, inset 0px 1.75824px 2.63736px #a0b9d0;
                    border-radius: 1.08597px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 4.44588px;
                    line-height: 100%;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #101010;
                }
                .next-icon-center {
                    background: #f0f4f8;
                    box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
                    border-radius: 50.7836px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: -4px 2px 0px;
                    .iconss {
                        font-size: 10px;
                        color: #c49530;
                        margin: 3px;
                    }
                }

                .video-icon {
                    font-size: 16px;
                    background: #f0f4f8;
                    box-shadow: 1.17633px 1.17633px 1.7645px #7b858f, -1.17633px -1.17633px 1.56844px #ffffff;
                    border-radius: 50%;
                    color: #c49530;
                    width: 18.64px;
                    height: 18.64px;
                    margin: -5px 4px 0px 3px;
                    padding: 5px 1px;
                    svg {
                        margin-top: -13px;
                    }
                }
                .mic-img {
                    padding: 0px 20px;
                    img {
                        width: 90px;
                    }
                }
            }

            .video-section {
                display: none !important;
                width: 93%;
                margin: auto;
                margin-top: 20px;
                background: #f0f4f8;
                box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
                border-radius: 8px;
                right: 80px;
                height: 50%;
                bottom: 27%;
                display: flex;
                justify-content: center;
                align-items: center;
                .video-img {
                    background-image: url(../../../../../images/video-img.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 152.63px;
                    margin: 28px;
                    width: 230px;
                    height: 155.63px;
                    border-radius: 10.5263px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .design-about {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 15px 18px;
            width: 56px;
            height: 73px;
            position: relative;
            z-index: 1;
            background: #f0f4f8 !important;
            border: 4px solid transparent;
            box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb !important;
            color: #202020 !important;
            border-radius: 4px;
            font-weight: 500;
            font-size: 9px;
            line-height: 13px;
        }
        .mobile-btns {
            position: relative;
            top: -160px;
            display: flex;
            justify-content: space-between;
            padding: 0px 20px;
            align-items: center;
            .btn-video {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 114px;
                height: 45px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #ffffff;

                /* Others/1 */

                background: #304053;
                border-radius: 4px;
            }
            h4 {
                width: 130px;
                color: white;
                font-size: 18px;
                position: relative;
                bottom: -253px;
                color: #202020;
                z-index: 1;
            }
        }
        .content-img {
            position: relative;
            width: 100%;
        }
        .hero-section {
            position: relative;
            background: #f0f4f8;
            padding: 15px;
            margin: 0px 15px;
            box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border-radius: 20px;
        }

        .hero-part {
            position: absolute;
            display: none !important;
            top: 34px;
            left: 34px;
            h1 {
                font-weight: 700;
                font-size: 36px;
                line-height: 50px;
                color: #ffffff;

                text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            }
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                color: #ffffff;

                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
            }
        }
        // @media screen and (min-width: 1500px) {
        //     .hero-part {
        //         position: absolute;
        //         top: 80px;
        //         left: 80px;
        //         h1 {
        //             font-weight: 700;
        //             font-size: 68px;
        //             line-height: 90px;
        //             color: #ffffff;

        //             text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        //         }
        //         p {
        //             font-weight: 400;
        //             font-size: 20px;
        //             line-height: 24px;
        //             color: #ffffff;

        //             text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        //         }
        //     }
        // }
        .content-hero {
            width: 92%;
        }

        .offcanvas-backdrop.show {
            opacity: 0 !important;
            position: absolute !important;
            z-index: 9;
        }
        .offcanvas {
            position: absolute !important;
            z-index: 9;
        }
    }

    .img-logo-oter {
        background: #f0f4f8;
        /* Neo/Light/Inner/3 */

        box-shadow: inset -4px -4px 10px #ffffff, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
        border-radius: 15px;
        padding: 10px;
        margin-left: 10px;
        .img-logo {
            border-radius: 15px;
            margin-left: 0px !important;
        }
    }
}
.modal-setting {
    .modal .modal-dialog {
        max-width: 99% !important;
        top: 130px;
        margin: auto;
    }
    @media screen and (min-width: 1920px) {
        .modal .modal-dialog {
            top: 130px;
        }
    }
    @media screen and (min-width: 576px) {
        .modal .modal-dialog {
            max-width: 99% !important;
        }
    }
    @media screen and (min-width: 576px) {
        .modal .modal-dialog {
            max-width: 99% !important;
        }
    }
    .modal-90w {
        width: 90% !important;
    }

    .modal-content {
        background: linear-gradient(151.05deg, #ebf2f7 13.27%, #f2f6f9 83.27%) !important;
        box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8),
            2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4) !important;
        border: 1px solid transparent !important;
        border-radius: 2.84939px;
    }
    .modal-header {
        border-bottom: none;
    }
    .screen-btn {
        background: linear-gradient(49.05deg, #f0bf25 -0.22%, #e28d0e 101.07%);
        box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
        border-radius: 25px;
        padding: 15px;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #202020;
        width: 143px;
        margin: 30px auto;
        display: flex;
        justify-content: center;
        border: none;
    }
}

// @media screen and  (min-width: 576px){
//     .modal-dialog {
//         max-width: 100%!important;
//         margin-right: auto;
//         margin-left: auto;
//     }
// }
@media (min-width: 576px) {
    .modal-dialog.modal-90w {
        max-width: 100% !important;
    }
}
.banner-btn {
    .btn-left {
        button {
            top: 12px;
            transform: rotate(7deg);
        }
    }
    .btn-bg {
        background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%) !important;
        box-shadow: inset -4.55556px -4.55556px 7.59259px rgba(255, 255, 255, 0.75),
            inset 3.03704px 3.03704px 7.59259px rgba(166, 180, 200, 0.4) !important;
        border-radius: 3px;
        border: 1px solid #f2e6cf;
        button {
            background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
            border-radius: 5px;
            color: #202020 !important;
        }
    }
    .ml-3 {
        margin-left: 40px;
    }
}
.modal-dialog.modal-90w {
    width: 99%;
    top: 130px;
    margin: auto;
    .modal-content {
        background: linear-gradient(151.05deg, #ebf2f7 13.27%, #f2f6f9 83.27%) !important;
        box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8),
            2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4) !important;
        border: 1px solid transparent !important;
        border-radius: 2.84939px;
        .modal-header {
            border-bottom: none;
        }
    }
}
.screen-btn {
    cursor: pointer;
}
