.TAB-7-form {
    li div label {
        font-size: 12px !important;
        font-weight: 500;
        height: 23px;
    }

    .btn-bg button {
        top: 15px;
        transform: rotate(13deg);
    }

    .custom-file-drop-area p {
        font-size: 15px;
    }

    .custom-file-drop-area .icon {
        margin-bottom: 10px;
        font-size: 15px;
        width: 25px;
        height: 25px;
    }

    .custom-file-drop-area {
        // background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 20px;
    }

    .f_14 {
        font-size: 14px;
    }



    .items-center {
        align-items: center;
    }


    input {
        // background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 5px;
    }

    .select_shadow {
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4) !important;
    }

    .first-field {
        .bgArrow:before {
            top: 20px !important;
            right: 6px;
        }
    }

    .px_2 {
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }

    .btn-bg {
        color: #edb32b;
    }

    // .btn-bg button {
    //     color: #fff;
    // }


    .pl_0 {
        padding-left: 0 !important;
    }

    .w-150 {
        width: 150px !important;
    }

    .TAB-6-form .select-field .bgArrow:before {
        top: 11px !important;
        right: 16px;
    }
}

.dark .TAB-7-form {

    .custom-file-drop-area {
        background: #1c1c1c;
        box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99), inset 6px 9px 16px rgba(0, 0, 0, 0.8);
    }
}

.fw-medium {
    font-weight: 500 !important;
}

.TAB-8_1-form {
    .social_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -5px -5px 8px #FFFFFF, inset 4px 4px 8px #889FB6;
        border-radius: 100px;

        .img {
            height: 30px;
        }
    }

    .input-group {
        background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 5px;

        button {
            background: var(--goldenlinear);
            border-radius: 5px;
            height: auto;
            margin: 0;
            width: auto;
            color: #000;
            white-space: nowrap;
        }
    }

    .h_30 {
        height: 30px;
    }

    .QR_label {
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        color: #545C81;
        margin-top: 4px;
    }

    .download_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 3px;

        .icon {
            color: #F0BF25;
        }
    }

    .QR_outer_div {
        display: flex;
        padding: 10px;
        width: 90px;
        height: 90px;
        // background: #EFF4F8;ss
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 5px;

        .QR_box {
            background-image: url("/images/QR.png");
            background-repeat: no-repeat;
            height: auto;
            width: 90px;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .all_QR_btn {
        padding: 12px 65px !important;
        background: linear-gradient(349.94deg, #F0BF25 -8.14%, #E28D0E 119.35%);
        box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
        border-radius: 5px;
        white-space: nowrap;
        width: auto;
        color: #fff;
        height: auto;
    }

    .social_icon_block {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 40px;
        height: 40px;
        background: var(--goldenlinear);
        border-radius: 25px;
    }

    .disclaimer {
        background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 5px;
        padding: 20px;
        text-align: center;
        p{
            font-size: 14px;
        }
    }

    .btn-bg {
        color: #edb32b;
    }

    .btn-bg button {
        // color: #fff;
        top: 20px;
        transform: rotate(8deg);
    }
}

.mt_100 {
    margin-top: 100px;
}
.socailnet {
    width: 342px;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 53px;
        width:2px;
        height: 120px;
        background: #EFF4F8;
        box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
    }
    &::before {
        content: "";
        position: absolute;
        bottom:-46px;
        right: 53px;
        width:2px;
        height: 120px;
        background: #EFF4F8;
        box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
    }
}
.socialText {
    width: 260px;
    height: 210px;
    padding: 12px 15px;
    margin-top: -50px;
    margin-bottom: 15px;
    h3 {
        font-family: 'Times New Roman', Times, serif;
        color: #A67363;
        font-size: 30px;
        line-height: 30px;
        font-weight: 700;
        text-align: center;
    }
    h4 {
        font-size: 12px;
        line-height: 14px;
    }
}
.linesap{
    box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
    height: 3px;
    margin: 0px 0;
}
.socailnet {
    h5{
        font-size: 13px;
        line-height: 2-px;
    }
}
.btmtxt{
    width: 560px;
    height: 70px;
    padding: 12px 10px;
    margin-top: -47px;
    p{
        font-size: 15px;
        line-height: 24px;
        margin: 0;
    }
}
.linesep8 {
    h3{
        font-size: 24px;
        line-height: 35px;
        margin: 5px auto;
    }
    button{
        width:270px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1DBFF0;
        letter-spacing: 0.04em;
        font-size: 10px;
    }
}
.socialblue {
    width: 270px;
    height: 32px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1DBFF0;
    margin-top: -16px;
    a {
        background: #1DBFF0;
        box-shadow: inset -2px -2px 2px rgba(191, 255, 240, 0.3), inset 2px 2px 2px #22799E;
        color: #fff;
        margin: 0 1.5px;
    }
}
.thirdsec {
    font-family: 'Roboto Mono', monospace;
    color: #545C81;
    letter-spacing: 0.01em;
}
.yellowClr {
    .br-primary {
        background: linear-gradient(169.44deg, #FACB30 -5.83%, #FEF9C8 35.12%, #C49530 89.43%, #FFF8C4 129.64%);
    }
}
.silverClr{
    .br-primary{
        background: linear-gradient(180deg, #A5A5A5 -18%, #EBEBEB 22.5%, #C0C0C0 67.84%, #E8E8E8 116%);
    }
}
.emailul {
    li {
        position: relative;
        font-size: 12px;
        line-height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        h6{
            font-size: 12px;
            line-height: 12px;
            margin-bottom: 0;
        }
        h4{
            font-size: 24px;
            letter-spacing: 0.22em;
            line-height: 24px;
        }
        &::after{
            border: 2px solid #EFF4F8;
            box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
            position: absolute;
            content:"";
            height: 2px;
            width: 100%;
            bottom: 0px;
            left: 0px;
        }
        &:nth-child(even){
            height: 50px;
            &::before {
                border: 2px solid #EFF4F8;
                box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
                position: absolute;
                content:"";
                height: 50px;
                width: 2px;
                bottom: 0px;
                right: 0px;
            }
        }
        &:last-child{
            &::after{
                content: none;
            }
            h6{
                width: 78%;
            }
        }
        &:nth-child(4){
            &::after{
                content: none;
            }
        }
    }
}
.emailul2 {
    li {
        position: relative;
        font-size: 11px;
        line-height: 32px;
        &:first-child{
            &::after{
                border: 2px solid #EFF4F8;
                box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
                position: absolute;
                content:"";
                height: 2px;
                width: 100%;
                bottom: 0px;
                left: 0px;
            }
        }
    }
}
.emailSocial{
    background: #545C81;
    padding: 5px;
    margin-bottom: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    a {
        background: #545C81;
        box-shadow: inset -2px -2px 2px rgba(208, 191, 255, 0.3), inset 2px 2px 2px #38464D;
        color: #fff;
        margin: 0 10px;
    }
}
.emailfourth{
    h3{
        font-family: 'Abril Fatface', cursive;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.01em;
    }
    .customimg {
        top: -50px;
        position: absolute;
    }
    .tophead{
        letter-spacing: 0.2em;
        text-transform: uppercase;
        width: 421px;
    }
}
.emailfive{
    h2 {
        font-size: 40px;
        line-height: 48px;
        letter-spacing: 0.05em;
        font-family: 'Jost', sans-serif;
        font-weight: 700;
    }
}
ul.emaillist{
    li{
        position: relative;
        &:first-child{
            &::after{
                position: absolute;
                top:0;
                content:"";
                right: 0px;
                width:0px;
                height:50px;
                border: 1px solid #EFF4F8;
                box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
            }
        }
        h4, h5{
            font-size: 10px;
            line-height: 10px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            margin-bottom: 3px;
            font-weight: 600;
            margin-top: 0;
        }
        p{
            font-size: 12px;
            line-height: 100%!important;
            // letter-spacing: 0.02em;
            margin: 0;
        }
    }
}
.largetp{
    button {
        top:25px;
    }
}
.emailsix {
    h6{
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 0.02em;
        b{
            font-size: 9px;
            line-height: 9px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
        }
    }
}
.bgTopEmail {
    background: url("../../../../images/img50.png") no-repeat;
    background-size: 100% auto;
    padding-top: 65px;
    .bgmiddelEmail{
        background: url("../../../../images/bg-email.png") no-repeat;
        background-size: 100% 100%;
    }
    h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        line-height: 30px;
        font-weight: 600;
    }
    h4 {
        letter-spacing: 0.12em;
        font-size: 11px;
        line-height: 11px;
        width: 280px;
        padding: 9px 16px;
    }
    h6{
        font-size: 10px;
        text-transform: uppercase;
        line-height: 15px;
    }
    ul {
        width: 225px;
    }
}
.emailslider{
    h5{
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
}
.slideremail {
    .socailnet {
        width: 300px;
    }
    .emailcustom {
        width: 96%;
        margin-left: 10px;
        margin-top: 10px;
    }
    .ProfileTrack{
        height: auto!important;
    }
    .slick-active.slick-current {
        .slidimg {
            border: 3px solid var(--primary);
        }
    }
    .w-98{
        width: 98%;
    }
}
.textareasec {
    p{
        font-size: 16px;
        line-height: 18px;
    }
}