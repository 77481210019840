@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');
// ammar css start
.desktop-glassmorphic-design-1{
    .how-it-work-help-section {
        padding: 40px 75px 120px;
        background: linear-gradient(315.3deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%);
        .top-desc {
            h2 {
                font-size: 30px;
                font-weight: 600;
                line-height: 36px;
                letter-spacing: 0em;
            }
    
            p {
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
            }
        }
    
        .programs-flex {
            gap: 40px;
            margin-top: 40px;
        }
    
        .how-it-work-program {
            width: 100%;
            max-width: 250px;
            height: 250px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
            box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
            backdrop-filter: blur(5px);
            padding: 20px;
            position: relative;
    
            h5 {
                font-family: Inter;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                display: block;
                width: 100%;
                max-width: 160px;
                margin: 0 auto;
            }
    
            p {
                font-size: 13px;
                margin: 0;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: center;
                font-family: 'inter';
                display: block;
                margin-top: 10px;
            }
            .how-it-work-program-numbering {
                position: absolute;
                left: 0;
                top: 15px;
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
    
                span{
                    font-size: 20px;
                    font-weight: 600;
                    color: #202020;
                    font-family: Inter
                }
            }
        }
    
        .timeline-section-content {
            display: flex;
            align-items: center;
            margin-top: 70px;
            .timeline-working-content {
                width: 35%;
                text-align: left;
                .timeline-workin-top-content{
                    .how-it-works-btn {
                        border-radius: 30px;
                        padding: 14px 25px;
                        width: fit-content;
                        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                        color: #202020;
                        cursor: pointer;
                    }
                    h2 {
                        font-family: Inter;
                        font-size: 36px;
                        font-weight: 700;
                        line-height: 44px;
                        letter-spacing: 0em;
                        text-align: left;
                        display: block;
                        margin-top: 20px;
                        margin-bottom: 10px;;
                        color: #202020;
                    }
                    p {
                        font-family: Inter;
                        font-size: 16px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 19px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                }
                .timeline-workin-middle-content{
                    margin-top: 60px;   
    
                    .timeline-working-desc{
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #202020;
    
                    }
                }
                .timeline-section-bottom-content{
                    margin-top: 80px;
                    .timeline-section-bottom-content-inner {
                        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                        box-shadow: none;
                        width: 100%;
                        max-width: 460px;
                        padding: 40px;
                        border-radius: 20px;
    
                        h3 {
                            font-family: Inter;
                            font-size: 30px;
                            font-weight: 400;
                            line-height: 36px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #202020;
                            margin-bottom:0 ;
                        }
                        p{
                            font-family: Inter;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 21px;
                            letter-spacing: 0em;
                            text-align: left;
                            margin-bottom: 0;
                            margin-top: 10px;
                            color: #3C3D40;
    
                        }
                        .btn-text-wrapper {
                            display: flex;
                            align-items: center;
                            margin-top: 20px;
                            .timeline-lets-talk {
                                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                                border-radius: 40px;
                                padding: 20px;
                                font-family: Inter;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 16px;
                                letter-spacing: 0.04em;
                                text-align: center;
                                margin-right: 20px;
                                width: 150px;
                                cursor: pointer;
                            }
    
                            .timeline-working-hours {
                                width: 100%;
                                max-width: 160px;
                                border-left: 1px solid #CBD5E0;
                                padding-left: 20px;
                                span {
                                    font-family: Inter;
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: 16px;
                                    letter-spacing: 0em;
                                    text-align: left;
                                    color: #202020;
                                }
                            }
    
                        }
                    }
                }
    
            }
            .steps-timeline-wrapper{
                width: 65%;
                position: relative;
                .steps-timeline-img-wrapper {
                    width: 100%;
                    max-width: 680px;
                    margin: 0 auto;
                    position: relative;
                    .steps-timeline-content {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                          .steps-timeline-content-inner {
                        width: 100%;
                        max-width: 180px;
                        position: absolute;
                        margin: 0 auto;
                        .step-counter {
                            width: 70px;
                            height: 70px;
                            margin: 0 auto;
                            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                            box-shadow: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 10px;
                            font-family: Inter;
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 29px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
                        span.step-heading {
                            font-family: Inter;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 19px;
                            letter-spacing: 0em;
                            color: #202020;
                            margin-top: 10px;
                            display: block;
                        }
                        .steps-timeline {
                            font-family: Inter;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0em;
                            text-align: center;
                            margin-bottom: 0;
                            margin-top: 5px;
                        }
      
                    }
                    .steps-timeline-content-inner:first-child{
                        left: 0px;
                        top: 25px;
    
                    }
                    .steps-timeline-content-inner:nth-child(2){
                        right: 0px;
                        top: 135px;
    
                    }
                    .steps-timeline-content-inner:nth-child(3){
                        left: 0px;
                        top: 230px;
    
                    }
                    .steps-timeline-content-inner:nth-child(4){
                        right: 0px;
                        bottom: 160px;
    
                    }
                    .steps-timeline-content-inner:nth-child(5){
                        left: 0px;
                        bottom: 60px;
    
                    }
                    .steps-timeline-content-inner:nth-child(6){
                        right: 0px;
                        bottom: -50px;
    
                    }
                    }
                  
                }
                .glassmorphic-timeline{
                    display: block !important;
                    margin: 0 auto;
                }
                .regular-timeline, .neumorphic-timeline{
                    display: none;
                }
     
            }
        }
    }
}


// ammr css end