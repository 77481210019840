@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
// ammar css start
.mobile-help-section {
  padding: 40px 20px 60px !important;
  .top-desc {
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      letter-spacing: 0em;
    }

    p {
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
    }
  }

  .programs-flex {
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 40px;
  }
}

// ammr css end
