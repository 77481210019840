@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

.Glassmorphic-design {
    .reg-sond{
        display: none!important;
    }
    .glass-sond{
        display: block!important;
    }
    .reg-book{
        display: none;
    }
    .book-me-mue {
        display: none;
    }
    .reg-sond {
        display: none;
    }
    .glass-sond {
        display: block;
    }
    .switch-mobile .switch-border {
        z-index: 1 !important;
    }
    .bars-hambrger .hamburger-outer .social-set {
        display: none;
    }
    .glass-show {
        text-align: center;
    }
    .book-me-btn {
        display: none !important;
    }
    .meu-timeline {
        display: block;
    }
    .fa-magnify {
        box-shadow: none;
        background: none;
        font-size: 30px !important;

        margin-top: 54px !important;
        padding: 0px !important;
        color: #394649;

        border-radius: 30px;
    }
    .meu-social {
        display: block;
    }
    .reg-social {
        display: none !important;
    }
    .bars-seeall .hamburger-outer .top-padding li {
        padding: 19px 8px;
    }
    .testimonial-p {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #202020 !important;
        padding: 8px;
        margin-left: 18px;
        border-radius: 10px;
        width: 127px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn-video {
        display: none !important;
    }
    .switch-mobile {
        display: block;
        position: absolute;
        .mode-set {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            z-index: 1;
            bottom: -168px;
            position: absolute;
            transform: rotate(-90deg);
            z-index: 1;
        }
        .social-set {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 12px;
            color: #f0f4f8;
            z-index: 1;
            bottom: -289px;
            position: absolute;
            transform: rotate(-90deg);
        }
        .switch-border {
            width: 75px;
            height: 40.85px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            left: -10px;
            top: 246px;
            position: relative;
            border-radius: 49.5px;
            z-index: 31;
            .check-box {
                transform: scale(2);
            }

            input[type="checkbox"] {
                position: relative;
                appearance: none;
                width: 100px;
                height: 50px;
                background: #ccc;
                border-radius: 50px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                transition: 0.4s;
            }

            input:checked[type="checkbox"] {
                background: #7da6ff;
            }

            input[type="checkbox"]::after {
                position: absolute;
                content: "";
                width: 22px;
                height: 22px;
                left: 50%;
                top: 4px;
                background: #fff;
                border-radius: 50%;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                transform: scale(1.1);
                transition: 0.4s;
            }

            input:checked[type="checkbox"]::after {
                left: 8px;
            }

            check-box {
                display: inline-block;
                position: relative;
                width: 60px;
                height: 30.64px;
                left: 7px;
                top: 5px;
                box-shadow: none !important;
                border-radius: 49.5px;
                z-index: 2;
                background: #7cbaa7;
                transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                vertical-align: middle;
                cursor: pointer;
            }
            input[type="checkbox"] {
                display: inline-block;
                position: relative;
                width: 60px;
                height: 30.64px;
                left: 7px;
                top: 5px;
                box-shadow: none !important;
                border-radius: 49.5px;
                z-index: 2;
                background: #7cbaa7;
                transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                vertical-align: middle;
                cursor: pointer;
            }

            input[type="checkbox"]::before {
                content: "";

                border-radius: 50%;

                position: absolute;
                top: 0;
                left: 0;
                transition: 0.5s;
            }

            input[type="checkbox"]:checked::before {
                transform: translateX(100%);
                background: #fff;
            }

            input[type="checkbox"]:checked {
                display: inline-block;
                position: relative;
                width: 60px;
                height: 30.64px;
                left: 7px;
                top: 5px;
                box-shadow: none !important;
                border-radius: 49.5px;
                z-index: 2;
                background: #7cbaa7;
                transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }
    .mue-plist {
        display: block;
    }
    .reg-timeline {
        display: none !important;
    }
    .border-b {
        border-bottom: 1px solid transparent !important;
    }
    .meu-timeline {
        display: block !important;
        .timeline {
            margin-left: 33px;
        }
    }
    .mue-border {
        width: 100%;
        height: 2px;
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        margin: 5px 3px;
    }

    .reg-img {
        display: none;
    }
    .meu-img {
        display: block!important;
    }
    .reg-social {
        display: none;
    }
    .meu-social {
        display: block;
        li {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            border-radius: 30px;
            padding: 10px;
            width: 35px;
            height: 35px;
            text-align: center;
            padding: 9px 9px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px auto;
        }
    }

    .reg-icon {
        display: none !important;
    }
    .mue-shape {
        display: block;
        img {
            position: absolute;
            left: 0;
            z-index: 0;
            top: 185px;
        }
    }
    .mue-shape-last {
        display: block;
        img {
            position: absolute;
            z-index: 0;
            bottom: 91px;
            right: 0;
        }
    }
    .meu-icon {
        display: block !important;

        border-radius: 11.7633px;

        font-size: 14px !important;

        border-radius: 50%;
        color: #c49530 !important;

        svg {
            margin-top: -22px;
        }
    }

    .design-screen-mobile {
        width: 400px;
        margin: auto;
        background-image: url(../../../../../images/Desktop-theme-2.png);
        background-repeat: no-repeat;
        background-size: cover;
        .content-text .playlist-section-text{
            margin-top: 20px!important;
        }
        .content-text .video-section {
            display: block !important;
            position: absolute!important;
            right: 1px!important;
            height: 18% !important;
            bottom: 27%!important;
            width: 41%!important;
            top: 15px!important;
        }
 
   
        .bookme-inner{
            .hamburger-outer{
         
                    left: -16px;
          
            }
        }
        .reg-sond{
            display: none!important;
        }
        .glass-sond{
            display: block!important;
        }
        .bars-hambrger .hamburger-outer{
            left: -12px !important;
            top: 0;
        }
        .playlist-section-text {
            position: revert !important;
            margin-left: 33px !important;
        }
        .bars-hambrger .hamburger-outer {
            .switch-border {
                top: -269px !important;
            }
            .social-set {
                margin-left: 70px;
            }

            .testimonial .timeline-top {
                width: 88px;
                padding: 9px;
                height: 87px;
                top: 93px !important;
                left: 14px !important;
            }
        }
        .meu-social {
            display: block;
        }
        .glass-show {
            display: block;
            width: 182px;
            position: relative;
            right: 0px;
        }
        .bookme-oter {
            position: relative;

            .bookme-inner {
                // background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                // border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
             border: none!important;
                z-index: 3;
                padding: 20px;
                width: 400px;
                position: absolute;

                right: -12px;
                top: 54px;

                .forms-inpt {
                    border-radius: 10px;
                    padding: 40px 30px;
                    margin-top: 40px;
                    .form-control {
                        width: 100%;
                        margin: 0px 3px;
                        height: 100% !important;
                        padding: 13px 30px;
                        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                        border-radius: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;

                        color: #808492;
                    }
                }

                .mr-0 {
                    margin-right: 0px !important;
                }
                .meu-timeline {
                    display: none;
                }
                .timelinee {
                    counter-reset: year 2016;
                    position: relative;
                    margin-top: 73px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p {
                        font-weight: 400;
                        font-size: 8px;
                        line-height: 12px;
                        /* identical to box height */

                        display: flex;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #1c1c1c;
                        margin-top: -3px;
                    }
                }

                .timelinee li {
                    list-style: none;
                    float: left;

                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Dosis", sans-serif;
                }

                //   ul:nth-child(1){
                //     color: #4caf50;
                //   }
                .timeline-icon {
                    margin-top: -30px;
                }
                .timeline-topp {
                    width: 22px;
                    height: 22px;
                    font-weight: 500;
                    font-size: 8px;
                    line-height: 7px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: #5f6168 !important;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    top: -22px;
                    left: 24px;
                }
                .timelinee li {
                    z-index: 2;
                    position: relative;

                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 46px;
                    margin: 0 auto 10px auto;
                    width: 75px;
                    height: 75px;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    color: #5f6168;
                    font-size: 15px;
                    margin-right: 15px;

                    transition: all ease-in-out 0.3s;
                    cursor: pointer;
                    img {
                        width: 20px;
                    }
                    .message {
                        width: 25px !important;
                    }
                    .load {
                        width: 28px !important;
                    }
                    .answer {
                        width: 32px;
                    }
                }

                .timelinee:after {
                    content: "";
                    position: absolute;

                    width: 70%;
                    height: 12px;
                    border: 3px solid #7cbaa7;
                    background-color: #304053;
                    top: 34px;
                    left: 72px;
                    z-index: 1;
                    transition: all ease-in-out 0.3s;
                    margin: auto;
                }
                .timelinee li.active {
                    color: #555555;
                }

                .timelinee li.active + li:after {
                    background: #4caf50 !important;
                }
                .forms-inpt {
                    .form-control {
                        width: 100%;
                        margin: 0px 3px;
                        height: 100% !important;
                        padding: 13px 30px;
                     
                        border-radius: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;

                        color: #808492;
                    }
                }
                .book-me-btnn {
                    width: 150px;
                    margin: auto;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: #7cbaa7;
                    border: 1px solid #7cbaa7;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: #202020;
                    font-weight: 500;
                    text-align: center;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }
        .bookme-oterr {
            position: relative;
            .bookme-inner {
              
                z-index: 3;
                padding: 20px;
                width: 400px;
                position: absolute;

                top: 55px;
                left: 0px;

                .close-btn {
                    border: none;
                    background-color: transparent !important;
                    top: 0px;
                    right: 14px;
                    font-size: 34px;
                    color: grey;
                    position: absolute;
                }
                .forms-inpt {
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.5) 3.01%,
                        rgba(255, 255, 255, 0.05) 103.3%
                    ) !important;
                    border: 1px solid rgba(255, 255, 255, 0.4901960784);
                    border-radius: 10px;
                    padding: 40px 30px;
                    margin-top: 40px;
                    .form-control {
                        width: 100%;
                        margin: 0px 3px;
                        height: 100% !important;
                        padding: 13px 30px;
                        background: linear-gradient(#f0f4f8, #f0f4f8) padding-box,
                            linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                        border: 3px solid transparent !important;
                        border-radius: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;

                        color: #808492;
                    }
                }

                .mr-0 {
                    margin-right: 0px !important;
                }
                .meu-timeline {
                    display: none;
                }
                .timelinee {
                    counter-reset: year 2016;
                    position: relative;
                    margin-top: 73px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p {
                        font-weight: 400;
                        font-size: 8px;
                        line-height: 12px;
                        /* identical to box height */

                        display: flex;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #1c1c1c;
                        margin-top: -3px;
                    }
                }

                .timelinee li {
                    list-style: none;
                    float: left;

                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Dosis", sans-serif;
                }

                //   ul:nth-child(1){
                //     color: #4caf50;
                //   }
                .timeline-icon {
                    margin-top: -30px;
                }
                .timeline-topp {
                    width: 22px;
                    height: 22px;
                    font-weight: 500;
                    font-size: 8px;
                    line-height: 7px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    top: -22px;
                    left: 24px;
                }
                .timelinee li {
                    z-index: 2;
                    position: relative;

                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 46px;
                    margin: 0 auto 10px auto;
                    width: 75px;
                    height: 75px;
                    background: #f0f4f8;
                    color: #5f6168;
                    font-size: 15px;
                    margin-right: 15px;

                    transition: all ease-in-out 0.3s;
                    cursor: pointer;
                    img {
                        width: 20px;
                    }
                    .message {
                        width: 25px !important;
                    }
                    .load {
                        width: 28px !important;
                    }
                    .answer {
                        width: 32px;
                    }
                }

                .timelinee:after {
                    content: "";
                    position: absolute;

                    width: 70%;
                    height: 12px;
                    border: 3px solid #7cbaa7;
                    background-color: #304053;
                    top: 34px;
                    left: 72px;
                    z-index: 1;
                    transition: all ease-in-out 0.3s;
                    margin: auto;
                }
                .timelinee li.active {
                    color: #555555;
                }

                .timelinee li.active + li:after {
                    background: #4caf50 !important;
                }
                .forms-inpt {
                    .form-control {
                        width: 100%;
                        margin: 0px 3px;
                        height: 100% !important;
                        padding: 13px 30px;
                        background: linear-gradient(#f0f4f8, #f0f4f8) padding-box,
                            linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                        border: 3px solid transparent !important;
                        border-radius: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;

                        color: #808492;
                    }
                }
                .book-me-btnn {
                    width: 150px;
                    margin: auto;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: #7cbaa7;
                    border: 1px solid #7cbaa7;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: #202020;
                    font-weight: 500;
                    text-align: center;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }
        .search-position {
            position: relative;
        }
        .searchbox {
            top: -53px;
            left: 112%;
            position: absolute;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            border-radius: 10px !important;
            border: none;
            z-index: 3;
            padding: 20px 30px;
            width: 380px;
            .search-area {
                margin: 0px;
                .close-btn {
                    border: none;
                    background-color: transparent !important;
                    top: -11px;
                    right: -155px;
                    font-size: 34px;
                    color: grey;
                    position: relative;
                }
                .search-field {
                    width: 186px;
                    margin: 0px 0px 0px;
                    height: 44px !important;

                    padding: 2px 11px;

                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    border-radius: 40px;

                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #394649;
                    .icon-mgni {
                        font-size: 35px !important;
                    }
                    input {
                        font-size: 15px;
                    }
                    svg {
                        cursor: pointer;
                    }
                }
            }
            .search-me-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 17px 15px;
                margin-bottom: 10px !important;
                gap: 10px;
                margin: auto;
                margin-top: 20px;
                width: 110px !important;
                height: 50px;
                background: #7cbaa7;
                border-radius: 8px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                border: none;
                letter-spacing: 0.04em;
                text-transform: uppercase;
            }
        }
        .bars-hambrger {
            position: relative;

            .react-multi-carousel-track {
                .react-multi-carousel-item {
                    button {
                        width: 150px !important;
                        background-color: transparent !important;
                    }
                }
            }
            .hamburger-outer {
                position: absolute;
                z-index: 4;
                padding: 40px 20px 40px;
                width: 400px;
                left: -13px;
                top: -29px;
                .forms-inpt {
                    .form-control {
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        ) !important;
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    }
                }

                .sidebar-li {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: center;
                    border-bottom: 1px solid rgb(177, 176, 176);
                    padding: 20px;
                    color: #3c3d40 !important;
                }

                .testimonial-p {
                    color: #3c3d40;
                    font-size: 15px;
                }
                .bottom-tabs {
                    color: #3c3d40;
                    margin-top: 5px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h6 {
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 120%;
                        color: #3c3d40;
                    }
                }

                .btn-close {
                    background-color: #7cbaa7;
                }
                .close-btn {
                    border: none;
                    color: white;
                    background: #7cbaa7;
                    width: 45px;
                    height: 40px;
                    font-size: 23px;
                    position: absolute;
                    right: 17px;
                    top: 30px;
                }
                .switch-outer {
                    width: 62.06px;
                    height: 33px;
                    background-color: white;
                    border-radius: 49.5px;
                    z-index: 1;
                    margin: 0px 15px;
                    .switch {
                        display: inline-block;
                        position: relative;
                        width: 39.58px;
                        height: 22.71px;
                        box-shadow: none !important;
                        left: 6px;
                        top: 6px;
                        box-shadow: none;
                        border-radius: 49.5px;
                        z-index: 2;
                        background: #7cbaa7;
                        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .switch::before {
                        content: "";
                        position: absolute;
                        top: 4px;
                        left: 20px;
                        width: 15.03px;
                        height: 15.03px;
                        background: white;
                        border-radius: 50%;
                        transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                            background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    }
                    .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                    }
                    input:checked + .switch {
                        background: #7cbaa7;
                    }
                    input:checked + .switch::before {
                        left: 9px;
                        background: #fff;
                    }
                    input:checked + .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                    }
                }
                .mode-set {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #3c3d40;

                    z-index: 1;
                }
                .social-set {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    color: #3c3d40;
                    z-index: 1;
                }
                .btn-close {
                    background-color: white;
                    opacity: 1;
                    border-radius: 0px !important;
                    width: 30px;
                    height: 30px;
                }

                .bottom-tabs {
                    color: #cbd5e0;
                    margin-top: 5px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h6 {
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 120%;
                        color: #cbd5e0;
                    }
                    svg {
                        font-size: 25px;
                    }
                }
                .testimonial-p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .top-padding {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    li {
                        padding: 0px 8px;
                    }
                }
                .testimonial {
                    margin-top: -80px;
                    .timeline-top {
                        flex-direction: column;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        position: relative;
                        color: black;
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                        overflow: hidden;
                        width: 87px;
                        padding: 8px;
                        height: 87px;
                        top: 97px;
                        left: 23px;
                        p {
                            margin-top: 4px;
                            line-height: 10px;
                        }
                        .icon-green {
                            font-size: 8px;
                            color: #202020;
                            padding-top: 3px;
                        }
                        p {
                            font-size: 8px;
                        }
                    }

                    img {
                        width: 130px;
                        margin: 0px 13px;
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
        .border-b {
            border-bottom: 1px solid #b2bdcb;
        }
        .navbar-top {
            background: transparent !important;
            height: 135px;
            padding: 0px 12px !important;

            .testimonial-seeall {
                display: flex;
                align-items: center;
                h5 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 159.5%;

                    text-align: center;
                    letter-spacing: 0.01em;
                    text-decoration-line: underline;
                    color: #202020;
                    padding-right: 10px;
                    margin-top: 5px;
                }
                img {
                    width: 20px;
                }
            }
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;

                    width: 87px;
                    padding: 8px;
                    height: 87px;
                    top: 97px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #202020;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 130px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }
            .profile-img {
             
                width: 66px;
                margin-left: 15px;
                margin-top: -117px;

            }
            .icons-circle {
                width: 115px;
                margin-top: 5px;
            }
            .img-logo {
                margin-left: 20px;
                width: 78px;
            }
            .timeline {
                counter-reset: year 2016;
                position: relative;
                margin-top: 27px;
                p {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 11px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -3px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -23px;
            }
            .timeline-top {
                width: 22px;
                height: 22px;
                font-weight: 500;
                font-size: 6px;
                line-height: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #5f6168 !important;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.9) 3.01%, rgba(255, 255, 255, 0.1) 103.3%);
                /* Background blur/Light/4 */

                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(1.5px);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                top: -20px;
                left: 14px;
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;
                width: 60px;
                height: 60px;

                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.3) 3.01%,
                    rgba(255, 255, 255, 0) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                color: #5f6168;
                font-size: 15px;
                margin-right: 6px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                img {
                    width: 15px;
                }
                .message {
                    width: 20px !important;
                }
                .load {
                    width: 22px !important;
                }
                .answer {
                    width: 28px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;
                width: 72%;
                height: 9px;
                border: 3px solid #f0f4f8;
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                top: 26px;
                box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9);
                left: 40px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
        }
        .mi-info {
            font-size: 25px;
            margin-left: 17px;
            margin-top: -20px;
        }

        .navbar-right {
            padding: 18px 18px 0px 18px;
        }
        .navbar-btn {
            margin-left: 20px;
            .name-btn {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 4px;
                font-weight: 600;
                font-size: 9px;
                line-height: 100%;
                padding: 8px 10px;
                margin-bottom: 10px;
                text-align: center;
                letter-spacing: 0.01em;
                width: 115px;
                color: #202020;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 11px;
                text-align: center;
                color: #202020;
            }
        }

        .navbar-bars {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 20px;
            width: 40px;
            height: 40px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            color: #202020;

            border-radius: 5px;

            margin: auto;
            margin-left: 10px;
            margin-right: 20px;
            margin-top: -7px;
        }
        .navbar-magni {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px;
            font-size: 40px;

            width: 40px;
            height: 40px;
            margin-bottom: 20px;
            color: white;
            background: #202020;
        }
        .text-hide {
            color: transparent;
        }
        .main-content {
            .hero-sidebar {
                background: #f0f4f8;
                width: 8%;
                height: 100%;
                padding: 15px;

                .switch-outer {
                    width: 182px;
                    left: 22px;
                    height: 93px;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    position: relative;
                    border-radius: 49.5px;
                    z-index: 1;
                    .switch {
                        display: inline-block;
                        position: relative;
                        width: 130px;
                        height: 69.64px;
                        left: -16px;
                        top: 10px;
                        border-radius: 49.5px;
                        z-index: 2;
                        background: #7cbaa7;
                        box-shadow: none !important;
                        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .switch::before {
                        content: "";
                        position: absolute;
                        top: 9px;
                        left: 68px;
                        width: 49.6px;
                        height: 49.6px;
                        background: white;
                        border-radius: 50%;
                        transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                            background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    }
                    .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                    }
                    input:checked + .switch {
                        background: #7cbaa7;
                    }
                    input:checked + .switch::before {
                        left: 11px;
                        background: #fff;
                    }
                    input:checked + .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                    }
                }
                .mode-set {
                    position: relative;

                    top: -37px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                .social-set {
                    position: relative;

                    top: 39px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 11px;
                    text-align: center;
                    color: #202020;
                }
                .bottom-tabs {
                    color: #394649;
                    margin-top: 50px;
                    h6 {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 120%;
                        color: #192529;
                    }
                }

                .info-icon {
                    border: 3px solid #394649;
                    padding: 5px 11px 8px 14px;
                    font-size: 19px;
                    margin: 20px 0px;
                    color: #394649;

                    border-radius: 50px;
                }
                .fa-magnify {
                    font-size: 35px;
                    margin: 10px 0px 55px;
                    color: #394649;
                }
            }
            .content-hero {
                width: 92%;
                .content-text {
                    background: #f0f4f8;
                }
            }
        }

        .video-img {
            background-image: url(../../../../../images/video-img.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            /* margin: 60px; */
            width: 120px !important;
            height: 80.63px;
            border-radius: 10.5263px 0px 0px 10.5263px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            top: 25px;
            left: 241px;
        }
        .video-img img {
            width: 50px;
            height: 50px;
        }
        .over-flow {
            overflow: scroll;
            height: 440px;
        }
    
    }

    .design-screen-mobile {
        .content-text .video-section {
            display: block!important;
            .video-img{
                position: revert!important;
                
            }
        }
        .content-text .tab-himself-text p {
            overflow: auto;
            height: 371px!important;
            padding-right: 10px;
        }
        .content-text {
            .playlist-section {
                left: 87px;
                top: 58%;
                p {
                    display: none;
                }
            }
            .tab-himself-text {
                margin-top: 33px;
                overflow: revert !important;
                background: transparent !important;
                .playlist-section {
                    position: revert !important;
                }
                .video-section {
                    display: block !important;
                    position: revert !important;
                    margin-right: 0;
                    height: 18%;
                    padding: 7px;
                    width: 47%;
                    margin-top: 14px;
                    .video-img {
                        margin: 12px;

                        position: revert !important;
                    }
                }

            }
            .tab-content {
                height: 615px !important;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 15px;
            }
        }
        .content-text .tab-content {
            margin-top: revert;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            border-radius: 8px;
            .tab-himself-text {
                height: 100%;
                padding: 15px;
            }
            .video-img {
                background-image: url(../../../../../images/video-img.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                /* margin: 60px; */
                width: 120px !important;
                height: 80.63px;
                border-radius: 10.5263px 0px 0px 10.5263px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                top: 25px;
                left: 241px;
            }
            .video-img img {
                width: 50px;
                height: 50px;
            }
        }

        .hero-part {
            display: block !important;
            top: 448px;
            left: 15px;
            .glass-show {
                margin-top: 294px;
                width: 182px;
                position: relative;
                z-index: 4;
            }
        }
        .intro-oter {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            border-radius: 0px 15px 15px 0px;
            padding: 15px;
        }
        .intro-banner {
            background-image: url(../../../../../images/intro-img.png);
            width: 300px;
            height: 160px;
            border-radius: 10px;

            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            text-align: center;
            padding: 20px;
            h5 {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
            }
            h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 30px;
                line-height: 36px;

                color: #ffffff;
            }

            .intro-btn {
                font-weight: 400;

                font-size: 14px;
                line-height: 17px;
                color: #202020;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 20px;
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                border-radius: 20px;
                width: 250px;
                height: 37px;
                margin: 15px auto auto;
            }
        }
        .switch-mobile {
            display: block;
            position: absolute;
            .mode-set {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #ffffff;
                z-index: 1;
                bottom: -168px;
                position: absolute;
                transform: rotate(-90deg);
                z-index: 1;
            }
            .social-set {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 12px;
                color: #f0f4f8;
                z-index: 1;
                bottom: -289px;
                position: absolute;
                transform: rotate(-90deg);
            }
            .switch-border {
                width: 75px;
                height: 40.85px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                left: -10px;
                top: 246px;
                position: relative;
                border-radius: 49.5px;
                z-index: 31;
                .check-box {
                    transform: scale(2);
                }

                input[type="checkbox"] {
                    position: relative;
                    appearance: none;
                    width: 100px;
                    height: 50px;
                    background: #ccc;
                    border-radius: 50px;
                    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    transition: 0.4s;
                }

                input:checked[type="checkbox"] {
                    background: #7da6ff;
                }

                input[type="checkbox"]::after {
                    position: absolute;
                    content: "";
                    width: 22px;
                    height: 22px;
                    left: 50%;
                    top: 4px;
                    background: #fff;
                    border-radius: 50%;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                    transform: scale(1.1);
                    transition: 0.4s;
                }

                input:checked[type="checkbox"]::after {
                    left: 8px;
                }

                check-box {
                    display: inline-block;
                    position: relative;
                    width: 60px;
                    height: 30.64px;
                    left: 7px;
                    top: 5px;
                    box-shadow: none !important;
                    border-radius: 49.5px;
                    z-index: 2;
                    background: #7cbaa7;
                    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    vertical-align: middle;
                    cursor: pointer;
                }
                input[type="checkbox"] {
                    display: inline-block;
                    position: relative;
                    width: 60px;
                    height: 30.64px;
                    left: 7px;
                    top: 5px;
                    box-shadow: none !important;
                    border-radius: 49.5px;
                    z-index: 2;
                    background: #7cbaa7;
                    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    vertical-align: middle;
                    cursor: pointer;
                }

                input[type="checkbox"]::before {
                    content: "";

                    border-radius: 50%;

                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: 0.5s;
                }

                input[type="checkbox"]:checked::before {
                    transform: translateX(100%);
                    background: #fff;
                }

                input[type="checkbox"]:checked {
                    display: inline-block;
                    position: relative;
                    width: 60px;
                    height: 30.64px;
                    left: 7px;
                    top: 5px;
                    box-shadow: none !important;
                    border-radius: 49.5px;
                    z-index: 2;
                    background: #7cbaa7;
                    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
        }
        .hero-section-mue .content-img {
            width: 100% !important;
            right: 0;
            height: 75% !important;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            border-radius: 20px;
        }
        .hero-section {
            padding: 15px !important;
            margin: 0px !important;
        }
        .navbar-top .timeline li {
            width: 50px;
            height: 50px;
        }
        .glass-show {
            width: 148px;
            display: block;
        }

        .search-area .search-field {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            box-shadow: none!important;
            border-radius: 5px !important;

            width: 229px !important;
        }
        .img-logo {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            border-radius: 5px;
            padding: 10px;
        }
        .bookme-oterr .bookme-inner .hamburger-outer {
            .forms-inpt {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784);
            }
            .form-control {
                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.5) 3.01%,
                    rgba(255, 255, 255, 0.05) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784);
                box-shadow: none !important;
            }
        }
    
    }
    .bars-green {
        z-index: 3 !important;
        position: relative;
    }
    .bookme-oterr .bookme-inner {
        background: transparent !important;
        box-shadow: none !important;
    }
    .bookme-oterr .bookme-inner .forms-inpt .form-control {
        background: transparent !important;
    }

    .hamburger-outer {
        position: absolute;
        z-index: 3;
        padding: 40px 20px 40px;
        width: 400px;

        .close-btn {
            width: 48px !important;
            border: none;
            background-color: transparent !important;
            background-image: url(../../../../../images/close-batch.png) !important;
            background-repeat: no-repeat;

            height: 70px !important;
            font-size: 23px;
            position: absolute;
            right: 17px !important;
            top: 1px !important;
            font-size: 34px;
            color: grey !important;
            position: absolute;
            svg {
                margin-top: -34px;
                margin-left: 10px;
                font-size: 28px;
            }
        }
    }
    .bookme-oter .bookme-inner {
        background: transparent !important;
        box-shadow: none !important;
    }
    .bookme-oter .bookme-inner .forms-inpt .form-control {
        background: transparent !important;
    }
}

.screen-btn {
    cursor: pointer;
}