.blogpost-screen-mobile-3 {
    width: 480px;
    margin: 0 auto;
    background-color: #F0F4F8;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

    .blogpostdesktop-maincontent {
        background-color: #CBD5E0;
    }

    .white-box-position {
        top: -45px;

        .white-box {
            background-color: #F0F4F8;

            .white-box-content {
                .fashion {
                    font-size: 14px;
                    font-weight: 500;
                    font-family: 'inter';
                    line-height: 20px;
                }

                .fashion-heading {
                    font-size: 24px;
                    font-weight: 600;
                    font-family: 'inter';
                    line-height: 30px;
                }

                .fashion-paragraph {
                    font-size: 12px;
                    font-weight: 400;
                    font-family: 'inter';
                    line-height: 20px;
                }

                .read-more {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #304053;
                    font-family: 'inter';
                    text-decoration: underline;
                }
            }

        }

        .comment-share {
            p {
                font-size: 14px;
                font-weight: 600;
                font-family: 'inter';
                line-height: 22px;
                color: #202020;
            }
        }
    }

    .card-image-1 {
        img {
            width: 100% !important;
            height: 360px !important;
        }
    }

    .card-image-content {
        background-color: #7CBAA7;
        bottom: 33px;

        .fashion {
            font-size: 14px !important;
            font-weight: 500;
            font-family: 'inter';
            line-height: 20px;
        }

        .fashion-paragraph {
            font-size: 14px;
            font-weight: 400;
            font-family: 'inter';
            line-height: 22px;
        }
    }

    .bookmark-icon {
        top: 5px;
        right: 0px;

        span {
            background-color: #7CBAA7;
            padding: 5px;
        }
    }

    .card-content-bgcolor {
        background-color: #7CBAA7;
        position: relative;
        top: -27px;
        .fashion {
            font-size: 13px;
            font-weight: 500;
            line-height: 16px;
            font-family: 'inter';
        }

        .main-heading {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            font-family: 'inter';

        }

        .fashion-paragraph {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            font-family: 'inter';
        }

        .read-more {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            color: #304053;
            font-family: 'inter';
            text-decoration: underline;
        }
    }

    .icons {
        position: relative;

        .thumb {
            .thumb-icon {
                color: white;
                padding: 5px;
                font-size: 20px;
                border-radius: 50%;
                // right: 5px;
                z-index: 3;
                position: relative;
                background-color: #5890FF;
            }
            .heart-icon{
                color: white;
                padding: 5px;
                font-size: 20px;
                border-radius: 50%;
                z-index: 1;
                left: 3px;
                background-color: #F52C4C;
                position: relative;
            }
            .shock-icon{
                color: white;
                padding: 5px;
                font-size: 20px;
                border-radius: 50%;
                left: 6px;
                position: relative;
                background-color: #FED869;
            }
            span{
                font-size: 14px;
                font-weight: 600;
                font-family: 'inter';
                line-height: 17px;
            }
        }

        .comment-share {
            span {
                font-size: 14px;
                font-weight: 600;
                font-family: 'inter';
                line-height: 17px;
            }
        }
    }





}