.elevator_btn {
    .btn-bg.lg button {
        top: 15px;
        transform: rotate(12deg);
    }
}

.h-300 {
    height: 300px;
}

.printful_merch_account {
    h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
    }

    .three-bsiness .h3-border1 {
        padding-bottom: 30px;
    }

    .btn-bg.lg button {
        top: 15px;
        transform: rotate(12deg);
    }

    .w-75 {
        width: 75%;
    }

    .QR_label {
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        color: #545C81;
        margin-top: 4px;
    }

    .download_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 3px;

        .icon {
            color: #F0BF25;
        }
    }

    .QR_outer_div {
        display: flex;
        padding: 10px;
        width: 90px;
        height: 90px;
        // background: #EFF4F8;ss
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 5px;

        .QR_box {
            background-image: url("/images/QR.png");
            background-repeat: no-repeat;
            height: auto;
            width: 90px;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .input-group {
        button {
            // background: linear-gradient(349.94deg, #F0BF25 -8.14%, #E28D0E 119.35%);
            border-radius: 5px;
            height: auto;
            margin: 0;
            width: auto;
            // color: #fff;
            white-space: nowrap;
        }
    }

    .factorTab1 {

        &::before {
            content: url("../../../../images/vidBg_1.png");
            position: absolute;
            top: -50px;
            right: -32px;
            background: #eff4f8;
        }

        .VideoIcon {
            right: -28px;
        }
    }

    .toggle_btn_wrapper {
        background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
        box-shadow: -6px -6px 20px rgba(255, 255, 255, 0.8), 3px 3px 12px rgba(166, 180, 200, 0.4);
        border-radius: 3px;

        label {
            color: #0777AE;
        }

        .form-switch .form-check-input {
            width: 80px;
        }
    }

    .text_blue {
        color: #0777AE;
    }

    .rotate_btn {
        .btn-bg button {
            // background: linear-gradient(349.94deg, #F0BF25 -8.14%, #E28D0E 119.35%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
            border-radius: 5px;
            transform: rotate(5.3deg);
            top: 16px;
            // color: #fff;
        }
    }

    .w-35 {
        width: 35px;
    }

    .w-360 {
        width: 360px;
    }

    .date_area {
        display: flex;
        align-items: flex-start;
        padding: 10px 15px;
        background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
        box-shadow: -6px -6px 20px rgba(255, 255, 255, 0.8), 3px 3px 12px rgba(166, 180, 200, 0.4);
        border-radius: 3px;
        width: auto;

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2px;
            background: #EFF4F8;
            box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
            border-radius: 20px;
        }
    }

    .font-weight-semibold {
        font-weight: 600;
    }

    .f_20 {
        font-size: 20px;
    }
}

.dark {
    .amazon_affiliate_accrount {
        .toggle_btn_wrapper {
            background: transparent;
            box-shadow: -6px -6px 20px rgba(0, 0, 0, 0.8), 3px 3px 12px rgba(46, 44, 44, 0.99);
        }

        .date_area {
            background: transparent;
            box-shadow: -6px -6px 20px rgba(0, 0, 0, 0.8), 3px 3px 12px rgba(46, 44, 44, 0.99);

            .icon {
                background: transparent;
                box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.8), -2px -2px 3px rgba(46, 44, 44, 0.99);
            }
        }

        .rotate_btn {
            .btn-bg button {
                box-shadow: -12px -12px 20px rgba(0, 0, 0, 0.8), 6px 6px 24px rgba(46, 44, 44, 0.99);
            }
        }
    }
}