@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");



.Feature-on-mobile-2{

  .features-on-design-mobile-2{
    background-color: #cbd5e0;
  width: 400px;
  margin: auto;
  position: relative;
  height: 650px;
  .grey-box-mobile-2{
  background-color: #CBD5E0;
}
.image-circle-mobile-2{
      background-color: #ffffff;
      
      border-radius: 50%;
      display: flex;
      
      align-items: center;
      justify-content: center;
      margin: 2px -10px 0px 0px ;
      
      z-index: 90;
      img{
      margin: 0px;
  }  
}

    .image-usa {
      background-color: #1EA3FF ;
    
    
    }
    
    .box-view-mobile-2 {
      border-radius: 10px;
      background-color: black;
    }
    .box-view-mobile-2 p{
      font-size: 20px;
      font-weight: bolder;
color: white;
    }
    
    .box-container-mobile-2{
      margin-top: -45px;
    }

  }
  
}