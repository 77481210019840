@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');


.Feature-on-mobile-2 {

    .features-on-design-mobile-2 {
        background: #F0F4F8;
        box-shadow: 5px 4px 5px 1px rgba(67, 67, 67, 0.09);

        width: 400px;
        margin: auto;
        position: relative;
        height: 650px;

        .grey-box-mobile-2 {
            background: #F0F4F8;
            // box-shadow: 5px 4px 5px 1px rgba(67, 67, 67, 0.09);
            border-radius: 5px;
        }

        .image-circle-mobile-2 {
            background: #F0F4F8;
            // fill: #ffffff;
            box-shadow: 1px 3px 10px -4px rgba(0, 0, 0, 0.22);

            img {
                margin: 0px;
            }
        }

        .image-usa {
            background-color: #F0F4F8;


        }

        .box-view-mobile-2 {
            border-radius: 20px;
            border-radius: 40px 40px 0px 0px;
            background: var(--grey-8, #F0F4F8);
            /* Neo/Light/Outer/2 */
            box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
        }

        .box-view-mobile-2 p {
            font-size: 20px;
            font-weight: bolder;
            color: black;
        }

        .box-container-mobile-2 {
            margin-top: -45px;
        }
 

    }

}