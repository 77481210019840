$color_1: #202020;
$color_2: #5F6168;
$color_3: #3C3D40;

.event-timeline {
  position: relative;
  justify-content: center;
  text-align: center;

  &::before {
    content: "";
    height: 170px;
    width: 4px;
    position: absolute;
    background: $color_3;
  }

  .timeline-container {
    position: relative;
    width: 100%;

    .timeline-continue {
      position: relative;
      width: 100%;
      padding: 30px 0;

      .-mt_100 {
        margin-top: -100px;
      }

      .main-timeline {
        position: relative;
        overflow: hidden;
        padding: 0 15px;
        margin-left: 0;

        .timeline-left {
          padding: 0;

          .timeline {
            &::before {
              right: 0;
              left: auto;
            }

            &::after {
              right: -4px;
              left: auto;
              background: #808492;
            }

            .timeline-content {
              float: left;

              .event_detail {
                &::before {
                  content: "";
                  width: 33px;
                  height: 33px;
                  background: $color_3;
                  border-radius: 50%;
                  position: absolute;
                  right: -18px;
                  top: 43%;
                }
              }

              &::before {
                right: -10px;
                left: auto;
                background: #808492;
              }

              &::after {
                right: 14.6%;
                left: auto;
              }
            }
          }
        }

        .timeline-right {
          padding: 0 2px;

          .timeline {
            margin-top: 120px;

            .timeline-content {
              .event_detail {
                &::before {
                  content: "";
                  width: 33px;
                  height: 33px;
                  background: #808492;
                  border-radius: 50%;
                  position: absolute;
                  left: -18px;
                  top: 43%;
                }
              }
            }

            &:nth-child(3n) {
              margin-top: -50px;
            }
          }
        }

        .timeline {
          width: 100%;
          float: right;
          position: relative;
          z-index: 1;
          padding: 30px 0;

          &::before {
            content: "";
            position: absolute;
            width: 19%;
            height: 4px;
            background: #808492;
            left: 0;
            top: 50%;
            z-index: -1;
            transform: translateY(-50%);
          }

          .timeline-content {
            width: 76%;
            float: right;
            margin-right: 15px;
            background: #fff;
            text-align: left;

            &::before,
            &::after {
              content: "";
              width: 17px;
              height: 17px;
              border-radius: 50%;
              background: $color_3;
              position: absolute;
              top: 50%;
              left: -9px;
              z-index: 1;
              transform: translateY(-50%);
            }

            &::after {
              left: 19%;
              transform: translate(-50%, -50%);
            }

            .event_detail {
              width: 435px;
              height: 168px;

              .event_day {
                background: #7CBAA7;
                width: 25px;
                padding: 5px 10px 5px 10px;
                writing-mode: vertical-lr;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .event_date {
                width: 100px;
                height: 168px;
                padding: 40px 0 40px 0;
                background: #ffffff;
                display: flex;
                align-items: center;

                p {
                  span {
                    font-size: 56px;
                    line-height: 67.77px;
                    color: $color_1;
                  }

                  font-size: 14px;
                  font-weight: 400;
                  line-height: 16.94px;
                  color: $color_2;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 0;
                }
              }

              .event_description {
                width: 330px;
                height: 168px;
                padding: 20px 10px;
                gap: 10px;
                background: #F0F4F8;

                h2 {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 19.36px;
                  color: $color_1;
                }

                h6 {
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 14.52px;
                  color: $color_1;
                  width: 70%;
                }

                p {
                  color: $color_3;
                  font-size: 12px;
                  line-height: 19.2px;
                  font-weight: 400;
                }
              }

              .event_theme {
                font-size: 11px;
                line-height: 13.31px;
                font-weight: 400;
                color: $color_3;
                margin: 0;

                strong {
                  font-weight: 500;
                  color: $color_1;
                }
              }

              button {
                width: 105px;
                height: 35px;
                padding: 12px 14px 12px 14px;
                background: #7CBAA7;
                font-size: 13px;
                line-height: 15.73px;
                font-weight: 500;
                color: $color_1;
                border: none;
              }
            }


          }

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 4px;
            height: 60%;
            background: $color_3;
            left: -2px;
          }

          &::nth-child(even) {
            margin-bottom: 70px;
          }

          &::nth-child(2) {
            margin-top: 200;
          }

          &:nth-child(2n) {
            &::before {
              left: auto;
              right: 0;
            }
          }

          &:nth-child(2n) {
            &::after {
              right: -3px;
            }
          }

          // &:first-child,
          // &:last-child:nth-child(even) {
          //   margin: 0;
          // }
        }
      }
    }
  }

  .last_timeline .timeline {
    &::after {
      display: none;
    }
  }

  // .timeline .row.timeline-right,
  // .timeline .row.timeline-left .timeline-date {
  //   text-align: left;
  // }


}

.eventsTab {
  .event-timeline {
    &::before {
      top: 10px;
      height: 100px;
    }

    &::after {
      content: "";
      width: 17px;
      height: 17px;
      background: #3C3D40;
      border-radius: 50%;
      position: absolute;
      left: 49%;
      top: 9px;
    }

    .timeline-container {
      .timeline-continue {

        &::before {
          content: "";
          width: 33px;
          height: 33px;
          background: #3C3D40;
          border-radius: 50%;
          position: absolute;
          left: 48%;
          top: 80px;
        }

        .-mt_100 {
          margin-top: -95px;
        }

        .main-timeline {
          padding: 75px 30px 0;
          .line_left{
            &::after{
              content: "";
              position: absolute;
              top: 50%;
              width: 4px;
              height: 25%;
              background: $color_3;
              right: 51px;
            }
          }
          // .line_right{
          //   &::after{
          //     content: "";
          //     position: absolute;
          //     top: 50%;
          //     width: 4px;
          //     height: 60%;
          //     background: $color_3;
          //     left: 39px;
          //   }
          // }
          .timeline-left {
            width: 100%;

            .timeline {
              padding: 20px 0;

              &::before {
                left: 0;
                right: auto;
              }

              &::after {
                left: 9px;
                background: #3C3D40;
                right: auto;
              }

              .timeline-content {
                float: right;

                &::after {
                  left: 20%;
                  right: auto;
                }

                &::before {
                  left: -5px;
                  right: auto;
                  width: 33px;
                  height: 33px;
                  background: #3C3D40;
                }

                .event_detail {
                  width: 100%;

                  &::before {
                    display: none;
                    // left: 48%;
                    // right: auto;
                    // top: 7%;
                  }

                  .event_description {
                    width: 375px;
                  }

                  .event_date {
                    width: 120px;
                    justify-content: center;
                  }
                }
              }
            }
          }

          .timeline-right {
            width: 100%;

            .timeline {
              margin-top: 0px;
              padding: 20px 0 40px;

              &::before {
                right: 17px;
                left: auto;
              }

              &::after {
                right: 19px;
                left: auto;
                background: #3C3D40;
              }

              .timeline-content {
                float: left;

                &::after {
                  right: 20%;
                  left: auto;
                }

                &::before {
                  right: 5px;
                  left: auto;
                  width: 33px;
                  height: 33px;
                  background: #3C3D40;
                }

                .event_detail {
                  width: 100%;

                  &::before {
                    display: none;
                  }

                  .event_description {
                    width: 375px;
                  }

                  .event_date {
                    width: 120px;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.eventsMobile {
  .event-timeline {
    &::before {
      top: 10px;
      height: 100px;
    }

    &::after {
      content: "";
      width: 17px;
      height: 17px;
      background: #3C3D40;
      border-radius: 50%;
      position: absolute;
      left: 49%;
      top: 9px;
    }

    .timeline-container {
      .timeline-continue {

        &::before {
          content: "";
          width: 33px;
          height: 33px;
          background: #3C3D40;
          border-radius: 50%;
          position: absolute;
          left: 46%;
          top: 80px;
        }

        .-mt_100 {
          margin-top: -95px;
        }

        .main-timeline {
          padding: 75px 10px 0;
          .line_left{
            &::after{
              content: "";
              position: absolute;
              top: 50%;
              width: 4px;
              height: 25%;
              background: $color_3;
              right: 25px;
            }
          }
          // .line_right{
          //   &::after{
          //     content: "";
          //     position: absolute;
          //     top: 50%;
          //     width: 4px;
          //     height: 60%;
          //     background: $color_3;
          //     left: 39px;
          //   }
          // }
          .timeline-left {
            width: 100%;

            .timeline {
              padding: 20px 0;

              &::before {
                left: 0;
                right: auto;
              }

              &::after {
                left: 9px;
                background: #3C3D40;
                right: auto;
              }

              .timeline-content {
                float: right;
                margin-right: 0;

                &::after {
                  left: 20%;
                  right: auto;
                }

                &::before {
                  left: -5px;
                  right: auto;
                  width: 33px;
                  height: 33px;
                  background: #3C3D40;
                }

                .event_detail {
                  width: 100%;
                  height: 140px;

                  &::before {
                    display: none;
                    // left: 48%;
                    // right: auto;
                    // top: 7%;
                  }

                  .event_day{
                    padding: 4px;
                    font-size: 12px;
                    font-weight: 500;
                    width: 20px;
                  }
                  .event_description {
                    width: 375px;
                    height: 140px;
                    padding: 12px 5px;
                    h2{
                      font-size: 14px;
                      font-weight: 700;
                    }
                    h6{
                      width: 100%;
                      font-size: 10px;
                      margin-bottom: 0;
                    }
                    p{
                      // font-size: 10px;
                      margin-bottom: 5px;
                    }
                  }
                    .event_theme{
                      margin-bottom: 0;
                      font-size: 9px;
                    }
                  button{
                    width: 70px;
                    height: 25px;
                    padding: 7px;
                  }

                  .event_date {
                    width: 100px;
                    height: 140px;
                    justify-content: center;
                    p{
                      font-size: 12px;
                      span{
                      font-size: 34px;
                      line-height: 44px;
                    }
                  }
                  }
                }
              }
            }
          }

          .timeline-right {
            width: 100%;

            .timeline {
              margin-top: 0px;
              padding: 15px 0 40px;

              &::before {
                right: 17px;
                left: auto;
              }

              &::after {
                right: 13px;
                left: auto;
                background: #3C3D40;
              }

              .timeline-content {
                float: left;
                margin-right: 0;

                &::after {
                  right: 17%;
                  left: auto;
                }

                &::before {
                  right: 0px;
                  left: auto;
                  width: 33px;
                  height: 33px;
                  background: #3C3D40;
                }

                .event_detail {
                  width: 100%;
                  height: 140px;

                  &::before {
                    display: none;
                  }

                 
                  .event_day{
                    padding: 4px;
                    font-size: 12px;
                    font-weight: 500;
                    width: 20px;
                  }
                  .event_description {
                    width: 375px;
                    height: 140px;
                    padding: 12px 5px;
                    h2{
                      font-size: 14px;
                      font-weight: 700;
                    }
                    h6{
                      width: 100%;
                      font-size: 10px;
                      margin-bottom: 0;
                    }
                    p{
                      // font-size: 10px;
                      margin-bottom: 5px;
                    }
                  }
                    .event_theme{
                      margin-bottom: 0;
                      font-size: 9px;
                    }
                  button{
                    width: 70px;
                    height: 25px;
                    padding: 7px;
                  }

                  .event_date {
                    width: 100px;
                    height: 140px;
                    justify-content: center;
                    p{
                      font-size: 12px;
                      span{
                      font-size: 34px;
                      line-height: 44px;
                    }
                  }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}