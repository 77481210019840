@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.design-screen-tab-3{
  background-color: #CBD5E0;
}
.RegularTheme3Tab {
  // height: 1300px;
  height: auto;
  width: 100%;
  padding-top: 50px;
  margin: 0px auto;
  .top_heading{
    font-size: 30px;
    line-height: 36.31px;
    font-weight: 600;
    color: #202020;
    text-align: center;
  }
}
.RegularTheme3Tab .text-center-tab {
  width: 68%;
  margin: -15px auto 50px 11%;
  text-align: left;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.RegularTheme3Tab .position-nav-tab {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 75px;
  width: 80%;
  height: 150px;
  align-items: center;
}

.tab-main-section .main-container-tab {
  height: 635px !important;
  width: 99%;
  border-radius: 0px;
  // background: #202020;
  margin: 0px auto;
}

.RegularTheme3Tab .position-box-one-tab {
  width: 20%;
  // background: #7cbaa7;
  position: absolute;
  top: 42% !important;
  right: 38%;
}
// form position
.form-and-profile-tab .form-position-tab {
  width: 44%;
  height: 500px !important;
}
.form-and-profile-tab .side-position-box-tab {
  width: 44%;
  height: 500px !important;
  background-image: linear-gradient(
    to right,
    #bacdc7 0%,
    #bacdc7 20%,
    #f0f4f8 20%,
    #f0f4f8 100%
  ) !important;
}
.form-and-profile-tab {
  z-index: 2;
  background-color: none !important;
  position: absolute;
  top: 55%;
  // justify-content: space-around;
  gap: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 500px;
}
.RegularTheme3Tab .pattern-tab {
  position: absolute;
  top: 46.7%;
  left: 30.7%;
  z-index: 1;
}
