@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");

.Feature-on-desktop-2{

  
  .grey-box-desktop-2 {
    background-color: #CBD5E0;
  padding: 80px 80px 80px 80px;
  height: 800px;
}

.image-circle-2 {
  background-color: #ffffff;
  
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  
  align-items: center;
  justify-content: center;
  // margin: 10px 20px 0px 10px ;
  margin-right: -15px;
  box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.125);
  z-index: 90;
}
.image-usa {
  background-color: #1EA3FF ;


}

.box-view-2 {
  border-radius: 10px;
  background-color: black;
}
.box-view-2 p{
  font-size: 35px;
  font-weight: bolder;
  color: white;
}

.box-container-2{
  margin-top: -53px;
}
}