@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');

.featured-on-neumorphic-desktop-1 {


    .Feature-on-desktop-1 {

        .grey-box {
            padding: 120px 80px 120px 80px;
            border-radius: 5px;
            background: #F0F4F8;
            box-shadow: 5px 4px 5px 1px rgba(67, 67, 67, 0.09);
        }

        .image-circle {

            background: #F0F4F8;
            // fill: #ffffff;
            box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;

        }

        .image-circle span {
            background-color: #F0F4F8;
            padding: 10px 10px;
            border-radius: 50%;
            filter: drop-shadow(2px 2px 8px #B2BDCB) drop-shadow(-2px -2px 8px #FFF);
        }

        .image-circle::before {

            padding: 5px;
        }

        .image-usa {
            background-color: #F0F4F8;
        }

        .line-update {

            background: #EAA91B;
            width: 160%;
            z-index: 1;
        }

        .outer-circle-desktop-1 {
            width: 50%;
            height: 50%;
            border-radius: 400px;
            background: var(--grey-8, #F0F4F8);

            /* Neo/Light/Outer/3 */
            box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
        }
    }


}