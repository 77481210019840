.service-package-plan-desktop-1 {
    background-color: #CBD5E0;

    .toggle_button {
        background: #F0F4F8;
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: #7CBAA7 !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            background: transparent;
            color: #000000;
            text-align: center;
            border: none;
        }

    }

    .main_row {
        background: #B2BDCB;
        border-radius: 20px;
        height: 450px;
        z-index: 0;
        position: absolute;
        width: 90%;
    }

    .plan_content {
        display: flex;
        z-index: 20;

        h2 {
            color: #202020;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            text-align: center;
        }

        ul {
            li {
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: 17px;
                margin-bottom: 20px;

                .icon {
                    width: 1.25rem;
                    height: 1.25rem;
                    margin-right: 8px;
                }

                .text_yellow {
                    color: #FF7714;
                }
            }
        }

        .signin_btn {
            width: 110px;
            height: 110px;
            border: 1px solid #B2BDCB;
            filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn_inner {
                width: 100px;
                height: 97px;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    display: block;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .basic_btn_inner {
                background: #3D6861;
            }

            .professional_btn_inner {
                background: #304053;
            }

            .luxury_btn_inner {
                background: #3C3D40;
            }
        }
    }

    .plan_div {
        h1 {
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            padding-top: 10px;
        }

        .text_basic {
            color: #3D6861;
        }

        .text_professional {
            color: #304053;
        }

        .text_luxury {
            color: #3C3D40;
        }
    }

    .arrow_icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 8px;
    }

    .plan_outer {
        width: 250px;
        height: 710px;
        background: #F0F4F8;
        border-radius: 10px 10px 200px 200px;
    }

    .price {
        h2 {
            font-weight: 700;
            font-size: 48px;
            line-height: 40px;
            text-align: center;
            color: #FFFFFF;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #F0F4F8;
        }
    }

    .ribbon1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 20px);
        height: 120px;
        line-height: 50px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #3D6861;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid #314A4E;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            bottom: -10px;
            border-top: 10px solid #314A4E;
            border-right: 10px solid transparent;
        }
    }

    .ribbon_1 {
        display: block;
        width: calc(100% + 20px);
        height: 10px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #3D6861;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -10px;
            left: 0;
            border-bottom: 10px solid #314A4E;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: -10px;
            border-bottom: 10px solid #314A4E;
            border-right: 10px solid transparent;
        }
    }



    .ribbon2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 20px);
        height: 120px;
        line-height: 50px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #304053;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid #282F37;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            bottom: -10px;
            border-top: 10px solid #282F37;
            border-right: 10px solid transparent;
        }
    }

    .ribbon_2 {
        display: block;
        width: calc(100% + 20px);
        height: 10px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #304053;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -10px;
            left: 0;
            border-bottom: 10px solid #282F37;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: -10px;
            border-bottom: 10px solid #282F37;
            border-right: 10px solid transparent;
        }
    }



    .ribbon3 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 20px);
        height: 120px;
        line-height: 50px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #3C3D40;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid #202020;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            bottom: -10px;
            border-top: 10px solid #202020;
            border-right: 10px solid transparent;
        }
    }

    .ribbon_3 {
        display: block;
        width: calc(100% + 20px);
        height: 10px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #3C3D40;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -10px;
            left: 0;
            border-bottom: 10px solid #202020;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: -10px;
            border-bottom: 10px solid #202020;
            border-right: 10px solid transparent;
        }
    }


}