.subscription-screen-tab-1 {
  .subscription-tab-theme1 {
    background-color: #CBD5E0;
    padding: 250px 20px 150px;
    width: 768px;
    margin: 0 auto;
    &.regular-style {
      background-color: #CBD5E0;
    }
    &.neumorphic-style {
      background-color: #F0F4F8;
      box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
    }
    &.glassmorphic-style {
      background: linear-gradient(326deg, #9595B8 0%, #ECEDF5 46.35%, #C8C9D2 70.83%, #97999D 100%);
    }
    .subscription-tab-theme1-main-wrapper {
      background-color: #F0F4F8;
      padding: 250px 20px 100px;
      max-width: 1000px;
      width: 100%;
      margin: auto;
      position: relative;
      .subscribe-img-wrapper {
        background-color: white;
        padding: 42px 20px 30px 20px;
        text-align: center;
        position: absolute;
        top: -125px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        &.regular-style {
          background-color: white;
        }
        &.neumorphic-style {
          border-radius: 10px;
          background-color: #F0F4F8;
          box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
        }
        &.glassmorphic-style {
          background: linear-gradient(135deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.05) 100%);
          backdrop-filter: blur(10px);
          border-radius: 10px;
        }
        .subscribeUserIcon {
          width: 140px;
          height: 140px;
          border-radius: 50%;
        }
        .subscribeAroundTextImg {
          width: 181px;
          height: 100px;
          position: absolute;
          top: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
        .choose-position {
          font-size: 13px;
          line-height: 16px;
          color: #FFFFFF;
          display: block;
          background-color: #202020;
          padding: 10px 20px;
          margin: 0 0 0 0;
          font-weight: 400;
          &.regular-style {
            color: #FFFFFF;
            background-color: #202020;
          }
          &.neumorphic-style {
            color: #202020;
            background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
          }
          &.glassmorphic-style {
            color: #202020;
            background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
          }
        }
      }
      .regular-main-wrapper {
        position: relative;
        width: 100%;
        padding: 130px 0 0;
        .subscription-images-wrapper {
          position: relative;
          .images-main-wrapper {
            position: absolute;
            bottom: 7px;
            min-height: 350px;
            width: 100%;
            .images-wrapper {
              position: relative;
              padding: 0;
              margin: 0;
              min-height: 350px;
              width: 600px;
              margin: 0 auto;
              z-index: 1;
              img {
                width: 180px !important;
                object-fit: cover;
                position: absolute;
                bottom: 0;
                &:nth-child(1) {
                  left: 0;
                  bottom: 67px;
                }
                &:nth-child(2) {
                  bottom: 120px;
                  left: 115px;
                  z-index: 2;
                }
                &:nth-child(3) {
                  bottom: 120px;
                  right: 152px;
                  z-index: 4;
                }
                &:nth-child(4) {
                  right: 25px;
                  bottom: 67px;
                }
              }
            }
          }
          .sign-up-form {
            background-color: #202020;
            padding: 50px 90px;
            z-index: 2;
            position: relative;
            &.regular-style {
              background-color: #202020;
            }
            &.neumorphic-style {
              background-color: #F0F4F8;
              margin: 0 0 20px 0;
              box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
            }
            &.glassmorphic-style {
              margin: 0 0 20px 0;
              backdrop-filter: blur(10px);
              background: linear-gradient(135deg, rgba(255, 255, 255, 0.70) 0%, rgba(255, 255, 255, 0.02) 100%);
            }
            form {
              width: 100%;
              .form-group {
                display: flex;
                justify-content: center;
                align-items: center;
                &.regular-style {
                  input {
                    background-color: white;
                  }
                  button {
                    color: #3C3D40;
                    background: #7CBAA7;
                    &:hover {
                      background: #7CBAA7 !important;
                      color: #3C3D40;
                      border: none;
                    }
                  }
                }
                &.neumorphic-style {
                  input {
                    background-color: #F0F4F8;
                    box-shadow: 2px 2px 6px 0px #B2BDCB inset, -2px -2px 6px 0px #FFF inset;
                  }
                  button {
                    color: #3C3D40;
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  }
                }
                &.glassmorphic-style {
                  input {
                    border-radius: 4px;
                    backdrop-filter: blur(10px);
                    background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
                  }
                  button {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                    border-radius: 4px;
                  }
                }
                input {
                  background-color: white;
                  border-radius: 0;
                  height: 46px;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  &:focus {
                    box-shadow: none;
                    border: none;
                  }
                  &::placeholder {
                    color: #5F6168;
                  }
                }
                button {
                  background-color: #7CBAA7;
                  height: 46px;
                  padding: 15px 30px;
                  font-size: 13px;
                  color: #3C3D40;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: 0.52px;
                  text-transform: uppercase;
                  margin: 0 0 0 2px;
                  box-shadow: none;
                  border-radius: 0;
                  border: none;
                  &:hover {
                    background-color: #7CBAA7 !important;
                    color: #3C3D40;
                    border: none;
                  }
                }
              }
            }
          }
        }
        .on-off-buttons-wrapper {
          background-color: white;
          padding: 20px;
          &.regular-style {
            background-color: white;
          }
          &.neumorphic-style {
            background-color: #F0F4F8;
            box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
          }
          &.glassmorphic-style {
            background: linear-gradient(135deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
          }
          .btns-list {
            display: grid;
            padding: 0;
            min-width: 392px;
            grid-template-columns: auto auto auto;
            width: 400px;
            margin: 0 auto;
            gap: 30px 0;
            li {
              // font-family: $inter;
              text-align: center;
              .labelSwitch {
                position: relative;
                display: inline-block;
                font-size: inherit;
                input {
                  opacity: 0;
                  width: 0;
                  height: 0;
                }
                input:checked + .span {
                  background-color: #7CBAA7;
                }
                input:checked + .span:before {
                  transform: translateX(14px);
                  background-color: #D3FFB0;
                }
                .span {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: #B2BDCB;
                  transition: 0.3s;
                  border-radius: 30px;
                  width: 36px;
                  margin: 0 auto;
                  height: 21px;
                  &:before {
                    position: absolute;
                    content: "";
                    height: 16px;
                    width: 16px;
                    left: 3px;
                    bottom: 2.4px;
                    background-color: #F0F4F8;
                    border-radius: 50%;
                    transition: 0.3s;
                  }
                }
                strong {
                  display: block;
                  margin: 12px 0 0 0;
                  font-weight: 400;
                  color: #202020;
                  font-size: 14px;
                }
                &.regular-style {
                  .span {
                    background-color: #B2BDCB;
                    &:before {
                      background-color: #F0F4F8
                    }
                  }
                  input:checked + .span {
                    background-color: #7CBAA7;
                  }
                  input:checked + .span:before {
                    background-color: #D3FFB0;
                  }
                }
                &.neumorphic-style {
                  .span {
                    background: #E5E9ED;
                    background-image: linear-gradient(#4E4E57, #E5E9ED);
                    &:before {
                      background-color: #4E4E57;
                    }
                  }
                  input:checked + .span {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  }
                  input:checked + .span:before {
                    background-image: linear-gradient(#4E4E57, #E5E9ED);
                  }
                }
                &.glassmorphic-style {
                  .span {
                    background: #ffffff;
                    &:before {
                      background-color: #3C3D40;
                    }
                  }
                  input:checked + .span {
                    background: linear-gradient(180deg, #FACB30 0%, #FEF9C8 30.23%, #C49530 64.06%, #FFF8C3 100%);
                  }
                  input:checked + .span:before {
                    background-color: #3C3D40;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
