@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.position-box-one {
  width: 100%;
  background-color: var(--bg-secondary-light);
  padding: 20px;
  &.Regular-style {
    background: #7cbaa7;
  }
  &.Neumorphic-style {
    padding: 25px 40px;
    background: var(--bg-secondary-light);
    box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
    border-radius: 20px;

    &:before {
      content: "";
      position: absolute;
      padding-top: 10px;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border: 20px solid #eaedee;
      border-radius: 20px;
      box-shadow: -2px -2px 6px #f5f3f3, 2px 2px 6px #b2bdcb;
    }
  }
}
.pos-one {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
}
.pos-two {
  font-family: "Christmas Wish";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
