@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.diet-programs-main-mobile {
  padding: 40px 10px;
  display: block;
  .diet-programs-flex {
    display: block;
    background-color: #202020;
    padding: 5px;
    .diet-programs-left-section {
      width: 100%;
      padding: 15px;
    }
  }
}
