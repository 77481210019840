@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Norican&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hurricane&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Qwigley&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.TAB-10-form {
    .TabBtns ul li {
        position: relative;
        margin-left: 5%;
    }

    .TabBtns ul li:first-child {
        margin-left: 0;
    }

    .TabBtns ul li button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        height: 40px;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 40px;
    }

    .TabBtns ul li::before {
        content: "";
        position: absolute;
        background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%);
        box-shadow: inset -3px -3px 2px var(--shadow-light-50), inset 2px 2px 3px rgba(77, 89, 107, 0.15);
        border-radius: 5px;
        width: 60px;
        height: 9px;
        z-index: -1;
        top: 20px;
        left: -50px;
    }

    .TabBtns ul li:first-child:before {
        display: none;
    }

    .TabBtns ul li button.active {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        height: 40px;
        background: var(--goldenlinear)!important;
        color: #1C1C1C !important;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 40px;
    }

    .uploadSec ul li {
        width: 33.33%;
    }

    .TAB-8_1-form {
        .social_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
            box-shadow: inset -5px -5px 8px #FFFFFF, inset 4px 4px 8px #889FB6;
            border-radius: 100px;

            .img {
                height: 30px;
            }
        }

        .policy_dropdown {
            .dropdown_btn {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 315.55px;
                height: 43px;
                background: linear-gradient(349.94deg, #F0BF25 -8.14%, #E28D0E 119.35%);
                border-radius: 5px;
                box-shadow: none;

                span {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 15px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }

            .dropdown .dropArrow {
                margin-bottom: 1rem;
                height: auto;
            }

            .dropdown-menu {
                width: 850px;
                position: relative;
                z-index: 50;
                top: auto;
            }

            .btnArrow .dropArrow a::after {
                left: 50%;
                bottom: -4px;
                top: unset;
            }

            .yellow_btn {
                background: linear-gradient(349.94deg, #F0BF25 -8.14%, #E28D0E 119.35%);
                border-radius: 5px;
                height: auto;
                margin: 0;
                width: auto;
                color: #fff;
                white-space: nowrap;
            }

            .select {
                .country .css-13cymwt-control {
                    border-radius: 50px !important;
                }

                .bgArrow::before {
                    top: 19px;
                }
            }

            .check_box {
                width: 65%;
                margin: 0 auto;
                height: 42px;
                background: #E5E9ED;
                box-shadow: inset 0px -2.68402px 5.36804px #FFFFFF, inset 0px 2.68402px 4.02603px #A0B9D0;
                border-radius: 13.4201px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 120%;
                    color: #1C1C1C;
                }

                .check_icon {
                    background: #E5E9ED;
                    box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;

                    .icon {
                        fill: #69cb4c;
                    }
                }
            }

            .text_box {
                display: flex;
                align-items: flex-start;
                padding: 20px;
                width: 100%;
                background: #E5E9ED;
                box-shadow: inset -1.16214px -1.16214px 3.87381px #FFFFFF, inset 1.54952px 1.54952px 3.09904px #889FB6;
                border-radius: 7.74761px;

                ol {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 120%;
                    text-align: justify;
                    color: #7F838F;
                    list-style: decimal;

                    h6 {
                        font-weight: 400;
                        font-size: 14px;
                    }

                    li {
                        margin-left: 20px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .input-group {
        background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 5px;

        button {
            background: linear-gradient(349.94deg, #F0BF25 -8.14%, #E28D0E 119.35%);
            border-radius: 5px;
            height: auto;
            margin: 0;
            width: auto;
            color: #fff;
            white-space: nowrap;
        }
    }

    .h_30 {
        height: 30px;
    }

    .QR_label {
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        color: #545C81;
        margin-top: 4px;
    }

    .download_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 3px;

        .icon {
            color: #F0BF25;
        }
    }

    .QR_outer_div {
        display: flex;
        padding: 10px;
        width: 90px;
        height: 90px;
        // background: #EFF4F8;ss
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 5px;

        .QR_box {
            background-image: url("/images/QR.png");
            background-repeat: no-repeat;
            height: auto;
            width: 90px;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .all_QR_btn {
        padding: 12px 65px !important;
        background: linear-gradient(349.94deg, #F0BF25 -8.14%, #E28D0E 119.35%);
        box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
        border-radius: 5px;
        white-space: nowrap;
        width: auto;
        color: #fff;
        height: auto;
    }

    .social_icon_block {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 40px;
        height: 40px;
        background: var(--goldenlinear);
        border-radius: 25px;
    }

    .disclaimer {
        background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
        box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
        border-radius: 5px;
        padding: 20px;
        text-align: center;

        p {
            font-size: 14px;
        }
    }

    .btn-bg {
        color: #edb32b;
    }

    .btn-bg button {
        // color: #fff;
        top: 21px;
        transform: rotate(7deg);
    }
}

.Tab_settings {
    .border_b {
        width: 100%;
        height: 2px;
        background: #EFF4F8;
        box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
    }

    .yellow_icon {
        color: #DDA01A;
        width: 40px;
        height: 40px;
    }

    .section_wrapper {
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 4px;
        padding: 2rem;

        // .uploadSec ul li {
        //     width: auto;
        // }

        .text_editor {
            .dropdown-menu {
                li {
                    width: auto !important;
                }
            }
        }

        .uploadSec ul .li {
            width: 50%;
            padding: 0 5px;

            .icon {
                width: 30px;
                height: 30px;
                color: #4E4E57;
            }

            .p {
                font-size: 18px;
                box-shadow: unset;
                background: transparent;
            }

            ::-webkit-input-placeholder {
                text-align: center;
            }

            .pt_6 {
                padding-top: 6rem;
            }

            .br_20 {
                border-radius: 20px;
            }
        }

        .h_16 {
            height: 16rem;
        }

        .relative {
            position: relative;
        }

        .dropFormat {
            position: absolute;
            right: 1rem;
            top: .75rem;
        }

        .section_inner {
            background: #EFF4F8;
            box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
            border-radius: 10px;
            border: 2px solid #FEC83A;
            padding: 2rem;

            h4 {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                display: flex;
                align-items: center;
                color: #1C1C1C;
            }

            .arrow_icon {
                .icon {
                    width: 2rem;
                    height: 2rem;
                    color: #4E4E57;
                    text-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
                }
            }

            .cross_icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                background: #EFF4F8;
                box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
                border-radius: 30px;

                .icon {
                    width: 20px;
                    height: 20px;
                    color: #4E4E57;
                }
            }
        }
    }

    .check-button {
        .btn {
            background: var(--goldenlinear);
            box-shadow: 2px 2px 3px var(--shodow-drop-dark), -2px -2px 3px var(--shadow-light-50);
        }
    }

    .form-switch {
        justify-content: unset;

        label {
            h4 {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                display: flex;
                align-items: center;
                color: #1C1C1C;
            }

            p {
                font-size: 14px;
                line-height: 100%;
                letter-spacing: 0.01em;
                text-transform: capitalize;
                color: #545C81;
            }
        }
    }

    input+label {
        background: transparent;
        box-shadow: unset;
    }


}

.modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.mdl {
    button {
        width: 100%;
        height: 45px;
        background: #F1F4F9;
        box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #889FB6;
        border-radius: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-right: 30px;

        &:hover,
        &:focus,
        &:active {
            border-radius: 45px !important;
            background: transparent !important;
            box-shadow: -5px -5px 10px #FFFFFF, 5px 5px 10px #889FB6 !important;
        }

        .inner_btn {
            width: 26.08px;
            height: 25.43px;
            background: #F1F4F9;
            box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.2), 5px 5px 10px rgba(136, 159, 182, 0.2);
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn_1 {
                width: 13.39px;
                height: 13.06px;
                background: #F1F4F9;
                box-shadow: inset -5px -5px 10px #FFFFFF, inset 5px 5px 10px #889FB6;
                border-radius: 100px;
            }
        }

        .btn_arrow {
            position: absolute;
            background-image: url('../../../../images/down.png');
            width: 35px;
            height: 60px;
            display: inline-block;
            right: -20px;
            top: -8px;
        }

    }

}

.color_dropdown {
    .dropdown-menu.show {
        position: fixed;
        top: 0px !important;
        left: 0px !important;
        transform: translate(40px, 5px) !important;
        will-change: transform !important;
    }
}

.modal {
    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        border: none;
        // width: 370px;
        // height: 341px;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 10px;

        .modal-title {
            width: 100%;
        }

        .modal-header {
            width: 100%;
            padding: 0;
            padding-bottom: 10px;
        }

        .modal-body {
            width: 100%;
        }

        .btn-bg button {
            top: 12px;
            transform: rotate(7deg);
        }

    }
}

.setting_box {
    .uploadSec {
        ul {
            li {
                width: 50% !important;
            }
        }
    }
}

.mb-30 {
    margin-bottom: 30px;
}

.w_320 {
    width: 320px;
}

.button_modal {
    .modal-dialog {
        width: 320px;
    }

    .relative {
        position: relative;
    }

    .button_wrapper {
        .inner_btn {
            width: 26.08px;
            height: 25.43px;
            background: #F1F4F9;
            box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.2), 5px 5px 10px rgba(136, 159, 182, 0.2);
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn_1 {
                width: 13.39px;
                height: 13.06px;
                background: #F1F4F9;
                box-shadow: inset -5px -5px 10px #FFFFFF, inset 5px 5px 10px #889FB6;
                border-radius: 100px;
            }
        }

        span {
            font-weight: 600;
            font-size: 12px;
            // line-height: 190%;
            // letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #1C1C1C;
        }

        .button_1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            position: relative;
            padding: 13px 15px 13px 20px;
            width: 153px;
            height: 50px;
            background: #EFF4F8;
            box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
            border-radius: 30px;
        }

        .button_2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 13px 15px 13px 20px;
            width: 154px;
            height: 50px;
            background: #EFF4F8;
            box-shadow: inset -5px -5px 8px #FFFFFF, inset 4px 4px 8px #889FB6;
            border-radius: 30px;
        }

        .button_3_outer {
            display: flex;
            padding: 6px 8px;
            width: 161px;
            height: 50px;
            background: #EFF4F8;
            box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #889FB6;
            border-radius: 30px;

            .button_3 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 7px 10px 7px 15px;
                width: 145px;
                height: 38px;
                background: #EFF4F8;
                box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
                border-radius: 20px;

            }
        }

        .button_4_outer {
            display: flex;
            padding: 6px 8px;
            width: 161px;
            height: 50px;
            background: #EFF4F8;
            box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #889FB6;
            border-radius: 30px;

            .button_4 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 7px 10px 7px 15px;
                width: 145px;
                height: 38px;
                background: #EFF4F8;
                box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #889FB6;
                border-radius: 20px;

            }
        }

        .button_5 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 15px 13px 20px;
            width: 155px;
            height: 50px;
            background: #EFF4F8;
            border: 2px solid #37B3F9;
            box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
            border-radius: 30px;
        }

        .button_6 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 15px 13px 20px;
            width: 155px;
            height: 50px;
            background: #EFF4F8;
            border: 2px solid #FF8F6E;
            box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
            border-radius: 30px;
        }

        .button_7 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 15px 13px 20px;
            width: 155px;
            height: 50px;
            background: #EFF4F8;
            border: 2px solid #5C6EFD;
            box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
            border-radius: 30px;
            position: relative;

            .btn_border {
                position: absolute;
                top: -8px;
                margin-left: 1rem;
            }

            .btn_border_reverse {
                rotate: 180deg;
                position: absolute;
                bottom: -8px;
                margin-left: 1rem;
            }
        }

        .button_8 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 15px 13px 20px;
            width: 155px;
            height: 50px;
            background: #CBA5A8;
            border-radius: 30px;
            position: relative;
            z-index: 10;
        }

        .btn_8_after {
            width: 30px;
            height: 50px;
            background: #F1F4F9;
            border: 3px solid #CBA5A8;
            border-radius: 45px;
            position: absolute;
            right: -1rem;
            z-index: 0;
        }

        .button_9 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 15px 13px 20px;
            width: 155px;
            height: 50px;
            background: #EFF4F8;
            border: 2px dashed #7F838F;
            box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
            border-radius: 30px;
        }

        .ml_2 {
            margin-left: 2.75rem !important;
        }
    }
}

// .modal-header {
//     .btn-close {
//         display: none;
//     }
// }

.text_alignment_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    background: #EFF4F8;
    box-shadow: 4px 4px 8px #b7c7d7, -4px -4px 8px #ffffffb3;
    // box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
    border-radius: 20px;
    margin-left: 10px;
}



.text_icon {
    width: 26px;
    height: 26px;
    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
    border-radius: 45px;

    span {
        color: #C49530;
        text-decoration: 2px underline;
    }
}

.w-220 {
    width: 220px;
}

.w-350 {
    width: 350px;
}

.btn-bg1 button {
    color: #fff;
    top: 6px;
    transform: rotate(7deg);
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}

.btn-bg {
    color: #DDA01A;
    border: 1px solid #F2E6CF;
}

.uploadSec.widsec {
    li {
        width: 33.33% !important;
    }
}


.dark .TAB-10-form {
    .TabBtns ul li button {
        color: #fff;
        background: #1c1c1c;
        box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
        // box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8), -4px -4px 8px rgba(46, 44, 44, 0.99);
    }

    .TabBtns ul li::before {
        box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99),
            inset 6px 9px 16px rgba(0, 0, 0, 0.8);
    }

    .Tab_settings {
        .section_wrapper {
            color: #fff;
            background: #1c1c1c;
            box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;

            .section_inner {
                color: #fff;
                background: #1c1c1c;
                box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;

                .border_b {
                    border-bottom: 2px solid #1c1c1c;
                    background: #1c1c1c;
                    text-shadow: 1px 1px 1px #2E2C2C, -1px -1px 1px #000;
                }

                .cross_icon {
                    background: #1c1c1c;
                    box-shadow: 2px 2px 3px #2E2C2C, -2px -2px 3px #000;

                    .icon {
                        color: #EFF4F8;
                    }
                }
            }
        }
    }



    .primarybtn {
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        width: 198px;
        height: 47px;
    }

    .genbtn {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.05em;
        width: 135px;
        padding: 0;
        height: 57px;
        color: #FFFFFF;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 15px;
    }

    .btnArrow2 .dropArrow a::after {
        top: 4px !important;
    }

    .bioSec {
        textarea {
            border-radius: 0px 0px 30px 30px !important;
        }
    }

    .inputwid {
        input {
            width: 80% !important;
        }
    }
}

.mbltop {
    background: #2C2C2F;
    padding: 11px 4px;
    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    .mblbtm {
        background: #060606;
        padding: 5px 12px;
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.2);
        border-radius: 12px;

        .topsec {
            background: #D8D8D8;
            height: 24px;
            border-radius: 4px 4px 0px 0px;
            display: inline-block;
        }

        .mdlsec {
            width: 100%;
            background: url("/images/img51.png") no-repeat;
            background-size: 100% 475px;
            display: inline-block;
            // height: 475px;
            padding: 25px 20px 0 27px;

            .imgsec {
                width: 271px;
                height: 292px;
                display: inline-block;
                padding: 29px 27px;
                margin-top: -20px;
                z-index: 1;
            }
        }

        .mdlsec1 {
            width: 100%;
            background: url("/images/img51s.png") no-repeat;
            background-size: 100% 475px;
            display: inline-block;
            // height: 475px;
            padding: 25px 20px 0 27px;

            .imgsec {
                width: 271px;
                height: 292px;
                display: inline-block;
                padding: 29px 27px;
                margin-top: -20px;
                z-index: 1;
            }
        }

        .bmsec {
            width: 100%;
            display: inline-block;
            height: auto;
            border-radius: 146px 146px 0px 0px;

            button.sm {
                width: 141px;
                height: 28px;
                border-radius: 10px;
            }

            h6 {
                height: 29px;
                width: 114px;
                border-radius: 7.2501px;
                display: inline-block;
                font-size: 10px;
                line-height: 29px;
            }

            button.lg {
                width: 164px;
                height: 45px;
                text-transform: uppercase;

            }
        }
    }
}

.border-4 {
    border-width: 4px;
    border-style: solid;
}

.biosetp10 {
    .navbtn {
        font-size: 9px;
        line-height: 10px;
        height: 84px;
        width: 70px;
        padding: 7px 5px;
        border: 0px solid #000;
        margin: 0 1.5px;
        border-radius: 5px;

        span {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--page-background);
        }
    }

    .active {
        span {
            border: 1px solid var(--primary);
        }
    }

    .textsec {
        border-radius: 8px;
        border: 1px solid var(--primary);
        width: 100%;
        height: 330px;
        padding-top: 70px;
        margin-top: -18px;
        margin-bottom: 23px;

        h5 {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
        }

        p {
            font-size: 11px;
            line-height: 18px;
        }

        .overflow-overlay {
            overflow-y: overlay;
        }
    }
}

.btmarea {
    position: relative;

    button {
        position: relative;
        width: 202px;
        height: 50px;
        padding: 0px 10px 0 25px;
        border-radius: 45px;
        z-index: 1;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        z-index: 1;
    }

    &::after {
        content: "";
        width: 46px;
        height: 77px;
        position: absolute;
        right: -15px;
        top: -10px;
        background: url('../../../../images/btnline.png') no-repeat;
        z-index: 0;
    }
}

.socialmbl {
    a {
        background: #1C1C1C;
        box-shadow: inset -5px -5px 10px rgba(255, 255, 255, 0.2), inset 5px 5px 10px #000000;
        border-radius: 50px;
        width: 36px;
        height: 36px;
        margin: 0 4.5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.goldbr {
    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
    box-shadow: inset -3px -3px 10px rgba(255, 255, 255, 0.3), inset 4px 4px 8px #9D7718;
    border-radius: 30px 30px 0px 0px;
    height: 197px;
    padding: 20px 17px 15px;

    // overflow: overlay;
    .goldiner {
        overflow: overlay;
        height: 100%;
        width: 100%;
        padding: 0 15px;
    }

    h4 {
        font-weight: 800;
        font-size: 20px;
        line-height: 20px;
    }

    p {
        font-weight: 500;
        font-size: 12px;
        line-height: 21px !important;
        letter-spacing: 0.01em;
    }
}

.goldInput {
    height: 47px;

    input {
        font-size: 12px;
        font-weight: 500;

        &:-ms-input-placeholder {
            font-size: 12px;
        }
    }

    &:-ms-input-placeholder {
        font-size: 12px;
    }

    button {
        font-size: 12px;
        font-weight: 500;
        border-left: 5px solid #a0a1a56b !important;
        border: 0px solid #000;

        svg {
            width: 30px;
            height: 30px;
        }
    }
}

.goldbtn {
    button {
        height: 47px;
        font-size: 15px;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        box-shadow: -3px -3px 10px var(--shadow-light), 4px 4px 8px var(--shadow-light-50);
        margin-bottom: 12px;
        padding-left: 15px;
        position: relative;

        svg {
            position: absolute;
            left: 15px;
            top: 8px;
        }
    }
}

.mblBtmSec {
    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
    box-shadow: inset -3px -3px 10px rgba(255, 255, 255, 0.3), inset 4px 4px 8px #9D7718;
    display: inline-block;
    height: 57px;
    padding: 0 25px;

    a {
        font-size: 8px;
        line-height: 9px;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            margin-bottom: 8px;
        }
    }
}

.socailtab2 {
    span {
        width: 44%;
        height: 126px;
        margin: 8px;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
            width: 30px;
            height: 30px;
            margin-bottom: 10px;
        }
    }
}

.golden {

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
        border-radius: 10px;
        background: var(--page-background);
        padding: 3px 2px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 1);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
    }
}

.silver {
    // background: #D8D8D8;

    .goldbr {
        background: linear-gradient(180deg, #808080 -18%, #EBEBEB 22.5%, #A7A7A7 67.84%, #E8E8E8 116%);
        box-shadow: inset -3px -3px 10px rgba(255, 255, 255, 0.3), inset 4px 4px 8px #939291;
    }

    .goldbtn {
        button {
            background: linear-gradient(180deg, #808080 -18%, #EBEBEB 22.5%, #A7A7A7 67.84%, #E8E8E8 116%);
            box-shadow: -6px -6px 15px var(--shadow-light), 6px 6px 15px var(--shadow-light-50);
        }
    }

    .mblBtmSec {
        background: linear-gradient(180deg, #808080 -18%, #EBEBEB 22.5%, #A7A7A7 67.84%, #E8E8E8 116%);
        box-shadow: inset -3px -3px 10px rgba(255, 255, 255, 0.3), inset 4px 4px 8px #939291;
    }

    .bt-primary {
        background: linear-gradient(180deg, #808080 -18%, #EBEBEB 22.5%, #A7A7A7 67.84%, #E8E8E8 116%);
    }

    .btmarea {
        &::after {
            background: url('../../../../images/btnline1.png') no-repeat;
        }
    }

    .biosetp10 .textsec {
        background: #F0F4F8;
        box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset -1px -1px 2px #FFFFFF, inset 1px 1px 2px rgba(178, 189, 203, 0.9);
        border-radius: 10px;
        background: var(--page-background);
        padding: 3px 2px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #808080 -18%, #EBEBEB 22.5%, #A7A7A7 67.84%, #E8E8E8 116%);
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 1);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(180deg, #808080 -18%, #EBEBEB 22.5%, #A7A7A7 67.84%, #E8E8E8 116%);
    }

    .bmsec {
        width: 148px;
        display: inline-block;
        height: 148px;
        border-radius: 50%;
    }

    .border-secondary1 {
        border-color: #D8D8D8!important;
    }
    .biosetp10 {    
        .active {
            span {
                border: 1px solid #808080;
            }
        }
    }
}

.imgBG{
    background: url("/images/img52.png") no-repeat;
    background-size: contain;
    background-color: #D8D8D8!important;
}
.imgBG1{
    background: url("/images/img54.png") no-repeat;
    background-size: contain;
    padding-top: 70px;
}
.imgBG2{
    background: url("/images/img55.png") no-repeat;
    background-size: 315px 1190px;
    background-position: top center;
    padding-top: 100px;
}
.imgBG3{
    background: url("/images/img58.png") no-repeat;
    background-size: 100% 500px;
    padding-top: 80px;
}
.imgBG4{
    background: url("/images/img59.png") no-repeat;
    background-size: contain;
    background-position:top center;
    padding-top: 260px;
}
.imgBG5{
    background: url("/images/img59s.png") no-repeat;
    background-size: contain;
    background-position:top center;
    padding-top: 260px;
}
.imgBG6{
    background: url("/images/img60.png") no-repeat;
    background-size: contain;
    background-position:top center;
    padding-top: 120px;
}
.secstyle {
    h5.h5{
        width: 264px;
        height: 67px;
        border-radius: 4px;
        font-weight: 700;
        font-size: 32px;
        line-height:40px;
        text-transform: capitalize;
        background: #D8D8D8;
    }
    button.lrg{
        width: 257px;
        height: 38px;
        border-radius: 40px;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
    }
}
.imgsec1 {
    width: 185px;
    height: 185px;
    display: inline-block;
    padding: 18px;
    z-index: 1;
    margin-bottom: 67px;
}
.secstyle1 {
    .h5{
        width: 114px;
        height: 29px;
        border-radius: 7.2501px;
        font-weight: 500;
        font-size: 10px;
        line-height:18px;
        letter-spacing: 0.01em;
    }
    button.lrg{
        width: 141px;
        height: 28px;
        border-radius: 40px;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        text-transform: uppercase;
    }
    hr{
        width: 190px;
        border-top: 2px solid;
    }
    .imgsec1 {
        margin-bottom: 135px;
    }
}
.secstyle2 {
    .h5{
        width: 141px;
        height: 29px;
        border-radius: 7.2501px;
        font-weight: 800;
        font-size: 12px;
        line-height:18px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
    }
    p{
        font-size: 12px;
        line-height:21px!important;
        font-weight: 500;
    }
    button{
        color: #000;
    }
    button.lrg{
        width: 141px;
        height: 28px;
        border-radius: 40px;
        font-weight: 700;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.01em;
        color: #545C81;
    }
}
.imgsec2 {
    width: 278px;
    height: 256px;
    display: inline-block;
    padding: 12px 13px;
    z-index: 1;
    margin-bottom: 18px;
    border-radius: 0px 84px 0px 0px;
    img {
        border-radius: 0px 78px 0px 0px;
    }
}
.imgsec3 {
    width: 145px;
    height: 145px;
    display: inline-block;
    padding: 15px;
    z-index: 1;
    margin-bottom: 67px;
    img{
        width: 118px;
        height: 118px;
    }
}
.imgsec3s{
    margin: -90px auto;
}
.socialmbl1 {
    a {
        &:nth-child(2), &:nth-child(5){
            margin-top: 24px;
        }
        &:nth-child(3), &:nth-child(4){
            margin-top: 34px;
        }
    }
}
.secstyle3 {
    h5{
        width: auto;
        height: 29px;
        border-radius: 7.2501px;
        font-weight: 700;
        font-size: 36px;
        line-height:48px;
        letter-spacing: 0.01em;
    }
    button{
        color: #000;
    }
    button.lrg{
        width: 141px;
        height: 28px;
        border-radius: 40px;
        font-weight: 700;
        font-size: 10px;
        line-height: 10px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.01em;
        color: #545C81;
    }
}
.style4{
    background: url('/images/img62.png') no-repeat;
    background-size: contain;
    width: 249px;
    height: 81px;
    margin-top: -49px;
    h4{
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
        color: #1C1C1C;
        margin: 0;
    }
    h6{
        height: auto!important;
        margin: 0;
        line-height: 10px!important;
    }
}
.style5{
    background: url('/images/img62s.png') no-repeat!important;
}
.solidline {
    width: 195px;
    height: 20px;
    border-radius: 0 0 30px 30px;
}
.imgdiv {
    width: 148px;
    height:41px ;
    left: 24px;
    bottom:54px;
    h5{
        line-height: 17px;
        margin: 0;
        height: auto!important;
    }
    h6{
        line-height: 10px;
        margin: 0;
        height: auto!important;
    }
}
.imgdiv1 {
    width: 290px;
    height: 158px;
    left:-13px;
    padding:15px 39px 0;
    top:0px;
    margin-top: -125px;
    margin-bottom: 30px;
    background: url('/images/img64.png') no-repeat!important;
    background-size: cover;
    h5{
        line-height: 25px;
        margin: 0 0 12px;
        height: auto!important;
        font-weight: 800;
        font-size: 20px;
    }
    h6{
        line-height: 10px;
        margin: 0 0 16px;
        height: auto!important;
    }
}
.imgdiv2 {
    background: url('/images/img64s.png') no-repeat!important;
}
.mt-115{
    margin-top: -115px!important;
}
.style6{
    h4{
        font-weight: 500;
        font-size: 9.53702px;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.01em;
        // color: #545C81;
        width: 114px;
        height: 29px;
        border-radius: 7.2501px 7.2501px 0px 0px;
    }
    h6{
        width: 178px!important;
        height: 28px!important;
        border-radius: 5px!important;
        font-weight: 500;
        font-size: 9.53702px!important;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        // color: #545C81;
    }
}
.mt-8{
    margin-top: 240px;
}
.imgsec4 {
    width: 106px;
    height: 106px;
    display: inline-block;
    padding: 10px;
    z-index: 1;
    img{
        width: 84px;
        height: 84px;
    }
}
.style7{
    h4{
        font-weight: 800;
        font-size: 20px;
        line-height: 123.5%;
        // color: #1C1C1C;
        margin: 0;
    }
    h6{
        font-weight: 500;
        font-size: 9.53702px!important;
        line-height: 100%!important;
        letter-spacing: 0.01em;
        // color: #545C81;
        margin: 0;
        
    }
}
.sm-social {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.dark {
    .biosetp10 .navbtn span{
        color: #fff!important;
    }
    .silver .border-secondary1 {
        background-color: var(--page-background);
        box-shadow: inset -5px -5px 8px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light)!important;
    }
    .imgBG{
        background: url("/images/img52s.png") no-repeat;
        color: #000!important;
        .goldbtn button, .silver .goldbtn button{
            box-shadow: 4px 4px 8px #a0b9d0, -4px -4px 8px #ffffffb3;
        }
        .silvericon, .textsec {
            color: #fff;
        }
    }
    .imgBG1{
        background: url("/images/img54s.png") no-repeat;
        .mblBtmSec, .goldiner {
            color: #000;
        }
        .mblBtmSec a{
            color: #000;
        }
    }
    .imgBG3{
        background: url("/images/img58s.png") no-repeat;
    }
    .goldiner {
        color: #000;
    }
    .imgBG4{
        background: url("/images/img59ss.png") no-repeat;
    }
    .shadow-3{
        box-shadow: 0px 0px 0px 0px #000;
    }
}