@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

///Regular Version Theme 2 
.regular4-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbd5e0;
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular4_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;


        .profile-div {
            width: 492px;
            height: 434px;
            position: relative;
            padding: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask-img-div {
                width: 400px;
                height: 434px;

                img {
                    height: 434px;
                    width: 400px;
                    background-size: cover;
                }
            }

            .inner-circle-div {
                height: 315px;
                width: 315px;
                background-color: #304053;
                border-radius: 50%;
                border: 3px solid #FFFFFF;
                box-sizing: border-box;
                position: absolute;
                top: 14%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .inverted-comma {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    .left-comma-div {
                        justify-self: flex-start;
                    }

                }
            }

            .avatar-div {
                width: 280px;
                height: 280Px;
                position: absolute;
                top: 42%;

                img {
                    width: 280px;
                    height: 280px;
                }

            }

            .rating-stars-div {
                position: absolute;
                top: 118%;
                left: 40%;

            }


            .profile-detail-div {

                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 380px;
                height: 178px;
                background: rgba(46, 46, 46, 0.9);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                padding: 20px;
                transform: translateY(-50px);
                font-family: Inter;


                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0px;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

            .profile-detail-div::after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-top-color: rgba(46, 46, 46, 0.9);
                border-bottom: 0;
                border-left: 0;

            }

        }

        .see-my-wall-div {
            margin: 0px;
            padding: 0px;
            display: flex;
            position: absolute;
            top: 78%;
            left: 36%;

            p {
                color: white;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 1);
                color: rgba(32, 32, 32, 1);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}

.regular4-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbd5e0;
    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular4_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;


        .profile-div {
            width: 492px;
            height: 434px;
            position: relative;
            padding: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask-img-div {
                width: 400px;
                height: 434px;

                img {
                    height: 434px;
                    width: 400px;
                    background-size: cover;
                }
            }

            .inner-circle-div {
                height: 315px;
                width: 315px;
                background-color: #304053;
                border-radius: 50%;
                border: 3px solid #FFFFFF;
                box-sizing: border-box;
                position: absolute;
                top: 14%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .inverted-comma {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    .left-comma-div {
                        justify-self: flex-start;
                    }

                }
            }

            .avatar-div {
                width: 280px;
                height: 280Px;
                position: absolute;
                top: 42%;

                img {
                    width: 280px;
                    height: 280px;
                }

            }

            .rating-stars-div {
                position: absolute;
                top: 118%;
                left: 40%;

            }


            .profile-detail-div {

                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 380px;
                height: 178px;
                background: rgba(46, 46, 46, 0.9);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                padding: 20px;
                transform: translateY(-50px);
                font-family: Inter;


                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0px;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

            .profile-detail-div::after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-top-color: rgba(46, 46, 46, 0.9);
                border-bottom: 0;
                border-left: 0;

            }

        }

        .see-my-wall-div {
            margin: 0px;
            padding: 0px;
            display: flex;
            position: absolute;
            top: 78%;
            left: 36%;

            p {
                color: white;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 1);
                color: rgba(32, 32, 32, 1);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}

.regular4-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular4_mobile_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-bottom: 40px;


        .profile-div {
            width: 299.51px;
            height: 333.1px;
            position: relative;
            padding: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            transform: translateY(-130px);

            .mask-img-div {
                width: 299.51px;
                height: 333.1px;

                img {
                    height: 333.1px;
                    width: 299.51px;
                    background-size: cover;
                }
            }

            .inner-circle-div {
                height: 230px;
                width: 230px;
                background-color: #304053;
                border-radius: 50%;
                border: 3px solid #FFFFFF;
                box-sizing: border-box;
                position: absolute;
                top: 3%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .inverted-comma {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    .left-comma-div {
                        justify-self: flex-start;
                    }

                }
            }

            .avatar-div {
                width: 210px;
                height: 210Px;
                position: absolute;
                top: 32%;

                img {
                    width: 210px;
                    height: 210px;
                }

            }

            .rating-stars-div {
                position: absolute;
                top: 127%;
                left: 36%;

            }


            .profile-detail-div {

                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 279px;
                height: 194px;
                background: rgba(46, 46, 46, 0.9);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                padding: 20px;
                transform: translateY(-50px);
                font-family: Inter;


                h1 {
                    font-size: 14px;
                    font-weight: 600;

                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0px;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 234px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

            .profile-detail-div::after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-top-color: rgba(46, 46, 46, 0.9);
                border-bottom: 0;
                border-left: 0;

            }

        }

        .see-my-wall-div {
            margin: 0px;
            padding: 0px;
            display: flex;
            position: absolute;
            top: 83%;
            left: 43%;

            p {
                color: white;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 1);
                color: rgba(32, 32, 32, 1);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}

/////////////////////////////////////////////////////////////
///Neumorphic Version Theme 2 
.neumorphic4-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F4F8;
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic4_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;


        .profile-div {
            width: 492px;
            height: 434px;
            position: relative;
            padding: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask-img-div {
                width: 400px;
                height: 434px;

                img {
                    height: 434px;
                    width: 400px;
                    background-size: cover;
                }
            }

            .inner-circle-div {
                height: 315px;
                width: 315px;
                background-color: #F0F4F8;
                border-radius: 50%;
                border: 3px solid #FACB30;
                box-sizing: border-box;
                position: absolute;
                top: 7%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .inverted-comma {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    .left-comma-div {
                        justify-self: flex-start;
                    }

                }
            }

            .avatar-div {
                width: 280px;
                height: 280Px;
                position: absolute;
                top: 39%;

                img {
                    width: 280px;
                    height: 280px;
                }

            }

            .rating-stars-div {
                position: absolute;
                top: 118%;
                left: 40%;

            }


            .profile-detail-div {

                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 380px;
                height: 178px;
                background: rgba(255, 255, 255, 0.9);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                padding: 20px;
                transform: translateY(-50px);
                font-family: Inter;
                color: #202020;


                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0px;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

            .profile-detail-div::after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-top-color: rgba(255, 255, 255, 0.9);
                border-bottom: 0;
                border-left: 0;

            }

        }

        .see-my-wall-div {
            margin: 0px;
            padding: 0px;
            display: flex;
            position: absolute;
            top: 78%;
            left: 36%;

            p {
                color: #202020;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 0.9);
                color: rgba(32, 32, 32, 1);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}

.neumorphic4-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F4F8;
    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic4_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;


        .profile-div {
            width: 492px;
            height: 434px;
            position: relative;
            padding: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask-img-div {
                width: 400px;
                height: 434px;

                img {
                    height: 434px;
                    width: 400px;
                    background-size: cover;
                }
            }

            .inner-circle-div {
                height: 315px;
                width: 315px;
                background-color: #F0F4F8;
                border-radius: 50%;
                border: 3px solid #FACB30;
                box-sizing: border-box;
                position: absolute;
                top: 7%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .inverted-comma {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    .left-comma-div {
                        justify-self: flex-start;
                    }

                }
            }

            .avatar-div {
                width: 280px;
                height: 280Px;
                position: absolute;
                top: 39%;

                img {
                    width: 280px;
                    height: 280px;
                }

            }

            .rating-stars-div {
                position: absolute;
                top: 118%;
                left: 40%;

            }


            .profile-detail-div {

                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 380px;
                height: 178px;
                background: rgba(255, 255, 255, 0.9);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                padding: 20px;
                transform: translateY(-50px);
                font-family: Inter;
                color: #202020;


                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0px;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

            .profile-detail-div::after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-top-color: rgba(255, 255, 255, 0.9);
                border-bottom: 0;
                border-left: 0;

            }

        }

        .see-my-wall-div {
            margin: 0px;
            padding: 0px;
            display: flex;
            position: absolute;
            top: 78%;
            left: 36%;

            p {
                color: #202020;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 0.9);
                color: rgba(32, 32, 32, 1);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}

.neumorphic4-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic4_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-bottom: 40px;


        .profile-div {
            width: 299.51px;
            height: 333.1px;
            position: relative;
            padding: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask-img-div {
                width: 299.51px;
                height: 333.1px;

                img {
                    height: 333.1px;
                    width: 299.51px;
                    background-size: cover;
                }
            }

            .inner-circle-div {
                height: 230px;
                width: 230px;
                background-color: #F0F4F8;;
                border-radius: 50%;
                border: 3px solid #FFFFFF;
                box-sizing: border-box;
                position: absolute;
                top: 0%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .inverted-comma {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    .left-comma-div {
                        justify-self: flex-start;
                    }

                }
            }

            .avatar-div {
                width: 210px;
                height: 210Px;
                position: absolute;
                top: 32%;

                img {
                    width: 210px;
                    height: 210px;
                }

            }

            .rating-stars-div {
                position: absolute;
                top: 127%;
                left: 36%;

            }


            .profile-detail-div {

                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 279px;
                height: 194px;
                background: rgba(255, 255, 255, 0.9);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                padding: 20px;
                transform: translateY(-50px);
                font-family: Inter;
                color: #202020;

                h1 {
                    font-size: 14px;
                    font-weight: 600;

                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0px;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 234px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

            .profile-detail-div::after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-top-color: rgba(255, 255, 255, 0.9);
                border-bottom: 0;
                border-left: 0;

            }

        }

        .see-my-wall-div {
            margin: 0px;
            padding: 0px;
            display: flex;
            position: absolute;
            top: 78%;
            left: 43%;

            p {
                color: rgba(32, 32, 32, 1);
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 1);
                color: rgba(32, 32, 32, 1);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}


/////////////////////////////////////////////////////////////
///Glassmorphic Version Theme 2 
.glassmorphic4-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(185, 179, 179, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/glassmorphic4_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;


        .profile-div {
            width: 492px;
            height: 434px;
            position: relative;
            padding: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask-img-div {
                width: 400px;
                height: 434px;

                img {
                    height: 434px;
                    width: 400px;
                    background-size: cover;
                }
            }

            .inner-circle-div {
                height: 315px;
                width: 315px;
                /* From https://css.glass */
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border-radius: 50%;
                border: 3px solid #FACB30;
                box-sizing: border-box;
                position: absolute;
                top: 14%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .inverted-comma {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    .left-comma-div {
                        justify-self: flex-start;
                    }

                }
            }

            .avatar-div {
                width: 280px;
                height: 280Px;
                position: absolute;
                top: 42%;

                img {
                    width: 280px;
                    height: 280px;
                }

            }

            .rating-stars-div {
                position: absolute;
                top: 118%;
                left: 40%;

            }


            .profile-detail-div {

                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 380px;
                height: 178px;
                /* From https://css.glass */
                background: rgba(255, 255, 255, 0.2);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                padding: 20px;
                transform: translateY(-50px);
                font-family: Inter;
                color: #202020;


                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0px;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

            .profile-detail-div::after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-top-color: rgba(255, 255, 255, 0.2);
                border-bottom: 0;
                border-left: 0;

            }

        }

        .see-my-wall-div {
            margin: 0px;
            padding: 0px;
            display: flex;
            position: absolute;
            top: 84%;
            left: 36%;

            p {
                color: #202020;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 0.9);
                color: rgba(32, 32, 32, 1);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}

.glassmorphic4-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background:rgba(185, 179, 179, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/glassmorphic4_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;


        .profile-div {
            width: 492px;
            height: 434px;
            position: relative;
            padding: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask-img-div {
                width: 400px;
                height: 434px;

                img {
                    height: 434px;
                    width: 400px;
                    background-size: cover;
                }
            }

            .inner-circle-div {
                height: 315px;
                width: 315px;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border-radius: 50%;
                border: 3px solid #FACB30;
                box-sizing: border-box;
                position: absolute;
                top: 14%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .inverted-comma {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    .left-comma-div {
                        justify-self: flex-start;
                    }

                }
            }

            .avatar-div {
                width: 280px;
                height: 280Px;
                position: absolute;
                top: 42%;

                img {
                    width: 280px;
                    height: 280px;
                }

            }

            .rating-stars-div {
                position: absolute;
                top: 118%;
                left: 40%;

            }


            .profile-detail-div {

                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 380px;
                height: 178px;
                /* From https://css.glass */
                background: rgba(255, 255, 255, 0.2);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                padding: 20px;
                transform: translateY(-50px);
                font-family: Inter;
                color: #202020;


                h1 {
                    font-size: 16px;
                    font-weight: 600;

                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0px;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 340px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

            .profile-detail-div::after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-top-color: rgba(255, 255, 255, 0.2);
                border-bottom: 0;
                border-left: 0;

            }

        }

        .see-my-wall-div {
            margin: 0px;
            padding: 0px;
            display: flex;
            position: absolute;
            top: 84%;
            left: 36%;

            p {
                color: #202020;
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 0.9);
                color: rgba(32, 32, 32, 1);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}

.glassmorphic4-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/glassmorphic4_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-bottom: 40px;


        .profile-div {
            width: 299.51px;
            height: 333.1px;
            position: relative;
            padding: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mask-img-div {
                width: 299.51px;
                height: 333.1px;

                img {
                    height: 333.1px;
                    width: 299.51px;
                    background-size: cover;
                }
            }

            .inner-circle-div {
                height: 230px;
                width: 230px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border: 3px solid #FACB30;
                box-sizing: border-box;
                position: absolute;
                top: 0%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .inverted-comma {
                    display: flex;
                    width: 90%;
                    justify-content: space-between;

                    .left-comma-div {
                        justify-self: flex-start;
                    }

                }
            }

            .avatar-div {
                width: 210px;
                height: 210Px;
                position: absolute;
                top: 32%;

                img {
                    width: 210px;
                    height: 210px;
                }

            }

            .rating-stars-div {
                position: absolute;
                top: 127%;
                left: 36%;

            }


            .profile-detail-div {

                display: flex;
                align-items: center;
                flex-direction: column;
                position: relative;
                width: 279px;
                height: 194px;
                background: rgba(255, 255, 255, 0.9);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                padding: 20px;
                transform: translateY(-50px);
                font-family: Inter;
                color: #202020;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                border-radius: 16px;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);

                h1 {
                    font-size: 14px;
                    font-weight: 600;

                }

                p {
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;
                    margin: 0px;

                }

                h3 {
                    font-size: 12px;
                    font-weight: 400;
                    width: 234px;
                    line-height: 19.2px;
                    text-align: center;
                }

            }

            .profile-detail-div::after {
                content: '';
                position: absolute;
                bottom: 0;
                top: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-top-color: rgba(0, 0, 0, 0.1);
                border-bottom: 0;
                border-left: 0;

            }

        }

        .see-my-wall-div {
            margin: 0px;
            padding: 0px;
            display: flex;
            position: absolute;
            top: 78%;
            left: 43%;

            p {
                color: rgba(32, 32, 32, 1);
                margin: 0px 0px 5px 0px;
            }

            a {
                width: 128px;
                height: 30px;
                padding: 7px 14px 7px 14px;
                border-radius: 20px;
                background-color: rgba(255, 255, 255, 1);
                color: rgba(32, 32, 32, 1);
                font-size: 13px;
                font-weight: 500;
            }
        }
    }
}