.website_design {
    .heading {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #202020;
    }

    .light_mode {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 15px 30px;
        width: 100%;
        height: 12rem;
        border: 0;
        background: #EFF4F8;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
        border-radius: 10px;
        font-weight: 400;
        font-size: 32px !important;
        line-height: 120%;
        color: #1C1C1C;

        .review-outer {
            position: absolute;
            top: -4rem;

            .review_wrapper {
                width: 155px;
                height: 155px;
                position: relative;
                padding: 10px;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 155px;
                    height: 155px;
                }

                .review {
                    width: 135px;
                    height: 135px;
                    border-radius: 50%;
                    padding: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        color: #FEC83A;
                        width: 10px;
                        height: 10px;
                    }

                    p {
                        font-weight: 400;
                        font-size: 8px;
                        line-height: 130%;
                        text-align: center;
                        letter-spacing: 0.01em;
                        color: #202020;

                    }
                }

                .img_position {
                    position: absolute;
                    left: 100px;
                    top: 7rem;

                    .img_wrapper {
                        width: 99.12px;
                        height: 99.12px;
                        background: var(--goldenlinear);
                        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                        border-radius: 50%;
                        padding: 5px;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:after {
                            width: 0;
                            height: 0;
                            border-top: 100px solid red;
                            border-right: 100px solid transparent;
                        }

                        img {
                            width: 90px;
                            height: 90px;
                        }
                    }
                }
            }

        }

        .slider_outer {
            display: flex;
            margin-left: 140px;
            position: relative;

            .circle_yellow {
                box-sizing: border-box;
                width: 90px;
                height: 90px;
                background: #F0F4F8;
                box-shadow: -4px -4px 10px #FFFFFF, 5px 5px 10px rgba(178, 189, 203, 0.8);
                border-radius: 100px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                left: 1rem;
                top: -3rem;

                .inner {
                    width: 72px;
                    height: 72px;
                    background: #FDD41E;
                    box-shadow: inset 5px -4px 10px rgba(224, 121, 27, 0.3);
                    border-radius: 100px;
                }
            }

            .slider {
                width: 21px;
                height: 154px;
                background: #F0F4F8;
                box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                padding: 12px 0px;

                .slide {
                    width: 9px;
                    height: 62px;
                    background: linear-gradient(49.05deg, #F0BF25 -0.22%, #E28D0E 101.07%);
                    box-shadow: -1px -1px 3px #FFFFFF, 1px 1px 3px rgba(178, 189, 203, 0.7);
                    border-radius: 20px;
                }
            }
        }

        .card_outer {
            margin-left: 60px;
            position: relative;
            rotate: -30deg;

            .form-switch {
                justify-content: center;
            }
        }

        .-mb-4 {
            margin-bottom: -1rem;
        }

        .card_img {
            box-sizing: border-box;
            width: 272.36px;
            height: 142.66px;
            background: #F0F4F8;
            border: 0.648471px solid #F0F4F8;
            box-shadow: -7.0135px -7.0135px 17.5338px #FFFFFF, 7.0135px 7.0135px 17.5338px rgba(178, 189, 203, 0.7);
            border-radius: 2.59388px;

            padding: 1rem;

            .sticky_toggle {
                position: absolute;
            }

            h2 {
                font-weight: 500;
                font-size: 11.6725px;
                line-height: 14px;
                color: #545C81;
                text-align: left;
            }

            p {
                font-weight: 500;
                font-size: 6.48471px;
                line-height: 10px;
                color: #8B8E9C;
            }

            .image_wrapper {
                width: 98.57px;
                height: 116.73px;
                background: #F0F4F8;
                box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
                border-radius: 6.48471px;
                float: right;
                // margin-right: 1rem;

                img {
                    rotate: 15deg;
                }
            }

            .btn {
                width: 61.71px;
                height: 19.33px;
                background: #F0F4F8;
                border: 0.648471px solid #F0F4F8;
                box-shadow: -5.18776px -5.18776px 7.78165px rgba(255, 255, 255, 0.5), 5.18776px 5.18776px 7.78165px rgba(166, 180, 200, 0.3);
                border-radius: 6.48471px;
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    font-weight: 500;
                    font-size: 6.48471px;
                    line-height: 10px;
                    color: #656D97;
                }
            }

            .icon_search {
                font-size: 6.48px;
                color: #656D97;
            }

            .search_area {
                height: 6.48px;
                width: 31.13px;
                background: linear-gradient(134.17deg, #E9ECF1 4.98%, #F0F4F8 94.88%);
                box-shadow: inset -1.16892px -1.16892px 2.33783px #FFFFFF, inset 1.16892px 1.16892px 2.33783px rgba(178, 189, 203, 0.9);
                border-radius: 1.29694px;
            }
        }


        .circle {
            position: absolute;
            width: 92px;
            height: 92px;
            bottom: -3rem;
            background: #F0F4F8;
            border: 0.5px solid #F0F4F8;
            box-shadow: inset -4px -4px 10px #FFFFFF, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            .inner {
                box-sizing: border-box;
                position: absolute;
                width: 48.88px;
                height: 48.88px;
                background: #F0F4F8;
                border: 4px solid #F0F4F8;
                box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                border-radius: 100px;
            }
        }

    }

    // .regular_mode {
    //   background-image: url("../../../images/image_2.png");
    //   background-repeat: no-repeat;
    //   background-position: center;
    //   height: 406px;
    .regular_mod {
        position: absolute !important;
        left: 3rem;
        top: 36%;
    }

    // }

    .check_box {
        position: relative;

        .btn_check {
            left: 0;
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none;
        }

        input+label {
            display: flex;
            flex-direction: row;
            padding: 7px;
            width: 39px;
            height: 39px;
            background: #F0F4F8;
            box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
            border-radius: 30px;

            svg {
                width: 25px;
                height: 25px;
                color: #B2BDCB;
            }
        }

        .btn_check:checked+.btn {
            background-color: transparent;
        }

        // input:checked+label {
        //     svg {
        //         color: #1BE347;
        //     }
        // }
    }


}

.dark {

    .light_mode {
        background: #1c1c1c;
        box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px rgba(0, 0, 0, 0.8);

        .review-outer {

            .img_position {

                .img_wrapper {
                    width: 99.12px;
                    height: 99.12px;
                    background: var(--goldenlinear);
                    box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.8), 2px 2px 8px #B2BDCB;
                }
            }
        }

    }

    .slider_outer {

        .circle_yellow {
            background: #1c1c1c;
            box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.8), 5px 5px 10px rgba(178, 189, 203, 0.8);
        }

        .slider {
            background: #1c1c1c;
            box-shadow: inset -2px -2px 6px rgba(0, 0, 0, 0.8), inset 2px 2px 6px #B2BDCB;

            .slide {
                background: linear-gradient(49.05deg, #F0BF25 -0.22%, #E28D0E 101.07%);
                box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.8), 1px 1px 3px rgba(178, 189, 203, 0.7);
            }
        }
    }


    .card_img {
        background: #1c1c1c;
        border: 0.648471px solid #1c1c1c;
        box-shadow: -7.0135px -7.0135px 17.5338px rgba(0, 0, 0, 0.8), 7.0135px 7.0135px 17.5338px rgba(178, 189, 203, 0.7);

        .image_wrapper {
            background: #1c1c1c;
            box-shadow: inset -2px -2px 6px rgba(0, 0, 0, 0.8), inset 2px 2px 6px #B2BDCB;
        }

        .btn {
            background: #1c1c1c;
            border: 0.648471px solid #1c1c1c;
            box-shadow: -5.18776px -5.18776px 7.78165px rgba(255, 255, 255, 0.5), 5.18776px 5.18776px 7.78165px rgba(166, 180, 200, 0.3);
        }


        .search_area {
            background: linear-gradient(134.17deg, #262626 4.98%, #1c1c1c 94.88%);
            box-shadow: inset -1.16892px -1.16892px 2.33783px rgba(0, 0, 0, 0.8), inset 1.16892px 1.16892px 2.33783px rgba(178, 189, 203, 0.9);
        }
    }


    .circle {
        background: transparent;
        box-shadow: inset -4px -4px 10px rgba(0, 0, 0, 0.8), inset 5px 5px 10px rgba(178, 189, 203, 0.8);

        .inner {
            background: transparent;
            border: 4px solid #1c1c1c;
            box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.8), 2px 2px 8px rgba(46, 44, 44, 0.99);
        }
    }

}

input+label {
    background: #1c1c1c;
    box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.8), 2px 2px 8px rgba(46, 44, 44, 0.99);
}

.card_img {
    background: #1c1c1c;
    border: 0.648471px solid #1c1c1c;
    box-shadow: -7.0135px -7.0135px 17.5338px rgba(0, 0, 0, 0.8), 7.0135px 7.0135px 17.5338px rgba(46, 44, 44, 0.99);

    .image_wrapper {
        background: #1c1c1c;
        box-shadow: inset -2px -2px 6px rgba(0, 0, 0, 0.8), inset 2px 2px 6px #B2BDCB;
    }

    .btn {
        background: #1c1c1c;
        box-shadow: -5.18776px -5.18776px 7.78165px rgba(0, 0, 0, 0.8), 5.18776px 5.18776px 7.78165px rgba(46, 44, 44, 0.99);
    }

    .icon_search {
        font-size: 6.48px;
        color: #656D97;
    }

    .search_area {
        background: transparent;
        box-shadow: inset -1.16892px -1.16892px 2.33783px rgba(0, 0, 0, 0.8), inset 1.16892px 1.16892px 2.33783px rgba(46, 44, 44, 0.99);
        border-radius: 1.29694px;
    }
}

.green-chk{
    color: #16b238 !important;
    
}
.gold-chk{
    color: #c49632 !important;
}
.lightgreen-chk{
    color: #7CBAA7 !important;
}