@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');

.featured-on-neumorphic-mobile-4 {
    .Feature-on-mobile-4{
        .grey-box-mobile-4 {
            background: var(--grey-8, #F0F4F8);
            width: 400px;
            
          height: 650px;
          margin: auto;
          flex-direction: column;
          
          
        }
        
        .image-circle-mobile-4 {
            background: var(--grey-8, #F0F4F8);
          border-radius: 50%;
          display: flex;
        width: fit-content;
          align-items: center;
          justify-content: center;
          margin: 0px 4px;
          filter: drop-shadow(2px 2px 8px #B2BDCB) drop-shadow(-2px -2px 8px #FFF);
          z-index: 90;
        }
        
        .image-usa {
          background-color: #1EA3FF;
          
        }
    }
    
}