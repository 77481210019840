.service-package-plan-neumorphic-tab-5 {
    background-color: #CBD5E0;
    width: 768px;
    margin: auto;
    // height: 1075px;

    .h_1010 {
        height: 1010px;
    }

    .toggle_button {
        background: #F0F4F8;
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: #5F6168;
            box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
            color: #F0F4F8;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            width: 86px;
            height: 35px;
            border-radius: 30px;
            background: #F0F4F8;
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
            background: transparent;
            color: #000000;
            text-align: center;
            border: none;
        }

    }

    .content_wrapper {
        background: #F0F4F8;
        border-radius: 25px;
        padding: 20px;
        box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
    }

    .main-content {
        background: #F0F4F8;
    }

    .fs_8 {
        font-size: 8px;
    }

    .read_more_btn {
        background: #F0F4F8;
        box-shadow: -1px -1px 3px #FFFFFF, 1px 1px 3px rgba(178, 189, 203, 0.7);
        border-radius: 5px;
        width: 68px;
        height: 24px;
        color: #202020;
        font-size: 8px;
        line-height: 10px;
    }

    .main_wrapper {
        background: #F0F4F8;
        border-radius: 40px;
        padding: 10px;
        box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);

        .plan_outer_wrapper {
            background: #F0F4F8;
            border: 1px dashed #000000;
            border-radius: 35px;
            padding: 20px;


            .plan_wrapper {
                padding: 20px;
                width: 640px;
                height: 772px;
                background: #F0F4F8;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                .plan_outer {
                    width: 195px;
                    height: 630px;
                    box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                    background: #F0F4F8;
                    border-radius: 20px;
                    position: relative;


                    .plan_content {
                        padding: 80px 15px;
                    }

                    .badge_wrapper {
                        position: absolute;
                        width: 120px;
                        height: 125px;
                        top: -60px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        h2 {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 29px;
                            text-align: center;
                            color: #202020;
                            margin: 0;
                        }

                        p {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            text-align: center;
                            color: #202020;
                            margin-bottom: 0;
                        }
                    }

                    .bg_green {
                        background: #5AA3AD;
                    }

                    .bg_amber {
                        background: #F4B343;
                    }

                    .bg_gray {
                        background: #847F67;
                    }

                    .text_green {
                        color: #5AA3AD;
                    }

                    .text_amber {
                        color: #F4B343;
                    }

                    .text_gray {
                        color: #847F67;
                    }

                    .price {
                        h1 {
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 29px;
                            text-align: center;
                        }

                        p {
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 17px;
                            text-align: center;
                            color: #202020;
                        }
                    }

                    li {
                        list-style: none;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #202020;
                        text-align: left;
                        padding: 20px 0;
                        display: flex;
                        align-items: center;

                        &:last-child {
                            border-bottom: none;
                        }

                        .icon_outer {
                            background: #F0F4F8;
                            box-shadow: -1px -1px 3px #FFFFFF, 1px 1px 3px rgba(178, 189, 203, 0.7);
                            border-radius: 20px;
                            width: 26px;
                            height: 26px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-right: 10px;

                            .icon {
                                width: 1.25rem;
                                height: 1.25rem;
                                color: #202020;
                            }
                        }
                    }

                    .list_divider {
                        width: 100%;
                        height: 2px;
                        background: #F0F4F8;
                        box-shadow: -1px -1px 1px #FFFFFF, 1px 1px 1px rgba(178, 189, 203, 0.7);
                        display: block;
                    }

                    .footer_wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-top: 4px solid #F0F4F8;
                        width: 100%;
                        height: 46px;
                        background: #F0F4F8;
                        border-radius: 0 0 20px 20px;
                        position: absolute;
                        bottom: 0;

                        .btn_wrapper {
                            width: 115px;
                            height: 43px;
                            background: #F0F4F8;
                            position: relative;
                            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;

                            .basic_sign_up_btn {
                                background: #5F6168;
                            }

                            .standard_sign_up_btn {
                                background: #5F6168;
                            }

                            .premium_sign_up_btn {
                                background: #5F6168;
                            }

                            .btn1 {
                                padding: 10px 25px;
                                width: 115px;
                                height: 63px;
                                position: absolute;
                                top: -40px;
                                border: 0;
                                color: #F0F4F8;
                                font-size: 14px;
                                border-bottom: 20px solid #F0F4F8 !important;
                            }
                        }
                    }

                }

            }
        }
    }

    .relative {
        position: relative;
    }
}