.design-screen-desktop-1{
    background-color: #CBD5E0;

    .version-2{
        display: none;
    }
    .search-navbar-btn{
        display: none;
    }
    .sidebar-video{
        display: none;
    }
    @media screen and (max-width:1369px) {
        .active-oter {
         
            margin-right: 7px !important;
        }
        .reg-show {
 
            right: 9%!important;
   
        }
        .content-text .tab-himself-text{
            padding: 70px 115px 20px;
        }
        .main-content .hero-sidebar .mode-set {
     
            left: 85px!important;
        }
        .main-content .hero-sidebar .social-set{
            left: 85px!important;
        }
        .content-text .playlist-section h4{
            right: -58px !important;
        }
    }
    .glass-btn{
        display: none;
    }

   .testimonial{
        transition: transform 0.3s;
      }
       .testimonial:hover {
        transform: scale(1.1);
      }
      @media screen and (max-width:1400px) {
        .icons-circle{

        }
      }
    .dark-meu-show{
        display: none;
    }
 
    .glass-show{
        display: none;
    }

    .timeline-glass{
        display: none;
    }
    .reg-sond{
        display: block;
    }
    .glass-sond{
        display: none;
    }
    .circle-shape{
        display: none;
    }
    .navbar-shape{
        display: none;
    }
    .vector-shape{
        display: none;
    }
    .mue-shape-right{
        display: none;
    }
  .hero-section-mue{
    display: none;
  }
    .timeline-gold{
        display: none;
    }
    .meu-timeline{
        display: none;
    }
    .meu-social{
        display: none;
    }
    .mue-shape{
        display: none;
    }
    .timeline-gold{
        display: none;
    }
    .gold-btn{
        display: none;
    }
    .switch-border{
        width: 182px;
        left: 22px;
        height: 93px;
        background-color: white;
        position: relative;
        border-radius: 49.5px;
        z-index: 31;
        .check-box {
            transform: scale(2);
        }
        
        input[type="checkbox"] {
            position: relative;
            appearance: none;
            width: 100px;
            height: 50px;
            background: #ccc;
            border-radius: 50px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            cursor: pointer;
            transition: 0.4s;
        }
        
        input:checked[type="checkbox"] {
            background: #7da6ff;
        }
        
        input[type="checkbox"]::after {
            position: absolute;
            content: "";
            width: 48px;
            height: 48px;
            left: 50%;
            top: 10px;
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transform: scale(1.1);
            transition: 0.4s;
        }
        
        input:checked[type="checkbox"]::after {
          
            left: 14px;
    
        }
        
        check-box{
            display: inline-block;
            position: relative;
            width: 130px;
            height: 69.64px;
            left: -16px;
            top: 10px;
            box-shadow: none !important;
            border-radius: 49.5px;
            z-index: 2;
            background: #7CBAA7;
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            vertical-align: middle;
            cursor: pointer;
        }
        input[type="checkbox"] {
            display: inline-block;
            position: relative;
            width: 130px;
            height: 69.64px;
            left: -16px;
            top: 10px;
            box-shadow: none !important;
            border-radius: 49.5px;
            z-index: 2;
            background: #7CBAA7;
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            vertical-align: middle;
            cursor: pointer;
          }
          
          input[type="checkbox"]::before {
            content: "";
         
            border-radius: 50%;
         
            position: absolute;
            top: 0;
            left: 0;
            transition: 0.5s;
          }
          
          input[type="checkbox"]:checked::before {
            transform: translateX(100%);
            background: #fff;
          }
          
          input[type="checkbox"]:checked {
            display: inline-block;
            position: relative;
            width: 130px;
            height: 69.64px;
            left: -16px;
            top: 10px;
            box-shadow: none !important;
            border-radius: 49.5px;
            z-index: 2;
            background: #7CBAA7;
            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
            vertical-align: middle;
            cursor: pointer;
          }
          
    }
    @media screen and (max-width:1600px) {
        .timeline li{
            margin-right: 18px!important;
        }
    }
 .bookme-oter{

    
    .bookme-inner{
        background: #cbd5e0;
        z-index: 3;
        padding: 20px;
        width: 400px;
        position: absolute;
        box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
        right: 365px;
        top: 0px;

        .close-btn {
            border: none;
            background-color: transparent !important;
            top: 0px;
            right: 14px;
            font-size: 34px;
            color: grey;
            position: absolute;
        }
            .forms-inpt {
                background-color: white;
            border-radius: 10px;
          padding: 40px 30px;
          margin-top: 40px;
                .form-control {
                    width: 100%;
                    margin: 0px 3px;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: transparent;
                    border: 1px solid #7cbaa7;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
            
                    color: #808492;
                }
            }
            
            .mr-0{
                margin-right: 0px!important;
            }
            .meu-timeline{
                display: none;
            }
            .timeline {
                counter-reset: year 2016;
                position: relative;
                margin-top: 73px;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    font-weight: 400;
                    font-size: 8px;
                    line-height: 12px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -3px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-top {
                width: 30px;
                height: 30px;
                font-weight: 500;
                font-size: 8px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #f0f4f8;
                background: #5f6168;
                top: -22px;
                left: 24px;
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 46px;
                margin: 0 auto 10px auto;
                width: 75px;
                height: 75px;
                background: #f0f4f8;
                color: #5f6168;
                font-size: 15px;
                margin-right: 15px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
          
                img {
                    width: 20px;
                }
                .message {
                    width: 25px !important;
                }
                .load {
                    width: 28px !important;
                }
                .answer {
                    width: 32px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;

                width: 70%;
                height: 12px;
                border: 3px solid #7cbaa7;
                background-color: #304053;
                top: 34px;
                left: 72px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
            .forms-inpt {
                .form-control {
                    width:100%;
                    margin: 0px 3px;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: transparent;
                    border: 1px solid #7cbaa7;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
            
                    color: #808492;
                }
            }
            .book-me-btn{
                width: 150px;
            margin: auto;
            height: 100% !important;
            padding: 13px 30px;
            background: #7cbaa7;
            border: 1px solid #7cbaa7;
            border-radius: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
             color: #202020;
             font-weight: 500;
             text-align: center
             ;
            font-size: 13px;
            line-height: 16px;
            }
    }

 }
    .search-position{
        position: relative;
    }
    .searchbox{
        top: -53px;
        left: 112%;
        position: absolute;
        background: #f0f4f8 !important;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
        border-radius: 10px !important;
        border: none;
        z-index: 3;
        padding: 20px 30px;
        width: 380px;
        .search-area {
            margin: 0px;
            .close-btn{
                border: none;
                background-color:transparent!important;
                top: -11px;
                right: -155px;
                font-size: 34px;
                color: grey;
                position: relative;
            }
            .search-field {
                width: 100%;
                margin: 0px 10px 0px;
                height: 44px !important;
                box-shadow: none;
                padding: 2px 11px;
    
                background: transparent;
                border: 1px solid #394649;
                border-radius: 50px;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #394649;
                .icon-mgni {
                    font-size: 35px !important;
                }
                input {
                    font-size: 15px;
                }
                svg {
                    cursor: pointer;
                }
            }
        }
        .search-me-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 17px 15px;
            margin-bottom: 10px !important;
            gap: 10px;
            margin: auto;
            margin-top: 20px;
            width: 110px !important;
            height: 50px;
            background: #7cbaa7;
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            border: none;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }
    }
    .bars-hambrger{
        position: relative;
        .react-multi-carousel-track {
            .react-multi-carousel-item{
                button{
                    width: 150px!important;
                    background-color: transparent!important;
                }
               }
        }
        .hamburger-outer{
            background-color: #202020!important;
            position: absolute;
            z-index: 3;
            padding: 40px 20px 40px;
            width: 400px;
            right: 0;
            top: 0;
            .close-btn{
                width: 45px;
    height: 40px;
    font-size: 23px;
    position: absolute;
    right: 17px;
    top: 30px;
            }

            .switch-outer {
                width: 62.06px;
                height: 33px;
                position: relative;
                background-color: white;
                border-radius: 49.5px;
                z-index: 31;
                margin: 0px 15px;
                .switch {
                    display: inline-block;
                    position: relative;
                    width: 39.58px;
                    height: 22.71px;
                    box-shadow: none!important;
                    left: 6px;
                    top: 6px;
                    box-shadow: none;
                    border-radius: 49.5px;
                    z-index: 2;
                    background: #7cbaa7;
                    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    vertical-align: middle;
                    cursor: pointer;
                }
                .switch::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 20px;
                    width: 15.03px;
                    height: 15.03px;
                    background: white;
                    border-radius: 50%;
                    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                        box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                }
                .switch:active::before {
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                }
                input:checked + .switch {
                    background: #7cbaa7;
                }
                input:checked + .switch::before {
                    left: 9px;
                    background: #fff;
                }
                input:checked + .switch:active::before {
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                }
            }
            .mode-set {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #3c3d40;
    
                z-index: 1;
            }
            .social-set {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: #f0f4f8;
                z-index: 1;
            }
            .btn-close {
                background-color: white;
                opacity: 1;
                border-radius: 0px !important;
                width: 30px;
                height: 30px;
            }
            .sidebar-li {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                text-align: center;
                border-bottom: 1px solid grey;
                padding: 20px;
    
                /* Grey/8 */
    
                color: #f0f4f8;
            }
            .bottom-tabs {
                color: #cbd5e0;
                margin-top: 5px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                h6 {
                    margin-left: 10px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 120%;
                    color: #cbd5e0;
                }
                svg {
                    font-size: 25px;
                }
            }
            .testimonial-p {
                color: #cbd5e0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .top-padding {
                display: flex;
                justify-content: center;
                align-items: center;
                li {
                    padding: 0px 8px;
                }
            }
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: white;
                    overflow: hidden;
                    width: 87px;
                    padding: 8px;
                    height: 87px;
                    top: 97px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #7cbaa7;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }
    
                img {
                    width: 130px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }
        }

    }

    .offcanvs{
        z-index: 99999;
        right: 0;
    }
    .offcanvas {
        position: absolute!important;
        right: 0;
    }
    .offcanvas-backdrop.show {
        opacity: 0!important;
    }
    .offcanvas-backdrop.fade.show{
        opacity: 0!important;
    }
    .meu-show{
        display: none;
    }
    .meu-timeline{
        display: none;
    }
    .meu-img{
        display: none;
    }
    .meu-icon{
        display: none;
    }
    .navbar-top{
    background: #CBD5E0;
    height: 160px;
    padding: 24px!important;
   
    .testimonial{
        margin-top: -80px;
        .timeline-top{
            flex-direction: column;
            font-weight: 500;
            font-size: 12px;
            line-height: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            color: black;
            background: white;
           
            width: 87px;
            padding: 8px;
            height: 87px;
            top: 97px;
            left: 23px;
p{
    margin-top: 4px;
    line-height: 10px;
}
            .icon-green{
                font-size: 8px;
                color: #7CBAA7;
                padding-top:3px;
            }
            p{
                font-size: 8px;
            }
        }

     img{
        width: 130px;
        margin: 0px 13px;
        position: relative;
        z-index: 2;
    }
}
.profile-img{
    width: 56%;
    margin-left: 15px;
    margin-top: -136px;

}
.icons-circle{
    width: 140px;
    margin-top: 18px;
}
.img-logo{
    margin-left: 20px;
    width: 110px;
}
.timeline{
    counter-reset: year 2016;
    position: relative;
    margin-top: 27px;
    p{
        font-weight: 400;
font-size: 9px;
line-height: 11px;
/* identical to box height */

display: flex;
align-items: center;
display: flex;
align-items: center;
justify-content: center;
color: #1C1C1C;
margin-top: -3px;
    }
  }
  
  .timeline li{
    list-style: none;
    float: left;

    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Dosis', sans-serif;
  }
  
//   ul:nth-child(1){
//     color: #4caf50;
//   }
.timeline-icon{
    margin-top: -30px;
}
  .timeline-top{
    width: 35px;
height: 35px;
font-weight: 500;
font-size: 12px;
line-height: 13px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
position: relative;
color: #F0F4F8;
background: #5F6168;
top: -23px;
    left: 24px;
  }
  .timeline li{
    z-index: 2;
    position: relative;

    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 50px;
    margin: 0 auto 10px auto;
    width: 85px;
    height: 85px;


 
    background: #F0F4F8;
color: #5F6168;
font-size: 15px;
margin-right: 47px;

    transition: all ease-in-out .3s;

    cursor: pointer;
    @media screen and (max-width:1450px){
        margin-right: 22px;
    }
img{
    width: 20px;
}
.message{
    width:25px!important;
}
.load {
    width: 28px!important;
}
.answer {
    width: 32px;
}
  }
  
  .timeline:after{
    content: "";
    position: absolute;
  
    width: 58%;
    height: 12px;
    border: 3px solid #7CBAA7;
    background-color: #304053;
    top: 34px;
    left: 72px;
    z-index: 1;
    transition: all ease-in-out 0.3s;
    margin: auto;
  }
  .timeline li.active{
    color: #555555;
  }

  .timeline li.active + li:after{
    background: #4caf50!important;
  }
}
@media screen and (max-width:1550px) {
       .navbar-top{
    background: #CBD5E0;
    height: 160px;
    padding: 24px!important;
    .testimonial{
        margin-top: -80px;
        .timeline-top{
            flex-direction: column;
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            color: black;
            background: white;
           
            width: 87px;
            padding: 8px;
            height: 87px;
            top: 97px;
            left: 23px;
p{
    margin-top: 4px;
    line-height: 10px;
}
            .icon-green{
                font-size: 8px;
                color: #7CBAA7;
                padding-top:3px;
            }
            p{
                font-size: 8px;
            }
        }

     img{
        width: 130px;
        margin: 0px 13px;
        position: relative;
        z-index: 2;
    }
}
.profile-img{
    width: 72px;
    margin-left: 12%;
    margin-top: -100%;

}
.icons-circle{
        width: 125px;
    margin-top: 18px;
}
.img-logo{
    margin-left: 11px;
    width: 88px;
}
.timeline{
    counter-reset: year 2016;
    position: relative;
    margin-top: 27px;
    p{
        font-weight: 400;
        font-size: 8px;
        padding: 0px 15px 0px;
        line-height: 11px;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1C1C1C;
        margin-top: -7px;
    }
  }
  
  .timeline li{
    list-style: none;
    float:left;

    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Dosis', sans-serif;
  }
  
//   ul:nth-child(1){
//     color: #4caf50;
//   }
.timeline-icon{
    margin-top: -30px;
}
  .timeline-top{
    width: 35px;
height: 35px;
font-weight: 500;
font-size: 12px;
line-height: 13px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
position: relative;
color: #F0F4F8;
background: #5F6168;
top: -23px;
    left: 24px;
  }
  .timeline li{
    z-index: 2;
    position: relative;

    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 50px;
    margin: 0 auto 10px auto;
   width: 78px;
    height: 78px;


 
    background: #F0F4F8;
color: #5F6168;
font-size: 15px;
margin-right: 30px;

    transition: all ease-in-out .3s;
    cursor: pointer;
img{
    width: 20px;
}
.message{
    width:25px!important;
}
.load {
    width: 28px!important;
}
.answer {
    width: 32px;
}
  }
  
  .timeline:after{
    content: "";
    position: absolute;
      width: 58%;
    height: 12px;
    border: 3px solid #7CBAA7;
    background-color: #304053;
     top: 34px;
    left: 54px;
    z-index: 1;
    transition: all ease-in-out 0.3s;
    margin: auto;
  }
  .timeline li.active{
    color: #555555;
  }

  .timeline li.active + li:after{
    background: #4caf50!important;
  }
}
}
@media screen and (min-width:1800px) {
    .profile-img{
  
        width: 52% !important;
        margin-left: 20px !important;
        margin-top: -133px !important;
    
    }

}
@media screen and (max-width:1400px) {
    
}
.navbar-bars{
    display: flex;
justify-content: center;
align-items: center;
padding: 10px;
font-size: 30px;

width: 120px;
height: 160px;
color: white;
background: #202020;
}

.text-hide{
    color: transparent;
}
  .main-content{
    .hero-sidebar{
        background: #CBD5E0;
        width:8%;
        height:100%;
        padding: 15px;

        .switch-outer{
            width: 182px;
            left: 22px;
            height: 93px;
background-color: white;
position: relative;
border-radius: 49.5px;
z-index: 31;
.switch {
    display: inline-block;
    position: relative;
    width: 130px;
height: 69.64px;
left: -16px;
top: 10px;
box-shadow: none!important;
    border-radius: 49.5px;
    z-index: 2;
    background: #7CBAA7;
    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    vertical-align: middle;
    cursor: pointer;
}
.switch::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 68px;
    width: 49.6px;
    height: 49.6px;
    background: white;
    border-radius: 50%;
    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
}
input:checked + .switch {
    background: #7CBAA7;
}
input:checked + .switch::before {
    left: 11px;
    background: #fff;
}
input:checked + .switch:active::before {
    box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);
}
        }
   .mode-set{
    position: relative;
   
    top: -37px;
    left: 104px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    transform: rotate(-90deg);
    z-index: 1;
   }
   .social-set{
    position: relative;
  
    top: 39px;
    left: 104px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    transform: rotate(-90deg);
    z-index: 1;
   }
        p{
            font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 11px;
text-align: center;
color: #202020;
        }
.bottom-tabs{
color: #394649;
margin-top: 50px;
 h6{
    font-weight: 600;
font-size: 15px;
line-height: 120%;
color: #192529;
}

}

.name-btn{
background: #7CBAA7;
border-radius: 4px;
font-weight: 600;
font-size: 9px;
line-height: 100%;
padding: 8px 10px;
margin-bottom: 10px;
text-align: center;
letter-spacing: 0.01em;
color: #202020;


}
.info-oter{

    width: 60px;
    height: 59px;
    margin: auto;
    border-radius: 50px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.info-icon{
    border: 3px solid #394649 ;
    padding: 5px 11px 8px 14px;
    font-size: 19px;
margin: 20px 0px;
    color: #394649;

    border-radius: 50px;
}
.fa-magnify{
 
    border-radius: 30px;
    padding: 12px;
    font-size: 35px;
    margin: 10px 0px 55px;
color: #394649;
}
    }
    .content-hero{
width:92%;
.content-text{
    background: #CBD5E0;
}
    }
  }
 .content-text{
    position: relative;
    .dots{
        position: relative;
    top: -88px;
    left: 44px;
    }
    .tab-right-section{
        width: 15%;
    }
    .nav-item .active {
        background: #202020;
        color: white;
        box-shadow: none;
    }
   
    .tab-section{
        width: 55%;
    margin: auto;
        margin-top: -63px!important;
    }
    .tab-content{
        margin-top: -75px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
   
       
        position: relative;
    
        height: 364px;
        background: #7CBAA7;
        border-radius: 8px;
    
    }
.tab-himself-text{
   overflow: auto;
 
   padding: 70px 100px 20px;
p{
    overflow: auto;
    height: 213px;
    padding-right: 10px;
    ::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888; 
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
}

}
.playlist-section{
    

position: absolute;
    left: 55px;
    z-index: 1;
    top: 5%;
h4{
    margin-bottom: 40px;
    width: 130px;
    position: relative;
    right: -99px!important;
    @media screen and (max-width:1500px) {
        right: -60px!important;
    }
}

/* Grey/8

Base color for Light mode
*/

}
.playlist-section-text{
    position: absolute;
    background: #F0F4F8;
box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
border-radius: 8px;
    width: 100%;
    
  padding: 20px;
  margin-top: -20px;
  right: -218px;
  width: 355px;


/* Grey/8

Base color for Light mode
*/
background: #F0F4F8;
box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
border-radius: 8px;
@media screen and (max-width:1662px) {
    right: -193px;
    width: 350px;

}
@media screen and (max-width:1450px) {
    right: -167px;
    width: 330px;
}
.slidecontainer{
    width: revert;
    margin-right: 20px;
}
input[type="range"].range-slider {
    width: 80px;
    margin-right: 7px;

}
.range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
    height: 20px !important;
    width: 26px !important;
    bottom: -24px !important;
    border-radius: 50% !important;
    display: flex !important;
    color: #202020;
    justify-content: center !important;
    align-items: center !important;
    background-color: #3860ee !important;
    font-size: 8px;
}
.range-slider__wrap
    .range-slider__tooltip.range-slider__tooltip--bottom
    .range-slider__tooltip__caret::before {
    border-bottom-color: #3860ee !important;
    border-width: 0 0.3rem 0.3rem;
    bottom: 25px;
    left: 2px;
}
.range-slider__wrap {
    position: relative;
    display: block;
    height: 16px !important;

    .range-slider__tooltip {
        z-index: 0 !important;

        &.range-slider__tooltip--bottom {
            bottom: calc(0.75em + 0.375rem + 1px + 0.4rem + 10px + 3px) !important;
            top: auto !important;
        }

        .range-slider__tooltip__caret {
            transform: translateX(50%) !important;
        }
    }
}
input[type="range"].range-slider {
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        box-sizing: border-box;
        border: none;
        border-radius: 50%;
        height: 8px !important;
        background-color: #3860ee!important;
        border: 1px solid black;
        width: 8px !important;
        cursor: pointer;
        transition: box-shadow 0.5s ease;
        margin-top: -3px !important;
    }
}
input[type="range"].range-slider {
    box-shadow: none;
    height: 8px;
    padding: 0px;
}
input[type="range"].range-slider {
    &::-webkit-slider-runnable-track {
        -webkit-appearance: none !important;
        width: 100% !important;
        margin-top: 8px;
        height: 1px !important;
        background-color: #202020 !important;
        border: 2px solid #202020;
        outline: none !important;

        -webkit-transition: 0.2s !important;

        border-radius: 30px !important;
    }
}
.next-icon{
    background: #202020;
border-radius: 23.0737px;
width: 35px;
text-align: center;
color: white;
font-size: 25px;
display: flex;
justify-content: center;
align-items: center;
height: 35px;
}
.setting-icons{
    background: #202020;
    margin-left: 20px;
    border-radius: 24.0118px;
    padding: 1px;
    color: white;
    font-size: 15px;
    display: flex;
    .icons-set{
margin: 5px 9px;
    }
}
.side-text{
    /* Grey/9 */

background: #FFFFFF;
border-radius: 2.38694px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 11.139px 6.36517px 3.97823px;
font-weight: 500;
font-size: 8.40381px;
line-height: 100%;
margin: 0px 10px;
/* or 5px */
border: 2px solid #DDA01A;

text-align: center;
letter-spacing: 0.01em;
text-transform: uppercase;
color: #1C1C1C;
}
.text-gree{
    display: flex;
    position: relative;
    top: 4px;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 3.27619px 5.2419px;
width: 105.48px;
display: flex;
    justify-content: center;
    margin: auto;
    font-size: 8px;
height: 10.55px;

background: #E5E9ED;
border-radius: 1.96571px;
font-style: normal;
font-weight: 700;
font-size: 4.44588px;
line-height: 100%;
text-align: center;
letter-spacing: 0.01em;
color: #101010;
}
.next-icon-center{
    
    background: #202020;
    border-radius: 50.7836px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -4px 2px 0px;
    .iconss{
font-size: 15px;
color: white;
margin: 8px;
    }

}

.video-icon{
    font-size: 45px;
    color: #202020;
    margin: 0px 3px;
    svg{
        margin-top: -13px;
    }
}
.mic-img{
    
    padding: 0px 20px ;
    img{
        width: 90px;
    }
}
}
.btn-video{
    position: absolute;
    right: 19%;
    top: 36px;
    display: flex;
flex-direction: row;
align-items: center;
padding: 17px 25px;
width: 114px;
height: 45px;
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 16px;
letter-spacing: 0.04em;
text-transform: uppercase;
color: #FFFFFF;

/* Others/1 */

background: #304053;
border-radius: 4px;

}
.video-section{
    width: 23%;
    position: absolute;
    background: #F0F4F8;
box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
border-radius: 8px;
right: 80px;
height: 50%;
bottom: 27%;
display: flex;
justify-content: center;
align-items: center;
.video-img{
    background-image:url(../../../../../../images/video-img.png);
    background-repeat: no-repeat;
    background-position: center;
    height: 152.63px;
    margin: 28px;
    width: 230px;
    height: 155.63px;
    border-radius: 10.5263px;
    display: flex;
    justify-content: center;
    align-items: center;
}
}
 }
 .design-about{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 15px 18px;
width: 100px;
height: 120px;
position: relative;
z-index: 1;
background: #304053;
color: white;
border-radius: 4px;
font-weight: 500;
font-size: 12px;
line-height: 15px;
text-align: center;
 }
  .content-img{
    position: relative;
    width: 100%;
  }
  .hero-section{
    position: relative;
  }
  .hero-part{
    position: absolute;
    top: 80px;
    left: 80px;
    h1{
        font-weight: 700;
font-size: 68px;
line-height: 90px;
color: #FFFFFF;

text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    }
    p{
        font-weight: 400;
font-size: 20px;
line-height: 24px;
color: #FFFFFF;

text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    }
  }
  @media screen and (min-width:1500px) {
    .hero-part{
        position: absolute;
        top: 80px;
        left: 80px;
        h1{
            font-weight: 700;
    font-size: 68px;
    line-height: 90px;
    color: #FFFFFF;
    
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        }
        p{
            font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        }
      }
  }
  .content-hero{
    width: 92%;
  }

}
