@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

.Neumorphic-design {
    .glass-btn{
        display: none;
    }
    .searchbox{
        .meu-show{
            position: revert!important;
        }
    }
    .bottom-tabs{
        svg{
            background: #f0f4f8;
            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 30px;
            width: 20px;
            height: 20px;
            padding: 6px;
        }
        }
    
    .hero-section-reg {
        display: none;
    }
    .timeline-gold{
        display: block;

    }

    .dots{
        display: none;
    }
    .mue-shape{
        display: block;
    }
    .meu-show{
display: block;
    }
    .test-reg{
        display: none;
    }
    .fa-magnify{
        font-size: 26px;
        margin: 10px 0px 55px;
    color: #394649;
    background: #F0F4F8;
        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
        border-radius: 30px;
        padding: 10px;
    }
    .info-oter{
        background: #F0F4F8;
        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
        width: 50px;
        height: 50px;
        margin: auto;
        border-radius: 50px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .meu-social{
        display: block;
    }
    .reg-social{
        display: none!important;
    }
    .down-gold{
        display: block!important;
    
        width: 25px!important;
    }
    .down-icon{
        display: none;
    }
    .testimonial-p {
   
        background: #F0F4F8;
        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #202020!important;
        padding: 8px;
        margin-left: 18px;
        border-radius: 10px;
        width: 127px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn-video {
        display: none!important;
    }
    .reg-timeline{
        display: none;
    }
    .meu-timeline{
        display: block!important;
    }

    .reg-img{
        display: none;
    
    }
    .meu-img{
        display: block;
    }
    .reg-social{
display: none;
    }
    .meu-social{
        display: block;
        li {
            background: #F0F4F8;
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
            border-radius: 30px;
            padding: 10px;
            width: 35px;
            height: 35px;
            text-align: center;
            padding: 9px 9px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px auto;

        }
    }

    .reg-icon{
        display: none!important;
    }
    .meu-icon{
        display: block!important;
        background: #f0f4f8;
        box-shadow: inset 0px -0.78422px 1.56844px #ffffff, inset 0px 0.78422px 1.17633px #a0b9d0;
        border-radius: 11.7633px;
 
            font-size: 14px!important;
            background: #f0f4f8;
            box-shadow: 1.17633px 1.17633px 1.7645px #7b858f, -1.17633px -1.17633px 1.56844px #ffffff;
            border-radius: 50%;
            color: #c49530!important;
            width: 22.64px;
            height: 16.64px;
            margin: 0px 4px 0px 3px;
            padding: 4px 1px;
            svg {
                margin-top: -22px;
            }
      
    }

    .design-screen-tab-1{
        .navbar-top .testimonial img{
            margin: auto!important;
        }
        .bars-seeall .hamburger-outer .testimonial .timeline-top{
            right: 0!important;
            left: 38%!important;
            width: 79px!important;
            height: 82px!important;
            top: 104px!important;
        }
        .mue-shape-right {
   
            right: 475px!important;
        }
        .bookme-oter .bookme-inner .hamburger-outer .forms-inpt {
      
            padding: 3px 15px!important;
            .meu-show{
                position: revert!important;
            }
        }
        .search-me-btn{
            display: none!important;
        }
      
        .bars-seeall .hamburger-outer{
            right: -48px !important;
        }
      
        .navbar-top .testimonial .timeline-top {

            width: 70px!important;
            padding: 7px!important;
            height: 65px!important;
            top: 85px!important;
            left: 16px!important;
        }
        .bars-hambrger .hamburger-outer{
            background-color: #f0f4f8!important;
        }
        .himself-right {
            width: 45%;
            margin-top: 267px;
        }
        .img-section{
            display: none;
        }
        .mue-shape {
            position: absolute;
            z-index: 0;
            bottom: 109px;
            width: 148px;
       
        }
        .content-text .tab-section {
            width: 56%;
            margin: auto auto auto 183px;
            margin-top: -63px !important;
        }
        .m-section .hero-section {
            box-shadow: none!important;
            position: relative;
            background: transparent;
padding: 0px!important ;

margin: 0px!important;
        }
     .m-section   .hero-section-mue .content-img {
            width: 90% !important;
            right: -5% !important;
            height: 100%!important;
            padding: 15px!important;
        }
        .mue-shape-right{
            display: block;
            position: absolute;
            right: 288px;
            top: 177px;
        }
        background: #F0F4F8;
        width:768px;
        margin: auto;
        .switch-border {
            background: #F0F4F8!important;
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
        }
        .switch-border input[type=checkbox]::after {
            background: #EAEFF6!important;
            box-shadow: inset 0px -6.85714px 13.7143px #FFFFFF, inset 0px 6.85714px 10.2857px #A0B9D0!important;
        }
        .meu-social{
            display: block;
        }
        .bars-hambrger .hamburger-outer .testimonial .timeline-top {
            flex-direction: column;
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            color: black;
            background: #f0f4f8 !important;
            box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
            overflow: hidden;
            width: 81px;
            padding: 8px;
            height: 81px;
            top: 88px;
            left: 18px;
            p {
                font-size: 6px;
            }
        }
        .meu-playlist-section {
            background: #f0f4f8;
            box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
            border-radius: 6.41451px;
            padding: 15px !important;
            .next-icon-center .iconss {
                font-size: 10px;
                color: #c49530;
                margin: 8px;
            }
        }
        .gold-btn {
            position: absolute;
            z-index: 5;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
            box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border-radius: 30px;
            padding: 20px;
            width: 241px;
            top: 32px;
            left: -29px;
            display: block;
            text-align: center;
            margin-bottom: 63px;
            margin-top: -27px;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            color: #202020;
        }
        .bookme-inner{
            background: #f0f4f8;
            z-index: 3;
            padding: 20px;
            width: 400px;
            position: absolute;
            box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
           
z-index: 4;
    
            .close-btn {
                border: none;
                background-image: url(../../../../../images/close-batch.png);
                background-repeat: no-repeat;
                top: 2px;
                height: 68px;
                margin-top: -10px;
                top: 3px;
                right: 9px;
                font-size: 34px;
                color: grey;
                position: absolute;
                svg{
                    margin-top: -34px;
                    margin-left: 10px;
                    font-size: 28px;
                }
            }
                .forms-inpt {
                    background: #F0F4F8;
                border-radius: 10px;
                padding: 38px 15px;
              margin-top: 40px;
                    .form-control {
                        width: 100%;
                        margin: 0px 3px;
                        height: 100% !important;
                        padding: 13px 30px;
                        background: #F0F4F8;
                        border: 1px solid transparent;
                        box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
                        border-radius: 4px;
                        
                
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;
                
                        color: #808492;
                    }
                }
                
                .mr-0{
                    margin-right: 0px!important;
                }
                .meu-timeline{
                    display: none;
                }
                .timeline {
                    counter-reset: year 2016;
                    position: relative;
                    margin-top: 73px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p {
                        font-weight: 400;
                        font-size: 8px;
                        line-height: 12px;
                        /* identical to box height */
    
                        display: flex;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #1c1c1c;
                        margin-top: -3px;
                    }
                }
    
                .timeline li {
                    list-style: none;
                    float: left;
    
                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Dosis", sans-serif;
                }
    
                //   ul:nth-child(1){
                //     color: #4caf50;
                //   }
                .timeline-icon {
                    margin-top: -30px;
                }
                .timeline-top {
                    width: 30px;
                    height: 30px;
                    font-weight: 500;
                    font-size: 8px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: #f0f4f8!important;
                    box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
                    top: -22px;
                    left: 24px;
                }
                .timeline li {
                    z-index: 2;
                    position: relative;
    
                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 46px;
                    margin: 0 auto 10px auto;
                   
                    background: #f0f4f8;
                    color: #5f6168;
                    font-size: 15px;
                    margin-right: 15px;
    
                    transition: all ease-in-out 0.3s;
                    cursor: pointer;
                    img {
                        width: 20px;
                    }
                    .message {
                        width: 25px !important;
                    }
                    .load {
                        width: 28px !important;
                    }
                    .answer {
                        width: 32px;
                    }
                }
    
                .timeline:after {
                    content: "";
                    position: absolute;
    
                    width: 70%;
                    height: 12px;
                    border: 3px solid #7cbaa7;
                    background-color: #304053;
                    top: 34px;
                    left: 72px;
                    z-index: 1;
                    transition: all ease-in-out 0.3s;
                    margin: auto;
                }
                .timeline li.active {
                    color: #555555;
                }
    
                .timeline li.active + li:after {
                    background: #4caf50 !important;
                }
                .forms-inpt {
                    .form-control {
                        width:100%;
                        margin: 0px 3px;
                        height: 100% !important;
                        padding: 13px 30px;
                        background: #F0F4F8;
                        border: 1px solid transparent;
                        box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
                        border-radius: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;
                
                        color: #808492;
                    }
                }
                .book-me-btn{
                    width: 150px;
                margin: auto;
                height: 100% !important;
                padding: 13px 30px;
                background: #7cbaa7;
                border: 1px solid #7cbaa7;
                border-radius: 5px;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                 color: #202020;
                 font-weight: 500;
                 text-align: center
                 ;
                font-size: 13px;
                line-height: 16px;
                }
        }
    
        .searchbox{
            .search-area{
                .search-field {
                    margin-top: 50px!important;
                    background: #F0F4F8!important;
                    box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB!important;
                    border-radius: 40px;
                    border: 1px solid transparent!important;
                    width: 100%;
                    margin: 0px 10px 0px;
                    height: 44px !important;
                    box-shadow: none;
                    padding: 2px 11px;
          
            
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #394649;
                }
            }
     
            .close-btn {
                border: none;
                background-image: url(../../../../../images/close-batch.png);
                background-repeat: no-repeat;
                top: 2px;
                height: 68px;
                margin-top: -11px;
                top: 3px;
                right: 9px;
                font-size: 34px;
                color: grey;
                position: absolute;
                svg{
                    margin-top: -34px;
                    margin-left: 10px;
                    font-size: 28px;
                }
            } 
        }
        .hamburger-outer {
    
            position: absolute;
            z-index: 3;
            padding: 40px 20px 40px;
            width: 400px;
            right: 0;
            top: 0;
            background: #F0F4F8!important;
            box-shadow: -4px -4px 10px #FFFFFF, 5px 5px 10px rgba(178, 189, 203, 0.8);
            border-radius: 10px;
            .close-btn {
        
                height: 67px;
                font-size: 23px;
                position: absolute;
                right: 14px;
                top: 1px;
            }
             .sidebar-li {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                background: #F0F4F8;
                background: #F0F4F8;
                box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
             
                text-align: center;
              border-bottom: 1px solid transparent; 
                padding: 20px;
                color: #f0f4f8;
                color: #202020;
                margin: 16px;
            }
            .switch-outer{
                display: none;
            }
        }

        .close-btn {
            border: none;
            background-image: url(../../../../../images/close-batch.png);
            background-repeat: no-repeat;
            top: 2px;
            height: 68px;
            margin-top: -10px;
            top: 3px;
            right: 9px;
            font-size: 34px;
            color: grey;
            position: absolute;
            svg{
                margin-top: -34px;
                margin-left: 10px;
                font-size: 28px;
            }
        }
        .meu-show{
            display: block;
            position: absolute;
     
            right: 38px;
            top: 51px;
        }
        .margin-left-btn {
            margin-left: 9px;
        }
        .bars-hambrger {
            display: flex;
            align-items: center;
        }
        .bars-seeall .hamburger-outer .top-padding li {
            padding: 19px 8px;
        }
        .navbr-icon{
            height: 130px;  
        }
        .navbar-top{
            background: #F0F4F8;
        height: 130px;
        .active-oter {
            width: 75px;
            height: 75px;
            padding: 6px;
            z-index: 2;
            border-radius: 50px;

            background: #f0f4f8;
            box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
        }
     
        .testimonial-seeall{
            display: flex;
            align-items: center;
   .testimonial .timeline-top .icon-green{
                padding-top: 0px !important;
    padding-bottom: 2px;
    color: #cfa84d !important;
            }
            h5{
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 159.5%;
                
                text-align: center;
                letter-spacing: 0.01em;
                text-decoration-line: underline;
                color: #202020;
                padding-right: 3px;
                margin-top: 5px;
    
            }
            img{
             width: 15px;
            }
    
        }
        .testimonial{
            margin-top: -80px;
            .timeline-top{
                flex-direction: column;
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: black;
                background: #f0f4f8!important;
                box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                width: 68px;
                padding: 8px;
                height: 68px;
                top: 79px;
                left: 20px;
    p{
        font-size: 5px!important;
        /* text-align: center; */
        line-height: 6px;
        margin-top: 3px;
       
    }
                .icon-green{
                    font-size: 8px;
                    color: #7CBAA7;
                    padding-top:8px!important;
                    padding-bottom: 2px;
                }
                p{
                    font-size: 8px;
                }
            }
    
         img{
            width: 101px;
            margin: -9px 0px;
            position: relative;
            z-index: 2;
            top: 14px;
            left: 13px;
        }
    }
    .mr--34{
        margin-right: -34px;
    }
    .mr-0{
        margin-right: 0px!important;
    }
    .profile-img{
        width: 60%;
        margin-left: 11px;
        margin-top: -100px;

    
    }
    .icons-circle{
        width: 90px;
        margin-top: 18px;
    }
    .img-logo{
        margin-left: 5px;
        width: 110px;
    }
    .timeline{
        display: flex;
        justify-content: space-around;
        counter-reset: year 2016;
        position: relative;
        margin-top: 27px;
        p{
            font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1C1C1C;
    margin-top: -16px;
        }
      }
      
      .timeline li{
        list-style: none;
        float: left;
    
        position: relative;
        text-align: center;
        text-transform: uppercase;
        font-family: 'Dosis', sans-serif;
      }
      
    //   ul:nth-child(1){
    //     color: #4caf50;
    //   }
    .timeline-icon{
        margin-top: -35px;
    }
      .timeline-top{
        flex-direction: column;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: relative;
        color: black;
        background: #f0f4f8!important;
        box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
        width: 35px;
        padding: 8px;
        height: 35px;
        top: -25px;
        left: 15px;
      }
      .timeline li{
        z-index: 2;
        position: relative;
    
        border-radius: 50%;
        display: block;
        text-align: center;
        line-height: 50px;
        margin: 0 auto 10px auto;
     
        width: 63px;
        height: 63px;
        background: #eff4f8;
        /* Neo/Light/Outer/4 */
        box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
        color: #5f6168;
        font-size: 15px;
        margin-right: 13px;
        
    
        transition: all ease-in-out .3s;
        cursor: pointer;
    img{
        width: 15px!important;
        margin-top: -10px;
    }
    .message{
        width:18px!important;
        margin-top: -10px;
    }
    .load {
        width: 18px!important;
        margin-top: -10px;
    }
    .answer {
        width: 20px!important;
        margin-top: -10px;
    }
      }
      
      .timeline:after{
        content: "";
        position: absolute;
      
        width: 74%;
        height: 12px;
        box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9);
        border: 3px solid #f0f4f8;
        background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
        top: 29px;
        left: 53px;
        z-index: 1;
        transition: all ease-in-out 0.3s;
        margin: auto;
      }
      .timeline li.active{
        color: #555555;
      }
    
      .timeline li.active + li:after{
        background: #4caf50!important;
      }
    }
   
    .navbar-bars{
        display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-size: 25px;
    
    width: 50px;
    height: 60px;
    background: #F0F4F8;
    /* Neo/Light/Outer/3 */
    color:  #202020;
    box-shadow: -4px -4px 10px #FFFFFF, 5px 5px 10px rgba(178, 189, 203, 0.8);
    }
    
    .text-hide{
        color: transparent;
    }
      .main-content{
        .hero-sidebar{
      
            background: #f0f4f8;
            box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
            width: 11%;
            height: 100%;
            padding: 5px;
            z-index: 1;
            .fa-magnify{
                background: #f0f4f8;
                box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
                padding: 10px!important;

            }
            .switch-outer{
                width: 110px;
                height: 60px;
            
    background-color: white;
    position: relative;
    border-radius: 49.5px;
    z-index: 1;
    .switch {
        display: inline-block;
        position: relative;
        width: 74px;
        height: 44px;
        height: 44px;
        left: -10px;
        top: 8px;
        border-radius: 49.5px;
        box-shadow: none!important;
        z-index: 2;
        background: #7CBAA7;
        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        vertical-align: middle;
        cursor: pointer;
    }
    .switch::before {
        content: '';
        position: absolute;
        top: 7px;
        left: 37px;
        width: 30px;
        height: 30px;
        background: white;
        border-radius: 50%;
        transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .switch:active::before {
        box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
    }
    input:checked + .switch {
        background: #7CBAA7;
    }
    input:checked + .switch::before {
        left: 8px;
        background: #fff;
    }
    input:checked + .switch:active::before {
        box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(0,150,136,0.2);
    }
            }
       .mode-set{
        position: relative;
       
        top: -37px;
        left: 104px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        transform: rotate(-90deg);
        z-index: 1;
       }
       .social-set{
        position: relative;
      
        top: 39px;
        left: 104px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #FFFFFF;
        transform: rotate(-90deg);
        z-index: 1;
       }
            p{
                font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    text-align: center;
    color: #202020;
            }
    .bottom-tabs{
    color: #394649;
    margin-top: 50px;
     h6{
        font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    color: #192529;
    }
    
    }
    
    .name-btn{
        background: #f0f4f8;
        border: 1.14031px solid #f0f4f8;
        box-shadow: inset -4.80637px -4.80637px 7.69018px #ffffff, inset 3.84509px 3.84509px 7.69018px #889fb6;
    border-radius: 4px;
    font-weight: 600;
    font-size: 6px;
    line-height: 100%;
    padding: 8px 10px;
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #202020;
    
    }
    
    .info-icon{
        border: 3px solid #394649 ;
        padding: 5px 11px 8px 14px;
        font-size: 19px;
    margin: 20px 0px;
        color: #394649;
    
        border-radius: 50px;
    }
    .fa-magnify{
        font-size:27px;
        margin: 10px 0px 55px;
    color: #394649;
    }
        }
        .content-hero{
    width:92%;
    .content-text{
        background: #f0f4f8;
    }
        }
      }
     .content-text{
        position: relative;
        .dots{
            position: relative;
        top: -88px;
        left: 5px;
        }
        .tab-right-section{
            width: 15%;
        }
        .nav-item .active {
            background: linear-gradient(#f0f4f8, #f0f4f8) padding-box,
                linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
            border: 3px solid transparent !important;
            box-shadow: none;
        }
        .nav-item {
            background: #f0f4f8;
            /* Neo/Light/Inner/4 */

            box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
            border-radius: 5px;
            padding: 10px;
            z-index: 2;
        }
        .tab-section {
            width: 56%;
            margin: auto auto auto 183px;
            margin-top: -63px !important;
        }
     
        .tab-content{
            margin-top: -75px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 61px 33px 20px;
            position: relative;
            height: 402px;
            background: #f0f4f8;
            box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border: 1px solid #facb30;
            border-radius: 8px;
       
        }
    .tab-himself-text{
margin-top: 80px;
    p{
        font-size: 10px!important;
        height: 200px;
        overflow: auto;
        padding-right: 10px;
        ::-webkit-scrollbar {
            width: 10px;
          }
          
          /* Track */
          ::-webkit-scrollbar-track {
            background: #f1f1f1; 
          }
           
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #888; 
          }
          
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #555; 
          }
    }
    
    }
    .playlist-section{
        
    
    position: absolute;
        left: 55px;
        z-index: 1;
        top: 5%;
    h4{
        margin-bottom: 40px;
        width: 130px;
        position: relative;
        left: -8px !important;
        font-size: 18px;
    }
    
    /* Grey/8
    
    Base color for Light mode
    */
    
    }
    .playlist-section-text{
        position: absolute;
        background: #F0F4F8;
        box-shadow: inset -1.06667px -1.06667px 3.2px #FFFFFF, inset 1.06667px 1.06667px 3.2px #B2BDCB!important;
        border-radius: 8px;
        padding: 10px;
        margin-top: 103px;
        left: -24px;
        width: 176px;
        background: #F0F4F8;
        box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
        border-radius: 8px;
    // @media screen and (max-width:1580px) {
    //     right: -193px;
    //     width: 350px;
    
    // }
    input[type=range].range-slider{
    
        width: 48px;
        margin-right: 7px;
    
    }
    .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
        height: 20px !important;
        width: 26px !important;
        bottom: -24px !important;
        border-radius: 50% !important;
        display: flex !important;
        color:#1C1C1C;
        justify-content: center !important;
        align-items: center !important;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        font-size: 8px;
    }
    .range-slider__wrap .range-slider__tooltip.range-slider__tooltip--bottom .range-slider__tooltip__caret::before {
        border-bottom-color: #C49530 !important;
        border-width: 0 0.3rem 0.3rem;
        bottom: 25px;
        left: 2px;
    }
    .range-slider__wrap {
        position: relative;
        display: block;
        height: 16px!important;
    
        .range-slider__tooltip {
            z-index: 0 !important;
    
            &.range-slider__tooltip--bottom {
                bottom: calc(0.75em + 0.375rem + 1px + 0.4rem + 10px + 3px) !important;
                top: auto !important;
    
        
            }
    
            .range-slider__tooltip__caret {
                transform: translateX(50%) !important;
            }
    
        
        }
    }
    input[type="range"].range-slider {
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            box-sizing: border-box;
            border: none;
            border-radius: 50%;
            height: 21px !important;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);

                            border: 1px solid black;
            width: 21px !important;
            cursor: pointer;
            transition: box-shadow 0.5s ease;
            margin-top: -3px !important;
        }
    }
    input[type="range"]::-ms-fill-lower {
        background-color: #43e5f7; 
        }
        input[type="range"]::-ms-fill-upper {  
        background-color: #9a905d;
        }
    input[type="range"].range-slider {
        box-shadow: none;
        height:8px;
        padding: 0px;
    }
    input[type="range"].range-slider {
    
        &::-webkit-slider-runnable-track {
            -webkit-appearance: none !important;
            width: 100% !important;
            margin-top: 8px;
            height: 1px !important;
            background-color: #202020!important;
            border: 2px solid #202020;
            outline: none !important;
            
            -webkit-transition: 0.2s !important;
        
            border-radius: 30px !important;
        
        }
    }
    .next-icon{
        background: #f0f4f8;
        box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
        color: #202020!important;
        border-radius: 23.0737px;
        width: 20px;
        padding: 2px;
        text-align: center;
        font-size: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
    }
    .setting-icons{
        background: #F0F4F8;
        margin-left: 3px;
        box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
        border-radius: 24.0118px;
        padding: 0px;
        color: #202020;
        font-size: 7px;
        display: flex;
        margin-top: -5px;
        .icons-set{
            margin: 4px 4px;
        }
    }
    .side-text{
        /* Grey/9 */
    
        background: #F0F4F8;
        box-shadow: 1.75824px 1.75824px 3.51648px #B7C7D7, -1.75824px -1.75824px 3.51648px #FFFFFF;
    border-radius: 2.38694px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95px;
    padding: 7.139px 0.36517px 3.97823px;
    font-weight: 500;
    font-size: 5.40381px;
    line-height: 100%;
    margin: 0px 10px;
    /* or 5px */
    border: 2px solid #DDA01A;
    
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #1C1C1C;
    }
    .text-gree{
        display: flex;
        position: relative;
        top: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.27619px 5.2419px;
    width: 60.48px;
    display: flex;
        justify-content: center;
        margin: auto;
        font-size: 8px;
    height: 10.55px;
    
    background: #F0F4F8;
    box-shadow: inset 0px -1.75824px 3.51648px #FFFFFF, inset 0px 1.75824px 2.63736px #A0B9D0;
    border-radius: 1.08597px;
    font-style: normal;
    font-weight: 700;
    font-size: 4.44588px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #101010;
    }
    .next-icon-center{
        
        background: #f0f4f8;
        box-shadow: 3.2967px 3.2967px 6.59341px #b7c7d7, -3.2967px -3.2967px 6.59341px #ffffff;
        border-radius: 50.7836px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -4px 2px 0px;
        .iconss{
    font-size: 9px;
    color: #c49530;
    margin: 4px;
        }
    
    }
    
    .video-icon{
        font-size: 25px;
        color: #202020;
        margin: 0px 2px;
        svg{
            margin-top: -11px;
        }
    }
    .mic-img{
        
        padding: 0px 5px ;
        img{
            width: 44px;
        }
    }
    }
    .btn-video{
        position: absolute;
        right: 3%;
        top: 36px;
        display: flex;
    flex-direction: row;
    align-items: center;
    padding: 17px 25px;
    width: 114px;
    height: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    
    /* Others/1 */
    
    background: #304053;
    border-radius: 4px;
    
    }
    .video-section{
        width: 23%;
        position: absolute;
        background: #F0F4F8;
        /* Neo/Light/Inner/4 */
        
        box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
        border-radius: 8px;
        right: -7px;
        height: 25%;
        bottom: 27%;
        display: flex;
        justify-content: center;
        align-items: center;
    .video-img{
        background-image:url(../../../../../images/video-img.png);
        background-repeat: no-repeat;
        background-size: cover;
        margin: 13px;
        width: 135px;
        height: 83.63px;
        border-radius: 10.5263px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 50px;
            height: 50px;
        }
    }
    }
     }
     .design-about{
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 18px;
    width: 61px;
    height: 80px;
    position: relative;
    z-index: 1;
    color: #202020 !important;
    background-color: #f0f4f8!important;
    box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb !important;
    border-radius: 4px;
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
     }
      .content-img{
        position: relative;
        width: 100%;
      }
      .hero-section{
        position: relative;
      }
      .hero-part{
        position: absolute;
        top: 36px;
        left: 80px;
        h1{
            font-weight: 700;
            font-size: 56px;
            line-height: 68px;
    color: #FFFFFF;
    
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        }
        p{
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
    color: #FFFFFF;
    
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        }
      }
    //   @media screen and (min-width:1500px) {
    //     .hero-part{
    //         position: absolute;
    //         top: 80px;
    //         left: 80px;
    //         h1{
    //             font-weight: 700;
    //     font-size: 68px;
    //     line-height: 90px;
    //     color: #FFFFFF;
        
    //     text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    //         }
    //         p{
    //             font-weight: 400;
    //     font-size: 20px;
    //     line-height: 24px;
    //     color: #FFFFFF;
        
    //     text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    //         }
    //       }
    //   }
      .content-hero{
        width: 92%;
      }
    }

    .img-logo-oter{
        background: #F0F4F8;
/* Neo/Light/Inner/3 */

box-shadow: inset -4px -4px 10px #FFFFFF, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
border-radius: 15px;
padding: 10px;
margin-left: 10px;
.img-logo{
    border-radius: 15px;
    margin-left: 0px!important;
}
    }

}
.Neumorphic-design .design-screen-tab-1 .bars-seeall .hamburger-outer .testimonial .timeline-top .icon-green {
    font-size: 8px;
    color: #cfa84d !important;
    padding-top: 0px !important;
    padding-bottom: 2px;
}
.Neumorphic-design .design-screen-tab-1 .bars-seeall .hamburger-outer .testimonial .timeline-top{
    width: 68px;
    padding: 8px;
    height: 68px;
    top: 79px;
    left: 20px;
}

.modal-setting {
    .modal .modal-dialog {
        max-width: 99% !important;
        top: 130px;
        margin: auto;
    }
    @media screen and (min-width: 1920px) {
        .modal .modal-dialog {
            top: 130px;
        }
    }
    @media screen and (min-width: 576px) {
        .modal .modal-dialog {
            max-width: 99% !important;
        }
    }
    @media screen and (min-width: 576px) {
        .modal .modal-dialog {
            max-width: 99% !important;
        }
    }
    .modal-90w {
        width: 90% !important;
    }

    .modal-content {
        background: linear-gradient(151.05deg, #ebf2f7 13.27%, #f2f6f9 83.27%) !important;
        box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8),
            2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4) !important;
        border: 1px solid transparent !important;
        border-radius: 2.84939px;
    }
    .modal-header {
        border-bottom: none;
    }
    .screen-btn {
        background: linear-gradient(49.05deg, #f0bf25 -0.22%, #e28d0e 101.07%);
        box-shadow: -6px -6px 15px #ffffff, 6px 6px 15px rgba(178, 189, 203, 0.7);
        border-radius: 25px;
        padding: 15px;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #202020;
        width: 143px;
        margin: 30px auto;
        display: flex;
        justify-content: center;
        border: none;
    }
}

// @media screen and  (min-width: 576px){
//     .modal-dialog {
//         max-width: 100%!important;
//         margin-right: auto;
//         margin-left: auto;
//     }
// }
@media (min-width: 576px) {
    .modal-dialog.modal-90w {
        max-width: 100% !important;
    }
}
.banner-btn {
    .btn-left {
        button {
            top: 12px;
            transform: rotate(7deg);
        }
    }
    .btn-bg {
        background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%) !important;
        box-shadow: inset -4.55556px -4.55556px 7.59259px rgba(255, 255, 255, 0.75),
            inset 3.03704px 3.03704px 7.59259px rgba(166, 180, 200, 0.4) !important;
        border-radius: 3px;
        border: 1px solid #f2e6cf;
        button {
            background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
            border-radius: 5px;
            color: #202020 !important;
        }
    }
    .ml-3 {
        margin-left: 40px;
    }
}
.modal-dialog.modal-90w {
    width: 99%;
    top: 130px;
    margin: auto;
    .modal-content {
        background: linear-gradient(151.05deg, #ebf2f7 13.27%, #f2f6f9 83.27%) !important;
        box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8),
            2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4) !important;
        border: 1px solid transparent !important;
        border-radius: 2.84939px;
        .modal-header {
            border-bottom: none;
        }
    }
}
.screen-btn {
    cursor: pointer;
}
