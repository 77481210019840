@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

///Regular Version Theme 2 
.regular2-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbd5e0;
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 540px;
            height: 600px;
            background-color: rgba(124, 186, 167, 1);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 20px 20px 20px;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 63%;
            }

            h1 {
                font-weight: 400;
                font-size: 20px;
                color: rgba(32, 32, 32, 1);
                margin-bottom: 10px;
                font-family: Inter;

            }

            .img-div {
                width: 160px;
                height: 160;
                position: absolute;
                top: 15%;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;

                }
            }

            .thought-outer-div {
                width: 500px;
                height: 380px;
                background-size: contain;
                position: absolute;
                top: 25%;
                z-index: 0;
                background: url('../../../../../images/testimonial-case-study/regular2_thought.png');
                padding-top: 10px;

                .thought-inner-div {
                    background: url('../../../../../images/testimonial-case-study/regular2_thoughts_frame.png');
                    background-size: cover;
                    width: 480px;
                    height: 352px;
                    margin-left: 10px;
                    padding-top: 100px;

                    h1 {
                        font-size: 16px;
                        font-weight: 600;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;

                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(95, 97, 104, 1);
                        font-family: Inter;
                        width: 360px;
                    }
                }
            }

            .see-my-wall-div {
                margin-top: 0px;
                position: absolute;
                top: 84%;
                left: 5%;
                color: rgba(32, 32, 32, 1);

                p {
                    text-align: start;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(255, 255, 255, 1);
                    color: rgba(32, 32, 32, 1);
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}

.regular2-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cbd5e0;
    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 540px;
            height: 600px;
            background-color: rgba(124, 186, 167, 1);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 20px 20px 20px;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 63%;
            }

            h1 {
                font-weight: 400;
                font-size: 20px;
                color: rgba(32, 32, 32, 1);
                margin-bottom: 10px;
                font-family: Inter;

            }

            .img-div {
                width: 160px;
                height: 160;
                position: absolute;
                top: 15%;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;

                }
            }

            .thought-outer-div {
                width: 500px;
                height: 380px;
                background-size: contain;
                position: absolute;
                top: 25%;
                z-index: 0;
                background: url('../../../../../images/testimonial-case-study/regular2_thought.png');
                padding-top: 10px;

                .thought-inner-div {
                    background: url('../../../../../images/testimonial-case-study/regular2_thoughts_frame.png');
                    background-size: cover;
                    width: 480px;
                    height: 352px;
                    margin-left: 10px;
                    padding-top: 100px;

                    h1 {
                        font-size: 16px;
                        font-weight: 600;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;

                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(95, 97, 104, 1);
                        font-family: Inter;
                        width: 360px;
                    }
                }
            }

            .see-my-wall-div {
                margin-top: 0px;
                position: absolute;
                top: 84%;
                left: 5%;
                color: rgba(32, 32, 32, 1);

                p {
                    text-align: start;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(255, 255, 255, 1);
                    color: rgba(32, 32, 32, 1);
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}


.regular2-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
    width: 320px;
    height: 1000px;
    margin-bottom: 30px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/regular_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 304px;
            height: 491px;
            background-color: rgba(124, 186, 167, 1);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 20px 20px 20px;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 72%;
            }

            h1 {
                font-weight: 400;
                font-size: 16px;
                color: rgba(32, 32, 32, 1);
                margin-bottom: 10px;
                font-family: Inter;

            }

            .img-div {
                width: 160px;
                height: 160;
                position: absolute;
                top: 15%;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;

                }
            }

            .thought-outer-div {
                width: 280px;
                height: 296px;
                background-size: cover;
                position: absolute;
                top: 30%;
                z-index: 0;
                background: url('../../../../../images/testimonial-case-study/regular2_thought_mobile.png');
                padding-top: 10px;

                .thought-inner-div {
                    background: url('../../../../../images/testimonial-case-study/regular2_thoughts_frame_mobile.png');
                    background-size: cover;
                    width: 260px;
                    height: 270px;
                    margin-left: 10px;
                    padding-top: 70px;

                    h1 {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                    }

                    p {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                        margin: 0px;


                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(95, 97, 104, 1);
                        font-family: Inter;
                        line-height: 24px;
                        display: block;
                        text-align: center;
                        width: 240px;
                        margin-left: 8px;
                    }
                }
            }

            .see-my-wall-div {
                margin-top: 0px;
                position: absolute;
                top: 85%;
                left: 0%;
                color: rgba(32, 32, 32, 1);

                p {
                    text-align: start;
                    font-size: 13px;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(255, 255, 255, 1);
                    color: rgba(32, 32, 32, 1);
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//

.neumorphic2-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(240, 244, 248, 1);
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic2_tab_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        background-color: rgba(240, 244, 248, 1);

        .profile-div {
            width: 560px;
            height: 666px;
            background-color: rgba(240, 244, 248, 1);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 20px 20px 20px;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 63%;
            }

            h1 {
                font-weight: 400;
                font-size: 20px;
                color: rgba(32, 32, 32, 1);
                margin-bottom: 10px;
                font-family: Inter;

            }

            .img-div {
                width: 160px;
                height: 160;
                position: absolute;
                top: 15%;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;

                }
            }

            .thought-outer-div {
                width: 520px;
                height: 380px;
                background-size: contain;
                position: absolute;
                top: 25%;
                z-index: 0;
                background: url('../../../../../images/testimonial-case-study/neumorphic2_thought.png');
                padding-top: 10px;

                .thought-inner-div {
                    background: url('../../../../../images/testimonial-case-study/neumorphic2_thoughts_frame.png');
                    background-size: cover;
                    width: 480px;
                    height: 352px;
                    margin-left: 10px;
                    padding-top: 100px;
                    transform: translate(10px, 10px);

                    h1 {
                        font-size: 16px;
                        font-weight: 600;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;

                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(95, 97, 104, 1);
                        font-family: Inter;
                        width: 360px;
                    }
                }
            }

            .see-my-wall-div {
                margin-top: 0px;
                position: absolute;
                top: 84%;
                left: 5%;
                color: rgba(32, 32, 32, 1);

                p {
                    text-align: start;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(255, 255, 255, 1);
                    color: rgba(32, 32, 32, 1);
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}


.neumorphic2-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(240, 244, 248, 1);
    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic2_tab_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
        background-color: rgba(240, 244, 248, 1);

        .profile-div {
            width: 560px;
            height: 666px;
            background-color: rgba(240, 244, 248, 1);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 20px 20px 20px;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 63%;
            }

            h1 {
                font-weight: 400;
                font-size: 20px;
                color: rgba(32, 32, 32, 1);
                margin-bottom: 10px;
                font-family: Inter;

            }

            .img-div {
                width: 160px;
                height: 160;
                position: absolute;
                top: 15%;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;

                }
            }

            .thought-outer-div {
                width: 520px;
                height: 380px;
                background-size: contain;
                position: absolute;
                top: 25%;
                z-index: 0;
                background: url('../../../../../images/testimonial-case-study/neumorphic2_thought.png');
                padding-top: 10px;

                .thought-inner-div {
                    background: url('../../../../../images/testimonial-case-study/neumorphic2_thoughts_frame.png');
                    background-size: cover;
                    width: 480px;
                    height: 352px;
                    margin-left: 10px;
                    padding-top: 100px;
                    transform: translate(10px, 10px);

                    h1 {
                        font-size: 16px;
                        font-weight: 600;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;

                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(95, 97, 104, 1);
                        font-family: Inter;
                        width: 360px;
                    }
                }
            }

            .see-my-wall-div {
                margin-top: 0px;
                position: absolute;
                top: 84%;
                left: 5%;
                color: rgba(32, 32, 32, 1);

                p {
                    text-align: start;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    border-radius: 20px;
                    background-color: rgba(255, 255, 255, 1);
                    color: rgba(32, 32, 32, 1);
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}



.neumorphic2-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
    width: 320px;
    height: 1000px;
    margin-bottom: 30px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/neumorphic2_tab_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 284px;
            height: 540px;
            background: rgba(240, 244, 248, 1);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 20px 20px 20px;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 68%;
            }

            h1 {
                font-weight: 400;
                font-size: 14px;
                color: rgba(32, 32, 32, 1);
                margin-bottom: 10px;
                font-family: Inter;

            }

            .img-div {
                width: 160px;
                height: 160;
                position: absolute;
                top: 15%;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;

                }
            }

            .thought-outer-div {
                width: 265px;
                height: 286px;
                background-size: contain;
                position: absolute;
                top: 30%;
                z-index: 0;
                background: url('../../../../../images/testimonial-case-study/glassmorphic2_thoughts_frame_mobile.png');
                padding-top: 10px;

                .thought-inner-div {
                    background: url('../../../../../images/testimonial-case-study/glassmorphic2_thought_mobile.png');
                    background-size: contain;
                    width: 247px;
                    height: 257px;
                    margin-left: 10px;
                    padding-top: 70px;

                    h1 {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                        font-weight: 600;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                        margin: 0px;
                        font-weight: 400;
                        transform: translateY(-10px);

                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(95, 97, 104, 1);
                        font-family: Inter;
                        line-height: 24px;
                        display: block;
                        text-align: center;
                        width: 240px;
                        margin-left: 8px;
                        transform: translateY(-12px);
                    }
                }
            }

            .see-my-wall-div {
                margin-top: 0px;
                position: absolute;
                top: 85%;
                left: 0%;
                color: rgba(32, 32, 32, 1);

                p {
                    text-align: start;
                    font-size: 13px;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    background-color: #F0F4F8;
                    border-radius: 16px;
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}

//////////////////////////////////
.glassmorphic2-desktop-theme {

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(47, 38, 38, 0.27);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(47, 38, 38, 0.3);
    width: 1440px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/glassmorphic2_desktop_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 1000px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 540px;
            height: 600px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 20px 20px 20px;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 63%;
            }

            h1 {
                font-weight: 400;
                font-size: 20px;
                color: rgba(32, 32, 32, 1);
                margin-bottom: 10px;
                font-family: Inter;

            }

            .img-div {
                width: 160px;
                height: 160;
                position: absolute;
                top: 15%;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;

                }
            }

            .thought-outer-div {
                width: 500px;
                height: 380px;
                background-size: contain;
                position: absolute;
                top: 25%;
                z-index: 0;
                background: url('../../../../../images/testimonial-case-study/glassmorphic2_thought.png');
                padding-top: 10px;

                .thought-inner-div {
                    background: url('../../../../../images/testimonial-case-study/glassmorphic2_thoughts_frame.png');
                    background-size: cover;
                    width: 480px;
                    height: 352px;
                    margin-left: 10px;
                    padding-top: 100px;

                    h1 {
                        font-size: 16px;
                        font-weight: 600;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;

                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(95, 97, 104, 1);
                        font-family: Inter;
                        width: 360px;
                    }
                }
            }

            .see-my-wall-div {
                margin-top: 0px;
                position: absolute;
                top: 84%;
                left: 5%;
                color: rgba(32, 32, 32, 1);

                p {
                    text-align: start;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 16px;
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}

.glassmorphic2-tab-theme {

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(47, 38, 38, 0.27);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(47, 38, 38, 0.3);

    width: 768px;
    height: 1200px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/glassmorphic2_tab_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 728px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 540px;
            height: 600px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 20px 20px 20px;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 63%;
            }

            h1 {
                font-weight: 400;
                font-size: 20px;
                color: rgba(32, 32, 32, 1);
                margin-bottom: 10px;
                font-family: Inter;

            }

            .img-div {
                width: 160px;
                height: 160;
                position: absolute;
                top: 15%;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;

                }
            }

            .thought-outer-div {
                width: 500px;
                height: 380px;
                background-size: contain;
                position: absolute;
                top: 25%;
                z-index: 0;
                background: url('../../../../../images/testimonial-case-study/glassmorphic2_thought.png');
                padding-top: 10px;

                .thought-inner-div {
                    background: url('../../../../../images/testimonial-case-study/glassmorphic2_thoughts_frame.png');
                    background-size: cover;
                    width: 480px;
                    height: 352px;
                    margin-left: 10px;
                    padding-top: 100px;

                    h1 {
                        font-size: 16px;
                        font-weight: 600;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;

                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(95, 97, 104, 1);
                        font-family: Inter;
                        width: 360px;
                    }
                }
            }

            .see-my-wall-div {
                margin-top: 0px;
                position: absolute;
                top: 84%;
                left: 5%;
                color: rgba(32, 32, 32, 1);

                p {
                    text-align: start;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 16px;
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}


.glassmorphic2-mobile-theme {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: none;
    width: 320px;
    height: 1000px;
    margin-bottom: 30px;

    .dot-container {
        background-image: url("../../../../../images/testimonial-case-study/glassmorphic2_tab_dots.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: fit-content;
        width: 320px;
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-top: 100px;

        .profile-div {
            width: 304px;
            height: 491px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 40px 20px 20px 20px;
            position: relative;

            .rating-stars-div {
                position: absolute;
                top: 72%;
            }

            h1 {
                font-weight: 400;
                font-size: 16px;
                color: rgba(32, 32, 32, 1);
                margin-bottom: 10px;
                font-family: Inter;

            }

            .img-div {
                width: 160px;
                height: 160;
                position: absolute;
                top: 15%;
                z-index: 1;

                img {
                    width: 100%;
                    height: 100%;

                }
            }

            .thought-outer-div {
                width: 265px;
                height: 286px;
                background-size: contain;
                position: absolute;
                top: 30%;
                z-index: 0;
                background: url('../../../../../images/testimonial-case-study/glassmorphic2_thoughts_frame_mobile.png');
                padding-top: 10px;

                .thought-inner-div {
                    background: url('../../../../../images/testimonial-case-study/glassmorphic2_thought_mobile.png');
                    background-size: contain;
                    width: 247px;
                    height: 257px;
                    margin-left: 10px;
                    padding-top: 70px;

                    h1 {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                        font-weight: 600;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(32, 32, 32, 1);
                        text-align: center;
                        font-family: Inter;
                        margin: 0px;
                        font-weight: 400;
                        transform: translateY(-10px);

                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        color: rgba(95, 97, 104, 1);
                        font-family: Inter;
                        line-height: 24px;
                        display: block;
                        text-align: center;
                        width: 240px;
                        margin-left: 8px;
                        transform: translateY(-12px);
                    }
                }
            }

            .see-my-wall-div {
                margin-top: 0px;
                position: absolute;
                top: 85%;
                left: 0%;
                color: rgba(32, 32, 32, 1);

                p {
                    text-align: start;
                    font-size: 13px;
                }

                a {
                    width: 128px;
                    height: 30px;
                    padding: 7px 14px 7px 14px;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: 16px;
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(5px);
                    -webkit-backdrop-filter: blur(5px);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
    }
}