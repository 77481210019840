@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');
// ammar css start
// .tablet-regular-help-section{
//     padding: 40px 20px !important;
//     .top-desc {
//         h2 {
//             font-size: 30px;
//             font-weight: 600;
//             line-height: 36px;
//             letter-spacing: 0em;
//         }

//         p {
//             font-family: Inter;
//             font-size: 14px;
//             font-weight: 400;
//             line-height: 17px;
//             letter-spacing: 0em;
//         }
//     }

//     .programs-flex {
//         flex-wrap: wrap;
//         gap: 40px;
//         margin-top: 40px;
//     }

 

//     .timeline-section-content {
//         display: block !important;
//         margin-top: 100px;
     
        
//     }
   
// }
.Feature-on-tab-1{

  .features-on-design-tab-1 {
    
    background-color: #cbd5e0;
    width: 768px;
    margin: auto;
    

    .grey-box-tab {
        background-color: #CBD5E0;
        padding: 80px 80px 80px 80px;
      }
      
      .image-circle-tab {
        background-color: #ffffff;
        
        width: 90px;
        height: 90px;
        border-radius: 50%;
        display: flex;
        
        align-items: center;
        justify-content: center;
        margin: 7px 10px 0px 0px ;
        
        z-index: 90;
      }
      .image-usa{
        background-color: #1EA3FF ;
      }
    //   .image-line {
    //     position: absolute;
    //     width: 100%;
    //     height: 3px;
     
    //     top: 50%;
    //     left: 0;
    //     transform: translateY(-50%);
    //   }

    .line-update-tab{
          margin: 81px 20px -60px 10px ;
        height: 12px;
        width: 75%;
        background: linear-gradient(to bottom, #7CBAA7 0%, #7CBAA7 33%, #304053 33%,#304053 66%,  #7CBAA7 66%, #7CBAA7 100%);
      }
      
      .curve-image-tab{
    
    padding-top: 20px;
    padding-bottom: 20px;
      }
    

}

}
// ammr css end