@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');
// ammar css start
.tablet-regular-help-section{
    padding: 40px 20px !important;
    .top-desc {
        h2 {
            font-size: 30px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0em;
        }

        p {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
        }
    }

    .programs-flex {
        flex-wrap: wrap;
        gap: 40px;
        margin-top: 40px;
    }

 

    .timeline-section-content {
        display: block !important;
        margin-top: 100px;
     
        
    }
   
}

// ammr css end