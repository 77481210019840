@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

.desktop-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cbd5e0;
  width: 100%;
  height: 1200px;

  .desktop-dot-container {
    background-image: url("../../../../../images/testimonial-case-study/regular_desktop_dots.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    width: 1000px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 100px;

    .desktop-profile-div {
      width: 480px;
      height: 700px;
      background-color: rgba(32, 32, 32, 1);
      background-image: url("../../../../../images/testimonial-case-study/regular_profile_bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .desktop-profile-detail-avatar-div {
        position: absolute;
        height: fit-content;
        width: fit-content;
      }

      .desktop-profile-detail-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        height: 578px;

        .desktop-profile-detail-avatar-div {
          top: 31%;
        }

        .desktop-profile-detail-heading-div {
          width: 400px;
          height: 221px;
          padding: 40px 20px 20px 20px;
          background-image: url("../../../../../images/testimonial-case-study/regular_profile_heading.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            font-weight: 700;
            font-size: 36px;
            line-height: 43.57px;
          }

          span {
            font-family: "Dancing Script", cursive;
            font-weight: 700;
            transform: translateY(-45px);
            font-size: 50px;
            color: rgba(124, 186, 167, 1);
            line-height: 60px;
          }
        }

        .profile-desc-outer-div {
          background-image: url("../../../../../images/testimonial-case-study/regular1_profile_outer.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 400px;
          height: 280;
          display: flex;
          margin-top: 15px;

          .desktop-profile-detail-desc-div {
            width: 377px;
            height: 319px;
            background-image: url("../../../../../images/testimonial-case-study/regular_profile_desc.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: black;
            justify-content: center;
            position: relative;
            transform: translate(12px, 17px);
            z-index: 1;

            p {
              width: 340px;
              height: 57px;
              font-weight: 400;
              text-align: center;
              font-family: Inter;
              font-size: 12px;
            }

            h3 {
              width: 128px;
              font-weight: 600;
              font-size: 16px;
              line-height: 19.36px;
            }

            h4 {
              font-weight: 400;
              font-size: 14px;
              line-height: 16.94px;
            }
          }
        }
      }

      .see-my-wall-div {

        a {
          width: 128px;
          height: 30px;
          padding: 7px 14px 7px 14px;
          border-radius: 20px;
          background-color: rgba(124, 186, 167, 1);
          color: rgba(32, 32, 32, 1);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

///For Tablet
.tablet-main-container {
  display: flex;
  justify-content: center;
  background-color: #cbd5e0;
  width: 768px;
  height: 1200px;

  .desktop-dot-container {
    background-image: url("../../../../../images/testimonial-case-study/regular_desktop_dots.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    width: 728px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 100px;

    .desktop-profile-div {
      width: 480px;
      height: 700px;
      background-color: rgba(32, 32, 32, 1);
      background-image: url("../../../../../images/testimonial-case-study/regular_profile_bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .desktop-profile-detail-avatar-div {
        position: absolute;
        height: fit-content;
        width: fit-content;
      }

      .desktop-profile-detail-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        height: 578px;

        .desktop-profile-detail-avatar-div {
          top: 31%;
        }

        .desktop-profile-detail-heading-div {
          width: 400px;
          height: 221px;
          padding: 40px 20px 20px 20px;
          background-image: url("../../../../../images/testimonial-case-study/regular_profile_heading.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            font-weight: 700;
            font-size: 36px;
            line-height: 43.57px;
          }

          span {
            font-family: "Dancing Script", cursive;
            font-weight: 700;
            transform: translateY(-45px);
            font-size: 50px;
            color: rgba(124, 186, 167, 1);
            line-height: 60px;
          }
        }

        .profile-desc-outer-div {
          background-image: url("../../../../../images/testimonial-case-study/regular1_profile_outer.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 400px;
          height: 280;
          display: flex;
          margin-top: 15px;

          .desktop-profile-detail-desc-div {
            width: 377px;
            height: 319px;
            background-image: url("../../../../../images/testimonial-case-study/regular_profile_desc.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: black;
            justify-content: center;
            position: relative;
            transform: translate(12px, 17px);
            z-index: 1;

            p {
              width: 340px;
              height: 57px;
              font-weight: 400;
              text-align: center;
              font-family: Inter;
              font-size: 12px;
            }

            h3 {
              width: 128px;
              font-weight: 600;
              font-size: 16px;
              line-height: 19.36px;
            }

            h4 {
              font-weight: 400;
              font-size: 14px;
              line-height: 16.94px;
            }
          }
        }
      }

      .see-my-wall-div {

        a {
          width: 128px;
          height: 30px;
          padding: 7px 14px 7px 14px;
          border-radius: 20px;
          background-color: rgba(124, 186, 167, 1);
          color: rgba(32, 32, 32, 1);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

///For Mobile
.mobile-main-container {
  display: flex;
  justify-content: center;
  width: 320px;
  height: 1000px;

  .desktop-dot-container {
    background-image: url("../../../../../images/testimonial-case-study/regular_mobile_dots.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    width: 320px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    .desktop-profile-div {
      width: 300px;
      height: 660px;
      background-color: rgba(32, 32, 32, 1);
      background-image: url("../../../../../images/testimonial-case-study/regular_profile_bg_mobile.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .desktop-profile-detail-avatar-div {
        position: absolute;
        height: fit-content;
        width: fit-content;
      }

      .desktop-profile-detail-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 280px;
        height: 540px;

        .desktop-profile-detail-avatar-div {
          top: 25%;
        }

        .desktop-profile-detail-heading-div {
          width: 280px;
          height: 181px;
          padding: 40px 20px 20px 20px;
          background-image: url("../../../../../images/testimonial-case-study/regular_profile_heading_mobile.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            font-weight: 700;
            font-size: 24px;
            line-height: 43.57px;
          }

          span {
            font-family: "Dancing Script", cursive;
            font-weight: 700;
            transform: translateY(-45px);
            font-size: 36px;
            color: rgba(124, 186, 167, 1);
            line-height: 60px;
          }
        }

        .profile-desc-outer-div {
          background-image: url("../../../../../images/testimonial-case-study/regular1_profile_outer_mobile.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 280px;
          height: 280px;
          display: flex;
          margin-top: 10px;

          .desktop-profile-detail-desc-div {
            width: 260px;
            height: 294px;
            background-image: url("../../../../../images/testimonial-case-study/regular_profile_desc_mobile.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: black;
            justify-content: center;
            position: relative;
            transform: translate(12px, 17px);
            z-index: 1;

            p {
              width: 240px;
              height: 57px;
              font-weight: 400;
              text-align: center;
              font-family: Inter;
              font-size: 11px;
            }

            h3 {
              width: 128px;
              font-weight: 600;
              font-size: 16px;
              line-height: 19.36px;
            }

            h4 {
              font-weight: 400;
              font-size: 14px;
              line-height: 16.94px;
            }
          }
        }
      }

      .see-my-wall-div {

        a {
          width: 128px;
          height: 30px;
          padding: 7px 14px 7px 14px;
          border-radius: 20px;
          background-color: rgba(124, 186, 167, 1);
          color: rgba(32, 32, 32, 1);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Here Starts the Neumorphic Theme
Desktop Section For Neumorphic Theme*/
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.neumorphic-desktop-theme {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F4F8;
  width: 100%;
  height: 1200px;

  .desktop-dot-container {
    background-image: url("../../../../../images/testimonial-case-study/neumorphic_desktop_dots.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    width: 1000px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F0F4F8;
    margin-top: 100px;

    .desktop-profile-div {
      width: 480px;
      height: 700px;
      background-color: #F0F4F8;
      background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .desktop-profile-detail-avatar-div {
        position: absolute;
        height: fit-content;
        width: fit-content;
      }

      .desktop-profile-detail-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        height: 578px;

        .desktop-profile-detail-avatar-div {
          top: 31%;
        }

        .desktop-profile-detail-heading-div {
          width: 400px;
          height: 221px;
          padding: 40px 20px 20px 20px;
          background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_heading.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            font-weight: 700;
            font-size: 36px;
            line-height: 43.57px;
            color: #3C3D40;
          }

          span {
            font-family: "Dancing Script", cursive;
            font-weight: 700;
            transform: translateY(-45px);
            font-size: 50px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
            color: transparent;
            line-height: 60px;
          }
        }

        .profile-desc-outer-div {
          background-image: url("../../../../../images/testimonial-case-study/neumorphic1_profile_outer.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 400px;
          height: 280;
          display: flex;
          margin-top: 15px;

          .desktop-profile-detail-desc-div {
            width: 377px;
            height: 319px;
            background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_desc.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: black;
            justify-content: center;
            position: relative;
            transform: translate(12px, 17px);
            z-index: 1;

            p {
              width: 340px;
              height: 57px;
              font-weight: 400;
              text-align: center;
              font-family: Inter;
              font-size: 12px;
            }

            h3 {
              width: 128px;
              font-weight: 600;
              font-size: 16px;
              line-height: 19.36px;
            }

            h4 {
              font-weight: 400;
              font-size: 14px;
              line-height: 16.94px;
            }
          }
        }
      }

      .see-my-wall-div {

        a {
          width: 128px;
          height: 30px;
          padding: 7px 14px 7px 14px;
          border-radius: 20px;
          background-color: rgba(124, 186, 167, 1);
          background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

///For Tablet
.neumorphic-tablet-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F4F8;
  width: 768px;
  height: 1200px;

  .desktop-dot-container {
    background-image: url("../../../../../images/testimonial-case-study/neumorphic_desktop_dots.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    width: 1000px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F0F4F8;
    margin-top: 100px;

    .desktop-profile-div {
      width: 480px;
      height: 700px;
      background-color: #F0F4F8;
      background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .desktop-profile-detail-avatar-div {
        position: absolute;
        height: fit-content;
        width: fit-content;
      }

      .desktop-profile-detail-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        height: 578px;

        .desktop-profile-detail-avatar-div {
          top: 31%;
        }

        .desktop-profile-detail-heading-div {
          width: 400px;
          height: 221px;
          padding: 40px 20px 20px 20px;
          background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_heading.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            font-weight: 700;
            font-size: 36px;
            line-height: 43.57px;
            color: #3C3D40;
          }

          span {
            font-family: "Dancing Script", cursive;
            font-weight: 700;
            transform: translateY(-45px);
            font-size: 50px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
            color: transparent;
            line-height: 60px;
          }
        }

        .profile-desc-outer-div {
          background-image: url("../../../../../images/testimonial-case-study/neumorphic1_profile_outer.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 400px;
          height: 280;
          display: flex;
          margin-top: 15px;

          .desktop-profile-detail-desc-div {
            width: 377px;
            height: 319px;
            background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_desc.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: black;
            justify-content: center;
            position: relative;
            transform: translate(12px, 17px);
            z-index: 1;

            p {
              width: 340px;
              height: 57px;
              font-weight: 400;
              text-align: center;
              font-family: Inter;
              font-size: 12px;
            }

            h3 {
              width: 128px;
              font-weight: 600;
              font-size: 16px;
              line-height: 19.36px;
            }

            h4 {
              font-weight: 400;
              font-size: 14px;
              line-height: 16.94px;
            }
          }
        }
      }

      .see-my-wall-div {

        a {
          width: 128px;
          height: 30px;
          padding: 7px 14px 7px 14px;
          border-radius: 20px;
          background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}


.neumorphic-mobile-main-container {
  display: flex;
  justify-content: center;
  width: 320px;
  height: 1000px;

  .desktop-dot-container {
    background-image: url("../../../../../images/testimonial-case-study/neumorphic_desktop_dots.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    width: 320px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F0F4F8;

    .desktop-profile-div {
      width: 300px;
      height: 660px;
      background-color: #F0F4F8;
      background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_bg_mobile.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transform: translateX(10px);

      .desktop-profile-detail-avatar-div {
        position: absolute;
        height: fit-content;
        width: fit-content;
      }

      .desktop-profile-detail-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 280px;
        height: 540px;

        .desktop-profile-detail-avatar-div {
          top: 25%;
        }

        .desktop-profile-detail-heading-div {
          width: 250px;
          height: 181px;
          padding: 40px 20px 20px 20px;
          background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_heading_mobile.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            font-weight: 700;
            font-size: 20px;
            line-height: 43.57px;
            color: #3C3D40;
          }

          span {
            font-family: "Dancing Script", cursive;
            font-weight: 700;
            transform: translateY(-45px);
            font-size: 36px;
            line-height: 60px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
            -webkit-background-clip: text;
            -moz-background-clip: text;
            background-clip: text;
            color: transparent;
          }
        }

        .profile-desc-outer-div {
          background-image: url("../../../../../images/testimonial-case-study/neumorphic1_profile_mobile.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 240px;
          height: 280px;
          display: flex;
          margin-top: 15px;
          z-index: 0;

          .desktop-profile-detail-desc-div {
            width: 234px;
            height: 300px;
            background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_desc_mobile.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: black;
            justify-content: center;
            position: relative;
            transform: translate(4px, 10px);
            z-index: 1;

            p {
              width: 209px;
              height: 57px;
              font-weight: 400;
              text-align: center;
              font-size: 11px;
              color: #5F6168;
              margin: 0;
              line-height: 17.6px;
            }

            h3 {
              font-weight: 600;
              font-size: 14px;
              margin-top: 18px;
            }

            h4 {
              font-weight: 400;
              font-size: 12px;
              line-height: 14.52px;
            }
          }
        }
      }

      .see-my-wall-div {

        a {
          width: 128px;
          height: 30px;
          padding: 7px 14px 7px 14px;
          border-radius: 20px;
          background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
          color: rgba(32, 32, 32, 1);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/* Here Starts the Glassmorphic Theme
Desktop Section For Glassmorphic Theme*/
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.glassmorphic-desktop-theme {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.459);
  ;
  width: 100%;
  height: 1200px;

  .desktop-dot-container {
    background-image: url("../../../../../images/testimonial-case-study/glassmorphic_desktop_dots.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    width: 1000px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(128, 128, 128, 0.459);
    ;
    margin-top: 100px;

    .desktop-profile-div {
      width: 480px;
      height: 700px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .desktop-profile-detail-avatar-div {
        position: absolute;
        height: fit-content;
        width: fit-content;
      }

      .desktop-profile-detail-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        height: 578px;

        .desktop-profile-detail-avatar-div {
          top: 31%;
        }

        .desktop-profile-detail-heading-div {
          width: 400px;
          height: 221px;
          padding: 40px 20px 20px 20px;
          background-image: url("../../../../../images/testimonial-case-study/glassmorphic_profile_heading.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            font-weight: 700;
            font-size: 36px;
            line-height: 43.57px;
            color: #202020;
          }

          span {
            font-family: "Dancing Script", cursive;
            font-weight: 700;
            transform: translateY(-45px);
            font-size: 50px;
            color: #202020;
            line-height: 60px;
          }
        }

        .profile-desc-outer-div {
          background-image: url("../../../../../images/testimonial-case-study/glassmorphic1_profile_outer.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 400px;
          height: 280;
          display: flex;
          margin-top: 15px;

          .desktop-profile-detail-desc-div {
            width: 377px;
            height: 319px;
            background-image: url("../../../../../images/testimonial-case-study/glassmorphic_profile_desc.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: black;
            justify-content: center;
            position: relative;
            transform: translate(12px, 17px);
            z-index: 1;

            p {
              width: 340px;
              height: 57px;
              font-weight: 400;
              text-align: center;
              font-family: Inter;
              font-size: 12px;
            }

            h3 {
              width: 128px;
              font-weight: 600;
              font-size: 16px;
              line-height: 19.36px;
            }

            h4 {
              font-weight: 400;
              font-size: 14px;
              line-height: 16.94px;
            }
          }
        }
      }

      .see-my-wall-div {
        p {
          color: #202020;
        }

        a {
          width: 128px;
          height: 30px;
          padding: 7px 14px 7px 14px;
          border-radius: 20px;
          background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
          color: rgba(32, 32, 32, 1);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}


///For Tablet
.glassmorphic-tablet-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.459);
  width: 768px;
  height: 1200px;

  .desktop-dot-container {
    background-image: url("../../../../../images/testimonial-case-study/glassmorphic_tab_dots.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    width: 728px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(128, 128, 128, 0.459);
    ;
    margin-top: 100px;

    .desktop-profile-div {
      width: 480px;
      height: 700px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      background-image: url("../../../../../images/testimonial-case-study/neumorphic_profile_bg.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .desktop-profile-detail-avatar-div {
        position: absolute;
        height: fit-content;
        width: fit-content;
      }

      .desktop-profile-detail-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 400px;
        height: 578px;

        .desktop-profile-detail-avatar-div {
          top: 31%;
        }

        .desktop-profile-detail-heading-div {
          width: 400px;
          height: 221px;
          padding: 40px 20px 20px 20px;
          background-image: url("../../../../../images/testimonial-case-study/glassmorphic_profile_heading.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            font-weight: 700;
            font-size: 36px;
            line-height: 43.57px;
            color: #202020;
          }

          span {
            font-family: "Dancing Script", cursive;
            font-weight: 700;
            transform: translateY(-45px);
            font-size: 50px;
            color: #202020;
            line-height: 60px;
          }
        }

        .profile-desc-outer-div {
          background-image: url("../../../../../images/testimonial-case-study/glassmorphic1_profile_outer.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 400px;
          height: 280;
          display: flex;
          margin-top: 15px;

          .desktop-profile-detail-desc-div {
            width: 377px;
            height: 319px;
            background-image: url("../../../../../images/testimonial-case-study/glassmorphic_profile_desc.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: black;
            justify-content: center;
            position: relative;
            transform: translate(12px, 17px);
            z-index: 1;

            p {
              width: 340px;
              height: 57px;
              font-weight: 400;
              text-align: center;
              font-family: Inter;
              font-size: 12px;
            }

            h3 {
              width: 128px;
              font-weight: 600;
              font-size: 16px;
              line-height: 19.36px;
            }

            h4 {
              font-weight: 400;
              font-size: 14px;
              line-height: 16.94px;
            }
          }
        }
      }

      .see-my-wall-div {
        p {
          color: #202020;
        }

        a {
          width: 128px;
          height: 30px;
          padding: 7px 14px 7px 14px;
          border-radius: 20px;
          background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
          color: rgba(32, 32, 32, 1);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

.glassmorphic-mobile-main-container {
  display: flex;
  justify-content: center;
  width: 320px;
  height: 1000px;

  .desktop-dot-container {
    background-image: url("../../../../../images/testimonial-case-study/glassmorphic_desktop_dots.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    width: 320px;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F0F4F8;

    .desktop-profile-div {
      width: 300px;
      height: 660px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      background-image: url("../../../../../images/testimonial-case-study/yyy.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transform: translateX(5px);

      .desktop-profile-detail-avatar-div {
        position: absolute;
        height: fit-content;
        width: fit-content;
      }

      .desktop-profile-detail-div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 280px;
        height: 540px;

        .desktop-profile-detail-avatar-div {
          top: 25%;
        }

        .desktop-profile-detail-heading-div {
          width: 250px;
          height: 181px;
          padding: 40px 20px 20px 20px;
          background-image: url("../../../../../images/testimonial-case-study/glassmorphic_profile_heading_mobile.png");
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            font-family: Inter;
            font-weight: 700;
            font-size: 20px;
            line-height: 43.57px;
            color: #3C3D40;
          }

          span {
            font-family: "Dancing Script", cursive;
            font-weight: 700;
            transform: translateY(-45px);
            font-size: 36px;
            line-height: 60px;
            color: #202020;
          }
        }

        .profile-desc-outer-div {
          background-image: url("../../../../../images/testimonial-case-study/fff.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 248px;
          height: 280px;
          display: flex;
          margin-top: 5px;
          z-index: 0;

          .desktop-profile-detail-desc-div {
            width: 234px;
            height: 300px;
            background-image: url("../../../../../images/testimonial-case-study/glassmorphic_profile_desc_mobile.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: black;
            justify-content: center;
            position: relative;
            transform: translate(7px, 10px);
            z-index: 1;

            p {
              width: 209px;
              height: 57px;
              font-weight: 400;
              text-align: center;
              font-size: 11px;
              color: #5F6168;
              margin: 0;
              line-height: 17.6px;
            }

            h3 {
              font-weight: 600;
              font-size: 14px;
              margin-top: 18px;
            }

            h4 {
              font-weight: 400;
              font-size: 12px;
              line-height: 14.52px;
            }
          }
        }
      }

      .see-my-wall-div {
        p {
          color: #202020;
          font-size: 13px;
          font-weight: 500;
        }

        a {
          width: 128px;
          height: 30px;
          padding: 7px 14px 7px 14px;
          border-radius: 20px;
          background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
          color: rgba(32, 32, 32, 1);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}