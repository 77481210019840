.service-package-plan-neumorphic-tab-4 {
    background-color: #CBD5E0;
    width: 768px;
    margin: auto;
    // height: 1075px;

.h_850{
    height: 850px;
}
.relative{
    position: relative;
}
.toggle_button {
    background: #F0F4F8;
    border-radius: 30px;
    width: 183px;
    height: 35px;
    display: flex;

    .active_btn {
        width: 97px;
        height: 35px;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
        border-radius: 30px;
        color: #000000;
        text-align: center;
    }

    .btn1 {
        width: 97px;
        height: 35px;
        background: #F0F4F8;
        box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
        border-radius: 30px;
        color: #000000;
        text-align: center;
        border: none;

    }

}

    .plan_outer {
        width: 235px;
        height: 650px;
        background: #F0F4F8;
        box-shadow: -4px -4px 10px #FFFFFF, 5px 5px 10px rgba(178, 189, 203, 0.8);
        border-radius: 20px;
        position: relative;

        .shape_wrapper {
            position: relative;

            .shape_outer {
                font-size: 1.1em;
                color: #fff;
                background: #F0F4F8;
                border-radius: 20px 20px 0px 0px;
                height: 130px;
                position: relative;
                padding: 20px;
                border-radius: 10px;
                // margin: 50px auto;
                max-width: 320px;

                &::after {
                    content: "";
                    border: 20px solid transparent;
                    position: absolute;
                }
            }

            .bottom.shape_outer {
                &::after {
                    border-top-color: #F0F4F8;
                    border-bottom: 0;
                    bottom: -20px;
                    left: 50%;
                    margin-left: -20px;
                }
            }

            .ribbon_wrapper {
                position: absolute;
                top: -5px;
                left: 13px;
                z-index: 20;

                .ribbon {
                    // height: 188px;
                    position: relative;
                    margin-bottom: 30px;
                    background: url(https://html5book.ru/wp-content/uploads/2015/10/snow-road.jpg);
                    background-size: cover;
                    text-transform: uppercase;
                    color: white;
                }

                .ribbon1 {
                    width: 160px;
                    height: 90px;
                    background: #3C3D40;
                    border-radius: 0px 10px 10px 10px;
                    padding: 10px 0;
                    position: absolute;
                    top: -15px;
                    left: 25px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:before {
                        height: 0;
                        width: 0;
                        left: -12px;
                        top: 0;
                        border-bottom: 20px solid #202020;
                        border-left: 12px solid transparent;
                        content: "";
                        position: absolute;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                    }

                    .price {
                        h2 {
                            font-weight: 700;
                            line-height: 44px;
                            font-size: 36px;
                            text-align: center;
                            color: #fff;
                        }

                        p {
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 16px;
                            text-align: center;
                            text-transform: uppercase;
                            color: #fff;
                            margin-bottom: 0;
                        }
                    }
                }
            }

        }

        .bg_light {
            background: #F0F4F8;
        }

        .plan_listing {
            height: 500px;
            padding: 40px 0 20px 0;
            box-shadow: inset -4px -4px 10px #FFFFFF, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
            background: #F0F4F8;
            border-radius: 0px 0px 20px 20px;
            h3 {
                font-weight: 600;
                line-height: 24px;
                font-size: 20px;
                color: #000000;
                text-align: center;
            }

            p {
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                color: #3C3D40;
                text-align: center;
            }

            ul {
                // padding: 0 10px;

                li {
                    color: #202020;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    line-height: 17px;
                    padding: 15px 20px;
                    display: flex;
                    align-items: center;

                    .icon_outer {
                        background: #F0F4F8;
                        box-shadow: -1px -1px 3px #FFFFFF, 1px 1px 3px rgba(178, 189, 203, 0.7);
                        border-radius: 5px;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                    .icon {
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-right: 8px;
                    }

                    .text_yellow {
                        color: #FF7714;
                    }
                }
            }
            }
        }

        .green_btn1 {
            width: 125px;
            height: 35px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            color: #202020;
            border-radius: 30px;
            font-size: 12px;
            color: #000000;
            text-align: center;
            border: none;
            z-index: 30;
            position: absolute;
            margin-top: -15px;
        }

       
        .btn {
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            color: #202020;
            border-radius: 40px;
            width: 178px;
            height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: -32px;
        }
    }
    .relative {
        position: relative;
    }
}