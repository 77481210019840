@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
// ammar css start

.Feature-on-mobile-1{

  .features-on-design-mobile-1 {
    
    background-color: #cbd5e0;
  width: 400px;
  margin: auto;
  position: relative;
  }
  
  .grey-box-mobile {
      background-color: #CBD5E0;
      padding: 30px 30px 30px 30px;
    }
    
    .image-circle-mobile {
      background-color: #ffffff;
      
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      
      align-items: center;
      justify-content: center;
      margin: 30px 4px 0px 0px ;
      
      z-index: 90;
  }
  .image-usa{
      background-color: #1EA3FF ;
    }
  //   .image-line {
  //     position: absolute;
  //     width: 100%;
  //     height: 3px;
   
  //     top: 50%;
  //     left: 0;
  //     transform: translateY(-50%);
  //   }
  
  .line-update-mobile{
        margin: 81px 20px -60px 10px ;
      height: 12px;
      width: 85%;
      background: linear-gradient(to bottom, #7CBAA7 0%, #7CBAA7 33%, #304053 33%,#304053 66%,  #7CBAA7 66%, #7CBAA7 100%);
    }
    
    .curve-image-mobile{
  
  padding-top: 20px;
  padding-bottom: 20px;
}
.curve-image-mobile img{
      width: 268px;

    }
    
}
// ammr css end

