@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

.Glassmorphic-design {
    .navbar-top .forms-inpt {
        background: transparent !important ;
        border: none !important;
    }
    .book-me-mue {
        display: none;
    }
    .reg-sond {
        display: none;
    }
    .glass-sond {
        display: block;
    }
    .meo-shape {
        opacity: 0.3;
    }
    .book-me-reg {
        display: none !important;
    }
    .reg-timeline {
        display: none;
    }
    .meo-shape {
        display: block;
        position: absolute;

        z-index: 0;
        left: 17px;
    }
    .meu-playlist-section {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        border-radius: 6.41451px;
        padding: 15px !important;
        .next-icon-center .iconss {
            font-size: 10px;
            color: #c49530;
            margin: 8px;
        }
    }
    .meu-timeline {
        display: block !important;
        .timeline {
            display: flex;
        }
    }

    .intro-anner-oter {
        margin-top: 32px;
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        padding: 20px;
        border-radius: 0px 15px 15px 0px;
        width: 412px;
        height: 195px;
    }

    .fa-magnify {
        font-size: 26px;
        margin: 10px 0px 55px;
        color: #394649;
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        border-radius: 30px;
        padding: 10px;
    }
    .info-oter {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        width: 46px !important;
        height: 46px !important;
        margin: auto;
        border-radius: 50px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .hero-section-reg {
        display: none;
    }
    .hero-section-mue {
        display: block!important;
        .content-img {
            width: 70% !important;
            right: -35%;
            height: 75% !important;
            box-shadow: inset -4px -4px 10px #ffffff, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
            border-radius: 20px;
        }
    }
    .meu-social {
        display: block;
    }
    @media screen and (max-width: 1450px) {
        .design-screen-desktop .content-text .playlist-section-text {
            right: -167px;
            width: 330px;
        }
    }

    @media screen and (max-width: 1662px) {
        .design-screen-desktop .content-text .playlist-section-text {
            right: -193px;
            width: 350px;
        }
    }
    .meu-show {
        display: none;
    }
    .test-reg {
        display: none;
    }
    .timeline-gold {
        display: none;
    }
    .reg-social {
        display: none !important;
    }

    .btn-video {
        display: none !important;
    }
    .bookme-oterr .bookme-inner .hamburger-outer {
        .forms-inpt {
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.5) 3.01%,
                rgba(255, 255, 255, 0.05) 103.3%
            ) !important;
            border: 1px solid rgba(255, 255, 255, 0.4901960784);
        }
        .form-control {
            width: 100%!important;
            background: linear-gradient(
                158.72deg,
                rgba(255, 255, 255, 0.5) 3.01%,
                rgba(255, 255, 255, 0.05) 103.3%
            ) !important;
            border: 1px solid rgba(255, 255, 255, 0.4901960784);
            box-shadow: none !important;
            
        }
    }
    .design-screen-desktop {
        background-image: url(../../../../../images/Desktop-theme-2.png);
        background-repeat: no-repeat;
        background-size: cover;
        .himself-right {
            width: 45%;
            margin-top: 280px;
        }
        .bookme-oter {
            .glass-show {
                display: block;
                width: 166px;
                position: relative;
                right: 22px;
            }
        }
        .tab-seeall {
            position: absolute;
            bottom: 399px;
            right: -33% !important;
            .hamburger-outer{
                  width: 315px!important;
                  left: -114px!important;
                  .testimonial .timeline-top{
                    margin: auto!important;
                    left: -13px!important;
                  }
                  .testimonial img{
               margin:auto!important;
                  }
            }
            .testimonial-seeall {
                display: flex;
                justify-content: center;
                align-items: center;
                .hamburger-outer {
                    background-color: #f0f4f8 !important;
                    position: absolute;
                    z-index: 4;
                    padding: 40px 20px 40px;
                    width: 400px;
                    left: -15px;
                    top: -244px;

                    .sidebar-li {
                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;
                        text-align: center;
                        border-bottom: 1px solid rgb(177, 176, 176);
                        padding: 20px;
                        color: #3c3d40;
                    }

                    .testimonial-p {
                        color: #3c3d40;
                        font-size: 15px;
                    }
                    .bottom-tabs {
                        color: #3c3d40;
                        margin-top: 5px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h6 {
                            margin-left: 10px;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 120%;
                            color: #3c3d40;
                        }
                    }

                    .btn-close {
                        background-color: #7cbaa7;
                    }
                    .close-btn {
                        border: none;
                        color: white;
                        background: #7cbaa7;
                        width: 45px;
                        height: 40px;
                        font-size: 23px;
                        position: absolute;
                        right: 17px;
                        top: 30px;
                    }
                    .switch-outer {
                        width: 62.06px !important;
                        height: 33px !important;
                        background-color: white;
                        border-radius: 49.5px;
                        z-index: 13;
                        margin: 0px 15px;
                        .switch {
                            display: inline-block;
                            position: relative;
                            width: 39.58px !important;
                            height: 22.71px !important;
                            box-shadow: none !important;
                            left: 6px !important;
                            top: 6px !important;
                            box-shadow: none;
                            border-radius: 49.5px;
                            z-index: 2;
                            background: #7cbaa7;
                            transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                            vertical-align: middle;
                            cursor: pointer;
                        }
                        .switch::before {
                            content: "";
                            position: absolute;
                            top: 4px !important;
                            left: 20px !important;
                            width: 15.03px !important;
                            height: 15.03px !important;
                            background: white;
                            border-radius: 50%;
                            transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                                background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                                box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                        }
                        .switch:active::before {
                            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                        }
                        input:checked + .switch {
                            background: #7cbaa7;
                        }
                        input:checked + .switch::before {
                            left: 9px;
                            background: #fff;
                        }
                        input:checked + .switch:active::before {
                            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                        }
                    }
                    .mode-set {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #3c3d40;

                        z-index: 1;
                    }
                    .social-set {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px !important;
                        line-height: 12px !important;
                        color: #3c3d40;
                        transform: rotate(0deg) !important;
                        position: revert !important;
                        z-index: 1;
                        margin-left: 20px;
                    }
                    .btn-close {
                        background-color: white;
                        opacity: 1;
                        border-radius: 0px !important;
                        width: 30px;
                        height: 30px;
                    }

                    .bottom-tabs {
                        color: #cbd5e0;
                        margin-top: 5px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h6 {
                            margin-left: 10px;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 120%;
                            color: #cbd5e0;
                        }
                        svg {
                            font-size: 25px;
                        }
                    }
                    .testimonial-p {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .top-padding {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        li {
                            padding: 0px 8px;
                        }
                    }
                    .testimonial {
                        margin-top: -80px;
                        .timeline-top {
                            flex-direction: column;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 13px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            position: relative;
                            color: black;
                            background: linear-gradient(
                                158.72deg,
                                rgba(255, 255, 255, 0.3) 3.01%,
                                rgba(255, 255, 255, 0) 103.3%
                            ) !important;
                            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                            overflow: hidden;
                            width: 87px;
                            padding: 8px;
                            height: 87px;
                            top: 97px;
                            left: 23px;
                            p {
                                margin-top: 4px;
                                line-height: 10px;
                            }
                            .icon-green {
                                font-size: 8px;
                                color: black !important;
                                padding-top: 3px;
                            }
                            p {
                                font-size: 6px;
                            }
                        }

                        img {
                            width: 130px;
                            margin: 0px 13px;
                            position: relative;
                            z-index: 2;
                        }
                    }
                }
                h5 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 159.5%;
                    text-align: center;
                    letter-spacing: 0.01em;
                    text-decoration-line: underline;
                    color: #202020;
                    padding-right: 3px;
                    margin-top: 5px;
                    margin-left: -20px;
                }
            }
            .timeline-top {
                width: 87px;
                padding: 8px;
                height: 87px;
                top: 95px;
                left: 20px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            }
            .hamburger-outer {
                position: absolute;
                z-index: 3;
                padding: 40px 20px 40px;
                width: 400px;
                right: 716px;
                top: -380px;
            }
            .bars-hambrger .hamburger-outer .bottom-tabs svg {
                font-size: 25px;
            }
            .main-content .hero-sidebar .bottom-tabs h6 {
                font-weight: 600;
                font-size: 15px;
                line-height: 120%;
                color: #192529;
            }
            .meu-social {
                .top-padding {
                    display: flex;
                }
            }
            .testimonial {
                margin-top: -80px;

                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;

                    width: 89px;
                    padding: 8px;
                    height: 89px;
                    top: 96px;
                    left: 21px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #202020;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 130px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }
        }
        .forms-inpt .form-control {
            background: linear-gradient(#dddee8, #dee0e9) padding-box,
                linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
            border: 3px solid transparent !important;
            border: 3px solid transparent !important;
            width: 100%;
        }

        .bars-hambrger .hamburger-outer .testimonial .timeline-top {
            width: 88px;
            padding: 9px;
            height: 87px;
            top: 93px !important;
            left: 14px !important;
        }
        .glass-show {
            display: block;
            width: 200px;
            position: relative;
            right: 0px;
        }
        .hamburger-outer {
            position: absolute;
            z-index: 3;
            padding: 40px 20px 40px;
            width: 400px;
            right: 0;
            top: 0;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            border-radius: 10px;
            .close-btn {
                height: 67px;
                font-size: 23px;
                position: absolute;
                right: 14px;
                top: 1px;
            }
            .sidebar-li {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;

                text-align: center;
                border-bottom: 1px solid transparent;
                padding: 20px;
                color: #f0f4f8;
                color: #202020;
                margin: 16px;
            }
            .switch-outer {
                display: none;
            }
        }
        .close-btn {
            border: none;
            background-image: url(../../../../../images/close-batch.png);
            background-repeat: no-repeat;
            top: 2px;
            height: 68px;
            margin-top: -11px;
            top: 3px;
            right: 9px;
            font-size: 34px;
            color: grey;
            position: absolute;
            svg {
                margin-top: -34px;
                margin-left: 10px;
                font-size: 28px;
            }
        }
        .video-btn-oter {
            position: absolute;
            backdrop-filter: blur(10px);
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            padding: 15px;
            top: 323px;
            z-index: 1;
            left: -221px;
            border-radius: 20px;
         
     
        .video-section {
            .video-img {
                position: revert !important;
                width: 215px;
                height: 140.63px;
            }
        }
    }
        .navbar-top {
            background: transparent !important;
            height: 160px;
            padding: 24px !important;
            .active-oter {
                width: 85px;
                height: 85px;
                margin-right: 30px;
                padding: 5px;
                z-index: 2;
                border-radius: 50px;
                backdrop-filter: blur(5px);
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            }
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    width: 87px;
                    padding: 8px;
                    height: 87px;
                    top: 97px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #202020;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 140px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }

            .profile-img {
                width: 84px;
                margin-left: 11px;
                margin-top: -210px;
            }
            .icons-circle {
                width: 140px;
                margin-top: 18px;
            }
            .img-logo {
                margin-left: 20px;
                width: 110px;
            }
            .timeline {
                display: flex;
                justify-content: revert !important;
                counter-reset: year 2016;
                position: relative;
                margin-top: 27px;
                p {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 11px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -13px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-top {
                width: 40px;
                height: 40px;
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #5f6168;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                top: -27px;
                left: 15px;
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;
                width: 70px;
                height: 70px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                color: #5f6168;
                font-size: 15px;
                margin-right: 47px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                @media screen and (max-width: 1400px) {
                    margin-right: 15px !important;
                }
                .timeline-med {
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    width: 75px;
                    margin: 10px;
                    height: 75px;
                    border-radius: 50%;
                }
                img {
                    width: 15px;
                    margin-top: -10px;
                }
                .message {
                    width: 20px !important;
                    margin-top: -10px;
                }
                .load {
                    width: 25px !important;
                    margin-top: -10px;
                }
                .answer {
                    width: 25px;
                    margin-top: -10px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;

                width: 60%;
                height: 12px;
                border: 3px solid #f0f4f8;
                background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                top: 34px;
                box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9);
                left: 85px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
        }
        .profile-img {
            @media screen and (max-width: 1623px) and (min-width: 1480px) {
                margin-left: -8px !important;
            }
        }
        .switch-border {
            left: 22px;
            width: 62.06px !important;
            height: 33px !important;

            input[type="checkbox"]::after {
                width: 15.03px !important;
                height: 15.03px !important;
                left: 45%;
                top: 4px;
            }

            input:checked[type="checkbox"]::after {
                left: 7px;
            }

            input[type="checkbox"] {
                left: 4px !important;
                top: 4px !important;
            }

            input[type="checkbox"]:checked {
                width: 130px;
                height: 69.64px;
                left: 5px;
                top: 5px;
            }
        }
        .hero-section-mue {
            height: 1107px !important;
            @media screen and (max-width: 1555px) and (min-width: 1500px) {
                height: 1124px !important;
            }
            @media screen and (max-width: 1500px) {
                height: 1126px !important;
            }
            @media screen and (max-width: 1482px) {
                height: 1127px !important;
            }
            @media screen and (max-width: 1452px) {
                height: 1145px !important;
            }
            @media screen and (max-width: 1350px) {
                height: 1145px !important;
            }
        }
        .bookme-oter {
            position: relative;
            margin-top: 32px;
            .bookme-inner {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
           
                border: none!important;

                .forms-inpt {
                    background: transparent !important;

                    padding: 38px 15px;
                    margin-top: 40px;
                    .form-control {
                        border: 1px solid transparent !important;
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                        border-radius: 4px !important;
                        width: 100%!important;
                    }
                }

                .timelinee:after {
                    content: "";

                    background-color: #304053;
                }
                .timelinee li.active {
                    color: #555555;
                }

                .timelinee li.active + li:after {
                    background: #4caf50 !important;
                }
            }
        }
        .bookme-oterr {
            position: relative;
            .bookme-inner {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
           

                top: 95px;
                left: -90px;

                .timelinee:after {
                    content: "";
                    position: absolute;

                    width: 70%;
                    height: 12px;

                    background-color: #304053;
                    top: 34px;
                    left: 72px;
                    z-index: 1;
                    transition: all ease-in-out 0.3s;
                    margin: auto;
                }
                .timelinee li.active {
                    color: #555555;
                }

                .timelinee li.active + li:after {
                    background: #4caf50 !important;
                }

                .book-me-btnn {
                    width: 150px;
                    margin: auto;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: #7cbaa7;
                    border: 1px solid #7cbaa7;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: #202020;
                    font-weight: 500;
                    text-align: center;
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }
        .search-position {
            position: relative;
        }
        .searchbox {
            top: -53px;
            left: 112%;
            position: absolute;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            border-radius: 10px !important;
            border: none;
            z-index: 3;
            padding: 20px 30px;
            width: 380px;
            .glass-show {
                margin: auto;
                margin-top: 15px;
            }
            .search-area {
                margin: 0px;
                margin-bottom: 20px;
                .close-btn {
                    border: none;
                    background-color: transparent !important;
                    top: -20px;
                    right: -155px;
                    font-size: 34px;
                    color: grey;
                    position: relative;
                }
                .search-field {
                    width: 100%;
                    margin: -14px 10px 0px;
                    height: 44px !important;
                    box-shadow: none;
                    padding: 2px 11px;

                    background: transparent;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #394649;
                    .icon-mgni {
                        font-size: 35px !important;
                    }
                    input {
                        font-size: 15px;
                    }
                    svg {
                        cursor: pointer;
                    }
                }
            }
            .search-me-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 17px 15px;
                margin-bottom: 10px !important;
                gap: 10px;
                margin: auto;
                margin-top: 20px;
                width: 110px !important;
                height: 50px;
                background: #7cbaa7;
                border-radius: 8px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                border: none;
                letter-spacing: 0.04em;
                text-transform: uppercase;
            }
        }
        .bars-hambrger {
            position: relative;
            .react-multi-carousel-track {
                .react-multi-carousel-item {
                    button {
                        width: 150px !important;
                        background-color: transparent !important;
                    }
                }
            }
            .hamburger-outer {
                background-color: #f0f4f8 !important;
                position: absolute;
                z-index: 4;
                padding: 40px 20px 40px;
                width: 400px;
                left: -15px;
                top: -106px;
                .sidebar-li {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: center;
                    border-bottom: 1px solid rgb(177, 176, 176);
                    padding: 20px;
                    color: #3c3d40 !important;
                }

                .testimonial-p {
                    color: #3c3d40;
                    font-size: 15px;
                }
                .bottom-tabs {
                    color: #3c3d40;
                    margin-top: 5px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h6 {
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 120%;
                        color: #3c3d40;
                    }
                }

                .btn-close {
                    background-color: #7cbaa7;
                }
                .close-btn {
                    border: none;
                    color: white;
                    background: #7cbaa7;
                    width: 45px;
                    height: 40px;
                    font-size: 23px;
                    position: absolute;
                    right: 17px;
                    top: 30px;
                }
                .switch-outer {
                    width: 62.06px !important;
                    height: 33px !important;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    border-radius: 49.5px;
                    z-index: 1;
                    margin: 0px 15px;
                    .switch {
                        display: inline-block;
                        position: relative;
                        width: 39.58px !important;
                        height: 22.71px !important;
                        box-shadow: none !important;
                        left: 6px !important;
                        top: 6px !important;
                        box-shadow: none;
                        border-radius: 49.5px;
                        z-index: 2;
                        background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                        box-shadow: 1.71429px 1.71429px 1.71429px #b6c8da, -1.71429px -1.71429px 1.71429px #ffffff;
                        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .switch::before {
                        content: "";
                        position: absolute;
                        top: 4px !important;
                        left: 20px !important;
                        width: 15.03px !important;
                        height: 15.03px !important;
                        background: #eaeff6;
                        box-shadow: inset 0px -6.85714px 13.7143px #ffffff, inset 0px 6.85714px 10.2857px #a0b9d0;
                        border-radius: 50%;
                        transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                            background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    }
                    .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                    }
                    input:checked + .switch {
                        background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                        box-shadow: 1.71429px 1.71429px 1.71429px #b6c8da, -1.71429px -1.71429px 1.71429px #ffffff;
                    }
                    input:checked + .switch::before {
                        left: 9px;
                        background: #eaeff6;
                        box-shadow: inset 0px -6.85714px 13.7143px #ffffff, inset 0px 6.85714px 10.2857px #a0b9d0;
                    }
                    input:checked + .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                    }
                }
                .mode-set {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #3c3d40;

                    z-index: 1;
                }
                .social-set {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px !important;
                    line-height: 12px !important;
                    color: #3c3d40;
                    transform: rotate(0deg) !important;
                    position: revert !important;
                    z-index: 1;
                    margin-left: 20px;
                }
                .btn-close {
                    background-color: white;
                    opacity: 1;
                    border-radius: 0px !important;
                    width: 30px;
                    height: 30px;
                }

                .bottom-tabs {
                    margin-top: 5px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h6 {
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 120%;
                    }
                    svg {
                        font-size: 25px;
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    }
                }
                .testimonial-p {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .top-padding {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    li {
                        padding: 0px 8px;
                    }
                }
                .testimonial {
                    margin-top: -80px;
                    .timeline-top {
                        flex-direction: column;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        position: relative;
                        color: black;
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                        overflow: hidden;
                        width: 80px;
                        padding: 8px;
                        height: 80px;
                        top: 89px !important;
                        left: 23px !important;
                        p {
                            margin-top: 4px;
                            line-height: 8px;
                        }
                        .icon-green {
                            font-size: 8px;
                            color: #202020;
                            padding-top: 3px;
                        }
                        p {
                            font-size: 6px;
                        }
                    }

                    img {
                        width: 130px;
                        margin: 0px 13px;
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }
        .navbar-top {
            height: 140px;
            padding: 24px !important;
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;

                    width: 87px;
                    padding: 8px;
                    height: 87px;
                    top: 97px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #202020;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 130px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }

            .img-logo {
                margin-left: 20px;
                width: 110px;
            }
            .timeline {
                counter-reset: year 2016;
                position: relative;
                margin-top: 27px;
                p {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 11px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -15px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-top {
                width: 35px;
                height: 35px;
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: black;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.9) 3.01%, rgba(255, 255, 255, 0.1) 103.3%);
                /* Background blur/Light/4 */

                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(1.5px);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                top: -23px;
                left: 24px;
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;
                width: 72px;
                height: 72px;

                background: linear-gradient(
                    158.72deg,
                    rgba(255, 255, 255, 0.3) 3.01%,
                    rgba(255, 255, 255, 0) 103.3%
                ) !important;
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                color: #5f6168;
                font-size: 15px;
                margin-right: 47px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                @media screen and (max-width: 1341px) {
                    margin-right: 17px !important;
                }
                img {
                    width: 20px;
                }
                .message {
                    width: 25px !important;
                }
                .load {
                    width: 28px !important;
                }
                .answer {
                    width: 32px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;
                width: 355px;
                height: 12px;

                background-color: #304053;
                top: 34px;
                left: 72px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
        }
        @media screen and (max-width: 1550px) {
            .navbar-top {
                height: 140px;
                padding: 24px !important;
                .testimonial {
                    margin-top: -80px;
                    .timeline-top {
                        flex-direction: column;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        position: relative;
                        color: black;
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;

                        width: 87px;
                        padding: 8px;
                        height: 87px;
                        top: 97px;
                        left: 23px;
                        p {
                            margin-top: 4px;
                            line-height: 10px;
                        }
                        .icon-green {
                            font-size: 8px;
                            color: #202020;
                            padding-top: 3px;
                        }
                        p {
                            font-size: 8px;
                        }
                    }

                    img {
                        width: 130px;
                        margin: 0px 13px;
                        position: relative;
                        z-index: 2;
                    }
                }
                .profile-img {
                    width: 84px;
                    margin-left: 11px;
                    margin-top: -210px;
                }
                .icons-circle {
                    width: 125px;

                    margin: auto;
                    position: relative;
                    margin-bottom: 25px;
                }
                .img-logo {
                    margin-left: 11px;
                    width: 88px;
                }
                .timeline {
                    counter-reset: year 2016;
                    position: relative;
                    margin-top: 27px;
                    p {
                        font-weight: 400;
                        font-size: 8px;
                        padding: 0px 15px 0px;
                        line-height: 11px;
                        display: flex;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #1c1c1c;
                        margin-top: -15px;
                    }
                }

                .timeline li {
                    list-style: none;
                    float: left;

                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Dosis", sans-serif;
                }

                //   ul:nth-child(1){
                //     color: #4caf50;
                //   }
                .timeline-icon {
                    margin-top: -30px;
                }
                .timeline-top {
                    width: 35px;
                    height: 35px;
                    font-weight: 500;
                    font-size: 9px;
                    line-height: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    top: -25px;
                    left: 20px;
                }
                .timeline li {
                    z-index: 2;
                    position: relative;

                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 50px;
                    margin: 0 auto 10px auto;
                    width: 72px;
                    height: 72px;

                    background: #f0f4f8;
                    color: #5f6168;
                    font-size: 15px;
                    margin-right: 30px;

                    transition: all ease-in-out 0.3s;
                    cursor: pointer;
                    img {
                        width: 18px;
                    }
                    .message {
                        width: 20px !important;
                    }
                    .load {
                        width: 23px !important;
                    }
                    .answer {
                        width: 28px;
                    }
                }

                .timeline:after {
                    content: "";
                    position: absolute;
                    width: 67%;
                    height: 12px;

                    background-color: #304053;
                    top: 34px;
                    left: 54px;
                    z-index: 1;
                    transition: all ease-in-out 0.3s;
                    margin: auto;
                }
                .timeline li.active {
                    color: #555555;
                }

                .timeline li.active + li:after {
                    background: #4caf50 !important;
                }
            }
        }
        .top-padding {
            margin-top: -124px;
        }
        .navbar-bars {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 30px;

            width: 56px;
            height: 56px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            color: #202020;

            border-radius: 8px;
            margin: auto;
            margin-top: 35px;
        }

        .text-hide {
            color: transparent;
        }
        .main-content {
            .hero-sidebar {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border-right: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                width: 9% !important;
                height: 100%;
                padding: 15px;
                z-index: 2;

                .fa-magnify {
                    font-size: 26px;
                    margin: 10px 0px 55px;
                    color: #394649;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    border-radius: 30px;
                    padding: 10px;
                    margin-bottom: 35px !important;
                }
                .profile-img {
                    width: 84px;
                    margin-left: 11px;
                    margin-top: -210px;
                }
                .icons-circle {
                    width: 94px;
                    left: -3px;
                    top: -5px;

                    margin: auto;
                    position: relative;
                    margin-bottom: 25px;
                }
                @media screen and (max-width: 1500px) {
                    width: 94px;
                    left: -3px;
                    top: -5px;
                }
                .switch-outer {
                    width: 182px;
                    left: 22px;
                    height: 93px;
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    /* Neo/Light/Inner/4 */

                    box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
                    position: relative;
                    border-radius: 49.5px;
                    z-index: 1;
                    .switch {
                        display: inline-block;
                        position: relative;
                        width: 130px;
                        height: 69.64px;
                        left: -16px;
                        top: 10px;
                        border-radius: 49.5px;
                        z-index: 2;
                        background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                        box-shadow: 1.71429px 1.71429px 1.71429px #b6c8da, -1.71429px -1.71429px 1.71429px #ffffff;
                        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                        vertical-align: middle;
                        cursor: pointer;
                        box-shadow: none !important;
                    }
                    .switch::before {
                        content: "";
                        position: absolute;
                        top: 9px;
                        left: 68px;
                        width: 49.6px;
                        height: 49.6px;
                        background: #eaeff6;
                        box-shadow: inset 0px -6.85714px 13.7143px #ffffff, inset 0px 6.85714px 10.2857px #a0b9d0;
                        border-radius: 50%;
                        transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                            background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    }
                    .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                    }
                    input:checked + .switch {
                        background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                        box-shadow: 1.71429px 1.71429px 1.71429px #b6c8da, -1.71429px -1.71429px 1.71429px #ffffff;
                    }
                    input:checked + .switch::before {
                        left: 11px;
                        background: #eaeff6;
                        box-shadow: inset 0px -6.85714px 13.7143px #ffffff, inset 0px 6.85714px 10.2857px #a0b9d0;
                    }
                    input:checked + .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                    }
                }
                .mode-set {
                    position: relative;

                    top: -37px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #3c3d40;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                .social-set {
                    position: relative;

                    top: 39px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #3c3d40;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 9px;
                    line-height: 11px;
                    text-align: center;
                    color: #202020;
                }
                .bottom-tabs {
                    color: #394649;
                    margin-top: 5px;
                    h6 {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 120%;
                        color: #192529;
                    }
                }

                .name-btn {
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    box-shadow: none !important;
                    border-radius: 4px;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 9px;
                    line-height: 100%;
                    padding: 8px 10px;
                    margin-bottom: 10px;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #202020;
                }
                .info-icon {
                    border: 3px solid #394649;
                    padding: 3px 10px 6px 11px;
                    font-size: 18px;
                    margin: 20px 0px;
                    color: #394649;

                    border-radius: 50px;
                }
                .fa-magnify {
                    font-size: 44px !important;
                    margin: 0px 0px 14px;
                    color: #394649;
                    padding: 5px !important;
                }
            }
            .content-hero {
                width: 92%;

                .content-text {
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                }
            }
        }
        .content-text {
            position: relative;
            .dots {
                position: relative;
                top: -88px;
                left: 44px;
            }
            .tab-right-section {
                width: 15%;
            }
            .nav-item {
                backdrop-filter: blur(5px);
            }
            .nav-item .active {
                background: linear-gradient(#f0f4f8, #f0f4f8) padding-box,
                    linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
                border: 3px solid transparent !important;
                backdrop-filter: blur(5px);
            }

            .tab-section {
                width: 55%;
                margin: auto;
                margin-top: -63px !important;
            }
            .tab-content {
                margin-top: -75px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 70px 100px 20px;

                position: relative;
                overflow: auto;
                height: 364px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 20px;
                border-radius: 8px;
            }
            .tab-himself-text {
                p {
                    overflow: auto;
                    padding-right: 10px;
                    ::-webkit-scrollbar {
                        width: 10px;
                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                        background: #888;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                }
            }

            .btn-video {
                position: absolute;
                right: 19%;
                top: 36px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 17px 25px;
                width: 114px;
                height: 45px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #ffffff;

                /* Others/1 */

                background: #304053;
                border-radius: 4px;
            }
            .video-section {
              
                width: 23%;
                position: absolute;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 8px;
                right: 80px;
                height: 50%;
                bottom: 27%;
                display: flex;
                justify-content: center;
                align-items: center;

                .video-img {
                    display: none;
                    background-image: url(../../../../../images/video-img.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 152.63px;
                    margin: 28px;
                    width: 230px;
                    height: 155.63px;
                    border-radius: 10.5263px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .design-about {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 15px 18px;
            width: 100px;
            height: 120px;
            position: relative;
            backdrop-filter: blur(5px);
            z-index: 1;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            border-radius: 10px;
            color: #202020;
            border-radius: 4px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
        }
        .nav-item .active {
            background: linear-gradient(#efefef, #c3c3d2) padding-box,
                linear-gradient(to bottom, #facb30, #fef9c8, #d19f32, #fff8c3) border-box !important;
            border: 3px solid transparent !important;
        }
        .content-img {
            position: relative;
            width: 100%;
        }
        .hero-section {
            position: relative;
            box-shadow: none !important;
            background-color: transparent !important;
            margin: -8px 80px;
        }

        .hero-part {
            position: absolute;
            top: 80px;
            left: 80px;
            h1 {
                font-weight: 700;
                font-size: 68px;
                line-height: 90px;
                color: #ffffff;

                text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            }
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;

                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
            }
        }
        @media screen and (min-width: 1500px) {
            .hero-part {
                position: absolute;
                top: 80px;
                left: 80px;
                h1 {
                    font-weight: 700;
                    font-size: 68px;
                    line-height: 90px;
                    color: #ffffff;

                    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
                }
                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;

                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
                }
            }
        }
        .content-hero {
            width: 92%;
        }
        .book-me-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 17px 18px;
            gap: 10px;
            margin: 0px 30px 0px 20px;
            width: 151px;
            height: 50px;
            background: #7cbaa7;
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            /* identical to box height */
            border: none;
            letter-spacing: 0.04em;
            text-transform: uppercase;
        }
        .navbar-top{
            .forms-inpt {
                z-index: 3;
                background-color: transparent !important;
                .form-control {
                    width: 260px;
                    margin: 0px 10px;
                    height: 100% !important;
                    padding: 13px 30px;
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    border-radius: 4px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
    
                    color: #808492;
                    @media screen and (max-width: 1476px) {
                        width: 200px;
                    }
                }
            }
        }
      
        .timeline-desktop {
            display: flex;
            justify-content: flex-end;
        }
        .intro-left-section {
            position: relative;
            z-index: 2;
            top: -154px;
            left: -13px;

            .logo-intro {
                width: 100%;
                text-align: right;
                left: 279px;
                position: relative;
            }
            .img-top {
                width: 150px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                padding: 10px;
                border-radius: 15px;

                .img-logo {
                    border: none !important;
                    border-radius: 15px;
                    width: 150px;
                }
            }
            .meu-social {
                display: block;
            }
            .reg-social {
                display: none;
            }
            .book-me-btn {
                margin-top: 20px;
                width: 114px;
                margin-bottom: 10px;
                height: 50px;
            }
            .intro-banner {
                background-image: url(../../../../../images/intro-img.png);
                width: 374px;
                height: 156px;
                border-radius: 0px 15px 15px 0px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                text-align: center;
                padding: 20px;
                h5 {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;
                }
                h2 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 36px;

                    color: #ffffff;
                }

                .intro-btn {
                    font-weight: 400;

                    font-size: 14px;
                    line-height: 17px;
                    color: #202020;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 20px;
                    background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                    border-radius: 20px;
                    width: 250px;
                    height: 37px;
                    margin: 15px auto auto;
                }
            }
        }
        .tab-section {
            .tab-oter-content {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 20px;
                backdrop-filter: blur(21px);
                padding: 20px;
            }
            .tab-content {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                border-radius: 16px;
            }
        }
        .hero-conent {
            height: 800px;
            .himself-section {
                position: relative;
                border-radius: 16px;
                width: 54%;
                top: -597px;
                left: 4%;
                margin: auto;
                p {
                    font-size: 13px;
                    font-weight: 500;
                }
                .modal-tabs {
                    position: relative;
                    top: -85px;
                }
                .slidecontainer {
                    width: revert;
                    margin-left: -47px;
                    margin-right: 11px;
                }
                .playlist-section {
                    p {
                        height: 129px;
                        overflow: auto;
                    }
                }
                .playlist-section-text {
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    border-radius: 8px;
                    width: 100%;

                    padding: 20px;
                    margin-top: 23px;
                    width: 292px !important;

                    /* Grey/8
        
        Base color for Light mode
        */
                    background: linear-gradient(
                        158.72deg,
                        rgba(255, 255, 255, 0.3) 3.01%,
                        rgba(255, 255, 255, 0) 103.3%
                    );
                    border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    border-radius: 8px;
                    .slidecontainer {
                        width: revert;
                        margin-right: -8px;
                    }
                    @media screen and (max-width: 1580px) {
                        right: -159px;
                        width: 336px;
                    }
                    input[type="range"].range-slider {
                        width: 68px;

                        margin-right: 15px;
                    }
                    .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                        height: 20px !important;
                        width: 30px !important;
                        bottom: -24px !important;
                        border-radius: 50% !important;

                        left: -10px;

                        display: flex !important;
                        color: #202020;
                        justify-content: center !important;
                        align-items: center !important;
                        background: linear-gradient(180deg, #facb30 -18%, #fef9c8 22.5%, #c49530 67.84%, #fff8c4 116%);
                    }
                    .range-slider__wrap
                        .range-slider__tooltip.range-slider__tooltip--bottom
                        .range-slider__tooltip__caret::before {
                        border-bottom-color: #facb30 !important;
                        left: 13px;
                    }
                    .next-icon {
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                        border-radius: 23.0737px;
                        width: 35px;
                        text-align: center;
                        color: #202020 !important;
                        font-size: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 35px;
                    }
                    .setting-icons {
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                        margin-left: 5px;
                        border-radius: 24.0118px;
                        padding: 1px;
                        color: #202020;
                        font-size: 8px;
                        display: flex;
                        .icons-set {
                            margin: 5px 9px;
                        }
                    }
                    .side-text {
                        /* Grey/9 */
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                        border-radius: 2.38694px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 11.139px 6.36517px 3.97823px;
                        font-weight: 500;
                        font-size: 6.40381px;
                        line-height: 100%;
                        margin: 0px 10px;
                        /* or 5px */
                        border: 2px solid #dda01a;

                        text-align: center;
                        letter-spacing: 0.01em;
                        text-transform: uppercase;
                        color: #1c1c1c;
                    }
                    .text-gree {
                        display: flex;
                        position: relative;
                        top: 4px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 3.27619px 5.2419px;
                        width: 105.48px;
                    }
                    .next-icon-center {
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;

                        .iconss {
                            font-size: 10px;
                            color: #c49530;
                            margin: 8px;
                        }
                    }

                    .video-icon {
                        font-size: 20px;
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                        border-radius: 50%;
                        color: #c49530;
                        width: 22.64px;
                        height: 22.64px;
                        margin: -5px 0px 0px 0px;
                        padding: 4px 1px;
                        svg {
                            margin-top: -13px;
                        }
                    }
                    .mic-img {
                        padding: 0px 20px;
                        img {
                            width: 90px;
                        }
                    }
                }
                .tab-section {
                    .tab-content {
                        background: linear-gradient(
                            158.72deg,
                            rgba(255, 255, 255, 0.3) 3.01%,
                            rgba(255, 255, 255, 0) 103.3%
                        );
                        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
                    }
                }
            }
            .video-img {
                background-image: url(../../../../../images/video-img.png);

                background-size: cover;
                height: 152.63px;
                /* margin: 60px; */
                width: 120px;
                height: 80.63px;
                border-radius: 10.5263px;

                position: relative;
                top: -40px;
                right: -40px;
            }
            .video-img img {
                width: 50px;
                height: 50px;
            }
        }
     
    }
    .bars-green {
        z-index: 4;
        position: relative;
    }

    .img-logo-oter {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        border-radius: 15px;
        padding: 10px;
        margin-left: 10px;
        .img-logo {
            border: none !important;
            border-radius: 15px;
            margin-left: 0px !important;
        }
    }
}
