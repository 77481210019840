// .service-package-plan-desktop-3 {
//     background-color: #CBD5E0;

//     .toggle_button {
//         background: #F0F4F8;
//         border-radius: 30px;
//         width: 183px;
//         height: 35px;
//         display: flex;

//         .active_btn {
//             width: 97px;
//             height: 35px;
//             background: #7CBAA7 !important;
//             border-radius: 30px;
//             color: #000000;
//             text-align: center;
//         }

//         .btn1 {
//             background: transparent;
//             color: #000000;
//             text-align: center;
//             border: none;

//         }

//     }

//     .plan_outer {
//         display: flex;
//         justify-content: center;
//         padding: 85px 0px 0;
//         width: 320px;
//         height: 664px;
//         background: #3C3D40;
//         border-radius: 10px;
//         position: relative;

//         .price {
//             h2 {
//                 font-weight: 700;
//                 font-size: 36px;
//                 line-height: 43.57px;
//                 text-align: center;
//                 color: #FFFFFF;
//                 margin-bottom: 10px;
//             }

//             p {
//                 font-weight: 400;
//                 font-size: 16px;
//                 line-height: 19.36px;
//                 text-align: center;
//                 color: #F0F4F8;
//             }
//         }

//         .ribbon_wrapper {
//             position: absolute;
//             top: 0;

//             .ribbon {
//                 // height: 188px;
//                 width: 130px;
//                 position: relative;
//                 margin-bottom: 30px;
//                 background-size: cover;
//                 color: #202020;
//                 font-weight: 600;
//                 font-size: 24px;
//                 line-height: 29px;
//                 margin-left: 15px;


//             .ribbon1 {
//                 position: absolute;
//                 top: -15px;
//                 right: 10px;

//                 span {
//                     position: relative;
//                     // display: block;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     background: #7CBAA7;
//                     font-size: 14px;
//                     line-height: 1;
//                     padding: 12px 8px 10px;
//                     border-radius: 10px;
//                     width: 130px;
//                     height: 70px;

//                     &:before {
//                         position: absolute;
//                         content: "";
//                         height: 15px;
//                         width: 15px;
//                         left: -15px;
//                         top: 0;
//                         background: #7CBAA7;
//                     }

//                     &:after {
//                         position: absolute;
//                         content: "";
//                         height: 15px;
//                         width: 20px;
//                         left: -20px;
//                         top: 0;
//                         border-radius: 20px 20px 0 0;
//                         background: #54A6A1;
//                     }
//                 }
//             }

//             .ribbon_1 {
//                 position: absolute;
//                 top: -15px;
//                 right: 10px;

//                 span {
//                     position: relative;
//                     display: block;
//                     text-align: center;
//                     background: #7CBAA7;
//                     font-size: 14px;
//                     line-height: 1;
//                     padding: 12px 8px 10px;
//                     width: 130px;
//                     height: 50px;

//                     &:before {
//                         position: absolute;
//                         content: "";
//                         height: 15px;
//                         width: 15px;
//                         right: -15px;
//                         top: 0;
//                         border-radius: 0 10px;
//                         background: #7CBAA7;
//                     }

//                     &:after {
//                         position: absolute;
//                         content: "";
//                         height: 15px;
//                         width: 20px;
//                         right: -20px;
//                         top: 0;
//                         border-radius: 20px 15px 0 0;
//                         background: #54A6A1;
//                     }
//                 }
//             }
//         }

//         }

//         .plan_listing{
//             ul{
//                 li{
//                     color: #F0F4F8;
//                 font-size: 14px;
//                 font-weight: 400;
//                 text-align: left;
//                 line-height: 17px;
//                 padding: 15px 20px;
//                 border-bottom: 1px solid #F0F4F8;
//                 display: flex;
//                 align-items: center;
//                 // margin-bottom: 20px;
//                 .icon_outer{
//                     width: 30px;
//                     height: 30px;
//                     background: #7CBAA7;
//                     border-radius: 30px;
//                     padding: 5px;
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     margin-right: 5px;
//                     .icon{
//                         color: #304053;
//                     }
//                     .star_icon{
//                         color: #FFFFFF;
//                     }
//                 }
//                 }
//             }
//         }
//         .btn{
//             background: #F0F4F8;
//             color: #3C3D40;
//             border-radius: 30px;
//         }
//     }
// }

.blogpost-screen-desktop-3 {
    background-color: #F0F4F8;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);

    .blogpostdesktop-maincontent {
        background-color: #CBD5E0;
    }

    .white-box-position {
        right: 39px;

        .white-box {
            background-color: #F0F4F8;

            .white-box-content {
                .fashion {
                    font-size: 14px;
                    font-weight: 500;
                    font-family: 'inter';
                    line-height: 20px;
                }

                .fashion-heading {
                    font-size: 36px;
                    font-weight: 700;
                    font-family: 'inter';
                    line-height: 43px;
                }

                .fashion-paragraph {
                    font-size: 14px;
                    font-weight: 400;
                    font-family: 'inter';
                    line-height: 22px;
                }

                .read-more {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #304053;
                    font-family: 'inter';
                    text-decoration: underline;
                }
            }
         
        }

        .comment-share {
            p {
                font-size: 14px;
                font-weight: 600;
                font-family: 'inter';
                line-height: 22px;
                color: #202020;
            }
        }
    }

    .card-image-1 {
       img{
        width: 100% !important;
        height: 360px !important;
       }
    }

    .card-image-content{
        background-color: #7CBAA7;
        bottom: 33px;
        .fashion{
            font-size: 14px !important;
            font-weight: 500;
            font-family: 'inter';
            line-height: 20px;
        }
        .fashion-paragraph{
            font-size: 14px;
            font-weight: 400;
            font-family: 'inter';
            line-height: 22px;
        }
    }

    .bookmark-icon{
        margin-top: 52px;
        span{
          background-color: #7CBAA7;
          padding: 5px;
        }
      }
}