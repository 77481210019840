.service-package-plan-neumorphic-tab-2 {
    width: 768px;
    margin: auto;

    .h_950 {
        height: 950px;
    }

    .toggle_button {
        background: #F0F4F8;
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            width: 97px;
            height: 35px;
            background: #F0F4F8;
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
            border-radius: 30px;
            color: #000000;
            text-align: center;
            border: none;
        }

    }

    .relative {
        position: relative;
    }


    .shape_outer {
        display: block;
        position: relative;

        .shape44_1 {
            margin-left: -5px;
            width: 245px;
            height: 160px;
            background: #F0F4F8;
            border-radius: 30px 30px 0 0;
            position: relative;
            justify-content: center;
            align-items: center;
            z-index: 20;
            display: flex;
            box-shadow: -3px -10px 10px #ffffff83, 3px -12px 16px #ffffff83;
        }

        .shape44 {
            margin: 20px;
            width: 195px;
            height: 180px;
            background: #F0F4F8;
            border-radius: -39%;
            position: relative;
            justify-content: center;
            align-items: center;
            margin-top: -41%;
            display: flex;
            box-shadow: -3px -3px 9px #ffffff83, 3px 3px 7px rgba(94, 104, 121, 0.46);
            transform: rotate(45deg);
        }
    }
    .green_btn1 {
        width: 125px;
        height: 35px;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        border-radius: 30px;
        font-size: 12px;
        color: #000000;
        text-align: center;
        border: none;
    }
    .plan_outer {
        width: 235px;
        height: 710px;
        box-shadow: -4px -4px 10px #FFFFFF, 5px 5px 10px rgba(178, 189, 203, 0.8);
        background: #F0F4F8;
        border-radius: 20px;
        position: relative;
        .plan_listing{
            ul {
                margin-top: 4rem;
                li {
                    color: #000000;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    line-height: 17px;
                    padding: .75rem 1rem;
                    &:nth-of-type(odd){
                        background: #F0F4F8;
                        box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
                    }
    
                    .icon {
                        width: 1.25rem;
                        height: 1.25rem;
                        margin-right: 8px;
                    }
    
                    .text_yellow {
                        color: #FF7714;
                    }
                }
            }
        }
    }
    .-mb-12{
        margin-bottom: -3rem;
    }
    .price {
        position: absolute;
        width: 120px;
        height: 120px;
        top: -60px;
        left: 60px;
        z-index: 20;
        border-radius: 50%;
        background: #F0F4F8;
        box-shadow: inset -4px -4px 10px #FFFFFF, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2 {
            font-weight: 700;
            font-size: 38px;
            line-height: 34px;
            text-align: center;
            color: #304053;
        }
        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #304053;
        }
    }
    .plan_heading {
        flex-direction: column;
        position: absolute;
        top: 65px;
        z-index: 20;
        width: 100%;

        h1 {
            font-weight: 700;
            font-size: 32px;
            line-height: 36px;
            letter-spacing: 0.08em;
            color: #202020;
        }
        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #202020;
        }
    }
    // #base {
    //     background: #304053;
    //     display: flex;
    //     justify-content: center;
    //     margin-left: -2.5px;
    //     position: relative;
    //     width: 240px;
    //     height: 230px;
    //     position: relative;
    //     border-top-left-radius: 20px;
    //     border-top-right-radius: 20px;
    //     &:before {
    //         border-top: 50px solid #304053;
    //         border-left: 120px solid transparent;
    //         border-right: 120px solid transparent;
    //         content: "";
    //         height: 0;
    //         left: 0;
    //         position: absolute;
    //         bottom: -50px;
    //         width: 0;
    //     }
    // }
    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 124px;
        height: 46px;
        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
        border-radius: 30px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #202020;
        position: absolute;
        bottom: -22px;
    }

}