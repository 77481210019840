input.menubar {
  position: absolute;
  display: none;
}
.circleMenu {
  .menu {
    position: absolute;
    top: 0;
    left:0px;
    margin-top: -40px;
    z-index: 15;
  }

  .btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
    color: var(--social-gray);
    background-color: var(--page-background);
    &:hover {
      color: var(--text-body)!important;
    }
  }
  .success-section {
    .btn {
      width: auto;
      position: relative;
      margin: 0 auto;
    }
  }

  .material-icons.md-36 {
    font-size: 36px;
    color: #03a9f4;
  }

  .btn {
    font-size: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .btn:not(:first-child) {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    z-index: -2;

    -webkit-transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
    transition: all 0.6s cubic-bezier(0.87, -0.41, 0.19, 1.44);
  }

  .btn:nth-child(2) {
    left: 0px;
    top: 0px;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }
  .btn:nth-child(3) {
    top: 0px;
    left: 0px;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .btn:nth-child(4) {
    left: 0px;
    top: 0px;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .btn:nth-child(5) {
    top: 0px;
    left: 0px;
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  .btn:nth-child(6) {
    top: 0px;
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .btn:nth-child(7) {
    top: 0px;
    left: 0px;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  .btn:nth-child(8) {
    left: 0px;
    top: 0px;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }
  .btn:nth-child(9) {
    top: 0px;
    left: 0px;
    -webkit-transition-delay: 0.7s;
    transition-delay: 0.7s;
  }

  input#toggle:checked ~ #show-menu .btn:nth-child(2) {
    left: -100px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  input#toggle:checked ~ #show-menu .btn:nth-child(3) {
    top: 0px;
    left: 100px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  input#toggle:checked ~ #show-menu .btn:nth-child(4) {
    left: 67px;
    top: 35px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  input#toggle:checked ~ #show-menu .btn:nth-child(5) {
    top: 80px;
    left: 32px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  input#toggle:checked ~ #show-menu .btn:nth-child(6) {
    top: 120px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  input#toggle:checked ~ #show-menu .btn:nth-child(7) {
    top: 80px;
    left: -32px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  input#toggle:checked ~ #show-menu .btn:nth-child(8) {
    top: 35px;
    left: -67px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  input#toggle:checked ~ #show-menu .btn:nth-child(9) {
    top: -50px;
    left: -50px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }

  .menuBtn,
  .closeBtn {
    position: absolute;
    transition: all 0.3s ease;
  }

  .closeBtn {
    transform: translateY(50px);
    opacity: 0;
  }

  input#toggle:checked ~ #show-menu .btn .menuBtn {
    transform: translateY(-50px);
    opacity: 0;
  }

  input#toggle:checked ~ #show-menu .btn .closeBtn {
    transform: translateY(0px);
    opacity: 1;
  }
}
.circleMenu {
  display: none;
  @media (max-width: 1800px) and (min-width: 320px) {
    display: block;
    width: 70px;
  }
}
