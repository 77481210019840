.blogpost-screen-tab-1 {
    background-color: #CBD5E0;
    width: 768px;
    margin: auto;
    .branding-photoshooting {
        .brand-heading {
            font-size: 10px;
            margin-left: 50px !important;

        }

        img {
            width: 70px;
            border-radius: 50%;
            margin-left: 9px;
            height: 70px;
        }
    }

    .blogpost-circle {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #7CBAA7;
        top: 270px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    .brandingparagraph {
        width: 179px !important;
        text-align: justify !important;
        margin-left: 50px !important;
        line-height: 23px;
        font-size: 12px;
    }

    .blogpost-content {
        position: absolute;
        bottom: 42%;
        left: 8%;
        width: 235px;

        p {
            font-size: 22px;
            line-height: 30px;
        }

    }

    .brand-heading2 {
        font-size: 12px;
        margin-right: 50px;
    }

    .blogpost-circle2 {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #7CBAA7;
        top: -297px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        span {
            font-size: 14px;
            font-weight: 600;
            line-height: 16px;
        }

    }



    .blogpost-content2 {
        position: absolute;
        bottom: 42%;
        left: 25%;
        width: 235px;

        p {
            line-height: 20px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .brandingparagraph2 {
        font-size: 12px;
        width: 179px !important;
        margin-right: 50px;
        line-height: 23px;
        text-align: justify;
    }

    .blog-postimage2 {
        width: 70px;
        border-radius: 50%;
        margin-right: 9px;
        height: 70px;
    }
    .bg-blogpost{
        background-color: #CBD5E0;
        
    }
    .bg-blogpost-2{
        background-color: #CBD5E0;
        
    }
}