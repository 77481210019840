@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");

.Feature-on-desktop-3{

  .grey-box-3 {
    background-color: #CBD5E0;
  padding: 120px 0px 120px 0px;
}

.image-circle-3 {
  background-color: #ffffff;

  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  
  align-items: center;
  justify-content: center;
  margin: 10px 20px 0px 10px;
  box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.125);
  z-index: 90;
}

.image-usa {
  background-color: #1EA3FF;

}

.text-center-3 p{
  font-size: 35px;
  font-weight: bolder;
}

.green-box-3 {
  background-color: #7CBAA7;
  padding: 50px 40px 50px 40px;
  width: 100%;
}

// .box-container-3{
  //   margin-top: -53px;
  // }
}