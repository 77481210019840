@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.diet-programs-main-mobile {
  .img-tranformation {
    display: block;
    margin-top: 30px;
    text-align: center;
    h4 {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 29px;
      letter-spacing: 0em;
    }
    .transform-img {
      margin-top: 30px;
      width: 100%;
    }
    .transform-img-neumorphic,
    .transform-img-glassmorphic {
      display: none;
    }
  }
}

.compare-slider-mobile-parent
{
  display: flex;
  flex-direction: column;
}
.mobile-bottom-image-row
{
  display: flex;
 margin-top: 50px;
 justify-content: space-between;
}
