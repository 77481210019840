@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');
.mobile-neumorphic-design-2{
    .diet-programs-main-mobile{
        padding: 40px 10px;
        display: block;
        .diet-programs-flex {
            display: block;
                padding: 10px;
                background: #F0F4F8;
                box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
                border-radius: 10px;
            .diet-programs-left-section {
                width: 100%;
                .diet-programs-left-section-inner{
                    background: #F0F4F8;
                    box-shadow: inset -4px -4px 10px #FFFFFF, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
                    border-radius: 20px;
                    padding: 15px;
                    img{
                        border-radius: 10px;
                    }
                }
            }
            .diet-consultaion{
                width: 100%;
                text-align: center;
                margin-top: 20px;
                h3{
                
                    font-family: Inter;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 29px;
                    letter-spacing: 0em;
                    color: #202020;
    
                }
                p{
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0em;
                    color: #202020;
                    font-style: italic;
                    font-family: 'Kalam', cursive;
                    margin-bottom: 10px;
    
                }
                .diet-consultaion-btn {
                    display: flex;
                    justify-content: center !important;
                    gap: 20px;
                    align-items: center;
                    .consultaion-btn {
                        padding: 11px 20px;
                        border-radius: 30px;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.04em;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                    .diet-buy-btn{
                        border: 0;
                        color: #202020;
                        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                    }
                    .program-btn{
                        border: 0;
                        background: #F0F4F8;
                        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                        color: #202020;
                                    
                    }
                }
            }
            .diet-programs-right-section{
                width: 100%;
                margin-top: 10px;
                    .training-guide-steps {
                        padding: 0px;
                        display: flex;
                        background-color: #fff;
                        height: 85px;
                        margin-bottom: 0;
                        filter: drop-shadow(2.79137px 2.79137px 6.97842px rgba(0, 0, 0, 0.25));
                        .consultaion-step {
                            height: 75px;
                            width: 80px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 25px;
                            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                            clip-path: polygon(0 0, 70% 0, 100% 100%, 30% 100%);
                            filter: drop-shadow(2.79137px 2.79137px 6.97842px rgba(0, 0, 0, 0.25));

                            span{
                                font-family: Inter;
                                font-size: 20px;
                                font-weight: 500;
                                line-height: 36px;
                                letter-spacing: 0em;
                            }
                        }
                        .consultaion-description{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            padding-left: 15px;
                            padding-right: 15px;
                            span {
                                font-family: Inter;
                                font-size: 13px;
                                font-weight: 600;
                                line-height: 19px;
                                letter-spacing: 0em;
                                color: #202020;
                            }
                            p{
                                font-family: Inter;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 16px;
                                letter-spacing: 0em;
                                color: #3C3D40;
                                margin-bottom: 0;
                                margin-top: 5px;
                            }
                        }
                    }
       
    
                }
              
        }
        .img-tranformation{
            display: block;
            margin-top: 30px;
            text-align: center;
            h4{
                font-family: Inter;
                font-size: 20px;
                font-weight: 600;
                line-height: 29px;
                letter-spacing: 0em;
    
            }
            .transform-img{
                margin-top: 30px;
                width: 100%;
                display: none;
            }
            .transform-img-neumorphic{
                display: block;
                margin: 30px auto 0;
            
            }
        }
    }
}
