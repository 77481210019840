.service-package-plan-neumorphic-desktop-1 {
    background-color: #EFF4F8;

    .toggle_button {
        background: #F0F4F8;
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            // box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            width: 97px;
            height: 35px;
            background: #F0F4F8;
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
            border-radius: 30px;
            color: #000000;
            text-align: center;
            border: none ;

        }

    }

    .main_row {
        background: #EFF4F8;
        border-radius: 20px;
        height: 450px;
        z-index: 0;
        position: absolute;
        width: 90%;
        box-shadow: inset -4px -4px 10px #ffffff, inset 5px 5px 10px rgba(178, 189, 203, 0.8);
    }

    .plan_content {
        display: flex;
        z-index: 20;

        h2 {
            color: #202020;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            text-align: center;
        }

        ul {
            li {
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: 17px;
                margin-bottom: 20px;

                .icon {
                    width: 1.25rem;
                    height: 1.25rem;
                    margin-right: 8px;
                }

                .text_yellow {
                    color: #FF7714;
                }
            }
        }

        .signin_btn {
            width: 110px;
            height: 110px;
            box-shadow: -4px -4px 10px #FFFFFF, 5px 5px 10px rgba(178, 189, 203, 0.8);
            background: #F0F4F8;
            border: none;
            // border: 1px solid #B2BDCB;
            // filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn_inner {
                width: 100px;
                height: 97px;
                // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                color: #202020;
                display: flex;
                justify-content: center;
                align-items: center;

                span {
                    display: block;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            .basic_btn_inner {
                background: #E3BDB5;
            }

            .professional_btn_inner {
                background: #9DCDA4;
            }

            .luxury_btn_inner {
                background: #E1C285;
            }
        }
    }

    .plan_div {
        h1 {
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
            text-align: center;
            padding-top: 10px;
            color: #3C3D40;
        }
.text_dark{
    color: #202020;
}
        .text_pink {
            color: #E3BDB5;
        }


        .text_orange {
            color: #E1C285;
        }
    }

    .arrow_icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 8px;
    }

    .plan_outer {
        width: 250px;
        height: 710px;
        background: #F0F4F8;
        border-radius: 10px 10px 200px 200px;
        box-shadow: -4px -4px 10px #FFFFFF, 5px 5px 10px rgba(178, 189, 203, 0.8);
    }

    .price {
        h2 {
            font-weight: 700;
            font-size: 48px;
            line-height: 40px;
            text-align: center;
            color: #202020;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #202020;
        }
    }

    .ribbon1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 20px);
        height: 120px;
        line-height: 50px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #E3BDB5;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid #E5A091;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            bottom: -10px;
            border-top: 10px solid #E5A091;
            border-right: 10px solid transparent;
        }
    }

    .ribbon_1 {
        display: block;
        width: calc(100% + 20px);
        height: 10px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #E3BDB5;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -10px;
            left: 0;
            border-bottom: 10px solid #E5A091;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: -10px;
            border-bottom: 10px solid #E5A091;
            border-right: 10px solid transparent;
        }
    }



    .ribbon2 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 20px);
        height: 120px;
        line-height: 50px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #9DCDA4;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid #73A178;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            bottom: -10px;
            border-top: 10px solid #73A178;
            border-right: 10px solid transparent;
        }
    }

    .ribbon_2 {
        display: block;
        width: calc(100% + 20px);
        height: 10px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #9DCDA4;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -10px;
            left: 0;
            border-bottom: 10px solid #73A178;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: -10px;
            border-bottom: 10px solid #73A178;
            border-right: 10px solid transparent;
        }
    }



    .ribbon3 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 20px);
        height: 120px;
        line-height: 50px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #E1C285;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            bottom: -10px;
            left: 0;
            border-top: 10px solid #D3B57D;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            bottom: -10px;
            border-top: 10px solid #D3B57D;
            border-right: 10px solid transparent;
        }
    }

    .ribbon_3 {
        display: block;
        width: calc(100% + 20px);
        height: 10px;
        text-align: center;
        margin-left: -10px;
        margin-right: -10px;
        background: #E1C285;
        position: relative;
        top: 20px;

        &:before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            top: -10px;
            left: 0;
            border-bottom: 10px solid #D3B57D;
            border-left: 10px solid transparent;
        }

        &:after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            right: 0;
            top: -10px;
            border-bottom: 10px solid #D3B57D;
            border-right: 10px solid transparent;
        }
    }


}