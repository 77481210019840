 .nicheClass {
     width: 1200px;
     ul.nichul {
         display: flex;
         flex-direction: column;
         text-align: center;
         padding-bottom: 40px;
         li.nichli {
             width: 100%;
             position: relative;
             margin-bottom: -15px;

             &:nth-child(odd) {
                 &::before {
                     position: absolute;
                     content: "";
                     background: url('../../../../images/StepZero/1.png') no-repeat;
                     height: 183px;
                     width: 90%;
                     top: 170px;
                     right: 0px;
                     background-size: 100% 100%;
                 }
             }

             &:nth-child(even) {
                 &::before {
                     position: absolute;
                     content: "";
                     background: url('../../../../images/StepZero/2.png') no-repeat;
                     height: 170px;
                     width: 90%;
                     top: 60px;
                     right: 0px;
                     background-size: 100% 100%;
                 }
             }
         }
     }
     .toptb {
         span {
             position: relative;
             width: 100px;

             &::after {
                 content: "";
                 position: absolute;
                 left: -9px;
                 bottom: 0;
                 background: url("../../../../images/StepZero/img4.png") no-repeat;
                 width: 27px;
                 height: 67px;
             }

             img {
                 margin-bottom: 15px;
             }

             h6 {
                 color: #525151;
                 text-align: center;
                 text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                 font-family: Lao MN;
                 font-size: 8px;
                 font-style: normal;
                 font-weight: 400;
                 line-height: 10px;
                 text-transform: uppercase;
                 margin: 0 0 18px;
             }

             &:last-child {
                 &::after {
                     bottom: -6px;
                 }

                 &::before {
                     content: "";
                     position: absolute;
                     right: -9px;
                     bottom: -25px;
                     background: url("../../../../images/StepZero/img4.png") no-repeat;
                     width: 27px;
                     height: 67px;
                 }
             }
         }
     }

    //  .dropheight{
    //     li {
    //         &::before{
    //             content: none;
    //         }
    //     }
    //  }
 }
.pb-200{
    padding-bottom: 200px;
}
 .nicheClass1 {
    width: 1200px;
    ul.nichul {
        display: flex;
        flex-direction: column;
        text-align: center;
        li.nichli {
            width: 100%;
            position: relative;
            margin-bottom: -15px;

            &:nth-child(odd) {
                padding-right: 24px;
                &::before {
                    position: absolute;
                    content: "";
                    background: url('../../../../images/StepZero/24s.png') no-repeat;
                    height: 341px;
                    width: 100%;
                    top: 50px;
                    left: 0px;
                    background-size: 100% 100%;
                }
            }
            &:nth-child(even) {
                padding-left: 24px;
                &::before {
                    position: absolute;
                    content: "";
                    background: url('../../../../images/StepZero/24.png') no-repeat;
                    height: 341px;
                    width: 100%;
                    top: 60px;
                    right: 0px;
                    background-size: 100% 100%;
                }
            }
            &:nth-child(1) {
                &::before {
                    position: absolute;
                    content: "";
                    background: url('../../../../images/StepZero/1.png') no-repeat;
                    height: 183px;
                    width: 90%;
                    top: 170px;
                    right: 0px;
                    left: inherit;
                    background-size: 100% 100%;
                }

            }
            &:last-child {
                &::before {
                    position: absolute;
                    content: "";
                    background: url('../../../../images/StepZero/1s.png') no-repeat;
                    height: 183px;
                    width: 90%;
                    top: 50px;
                    left: 0px;
                    right: inherit;
                    background-size: 100% 100%;
                }

            }
        }
    }
    .toptb {
        span {
            position: relative;
            width: 100px;

            &::after {
                content: "";
                position: absolute;
                left: -9px;
                bottom: 0;
                background: url("../../../../images/StepZero/img4.png") no-repeat;
                width: 27px;
                height: 67px;
            }

            img {
                margin-bottom: 15px;
            }

            h6 {
                color: #525151;
                text-align: center;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-family: Lao MN;
                font-size: 8px;
                font-style: normal;
                font-weight: 400;
                line-height: 10px;
                text-transform: uppercase;
                margin: 0 0 18px;
            }

            &:last-child {
                &::after {
                    bottom: -6px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    right: -9px;
                    bottom: -25px;
                    background: url("../../../../images/StepZero/img4.png") no-repeat;
                    width: 27px;
                    height: 67px;
                }
            }
        }
    }

   //  .dropheight{
   //     li {
   //         &::before{
   //             content: none;
   //         }
   //     }
   //  }
}

 .pt-6 {
     padding-top: 60px;
 }

 .bottom-120 {
     bottom: -120px;
 }

 .firstimg {
     position: relative;
     bottom: -30px;
 }
.h-42{
    height: 42px;
    text-align: left;
    background: linear-gradient(90deg, #FBFDFF -15.12%, #EDF6FB 27.24%, #FCFEFF 67.59%, #FAFDFF 101.74%, #FBFDFF 133.86%);
}
.mt-7{
    margin-top: -7px;
    cursor: pointer;
}
 .formdiv {
     display: inline-block;
     position: relative;
     width: 100%;
 }

 .formleft {
     display: flex;
     width: 100%;
     padding: 0px 0 10px 10px;
     justify-content: space-between;
     border-radius: 3.659px;
     border: 3.659px dashed #ddd;
     background: var(--main-grey, #EFF4F8);
     box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF inset, 7.31801px 7.31801px 14.63601px 0px #B7C7D7;
    h3 {
        color: #000;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        width: 275px;
        height: 50px;
        margin-top: 35px;
        margin-bottom: 15px;
        border-radius: 87.838px;
        background: linear-gradient(90deg, #FACB30 -0.06%, #FEF9C8 30.09%, #C49530 63.84%, #FFF8C3 99.68%);
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
    }
    input.newinput{
        width: 100%;
        height: 42px;
        border-radius: 87.838px;
        background: linear-gradient(90deg, #FBFDFF -15.12%, #EDF6FB 27.24%, #FCFEFF 67.59%, #FAFDFF 101.74%, #FBFDFF 133.86%);
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA inset, -2.63514px -2.63514px 3.51351px 0px #FFF inset, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
     }
    span {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 15px;

        .h4 {
            margin: 0;
            width: 85px;
            padding: 63px 0 0;
            h4 {
                color: #000;
                text-align: center;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-family: Lao MN;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 42px;
                margin: 0;
                margin: 0;
                width: 100%;
                height: 42px;
                padding: 0;
                border-radius: 87.838px;
                background: var(--Purple-Vertical, linear-gradient(90deg, #7E5F7E 15.22%, #CFACCF 45.82%, #A57EA5 76.03%, #DCC9DC 107.55%, #7E5F7E 133.82%));
                box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
            }
            input{
            width: 100%;
            height: 42px;
            border-radius: 87.838px;
            background: linear-gradient(90deg, #FBFDFF -15.12%, #EDF6FB 27.24%, #FCFEFF 67.59%, #FAFDFF 101.74%, #FBFDFF 133.86%);
            box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA inset, -2.63514px -2.63514px 3.51351px 0px #FFF inset, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
            }
        }

        .h5 {
            width: 150px;
            padding: 0;
            margin: 0;

            h5 {
                color: #000;
                text-align: center;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-family: Lao MN;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 42px;
                margin: 0;
                height: 42px;
                width: 100%;
                padding: 0;
                border-radius: 87.838px;
                background: var(--Pink-Vertical, linear-gradient(90deg, #FF9DA2 -19.64%, #FFE6E8 26.47%, #FEA9AE 67.57%, #FFF2F3 110.46%, #EDB4B3 146.2%));
                box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
            }
            input{
            width: 100%;
            height: 42px;
            border-radius: 87.838px;
            background: linear-gradient(90deg, #FBFDFF -15.12%, #EDF6FB 27.24%, #FCFEFF 67.59%, #FAFDFF 101.74%, #FBFDFF 133.86%);
            box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA inset, -2.63514px -2.63514px 3.51351px 0px #FFF inset, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
            }
        }

        .h6 {
            width: 85px;
            padding: 63px 0 0;
            margin: 0;

            h6 {
                color: #000;
                text-align: center;
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-family: Lao MN;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 42px;
                margin: 0;
                height: 42px;
                width: 100%;
                padding: 0;
                border-radius: 87.838px;
                border-radius: 87.838px;
                background: var(--Blue-Vertical, linear-gradient(90deg, #6A95B8 -15.12%, #B6DBF1 27.24%, #71A4CD 67.59%, #B3DCF3 101.74%, #71A4CD 133.86%));
                box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
            }
        }

        p {
            color: #000;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 190%;
            /* 26.6px */
            margin: 0;
        }
    }
}

.tagleft {
    h4 {
        border-radius: 87.838px;
        color: #000;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        height: 40px;
    }

    h6 {
        color: #000;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 19.2px */
    }
}
.onetag {
    h4 {
        width: 90px;
        background: var(--Green-Vertical, linear-gradient(272deg, #759490 1.38%, #BFDFD2 25.76%, #94BCB7 51.14%, #BFDFD2 73.49%, #759490 97.35%));
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
    }
}
.twotag {
    h4 {
        width: 150px;
        background: var(--Purple-Vertical, linear-gradient(90deg, #7E5F7E 15.22%, #CFACCF 45.82%, #A57EA5 76.03%, #DCC9DC 107.55%, #7E5F7E 133.82%));
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
    }
}
.threetag {
    h4 {
        width: 150px;
        background: var(--Gold-Vertical, linear-gradient(90deg, #FACB30 -0.06%, #FEF9C8 30.09%, #C49530 63.84%, #FFF8C3 99.68%));
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
    }
}
.fourtag {
    h4 {
        width: 150px;
        background: var(--Blue-Vertical, linear-gradient(90deg, #6A95B8 -15.12%, #B6DBF1 27.24%, #71A4CD 67.59%, #B3DCF3 101.74%, #71A4CD 133.86%));
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
    }
}
.pt-60 {
    padding-top: 60px;
}
.calculter {
    display: flex;
    min-width: 384px;
//  height: 550px;
    padding: 25px 40px 15px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: 40px;
    border: 6px solid var(--colors-2, #FACB30);
    background: var(--grey-8, #F0F4F8);
    box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
    z-index: 0;
    margin-top: -130px;
    .infotb {
        display: flex;
        width: 114px;
        padding: 3px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 40px;
        background: var(--colors-2, linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C3 116%));
        box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
        color: var(--grey-3, #3C3D40);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    h4 {
        width: 277.602px;
        height: 50px;
        color: #000;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 190%;
        border-radius: 87.838px;
        background: linear-gradient(90deg, #FACB30 -0.06%, #FEF9C8 30.09%, #C49530 63.84%, #FFF8C3 99.68%);
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
    }

    .caliner {
        padding: 15px 24px 0;
        background: var(--White, #EFF4F8);
        box-shadow: 3px 3px 7px 0px #B6C8DA, -3px -3px 4px 0px #FFF, 5px 2px 10px -6px #B7C7D7 inset;
        position: relative;
        z-index: 1;
        .calbtn {
        a {
            width: 65px;
            height: 65px;
            text-align: center;
            font-family: Lao MN;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 190%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-bottom: 20px;
            flex-direction: column;
            text-shadow: -2px -2px 2px #FFF, 1px 1px 2px #B6C8DA;
            color: #525151;
            box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.7), -6px -6px 15px 0px #FFF;
            b{
                line-height: 20px;
            }
            strong{
                line-height: normal;
                font-size: 13px;
            }
        }
        }
    }
}
.caltop{
    a.linkbtn {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        background:var(--page-background);
        box-shadow: 4px 4px 8px var(--shodow-drop-dark), -4px -4px 8px var(--shadow-light-50)!important;
    }
}
.calculaterbtm{
background: var(--White, #EFF4F8);
padding: 30px 0 10px;
width: 100%;
margin-top: -20px;
box-shadow: 3px 3px 7px 0px #B6C8DA, -3px -3px 4px 0px #FFF, 5px 2px 10px -6px #B7C7D7 inset;
a{
    margin: 0 10px;
}

}
.linbtm{
width: 150px;
height: 8px;
border-radius: 5px;
opacity: 0.2;
background: var(--grey-2, #202020);
display: inline-block;
margin-top: 20px;
}
.topheadGlob1{
display: flex;
width: 80%;
height: 42px;
justify-content: center;
align-items: center;
gap: 5.866px;
flex-shrink: 0;
position: relative;
margin-bottom:5px;
margin-top: -20px;
&::after{
    content: "";
    width: 100%;
    height: 48px;
    background: url("../../../../images/social/img1.png") no-repeat;
    background-size: 100% 100%;
    position:absolute;
    top: -24px;
    left: 0;
    z-index: 0;
}
.inrhead{
    width: 100%;
    height: 42px;
    border-radius: 4.228px 4.228px 0px 0px;
    border: 2.579px dashed var(--main-grey, #EFF4F8);
    background:var(--goldenlinear);
    box-shadow: -1.17315px -1.17315px 2.34631px 0px #FFF, 2.93288px 2.93288px 5.86576px 0px #B7C7D7;
    color: #1C1C1C;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0px 5.866px;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    line-height: 42px;
    z-index: 2;
    @media (max-width:1366px) and (min-width: 992px){ 
    font-size: 20px;
    }
}
}
.customDrop2{
    top: 85px !important;
    &::before{
        content: none!important;
    }
    .dropheight {
        max-height: 370px;
    }
    li{
        margin-bottom: 10px;
        &::after{
            bottom: 2px;
            top:3px;
        }
        b{
            font-weight: 400;
            position: absolute;
            left: 32px;
            bottom: -12px;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;
        }
        .spansec{
            justify-content: flex-start;
        }
    }
}
.login-tab2 {
    position: relative;
    .tabli {
        width: 8.3%;
        .btn-layer {
        box-shadow: -0px -0px 0px #FFFFFF, -0px -0px 0px #B8CCE0, inset -0px -0px 0px #FFFFFF;
        background: transparent;
        height: 42px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 5px;
        span {
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            height: 34px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--text-body);
            letter-spacing: 0.01em;
            box-shadow: 0px 0px 0px #C9D9E8, -0px -0px 0px #FFFFFF;
            position: relative;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            cursor: pointer;
            &::before {
            width: 35px;
            height: 45px;
            position: absolute;
            top: -42px;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 20px;
            }
            svg {
            margin-bottom: 0;
            }
        }
        }
        &.active{
        .btn-layer span {
            box-shadow: 0px 0px 0px #C9D9E8, -0px -0px 0px #FFFFFF;
            &::before {
            content: url("../../../../images/unionas.png");
            }
        }
        }
    }
}
.scroll-bar3 {
    height: 11px;
    width: 100%;
}
.borderTop1{
    border-radius: 55px;
    border: 1.5px dashed #9B9999;
    background: var(--page-background);
    padding: 45px 25px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        background: url('../../../../images/StepZero/img12.png') no-repeat;
        height: -webkit-fill-available;
        width: 13px;
        top: 33px;
        right: -25px;
        bottom: -60px;
        background-size: 100% 100%;
    }
}
// .borderTop4{
//     &::before {
//         bottom: 0px;
//     }
// }
.borderTop{
    border-radius: 55px;
    border: 1.5px dashed #9B9999;
    background: var(--page-background);
    padding: 50px 11px;
    position: relative;
    &::before{
        position: absolute;
        left: -32px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        content: "";
        background: url("../../../../images/linecurve.png") no-repeat;
        max-height: 370px;
        min-height: 200px;
        width: 38px;
        background-size: 100% 100%;
    }
    &::after{
        position: absolute;
        right: -32px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        content: "";
        background: url("../../../../images/linecurve.png") no-repeat;
        max-height: 370px;
        min-height: 200px;
        width: 38px;
        background-size: 100% 100%;
    }
    .leftlink{
        position: absolute;
        left: -34px;
        top: -440px;
        bottom: 0;
        margin: auto 0;
        height: fit-content;
    }
    .rightlink{
        position: absolute;
        right: -34px;
        top: -440px;
        bottom: 0;
        margin: auto 0;
        height: fit-content;
    }
}
.formleft1 {
    display: flex;
    width: 100%;
    padding: 0px 0 30px 10px;
    justify-content: space-between;
    border-radius: 3.659px;
    border: 3.659px dashed #ddd;
    background: var(--main-grey, #EFF4F8);
    box-shadow: -7.31801px -7.31801px 14.63601px 0px #FFF inset, 7.31801px 7.31801px 14.63601px 0px #B7C7D7;
    .range-slider__wrap{
        width: 100%;
    }
    input.newinput{
        width: 100%;
        height: 42px;
        border-radius: 87.838px;
        background: linear-gradient(90deg, #FBFDFF -15.12%, #EDF6FB 27.24%, #FCFEFF 67.59%, #FAFDFF 101.74%, #FBFDFF 133.86%);
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA inset, -2.63514px -2.63514px 3.51351px 0px #FFF inset, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
     }
}
.textlabel{
    color: var(--Icons-and-Fonts, #525151);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Lao MN;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 190%; /* 38px */
}
.newinput{
    .nav-item2{
        margin-left: -50px;
        width: 540px;
    }
    label.label1{
        width: 275px;
        position: relative;
        font-size: 15px;
        padding: 0 6px;
        text-align: left;
        &::after{
            content: "";
            position: absolute;
            bottom: -18px;
            left: 0;
            width:20px ;
            height:18px;
            display: inline-block;
            background: url("../../../../images/StepZero/img33.png") no-repeat;
            background-size: contain;
        }
    }
}
.w-66{
    min-width: 66px;
    background: url("../../../../images/StepZero/img34.png") no-repeat;
    width: 66px;
    height: 88px;
    color: #000;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Lao MN;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    padding: 6px 8px;
}
.lidiv{
    width: 100%;
    height: 105px;
    position: relative;
    &::after{
        position: absolute;
        right: -6px;
        bottom: -7px;
        content: "";
        background: url("../../../../images/StepZero/img35.png") no-repeat;
        background-size:contain;
        width: 25px;
        height: 31px;
    }
    h5{
        color: #000;
        font-family: Lao MN;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin: 0;
    }
    &:nth-child(2){
        background: var(--Metal-Green, linear-gradient(182deg, #759490 1.7%, #BFDFD2 25.08%, #94BCB7 49.45%, #BFDFD2 70.89%, #759490 93.79%));
    }
    &:nth-child(3){
        background: var(--Metal-Pink, linear-gradient(180deg, #EDB2B1 0%, #FAE2E2 23.75%, #EDB2B1 46.15%, #FAE2E2 70.62%, #EDB2B1 100%));
    }
    &:nth-child(4){
        background: var(--Metal-Blue, linear-gradient(180deg, #6B95B8 5%, #9CCAE4 26.35%, #71A4CD 48.75%, #9CCAE4 74.27%, #6B95B8 100%));
    }
}
.liDivLast{
    min-width: 165px;
    padding-top: 13px;
    h6{
        height: 30px;
        display: inline-block;
        background: var(--goldenlinear);
        color: var(--Icons-and-Fonts, #525151);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px;
        position: relative;
        width: 100%;
        right: -17px;
        &::after{
            content: "";
            position: absolute;
            right: 0;
            bottom: -13px;
            width: 19px;
            height: 13px;
            display: inline-block;
            background: url("../../../../images/StepZero/img36.png") no-repeat;
            background-size: contain;
        }
    }
}
.browserTb{
    border-radius: 18.957px;
    border: 0.903px solid #525151;
    background: var(--page-background);
    box-shadow: 2.70807px 2.70807px 6.31884px 0px #B6C8DA, -2.70807px -2.70807px 3.61077px 0px #FFF, 4.51346px 1.80538px 9.02692px -5.41615px #B7C7D7 inset;
    height: 300px;
}
.browsertop{
    padding: 10px 20px;
    border-bottom: 1px solid #525151;
}
.formheight{
    height: 544px;
    margin: 120px 0 0;
}
.mb-120{
    margin-bottom: 100px;
}
.w-80{
    width: 80px;
}
.tag1 {
    h4 {
        background: var(--Green-Vertical, linear-gradient(272deg, #759490 1.38%, #BFDFD2 25.76%, #94BCB7 51.14%, #BFDFD2 73.49%, #759490 97.35%));
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
    }
}
.tag4 {
    h4 {
        background: var(--Purple-Vertical, linear-gradient(90deg, #7E5F7E 15.22%, #CFACCF 45.82%, #A57EA5 76.03%, #DCC9DC 107.55%, #7E5F7E 133.82%));
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
    }
}
.tag2 {
    h4 {
        background: var(--Gold-Vertical, linear-gradient(90deg, #FACB30 -0.06%, #FEF9C8 30.09%, #C49530 63.84%, #FFF8C3 99.68%));
        box-shadow: 2.63514px 2.63514px 6.14865px 0px #B6C8DA, -2.63514px -2.63514px 3.51351px 0px #FFF, 4.39189px 1.75676px 8.78378px -5.27027px #B7C7D7 inset;
    }
}
.tag3 {
    h4 {
        background: var(--Metal-Pink, linear-gradient(180deg, #EDB2B1 0%, #FAE2E2 23.75%, #EDB2B1 46.15%, #FAE2E2 70.62%, #EDB2B1 100%));
        box-shadow: 2.37871px 2.37871px 5.55033px 0px #B6C8DA, -2.37871px -2.37871px 3.17162px 0px #FFF, 3.96452px 1.58581px 7.92905px -4.75743px #B7C7D7 inset;
    }
}
.tag5 {
    h4 {
        background: var(--Blue-Vertical, linear-gradient(90deg, #6A95B8 -15.12%, #B6DBF1 27.24%, #71A4CD 67.59%, #B3DCF3 101.74%, #71A4CD 133.86%));
        box-shadow: 2.37871px 2.37871px 5.55033px 0px #B6C8DA, -2.37871px -2.37871px 3.17162px 0px #FFF, 3.96452px 1.58581px 7.92905px -4.75743px #B7C7D7 inset;
    }
}
.tag6 {
    h4 {
        background: var(--Silver-Vertical, linear-gradient(90deg, #A5A5A5 -15.12%, #EAEAEA 27.24%, #B5B4B4 67.59%, #EAEAEA 101.74%, #B4B3B3 133.86%));
        box-shadow: 2.37871px 2.37871px 5.55033px 0px #B6C8DA, -2.37871px -2.37871px 3.17162px 0px #FFF, 3.96452px 1.58581px 7.92905px -4.75743px #B7C7D7 inset;
    }
}
.tag7 {
    h4 {
        background: var(--Pink-Vertical, linear-gradient(90deg, #FF9DA2 -19.64%, #FFE6E8 26.47%, #FEA9AE 67.57%, #FFF2F3 110.46%, #EDB4B3 146.2%));
        box-shadow: 2.37871px 2.37871px 5.55033px 0px #B6C8DA, -2.37871px -2.37871px 3.17162px 0px #FFF, 3.96452px 1.58581px 7.92905px -4.75743px #B7C7D7 inset;
    }
}
.fullVideo{
    .videoplayer3{
        width: 100%;
    }
    .playersec{
        width: 100%;
    }
    .topbtn{
        display: none;
    }
    .h-183{
        height: 287px;
    }
    img.w-auto{
        height: 50px;
    }
    .h-177 {
        height: 283px;
    }
}
.tagleft {
    h4{
        margin: 0;
    }
    .btn{
        font-size: 14px;
    }
}
.tagbtn{
    h4{
        width: 100%;
        height: 50px;
        padding: 9px 20px;
        border-radius: 2.981px;
        color: var(--greys-2, #1C1C1C);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 21px;
    }
}
.tagfive{
    padding-top: 50px;
    h4{
        width: 100%;
        height: 46px;
        border-radius: 87.838px;
        color: #000;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 46px;
    }
}
.mb-26{
    margin-bottom: 26px;
}
.circlediv{
    width: 165px;
    height: 165px;
    display: inline-block;
    border-radius: 50%;
    border: 2px dashed #9B9999;
    .innercircle{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: relative;
        h4{
            color: #000;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 134%; /* 32.16px */
            margin: 0 0 10px;
        }
    }
    .iconcirlce{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: inline-block;
        padding: 4px;
        margin-bottom: 20px;
        span{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 60%;
            }
        }
    }
    &:nth-child(odd){
        .innercircle{
            &::before{
                position: absolute;
                content: "";
                bottom: -12px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 122px;
                height: 39px;
                display: inline-block;
                z-index: 2;
            }
            &::after{
                position: absolute;
                content: "";
                background: url("../../../../images/StepZero/beforeimg/img2.png")no-repeat;
                background-size: contain;
                bottom: 13px;
                right: -30px;
                width: 55px;
                height: 129px;
                display: inline-block;
                z-index: 1;
            }
        }

    }
    &:nth-child(even){
        .innercircle{
            &::before{
                position: absolute;
                content: "";
                top: -1px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 122px;
                height: 39px;
                display: inline-block;
                z-index: 2;
            }
            &::after{
                position: absolute;
                content: "";
                background: url("../../../../images/StepZero/beforeimg/img4.png")no-repeat;
                background-size: contain;
                bottom: 13px;
                right: -30px;
                width: 55px;
                height: 129px;
                display: inline-block;
                z-index: 1;
            }
        }

    }
    &:last-child{
        .innercircle{
            &::after{
                content:none;
            }
        }

    }
    &:nth-child(1){
        &.active{
            .innercircle{
                background: var(--goldenlinear)!important;
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img1.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background:var(--goldenlinear)!important;
            }
        }
    }
    &:nth-child(2){
        &.active{
            .innercircle{
                background:linear-gradient(180deg, #EDB2B1 0%, #FAE2E2 23.75%, #EDB2B1 46.15%, #FAE2E2 70.62%, #EDB2B1 100%)!important;
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img2s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background:linear-gradient(180deg, #EDB2B1 0%, #FAE2E2 23.75%, #EDB2B1 46.15%, #FAE2E2 70.62%, #EDB2B1 100%)!important;
            }
        }
    }
    &:nth-child(3){
        &.active{
            .innercircle{
                background:linear-gradient(180deg, #A6A6A6 0%, #EDEDED 29.48%, #A6A6A6 53.44%, #EDEDED 76.35%, #A6A6A6 100%)!important;  
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img3s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background:linear-gradient(180deg, #A6A6A6 0%, #EDEDED 29.48%, #A6A6A6 53.44%, #EDEDED 76.35%, #A6A6A6 100%)!important;  
            }
        }
    }
    &:nth-child(4){
        &.active{
            .innercircle{
                background: var(--Radial-Green, radial-gradient(79.77% 79.77% at 27.43% 26.99%, #DFF9EF 6.97%, #9DC4C0 39.24%, #BFDFD2 61.66%, #93B4B0 83.93%, #A0CAC5 100%));
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;  
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img4s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background: var(--Radial-Green, radial-gradient(79.77% 79.77% at 27.43% 26.99%, #DFF9EF 6.97%, #9DC4C0 39.24%, #BFDFD2 61.66%, #93B4B0 83.93%, #A0CAC5 100%));
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;
            }
        }
    }
    &:nth-child(5){
        &.active{
            .innercircle{
                background: var(--Radial-Pink, radial-gradient(79.77% 79.77% at 27.43% 26.99%, #FFF2F3 6.97%, #FF9DA2 39.24%, #FFAFAE 61.66%, #EB949A 83.93%, #FF9DA2 100%));
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img5s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background: var(--Radial-Pink, radial-gradient(79.77% 79.77% at 27.43% 26.99%, #FFF2F3 6.97%, #FF9DA2 39.24%, #FFAFAE 61.66%, #EB949A 83.93%, #FF9DA2 100%));
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;
            }
        }
    }
    &:nth-child(6){
        &.active{
            .innercircle{
                background: var(--Radial-Green, radial-gradient(79.77% 79.77% at 27.43% 26.99%, #DFF9EF 6.97%, #9DC4C0 39.24%, #BFDFD2 61.66%, #93B4B0 83.93%, #A0CAC5 100%));
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;  
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img4s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background: var(--Radial-Green, radial-gradient(79.77% 79.77% at 27.43% 26.99%, #DFF9EF 6.97%, #9DC4C0 39.24%, #BFDFD2 61.66%, #93B4B0 83.93%, #A0CAC5 100%));
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;
            }
        }
    }
}
.circlediv1{
    width: 165px;
    height: 165px;
    display: inline-block;
    border-radius: 50%;
    border: 2px dashed #9B9999;
    .innercircle{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: relative;
        h4{
            color: #000;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 134%; /* 32.16px */
            margin: 0 0 10px;
        }
    }
    .iconcirlce{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: inline-block;
        padding: 4px;
        margin-bottom: 20px;
        span{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: 60%;
            }
        }
    }
    &:nth-child(odd){
        .innercircle{
            &::before{
                position: absolute;
                content: "";
                bottom: -12px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 122px;
                height: 39px;
                display: inline-block;
                z-index: 2;
            }
            &::after{
                position: absolute;
                content: "";
                background: url("../../../../images/StepZero/beforeimg/img2.png")no-repeat;
                background-size: contain;
                bottom: 13px;
                right: -30px;
                width: 55px;
                height: 129px;
                display: inline-block;
                z-index: 1;
            }
        }

    }
    &:nth-child(even){
        .innercircle{
            &::before{
                position: absolute;
                content: "";
                top: -1px;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 122px;
                height: 39px;
                display: inline-block;
                z-index: 2;
            }
            &::after{
                position: absolute;
                content: "";
                background: url("../../../../images/StepZero/beforeimg/img4.png")no-repeat;
                background-size: contain;
                bottom: 13px;
                right: -30px;
                width: 55px;
                height: 129px;
                display: inline-block;
                z-index: 1;
            }
        }

    }
    &:last-child{
        .innercircle{
            &::after{
                content:none;
            }
        }

    }
    &:nth-child(1){
        &.active{
            .innercircle{
                background:linear-gradient(90deg, #6A95B8 -15.12%, #B6DBF1 27.24%, #71A4CD 67.59%, #B3DCF3 101.74%, #71A4CD 133.86%)!important;
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img6s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background:linear-gradient(90deg, #6A95B8 -15.12%, #B6DBF1 27.24%, #71A4CD 67.59%, #B3DCF3 101.74%, #71A4CD 133.86%)!important;
            }
        }
    }
    &:nth-child(2){
        &.active{
            .innercircle{
                background:linear-gradient(90deg, #7E5F7E 15.22%, #CFACCF 45.82%, #A57EA5 76.03%, #DCC9DC 107.55%, #7E5F7E 133.82%)!important;
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img7s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background:linear-gradient(90deg, #7E5F7E 15.22%, #CFACCF 45.82%, #A57EA5 76.03%, #DCC9DC 107.55%, #7E5F7E 133.82%)!important;
            }
        }
    }
    &:nth-child(3){
        &.active{
            .innercircle{
                background: var(--Radial-Green, radial-gradient(79.77% 79.77% at 27.43% 26.99%, #DFF9EF 6.97%, #9DC4C0 39.24%, #BFDFD2 61.66%, #93B4B0 83.93%, #A0CAC5 100%));
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;  
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img8s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background:linear-gradient(182deg, #759490 1.7%, #BFDFD2 25.08%, #94BCB7 49.45%, #BFDFD2 70.89%, #759490 93.79%)!important;  
            }
        }
    }
    &:nth-child(4){
        &.active{
            .innercircle{
                background:linear-gradient(90deg, #7E5F7E 15.22%, #CFACCF 45.82%, #A57EA5 76.03%, #DCC9DC 107.55%, #7E5F7E 133.82%)!important;
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img7s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background:linear-gradient(90deg, #7E5F7E 15.22%, #CFACCF 45.82%, #A57EA5 76.03%, #DCC9DC 107.55%, #7E5F7E 133.82%)!important;
            }
        }
    }
    &:nth-child(5){
        &.active{
            .innercircle{
                background: linear-gradient(90deg, #FACB30 -0.06%, #FEF9C8 30.09%, #C49530 63.84%, #FFF8C3 99.68%)!important;
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img9s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background: linear-gradient(90deg, #FACB30 -0.06%, #FEF9C8 30.09%, #C49530 63.84%, #FFF8C3 99.68%)!important;
            }
        }
    }
    &:nth-child(6){
        &.active{
            .innercircle{
                background: #525151!important;
            }
        }
        .innercircle{
            &::before{
                background: url("../../../../images/StepZero/beforeimg/img10s.png")no-repeat;
                background-size: contain;
            }
            .iconcirlce .bg {
                background: #525151!important;
            }
        }
    }
}
.innercircle1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
}
/* tabs */
.btntb1{
    width: 165px;
    height: 48px;
    button{
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }
}
.privacyTab1 {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 20px;
      width: 90%;
      display: inline-block;
      background: #EBDDDE;
      z-index:0;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 15px;
    }
    button {
      font-weight: 500;
      font-size: 12px;
      line-height:16px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      letter-spacing: 1px;
      text-align: center;
      background: var(--page-background);
      color: var(--text-body);
      letter-spacing: 0.01em;
      box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
      position: relative;
      text-transform: uppercase;
      // &:focus {
      //   // background: linear-gradient(180deg, #DDA01A -18%, #FEC83A 116%);
      // }
    }
    button.active {
      background:var(--goldenlinear);
    }
    @media (max-width:991px) and (min-width: 320px){
      &::before {
        background: none;
      } 
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        li {
          width: 20%;
          margin-bottom: 30px;
        }
      }
    }
    @media only screen and (max-width: 767px) { 
      ul {
        li {
          width: 50%;
          text-align: center;
          margin-right: 0px!important;
        }
      }
    }
} 
.privacyTab1s {
    &::before {
      width: 40%;
    }
}
.privacyTab3s {
    &::before {
        top:6px;
    }
}
.tagleft{
    .h-42{
        height: 46px!important;
    }
    .py-2{
        padding-top: 16px;
        padding-bottom: 16px;
    }
    input{
        height: 46px;
    }
}
.sixtab{
    .hide{
        display: none;
    }
    .active{
        display:block !important;
    }
}
.innercircle {
    cursor: pointer;
}
.framebg{
    background: none!important;
    right: -20px!important;
}
.sevenBox{
    h4{
        color: #000;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0 0 7px;
    }
    p{
        color: #525151;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Lao MN;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
.w-20{
    width: 20%;
    padding-right: 15px;
}
.me-160{
    margin-right: 135px;
}
.pt-80{
    padding-top: 80px;
}
.lastcircle{
    margin-top: -75px;
    small{
        font-size: 12px;
        line-height: 16px;
    }
    .circleinner{
        height: 135px;
        width: 131px;
        padding: 7px 2px 10px 10px;
        position: relative;
        margin-right: 35px;
        cursor: pointer;
        span{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-radius: 50%;
            background: #EFF4F8;
            box-shadow: 0px 9px 6px 0px rgba(255, 255, 255, 0.27) inset, 0px -3px 4px 0px rgba(0, 0, 0, 0.31) inset;
            filter: drop-shadow(0px 14px 24px rgba(0, 0, 0, 0.35)) drop-shadow(0px -10px 24px rgba(255, 255, 255, 0.25));
            color: #000;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
        }
        &::after{
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            background: url(../../../../images/StepZero/beforeimg/img11.png) no-repeat;
            width: 170px;
            height: 150px;
            background-size: contain;
        }
        &::before{
            content: "";
            position: absolute;
            right: -12px;
            top: -10px;
            background: url(../../../../images/StepZero/beforeimg/img12.png) no-repeat;
            background-size: contain;
            width: 60px;
            height: 63px;
        }
    }
    .circleinner1{
        height: 135px;
        width: 131px;
        padding: 7px 10px 10px 2px;
        position: relative;
        margin-left: 35px;
        cursor: pointer;
        span{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-radius: 50%;
            background: #EFF4F8;
            box-shadow: 0px 9px 6px 0px rgba(255, 255, 255, 0.27) inset, 0px -3px 4px 0px rgba(0, 0, 0, 0.31) inset;
            filter: drop-shadow(0px 14px 24px rgba(0, 0, 0, 0.35)) drop-shadow(0px -10px 24px rgba(255, 255, 255, 0.25));
            color: #000;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
        &::after{
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            background: url(../../../../images/StepZero/beforeimg/img11.png) no-repeat;
            width: 170px;
            height: 150px;
            background-size: contain;
            transform: scaleX(-1);
        }
        &::before{
            content: "";
            position: absolute;
            left:-12px;
            top: -10px;
            background: url(../../../../images/StepZero/beforeimg/img12.png) no-repeat;
            background-size: contain;
            width: 60px;
            height: 63px;
            transform: scaleX(-1);
        }
    }
    .circleinner2{
        height: 210px;
        width: 206px;
        padding: 22px 12px 22px 22px;
        position: relative;
        margin: 0px 50px;
        cursor: pointer;
        span{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-radius: 50%;
            background: #EFF4F8;
            box-shadow: 0px 9px 6px 0px rgba(255, 255, 255, 0.27) inset, 0px -3px 4px 0px rgba(0, 0, 0, 0.31) inset;
            filter: drop-shadow(0px 14px 24px rgba(0, 0, 0, 0.35)) drop-shadow(0px -10px 24px rgba(255, 255, 255, 0.25));
            color: #000;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        &::after{
            content: "";
            position: absolute;
            left: -28px;
            top: 0;
            background: url(../../../../images/StepZero/beforeimg/img13.png) no-repeat;
            width: -webkit-fill-available;
            height: 202px;
            background-size: contain;
        }
        &::before{
            content: "";
            position: absolute;
            right:-114px;
            top: 4px;
            background: url(../../../../images/StepZero/beforeimg/img14.png) no-repeat;
            background-size: contain;
            width: inherit;
            height: 202px;
        }
    }
}
.me-150{
    margin-right: 150px!important;
}
.textgold{
    h4{
        font-family: Lao MN;
        // text-shadow: -2px -2px 2px var(--goldenlinear), 1px 1px 2px #B6C8DA;
        font-size: 100px;
        font-style: normal;
        font-weight: 400;
        background: radial-gradient(79.77% 79.77% at 27.43% 26.99%, #FEF9C8 6.97%, #E7B549 39.24%, #ECC54F 61.66%, #DAA127 83.93%, #EEC96E 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
.w-135{
    min-width: 135px;
}
.line-80{
    line-height: 80px;
}
.coverimg{
    object-fit: cover;
}
.w-90e{
    width: 90px!important;
}
.w-150e{
    width: 150px!important;
}
.checkboxevent {
    .large.btnRadio, input[type="radio"]{
        pointer-events: none;
    }
}
.factorTab1s{
    box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
    &::before {
      content: url("../../../../images/vidBg.png");
      position: absolute;
      top: -14px;
      right:-14px;
      z-index: 1;
    }
    .VideoIcon {
      position: absolute;
      top:0px;
      right:7px;
      z-index: 2;
    }
}
.mw-129{
    min-width: 129px;
}
.mt-87{
    margin-top: 83px;
}

.textinput{
    color: #000!important;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Lao MN;
    font-size: 23px!important;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    padding-top: 6px!important;
    height: 50px;
    width: 79%!important;
}
.sixtab2{
    .circleinner, .circleinner1 {
        .activeSpan{
            background: var(--goldenlinear);
        }
    }
}
.sixtab{
    #hide_me1{
        background: #fff!important;
    }
}
.circletab1{
    .circleiner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 100%;
        height: 328px;
        z-index: 1;
        position: relative;
        background: linear-gradient(180deg, #A6A6A6 0%, #EDEDED 29.48%, #A6A6A6 53.44%, #EDEDED 76.35%, #A6A6A6 100%);
        box-shadow: 3.84509px 3.84509px 7.69018px 0px #889FB6 inset, -4.80637px -4.80637px 7.69018px 0px #FFF inset;
        h4{
            color:#525151;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -1.08px;
            margin-top: auto;
        }
    }
    width: 320px;
    margin-right: 78px;
    display: block;
    position: relative;
    &::after{
        position: absolute;
        left: 15px;
        top:-130px;
        background: url("../../../../images/StepZero/img58.png") no-repeat;
        background-size: cover;
        display: block;
        width: 294px;
        height: 283px;
        content:"";
        z-index: 0;
    }
    .pincircle{
        display: flex;
        background: url("../../../../images/StepZero/img60.png") no-repeat;
        color:#1C1C1C;
        text-align: center;
        font-family: Lao MN;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0.36px;
        width: 140px;
        height: 173px;
        background-size: cover;
        justify-content: center;
        padding-top: 50px;
    }
    .newannous {
        background: url(../../../../images/StepZero/img62.png) no-repeat;
        background-size: 100% 100%;
        width: 192px;
        height: 132px;
        display: block;
        padding: 32px 0 0 45px;
        margin: 0 auto;
        h6{
            color: #525151;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.6px;
            width: 134px;
        }
    }
}
.circletab2{
    width: 375px;
    margin-right: 78px;
    display: block;
    position: relative;
    &::after{
        position: absolute;
        top: 80px;
        left:-197px;
        background: url("../../../../images/StepZero/img59.png") no-repeat;
        background-size: cover;
        display: block;
        width: 352px;
        height: 277px;
        content:"";
        z-index: 0;
    }
    .circleiner{
        width: 100%;
        height: 408px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        border-radius: 487px;
        background: linear-gradient(180deg, #6B95B8 5%, #9CCAE4 26.35%, #71A4CD 48.75%, #9CCAE4 74.27%, #6B95B8 100%);
        box-shadow: 3.84509px 3.84509px 7.69018px 0px #889FB6 inset, -4.80637px -4.80637px 7.69018px 0px #FFF inset;
        position: relative;
        z-index: 1;
        &::before{
            content: "";
            position: absolute;
            top: 60px;
            left: 118px;
            background: url("../../../../images/StepZero/img64.png") no-repeat;
            width: 415px;
            height:120px ;
            background-size: cover;
            z-index: -1;
        }
        h4{
            color: #2C2E2F;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.72px;
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin:auto 0 0;
            flex-direction: column;
            position: relative;
            z-index: 2;
        }
        .circle2{
            width: 329px;
            height: 308px;
            border-radius:50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background: linear-gradient(182deg, #759490 1.7%, #BFDFD2 25.08%, #94BCB7 49.45%, #BFDFD2 70.89%, #759490 93.79%);
            box-shadow: 3.84509px 3.84509px 7.69018px 0px #889FB6 inset, -4.80637px -4.80637px 7.69018px 0px #FFF inset;
            margin-bottom: -10px;
            padding-bottom: 17px;
            position: relative;
            &::before{
                content: "";
                position: absolute;
                top: 60px;
                left: 110px;
                background: url("../../../../images/StepZero/img65.png") no-repeat;
                width: 280px;
                height:154px ;
                background-size: cover;
                z-index: 0;
            }
        }
        .circle3{
            width: 190px;
            height: 190px;
            border-radius:50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url("../../../../images/StepZero/img63.png") no-repeat;
            background-size: cover;
            position: relative;
            h4{
                margin-top: 0;
            }
            &::before{
                content: "";
                position: absolute;
                top: 85px;
                left: 40px;
                background: url("../../../../images/StepZero/img66.png") no-repeat;
                width: 127px;
                height:126px ;
                background-size: cover;
            }
        }
    }
}
.circle1inner{
    position: absolute;
    right: -203px;
    top: 172px;
    width: 100px;
    img{
        width: 100%;
    }
    .iner1{
        background: url("../../../../images/StepZero/linesection/img68s.png") no-repeat;
        width: 110px;
        height: 107px;
        display: flex;
        background-size: contain;
        justify-content: center;
        align-items:flex-end;
        margin: 0 auto;
        h5{
            color:#1C1C1C;
            text-align: center;
            font-family: Lao MN;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.24px;
            margin-bottom: 34px;
        }
    }
    .newannous {
        background: url(../../../../images/StepZero/linesection/img68.png) no-repeat;
        background-size: 100% 100%;
        width: 118px;
        height: 118px;
        display: block;
        padding: 15px 0 0 38px;
        margin-left:-10px;
        h6{
            color: #525151;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: -0.6px;
            width: 70px;
        }
    }
}
.circle2inner{
    position: absolute;
    right: -80px;
    top: 317px;
    width: 100px;
    img{
        width: 100%;
    }
    .iner1{
        background: url("../../../../images/StepZero/linesection/img67s.png") no-repeat;
        width: 115px;
        height: 122px;
        display: flex;
        background-size: contain;
        justify-content: center;
        align-items:flex-end;
        margin: 0 auto;
        h5{
            color:#1C1C1C;
            text-align: center;
            font-family: Lao MN;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.24px;
            margin-bottom: 40px;
        }
    }
    .newannous {
        background: url(../../../../images/StepZero/linesection/img67.png) no-repeat;
        background-size: 100% 100%;
        width: 162px;
        height: 132px;
        display: block;
        padding: 33px 0 0 44px;
        margin-left:-27px;
        h6{
            color: #525151;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.6px;
            width: 103px;
        }
    }
}
.circle3inner{
    position: relative;
    left: 200px;
    top: 5px;
    width: 100px;
    .iner1{
        background: url("../../../../images/StepZero/linesection/img69s.png") no-repeat;
        width: 115px;
        height: 122px;
        display: flex;
        background-size: contain;
        justify-content: center;
        align-items:flex-end;
        margin: 0 auto;
        h5{
            color:#1C1C1C;
            text-align: center;
            font-family: Lao MN;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.24px;
            margin-bottom: 48px;
        }
    }
    img{
        width: 100%;
    }
    .newannous {
        background: url(../../../../images/StepZero/linesection/img69.png) no-repeat;
        background-size: 100% 100%;
        width: 142px;
        height: 112px;
        display: block;
        padding: 15px 0 0 38px;
        margin-left:-15px;
        h6{
            color: #525151;
            text-align: center;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            font-family: Lao MN;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.6px;
            width: 90px;
        }
    }
}
.headingtb{
    color: #2C2E2F;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Lao MN;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 208.333% */
    letter-spacing: -0.72px;
    h3{
        font-size: 18px;
        position: absolute;
        top:95px;
        left:260px;
    }
    h4{
        font-size: 18px;
        position: absolute;
        bottom:130px;
        right: 40px;
    }
    h5{
        font-size: 18px;
        position: absolute;
        bottom:73px;
        left: 154px;
    }
    h6{
        font-size: 24px;
        position: absolute;
        bottom:20px;
        right:110px;
    }
}
.borderTop2{
    border-radius: 55px;
    border: 1.5px dashed #9B9999;
    background: var(--page-background);
    padding: 45px 25px;
    position: relative;
}
.borderTop3{
    border-radius: 55px;
    border: 1.5px dashed #9B9999;
    background: var(--page-background);
    padding: 50px 11px;
    position: relative;
}
.borderTop4{
    #manage1{
        .factorTab1s{
            &::before {
              right:-11px;
            }
        }
    }
}
.centerdiv{
    .mauto{
        margin-left:auto;
        margin-right: auto;
    }
}
.borderTop1s{
    &::before {
        content:none;
    }
}
.borderTop5{
    border-radius: 55px;
    border: 1.5px dashed #9B9999;
    background: var(--page-background);
    padding: 45px 25px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        background: url('../../../../images/StepZero/img12.png') no-repeat;
        height: -webkit-fill-available;
        width: 13px;
        top: 33px;
        left: -25px;
        bottom: -60px;
        background-size: 100% 100%;
    }
}
.topsix{
    .sixtwo, .sixarrow{
        display: none;
    }
}
.topsix1{
    .sixone, .sixarrow{
        display: none;
    }
}

.setponeimg{
    img{
        height: auto;
    }
}
.w-63{
    width: 63%!important;
}