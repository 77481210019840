@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");


.eventTab {
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    height: 14px;
    width: 96%;
    display: inline-block;
    background: #F0F4F8;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 52px;
  }

  &::after {
    content: "";
    position: absolute;
    height: 6px;
    width: 96%;
    display: inline-block;
    background: #7CBAA7;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 56px;
  }

  .slidetab{
    margin-top: 0;
    padding: 0;
  }
  li{
    width: 190px !important;
  }
  .tab_btn_outer {
    width: 180px;
    height: 100px;
    background: #304053;
    padding-bottom: 4px;
    z-index: 1;
    position: relative;

    button {
      width: 180px;
      height: 96px;
      background: #F0F4F8;
      padding: 10px;
      font-size: 16px;
      color: #000000;
      line-height: 19.36px;
      font-weight: 400;
      text-align: center;
    }
  }

  button {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    letter-spacing: 1px;
    text-align: center;
    background: var(--page-background);
    color: var(--text-body);
    letter-spacing: 0.01em;
    // box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
    position: relative;
    text-transform: uppercase;
    // &:focus {
    //   // background: linear-gradient(180deg, #DDA01A -18%, #FEC83A 116%);
    // }
  }

  button.active {
    background: var(--goldenlinear);
  }

  @media (max-width:991px) and (min-width: 320px) {
    &::before {
      background: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li {
        width: 20%;
        margin-bottom: 30px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    ul {
      li {
        width: 50%;
        text-align: center;
        margin-right: 0px !important;
      }
    }
  }
}




.TabBtns ul li {
  position: relative;
  margin-left: 5%;
}

.TabBtns ul li:first-child {
  margin-left: 0;
}

.TabBtns ul li button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  height: 40px;
  background: #EFF4F8;
  box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
  border-radius: 40px;
}

.TabBtns ul li::before {
  content: "";
  position: absolute;
  background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%);
  box-shadow: inset -3px -3px 2px var(--shadow-light-50), inset 2px 2px 3px rgba(77, 89, 107, 0.15);
  border-radius: 5px;
  width: 60px;
  height: 9px;
  z-index: -1;
  top: 20px;
  left: -50px;
}

.TabBtns ul li:first-child:before {
  display: none;
}

.TabBtns ul li button.active {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  height: 40px;
  background:var(--goldenlinear)!important;
  color: #1C1C1C !important;
  box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
  border-radius: 40px;
}


.dark {
  .TabBtns ul li button {
    color: #fff;
    background: #1c1c1c;
    box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    // box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8), -4px -4px 8px rgba(46, 44, 44, 0.99);
  }

  .TabBtns ul li::before {
    box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99),
      inset 6px 9px 16px rgba(0, 0, 0, 0.8);
  }
}



.form-position {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  width: 100%;
  max-width: 380px;
  background: var(--theme);
  padding: 20px;
  gap: 20px;
  position: absolute;

  &.Regular-style {
    border: 1px solid #7cbaa7;
    background-color: var(--bg-white-secondary-light);

    .form-heading {
      color: var(--black);
    }

    .form-heading-one {
      color: #7cbaa7 !important;
    }

    .line {
      background-color: #9bb6af;
    }

    .c-s {
      background: #7cbaa7;
    }

    .c-si {
      background: var(--bg-color-dark) !important;
      color: var(--light);
    }

    .inp-s {
      border: #7cbaa7 1px solid;
    }

    .submit-btn-m {
      background-color: #7cbaa7;
    }
  }

  &.Neumorphic-style {
    background: var(--bg-white-secondary-light);
    box-shadow: -2px -2px 8px #ffffff, 2px 2px 8px #b2bdcb;
    border-radius: 20px;
    border: none !important;

    .form-heading-one {
      background: var(--goldenlinear);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .line {
      margin-top: 17px !important;
      height: 5px;
      width: 100%;
      margin: 0px auto;
      color: var(--theme);
      box-shadow: -1px -1px 1px #ffffff, 1px 1px 1px rgba(178, 189, 203, 0.9);
      background: var(--goldenlinear);
    }

    .c-s {
      background: var(--bg-white-secondary-light);
      box-shadow: -1px -1px 3px #ffffff, 1px 1px 3px rgba(178, 189, 203, 0.7);
      border-radius: 20px;
    }

    .c-si {
      background: var(--goldenlinear) !important;
      color: var(--black);
    }

    .inp-s {
      border: 1px solid white !important;
      box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb;
      border-radius: 4px;
    }

    .submit-btn-m {
      background: var(--goldenlinear);
      box-shadow: -4px -4px 10px #ffffff, 5px 5px 10px rgba(178, 189, 203, 0.8);
      border-radius: 5px;
    }
  }

  & .form-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
  }

  .form-heading-one {
    padding-left: 5px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
  }

  .circles {
    position: relative;
    top: -47px;
    width: 100%;
    margin: 10px auto;
  }

  .circle-text {
    font-family: "Inter";
    position: relative;
    top: -72px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .line {
    margin-top: 17px !important;
    height: 5px;
    width: 100%;
    margin: 0px auto;
    color: var(--theme);
  }

  .c-s {
    width: 30px;
    text-align: center;
    border-radius: 50%;
    height: 30px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inputs-all {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: -80px;
  }

  .inp-s {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  .submit-btn-m {
    width: 40%;
    padding: 15px;
    border: none;
    margin-top: 6px;
  }

  // form-position-end
}

.form2 {
  background-image: url("../../../../../../../images/hire-me/desktopview/form2.png");
  background-size: contain;
  background-repeat: no-repeat;
}