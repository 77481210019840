@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
// .diet-programs-main-mobile {
//   padding: 40px 10px;
//   display: block;
//   .diet-programs-flex {
//     display: block;
//     background-color: #202020;
//     padding: 5px;
//     .diet-programs-left-section {
//       width: 100%;
//       padding: 15px;
//     }
//   }
// }

.Feature-on-mobile-4{

  
  
  
  .grey-box-mobile-4 {
    background-color: #cbd5e0;
    width: 400px;
    
  height: 650px;
  margin: auto;
  flex-direction: column;
  
  
}

.image-circle-mobile-4 {
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
width: fit-content;
  align-items: center;
  justify-content: center;
  margin: 0px 4px;
  box-shadow: 0px 4px 51px 0px rgba(0, 0, 0, 0.125);
  z-index: 90;
}

.image-usa {
  background-color: #1EA3FF;
  
}




}