a {
  &:hover {
    color: var(--text-body);
  }
}
.privacyTab {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 30px;
    width: 96%;
    display: inline-block;
    background: #EBDDDE;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 21px;
  }
  button {
    font-weight: 500;
    font-size: 12px;
    line-height:16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    letter-spacing: 1px;
    text-align: center;
    background: var(--page-background);
    color: var(--text-body);
    letter-spacing: 0.01em;
    box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
    position: relative;
    text-transform: uppercase;
    // &:focus {
    //   // background: linear-gradient(180deg, #DDA01A -18%, #FEC83A 116%);
    // }
  }
  button.active {
    background:var(--goldenlinear);
  }
  @media (max-width:991px) and (min-width: 320px){
    &::before {
      background: none;
    } 
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        width: 20%;
        margin-bottom: 30px;
      }
    }
  }
  @media only screen and (max-width: 767px) { 
    ul {
      li {
        width: 50%;
        text-align: center;
        margin-right: 0px!important;
      }
    }
  }
}
.generalTab {
  ul {
    li {
      &:last-child {
        &::after {
          content: none;
        }
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      .listText {
        h5 {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.01em;
        margin-bottom: 5px;
        }
        p {
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 0.01em;
          margin: 0;
          margin-right:20px!important;
          line-height: 140%;
          color: var(--text-body);
        }
      }
    }
    .tabBdr {
      position: relative;
      padding: 20px 0;
      &::after {
        background: #EFF4F8;
        box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
        height: 2px;
        width: 100%;
        display: inline-block;
        position: absolute;
        bottom: 2px;
        left: 0;
        content: "";
      }
    }
  }
}
.RemoveLine {
  ul {
    li.tabBdr {
      &::after {
        content:none!important;
      }
    }
  }
}
.timeLinSec {
  h5 {
    line-height: 30px;
  }
}
.radioButton {
  .btn {
    width: 80px;
    height: 50px;
    line-height: 38px;
    font-size: 14px;
    box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
    border: 0px solid #000;
    color: var(--text-body);
  }
  .btn-check:checked + .btn {
    box-shadow: inset -3px -3px 10px #FFFFFF, inset 4px 4px 8px #889FB6;
  }
}
.factorTab{
  box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
  padding: 30px;
  &::before {
    content: url("../../../images/vidBg.png");;
    position: absolute;
    top:-12px;
    right:-12px;
  }
  .VideoIcon {
    position: absolute;
    top:-12px;
    right:-12px;
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 75px;
  }
  ul {
    li {
      label {
        font-size: 14px;
        font-weight: 500;
      }
      p {
        line-height: 30px;
      }
      .bgArrow {
        &::before {
          position: absolute;
          content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAQCAYAAAABOs/SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgBpZS9jtNAEIBnNg5H7o4izUkRPycaCoR04q+IrsF0iAYLOigIBQKlSQpEAQIHUVDyCL4XgA4JGqgQpakoaOw3MNIlShzvDvvrOHC5S2CieHd2x/PNzswap+nzkNU3XoC3CcA2EeonCGtylDovCpqO95FPhjAdD6HIR1ovpF6M90GPk6FcG6JcBz4ZyXcm4K01yDu2jt7ahpyvg5yb0f6Zd3zA6tuvQ0HsFRCg/EkhOSKBEb2mFUQgIlSDmmnV2ehtuaAtibQvvSHt9N7MVAgxaO08CZlS6yefhoAssiQiY+/IiqZAiq3cosKqEQ3GPk0w2sqClGJm1h2J6PTVl6GaMgeobXU70iDSp4KZkD0g2aCxXLeBoXuaYEwMJiYdNpZ+9rZ333ScX1ZhAGPUl8axNSxz7SBlwrCSkoroMlhztKm2Z/9eA9abY8292OxkjGq+9BBjeTZXYqykorrnwnELcwlT8Hg4Kvyz/ttsIdjAgwxzEUhwYo+JaItbde96Ciogck1lEo6CIAVvfOtiEGV/cv4CG/iNBIj5JCgxHWXdmUH3lU03QVlu11xlgImo5dfO33yXHsQ4EKz9N9oJ5+ArB7beNPOviqdPZEo5f2JV2DSf5v6l2x/SRf4XgpU0mn4CeV6Fw+zuomxGDSmvkW2sVOTcb3e+JIf5PhSs4a0g4VQEEpmZC2LKjfq6w/w1AvyVCxG0H39LjvJ7JFhJ89TduCjG1xXc3RXXSKy8PphxAb7fjeNlfC4FVrJ1rhtzwfvuk1nhmw8tw97uw69LQVcCK2ld6EcF5w/I1tN8EqXG8P6Ve5/2VvG1EljJmcvPIsFp4HSJHezceb8S9L/k5+de+OPjoxD+UX4DXJpPQT3IMC4AAAAASUVORK5CYII=);
          width: 34px;
          height: auto;
          display: inline-block;
          right:25px;
          top:36px;
        }
      }
    }
  }
}
.buttonArrow {
  box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
  width: 85px;
  height: 46px;
  line-height: 50px;
  &:focus {
    box-shadow: inset -3px -3px 10px #FFFFFF, inset 4px 4px 8px #889FB6;
  }
}

.dropLink{
  padding: 20px;
  z-index: 15;
  a {
    margin-bottom: 5px;
    &:focus {
      box-shadow: inset 0px 0px 0px #FFFFFF, inset 0px 0px 0px #A0B9D0!important;
    }
  }
  .DropdownRange  {
    width: 100%!important;
  }
}
.dropList {
  padding: 20px;
  z-index: 15;
  height: 300px;
  overflow-y: overlay;
  li {
    width: 330px;
    margin-bottom: 5px;
    a {
      box-shadow: 2px 2px 3px #B7C7D7, -2px -2px 3px #FFFFFF;
      border-radius: 30px;
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      &:focus {
        box-shadow: inset 0px -4px 8px #FFFFFF, inset 0px 4px 6px #A0B9D0!important;
      }
    }
  }
}
.dropdown {
  // margin-right: 20px;
  .dropArrow {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: #cea64a;
    }
  }
}
.privacySec{
  .dropdown-menu{
    position: relative;
    z-index: 15;
    &::before {
    position: absolute;
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAQCAYAAAABOs/SAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKOSURBVHgBpZS9jtNAEIBnNg5H7o4izUkRPycaCoR04q+IrsF0iAYLOigIBQKlSQpEAQIHUVDyCL4XgA4JGqgQpakoaOw3MNIlShzvDvvrOHC5S2CieHd2x/PNzswap+nzkNU3XoC3CcA2EeonCGtylDovCpqO95FPhjAdD6HIR1ovpF6M90GPk6FcG6JcBz4ZyXcm4K01yDu2jt7ahpyvg5yb0f6Zd3zA6tuvQ0HsFRCg/EkhOSKBEb2mFUQgIlSDmmnV2ehtuaAtibQvvSHt9N7MVAgxaO08CZlS6yefhoAssiQiY+/IiqZAiq3cosKqEQ3GPk0w2sqClGJm1h2J6PTVl6GaMgeobXU70iDSp4KZkD0g2aCxXLeBoXuaYEwMJiYdNpZ+9rZ333ScX1ZhAGPUl8axNSxz7SBlwrCSkoroMlhztKm2Z/9eA9abY8292OxkjGq+9BBjeTZXYqykorrnwnELcwlT8Hg4Kvyz/ttsIdjAgwxzEUhwYo+JaItbde96Ciogck1lEo6CIAVvfOtiEGV/cv4CG/iNBIj5JCgxHWXdmUH3lU03QVlu11xlgImo5dfO33yXHsQ4EKz9N9oJ5+ArB7beNPOviqdPZEo5f2JV2DSf5v6l2x/SRf4XgpU0mn4CeV6Fw+zuomxGDSmvkW2sVOTcb3e+JIf5PhSs4a0g4VQEEpmZC2LKjfq6w/w1AvyVCxG0H39LjvJ7JFhJ89TduCjG1xXc3RXXSKy8PphxAb7fjeNlfC4FVrJ1rhtzwfvuk1nhmw8tw97uw69LQVcCK2ld6EcF5w/I1tN8EqXG8P6Ve5/2VvG1EljJmcvPIsFp4HSJHezceb8S9L/k5+de+OPjoxD+UX4DXJpPQT3IMC4AAAAASUVORK5CYII=);
    width: 36px;
    height:auto ;
    display: inline-block;
    right:25%;
    }
  }

  .dropdown [data-popper-placement^='bottom-end']{
    top: 10px!important;
    &::before {
      top:-14px;
    }
  }
  .dropdown [data-popper-placement^='top-end']{
    bottom: 10px!important;
    &::before {
      bottom:-25px;
      transform: rotate(180deg);
    }
  }
  .radioButton {
    .dropdown-menu{
      &::before {
      content:none;
      }
    }
  }
}
.dropSec {
  height: 600px;
  overflow-y: overlay;
  &::before {
    content: none;
  }
  p{
    font-size: 15px;
    line-height: 19px!important;
    color: var(--text-body);
  }
  li {
    position: relative;
    &::after {
      background: #EFF4F8;
      box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
      height: 2px;
      width: 100%;
      display: inline-block;
      position: absolute;
      bottom: 2px;
      left: 0;
      content: "";
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

.customDropDown {
  .dropdown{
    position: absolute!important;
    left: 50px;
    top: 12px;
    .dropArrow.show {
      a {
        box-shadow: none!important;
      }
    }
  }
  .me-3 {
    label {
      text-align: left;
      padding-left: 22px;
    }
  }
  .dropdown-menu {
    &::before {
      content: none!important;
    }
  }
}

.privacyTab2 {
  &::before {
    height: 11px;
    top: 80px;
    width: 90%;
  }
}

.dark {
  .privacyTab {
    &::before {
      background: #666;
    }
    button {
      box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    }
  }
  .generalTab {
    ul {
      li {
        &::after {
          background: #666;
          box-shadow: 1px 1px 1px #2E2C2C, -1px -1px 1px #000;
        }
        .radioButton {
          div {
            .btn {
              box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
            }
            .btn-check:checked + .btn {
              box-shadow: inset 0px -4px 8px #2e2c2c, inset 0px 4px 6px #000;
            }
          }
        }
      }
    }
  }
  .factorTab{
    box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    &::before {
      content: url("../../../images/vidBg1.png");;
    }
  }
  .buttonArrow {
    box-shadow: 4px 4px 8px #2E2C2C, -4px -4px 8px #000;
    &:focus {
      box-shadow: inset 0px -4px 8px #2e2c2c, inset 0px 4px 6px #000;
    }
  }

}