@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');


.featured-on-neumorphic-desktop-3 {


    .Feature-on-desktop-3 {

        .grey-box-3 {
            background: #F0F4F8;
            box-shadow: 5px 4px 5px 1px rgba(67, 67, 67, 0.09);
            padding: 80px 80px 80px 80px;
            height: 800px;



        }

        .green-box-3 {
            border-radius: 20px;
            background: var(--grey-8, #F0F4F8);

            /* Neo/Light/Outer/3 */
            box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
        }

        .image-circle-3 {

            background: var(--grey-8, #F0F4F8);
            /* Neo/Light/Inner/3 */
            box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
            padding: 10px 10px;
            
        }
        
        .image-circle-3 span{
            padding:2px;
            border-radius: 50%;
            background-color: #F0F4F8;
            filter: drop-shadow(2px 2px 8px #B2BDCB) drop-shadow(-2px -2px 8px #FFF);

        }

        .image-usa {
            background-color: #F0F4F8;


        }

        .box-view-2 {
            border-radius: 20px;

            border-radius: 40px 40px 0px 0px;
            background: var(--grey-8, #F0F4F8);
            /* Neo/Light/Outer/2 */
            box-shadow: 6px 6px 15px 0px rgba(178, 189, 203, 0.70), -6px -6px 15px 0px #FFF;
        }

        .box-view-2 p {
            font-size: 35px;
            font-weight: bolder;
            color: black;
        }

        .box-container-2 {
            margin-top: -53px;
        }




    }
}