.service-package-plan-tab-3 {
    background-color: #CBD5E0;
    width: 768px;
    margin: auto;
    // height: 1075px;


    .toggle_button {
        background: #F0F4F8;
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: #7CBAA7 !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            background: transparent;
            color: #000000;
            text-align: center;
            border: none;
        }

    }

    .relative {
        position: relative;
    }
    .plan_outer {
        display: flex;
        justify-content: center;
        padding: 85px 0px 0;
        width: 230px;
        height: 624px;
        background: #3C3D40;
        border-radius: 10px;
        position: relative;

        .price {
            h2 {
                font-weight: 700;
                font-size: 32px;
                line-height: 43.57px;
                text-align: center;
                color: #FFFFFF;
                margin-bottom: 10px;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 19.36px;
                text-align: center;
                color: #F0F4F8;
            }
        }

        .ribbon_wrapper {
            position: absolute;
            top: 0;

            .ribbon {
                // height: 188px;
                width: 110px;
                position: relative;
                margin-bottom: 30px;
                background-size: cover;
                color: #202020;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                margin-left: 15px;
            

            .ribbon1 {
                position: absolute;
                top: -15px;
                right: 10px;

                span {
                    position: relative;
                    // display: block;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #7CBAA7;
                    font-size: 14px;
                    line-height: 1;
                    padding: 12px 8px 10px;
                    border-radius: 10px;
                    width: 110px;
                    height: 60px;

                    &:before {
                        position: absolute;
                        content: "";
                        height: 12px;
                        width: 12px;
                        left: -12px;
                        top: 0;
                        background: #7CBAA7;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        height: 15px;
                        width: 15px;
                        left: -15px;
                        top: 0;
                        border-radius: 15px 15px 0 0;
                        background: #54A6A1;
                    }
                }
            }

            .ribbon_1 {
                position: absolute;
                top: -15px;
                right: 10px;

                span {
                    position: relative;
                    display: block;
                    text-align: center;
                    background: #7CBAA7;
                    font-size: 14px;
                    line-height: 1;
                    padding: 12px 8px 10px;
                    width: 110px;
                    height: 50px;

                    &:before {
                        position: absolute;
                        content: "";
                        height: 12px;
                        width: 12px;
                        right: -12px;
                        top: 0;
                        border-radius: 0 10px;
                        background: #7CBAA7;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        height: 15px;
                        width: 15px;
                        right: -15px;
                        top: 0;
                        border-radius: 15px 15px 0 0;
                        background: #54A6A1;
                    }
                }
            }
        }

        }

        .plan_listing{
            ul{
                li{
                    color: #F0F4F8;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: 17px;
                padding: 15px;
                border-bottom: 1px solid #F0F4F8;
                display: flex;
                align-items: center;
                // margin-bottom: 20px;
                .icon_outer{
                    width: 25px;
                    height: 25px;
                    background: #7CBAA7;
                    border-radius: 30px;
                    padding: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;
                    .icon{
                        color: #304053;
                    }
                    .star_icon{
                        color: #FFFFFF;
                    }
                }
                }
            }
        }
        .btn{
            background: #F0F4F8;
            color: #3C3D40;
            border-radius: 30px;
        }
    }
}