
.login-tab {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 15px;
    width: 96%;
    display: inline-block;
    background: #EBDDDE;
    z-index: -1;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 12px;
  }
  li {
    width: 25%;
    .btn-layer {
      box-shadow: -1px -1px 0px #FFFFFF, -2px -2px 2px #B8CCE0, inset -1px -1px 0px #FFFFFF;
      background: var(--page-background);
      height: 42px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 5px;
      button {
        font-weight: 500;
        font-size: 11px;
        text-align: center;
        height: 34px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--page-background);
        color: var(--text-body);
        letter-spacing: 0.01em;
        box-shadow: 4px 4px 8px #C9D9E8, -4px -4px 8px #FFFFFF;
        position: relative;
        &::before {
          width: 100px;
          height: 30px;
          position: absolute;
          top: -68px;
          left: 0;
          right: 0;
          margin: 0 auto;
          border-radius: 20px;
        }
        svg {
          margin-bottom: 0;
        }
      }
      button.active {
        background: #2B2B2E;
        color: #FFFFFF;
        box-shadow: 4px 4px 8px #C9D9E8, -4px -4px 8px #FFFFFF;
        &::before {
          content: url("../../images//goldenbutton2.png")
        }
      }
    }
  }
}
.ImageCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#locked {
  img {
    width: auto;
  }
  .btn-locked {
    top:170px;
  }
}

.uploadImage{
  border: 8px solid var(--page-background);
    border-radius: 50px;
    box-shadow: inset -4px -4px 8px #fff, inset 4px 4px 8px #a0b9d0;
    -webkit-filter: drop-shadow(4px 4px 8px #bccbda) drop-shadow(-4px -4px 8px #fff);
    filter: drop-shadow(4px 4px 8px #BCCBDA) drop-shadow(-4px -4px 8px #FFFFFF);
    height: 130px;
    overflow: hidden;
    width: 90px;
    label {
      width: 100%;
      height: 100%;
      border-radius: 50px;
      padding: 5px;
      background: #d2dae7;
      div {
        width: 100%;
        height: 100%;
        border-radius: 50px;
        overflow: hidden;
        box-shadow: -4px -4px 8px #fff, inset 4px 4px 8px #a0b9d0;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          
        }
      }
    }
}

.hidden {
  display: none;
}

.neu_range_select__title {
  color: #4e4e57;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: .01em;
  line-height: 100%;
}
.neu_range_select__bg {
  background: var(--page-background);
  border-radius: 15px;
  box-shadow: inset -5px -5px 8px #fff, inset 4px 4px 8px #889fb6;
  height: 30px;
}
.neu_range_select__bar_container {
  background:var(--goldenlinear);
  border-radius: 15px;
  height: 30px;
}
.neu_range_select__bar_container .circle {
  background: var(--goldenlinear);
  border-radius: 15px;
  height: 22px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 22px;
}
.neu_range_select__number {
  color: #4e4e57;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.duration-100 {
  transition-duration: .1s;
}
.transition-all {
  transition-duration: .15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.p-\[4px\] {
  padding: 4px;
}
.w-\[111px\] {
  width: 100px;
}
.cursor-pointer {
  cursor: pointer;
}
.z-10 {
  z-index: 10;
}

.gander-button {
  background: var(--page-background);
  box-shadow: inset -3px -3px 10px #FFFFFF, inset 4px 4px 8px #889FB6;
  border-radius: 28px;
  width: 55px;
  height: 55px;
  margin: 0 auto;
  .btn {
    background: var(--page-background);
    box-shadow: 4px 4px 8px #B7C7D7, -2px -2px 8px #FFFFFF;
    color: #A1A3AC;
    border: 0px solid #000;
    width: 44px;
    height: 44px;
    line-height: 29px;
    text-align: center;
    border-radius: 50%;
  }
  .btn-check:checked + .btn{
    // color:#FFFFFF;
    background-color: inherit;
    background: var(--goldenlinear);
  box-shadow: 4px 4px 8px #B7C7D7, -4px -4px 8px #FFFFFF;
  }
  .btn:hover {
    background-color: inherit;
    background: var(--page-background);
  }
}


.textBody {
  color: var(--text-body);
}
.dividerText {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: var(--page-background);
  text-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
  position: relative;
  &::after{
    width: 150px;
    height: 2px;
    background: var(--page-background);
    box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
    content: "";
    position: absolute;
    left: 30px;
    top: 18px;
  }
  &::before{
    width: 150px;
    height: 2px;
    background: var(--page-background);
    box-shadow: 1px 1px 1px #B6C8DA, -1px -1px 1px #FFFFFF;
    content: "";
    position: absolute;
    right: 30px;
    top: 18px;
  }
}

.dividerText2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-shadow: 1px 1px 1px var(--shadow-light), -1px -1px 1px #4770DA;
  position: relative;
  color:#4770DA;
  &::after{
    width: 180px;
    height: 2px;
    background: var(--page-background);
    box-shadow: 1px 1px 1px var(--shadow-light), -1px -1px 1px var(--shadow-light-50);
    content: "";
    position: absolute;
    left: 55px;
    top: 22px;
  }
  &::before{
    width: 180px;
    height: 2px;
    background: var(--page-background);
    box-shadow: 1px 1px 1px var(--shadow-light), -1px -1px 1px var(--shadow-light-50);
    content: "";
    position: absolute;
    right: 55px;
    top: 22px;
  }
}

.IconRotate {
  transform: rotate(190deg);
}
#lockpopup {
  background:rgba(239, 244, 248, 0.8);
}
.locked {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}
.verificationCard {
  width: 220px;
  height: auto;
  padding: 20px 30px;
  .card {
    width: 70px;
    height: 70px;
    padding: 7px 0;
    border-radius: 15px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 28.77px;
    height: 55px;
    margin: 0 auto;
  }
  input {
    border-radius: 7px;
    box-shadow: inset -3px -3px 10px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
    height: 30px;
    margin-top: 10px;
  }
  .verifyIcon {
    width: 34px;
    height: 34px;
    margin: 30px auto 10px;   
    .success-section {
      button {
        height: 37px;
      }
    }
  }
}

.dark {
  .login-tab {
    &::before {
      background: #444;
    }
    li {
      .btn-layer {
        box-shadow: -1px -1px 0px #2E2C2C, -2px -2px 2px rgba(0, 0, 0, 0.68), inset -1px -1px 0px #2E2C2C;
        button {
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.68), -4px -4px 8px #2E2C2C;
        }
        button.active {
          background: var(--goldenlinear);
          box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.68), -4px -4px 8px #2E2C2C;
        }
      }
    }
  }
  .uploadImage{
      box-shadow: inset -4px -4px 8px #2E2C2C, inset 4px 4px 8px rgba(0, 0, 0, 0.68);
      -webkit-filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.68)) drop-shadow(-4px -4px 8px #2E2C2C);
      filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.68)) drop-shadow(-4px -4px 8px #2E2C2C);
  }
  .neu_range_select__bg {
    box-shadow: inset -5px -5px 8px #2E2C2C, inset 4px 4px 8px rgba(0, 0, 0, 0.68);
  }
  .gander-button {
    box-shadow: inset -3px -3px 10px rgba(0, 0, 0, 0.68), inset 4px 4px 8px #2E2C2C;
    .btn {
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.68), -4px -4px 8px #2E2C2C;
    }
    .btn-check:checked + .btn{
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.68), -4px -4px 8px #2E2C2C;
    }
  }
  .check-button {
    .btn {
      box-shadow: inset -3px -3px 10px #2E2C2C, inset 4px 4px 8px rgba(0, 0, 0, 0.68);
    }
    .btn-check:checked + .btn{
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.68), -2px -2px 3px #2E2C2C;
    }
  }
  .dividerText {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.68), -1px -1px 1px #2E2C2C;
    &::after{
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.68), -1px -1px 1px #2E2C2C;
    }
    &::before{
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.68), -1px -1px 1px #2E2C2C;
    }
  }
  .country {
    .css-13cymwt-control, .css-t3ipsp-control, .css-1kdep9t-control{
      box-shadow: inset -5px -5px 8px #2E2C2C, inset 4px 4px 8px rgba(0, 0, 0, 0.68);
    }
    .css-1nmdiq5-menu {
      box-shadow: -4px -4px 8px #2E2C2C, inset 4px 4px 8px rgba(0, 0, 0, 0.68);
    }
  }
}