.service-package-plan-neumorphic-desktop-3 {
    // background-color: #CBD5E0;

    .toggle_button {
        background: #F0F4F8;
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            // box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            width: 97px;
            height: 35px;
            background: #F0F4F8;
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
            border-radius: 30px;
            color: #000000;
            text-align: center;
            border: none ;

        }

    }

    .plan_outer {
        display: flex;
        justify-content: center;
        padding: 85px 0px 0;
        width: 320px;
        height: 664px;
        background: #F0F4F8;
        box-shadow: -4px -4px 10px #FFFFFF, 5px 5px 10px rgba(178, 189, 203, 0.8);
        border-radius: 10px;
        position: relative;

        .price {
            h2 {
                font-weight: 700;
                font-size: 36px;
                line-height: 43.57px;
                text-align: center;
                color: #202020;
                margin-bottom: 10px;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 19.36px;
                text-align: center;
                color: #202020;
            }
        }

        .ribbon_wrapper {
            position: absolute;
            top: 0;

            .ribbon {
                // height: 188px;
                width: 130px;
                position: relative;
                margin-bottom: 30px;
                background-size: cover;
                color: #fff;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                margin-left: 15px;
            

            .ribbon1 {
                position: absolute;
                top: -15px;
                right: 10px;

                span {
                    position: relative;
                    // display: block;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #3C3D40;
                    font-size: 14px;
                    line-height: 1;
                    padding: 12px 8px 10px;
                    border-radius: 10px;
                    width: 130px;
                    height: 70px;

                    &:before {
                        position: absolute;
                        content: "";
                        height: 15px;
                        width: 15px;
                        left: -15px;
                        top: 0;
                        background: #3C3D40;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        height: 15px;
                        width: 20px;
                        left: -20px;
                        top: 0;
                        border-radius: 20px 20px 0 0;
                        background: #202020;
                    }
                }
            }

            .ribbon_1 {
                position: absolute;
                top: -15px;
                right: 10px;

                span {
                    position: relative;
                    display: block;
                    text-align: center;
                    background: #3C3D40;
                    font-size: 14px;
                    line-height: 1;
                    padding: 12px 8px 10px;
                    width: 130px;
                    height: 50px;

                    &:before {
                        position: absolute;
                        content: "";
                        height: 15px;
                        width: 15px;
                        right: -15px;
                        top: 0;
                        border-radius: 0 10px;
                        background: #3C3D40;
                    }

                    &:after {
                        position: absolute;
                        content: "";
                        height: 15px;
                        width: 20px;
                        right: -20px;
                        top: 0;
                        border-radius: 20px 15px 0 0;
                        background: #202020;
                    }
                }
            }
        }

        }

        .plan_listing{
            ul{
                li{
                    color: #202020;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                line-height: 17px;
                padding: 15px 20px;
                border-bottom: 1px solid #bbbdc2;
                display: flex;
                align-items: center;
                // margin-bottom: 20px;
                .icon_outer{
                    width: 30px;
                    height: 30px;
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                    // background: #3C3D40;
                    border:2px solid #fff;
                    border-radius: 30px;
                    padding: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 5px;
                    .icon{
                        color: #202020;
                    }
                }
                }
            }
        }
        .btn{
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            color: #202020;
            border-radius: 30px;
        }
    }
}