@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.position-nav {
  width: 100%;
  height: 100%;
  align-items: center;
  background-color: var(--bg-white-secondary-light);

  &.Neumorphic-style {
    padding: 30px 10px;
    background: var(--bg-white-secondary-light);
    box-shadow: -4px -4px 10px #ffffff, 5px 5px 10px rgba(178, 189, 203, 0.8);
    border-radius: 20px;

    .p-n {
      background: var(--bg-white-secondary-light);
      padding: 10px !important;
      box-shadow: inset -2px -2px 6px #ffffff, inset 2px 2px 6px #b2bdcb !important;
      border-radius: 12px !important;

      > img {
        border-radius: 10px;
      }
    }
  }

  &.Regular-style {
    background: var(--bg-white-secondary-light) !important;
    border: 1px solid #7cbaa7;

    .p-n {
      width: auto;
      max-width: 25%;
      height: auto;
      color: #7cbaa7;
      background-color: white; // Add this line for background color

      > img {
        width: 100%;
      }
    }
  }
}
