.zoom-section .icons-circle{
  .circle {
      display: block;
      position: absolute;
      width: 15%;
      height: 15%;
      margin: -8%;
      border-radius: 50%;
      font-size: 9px;
      top: 50%;
      left: 51%;
      padding-left: 3px;
      padding-top: 1px;
    }
}
.zoom-section {

    transition: transform 0.3s;
  }
  
  .zoom-section:hover {
    transform: scale(1.1);
  }
  #parent-circle {
    position: relative;
    width: 85%;
    height: 105px;
    border: 1px solid rgb(124 186 167);
    border-radius: 50%;
    transform: rotate(0deg);
    transition: transform 0.7s linear;
    animation: rotate 7s infinite linear;
}
      #parent-circle  .printerest {
        background-color: white;
        transform: rotate(5deg) translate(319%) rotate(-5deg);

      }
      #parent-circle  .facebookk {
    background-color: white;
    transform: rotate(30deg) translate(319%) rotate(-46deg);
      }
      #parent-circle  .instaa {
        background-color: white;
        transform: rotate(60deg) translate(319%) rotate(-91deg);
      }
      #parent-circle  .twitter {
        background-color: white;
        transform: rotate(90deg) translate(319%) rotate(-92deg);
      }
      #parent-circle  .innn {
        background-color: white;
        transform: rotate(115deg) translate(319%) rotate(-95deg);
      }
    #parent-circle  .tiktok {
        background-color: white;
        transform: rotate(143deg) translate(319%) rotate(-166deg);
      }
         #parent-circle  .videoo {
        background-color: white;
        color: rgb(124 186 167);
        transform: rotate(194deg) translate(319%) rotate(-193deg);
      } 
  
        #parent-circle  .snapchat {
        background-color: white;
        color: rgb(124 186 167);
        transform: rotate(220deg) translate(319%) rotate(-215deg);
      }   #parent-circle  .logo-f{
        background-color: white;
        color: rgb(124 186 167);
        border-radius: 50px;
        padding: 0px 2px 3px;
font-size: 11px;
        transform: rotate(249deg) translate(319%) rotate(-251deg);
      }   #parent-circle  .logo2 {
        background-color: white;
        transform: rotate(279deg) translate(319%) rotate(84deg);
      }   #parent-circle  .logo-3 {
        background-color: white;
        transform: rotate(-53deg) translate(319%) rotate(-278deg);
      }   #parent-circle  .logo-4 {
        background-color: white;
        transform: rotate(-22deg) translate(319%) rotate(13deg);
      } 
      #parent-circle  .videooo {
        background-color: white;
        color: rgb(124 186 167);
        transform: rotate(169deg) translate(342%) rotate(-171deg);
      }
      .theme-one-mobile{
        .zoom-section {

          transition: transform 0.3s;
        }
        
        .zoom-section:hover {
          transform: scale(1.1);
        }
        #parent-circle {
          position: relative;
          width: 77%;
          height: 90px;
          border: 1px solid rgb(124 186 167);
          border-radius: 50%;
          transform: rotate(0deg);
          transition: transform 0.7s linear;
          animation: rotate 7s infinite linear;
      }
        .circle {
            display: block;
            position: absolute;
            width: 15%;
            height: 15%;
            margin: -8%;
            border-radius: 50%;
            font-size: 7px;
      top: 50%;
      left: 51%;
      padding-left: 3px;
      padding-top: 1px;
      }
            #parent-circle  .printerest {
              background-color: white;
              transform: rotate(5deg) translate(320%)  rotate(-5deg);
      
            }
            #parent-circle  .facebookk {
          background-color: white;
          transform: rotate(30deg) translate(320%)  rotate(-46deg);
            }
            #parent-circle  .instaa {
              background-color: white;
              transform: rotate(60deg) translate(320%)  rotate(-91deg);
            }
            #parent-circle  .twitter {
              background-color: white;
              transform: rotate(90deg) translate(320%)  rotate(-92deg);
            }
            #parent-circle  .innn {
              background-color: white;
              transform: rotate(115deg) translate(320%)  rotate(-95deg);
            }
           #parent-circle  .tiktok {
              background-color: white;
              transform: rotate(143deg) translate(320%)  rotate(-166deg);
            }   #parent-circle  .videoo {
              background-color: white;
              color: rgb(124 186 167);
              transform: rotate(194deg) translate(320%)  rotate(-193deg);
            }   #parent-circle  .snapchat {
              background-color: white;
              color: rgb(124 186 167);
              transform: rotate(220deg) translate(320%)  rotate(-215deg);
            }   #parent-circle  .logo-f{
              background-color: white;
              color: rgb(124 186 167);
              border-radius: 50px;
              padding: 0px 2px 3px;
      font-size: 11px;
              transform: rotate(249deg) translate(320%)  rotate(-251deg);
            } 
            #parent-circle  .videooo {
              background-color: white;
              color: rgb(124 186 167);
              transform: rotate(169deg) translate(342%) rotate(-171deg);
            }
              #parent-circle  .logo2 {
              background-color: white;
              transform: rotate(279deg) translate(320%)  rotate(84deg);
            }   #parent-circle  .logo-3 {
              background-color: white;
              transform: rotate(-53deg) translate(320%)  rotate(-278deg);
            }   #parent-circle  .logo-4 {
              background-color: white;
              transform: rotate(-22deg) translate(320%)  rotate(13deg);
            } 

      }
      .theme-one-tab{
        .zoom-section {

          transition: transform 0.3s;
        }
        
        .zoom-section:hover {
          transform: scale(1.1);
        }
        #parent-circle {
          position: relative;
          width: 91%;
          height: 83px;
          border: 1px solid rgb(124 186 167);
          border-radius: 50%;
          transform: rotate(0deg);
          transition: transform 0.7s linear;
          animation: rotate 7s infinite linear;
      }
        .circle {
            display: block;
            position: absolute;
            width: 15%;
            height: 15%;
            margin: -8%;
            border-radius: 50%;
            font-size: 8px;
      top: 50%;
      left: 51%;
      padding-left: 3px;
      padding-top: 1px;
      }
      #parent-circle  .printerest {
          background-color: white;
          transform: rotate(5deg) translate(318%)  rotate(-5deg);
  
        }
        #parent-circle  .facebookk {
      background-color: white;
      transform: rotate(30deg) translate(318%)  rotate(-46deg);
        }
        #parent-circle  .instaa {
          background-color: white;
          transform: rotate(60deg) translate(318%)  rotate(-91deg);
        }
        #parent-circle  .twitter {
          background-color: white;
          transform: rotate(90deg) translate(318%)  rotate(-92deg);
        }
        #parent-circle  .innn {
          background-color: white;
          transform: rotate(115deg) translate(318%)  rotate(-95deg);
        }
       #parent-circle  .tiktok {
          background-color: white;
          transform: rotate(143deg) translate(318%)  rotate(-166deg);
        }   #parent-circle  .videoo {
          background-color: white;
          color: rgb(124 186 167);
          transform: rotate(194deg) translate(318%)  rotate(-193deg);
        }   #parent-circle  .snapchat {
          background-color: white;
          color: rgb(124 186 167);
          transform: rotate(220deg) translate(318%)  rotate(-215deg);
        }   #parent-circle  .logo-f{
          background-color: white;
          color: rgb(124 186 167);
          border-radius: 50px;
          padding: 0px 2px 3px;
  font-size: 11px;
          transform: rotate(249deg) translate(318%)  rotate(-251deg);
        } 
        #parent-circle  .videooo {
          background-color: white;
          color: rgb(124 186 167);
          transform: rotate(169deg) translate(342%) rotate(-171deg);
        }
          #parent-circle  .logo2 {
          background-color: white;
          transform: rotate(279deg) translate(318%)  rotate(84deg);
        }   #parent-circle  .logo-3 {
          background-color: white;
          transform: rotate(-53deg) translate(318%)  rotate(-278deg);
        }   #parent-circle  .logo-4 {
          background-color: white;
          transform: rotate(-22deg) translate(318%)  rotate(13deg);
        } 
      }