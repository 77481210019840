@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");
.desktop-help-section{
    .programs-flex {
        gap: 40px;
        margin-top: 40px;
    }
    .how-it-work-program {
        width: 100%;
        max-width: 250px;
        height: 250px;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        padding: 20px;
        position: relative;
    
        h5 {
            font-family: Inter;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: center;
            display: block;
            width: 100%;
            max-width: 160px;
            margin: 0 auto;
        }
    
        p {
            font-size: 13px;
            margin: 0;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: center;
            font-family: 'inter';
            display: block;
            margin-top: 10px;
        }
        .how-it-work-program-numbering {
            position: absolute;
            left: 0;
            top: 15px;
            background-color: #7CBAA7;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
    
            span{
                font-size: 20px;
                font-weight: 600;
                color: #fff;
            }
        }
    }
}

