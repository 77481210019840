.service-package-plan-neumorphic-tab-6 {
    // background-color: #CBD5E0;
    width: 768px;
    margin: auto;
    // height: 1075px;

    .h_1010 {
        height: 1010px;
    }

    .toggle_button {
        background: #F0F4F8;
        border-radius: 30px;
        width: 183px;
        height: 35px;
        display: flex;

        .active_btn {
            width: 97px;
            height: 35px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%) !important;
            border-radius: 30px;
            color: #000000;
            text-align: center;
        }

        .btn1 {
            width: 97px;
            height: 35px;
            background: #F0F4F8;
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
            border-radius: 30px;
            color: #000000;
            text-align: center;
            border: none;

        }

    }

    .content_wrapper_outer {
        box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;
        padding: 0px 20px;
        background: #FFFFFF;
    .content_wrapper {
        background: #F0F4F8;
        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
        padding: 20px;
    }
}

.main-content {
    background: none;
}
    .fs_10 {
        font-size: 10px;
    }

    .main_wrapper {
        background: #F0F4F8;
        border: 4px dashed #FACB30;
        padding: 20px;

        .plan_outer_wrapper {
            background: #FFFFFF;
            padding: 20px;
            box-shadow: inset -2px -2px 6px #FFFFFF, inset 2px 2px 6px #B2BDCB;

            .modal-tabs1 {
                .nav-item {
                    .nav-link {
                        padding: 10px !important;
                        background: #F0F4F8;
                        box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                        color: #202020;
                        font-size: 13px;
                        line-height: 16px;
                        border-radius: 0;
                        font-weight: 500;
                    }
                    .active {
                        background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                        padding: 10px;
                        position: relative;
                        color: #202020;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: -15px;
                            left: 45%;
                            width: 0;
                            height: 0;
                            border-left: 9px solid transparent;
                            border-right: 9px solid transparent;
                            border-top: 15px solid #FACB30;
                        }

                    }
                }
            }
            .margin-left-btn{
                margin-left: 10px;
            }
            .tab_content_wrapper {
                background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                padding: 0 20px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            .tab_content {
                // width: 750px;
                height: 450px;
                background: #F0F4F8;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                // border-left: 20px solid #7CBAA7;
                // border-right: 20px solid #7CBAA7;
                display: flex;
                justify-content: center;
                align-items: center;

                h1 {
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 36px;
                    color: #202020;
                }

                h3 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #202020;
                    margin-bottom: 8px;
                }

                small {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #3C3D40;
                }

                h5 {
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    color: #202020;
                    margin-bottom: 15px;
                }

                ul {
                    list-style: disc;
                    padding-left: 2rem;

                    li {
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 17px;
                        color: #202020;
                        margin-bottom: 10px;
                    }
                }
            }
        }
            .tab_btn{
                .btn_left{
                    padding: 15px 20px;
                    background: #F0F4F8;
                    border: none;
                    box-shadow: -2px -2px 8px #FFFFFF, 2px 2px 8px #B2BDCB;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    color: #202020;
                }
                .btn_right{
                    padding: 15px 20px;
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    text-transform: uppercase;
                    color: #202020;
                    border: #FACB30;
                }
            }
        }
    }


    .relative {
        position: relative;
    }
}