.flex {
	display: flex;
}
.justifY-space {
	justify-content: space-between;
	align-items: center;
}

.ProgressBar-timeline {
	//background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
	box-shadow: -5.69878px -5.69878px 18.9959px rgba(255, 255, 255, 0.8),
		2.84939px 2.84939px 11.3976px rgba(166, 180, 200, 0.4);
	border-radius: 2.84939px;
	margin-bottom: 30px;
	padding: 5px 8px;

	color: lightgrey;
	position: relative;
	text-align: center;
	width: 70px;

	&:before {
		content: "";
		width: 9px;
		height: 98%;
		position: absolute;
		left: 30px;
		top: 19px;
		z-index: -2;
		background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%);
		box-shadow: inset -3px -3px 2px #ffffff, inset 2px 2px 3px rgba(77, 89, 107, 0.15);
	}

	&::after {
		content: "";
		width: 9px;
		height: 250px;
		position: absolute;
		left: 30px;
		top: 19px;
		z-index: -2;
		opacity: 1;
		background: var(--goldenlinear);
		background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
		box-shadow: 1px 5.46px 3.64px rgba(221, 190, 92, 0.25);
		border-radius: 36.4px;
	}
	.active {
		color: #000000;
	}
	.nav-link{
		padding: 0px;
	}
	ul {
		display: flex;
		flex-direction: column;

		.nav-link{
			color: black;
		}
		li {
			list-style-type: none;
			font-size: 12px;
			
			float: left;
			position: relative;
			box-shadow: inset -3.4px -3.4px 3.4px rgb(255 255 255 / 70%), inset 3.4px 3.4px 5.66667px rgb(152 151 151 / 68%);
			background: #f0f4f8;
			width: 48px;
			height: 48px;
			border-radius: 40px;
			margin: 0 auto 28px auto;
			// z-index: 25;

			h6 {
				background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
				border-radius: 50px;
				width: 30px;
				height: 30px;
				margin: 9px;
				padding: 5px;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				/* identical to box height */
                 font-weight: bold;
				text-align: center;

				 color: #585757;
			}
			.nav-link.active h6,
			.show > .nav-link h6 {
				background: linear-gradient(49.05deg, #f0bf25 -0.22%, #e28d0e 101.07%);
				box-shadow: inset 1px 1px 4px rgba(135, 118, 64, 0.2);
				border-radius: 40px;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				text-align: center;
				color: #ffffff;
			}

			// &:before {
			// 	width: 40px;
			// 	height: 40px;
			// 	line-height: 40px;
			// 	display: block;
			// 	font-size: 14px;
			// 	color: var(--shadow-light-50);
			// 	background: #aeaebb;

			// 	border-radius: 50%;
			// 	margin: 0 auto 10px auto;
			// 	border: 4px solid var(--page-background);
			//     filter: drop-shadow(4px 4px 8px var(--shadow-light)) drop-shadow(-4px -4px 8px var(--shadow-light-50));
		}

		.nav-link.active {
			h6 {
				background: linear-gradient(49.05deg, #f0bf25 -0.22%, #e28d0e 101.07%)!important;
				box-shadow: inset 1px 1px 4px rgba(135, 118, 64, 0.2);
				border-radius: 40px;
				font-weight: 500;
				font-size: 18px;
				line-height: 21px;
				text-align: center;
				color: #ffffff!important;
			}
		}
	}
}
.nav-pills .nav-link.active h6, .nav-pills .show > .nav-link h6 {
	color: #ffffff!important;
	background: linear-gradient(49.05deg, #f0bf25 -0.22%, #e28d0e 101.07%)!important;
}
.card-width {
	width: 100%;
}
.card-tab {
	background: linear-gradient(151.05deg, #ebf2f7 13.27%, #f2f6f9 83.27%);
	box-shadow: -6px -6px 20px rgba(255, 255, 255, 0.8), 3px 3px 12px rgba(166, 180, 200, 0.4);
	border-radius: 3px;
	height: 63px;
	// margin-bottom: 16px;
	margin-bottom: 13px;
	margin-left: 12px;
	.card-icon {
		color: #e28d0e;
		//background: linear-gradient(49.05deg, #F0BF25 -0.22%, #E28D0E 101.07%);
		font-size: 23px;
		padding: 0px 0px 0px 5px;
	}

	.card-text {
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		white-space: pre-wrap; 
		text-overflow: ellipsis; 
  
		text-align: left;
		display: flex;
		align-items: center;
		h5 {
			padding-left: 9px;
			padding-top: 9px;
			font-weight: 500;
			word-break: break-word;
			width: 200px;
			
		padding-right:5px;
			font-size: 12px;
			line-height: 16px;
			text-transform: capitalize;
			color: #000;
			flex: none;
			order: 1;
			flex-grow: 1;
		}
	}
	.card-progress {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.progress-line {
			width: 6px;
			height: 43px;
			background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%);
			box-shadow: inset -2px -2px 2px #ffffff, inset 1px 1px 3px rgba(77, 89, 107, 0.1);
			border-radius: 5px;
			transform: rotate(180deg);
		}
		.circles-small {
			text-align: center;
		}
		.circle-small {
			display: inline-block;
			position: relative;
			width: 68px!important;
			height: 68px;
			margin: 0px;
		}
		.circle-small svg {
			   
			width: 70px;
    box-shadow: inset -3.4px -3.4px 3.4px rgb(255 255 255 / 70%), inset 3.4px 3.4px 5.66667px rgb(152 151 151 / 68%);
    border-radius: 50%;
    height: 70px;
		}
		.circle-small .bg {
			fill: none;
			stroke-width: 6px;
			stroke: #e0e4ed;
		}
		.circle-small .progress {
			fill: none;
			stroke-width: 6px;
			background: linear-gradient(175.51deg, #1dd84e -9.47%, #1aac40 95.17%);
			box-shadow: inset 4.4px -3.52px 8.8px #31c7d0;
			border-radius: 35.2px;
			stroke: #2bc8a8;
			stroke-linecap: round;
			stroke-dasharray: 232.36;
			transform: rotate(-90deg);
			box-shadow: inset 6.07619px -4.86095px 12.1524px #31c7d0;
			transform-origin: 50% 50%;
		}
		.circle-small .progress.one {
			stroke-dashoffset: 100;
			animation: one 2.5s ease-in-out, appear 1s;
		}
		.circle-small .progress.two {
			stroke-dashoffset: 140;
			animation: two 3.5s ease-in-out, appear 2s;
		}
		.circle-small .text {
			position: absolute;
			width: 100%;
			top: 21px;
			font-weight: 500;
			font-size: 10px;
			line-height: 12px;
			text-align: center;
			color: #278674;
			left: 2px;
		}
		@keyframes one {
			from {
				stroke-dashoffset: 226.56;
			}

			to {
				stroke-dashoffset: 100;
			}
		}
	}
}
.active-tab {
	border-style: solid;
	border-width: 0px;
	border-image: linear-gradient(157deg, rgba(255 217 120) 0%, rgba(255 253 246) 60%) 4;
	box-shadow: -6px -6px 20px rgba(255, 255, 255, 0.8), 3px 3px 12px rgba(166, 180, 200, 0.4);
	border-radius: 3px;
}

.radioButton {
	.btn {
		width: 80px;
		height: 50px;
		line-height: 38px;
		font-size: 14px;
		box-shadow: 4px 4px 8px #b7c7d7, -4px -4px 8px #ffffff;
		border: 0px solid #000;
		color: var(--text-body);
	}
	.btn-check:checked + .btn {
		box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
	}
}
.factorTab {
	box-shadow: 4px 4px 8px #b7c7d7, -4px -4px 8px #ffffff;
	padding: 30px;
	&::before {
		content: url("../../../images/vidBg.png");
		position: absolute;
		top: -12px;
		right: -12px;
	}
	.VideoIcon {
		position: absolute;
		top: -12px;
		right: -12px;
	}
	h3 {
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 75px;
	}
	ul {
		li {
			label {
				font-size: 14px;
				font-weight: 500;
			}
			p {
				line-height: 30px;
			}
			.bgArrow {
				&::before {
					position: absolute;
					content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB4PSIwIiB5PSIwIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwYXRoIGQ9Ik01LDlIMTlsLTYuMjkzLDYuMjkzYTEsMSwwLDAsMS0xLjQxNCwwWiIgZmlsbD0iI2YxYjgyZSIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCI+PC9wYXRoPjwvZz48L3N2Zz4=);
					width: 34px;
					height: auto;
					display: inline-block;
					right: 25px;
					top: 36px;
				}
			}
		}
	}
}
.buttonArrow {
	box-shadow: 4px 4px 8px #b7c7d7, -4px -4px 8px #ffffff;
	width: 85px;
	height: 46px;
	line-height: 50px;
	&:focus {
		box-shadow: inset -3px -3px 10px #ffffff, inset 4px 4px 8px #889fb6;
	}
}

.dropLink {
	padding: 20px;
	z-index: 10;
	a {
		margin-bottom: 5px;
		&:focus {
			box-shadow: inset 0px 0px 0px #ffffff, inset 0px 0px 0px #a0b9d0 !important;
		}
	}
	.DropdownRange {
		width: 100% !important;
	}
}
.dropList {
	padding: 20px;
	z-index: 15;
	height: 300px;
	overflow-y: overlay;
	li {
		width: 330px;
		margin-bottom: 5px;
		a {
			box-shadow: 2px 2px 3px #b7c7d7, -2px -2px 3px #ffffff;
			border-radius: 30px;
			width: 100%;
			height: 50px;
			line-height: 50px;
			padding: 0 20px;
			&:focus {
				box-shadow: inset 0px -4px 8px #ffffff, inset 0px 4px 6px #a0b9d0 !important;
			}
		}
	}
}
.formtwo-label {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;

	color: #545c81;
}
#separator {
	height: 1em;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	.hr_s {
		margin-left: 0.5em;
		margin-right: 0.5em;
		width: 100%;
		height: 0.05em;
		background-color: #CEDAE9;
	}
	p{
		font-weight: 400;
font-size: 14px;
line-height: 16px;
margin-top:14px;
color: #545C81;
	}
}
.three-bsiness {

textarea {
    background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%);
    box-shadow: inset -6px -6px 10px rgb(255 255 255 / 75%), inset 3px 3px 10px rgb(166 180 200 / 40%);
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left !important;
    line-height: 19px;
    color: #4f5369;

}
label{
	font-weight: 400;
	font-size: 14px !important;
	line-height: 16px;
	color: #545c81;
	padding-bottom: 5px;
}
}
.btn-val {
    position: absolute;
    font-size: 16px;
    text-align: center;
    color: #6174a7;
    right: 27px;
}
.three-bsiness {
	.country .css-13cymwt-control,
	.country .css-t3ipsp-control,
	.country .css-1kdep9t-control,
	.country .css-7fr252-control {
	  // background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
	  box-shadow: -6px -6px 20px rgba(255, 255, 255, 0.8), 3px 3px 12px rgba(166, 180, 200, 0.4);
	  border-radius: 3px !important;
	}
	.time-btn .btn-bg button {
	//   background: linear-gradient(180deg, #dda01a -18%, #fec83a 116%);
	//   box-shadow: 6px 8px 8px rgb(42 46 56 / 12%);
	//   border-radius: 5px;
	//   font-size: 12px;
	//   font-weight: 600;
	//   line-height: 190%;
	//   letter-spacing: 0.05em;
	//   text-transform: uppercase;
	//   line-height: 47px;
	//   transition: all 0.4s ease-in-out 0s;
	//   position: absolute;
	//   top: 10px;
	//   left: 0px;  
	//   transform: rotate(10deg);
	}
	.btn-val {
	  position: absolute;
	  font-size: 16px;
	  text-align: center;
  
	  color: #6174a7;
	  right: 27px;
	}
	.h3-border {
	  border-bottom: 1px solid #b6c8da;
	  padding-bottom: 35px;
	  margin-bottom: 30px !important;
	}
	.h3-border1 {
		border-bottom: 1px solid #b6c8da;
		padding-bottom: 40px;
		padding-top: 15px;
		margin-bottom: 30px !important;
	  }
	li {
	  label {
		font-weight: 400;
		font-size: 14px !important;
		line-height: 16px;
		color: #545c81;
		padding-bottom: 5px;
	  }
	  textarea {
		background: linear-gradient(134.17deg, #f0f4f8 4.98%, #f0f4f8 94.88%);
		box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
		border-radius: 5px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		text-align: left!important;
		line-height: 19px;
		color: #4f5369;
	 
		::-webkit-input-placeholder { /* Edge */
		  font-style: normal;
		  font-weight: 400;
		  font-size: 16px;
		  text-align: left;
		  line-height: 19px;
		  color: #4f5369;
		}
		
		:-ms-input-placeholder { /* Internet Explorer */
		  font-style: normal;
		  font-weight: 400;
		  font-size: 16px;
		  text-align: left;
		  line-height: 19px;
		  color: #4f5369;
		}
  
	  }
	  input::placeholder {
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		text-align: left;
		line-height: 19px;
		color: #4f5369;
	  }
	}
	.factorTab ul li .bgArrow::before{
	  top: 50px;
	}
  }
  .btn-save-hide{
	.btn-val{
		display: none;
	}
  }
  .scoll-class{
	overflow-y: auto;
	height:105vh;
	margin-left: 9px;
	padding-left: 5px;
	overflow-x: hidden;
  }

.three-bsiness .nav-link.active{
	background: var(--page-background);
    box-shadow: inset -3px -3px 10px var(--shadow-light-50), inset 4px 4px 8px var(--shadow-light);
}
.class-scroll{
	// height: 105vh;
    overflow-y: inherit;
    padding: 10px;
    // overflow-x: hidden;
	.factorTab{
		padding: 20px;
	}
}
.TAB-4-form{
	input{
		background: linear-gradient(134.17deg, #F0F4F8 4.98%, #F0F4F8 94.88%);
box-shadow: inset -6px -6px 10px rgba(255, 255, 255, 0.75), inset 3px 3px 10px rgba(166, 180, 200, 0.4);
border-radius: 5px;
	}
	.first-field{
		.bgArrow:before{
			top: 20px!important;
			right: 16px;
		}
	}
	.css-qbdosj-Input{
		padding: 0px!important;
	}
}.suggest-field{
	padding-bottom: 8px;
	border-bottom: 1px solid #b6c8da;
	margin-bottom: 10px;
}
.suggest-p{


	font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
color: #474E74;
}
.card-img {
	width: 100%;
	max-width:150px;
	border-radius: calc(0.3rem - 0px);
	
  }
  
  .card-img-top {
	

  }
  
  .card-img-top:hover {
	opacity: 1;
  }
  
  .banner-content{
	position:relative;
	top:0;
	left:50px;
	width:350px;
	display:none;
  }
  
  .card-img-top:hover + .banner-content{display:block;
	    z-index: 22;

}
  .btn-adj{
	font-size: 12px;
  }
  .btn-mission{
	white-space: nowrap;
	background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
border-radius: 3px;
font-weight: 500;
font-size: 12px;
line-height: 16px;
padding: 15px 10px;
color: #5C70AD;
z-index: 1;
  }
  .direction-col{
	flex-direction: column;
	
  }

.desktop {
	.card-width{
		width: 300px;
	}
}
.card-width-desktop{
	width: 300px;
    margin-left: 60px;
	z-index: 2;
    margin-top: -4px;
	position: relative;
}
.three-hour-launch{
	.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
		color: var(--bs-nav-pills-link-active-color);
		background-color:transparent!important;
	}
	.z-per{
		z-index: 3;
		position: relative;
	}
}
.class-width-head{
	width: 24rem;
    
}
.dark {
	.three-bsiness .country .css-13cymwt-control, .three-bsiness .country .css-t3ipsp-control, .three-bsiness .country .css-1kdep9t-control, .three-bsiness .country .css-7fr252-control{
		box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
	}
	.factorTab {
		box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
	
	}
	.three-bsiness{
		li textarea , textarea{
			background: #1c1c1c;
			box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
		}
	
	}
	.ProgressBar-timeline{
		box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
	}
	.ProgressBar-timeline ul li{
		background: #1c1c1c;
		box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
	}
	.three-bsiness .h3-border{
		border-bottom: 1px solid #4a4a4a;
	}
	.card-tab{
		background: #1c1c1c;
		box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
	}
	.active-tab{
		border-image: linear-gradient(157deg, rgb(255, 217, 120) 0%, rgb(37 37 37) 60%) 4;
	}
	.card-tab .card-progress .progress-line{
		background: #3b3a3a;
		box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
	}
	.btn-mission{
		background: #1c1c1c;
		box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000;
	}
	.TAB-4-form input{
		background: #1c1c1c;
	}
	.suggest-field{
		border-bottom: 1px solid #4a4a4a;
	}
}
.border-box{
	border: 1px solid gold;
	background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
padding: 15px;
border-radius: 3px;
}

  
  #exTab2 h3 {
	
	padding : 5px 15px;
  }
  
  /* remove border radius for the tab */
  
  #exTab1 .nav-pills > li >a {
padding:10px 5px
  }
  #exTab11 .nav-pills > li  {
	
	height: 44px;
	  }
  #exTab11 .nav-pills > li >a {
	padding:10px 5px;
	height: 44px;
	  }
  .nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus {
	background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
	box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
	border-radius: 3px;
}
  
  /* change border radius for the tab , apply corners on top*/
  
  #exTab3 .nav-pills > li > a {
	border-radius: 4px 4px 0 0 ;
  }
  
  #exTab3 .tab-content {

	padding : 5px 15px;
  }
  .btn-adjj {
    width: 220px !important;
    text-align: left;
    font-size: 14px;
}
  
  
  
  
  .width-fll{
	width: 100%;
  }
@media screen and (max-width:1770px) and (min-width:1380px) {
	.flex-diction{
		flex-direction: column;
	}
 }
 position-relative{
	position: relative;
 }
 .btn-position{
	position: absolute;
	bottom: -94px;
    right: 24%;
 }
 .btn-positions {
	position: absolute;
	bottom: -94px;
    right: 24%;
 }
 .btn-positions1 {
	position: absolute;
	bottom: -120px;
    right: 20%;
 }
 .btn-positions2 {
	position: absolute;
	top: -10px;
    right: 20%;
	width:auto;
	height: 51px;
 }
 @media screen and (max-width:1290px) {
	.width-px{
		width: 100%;

	}
 }
  @media screen and (max-width:1800px) {
	.btn-positions {
		position: absolute;
		bottom: -134px;
		right: revert;
		left: 20px;
	 }
	 .mobile-h3-border{
		border-bottom: 1px solid #b6c8da;
    padding-bottom: 80px!important;
    margin-bottom: 25px !important;

	 }
 }
 .bgArrow {
input{
	pointer-events: none;
    opacity: 0!important;
}

 }
 .time-btn{
	list-style: none;
 }
 .img-set{
	width: 100%;
	height:100%;
	text-align: center;
	margin: auto;
	img{
		
		max-width: 130px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

 }
 .img-box-set{
	margin-right:18px!important;
	margin-top: 100px;
    height: 125vh;
	overflow-y: scroll;
	width: 100%;
	

 }
 .d-col {
	display: flex;
    flex-direction: column;
 }
 .width-set-mobile{
	width: 5rem;
	margin-left: 10px;
 }
 .threestep-scss{

	@media screen and (min-width:1400px) {

		.mobile{
	display: none;
		}
		.desktop{
			display: block;
				}
		.btn-adj{
			width: 260px!important;
	text-align: left;
	font-size: 14px;
		}
	}
	@media screen and (max-width:1400px) {
		.desktop{
	display: none;
		}
		.mobile{
			display: block;
			.ProgressBar-timeline{
				position: absolute;
				.card-tab .card-text {
					display: flex;
					align-items: center;
					margin-left: -34px;
					width: 228px;
					h5{
						text-align: left;
					}
				}
			}
			
		}
		
	 
	}
 }
 .dark{
	.ProgressBar-timeline:before{
		background: #202020!important;
        box-shadow: 4px 4px 8px #2e2c2c, -4px -4px 8px #000 !important;
	}
 }