@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");

.RegularTheme1Mob {
  height: 2150px;
  width: 100%;
  margin: 0px auto;
  // background: #b9c3cd;
  padding: 10px 0px;
}
.RegularTheme1Mob .text-center-mob {
  width: 100%;
  text-align: center;
  margin-top: -15px;
  margin-bottom: 60px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.RegularTheme1Mob .position-nav-mob {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 90px;
  width: 100%;
  height: 130px;
}

.RegularTheme1Mob .main-container-mob {
  height: 800px !important;
  width: 99%;
}
.RegularTheme1Mob .box-one-mob {
  width: 100%;
  // background: #7cbaa7;
  padding: 15px;
  position: absolute;
  top: 850px !important;
  left: 50%;
  transform: translateX(-50%);
}
// form position
.RegularTheme1Mob .form-position-mob {
  width: 95%;
  height: 500px !important;
  background: #f0f4f8;
  // form-position-end
}
.form-and-profile-mob .side-position-box-mob {
  width: 95%;
  height: 500px !important;
  background-image: linear-gradient(
    to right,
    #bacdc7 0%,
    #bacdc7 20%,
    #f0f4f8 20%,
    #f0f4f8 100%
  ) !important;
}
.RegularTheme1Mob .form-and-profile-mob {
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 1100px;
  justify-content: space-around;
  gap: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 500px;
}
