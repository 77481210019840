@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');


.featured-on-neumorphic-tab-1 {


  .Feature-on-tab-1 {


    .grey-box-tab {
      background: #F0F4F8;
      box-shadow: 5px 4px 5px 1px rgba(67, 67, 67, 0.09);
      border-radius: 5px;
    }

    .image-circle-tab {

      background: #F0F4F8;
      // fill: #ffffff;
      box-shadow: 5px 5px 5px 0px rgba(178, 189, 203, 0.80) inset, -4px -4px 10px 0px #FFF inset;
    }

    .image-circle-tab span {
      background-color: #F0F4F8;
      padding: 1px 1px;
      border-radius: 50%;
      filter: drop-shadow(2px 2px 8px #B2BDCB) drop-shadow(-2px -2px 8px #FFF);
    }

    .image-circle-tab::before {

      padding: -5px;
    }

    .image-usa {
      background-color: #ffffff;
    }
    .line-update-tab {

      background: #EAA91B;
      width: 100%;
      z-index: 1;
  }
    .outer-circle-tab-1 {
      width: 91%;
      // height: 50%;
      border-radius: 50%;
      background: var(--grey-8, #F0F4F8);

      /* Neo/Light/Outer/3 */
      box-shadow: 5px 5px 10px 0px rgba(178, 189, 203, 0.80), -4px -4px 10px 0px #FFF;
    }


  }

}