.TAB-4-form{
	.btn-bg.lg button {
		top: 18px;
		transform: rotate(7deg);
	}
}
.btn_rotate{
	.btn-bg.lg button {
		top: 18px;
		transform: rotate(7deg);
	}
}
.dark {
	.TAB-4-form {
		.in-field-design {
			background: #1c1c1c;
			box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99), inset 6px 9px 16px rgba(0, 0, 0, 0.8);
		}
		.border-box{
			background: #1c1c1c;
			box-shadow: inset -7px -6px 11px rgba(46, 44, 44, 0.99), inset 6px 9px 16px rgba(0, 0, 0, 0.8);
		}
	}
}
.mission-step{
	.topBtn {
		width: revert;
		height: 45px;
		margin-left: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #5C70AD;
		margin-bottom: 13px;
		bottom: -97px;
		right: 22%;
		padding: 0px 8px;
		font-size: 12px !important;
		@media screen and (max-width:1560px) {
			left: -24px;
			width: 264px;
			bottom: -136px;
			
		}
	}
}
.h4-top{
	font-style: normal;
font-weight: 500;
font-size: 14px!important;
line-height: 21px;
/* identical to box height */


color: #697094;
@media screen and (max-width:1560px) {
	padding-bottom: 67px!important;
}
}
.textarea-for{
	height: 95px;
}
