.design-screen-mobile {
    background-color: #cbd5e0;
    width: 400px;
    .himself-left{
        margin-top: 33%;
    }
    .content-text .tab-himself-text p {
        overflow-y: auto;
        height: 300px;
        padding-right: 10px;
    }
    .video-img{
   
        position: absolute!important;
        top: 16% !important;
        right: 14px!important;
        left: revert!important;
    
    }
    .zoom-section {

        transition: transform 0.3s;
      }
      
      .zoom-section:hover {
        transform: scale(1.1);
      }
   .testimonial{
        transition: transform 0.3s;
      }
       .testimonial:hover {
        transform: scale(1.1);
      }
    #parent-circle {
        position: relative;
        width: 82%;
        height: 95px;
        margin-top: 16px;
        border: 1px solid rgb(124 186 167);
        border-radius: 50%;
        transform: rotate(0deg);
        transition: transform 0.7s linear;
        animation: rotate 7s infinite linear;
    }
      .circle {
          display: block;
          position: absolute;
          width: 15%;
          height: 15%;
          margin: -8%;
          border-radius: 50%;
          font-size: 9px;
    top: 50%;
    left: 51%;
    padding-left: 1px;
    padding-top: 0px;
    }
    #parent-circle  .printerest {
        background-color: white;
        transform: rotate(5deg) translate(325%)  rotate(-5deg);

      }
      #parent-circle  .facebookk {
    background-color: white;
    transform: rotate(30deg) translate(325%)  rotate(-46deg);
      }
      #parent-circle  .instaa {
        background-color: white;
        transform: rotate(60deg) translate(325%)  rotate(-91deg);
      }
      #parent-circle  .twitter {
        background-color: white;
        transform: rotate(90deg) translate(325%)  rotate(-92deg);
      }
      #parent-circle  .innn {
        background-color: white;
        transform: rotate(115deg) translate(325%)  rotate(-95deg);
      }
     #parent-circle  .tiktok {
        background-color: white;
        transform: rotate(143deg) translate(325%)  rotate(-166deg);
      }   #parent-circle  .videoo {
        background-color: white;
        color: rgb(124 186 167);
        transform: rotate(194deg) translate(325%)  rotate(-193deg);
      }   #parent-circle  .snapchat {
        background-color: white;
        color: rgb(124 186 167);
        transform: rotate(220deg) translate(325%)  rotate(-215deg);
      }   #parent-circle  .logo-f{
        background-color: white;
        color: rgb(124 186 167);
        border-radius: 50px;
        padding: 0px 2px 3px;
font-size: 11px;
        transform: rotate(249deg) translate(325%)  rotate(-251deg);
      } 
      #parent-circle  .videooo {
        background-color: white;
        color: rgb(124 186 167);
        transform: rotate(169deg) translate(325%) rotate(-171deg);
      }
        #parent-circle  .logo2 {
        background-color: white;
        transform: rotate(279deg) translate(325%)  rotate(84deg);
      }   #parent-circle  .logo-3 {
        background-color: white;
        transform: rotate(-53deg) translate(325%)  rotate(-278deg);
      }   #parent-circle  .logo-4 {
        background-color: white;
        transform: rotate(-22deg) translate(325%)  rotate(13deg);
      } 
    .timeline-gold{
        display: none;
    }
    .meu-social{
        display: none;
    }
    .glass-show{
        display: none;
    }
    margin: auto;
    .timeline-glass{
        display: none;
    }
    .reg-sond{
        display: block;
    }
    .glass-sond{
        display: none;
    }
    .meu-img{
        display: none;
    }
    .meu-icon {
        display: none;
    }
    .book-me-mue{
        display: none;
    }
    .meu-timeline{
        display: none;
    }
    .book-me-mue{
        display: none;
    }
    .hero-section-mue{
        display: none;
    }
    .meu-timeline{
        display: none;
    }
    .switch-mobile{
        display: none;
    }
    .bookme-oter{
        position: relative;
            
                .bookme-inner{
                    background: #cbd5e0;
                    z-index: 3;
                    padding: 20px;
                    width: 400px;
                    position: absolute;
                    box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
                    right: -12px;
                    top: 54px;
            
                    .close-btn {
                        border: none;
                        background-color: transparent !important;
                        top: 0px;
                        right: 14px;
                        font-size: 34px;
                        color: grey;
                        position: absolute;
                    }
                        .forms-inpt {
                            background-color: white;
                        border-radius: 10px;
                      padding: 40px 30px;
                      margin-top: 40px;
                            .form-control {
                                width: 100%;
                                margin: 0px 3px;
                                height: 100% !important;
                                padding: 13px 30px;
                                background: transparent;
                                border: 1px solid #7cbaa7;
                                border-radius: 50px;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 17px;
                        
                                color: #808492;
                            }
                        }
                        
                        .mr-0{
                            margin-right: 0px!important;
                        }
                        .meu-timeline{
                            display: none;
                        }
                        .timelinee {
                            counter-reset: year 2016;
                            position: relative;
                            margin-top: 73px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            p {
                                font-weight: 400;
                                font-size: 8px;
                                line-height: 12px;
                                /* identical to box height */
            
                                display: flex;
                                align-items: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #1c1c1c;
                                margin-top: -3px;
                            }
                        }
            
                        .timelinee li {
                            list-style: none;
                            float: left;
            
                            position: relative;
                            text-align: center;
                            text-transform: uppercase;
                            font-family: "Dosis", sans-serif;
                        }
            
                        //   ul:nth-child(1){
                        //     color: #4caf50;
                        //   }
                        .timeline-icon {
                            margin-top: -30px;
                        }
                        .timeline-topp {
                            width: 30px;
                            height: 30px;
                            font-weight: 500;
                            font-size: 8px;
                            line-height: 13px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            position: relative;
                            color: #f0f4f8;
                            background: #5f6168;
                            top: -22px;
                            left: 24px;
                        }
                        .timelinee li {
                            z-index: 2;
                            position: relative;
            
                            border-radius: 50%;
                            display: block;
                            text-align: center;
                            line-height: 46px;
                            margin: 0 auto 10px auto;
                            width: 75px;
                            height: 75px;
                            background: #f0f4f8;
                            color: #5f6168;
                            font-size: 15px;
                            margin-right: 15px;
            
                            transition: all ease-in-out 0.3s;
                            cursor: pointer;
                            img {
                                width: 20px;
                            }
                            .message {
                                width: 25px !important;
                            }
                            .load {
                                width: 28px !important;
                            }
                            .answer {
                                width: 32px;
                            }
                        }
            
                        .timelinee:after {
                            content: "";
                            position: absolute;
            
                            width: 70%;
                            height: 12px;
                            border: 3px solid #7cbaa7;
                            background-color: #304053;
                            top: 34px;
                            left: 72px;
                            z-index: 1;
                            transition: all ease-in-out 0.3s;
                            margin: auto;
                        }
                        .timelinee li.active {
                            color: #555555;
                        }
            
                        .timelinee li.active + li:after {
                            background: #4caf50 !important;
                        }
                        .forms-inpt {
                            .form-control {
                                width:100%;
                                margin: 0px 3px;
                                height: 100% !important;
                                padding: 13px 30px;
                                background: transparent;
                                border: 1px solid #7cbaa7;
                                border-radius: 50px;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 17px;
                        
                                color: #808492;
                            }
                        }
                        .book-me-btnn{
                            width: 150px;
                        margin: auto;
                        height: 100% !important;
                        padding: 13px 30px;
                        background: #7cbaa7;
                        border: 1px solid #7cbaa7;
                        border-radius: 5px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;
                         color: #202020;
                         font-weight: 500;
                         text-align: center
                         ;
                        font-size: 13px;
                        line-height: 16px;
                        }
                }
            
             }
                 .bookme-oterr{
        position: relative;
                .bookme-inner{
                    background: #cbd5e0;
                    z-index: 3;
                    padding: 20px;
                    width: 400px;
                    position: absolute;
                    box-shadow: 4px 4px 10px rgba(40, 48, 70, 0.15);
                    top: 55px;
                    left: 0px;
            
                    .close-btn {
                        border: none;
                        background-color: transparent !important;
                        top: 0px;
                        right: 14px;
                        font-size: 34px;
                        color: grey;
                        position: absolute;
                    }
                        .forms-inpt {
                            background-color: white;
                        border-radius: 10px;
                      padding: 40px 30px;
                      margin-top: 40px;
                            .form-control {
                                width: 100%;
                                margin: 0px 3px;
                                height: 100% !important;
                                padding: 13px 30px;
                                background: transparent;
                                border: 1px solid #7cbaa7;
                                border-radius: 50px;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 17px;
                        
                                color: #808492;
                            }
                        }
                        
                        .mr-0{
                            margin-right: 0px!important;
                        }
                        .meu-timeline{
                            display: none;
                        }
                        .timelinee {
                            counter-reset: year 2016;
                            position: relative;
                            margin-top: 73px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            p {
                                font-weight: 400;
                                font-size: 8px;
                                line-height: 12px;
                                /* identical to box height */
            
                                display: flex;
                                align-items: center;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #1c1c1c;
                                margin-top: -3px;
                            }
                        }
            
                        .timelinee li {
                            list-style: none;
                            float: left;
            
                            position: relative;
                            text-align: center;
                            text-transform: uppercase;
                            font-family: "Dosis", sans-serif;
                        }
            
                        //   ul:nth-child(1){
                        //     color: #4caf50;
                        //   }
                        .timeline-icon {
                            margin-top: -30px;
                        }
                        .timeline-topp {
                            width: 30px;
                            height: 30px;
                            font-weight: 500;
                            font-size: 8px;
                            line-height: 13px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            position: relative;
                            color: #f0f4f8;
                            background: #5f6168;
                            top: -22px;
                            left: 24px;
                        }
                        .timelinee li {
                            z-index: 2;
                            position: relative;
            
                            border-radius: 50%;
                            display: block;
                            text-align: center;
                            line-height: 46px;
                            margin: 0 auto 10px auto;
                            width: 75px;
                            height: 75px;
                            background: #f0f4f8;
                            color: #5f6168;
                            font-size: 15px;
                            margin-right: 15px;
            
                            transition: all ease-in-out 0.3s;
                            cursor: pointer;
                            img {
                                width: 20px;
                            }
                            .message {
                                width: 25px !important;
                            }
                            .load {
                                width: 28px !important;
                            }
                            .answer {
                                width: 32px;
                            }
                        }
            
                        .timelinee:after {
                            content: "";
                            position: absolute;
            
                            width: 70%;
                            height: 12px;
                            border: 3px solid #7cbaa7;
                            background-color: #304053;
                            top: 34px;
                            left: 72px;
                            z-index: 1;
                            transition: all ease-in-out 0.3s;
                            margin: auto;
                        }
                        .timelinee li.active {
                            color: #555555;
                        }
            
                        .timelinee li.active + li:after {
                            background: #4caf50 !important;
                        }
                        .forms-inpt {
                            .form-control {
                                width:100%;
                                margin: 0px 3px;
                                height: 100% !important;
                                padding: 13px 30px;
                                background: transparent;
                                border: 1px solid #7cbaa7;
                                border-radius: 50px;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 17px;
                        
                                color: #808492;
                            }
                        }
                        .book-me-btnn{
                            width: 150px;
                        margin: auto;
                        height: 100% !important;
                        padding: 13px 30px;
                        background: #7cbaa7;
                        border: 1px solid #7cbaa7;
                        border-radius: 5px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 17px;
                         color: #202020;
                         font-weight: 500;
                         text-align: center
                         ;
                        font-size: 13px;
                        line-height: 16px;
                        }
                }
            
             }
        .search-position{
            position: relative;
        }
        .searchbox{
            top: -53px;
            left: 112%;
            position: absolute;
            background: #f0f4f8 !important;
            box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
            border-radius: 10px !important;
            border: none;
            z-index: 3;
            padding: 20px 30px;
            width: 380px;
            .search-area {
                margin: 0px;
                .close-btn{
                    border: none;
                    background-color:transparent!important;
                    top: -11px;
                    right: -155px;
                    font-size: 34px;
                    color: grey;
                    position: relative;
                }
                .search-field {
                    width: 100%;
                    margin: 0px 10px 0px;
                    height: 44px !important;
                    box-shadow: none;
                    padding: 2px 11px;
        
                    background: transparent;
                    border: 1px solid #394649;
                    border-radius: 50px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #394649;
                    .icon-mgni {
                        font-size: 35px !important;
                    }
                    input {
                        font-size: 15px;
                    }
                    svg {
                        cursor: pointer;
                    }
                }
            }
            .search-me-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 17px 15px;
                margin-bottom: 10px !important;
                gap: 10px;
                margin: auto;
                margin-top: 20px;
                width: 110px !important;
                height: 50px;
                background: #7cbaa7;
                border-radius: 8px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                border: none;
                letter-spacing: 0.04em;
                text-transform: uppercase;
            }
        }
        .bars-hambrger{
            position: relative;
            .react-multi-carousel-track {
                .react-multi-carousel-item{
                    button{
                        width: 150px!important;
                        background-color: transparent!important;
                    }
                   }
            }
            .hamburger-outer{
                background-color: #F0F4F8!important;
                position: absolute;
                z-index: 4;
                padding: 40px 20px 40px;
                width: 400px;
                left: -13px;
                top: -29px;
                .sidebar-li {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 16px;
                    text-align: center;
                    border-bottom: 1px solid rgb(177, 176, 176);
                    padding: 20px;
                    color: #3C3D40!important;
        
                }
             
                    .testimonial-p{
                        color: #3C3D40;
                        font-size: 15px;
                    }
                    .bottom-tabs {
                        color: #3C3D40;
                        margin-top: 5px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h6 {
                            margin-left: 10px;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 120%;
                            color:#3C3D40;
                        }
                    }
             
              .btn-close {
                    background-color: #7CBAA7;
        
                }
                .close-btn{
                    border: none;
    color: white;
    background: #7cbaa7;
                    width: 45px;
        height: 40px;
        font-size: 23px;
        position: absolute;
        right: 17px;
        top: 30px;
      
      
   
                }
                .switch-outer {
                    width: 62.06px;
                    height: 33px;
                    background-color: white;
                    border-radius: 49.5px;
                    z-index: 1;
                    margin: 0px 15px;
                    .switch {
                        display: inline-block;
                        position: relative;
                        width: 39.58px;
                        height: 22.71px;
                        box-shadow: none!important;
                        left: 6px;
                        top: 6px;
                        box-shadow: none;
                        border-radius: 49.5px;
                        z-index: 2;
                        background: #7cbaa7;
                        transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .switch::before {
                        content: "";
                        position: absolute;
                        top: 4px;
                        left: 20px;
                        width: 15.03px;
                        height: 15.03px;
                        background: white;
                        border-radius: 50%;
                        transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                            box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    }
                    .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                    }
                    input:checked + .switch {
                        background: #7cbaa7;
                    }
                    input:checked + .switch::before {
                        left: 9px;
                        background: #fff;
                    }
                    input:checked + .switch:active::before {
                        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                    }
                }
                .mode-set {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #3c3d40;
        
                    z-index: 1;
                }
                .social-set {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    color: #3c3d40;
                    z-index: 1;
                }
                .btn-close {
                    background-color: white;
                    opacity: 1;
                    border-radius: 0px !important;
                    width: 30px;
                    height: 30px;
                }
             
                .bottom-tabs {
                    color: #cbd5e0;
                    margin-top: 5px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    h6 {
                        margin-left: 10px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 120%;
                        color: #cbd5e0;
                    }
                    svg {
                        font-size: 25px;
                    }
                }
                .testimonial-p {
                   
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .top-padding {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    li {
                        padding: 0px 8px;
                    }
                }
                .testimonial {
                    margin-top: -80px;
                    .timeline-top {
                        flex-direction: column;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        position: relative;
                        color: black;
                        background: white;
                        overflow: hidden;
                        width: 87px;
                        padding: 8px;
                        height: 87px;
                        top: 93px;
                        left: 19px;
                        p {
                            margin-top: 4px;
                            line-height: 10px;
                        }
                        .icon-green {
                            font-size: 8px;
                            color: #7cbaa7;
                            padding-top: 3px;
                        }
                        p {
                            font-size: 8px;
                        }
                    }
        
                    img {
                        width: 130px;
                        margin: 0px 13px;
                        position: relative;
                        z-index: 2;
                    }
                }
            }
    
        }
    .border-b{
        border-bottom: 1px solid #B2BDCB;
    }
    .navbar-top {
        background: #cbd5e0;
        height: 135px;
        padding: 0px 12px !important;


        .testimonial-seeall {
            display: flex;
            align-items: center;
            h5 {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 159.5%;

                text-align: center;
                letter-spacing: 0.01em;
                text-decoration-line: underline;
                color: #202020;
                padding-right: 10px;
                margin-top: 5px;
            }
            img {
                width: 20px;
            }
        }
        .testimonial {
            margin-top: -80px;
            .timeline-top {
                flex-direction: column;
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: black;
                background: white;

                width: 87px;
                padding: 8px;
                height: 87px;
                top: 97px;
                left: 23px;
                p {
                    margin-top: 4px;
                    line-height: 10px;
                }
                .icon-green {
                    font-size: 8px;
                    color: #7cbaa7;
                    padding-top: 3px;
                }
                p {
                    font-size: 8px;
                }
            }

            img {
                width: 130px;
                margin: 0px 13px;
                position: relative;
                z-index: 2;
            }
        }
        .profile-img {
            width: 51%;
            margin-left: 11%;
            margin-top: -91%;
        
        }
        .icons-circle {
            width: 115px;
            margin-top: 5px;
        }
        .img-logo {
            margin-left: 20px;
            width: 78px;
        }
        .timeline {
            counter-reset: year 2016;
            position: relative;
            margin-top: 27px;
            p {
                font-weight: 400;
                font-size: 9px;
                line-height: 11px;
                /* identical to box height */

                display: flex;
                align-items: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #1c1c1c;
                margin-top: -3px;
            }
        }

        .timeline li {
            list-style: none;
            float: left;

            position: relative;
            text-align: center;
            text-transform: uppercase;
            font-family: "Dosis", sans-serif;
        }

        //   ul:nth-child(1){
        //     color: #4caf50;
        //   }
        .timeline-icon {
            margin-top: -23px;
        }
        .timeline-top {
            width: 30px;
            height: 30px;
            font-weight: 500;
            font-size: 9px;
            line-height: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            color: #f0f4f8;
            background: #5f6168;
            top: -20px;
            left: 14px;
        }
        .timeline li {
            z-index: 2;
            position: relative;

            border-radius: 50%;
            display: block;
            text-align: center;
            line-height: 50px;
            margin: 0 auto 10px auto;
            width: 60px;
            height: 60px;
        
            background: #f0f4f8;
            color: #5f6168;
            font-size: 15px;
            margin-right: 6px;

            transition: all ease-in-out 0.3s;
            cursor: pointer;
            img {
                width: 15px;
            }
            .message {
                width: 20px !important;
            }
            .load {
                width: 22px !important;
            }
            .answer {
                width: 28px;
            }
        }

        .timeline:after {
            content: "";
            position: absolute;
            width: 72%;
            height: 9px;
            border: 3px solid #7cbaa7;
            background-color: #304053;
            top: 26px;
            left: 40px;
            z-index: 1;
            transition: all ease-in-out 0.3s;
            margin: auto;
        }
        .timeline li.active {
            color: #555555;
        }

        .timeline li.active + li:after {
            background: #4caf50 !important;
        }
    }
    .mi-info{
        font-size: 25px;
        margin-left: 17px;
        margin-top: -20px;
    }
    // @media screen and (max-width: 1550px) {
    //     .navbar-top {
    //         background: #cbd5e0;
    //         height: 160px;
    //         padding: 24px !important;
    //         .testimonial {
    //             margin-top: -80px;
    //             .timeline-top {
    //                 flex-direction: column;
    //                 font-weight: 500;
    //                 font-size: 10px;
    //                 line-height: 13px;
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: center;
    //                 border-radius: 50%;
    //                 position: relative;
    //                 color: black;
    //                 background: white;

    //                 width: 87px;
    //                 padding: 8px;
    //                 height: 87px;
    //                 top: 97px;
    //                 left: 23px;
    //                 p {
    //                     margin-top: 4px;
    //                     line-height: 10px;
    //                 }
    //                 .icon-green {
    //                     font-size: 8px;
    //                     color: #7cbaa7;
    //                     padding-top: 3px;
    //                 }
    //                 p {
    //                     font-size: 8px;
    //                 }
    //             }

    //             img {
    //                 width: 130px;
    //                 margin: 0px 13px;
    //                 position: relative;
    //                 z-index: 2;
    //             }
    //         }
    //         .profile-img {
    //             width: 65px;
    //             margin-left: 26px;
    //             margin-top: -147px;
    //         }
    //         .icons-circle {
    //             width: 125px;
    //             margin-top: 18px;
    //         }
    //         .img-logo {
    //             margin-left: 11px;
    //             width: 88px;
    //         }
    //         .timeline {
    //             counter-reset: year 2016;
    //             position: relative;
    //             margin-top: 27px;
    //             p {
    //                 font-weight: 400;
    //                 font-size: 8px;
    //                 padding: 0px 15px 0px;
    //                 line-height: 11px;
    //                 display: flex;
    //                 align-items: center;
    //                 display: flex;
    //                 align-items: center;
    //                 justify-content: center;
    //                 color: #1c1c1c;
    //                 margin-top: -7px;
    //             }
    //         }

    //         .timeline li {
    //             list-style: none;
    //             float: left;

    //             position: relative;
    //             text-align: center;
    //             text-transform: uppercase;
    //             font-family: "Dosis", sans-serif;
    //         }

    //         //   ul:nth-child(1){
    //         //     color: #4caf50;
    //         //   }
    //         .timeline-icon {
    //             margin-top: -30px;
    //         }
    //         .timeline-top {
    //             width: 35px;
    //             height: 35px;
    //             font-weight: 500;
    //             font-size: 12px;
    //             line-height: 13px;
    //             display: flex;
    //             align-items: center;
    //             justify-content: center;
    //             border-radius: 50%;
    //             position: relative;
    //             color: #f0f4f8;
    //             background: #5f6168;
    //             top: -23px;
    //             left: 24px;
    //         }
    //         .timeline li {
    //             z-index: 2;
    //             position: relative;

    //             border-radius: 50%;
    //             display: block;
    //             text-align: center;
    //             line-height: 50px;
    //             margin: 0 auto 10px auto;
    //             width: 78px;
    //             height: 78px;

    //             background: #f0f4f8;
    //             color: #5f6168;
    //             font-size: 15px;
    //             margin-right: 30px;

    //             transition: all ease-in-out 0.3s;
    //             cursor: pointer;
    //             img {
    //                 width: 20px;
    //             }
    //             .message {
    //                 width: 25px !important;
    //             }
    //             .load {
    //                 width: 28px !important;
    //             }
    //             .answer {
    //                 width: 32px;
    //             }
    //         }

    //         .timeline:after {
    //             content: "";
    //             position: absolute;
    //             width: 67%;
    //             height: 12px;
    //             border: 3px solid #7cbaa7;
    //             background-color: #304053;
    //             top: 34px;
    //             left: 54px;
    //             z-index: 1;
    //             transition: all ease-in-out 0.3s;
    //             margin: auto;
    //         }
    //         .timeline li.active {
    //             color: #555555;
    //         }

    //         .timeline li.active + li:after {
    //             background: #4caf50 !important;
    //         }
    //     }
    // }
    .navbar-right{
        padding: 18px 18px 0px 18px ;
    }
    .navbar-btn {
        margin-left: 20px;
        .name-btn {
            background: #7cbaa7;
            border-radius: 4px;
            font-weight: 600;
            font-size: 9px;
            line-height: 100%;
            padding: 8px 10px;
            margin-bottom: 10px;
            text-align: center;
            letter-spacing: 0.01em;
            width: 115px;
            color: #202020;
        }
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 11px;
            text-align: center;
            color: #202020;
        }
    }

    .navbar-bars {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: 20px;
        width: 40px;
        height: 40px;
        color: white;
        background: #7cbaa7;
        border-radius: 8px;
        margin: auto;
        margin-left: 10px;
        margin-right: 20px;
        margin-top: 35px;
    }
    .navbar-magni {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        font-size: 40px;

        width: 40px;
        height: 40px;
        margin-bottom: 20px;
        color: white;
        background: #202020;
    }
    .text-hide {
        color: transparent;
    }
    .main-content {
        .hero-sidebar {
            background: #cbd5e0;
            width: 8%;
            height: 100%;
            padding: 15px;

            .switch-outer {
                width: 182px;
                left: 22px;
                height: 93px;
                background-color: white;
                position: relative;
                border-radius: 49.5px;
                z-index: 1;
                .switch {
                    display: inline-block;
                    position: relative;
                    width: 130px;
                    height: 69.64px;
                    left: -16px;
                    top: 10px;
                    border-radius: 49.5px;
                    z-index: 2;
                    background: #7cbaa7;
                    box-shadow: none!important;
                    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                    vertical-align: middle;
                    cursor: pointer;
                }
                .switch::before {
                    content: "";
                    position: absolute;
                    top: 9px;
                    left: 68px;
                    width: 49.6px;
                    height: 49.6px;
                    background: white;
                    border-radius: 50%;
                    transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
                        box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
                }
                .switch:active::before {
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
                }
                input:checked + .switch {
                    background: #7cbaa7;
                }
                input:checked + .switch::before {
                    left: 11px;
                    background: #fff;
                }
                input:checked + .switch:active::before {
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
                }
            }
            .mode-set {
                position: relative;

                top: -37px;
                left: 104px;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
                transform: rotate(-90deg);
                z-index: 1;
            }
            .social-set {
                position: relative;

                top: 39px;
                left: 104px;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
                transform: rotate(-90deg);
                z-index: 1;
            }
            p {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 11px;
                text-align: center;
                color: #202020;
            }
            .bottom-tabs {
                color: #394649;
                margin-top: 50px;
                h6 {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 120%;
                    color: #192529;
                }
            }

            .info-icon {
                border: 3px solid #394649;
                padding: 5px 11px 8px 14px;
                font-size: 19px;
                margin: 20px 0px;
                color: #394649;

                border-radius: 50px;
            }
            .fa-magnify {
                font-size: 35px;
                margin: 10px 0px 55px;
                color: #394649;
            }
        }
        .content-hero {
            width: 92%;
            .content-text {
                background: #cbd5e0;
            }
        }
    }
    .content-text {
        position: relative;
        .dots {
            position: relative;
            top: -88px;
            left: 44px;
        }
        .tab-right-section {
            width: 15%;
        }
        .nav-item .active {
            background: #202020;
            color: white;
            box-shadow: none;
        }

        .tab-section {
            width: 93%;
            margin: auto;
            margin-top: -180px !important;
        }
        .tab-content{
            margin-top: revert;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            border-radius: 8px;
        }
        .tab-himself-text {
         
            p {
                overflow: auto;
                padding-right: 10px;
                ::-webkit-scrollbar {
                    width: 10px;
                }

                /* Track */
                ::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: #888;
                }

                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
        .playlist-section {
       
            left: 55px;
            z-index: 1;
            top: 5%;
            h4 {
                margin-bottom: 40px;
                width: 130px;
                position: relative;
                right: -99px !important;
            }

            /* Grey/8

Base color for Light mode
*/
        }
        .playlist-section-text {
      margin-top: 20px;
            background: #ffffff;
                border-radius: 20px;
                padding: 20px;
               margin-bottom: 20px!important;
                left: -52px;
                width: 100%;

                // @media screen and (max-width:1580px) {
                //     right: -193px;
                //     width: 350px;

                // }
                .slidecontainer{
                    width: revert;
                    margin-right: 20px;
                }
                input[type="range"].range-slider {
                    width: 70px;
                    margin-right: 7px;
                }
                .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                    height: 20px !important;
                    width: 26px !important;
                    bottom: -24px !important;
                    border-radius: 50% !important;
                    display: flex !important;
                    color: #202020;
                    justify-content: center !important;
                    align-items: center !important;
                    background-color: #3860ee !important;
                    font-size: 8px;
                }
                .range-slider__wrap
                    .range-slider__tooltip.range-slider__tooltip--bottom
                    .range-slider__tooltip__caret::before {
                    border-bottom-color: #3860ee !important;
                    border-width: 0 0.3rem 0.3rem;
                    bottom: 25px;
                    left: 2px;
                }
                .range-slider__wrap {
                    position: relative;
                    display: block;
                    height: 16px !important;

                    .range-slider__tooltip {
                        z-index: 0 !important;

                        &.range-slider__tooltip--bottom {
                            bottom: calc(0.75em + 0.375rem + 1px + 0.4rem + 10px + 3px) !important;
                            top: auto !important;
                        }

                        .range-slider__tooltip__caret {
                            transform: translateX(50%) !important;
                        }
                    }
                }
                input[type="range"].range-slider {
                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        box-sizing: border-box;
                        border: none;
                        border-radius: 50%;
                        background-color: #3860ee!important;
                        border: 1px solid black;
                        height: 8px !important;
                        width: 8px !important;
                        cursor: pointer;
                        transition: box-shadow 0.5s ease;
                        margin-top: -3px !important;
                    }
                }
                input[type="range"].range-slider {
                    box-shadow: none;
                    height: 8px;
                    padding: 0px;
                }
                input[type="range"].range-slider {
                    &::-webkit-slider-runnable-track {
                        -webkit-appearance: none !important;
                        width: 100% !important;
                        margin-top: 8px;
                        height: 2px !important;
                        background-color: #202020 !important;
                        border: 2px solid #202020;
                        outline: none !important;

                        -webkit-transition: 0.2s !important;

                        border-radius: 30px !important;
                    }
                }
            .next-icon {
                background: #202020;
                border-radius: 23.0737px;
                width: 35px;
                text-align: center;
                color: white;
                font-size: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 35px;
            }
            .setting-icons {
                background: #202020;
                margin-left: 20px;
                border-radius: 24.0118px;
                padding: 1px;
                color: white;
                font-size: 15px;
                display: flex;
                .icons-set {
                    margin: 5px 9px;
                }
            }
            .side-text {
                /* Grey/9 */

                background: #ffffff;
                border-radius: 2.38694px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 11.139px 6.36517px 3.97823px;
                font-weight: 500;
                font-size: 8.40381px;
                line-height: 100%;
                margin: 0px 10px;
                /* or 5px */
                border: 2px solid #dda01a;

                text-align: center;
                letter-spacing: 0.01em;
                text-transform: uppercase;
                color: #1c1c1c;
            }
            .text-gree {
                display: flex;
                position: relative;
                top: 4px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 3.27619px 5.2419px;
                width: 105.48px;
                display: flex;
                justify-content: center;
                margin: auto;
                font-size: 8px;
                height: 10.55px;

                background: #e5e9ed;
                border-radius: 1.96571px;
                font-style: normal;
                font-weight: 700;
                font-size: 4.44588px;
                line-height: 100%;
                text-align: center;
                letter-spacing: 0.01em;
                color: #101010;
            }
            .next-icon-center {
                background: #202020;
                border-radius: 50.7836px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: -4px 2px 0px;
                .iconss {
                    font-size: 15px;
                    color: white;
                    margin: 8px;
                }
            }

            .video-icon {
                font-size: 45px;
                color: #202020;
                margin: 0px 3px;
                svg {
                    margin-top: -13px;
                }
            }
            .mic-img {
                padding: 0px 20px;
                img {
                    width: 90px;
                }
            }
        }
      
       
    }
    .design-about {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 15px 18px;
        width: 67px;
height: 80px;
        position: relative;
        z-index: 1;
 background: #7CBAA7;
        color: #202020;
        border-radius: 4px;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
    }
    .mobile-btns{
        position: relative;
        top: -160px;
        display: flex;
justify-content: space-between;
padding: 0px 20px;
        align-items: center;
        .btn-video {
          
            display: flex;
            flex-direction: row;
            align-items: center;
           justify-content: center;
            width: 114px;
            height: 45px;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #ffffff;

            /* Others/1 */

            background: #304053;
            border-radius: 4px;
        }
        h4{
            width: 130px;
            color: white;
            font-size: 18px;
        }
    }
    .content-img {
        position: relative;
        width: 100%;
    }
    .hero-section {
        position: relative;
    }
    .hero-part {
        position: absolute;
        top:99px;
        left: 0px;
        h1 {
            font-weight: 700;
            font-size: 36px;
            line-height: 50px;
            color: #ffffff;

            text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #ffffff;

            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
        }
    }
    // @media screen and (min-width: 1500px) {
    //     .hero-part {
    //         position: absolute;
    //         top: 80px;
    //         left: 80px;
    //         h1 {
    //             font-weight: 700;
    //             font-size: 68px;
    //             line-height: 90px;
    //             color: #ffffff;

    //             text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    //         }
    //         p {
    //             font-weight: 400;
    //             font-size: 20px;
    //             line-height: 24px;
    //             color: #ffffff;

    //             text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    //         }
    //     }
    // }
    .content-hero {
        width: 92%;
    }
    .search-area {
      margin: 0px;
        .search-field {
            width: 250px;
    margin: 0px 10px 0px;
    height: 44px!important;
    box-shadow: none;
    padding: 2px 11px;

            background: transparent;
            border: 1px solid #7cbaa7;
            border-radius: 50px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #808492;
            .icon-mgni{
                font-size: 35px!important;
            }
            input {

                font-size: 15px;
            }
            svg {
                cursor: pointer;
            }
        }
    }
    .book-me-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 17px 15px;
        gap: 10px;
        margin: 0px ;
        
        width: 110px!important;
        height: 50px;
        background: #7cbaa7;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        border: none;
        letter-spacing: 0.04em;
        text-transform: uppercase;
    }
    .padding-t-b{
        padding-top:10px!important;
        padding-bottom: 10px!important;
        height: 163px!important;
    }
    .intro-banner {
        background-image: url(../../../../../../images/intro-img.png);
        width: 300px;
        height: 160px;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        padding: 20px;
        h5 {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
        }
        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;

            color: #ffffff;
        }

        .intro-btn {
            font-weight: 400;

            font-size: 14px;
            line-height: 17px;
            color: #202020;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 20px;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 20px;
            width: 250px;
            height: 37px;
            margin: 15px auto auto;
        }
    }
    .book-me-btns {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 17px 18px;
        gap: 10px;
        margin: 0px 30px 0px 20px;
        width: 115px;
        height: 50px;
        background: #7cbaa7;
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        /* identical to box height */
        border: none;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        position: relative;
        left: 162px;
        margin-top: 10px!important;
        
    }
    .himself-section {
          
        position: relative;
        border-radius: 16px;
        width: 54%;
        top: -597px;
        left: 4%;
        margin: auto;
        p{
            font-size: 13px ;
            font-weight:500;
        }
        .modal-tabs{
            position: relative;
            top: -85px;
        }
        .slidecontainer {
            width: revert;
            margin-left: -38px;
            margin-right: 11px;
        }
        .playlist-section-text {
            background: #ffffff;
            border-radius: 20px;
            padding: 20px;
           margin-bottom: 20px!important;
            left: -52px;
            width: 270px;

            // @media screen and (max-width:1580px) {
            //     right: -193px;
            //     width: 350px;

            // }
            input[type="range"].range-slider {
                width: 48px;
                margin-right: 7px;
            }
            .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                height: 20px !important;
                width: 26px !important;
                bottom: -24px !important;
                border-radius: 50% !important;
                display: flex !important;
                color: #202020;
                justify-content: center !important;
                align-items: center !important;
                background-color: #3860ee !important;
                font-size: 8px;
            }
            .range-slider__wrap
                .range-slider__tooltip.range-slider__tooltip--bottom
                .range-slider__tooltip__caret::before {
                border-bottom-color: #3860ee !important;
                border-width: 0 0.3rem 0.3rem;
                bottom: 25px;
                left: 2px;
            }
            .range-slider__wrap {
                position: relative;
                display: block;
                height: 16px !important;

                .range-slider__tooltip {
                    z-index: 0 !important;

                    &.range-slider__tooltip--bottom {
                        bottom: calc(0.75em + 0.375rem + 1px + 0.4rem + 10px + 3px) !important;
                        top: auto !important;
                    }

                    .range-slider__tooltip__caret {
                        transform: translateX(50%) !important;
                    }
                }
            }
            input[type="range"].range-slider {
                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    box-sizing: border-box;
                    border: none;
                    border-radius: 50%;
                    height: 8px !important;
                    width: 8px !important;
                    cursor: pointer;
                    transition: box-shadow 0.5s ease;
                    margin-top: -3px !important;
                }
            }
            input[type="range"].range-slider {
                box-shadow: none;
                height: 8px;
                padding: 0px;
            }
            input[type="range"].range-slider {
                &::-webkit-slider-runnable-track {
                    -webkit-appearance: none !important;
                    width: 100% !important;
                    margin-top: 8px;
                    height: 1px !important;
                    background-color: #202020 !important;
                    border: 2px solid #202020;
                    outline: none !important;

                    -webkit-transition: 0.2s !important;

                    border-radius: 30px !important;
                }
            }
            .next-icon {
                background: #202020;
                border-radius: 23.0737px;
                width: 20px;
                padding: 2px;
                text-align: center;
                color: white;
                font-size: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
            }
            .setting-icons {
                background: #202020;
                margin-left: 3px;
                border-radius: 24.0118px;
                padding: 0px 5px;
                color: white;
                font-size: 12px;
                display: flex;
                margin-top: -5px;
                margin-right: -55px;
                margin-left: 22px;
                .icons-set {
                    margin: 4px 4px;
                }
            }
            .side-text {
                /* Grey/9 */

                background: #ffffff;
                border-radius: 2.38694px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 95px;
                padding: 7.139px 0.36517px 3.97823px;
                font-weight: 500;
                font-size: 5.40381px;
                line-height: 100%;
                margin: 0px 10px;
                /* or 5px */
                border: 2px solid #dda01a;

                text-align: center;
                letter-spacing: 0.01em;
                text-transform: uppercase;
                color: #1c1c1c;
            }
            .text-gree {
                display: flex;
                position: relative;
                top: 4px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 3.27619px 5.2419px;
                width: 60.48px;
                display: flex;
                justify-content: center;
                margin: auto;
                font-size: 8px;
                height: 10.55px;

                background: #e5e9ed;
                border-radius: 1.96571px;
                font-style: normal;
                font-weight: 700;
                font-size: 4.44588px;
                line-height: 100%;
                text-align: center;
                letter-spacing: 0.01em;
                color: #101010;
            }
            .next-icon-center {
                background: #202020;
                border-radius: 50.7836px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: -4px 2px 0px;
                .iconss {
                    font-size: 12px;
                    color: white;
                    margin: 4px;
                }
            }

            .video-icon {
                font-size: 25px;
                color: #202020;
                margin: 0px 2px;
                svg {
                    margin-top: -11px;
                }
            }
            .mic-img {
                padding: 0px 5px;
                img {
                    width: 75px;
                }
            }
        }
        .tab-himself-text {
    
            border-radius: 16px;
            height: 100%;
            padding: 40px;
        }
    }
.tab-content{
    margin-top: -58px!important;
    background-color: #cbd5e0!important;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}
    .tab-himself-text {
       
       
            height: 100%;
            padding: 15px;
    
        
        p {
            overflow: auto;
            padding-right: 10px;
            ::-webkit-scrollbar {
                width: 10px;
            }

            /* Track */
            ::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: #888;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: #555;
            }
        }
    }
    .video-img {
        background-image: url(../../../../../../images/video-img.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        /* margin: 60px; */
        width: 120px!important;
        height: 80.63px;
        border-radius: 10.5263px 0px 0px 10.5263px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 25px;
    left: 241px;
    }
    .video-img img {
        width: 50px;
        height: 50px;
    }
    .over-flow{
        overflow: scroll;
        height: 440px;
    }

}
