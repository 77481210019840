// .elevator {
//     .elevator_heading {
//         font-weight: 400;
//         font-size: 16px;
//         line-height: 19px;
//         margin-bottom: 30px;
//         color: #697094;
//     }

//     .btn-bg.lg button {
//         top: 15px;
//         transform: rotate(12deg);
//     }
// }

.elevator_btn {
    .btn-bg.lg button {
        top: 15px;
        transform: rotate(12deg);
    }
}

.h-300 {
    height: 300px;
}

.stripe_accrount {
    h3 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
    }

    .three-bsiness .h3-border1 {
        padding-bottom: 30px;
    }

    .btn-bg.lg button {
        top: 15px;
        transform: rotate(12deg);
    }

    .w-75 {
        width: 75%;
    }

    .factorTab1 {

        &::before {
            content: url("../../../../images/vidBg_1.png");
            position: absolute;
            top: -50px;
            right: -32px;
            background: #eff4f8;
        }

        .VideoIcon {
            right: -28px;
        }
    }

    .toggle_btn_wrapper {
        background: linear-gradient(151.05deg, #EBF2F7 13.27%, #F2F6F9 83.27%);
        box-shadow: -6px -6px 20px rgba(255, 255, 255, 0.8), 3px 3px 12px rgba(166, 180, 200, 0.4);
        border-radius: 3px;

        label {
            color: #0777AE;
        }

        .form-switch .form-check-input {
            width: 80px;
        }
    }

    .text_blue {
        color: #0777AE;
    }

    .rotate_btn {
        .btn-bg button {
            // background: linear-gradient(349.94deg, #F0BF25 -8.14%, #E28D0E 119.35%);
            box-shadow: -12px -12px 20px rgba(255, 255, 255, 0.8), 6px 6px 24px rgba(166, 180, 200, 0.4);
            border-radius: 5px;
            transform: rotate(6deg);
            top: 16px;
            // color: #fff;
        }
    }

    .w-35 {
        width: 35px;
    }

    .w-320 {
        width: 320px;
    }
}

.dark {
    .paypal_accrount {
        .toggle_btn_wrapper {
            background: transparent;
            box-shadow: -6px -6px 20px rgba(0, 0, 0, 0.8), 3px 3px 12px rgba(46, 44, 44, 0.99);
        }
    }

}