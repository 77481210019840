.Feature-on-desktop-1{

  .grey-box {
    background-color: #CBD5E0;
    padding: 120px 80px 120px 80px;
  }
  
  .image-circle {
    background-color: #ffffff;
    
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: flex;
    
    align-items: center;
    justify-content: center;
    margin: 10px 20px 0px 10px ;
    
    z-index: 90;
}
.image-usa{
    background-color: #1EA3FF ;
  }


.line-update {
  margin: 81px 20px -105px 10px ;
    height: 12px;
    width: 80%;
    background: linear-gradient(to bottom, #7CBAA7 0%, #7CBAA7 33%, #304053 33%,#304053 66%,  #7CBAA7 66%, #7CBAA7 100%);

  }
  
  .curve-image {

padding-top: 20px;
padding-bottom: 20px;
  }

}