@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap");

.position-nav-mob {
  width: 22%;
  height: 130px;
  align-items: center;
  &.Regular-style {
    background-color: var(--bg-secondary-light);
  }

  &.Neumorphic-style .p-nav-mob-child {
    background: var(--bg-secondary-light);
    border-radius: 9.13706px;
    padding: 10px;
    .p-n {
      background: var(--bg-secondary-light);
      box-shadow: inset -0.913706px -0.913706px 2.74112px #ffffff,
        inset 0.913706px 0.913706px 2.74112px #b2bdcb;
      border-radius: 5.48223px;
      padding: 10px;
    }
  }
  .p-nav-mob-child {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .p-n {
    height: 70%;
    width: 50%;
    color: #7cbaa7;
  }
  .p-n > img {
    width: 100%;
  }
}
