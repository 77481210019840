.mobile-help-section{
    .timeline-working-mob-content {
        width:100%;
        display: block;
        .timeline-workin-top-content{
            .how-it-works-btn {
                border-radius: 30px;
                padding: 14px 25px;
                width: fit-content;
                background-color: #202020;
                color: #fff;
                cursor: pointer;
                font-size: 14px;
                margin: 0 auto;
            }
            h2 {
                font-family: Inter;
                font-size: 30px;
                font-weight: 700;
                line-height: 30px;
                letter-spacing: 0em;
                text-align: center;
                display: block;
                margin-top: 20px;
                margin-bottom: 10px;;
                color: #304053;
            }
            p {
                font-family: Inter;
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
        .timeline-workin-middle-content{
            margin-top: 10px;   

            .timeline-working-desc{
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: center;
                color: #202020;
            }
        }
        .timeline-section-bottom-content{
            .timeline-section-bottom-content-inner {
                background-color: #fff;
                width: 100%;
                max-width: 460px;
                padding: 20px;
                box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
                border-radius: 20px;

                h3 {
                    font-family: Inter;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 36px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                    margin-bottom:0 ;
                }
                p{
                    font-family: Inter;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left;
                    margin-bottom: 0;
                    margin-top: 10px;

                }
                .btn-text-wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    .timeline-lets-talk {
                        background-color: #7CBAA7;
                        border-radius: 40px;
                        padding: 13px 20px;
                        font-family: Inter;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 16px;
                        letter-spacing: 0.04em;
                        text-align: center;
                        margin-right: 20px;
                        width: 150px;
                        cursor: pointer;
                    }

                    .timeline-working-hours {
                        width: 100%;
                        max-width: 160px;
                        border-left: 1px solid #CBD5E0;
                        padding-left: 20px;
                        text-align: left;
                        span {
                            font-family: Inter;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #202020;
                        }
                    }

                }
            }
        }

    }
}
