@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Norican&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hurricane&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Qwigley&display=swap");

.Glassmorphic-design {
    .profile-img{
        position: relative;
    }
    #parent-circle{
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
        box-shadow: inset 3.36935px 3.36935px 3.36935px rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(4.21169px);
        border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
        .circle{
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.7) 3.01%, rgba(255, 255, 255, 0.02) 103.3%);
            box-shadow: inset 3.36935px 3.36935px 3.36935px rgba(255, 255, 255, 0.25);
            backdrop-filter: blur(1.26351px);
color: black;

        }
        .videoo {
      
            color: rgb(124, 186, 167)!important;
        }
        .snapchat {

            background-color: transparent!important;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.7) 3.01%, rgba(255, 255, 255, 0.02) 103.3%);
            box-shadow: inset 3.36935px 3.36935px 3.36935px rgba(255, 255, 255, 0.25);
            backdrop-filter: blur(1.26351px);
        }
    }
    .timeline-top .icon-green{
        color:   black!important ;
    }
   .dark-meu-show{
    display: none;
   } 
    .hamburger-outer{
        .switch-outer{
            display: none;
        }
   
    }
    .glass-show{
        display: block!important;
        text-align: center;

    }

.meo-show{
    display: none;
}
    .bars-hambrger .hamburger-outer .bottom-tabs {
        color: #202020!important;
        h6{
            color: #202020!important;  
        }
        svg{
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%)!important;
            border: 1px solid rgba(255, 255, 255, 0.4901960784) !important;
            border-radius: 30px;
            padding: 2px;
        }
    }
    .forms-inpt {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%) !important;
        border: 1px solid rgba(255, 255, 255, 0.4901960784);
    }
    .searchbox .search-area .search-field{
        box-shadow: inset 0px 10px 20px rgba(255, 255, 255, 0.07);
backdrop-filter: blur(10px);
background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%) !important;
border: 1px solid rgba(255, 255, 255, 0.4901960784);

border-radius: 4px!important;
    }
    .content-text .playlist-section-text , .content-text .video-section {
        backdrop-filter: blur(10px);
    }
    .searchbox{
        background: linear-gradient(315.3deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%)!important;


        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
 
        backdrop-filter: blur(10px);
      
    }
    .reg-sond{
        display: none;
    }
    .glass-sond{
        display: block;
    }
    .hamburger-outer {
        background: linear-gradient(313.9deg, #9595B8 3.42%, #ECEDF5 47.62%, #C8C9D2 79.67%, #97999D 111.07%)!important;
    }
    .navbar-top .testimonial .timeline-top p {
        font-size: 6px!important;
    }
        .fa-magnify{
        font-size: 26px;
        margin: 10px 0px 55px;
    color: #394649;
    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
      border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
        border-radius: 30px;
        padding: 10px;
    }
    .info-oter{
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
        border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
        width: 50px;
        height: 50px;
        margin: auto;
        border-radius: 50px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .meu-social{
        display: block;
    }
@media screen and (max-width: 1450px){
    .design-screen-desktop-1 .content-text .playlist-section-text {
        right: -167px;
        width: 330px;
    }
}


@media screen and (max-width: 1662px){
.design-screen-desktop-1 .content-text .playlist-section-text {
    right: -193px;
    width: 350px;
}
}
    .meu-show{
        display: none!important;

    }
    .test-reg{
        display: none;
    }
    .timeline-gold{
        display: none;
    }
    .reg-social{
        display: none!important;
    }
    .switch-border {
        width: 182px;
        left: 22px;
        height: 93px;
        border: 1px solid rgba(255, 255, 255, 0.4901960784);
        position: relative;
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.7) 3.01%, rgba(255, 255, 255, 0.02) 103.3%)!important;
        box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(1.5px);
        position: relative;
        border-radius: 49.5px;
        z-index: 31;
        .check-box {
            transform: scale(2);
        }

        input[type="checkbox"] {
            position: relative;
            appearance: none;
            width: 100px;
            height: 50px;
            background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%);
            box-shadow: 1.0084px 1.0084px 1.0084px #B6C8DA, -1.0084px -1.0084px 1.0084px #FFFFFF !important;
            border-radius: 50px;
          
            cursor: pointer;
            transition: 0.4s;
        }

        input:checked[type="checkbox"] {
            background: #7da6ff;
        }

        input[type="checkbox"]::after {
            position: absolute;
            content: "";
            width: 48px;
            height: 48px;
            left: 50%;
            top: 10px;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%)!important;
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            transform: scale(1.1);
            transition: 0.4s;
        }

        input:checked[type="checkbox"]::after {
            left: 14px;
        }

        check-box {
            display: inline-block;
            position: relative;
            width: 130px;
            height: 69.64px;
            left: -16px;
            top: 10px;
            box-shadow: none !important;
            border-radius: 49.5px;
            z-index: 2;
            background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%);
    box-shadow: 1.0084px 1.0084px 1.0084px #B6C8DA, -1.0084px -1.0084px 1.0084px #FFFFFF !important;

            vertical-align: middle;
            cursor: pointer;
        }
        input[type="checkbox"] {
            display: inline-block;
            position: relative;
            width: 130px;
            height: 69.64px;
            left: -16px;
            top: 10px;
            box-shadow: none !important;
            border-radius: 49.5px;
            z-index: 2;
            background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%)!important;
            box-shadow: 1.0084px 1.0084px 1.0084px #B6C8DA, -1.0084px -1.0084px 1.0084px #FFFFFF !important;
     
            vertical-align: middle;
            cursor: pointer;
        }

        input[type="checkbox"]::before {
            content: "";

            border-radius: 50%;

            position: absolute;
            top: 0;
            left: 0;
            transition: 0.5s;
        }

        input[type="checkbox"]:checked::before {
            transform: translateX(100%);
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%)!important;
        }

        input[type="checkbox"]:checked {
            display: inline-block;
            position: relative;
            width: 130px;
            height: 69.64px;
            left: -16px;
            top: 10px;
            box-shadow: none !important;
            border-radius: 49.5px;
            z-index: 2;
            background: linear-gradient(158.72deg, rgba(0, 0, 0, 0.4) 3.01%, rgba(0, 0, 0, 0.2) 103.3%)!important;
            box-shadow: 1.0084px 1.0084px 1.0084px #B6C8DA, -1.0084px -1.0084px 1.0084px #FFFFFF !important;
    
            vertical-align: middle;
            cursor: pointer;
        }
    }
    .btn-video {
        display: none !important;
    }

    .design-screen-desktop-1 {
        background: linear-gradient(311.75deg, #9595B8 3.63%, #ECEDF5 53.34%, #C8C9D2 88.18%, #97999D 110.87%)!important;
        .hamburger-outer {
     
            left: -290px;
        }
        .glass-show{
    position: absolute;
    right: 75px;
    top: 9px;
}
@media screen and (max-width:1800px) and (min-width:1558px) {
    .navbar-top .profile-img {
        width: 60%!important;
        margin-left: 15%!important;
        margin-top: -133px!important;
    }
}
@media screen and (max-width:1369px) {
    .active-oter {
     
        margin-right: 7px !important;
    }
    .glass-show  {

        right: 2%!important;

    }
    .content-text .tab-himself-text{
        padding: 70px 115px 20px;
    }
    .main-content .hero-sidebar .mode-set {
 
        left: 85px!important;
    }
    .main-content .hero-sidebar .social-set{
        left: 85px!important;
    }
    .content-text .playlist-section h4{
        right: -58px !important;
    }
}

.searchbox     .glass-show {
    position: revert!important; 
    margin-top: 20px;

}
.bookme-inner{
    .hamburger-outer{
        .glass-show {
            position: revert!important; 
            margin-top: 20px;
        
        } 
    }
}
        .vector-shape{
    display: block;
    width: 25%;
    position: absolute;
    bottom: 136px;
    left: 16px;
  }
        .circle-shape{
        display: block;
        position: absolute;
        right: 94px;
        width: 336px;
        bottom: 297px;
        z-index: 0;
      }
      .navbar-shape{
        display: block;
        right: 55px;
        top: 15%;
        position: absolute;
      }
        .search-box{
        background: linear-gradient(315.3deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%)!important;
    
        position: absolute;

        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
 
        backdrop-filter: blur(10px);
      
    }
        .m-section .hero-section-mue .content-img {
            width: 90% !important;
            right: -5% !important;
            height: 624px !important;
            padding: 15px !important;
        }
        .m-section .hero-section {
            position: relative;
            box-shadow: none !important;
            background: transparent !important;
            padding: 0px !important;
            margin: 0px !important;

        }
        .bars-hambrger .hamburger-outer .testimonial .timeline-top {
            flex-direction: column;
            font-weight: 500;
            font-size: 10px;
            line-height: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: relative;
            color: black;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
            border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
            overflow: hidden;
            width: 84px;
            padding: 8px;
            height: 84px;
            top: 92px;
            left: 18px;
            p {
                font-size: 6px;
            }
        }
        .hamburger-outer {
    
            position: absolute;
            z-index: 3;
            padding: 40px 20px 40px;
            width: 400px;
            right: 0;
            top: 0;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
            border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
            border-radius: 10px;
            .close-btn {
             
                height: 67px;
                font-size: 23px;
                position: absolute;
                right: 14px;
                top: 1px;
            }
            .sidebar-li {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
             
                text-align: center;
              border-bottom: 1px solid transparent; 
                padding: 20px;
                color: #f0f4f8;
                color: #202020!important;
                margin: 16px;
             
            }
            .switch-outer{
                display: none;
            }
        }
       .gold-btn {
            position: absolute;
            z-index: 5;
            background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);
            box-shadow: -6px -6px 15px #FFFFFF, 6px 6px 15px rgba(178, 189, 203, 0.7);
            border-radius: 30px;
            padding: 20px;
            width: 270px;
            top: 39px;
            display: block;
            text-align: center;
            margin-bottom: 63px;
            margin-top: -27px;
            left: -66px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #202020;
        }
        .searchbox{
            background: linear-gradient(315.3deg, #9595B8 3.28%, #ECEDF5 45.05%, #C8C9D2 67.11%, #97999D 93.39%) !important;
            .search-area{
                .search-field {
margin-top: 15px!important;
background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
                    border-radius: 40px;
                    border: 1px solid transparent!important;
                    width: 100%;
                    margin: 0px 10px 0px;
                    height: 44px !important;
                    box-shadow: none;
                    padding: 2px 11px;
          
            
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #394649;
                }
            }
     
            .close-btn {
                border: none;
                background-image: url(../../../../../images/close-batch.png);
                background-repeat: no-repeat;
                top: 2px;
                height: 68px;
                margin-top: -11px;
                top: 3px;
                right: 9px;
                font-size: 34px;
                color: grey;
                position: absolute;
                svg{
                    margin-top: -34px;
                    margin-left: 10px;
                    font-size: 28px;
                }
            } 
        }
        .meu-social{
            display: block;
            li {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
                border-radius: 30px;
                padding: 10px;
                width: 35px;
                height: 35px;
                text-align: center;
                padding: 9px 9px 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 15px auto;
    
            }
        }
      
        .close-btn {
            border: none;
            background-image: url(../../../../../images/close-batch.png);
            background-repeat: no-repeat;
            top: 2px;
            height: 68px;
            margin-top: -10px;
            top: 3px;
            right: 9px;
            font-size: 34px;
            color: grey;
            position: absolute;
            svg{
                margin-top: -34px;
                margin-left: 10px;
                font-size: 28px;
            }
        }
        .meu-icon {
            display: block;
            margin-right: 10px;
            margin-left: 5px;
        }
        .bookme-inner{
            background: transparent;
        }
        .meu-img {
            display: block;
        }
        .reg-timeline{
            display: none;
        }
        .meu-timeline {
            display: block !important;
        }
        .forms-inpt {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%)!important;
            border: 1px solid rgba(255, 255, 255, 0.4901960784);
            border-radius: 10px;
            padding: 38px 15px;
          margin-top: 40px;
            .form-control {
                width:100%;
                margin: 0px 3px;
                height: 100% !important;
                padding: 13px 30px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;

        
                border-radius: 4px!important;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
        
                color: #808492;
            }
        }
        .bars-hambrger {
            display: flex;
            align-items: center;
        }
        .reg-timeline {
            display: none;
        }
        .reg-img {
            display: none;
        }
        .reg-icon {
            display: none;
        }
        .meu-icon {
            width: 21.57px;
            height: 21.57px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
            border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
            border-radius: 11.7633px;
        }
        .meu-playlist-section {
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
            border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
            border-radius: 6.41451px;
            padding: 15px !important;
            .next-icon-center .iconss {
                font-size: 10px;
                color: #c49530;
                margin: 8px;
            }
        }
      

        .meu-show img {
            position: absolute;

            display: flex;
            flex-direction: row;
            align-items: center;
            right: 7%;
            top: -4px;
        }

        .navbar-top {
            background: transparent!important;
            height: 160px;
            padding: 24px !important;
            .active-oter {
                width: 85px;
                height: 85px;
                margin-right: 48px;
                padding: 8px;
                z-index: 2;
                border-radius: 50px;

                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
     
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.07) 3.01%, rgba(255, 255, 255, 0.02) 103.3%);
/* Background blur/Light/3 */

box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
backdrop-filter: blur(5px);
            }
            .testimonial {
                margin-top: -80px;
                .timeline-top {
                    flex-direction: column;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: black;
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                    border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;

                    width: 93px;
                    padding: 8px;
                    height: 93px;
                    top: 104px;
                    left: 23px;
                    p {
                        margin-top: 4px;
                        line-height: 10px;
                    }
                    .icon-green {
                        font-size: 8px;
                        color: #202020;
                        padding-top: 3px;
                    }
                    p {
                        font-size: 8px;
                    }
                }

                img {
                    width: 140px;
                    margin: 0px 13px;
                    position: relative;
                    z-index: 2;
                }
            }

            .profile-img {
                width: 83px;
                margin-left: 30px;
                margin-top: -161px;
            }
            .icons-circle {
                width: 140px;
                margin-top: 18px;
            }
            .img-logo {
                margin-left: 20px;
                width: 110px;
            }
            .timeline {
                display: flex;
                justify-content: revert!important;
                counter-reset: year 2016;
                position: relative;
                margin-top: 27px;
                p {
                    font-weight: 400;
                    font-size: 9px;
                    line-height: 11px;
                    /* identical to box height */

                    display: flex;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #1c1c1c;
                    margin-top: -13px;
                }
            }

            .timeline li {
                list-style: none;
                float: left;

                position: relative;
                text-align: center;
                text-transform: uppercase;
                font-family: "Dosis", sans-serif;
            }

            //   ul:nth-child(1){
            //     color: #4caf50;
            //   }
            .timeline-icon {
                margin-top: -30px;
            }
            .timeline-top {
                width: 40px;
                height: 40px;
                font-weight: 500;
                font-size: 12px;
                line-height: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: relative;
                color: #5f6168;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
                top: -27px;
                left: 15px;
            }
            .timeline li {
                z-index: 2;
                position: relative;

                border-radius: 50%;
                display: block;
                text-align: center;
                line-height: 50px;
                margin: 0 auto 10px auto;
                width: 70px;
                height: 70px;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
                color: #5f6168;
                font-size: 15px;
                margin-right: 47px;

                transition: all ease-in-out 0.3s;
                cursor: pointer;
                @media screen and (max-width: 1400px) {
                    margin-right: 15px !important;
                }
                .timeline-med {
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                    border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
                    width: 75px;
                    margin: 10px;
                    height: 75px;
                    border-radius: 50%;
                }
                img {
                    width: 15px;
                    margin-top: -10px;
                }
                .message {
                    width: 20px !important;
                    margin-top: -10px;
                }
                .load {
                    width: 25px !important;
                    margin-top: -10px;
                }
                .answer {
                    width: 25px;
                    margin-top: -10px;
                }
            }

            .timeline:after {
                content: "";
                position: absolute;

                width: 60%;
                height: 12px;
                border: 4px solid #f0f4f8;
              background: #202020!important;
                top: 34px;
                box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9);
                left: 85px;
                z-index: 1;
                transition: all ease-in-out 0.3s;
                margin: auto;
            }
            .timeline li.active {
                color: #555555;
            }

            .timeline li.active + li:after {
                background: #4caf50 !important;
            }
        }

        @media screen and (max-width: 1550px) {
            .navbar-top {
              
                height: 160px;
                padding: 24px !important;
                .testimonial {
                    margin-top: -80px;
                    .timeline-top {
                        flex-direction: column;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 13px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        position: relative;
                        color: #5f6168;
                        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
      border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;

      width: 93px;
      padding: 8px;
      height: 93px;
      top: 104px;
                        left: 23px;
                        p {
                            margin-top: 4px;
                            line-height: 10px;
                        }
                        .icon-green {
                            font-size: 8px;
                            color: #202020;
                            padding-top: 3px;
                        }
                        p {
                            font-size: 8px;
                        }
                    }

                    img {
                        width: 140px;
                        margin: 0px 13px;
                        position: relative;
                        z-index: 2;
                    }
                }
                .profile-img {
                    width: 74px;
                    margin-left: 17px;
                    margin-top: -125px;
                }
                .icons-circle {
                    width: 125px;
                    margin-top: 18px;
                }
                .img-logo {
                    margin-left: 11px;
                    width: 88px;
                }
                .timeline {
                    display: flex;
                    justify-content: space-around;
                    counter-reset: year 2016;
                    position: relative;
                    margin-top: 27px;
                    p {
                        font-weight: 400;
                        font-size: 8px;
                        padding: 0px 15px 0px;
                        line-height: 11px;
                        display: flex;
                        align-items: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #1c1c1c;
                        margin-top: -14px;
                    }
                }

                .timeline li {
                    list-style: none;
                    float: left;

                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    font-family: "Dosis", sans-serif;
                }

                //   ul:nth-child(1){
                //     color: #4caf50;
                //   }
                .timeline-icon {
                    margin-top: -30px;
                }
                .timeline-top {
                    width: 35px;
                    height: 35px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: relative;
                    color: #5f6168;
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                    border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
                    top: -27px;
                    left: 18px;
                }
                .timeline li {
                    z-index: 2;
                    position: relative;

                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 50px;
                    margin: 0 auto 10px auto;
                    width: 70px;
                    height: 70px;

                    background: transparent;
                    color: #5f6168;
                    font-size: 15px;
                    margin-right: 30px;

                    transition: all ease-in-out 0.3s;
                    cursor: pointer;
                    @media screen and (max-width: 1400px) {
                        margin-right: 15px !important;
                    }
                    img {
                        width: 15px;
                    }
                    .message {
                        width: 20px !important;
                    }
                    .load {
                        width: 21px !important;
                    }
                    .answer {
                        width: 28px;
                    }
                }

                .timeline:after {
                    content: "";
                    position: absolute;
                    width: 69%;
                    height: 12px;
                    /* Neo/Light/Inner/6 */

                    box-shadow: inset -1px -1px 1px #ffffff, inset 1px 1px 1px rgba(178, 189, 203, 0.9);
                    border: 4px solid #f0f4f8;
                    background: #202020 !important;
                    top: 34px;
                    left: 54px;
                    z-index: 1;
                    transition: all ease-in-out 0.3s;
                    margin: auto;
                }
                .timeline li.active {
                    color: #555555;
                }

                .timeline li.active + li:after {
                    background: #4caf50 !important;
                }
            }
        }
        .navbar-bars {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 30px;

            margin: 10px;
            width: 90px;
            height: 90px;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
            border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
            border-radius: 5px;
            color: #202020;
        }

        .text-hide {
            color: transparent;
        }
        .main-content {
            .hero-sidebar {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%)!important;
                /* Background blur/Light/3 */
                
                box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25);
                backdrop-filter: blur(5px);
                /* Neo/Light/Outer/4 */
                z-index: 1;
                position: relative;
                box-shadow:none;
                width: 9%;
                height: 100%;
                padding: 15px;
                .fa-magnify{
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                    border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
                    padding: 12px!important;

                }
                .mode-set {
                    position: relative;

                    top: -37px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #202020;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                .social-set {
                    position: relative;

                    top: 39px;
                    left: 104px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #202020;
                    transform: rotate(-90deg);
                    z-index: 1;
                }
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 11px;
                    text-align: center;
                    color: #202020;
                }
                .bottom-tabs {
                    color: #394649;
                    margin-top: 50px;
                    h6 {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 120%;
                        color: #192529;
                    }
                }

                .name-btn {
             

                    border: 1.14031px solid #f0f4f8;

                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);  
                    border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;
                    border-radius: 4px;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 9px;
                    line-height: 100%;
                    padding: 8px 10px;
                    margin-bottom: 10px;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #202020;
                }
                .info-icon {
                    border: 3px solid #394649;
                    padding: 5px 11px 8px 14px;
                    font-size: 19px;
                    margin: 20px 0px;
                    color: #394649;

                    border-radius: 50px;
                }
                .fa-magnify {
                    font-size: 35px;
                    margin: 10px 0px 55px;
                    color: #394649;
                }
            }
            .content-hero {
                width: 92%;
                .content-text {
                    background: transparent!important;
                }
            }
        }
        .content-text {
            position: relative;
            .nav-item .active {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%)!important;  
              
                

                border: 1px solid #facb30!important;
                box-shadow: none;
            }
            .nav-pills {
                justify-content: center;
            }
            .tab-content {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
                backdrop-filter: blur(10px);
                border: 1px solid #facb30;
                height: 440px!important;
            }
            .nav-item {
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
    border: 1px solid rgba(255, 255, 255, 0.4901960784);
    backdrop-filter: blur(10px);
                border-radius: 5px;
                padding: 15px;
                z-index: 2;
            }
            .mue-shape{
                display: block;
             
            }
        
            .dots{
                display: none;
            }
            .dots {
                position: relative;
                top: -88px;
                left: 44px;
            }
            .tab-right-section {
                width: 15%;
            }
            .nav-item .active {
                background: #202020;
                color: white;
                box-shadow: none;
            }

            .tab-section {
                width: 55%;
                margin: auto;
                margin-top: -105px !important;
            }
            .tab-himself-text {
                margin-top: 25px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                position: relative;

                border-radius: 8px;
                p {
                    overflow: auto;
                    padding-right: 10px;
                    height: 190px;
                    ::-webkit-scrollbar {
                        width: 10px;
                    }

                    /* Track */
                    ::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                        background: #888;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }
                }
            }
            .playlist-section {
                position: absolute;
                left: 55px;
                z-index: 1;
                top: 5%;
                h4 {
                    margin-bottom: 40px;
                    width: 130px;
                    position: relative;
                    right: -99px !important;
                }

                /* Grey/8
    
    Base color for Light mode
    */
            }
            .playlist-section-text {
                position: absolute;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784);
                border-radius: 8px;
                width: 100%;
box-shadow: none!important;
                padding: 20px;
                margin-top: 23px;
                right: -159px;
                width: 336px;

                /* Grey/8
    
    Base color for Light mode
    */
    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
    border: 1px solid rgba(255, 255, 255, 0.4901960784);
                border-radius: 8px;
                .slidecontainer {
                    width: revert;
                    margin-right: -8px;
                }
                @media screen and (max-width: 1580px) {
                    right: -159px;
                    width: 336px;
                }
                input[type="range"].range-slider {
                    width: 68px;

                    margin-right: 20px;
                }
                .range-slider__wrap .range-slider__tooltip .range-slider__tooltip__label {
                    height: 20px !important;
                    width: 30px !important;
                    bottom: -24px !important;
                    border-radius: 50% !important;

                    left: -10px;

                    display: flex !important;
                    color: #202020;
                    justify-content: center !important;
                    align-items: center !important;
                    background: linear-gradient(180deg, #FACB30 -18%, #FEF9C8 22.5%, #C49530 67.84%, #FFF8C4 116%);

                }
                .range-slider__wrap
                    .range-slider__tooltip.range-slider__tooltip--bottom
                    .range-slider__tooltip__caret::before {
                    border-bottom-color: #FACB30 !important;
                    left: 13px;

                }
                .next-icon {
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                    border: 1px solid rgba(255, 255, 255, 0.4901960784);
                    border-radius: 23.0737px;
                    width: 35px;
                    text-align: center;
                    color: #202020!important;
                    font-size: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 35px;
                }
                .setting-icons {
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                    border: 1px solid rgba(255, 255, 255, 0.4901960784);
                    margin-left: 20px;
                    border-radius: 24.0118px;
                    padding: 1px;
                    color: #202020;
                    font-size: 15px;
                    display: flex;
                    .icons-set {
                        margin: 5px 9px;
                    }
                }
                .side-text {
                    /* Grey/9 */
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                    border: 1px solid rgba(255, 255, 255, 0.4901960784);
                    border-radius: 2.38694px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 11.139px 6.36517px 3.97823px;
                    font-weight: 500;
                    font-size: 6.40381px;
                    line-height: 100%;
                    margin: 0px 10px;
                    /* or 5px */
                    border: 2px solid #dda01a;

                    text-align: center;
                    letter-spacing: 0.01em;
                    text-transform: uppercase;
                    color: #1c1c1c;
                }
                .text-gree {
                    display: flex;
                    position: relative;
                    top: 4px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 3.27619px 5.2419px;
                    width: 105.48px;
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    font-size: 8px;
                    height: 10.55px;

                    background: #e5e9ed;
                    border-radius: 1.96571px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 4.44588px;
                    line-height: 100%;
                    text-align: center;
                    letter-spacing: 0.01em;
                    color: #101010;
                }
                .next-icon-center {
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                    border: 1px solid rgba(255, 255, 255, 0.4901960784);
                    border-radius: 50.7836px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: -4px 2px 0px;
                    .iconss {
                        font-size: 10px;
                        color: #c49530;
                        margin: 8px;
                    }
                }

                .video-icon {
                    font-size: 20px;
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                    border: 1px solid rgba(255, 255, 255, 0.4901960784);
                    border-radius: 50%;
                    color: #c49530;
                    width: 22.64px;
                    height: 22.64px;
                    margin: 0px 4px 0px 3px;
                    padding: 4px 1px;
                    svg {
                        margin-top: -13px;
                    }
                }
                .mic-img {
                    padding: 0px 20px;
                    img {
                        width: 90px;
                    }
                }
            }
            .btn-video {
                position: absolute;
                right: 19%;
                top: 36px;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 17px 25px;
                width: 114px;
                height: 45px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #202020;

                /* Others/1 */
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784);
                border-radius: 4px;
            }
            .video-section {

                width: 23%;
                position: absolute;
                background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                border: 1px solid rgba(255, 255, 255, 0.4901960784);
                border-radius: 8px;
                right: 80px;
                height: 50%;
                bottom: 27%;
                display: flex;
                justify-content: center;
                align-items: center;
                .video-name{
                    background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
                    border: 1px solid #f1b82e;
                    border-radius: 10px;
                }
                .video-img {
                    background-image: url(../../../../../images/video-img.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 152.63px;
                    margin: 13px;
                    width: 230px;
                    height: 155.63px;
                    border-radius: 10.5263px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: revert;
                }
            }
        }
        .design-about {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 15px 18px;
            width: 90px;
            height: 110px;
            position: relative;
            z-index: 1;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%);
            border: 1px solid rgba(255, 255, 255, 0.4901960784)!important;


            border-radius: 4px;

            border-radius: 4px;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            text-align: center;

            /* Grey/2 */

            color: #202020 !important;
        }
        .content-img {
            position: relative;
            width: 100%;
            height: 100% !important;
            background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.3) 3.01%, rgba(255, 255, 255, 0) 103.3%)!important;
            /* Background blur/Light/3 */
            
            box-shadow: inset 4px 4px 4px rgba(255, 255, 255, 0.25)!important;
            backdrop-filter: blur(5px);
            border: 1px solid #dcbd6c!important;
        }
        .hero-section {
            position: relative;
         
            padding: 20px;
            margin: 30px 80px;

            border-radius: 20px;
        }
        .hero-part {
            position: absolute;
            top: 41px;
            left: 107px;
            h1 {
                font-weight: 700;
                font-size: 68px;
                line-height: 90px;
                color: #ffffff;

                text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
            }
            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;

                text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
            }
        }
        @media screen and (min-width: 1500px) {
            .hero-part {
                position: absolute;
                top: 80px;
                left: 165px;
                h1 {
                    font-weight: 700;
                    font-size: 68px;
                    line-height: 90px;
                    color: #ffffff;

                    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
                }
                p {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: #ffffff;

                    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
                }
            }
        }
        .content-hero {
            width: 92%;
        }
    }

   

    .img-logo-oter {
        background: linear-gradient(158.72deg, rgba(255, 255, 255, 0.5) 3.01%, rgba(255, 255, 255, 0.05) 103.3%);
        border: 1px solid rgba(255, 255, 255, 0.4901960784);
        border-radius: 15px;
        padding: 10px;
        margin-left: 10px;
        .img-logo {
            border-radius: 15px;
            margin-left: 0px !important;
        }
    }

}

@media screen and (max-width: 1580px){
    .Glassmorphic-design .design-screen-desktop-1 {
        .content-text .playlist-section-text {
            right: -132px;
            width: 314px;
        }
        .gold-btn{
            left: -104px;
        }
        .playlist-section h4{
            margin-bottom: 52px!important;
            width: 130px;
            font-size: 19px;
            position: relative;
            right: -39px!important;
        }
        .active-oter {
            width: 85px;
            height: 85px;
            margin-right: 20px!important;
        }

    }
   
    

}