@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Inter:wght@400;500;600;700;800;900&family=Kalam:wght@300;400;700&display=swap');
// ammar css start
.desktop-help-section.how-it-work-help-section {
    padding: 40px 75px 0px;
    .top-desc {
        h2 {
            font-size: 30px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0em;
            font-family: Inter;
        }

        p {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
        }
    }

   
    .timeline-section-content {
        display: flex;
        align-items: center;
        margin-top: 70px;
      
    }
}

// ammr css end